import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DeleteImage from "../assets/images/deletedot.png";
import { IconButton } from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import { Rate } from "antd";
import { useDispatch } from "react-redux";
import { addRating } from "../action/otherProviderConstant";
import ImageLayouts from "./ImageLayouts/ImageLayouts";
import moment from "moment";

const ReviewPopUp = ({
  open,
  setOpen,
  providerId,
  center,
  isEvent = false,
  event,
  userId,
  userDetails,
  service,
  serviceId
}) => {
  const [value, setValue] = useState({
    rate: 0,
    discription: "",
  });

  const [validation, setValidation] = useState("");

  const dispatch = useDispatch();


  return (
    <>
      <Modal
        centered
        className={"reviewModal"}
        show={open.review}
        onHide={() => {
          setOpen((pre) => ({ ...pre, review: false }));
        }}
        dialogClassName="delete-modal"
      >
        <div className="Congratulations_msg p-3" style={{ maxWidth: "600px" }}>
          <div className="d-flex justify-content-between align-items-top position-relative">
            <div className="text-center w-100">
             
                <p className="m-0 mb-2">You just had an experience</p>
              {isEvent ? (
                <h3 className="m-0">
                  {event?.event?.event_name} on{" "}
                  {moment(event?.event?.start_date).format("DD MMM yyyy")}
                </h3>
              ) : (
                <h3 className="m-0">{userDetails?.details?.find_user_service_details?.filter((ser) => serviceId == ser?._id)[0]?.service_name}</h3>
              )}
            </div>
            <IconButton
              className="position-absolute"
              style={{ right: "-10px", top: "-10px" }}
              onClick={() => setOpen((pre) => ({ ...pre, review: false }))}
            >
              <img src={closeIcon} alt="" />
            </IconButton>
          </div>

          <div className="d-flex flex-wrap align-items-center">
            {isEvent ? (
              <div className="col-12 col-md-5 p-2">
                {event?.event?.event_media?.length > 2 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    viewBox="0 0 205 204"
                    fill="none"
                  >
                    <path
                      d="M83.0262 98.5854H31.2789C29.4974 98.5854 27.8504 97.6375 26.9555 96.0971L1.51387 52.3044C0.61168 50.7514 0.611681 48.8339 1.51387 47.281L26.9555 3.48832C27.8504 1.9479 29.4974 1 31.2789 1H83.0262C84.8077 1 86.4546 1.9479 87.3495 3.48831L112.791 47.281C113.693 48.8339 113.693 50.7514 112.791 52.3044L87.3495 96.0971C86.4546 97.6375 84.8077 98.5854 83.0262 98.5854Z"
                      fill="url(#pattern0_4934_6874)"
                      stroke="url(#paint0_linear_4934_6874)"
                    />
                    <path
                      d="M83.0274 203.013H31.2777C29.4968 203.013 27.8504 202.066 26.9552 200.527L1.51596 156.774C0.612578 155.22 0.612578 153.301 1.51596 151.747L26.9552 107.995C27.8504 106.455 29.4968 105.508 31.2777 105.508H83.0273C84.8082 105.508 86.4547 106.455 87.3498 107.995L112.789 151.747C113.692 153.301 113.692 155.22 112.789 156.774L87.3498 200.527C86.4547 202.066 84.8082 203.013 83.0274 203.013Z"
                      fill="url(#pattern1_4934_6874)"
                      stroke="url(#paint1_linear_4934_6874)"
                    />
                    <path
                      d="M173.856 150.585H122.475C120.689 150.585 119.038 149.633 118.145 148.086L92.8578 104.293C91.9644 102.746 91.9644 100.84 92.8578 99.2924L118.145 55.4997C119.038 53.9529 120.689 53 122.475 53H173.856C175.643 53 177.293 53.9529 178.186 55.4997L203.474 99.2924C204.367 100.84 204.367 102.746 203.474 104.293L178.186 148.086C177.293 149.633 175.643 150.585 173.856 150.585Z"
                      fill="url(#pattern2_4934_6874)"
                      stroke="url(#paint2_linear_4934_6874)"
                    />
                    <defs>
                      <pattern
                        id="pattern0_4934_6874"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image0_4934_6874"
                          transform="matrix(0.000281842 0 0 0.000329815 -0.0772127 0)"
                        />
                      </pattern>
                      <pattern
                        id="pattern1_4934_6874"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image1_4934_6874"
                          transform="matrix(0.000509165 0 0 0.000596319 0 -0.231982)"
                        />
                      </pattern>
                      <pattern
                        id="pattern2_4934_6874"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image2_4934_6874"
                          transform="matrix(0.000314814 0 0 0.000366166 -0.144738 0)"
                        />
                      </pattern>
                      <linearGradient
                        id="paint0_linear_4934_6874"
                        x1="40.021"
                        y1="1"
                        x2="40.021"
                        y2="98.5854"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_4934_6874"
                        x1="40.021"
                        y1="105.508"
                        x2="40.021"
                        y2="203.013"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_4934_6874"
                        x1="131.138"
                        y1="53"
                        x2="131.138"
                        y2="150.585"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <image
                        id="image0_4934_6874"
                        width="4096"
                        height="3032"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={event?.event?.event_media[0]?.file_name}
                      />
                      <image
                        id="image1_4934_6874"
                        width="1964"
                        height="2455"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={event?.event?.event_media[1]?.file_name}
                      />
                      <image
                        id="image2_4934_6874"
                        width="4096"
                        height="2731"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={event?.event?.event_media[2]?.file_name}
                      />
                    </defs>
                  </svg>
                )}
                {event?.event?.event_media?.length == 2 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    // height="152"
                    viewBox="0 0 206 152"
                    fill="none"
                  >
                    <path
                      d="M83.1668 98.5854H31.4195C29.638 98.5854 27.9911 97.6375 27.0962 96.0971L1.65449 52.3044C0.752305 50.7514 0.752306 48.8339 1.65449 47.281L27.0962 3.48832C27.9911 1.9479 29.638 1 31.4195 1H83.1668C84.9483 1 86.5952 1.9479 87.4902 3.48831L112.932 47.281C113.834 48.8339 113.834 50.7514 112.932 52.3044L87.4902 96.0971C86.5952 97.6375 84.9483 98.5854 83.1668 98.5854Z"
                      fill="url(#pattern0_4934_6870)"
                      stroke="url(#paint0_linear_4934_6870)"
                    />
                    <path
                      d="M173.997 150.585H122.616C120.83 150.585 119.179 149.633 118.286 148.086L92.9984 104.293C92.1051 102.746 92.1051 100.84 92.9984 99.2924L118.286 55.4997C119.179 53.9529 120.83 53 122.616 53H173.997C175.783 53 177.434 53.9529 178.327 55.4997L203.615 99.2924C204.508 100.84 204.508 102.746 203.615 104.293L178.327 148.086C177.434 149.633 175.783 150.585 173.997 150.585Z"
                      fill="url(#pattern1_4934_6870)"
                      stroke="url(#paint1_linear_4934_6870)"
                    />
                    <defs>
                      <pattern
                        id="pattern0_4934_6870"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image0_4934_6870"
                          transform="matrix(0.000281842 0 0 0.000329815 -0.0772127 0)"
                        />
                      </pattern>
                      <pattern
                        id="pattern1_4934_6870"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image1_4934_6870"
                          transform="matrix(0.000314814 0 0 0.000366166 -0.144738 0)"
                        />
                      </pattern>
                      <linearGradient
                        id="paint0_linear_4934_6870"
                        x1="40.1616"
                        y1="1"
                        x2="40.1616"
                        y2="98.5854"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_4934_6870"
                        x1="131.279"
                        y1="53"
                        x2="131.279"
                        y2="150.585"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <image
                        id="image0_4934_6870"
                        width="4096"
                        height="3032"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={event?.event?.event_media[0]?.file_name}
                      />
                      <image
                        id="image1_4934_6870"
                        width="4096"
                        height="2731"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={event?.event?.event_media[1]?.file_name}
                      />
                    </defs>
                  </svg>
                )}

                {event?.event?.event_media?.length == 1 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    viewBox="0 0 114 99"
                    fill="none"
                  >
                    <path
                      d="M82.9727 98.5056H31.223C29.4421 98.5056 27.7957 97.5583 26.9006 96.0188L1.46127 52.266C0.55789 50.7123 0.55789 48.7933 1.46127 47.2396L26.9006 3.48678C27.7957 1.94723 29.4421 1 31.223 1H82.9727C84.7535 1 86.4 1.94723 87.2951 3.48678L112.734 47.2396C113.638 48.7933 113.638 50.7123 112.734 52.266L87.2951 96.0188C86.4 97.5583 84.7535 98.5056 82.9727 98.5056Z"
                      fill="url(#pattern0_4934_6869)"
                      stroke="url(#paint0_linear_4934_6869)"
                    />
                    <defs>
                      <pattern
                        id="pattern0_4934_6869"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image0_4934_6869"
                          transform="matrix(0.000509165 0 0 0.000596319 0 -0.231982)"
                        />
                      </pattern>
                      <linearGradient
                        id="paint0_linear_4934_6869"
                        x1="39.9663"
                        y1="1"
                        x2="39.9663"
                        y2="98.5055"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <image
                        id="image0_4934_6869"
                        width="1964"
                        height="2455"
                        xlinkHref={event?.event?.event_media[0]?.file_name}
                        preserveAspectRatio="xMidYMid slice"
                      />
                    </defs>
                  </svg>
                )}
              </div>
            ) : (
                <div className="col-12 col-md-5 p-2">
                {userDetails?.details?.provider_banner_images?.length > 2 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    viewBox="0 0 205 204"
                    fill="none"
                  >
                    <path
                      d="M83.0262 98.5854H31.2789C29.4974 98.5854 27.8504 97.6375 26.9555 96.0971L1.51387 52.3044C0.61168 50.7514 0.611681 48.8339 1.51387 47.281L26.9555 3.48832C27.8504 1.9479 29.4974 1 31.2789 1H83.0262C84.8077 1 86.4546 1.9479 87.3495 3.48831L112.791 47.281C113.693 48.8339 113.693 50.7514 112.791 52.3044L87.3495 96.0971C86.4546 97.6375 84.8077 98.5854 83.0262 98.5854Z"
                      fill="url(#pattern0_4934_6874)"
                      stroke="url(#paint0_linear_4934_6874)"
                    />
                    <path
                      d="M83.0274 203.013H31.2777C29.4968 203.013 27.8504 202.066 26.9552 200.527L1.51596 156.774C0.612578 155.22 0.612578 153.301 1.51596 151.747L26.9552 107.995C27.8504 106.455 29.4968 105.508 31.2777 105.508H83.0273C84.8082 105.508 86.4547 106.455 87.3498 107.995L112.789 151.747C113.692 153.301 113.692 155.22 112.789 156.774L87.3498 200.527C86.4547 202.066 84.8082 203.013 83.0274 203.013Z"
                      fill="url(#pattern1_4934_6874)"
                      stroke="url(#paint1_linear_4934_6874)"
                    />
                    <path
                      d="M173.856 150.585H122.475C120.689 150.585 119.038 149.633 118.145 148.086L92.8578 104.293C91.9644 102.746 91.9644 100.84 92.8578 99.2924L118.145 55.4997C119.038 53.9529 120.689 53 122.475 53H173.856C175.643 53 177.293 53.9529 178.186 55.4997L203.474 99.2924C204.367 100.84 204.367 102.746 203.474 104.293L178.186 148.086C177.293 149.633 175.643 150.585 173.856 150.585Z"
                      fill="url(#pattern2_4934_6874)"
                      stroke="url(#paint2_linear_4934_6874)"
                    />
                    <defs>
                      <pattern
                        id="pattern0_4934_6874"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image0_4934_6874"
                          transform="matrix(0.000281842 0 0 0.000329815 -0.0772127 0)"
                        />
                      </pattern>
                      <pattern
                        id="pattern1_4934_6874"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image1_4934_6874"
                          transform="matrix(0.000509165 0 0 0.000596319 0 -0.231982)"
                        />
                      </pattern>
                      <pattern
                        id="pattern2_4934_6874"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image2_4934_6874"
                          transform="matrix(0.000314814 0 0 0.000366166 -0.144738 0)"
                        />
                      </pattern>
                      <linearGradient
                        id="paint0_linear_4934_6874"
                        x1="40.021"
                        y1="1"
                        x2="40.021"
                        y2="98.5854"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_4934_6874"
                        x1="40.021"
                        y1="105.508"
                        x2="40.021"
                        y2="203.013"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_4934_6874"
                        x1="131.138"
                        y1="53"
                        x2="131.138"
                        y2="150.585"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <image
                        id="image0_4934_6874"
                        width="4096"
                        height="3032"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={userDetails?.details?.provider_banner_images[0]?.file_name}
                      />
                      <image
                        id="image1_4934_6874"
                        width="1964"
                        height="2455"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={userDetails?.details?.provider_banner_images[1]?.file_name}
                      />
                      <image
                        id="image2_4934_6874"
                        width="4096"
                        height="2731"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={userDetails?.details?.provider_banner_images[2]?.file_name}
                      />
                    </defs>
                  </svg>
                )}
                {userDetails?.details?.provider_banner_images?.length == 2 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="206"
                    height="152"
                    viewBox="0 0 206 152"
                    fill="none"
                  >
                    <path
                      d="M83.1668 98.5854H31.4195C29.638 98.5854 27.9911 97.6375 27.0962 96.0971L1.65449 52.3044C0.752305 50.7514 0.752306 48.8339 1.65449 47.281L27.0962 3.48832C27.9911 1.9479 29.638 1 31.4195 1H83.1668C84.9483 1 86.5952 1.9479 87.4902 3.48831L112.932 47.281C113.834 48.8339 113.834 50.7514 112.932 52.3044L87.4902 96.0971C86.5952 97.6375 84.9483 98.5854 83.1668 98.5854Z"
                      fill="url(#pattern0_4934_6870)"
                      stroke="url(#paint0_linear_4934_6870)"
                    />
                    <path
                      d="M173.997 150.585H122.616C120.83 150.585 119.179 149.633 118.286 148.086L92.9984 104.293C92.1051 102.746 92.1051 100.84 92.9984 99.2924L118.286 55.4997C119.179 53.9529 120.83 53 122.616 53H173.997C175.783 53 177.434 53.9529 178.327 55.4997L203.615 99.2924C204.508 100.84 204.508 102.746 203.615 104.293L178.327 148.086C177.434 149.633 175.783 150.585 173.997 150.585Z"
                      fill="url(#pattern1_4934_6870)"
                      stroke="url(#paint1_linear_4934_6870)"
                    />
                    <defs>
                      <pattern
                        id="pattern0_4934_6870"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image0_4934_6870"
                          transform="matrix(0.000281842 0 0 0.000329815 -0.0772127 0)"
                        />
                      </pattern>
                      <pattern
                        id="pattern1_4934_6870"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image1_4934_6870"
                          transform="matrix(0.000314814 0 0 0.000366166 -0.144738 0)"
                        />
                      </pattern>
                      <linearGradient
                        id="paint0_linear_4934_6870"
                        x1="40.1616"
                        y1="1"
                        x2="40.1616"
                        y2="98.5854"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_4934_6870"
                        x1="131.279"
                        y1="53"
                        x2="131.279"
                        y2="150.585"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <image
                        id="image0_4934_6870"
                        width="4096"
                        height="3032"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={userDetails?.details?.provider_banner_images[0]?.file_name}
                      />
                      <image
                        id="image1_4934_6870"
                        width="4096"
                        height="2731"
                        preserveAspectRatio="xMidYMid slice"
                        xlinkHref={userDetails?.details?.provider_banner_images[1]?.file_name}
                      />
                    </defs>
                  </svg>
                )}

                {userDetails?.details?.provider_banner_images?.length == 1 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="114"
                    height="99"
                    viewBox="0 0 114 99"
                    fill="none"
                  >
                    <path
                      d="M82.9727 98.5056H31.223C29.4421 98.5056 27.7957 97.5583 26.9006 96.0188L1.46127 52.266C0.55789 50.7123 0.55789 48.7933 1.46127 47.2396L26.9006 3.48678C27.7957 1.94723 29.4421 1 31.223 1H82.9727C84.7535 1 86.4 1.94723 87.2951 3.48678L112.734 47.2396C113.638 48.7933 113.638 50.7123 112.734 52.266L87.2951 96.0188C86.4 97.5583 84.7535 98.5056 82.9727 98.5056Z"
                      fill="url(#pattern0_4934_6869)"
                      stroke="url(#paint0_linear_4934_6869)"
                    />
                    <defs>
                      <pattern
                        id="pattern0_4934_6869"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlinkHref="#image0_4934_6869"
                          transform="matrix(0.000509165 0 0 0.000596319 0 -0.231982)"
                        />
                      </pattern>
                      <linearGradient
                        id="paint0_linear_4934_6869"
                        x1="39.9663"
                        y1="1"
                        x2="39.9663"
                        y2="98.5055"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5CCBEA" />
                        <stop offset="1" stop-color="#EFCE96" />
                      </linearGradient>
                      <image
                        id="image0_4934_6869"
                        width="1964"
                        height="2455"
                        xlinkHref={userDetails?.details?.provider_banner_images[0]?.file_name}
                        preserveAspectRatio="xMidYMid slice"
                      />
                    </defs>
                  </svg>
                )}
              </div>
            )}
            <div className="col-12 col-md-7 p-2" >
              <div
                className="mt-2"
                style={{ color: "#FFC107", textAlign: "center" }}
              >
                <p
                  className="m-0 mb-1"
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#585858",
                  }}
                >
                  How was it?
                </p>
                <Rate
                  allowClear={false}
                  style={{ transform: "scale(1.3)" }}
                  value={value?.rate}
                  onChange={(e) => {
                    setValue({ ...value, rate: e });
                  }}
                />
              </div>
              <p
                style={{ textAlign: "center", color: "red", fontSize: "15px" }}
              >
                {validation}
              </p>
              <div className="mt-3">
                <p
                  className="m-0 mb-1"
                  style={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                >
                  Leave a public message?{" "}
                  <span style={{ fontSize: "12px", color: "#6F6F6F" }}>
                    (Optional)
                  </span>
                </p>
                <textarea
                  placeholder="Write here your message..."
                  style={{
                    border: "1.5px dashed rgba(0, 0, 0, 0.30)",
                    width: "100%",
                    borderRadius: "8px",
                    padding: "10px",
                    fontSize: "16px",
                    height: "100px",
                  }}
                  value={value?.discription}
                  onChange={(e) => {
                    setValue({ ...value, discription: e.target.value });
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="massageBtn mt-3"
                  style={{ maxWidth: "346px", height: "45px" }}
                  onClick={() => {
                    if (value.rate <= 0 || value.rate == 0) {
                      setValidation("please give the review");
                    } else {
                      setValidation("");
                      setValue({
                        rate: 0,
                        discription: "",
                      });
                      setOpen((pre) => ({ ...pre, review: false }));
                      dispatch(
                        addRating({
                          ...value,
                          providerId: providerId,
                          userId: userId,
                          center,
                          isEvent,
                        })
                      );
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReviewPopUp;
