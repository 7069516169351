// import {initializeApp} from "firebase/app";
// import {firebase} from 'firebase/app';
//
// const firebaseConfig = {
//     apiKey: "AIzaSyCWuqSWmLuhR37tUjvKU4FbEz7rFUZwXSk",
//     authDomain: "ad-anima-de6d7.firebaseapp.com",
//     projectId: "ad-anima-de6d7",
//     storageBucket: "ad-anima-de6d7.appspot.com",
//     messagingSenderId: "1036627568034",
//     appId: "1:1036627568034:web:9baa7cbcb417eacdbdbefb",
//     measurementId: "G-FZRLJNFNNX",
// };
//
// export const app = initializeApp(firebaseConfig);



import { initializeApp } from 'firebase/app';

// import 'firebase/auth'; // If you are using Firebase authentication
// import 'firebase/dynamic-links';

const firebaseConfig = {
    apiKey: 'AIzaSyCWuqSWmLuhR37tUjvKU4FbEz7rFUZwXSk',
    authDomain: 'ad-anima-de6d7.firebaseapp.com',
    projectId: 'ad-anima-de6d7',
    storageBucket: 'ad-anima-de6d7.appspot.com',
    messagingSenderId: '1036627568034',
    appId: '1:1036627568034:web:9baa7cbcb417eacdbdbefb',
};

export const app = initializeApp(firebaseConfig);
