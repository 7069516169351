import React, {useEffect} from 'react';
import img1 from "../assets/images/1.jpg"
import Header from "../Component/Header";
import {Grid, IconButton} from "@mui/material";
import locationIcon from "../assets/images/Discovery.svg"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import unsave from "../assets/images/saveIcon.svg"
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getGuestUpcominEvents, getUpcominEvents, saveUnSaveEvent} from "../action/upcomingEventConstant";
import moment from "moment";
import EmptyFile from "../Component/EmptyFile";
import saveIcon from "../assets/images/save.svg"
import GuestHeader from "../Component/GuestHeader";
import {DateFormate, LocalFormate, timeZoneFormate} from "../Utils/DateFormateUtils";


const UpcomingEvent = () => {
    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    const {upComingEvents} = useSelector((state) => state)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let {providerId} = useParams();

    const getEventDitails = () => {
        if (!userAuth?.token) {
            dispatch(getGuestUpcominEvents(providerId))
        } else {
            dispatch(getUpcominEvents(providerId))
        }
    }


    useEffect(() => {
        getEventDitails()
    }, [providerId]);

    const savedUnsaved = (id, status) => {
        dispatch(saveUnSaveEvent({id: id, status: status}))
        setTimeout(() => {
            getEventDitails()
        }, 1000);
    }

    const handleNavigate = (id) => {
        navigate(`/view-events/${id}/${providerId}`)
    }


    return (
        <>
            <div>
                <section className="main-page">
                    {!userAuth?.token ? <GuestHeader/> : <Header select={"Home"}/>}
                    <div className="main-inner">
                        <div className="container">
                            {upComingEvents?.upComingEvents?.length ? <>
                                    <p className="t-30-bold">Upcoming Event</p>
                                    <Grid container spacing={2}>
                                        {
                                            upComingEvents?.upComingEvents?.map((ele, i) => (
                                                <Grid item xs={12} md={6} key={i}>
                                                    <div
                                                        className={`d-flex inner-gred box_shadow position-relative cursor-pointer`}
                                                        style={{cursor: 'pointer'}}>
                                                        <div
                                                            className={`d-flex`}
                                                            onClick={() => handleNavigate(ele._id)}
                                                        >
                                                            <div className="p_image_shape me-2 me-sm-3">
                                                                <img src={ele?.event_media[0]}/>
                                                            </div>
                                                            <div style={{flexGrow: "1"}}>
                                                                <div
                                                                    className="d-flex justify-content-between align-items-center">
                                                                    <p className="t-16-bold m-0 mb-1">{ele?.event_name}</p>
                                                                </div>
                                                                <div className="d-flex flex-sm-row flex-column align-items-start align-items-sm-center mb-1">
                                                                    {/*<p className="pro_carousel_time m-0">{moment(LocalFormate(ele?.start_date, ele?.date_timezone)).format('MMM Do') !== moment(LocalFormate(ele?.end_date)).format('MMM Do') ? (`${moment(LocalFormate(ele?.start_date)).format('MMM Do')} - ${moment(LocalFormate(ele?.end_date)).format('MMM Do, YYYY')}`) : (`${moment(LocalFormate(ele?.start_date)).format('MMMM Do YYYY')}`)}</p>*/}
                                                                    <p className="pro_carousel_time m-0">{DateFormate(LocalFormate(ele?.start_date, ele?.date_timezone), LocalFormate(ele?.end_date, ele?.date_timezone))}</p>
                                                                    <FiberManualRecordIcon className="d-none d-sm-block"
                                                                        style={{transform: "scale(0.3)"}}/>
                                                                    <p className="pro_carousel_time m-0">{moment(LocalFormate(ele?.start_time, ele?.date_timezone)).format('LT')} to {moment(LocalFormate(ele?.end_time, ele?.date_timezone)).format('LT')} ({timeZoneFormate(ele?.start_time, ele?.date_timezone)})</p>
                                                                </div>
                                                                {ele?.is_virtual_event ? <p className="t-14-bold">Type: Virtual</p> : <div className="d-flex align-items-center gap-1">
                                                                    <img src={locationIcon} alt=""/>
                                                                    <p className="yelloe-t-bold m-0">{ele?.address}</p>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        {userAuth?.token && <div className="position-absolute" style={{
                                                            right: "10px",
                                                            top: "10px",
                                                        }}>
                                                            <IconButton style={{padding: "3px"}} onClick={() => {
                                                                if (ele.is_save) {
                                                                    savedUnsaved(ele?._id, false)
                                                                } else {
                                                                    savedUnsaved(ele?._id, true)
                                                                }
                                                            }}>
                                                                <img src={ele.is_save ? saveIcon : unsave} alt=""
                                                                     width="28px"/>
                                                            </IconButton>
                                                        </div>}
                                                    </div>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </> :
                                <EmptyFile/>}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default UpcomingEvent;