import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Component/Header";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  APP_VERSION,
  BASE_URL,
  GOOGLE_API_KEY,
  URL_PATH,
} from "../Configration/configration";
import { Tooltip } from "react-bootstrap";
import Footer from "../Component/Footer";
import HomeMap from "../Component/HomeMap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMoreGuestService,
  fetchMoreService,
  getGuestUserService,
  getUserService,
  saveClaimService,
  saveHomeEvent,
  saveService,
} from "../action/userServiceConstant";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { loaderConstant, serviceConstant } from "../action/constant";
import { getAllByPlaceholderText } from "@testing-library/react";
import { Empty, Rate } from "antd";
import { StarOutlined } from "@material-ui/icons";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import noImg from "../assets/images/noImg.jpg";
import GuestHeader from "../Component/GuestHeader";
import { getservice } from "../action/serviceConstant";
import logo from "../assets/images/ad-anima_logo.png";
import Joyride from "react-joyride";
import { UpdateVarsion } from "../action/versionConstant";
import placeImg from "../assets/images/userIconWithBg.png";
import imgLoader from "../assets/images/imgLoader.gif";
import imgErrorIcon from "../assets/images/alartIcon.jpg";
import HomeCard from "../Component/HomeCard";
import { saveUnSaveEvent } from "../action/upcomingEventConstant";
import Loader from "../Component/Loader";

const Home = () => {
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const selector = useSelector((state) => state);

  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const [descName, setDescName] = useState("");
  const firstTimeLogin = localStorage.getItem("firstTime");
  const [isLoader, setLoader] = useState(false);
  const [loaderCount, setLoaderCount] = useState(0);
  const [isCurruntLocations, setCurruntLocations] = useState(true);

  const [comeFirstTime, setComeFirstTime] = useState(true);
  const [hasMore, sethasMore] = useState(true);

  // const [serviceList, setserviceList] = useState([]);

  // const onLoad = React.useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new google.maps.LatLngBounds();
  //   markers.forEach(({ position }) => bounds.extend(position));
  //   map.fitBounds(bounds);
  // })

  const [center, setCenter] = useState();
  const selectedLatLong = JSON.parse(localStorage.getItem("latlong"));

  // useEffect(() => {
  //     setCenter(selectedLatLong)
  // }, [selectedLatLong]);

  const [registration, setregistration] = useState({
    provider_services: [],
    profile_picture: "",
    provider_introduction: "",
    website_links: [""],
    provider_banner_images: [],
    delete_banner_image_ids: [],
    is_other_desc: false,
    other_desc: "",
  });
  const [serviceOpen, setserviceOpen] = useState(false);
  const [providerSearch, setProviderSearch] = useState("");
  const searchingData = localStorage.getItem("place");
  const [location, setlocation] = useState("");

  const [userServiceQuery, setuserServiceQuery] = useState({
    page: 2,
    limit: 10,
    latLong: selectedLatLong,
  });

  const [firstTime, setfirstTime] = useState(true);

  const [latlongGetted, setlatlongGetted] = useState(false);

  useEffect(() => {
    if ("geolocation" in navigator) {
      //check geolocation available
      //try to get user current location using getCurrentPosition() method
      navigator?.geolocation?.getCurrentPosition(async (position) => {
        // console.log(
        //     "Found your location \nLat : " +
        //     position?.coords?.latitude +
        //     " \nLang :" +
        //     position?.coords?.longitude
        // );
        await setCenter({
          lat:
            selector?.service?.selectedLocation?.latLong?.lat ||
            position?.coords?.latitude,
          lng:
            selector?.service?.selectedLocation?.latLong?.lng ||
            position?.coords?.longitude,
        });
        if (!selectedLatLong) {
          localStorage.setItem(
            "latlong",
            JSON.stringify({
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            })
          );
        }
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position?.coords?.latitude}&lon=${position?.coords?.longitude}`
          );

          if (response.ok) {
            const data = await response.json();
            if (searchingData?.length) {
              setlocation(data.display_name);
              // localStorage.setItem("place", searchingData);
            } else {
              localStorage.setItem("place", data.display_name);
              setlocation(selector?.service?.selectedLocation?.place);
            }
            return data.display_name;
          } else {
            console.error("Failed to retrieve address");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      });
      setlatlongGetted(true);
    } else {
      console.log("Browser doesn't support geolocation!");
    }
  }, []);

  useEffect(() => {
    return () => {
      // setLoaderCount(0)
      // setLoader(true)
    };
  }, []);

  useEffect(() => {
    if (latlongGetted) {
      setLoader(true);
      setLoaderCount(loaderCount + 1);
      // if (serviceOpen) {
      if (!userAuth?.token) {
        dispatch(
          getGuestUserService(
            {
              service_id: registration.provider_services,
              ...userServiceQuery,
              page: 1,
              latLong: selectedLatLong,
              hasMore: () => sethasMore(false),
              service_name: providerSearch,
              miles_distance: selector?.version?.radius,
              setuserServiceQuery: setuserServiceQuery,
              isCurruntLocations: isCurruntLocations,
            },
            () => {
              setTimeout(() => {
                setLoader(false);
              }, 500);
            }
          )
        );
      } else {
        dispatch(
          getUserService(
            {
              service_id: registration.provider_services,
              ...userServiceQuery,
              page: 1,
              latLong: selectedLatLong,
              hasMore: () => sethasMore(false),
              service_name: providerSearch,
              miles_distance: selector?.version?.radius,
              setuserServiceQuery: setuserServiceQuery,
              isCurruntLocations: isCurruntLocations,
            },
            () => {
              setTimeout(() => {
                setLoader(false);
              }, 500);
            }
          )
        );
      }
      setuserServiceQuery({ ...userServiceQuery, page: 2 });
      sethasMore(true);
      // }
    }
    // }, []);
  }, [
    // serviceOpen,
    registration.provider_services,
    center,
    selector?.service?.selectedLocation,
    providerSearch,
    latlongGetted,
    selector?.version?.radius,
  ]);

  const fetchmoreData = () => {
    if (!userAuth?.token) {
      dispatch(
        fetchMoreGuestService({
          service_id: registration.provider_services,
          ...userServiceQuery,
          latLong: selectedLatLong,
          hasMore: () => sethasMore(false),
          service_name: providerSearch,
          miles_distance: selector?.version?.radius,
          setuserServiceQuery: setuserServiceQuery,
          isCurruntLocations: isCurruntLocations,
        })
      );
    } else {
      dispatch(
        fetchMoreService({
          service_id: registration.provider_services,
          ...userServiceQuery,
          latLong: selectedLatLong,
          hasMore: () => sethasMore(false),
          service_name: providerSearch,
          miles_distance: selector?.version?.radius,
          setuserServiceQuery: setuserServiceQuery,
          isCurruntLocations: isCurruntLocations,
        })
      );
    }

    // setuserServiceQuery({
    //     ...userServiceQuery,
    //     page: userServiceQuery?.page + 1,
    // });
  };
  const serviceList = useSelector((state) => state.service.serviceList);

  const userServiceList = useSelector((state) => state.userServiceList);

  // const dateFormats= () => {
  //     const date = new Date();
  //
  //     console.log(date, "new date")
  //     console.log(date?.toUTCString(), "toUTCString")
  //     console.log(date?.getUTCDate(), "getUTCDate")
  //     console.log(date?.toLocaleString(), "toLocaleString")
  //     console.log(date?.toISOString(), "toISOString")
  //     // console.log(date?., "toISOString")
  // }
  // useEffect(() => {
  //     dateFormats()
  // }, []);

  const saveProvider = (id, status) => {
    dispatch(saveService({ id: id, status: status }));
  };

  const saveEvent = (id, status) => {
    dispatch(saveHomeEvent({ id: id, status: status }));
  };

  const saveClaimProvider = (id, status) => {
    dispatch(saveClaimService({ id: id, status: status }));
  };

  const DescFilter = () => {
    const filterMap = serviceList?.filter((e) => {
      if (
        e?.description_name
          ?.toLocaleLowerCase()
          ?.includes(descName?.toLocaleLowerCase())
      ) {
        return e;
      }
    });
    return filterMap;
  };

  const handleSelectService = () => {
    if (registration.provider_services?.length) {
      dispatch({
        type: serviceConstant.SELECTED_USER_SERVICE,
        payload: registration.provider_services,
      });
    }
  };

  useEffect(() => {
    return () => {
      localStorage.setItem("firstTime", "1");
      // Check if the value changes
    };
  }, []);

  //   const selectedLocation = () => {
  //             if (location) {
  //                 const payload = {
  //                     place: location,
  //                     latLong: center
  //                 }
  //                 dispatch({
  //             type: serviceConstant.SELECTED_USER_LOCATION,
  //             payload: payload,
  //         });
  //     }
  // }

  const selectedLocation = () => {
    // if (location) {

    const payload = {
      place: location,
      latLong: center,
    };
    localStorage.setItem("location", JSON.stringify(payload));
    dispatch({
      type: serviceConstant.SELECTED_USER_LOCATION,
      payload: payload,
    });
    // }
  };

  useEffect(() => {
    return () => {
      selectedLocation(); // Executes when the component is about to unmount
    };
  }, []);

  useEffect(() => {
    dispatch(getservice());
  }, []);

  // useEffect(() => {
  //     selectedLocation()
  // }, [location])

  useEffect(() => {
    setlocation(selector?.service?.selectedLocation?.place);
    setCenter(selector?.service?.selectedLocation?.latLong);
  }, [selector?.service?.selectedLocation]);
  // useEffect(() => {
  //     setlocation(selector?.service?.selectedLocation?.place)
  //     setCenter(selector?.service?.selectedLocation?.latLong)
  // }, []);

  useEffect(() => {
    handleSelectService();
  }, [registration]);

  useEffect(() => {
    setregistration((pre) => ({
      ...pre,
      provider_services: selector?.service?.selectedService,
    }));
  }, [selector?.service?.selectedService]);

  const [{ run, steps }] = useState({
    run: true,
    steps: [
      {
        content: (
          <h2
            className="t-18-bold m-0"
            style={{
              textAlign: "center",
              padding: "18px",
              wordBreak: "break-word",
            }}
          >
            🎉 Congratulations on joining our innovative platform! Get ready to
            turn your expertise into an unforgettable experience.
          </h2>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        content: (
          <div className="p-0">
            <p
              className="t-10-bold m-0"
              style={{ color: "rgba(54, 56, 83, 0.50)", textAlign: "start" }}
            >
              STEP 1
            </p>
            <h2
              className="t-13-bold m-0"
              style={{ textAlign: "start", wordBreak: "break-word" }}
            >
              RESET PASSWORD
            </h2>
          </div>
        ),
        placement: "bottom",
        target: ".headerDropDown",
      },
      {
        content: (
          <div className="p-0">
            <p
              className="t-10-bold m-0"
              style={{ color: "rgba(54, 56, 83, 0.50)", textAlign: "start" }}
            >
              STEP 2
            </p>
            <h2
              className="t-13-bold m-0"
              style={{ textAlign: "start", wordBreak: "break-word" }}
            >
              Add services and details to your practice
            </h2>
          </div>
        ),
        placement: "bottom",
        target: ".mypagepopup",
      },
      {
        content: (
          <div className="p-0">
            <p
              className="t-10-bold m-0"
              style={{ color: "rgba(54, 56, 83, 0.50)", textAlign: "start" }}
            >
              STEP 3
            </p>
            <h2
              className="t-13-bold m-0"
              style={{ textAlign: "start", wordBreak: "break-word" }}
            >
              Set your schedule
            </h2>
          </div>
        ),
        placement: "bottom",
        target: ".myscedulePopup",
      },
    ],
  });

  const updatedSteps = steps.map((step, index) => {
    if (index === 0 && step.target === "body") {
      return {
        ...step,
        styles: {
          options: {
            // Apply specific styles for the first step targeting "body"
            // Modify as per your requirements
            // arrowColor: '#e3ffeb',
            // backgroundColor: '#e3ffeb',
            // overlayColor: 'rgba(79, 26, 0, 0.4)',
            primaryColor: "#000",
            // textColor: '#004a14',
            width: 500,
            height: 100,
            zIndex: 1000,
            padding: "5px",
          },
        },
      };
    } else {
      return {
        ...step,
        styles: {
          options: {
            // Apply default styles for other steps or targets
            // Modify as per your requirements
            primaryColor: "#000",
            width: 200,
            height: 100,
            zIndex: 1000,
            padding: "5px",
            // You can add more default styles here as needed
          },
        },
      };
    }
  });

  // ======== version =========

  // useEffect(() => {
  //     if (userAuth?.token && center && location) {
  //         const payload = {
  //             user_id: userAuth?._id,
  //             app_version: "1.0.0",
  //             app_platform: "web",
  //             device_token: userAuth?.token,
  //             center: {
  //                 type: "Point",
  //                 coordinates: [center?.lng, center?.lat],
  //             },
  //             address: location,
  //         };
  //
  //         dispatch(UpdateVarsion(payload));
  //     }
  // }, [center, location]);

  // useEffect(() => {
  //     const payload = {
  //         place: location,
  //         latLong: center,
  //     };
  //
  //     return () => {
  //         localStorage.setItem("place", JSON.stringify(payload));
  //     };
  // }, []);

  return (
    <>
      {/*========= Joyride =========*/}
      {/*{(isLoader && loaderCount < 2) && <Loader/>}*/}
      {userAuth?.token &&
        comeFirstTime &&
        firstTimeLogin == 0 &&
        userAuth?.is_admin_created &&
        userAuth?.login_counter <= 1 && (
          <Joyride
            steps={updatedSteps}
            continuous
            hideCloseButton
            run={run}
            scrollToFirstStep
            showProgress
            showSkipButton
            className="m-0 p-0"
          />
        )}

      <section className="main-page">
        {!userAuth?.token ? <GuestHeader /> : <Header select={"Home"} />}
        <div className="main-inner p-0">
          <div className="d-flex position-relative">
            <div
              className="col-12 col-lg-8 position-relative"
              style={{ height: "calc(100vh - 153px)" }}
            >
              {center ? (
                <>
                  <HomeMap
                    userAuth={userAuth}
                    serviceList={userServiceList}
                    center={selectedLatLong}
                  />
                </>
              ) : null}
            </div>
            <div
              className="col-lg-4 position-relative bg-white"
              style={{ height: "calc(100vh - 153px)" }}
            >
              <div className="w-100 p-2">
                <div className="rounded-2"></div>
              </div>
            </div>

            <div className="container position-absolute">
              <div className="row position-relative" style={{ height: "0" }}>
                <div
                  className="col-12 col-lg-8 row"
                  style={{
                    height: "0",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div className="col-12 col-lg-6 p-3 homepadding">
                    <div className="group-input">
                      <PlacesAutocomplete
                        value={searchingData}
                        onChange={(e) => {
                          setlocation(e);
                          localStorage.setItem("place", e);
                          if (e == "") {
                            if ("geolocation" in navigator) {
                              //check geolocation available
                              //try to get user current location using getCurrentPosition() method
                              navigator?.geolocation?.getCurrentPosition(
                                function (position) {
                                  // console.log(
                                  //     "Found your location \nLat : " +
                                  //     position?.coords?.latitude +
                                  //     " \nLang :" +
                                  //     position?.coords?.longitude
                                  // );
                                  if (!selectedLatLong) {
                                    localStorage.setItem(
                                      "latlong",
                                      JSON.stringify({
                                        lat: position?.coords?.latitude,
                                        lng: position?.coords?.longitude,
                                      })
                                    );
                                  }
                                  setCenter({
                                    lat: position?.coords?.latitude,
                                    lng: position?.coords?.longitude,
                                  });
                                }
                              );
                            } else {
                              console.log(
                                "Browser doesn't support geolocation!"
                              );
                            }
                          }
                        }}
                        onSelect={(address) => {
                          setCurruntLocations(false);
                          geocodeByAddress(address)
                            .then((results) => getLatLng(results[0]))
                            .then((latLng) => {
                              localStorage.setItem("place", address);
                              localStorage.setItem(
                                "latlong",
                                JSON.stringify({
                                  lat: latLng.lat,
                                  lng: latLng.lng,
                                })
                              );
                              setlocation(address);
                              setCenter({
                                lat: latLng.lat,
                                lng: latLng.lng,
                              });
                              setfirstTime(false);
                            })
                            .catch((error) => console.error("Error", error));
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M20 11.1755C20 15.6907 16.4183 21 12 21C7.58172 21 4 15.6907 4 11.1755C4 6.66029 7.58172 3 12 3C16.4183 3 20 6.66029 20 11.1755Z"
                                stroke="#363853"
                                strokeWidth="1.5"
                              />
                              <path
                                d="M9.5 10.5C9.5 9.11929 10.6193 8 12 8C13.3807 8 14.5 9.11929 14.5 10.5C14.5 11.8807 13.3807 13 12 13C10.6193 13 9.5 11.8807 9.5 10.5Z"
                                stroke="#363853"
                                strokeWidth="1.5"
                              />
                            </svg>
                            <input
                              type="text"
                              required
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "location-search-input",
                              })}
                              className="ps-5"
                              placeholder="Search Place Here..."
                            />
                            <div className="autocomplete-dropdown-container">
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6 p-3 homepadding"
                    style={{ zIndex: "12" }}
                  >
                    <div className="position-relative">
                      <div className="group-input position-relative">
                        <input
                          type="text"
                          name=""
                          placeholder={`${
                            registration.provider_services?.length
                              ? `${registration.provider_services.length} selected Services`
                              : "Select Description  Name"
                          } `}
                          className="select-servcesss map-select"
                          onChange={(e) => {
                            setProviderSearch(e.target.value);
                            // setserviceOpen(true)
                            // setDescName(e.target.value)
                          }}
                        />
                        <div
                          className="position-absolute "
                          onClick={() => setserviceOpen(!serviceOpen)}
                          style={{
                            zIndex: "100",
                            top: "0",
                            right: "52px",
                            cursor: "pointer",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M17 9.55029L12 14.5503L7 9.55029"
                              stroke="#363853"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        {/*// onClick={() => {*/}
                        {/*// setserviceOpen(!serviceOpen);*/}
                        {/*// setfirstTime(false)*/}
                        {/*}}*/}
                      </div>
                      <div
                        className={serviceOpen ? "list-open open" : "list-open"}
                        style={{ scrollBehavior: "smooth" }}
                      >
                        <input
                          type="text"
                          placeholder="Search..."
                          style={{
                            width: "100%",
                            marginTop: "10px",
                            borderRadius: "2px",
                            padding: "5px",
                          }}
                          onChange={(e) => {
                            // setserviceOpen(true)
                            setDescName(e.target.value);
                          }}
                          onClick={() => {
                            // setserviceOpen(!serviceOpen);
                          }}
                        />
                        {DescFilter()?.map((data) => {
                          return (
                            <>
                              <div className="group-input-check position-relative my-2">
                                <div className="">
                                  <label className="gred-checkbox">
                                    {data?.description_name}
                                    <input
                                      type="checkbox"
                                      checked={registration.provider_services.includes(
                                        data._id
                                      )}
                                      onClick={() => {
                                        let available = false;
                                        let newValue =
                                          registration?.provider_services?.filter(
                                            (value) => {
                                              if (
                                                registration?.provider_services.includes(
                                                  data._id
                                                )
                                              ) {
                                                available = true;
                                              }
                                              if (value !== data._id) {
                                                return value;
                                              }
                                            }
                                          );
                                        if (available) {
                                          setregistration({
                                            ...registration,
                                            provider_services: newValue,
                                          });
                                        } else {
                                          setregistration({
                                            ...registration,
                                            provider_services: [
                                              ...registration.provider_services,
                                              data._id,
                                            ],
                                          });
                                        }
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                {/* <h4 className="float-end">
                              {data?.admin_service_amount}
                            </h4> */}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-lg-4 position-lg-absolute position-relative p-0"
                  style={{
                    top: "0",
                    right: "0",
                    height: "calc(100vh - 153px)",
                    overflow: "hidden",
                    background: "white",
                  }}
                  id="scrollableDiv"
                >
                  <div
                    id="scrollableDivWrapper"
                    style={{
                      top: "0",
                      right: "0",
                      height: "calc(100vh - 143px)",
                      overflow: "auto",
                      background: "white",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={userServiceList}
                      next={fetchmoreData}
                      hasMore={hasMore}
                      scrollableTarget="scrollableDivWrapper"
                      className="infinity-list bg-white p-3 pb-5 pb-lg-3"
                    >
                      {userServiceList.map((data, index) => {
                        return (
                          <>
                            <HomeCard
                              data={data}
                              index={index}
                              userAuth={userAuth}
                              saveProvider={saveProvider}
                              saveEvent={saveEvent}
                              saveClaimProvider={saveClaimProvider}
                            />
                          </>
                        );
                      })}
                      {userServiceList?.length < 1 && (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>No Data Found</span>}
                        />
                      )}
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Home;
