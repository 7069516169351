import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {discountConstant, refDescountConstants} from "./constant";
import {toast} from "react-toastify";

export const checkDiscountCode = (payload, setLoader) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("discount_code_string", payload?.discount_code_string);
        fdata.append("provider_id", payload?.provider_id);
        if (payload?.is_event) {
            fdata.append("event_id", payload?.event_id);
        } else {
            fdata.append("service_id", payload?.service_id);
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.check_discount_code}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setLoader()
            dispatch({
                type: discountConstant?.CHECK_DISCOUNT_CODE,
                payload: response.data,
            });
        } else {
            setLoader()
            toast.error(response?.message);
        }
    };
};

export const discountCodeList = () => {
    return async (dispatch) => {
        let response;


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.all_discount_code}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            // body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: discountConstant.DISCOUNT_CODE_LISTING,
                payload: response.data,
            });

        } else {
            toast.error(response?.message);
        }
    };
};

export const getProviderEvent = () => {
    return async (dispatch) => {
        let response;


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.discount_provider_event_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            // body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: discountConstant.PROVIDER_EVENT_LIST,
                payload: response.data,
            });

        } else {
            toast.error(response?.message);
        }
    };
};

export const getProviderService = () => {
    return async (dispatch) => {
        let response;


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.discount_provider_service_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            // body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: discountConstant.PROVIDER_SERVICE_LIST,
                payload: response.data,
            });

        } else {
            toast.error(response?.message);
        }
    };
};

export const generateDiscount = (payload, getDiscountList, setLoader, setIsEdit, handleClearData, setOpenDiscountSucsess, setIsLinkPopup) => {
    return async (dispatch) => {
        let response;

        const fdata = new FormData();
        fdata.append("discount_percentage", payload?.discount_percentage);
        // fdata.append("is_user_limit_set", true);
        fdata.append("discount_type", payload?.discount_type);
        if (payload?.service_ids?.length > 0) {
            fdata.append("service_ids", JSON.stringify(payload?.service_ids));
        }
        if (payload?.event_ids?.length > 0) {
            fdata.append("event_ids", JSON.stringify(payload?.event_ids));
        }
        if (payload?.events_ids_details?.length > 0) {
            fdata.append("events_ids_details", JSON.stringify(payload?.events_ids_details));
        }
        fdata.append("is_set_expire_date", JSON.stringify(payload?.is_set_expire_date));
        if (payload?.expire_date != "" && payload?.expire_date != null) {
            fdata.append("expire_date", new Date(payload?.expire_date).toISOString());
        }
        if (Number(payload?.limit_user_count) > 0) {
            fdata.append("is_user_limit_set", true);
            fdata.append("limit_user_count", payload?.limit_user_count);
        } else {
            fdata.append("is_user_limit_set", false);
        }

        if(payload?.is_created_from_event){
            fdata.append("is_created_from_event", payload?.is_created_from_event);
        }

        if(payload?.note !== ""){
            fdata.append("notes", payload?.note);
        }

        if(payload?.discount_code !== "" && payload?.discount_code){
            fdata.append("discountCode", payload?.discount_code);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.create_discount_code}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            handleClearData()
            getDiscountList();
            setIsLinkPopup()
            setLoader(response?.data);
            setIsEdit()
            dispatch({
                type: discountConstant.ADD_DISCOUNT_CODE,
                payload: response.data,
            });
            setOpenDiscountSucsess(true)

        } else {
            setLoader()
            toast.error(response?.message);
        }
    };
};


export const editDiscount = (payload, getDiscountList, setLoader, setIsEdit, handleClearData, setOpenDiscountSucsess, setIsLinkPopup) => {
    return async (dispatch) => {
        let response;

        const fdata = new FormData();
        fdata.append("discount_percentage", payload?.discount_percentage);
        fdata.append("discount_code_id", payload?.discount_code_id);

        fdata.append("discount_type", payload?.discount_type);
        if (payload?.service_ids?.length > 0) {
            fdata.append("service_ids", JSON.stringify(payload?.service_ids));
        }
        if (payload?.event_ids?.length > 0) {
            fdata.append("event_ids", JSON.stringify(payload?.event_ids));
        }
        if (payload?.events_ids_details?.length > 0) {
            fdata.append("events_ids_details", JSON.stringify(payload?.events_ids_details));
        }
        fdata.append("is_set_expire_date", JSON.stringify(payload?.is_set_expire_date));
        if (payload?.expire_date != "" && payload?.expire_date != null) {
            fdata.append("expire_date", new Date(payload?.expire_date).toISOString());
        }
        if (Number(payload?.limit_user_count) > 0) {
            fdata.append("is_user_limit_set", true);
            fdata.append("limit_user_count", payload?.limit_user_count);
        } else {
            fdata.append("is_user_limit_set", false);
        }

        if (payload?.note !== "") {
            fdata.append("notes", payload?.note);
        }
        if (payload?.discount_code !== "") {
            fdata.append("discountCode", payload?.discount_code);
        }


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.edit_discount_code}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            if(handleClearData){

                handleClearData()
            }
            if(setIsLinkPopup){

                setIsLinkPopup()
            }
            getDiscountList();
            setLoader();
            setIsEdit();
            handleClearData();
            dispatch({
                type: discountConstant.EDIT_DISCOUNT_CODE,
                payload: response.data,
            });

            if(setOpenDiscountSucsess){

                setOpenDiscountSucsess(true)
            }

        } else {
            setLoader()
            toast.error(response?.message);
        }
    };
};

export const deleteDiscount = (id, getDiscountList, setOpenDetails) => {
    return async (dispatch) => {
        let response;

        const fdata = new FormData();
        fdata.append("discount_code_id", id);

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.delete_discount_code}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            getDiscountList();
            setOpenDetails();
            dispatch({
                type: discountConstant.DELETE_DISCOUNT_CODE,
                payload: response.data,
            });

        } else {
            toast.error(response?.message);
        }
    };
};