import React, {useEffect, useState} from 'react';
import GuestHeader from "../Component/GuestHeader";
import Header from "../Component/Header";
import logo from "../assets/images/ad-anima_logo.png";
import {Grid, IconButton} from "@mui/material";
import shareIocn from "../assets/images/shareIcon.svg";
import MapDiscoveryIcon from "../assets/images/mapDiscovery.svg";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {getGuestOtherProvider, getOtherProvider} from "../action/otherProviderConstant";
import {otherProviderConstant} from "../action/constant";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {claimProvider, claimProviderSMS} from "../action/claimConstant";
import Claim_ContactUs from "../Component/Claim_ContactUs";
import {toast} from "react-toastify";
import userIcon from "../assets/images/userIconWithBg.png"
// import Twilio from "twilio/lib/rest/Twilio";
// import {Twilio} from "twilio";
// import { sendSMS } from '../Component/TwilioService';

const Claim_profile = () => {
    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    const dispatch = useDispatch();
    const [isStatus, setIsStatus] = useState(false);
    const [isSms, setIsSms] = useState(false);
    const [isContact, setIsContact] = useState(false)
    const userDetails = useSelector((state) => state?.otherProviderDetails?.data);
    const {claim} = useSelector((state) => state)
    const claimJson = localStorage.getItem("claims");
    const [isSmsCall, setSmsCall] = useState(false)
    const ClaimData = (claimJson !== undefined && claimJson !== null) ? JSON.parse(claimJson) : {}
    const {id} = useParams();
    useEffect(() => {
        if (!userAuth?.token) {
            dispatch(getGuestOtherProvider(id));
        } else if (userAuth?._id) {
            dispatch(getOtherProvider(id));
        }

        return () => {
            dispatch({
                type: otherProviderConstant.REMOVE_OTHER_PROVIDER_DETAILS, payload: null,
            });
        };
    }, [id]);




    const handleSendLink = (status) => {
        if (status){
            setIsStatus(true)
        }else {
            setIsSms(true)
        }

        const payLoad = {
            claim_account_id: userDetails?.details?._id,
            claim_service_id: userDetails?.details?.find_user_service_details[0]?._id,
            profile_image: userDetails?.details?.profile_picture,
            status: status
        }
        dispatch(claimProvider(payLoad,
            () => {
            },
            (a) => {
                setIsStatus(a)
            },
            () => {
            },
            (a) => {
                setSmsCall(a)
            }));
    }


    const handleSendSMSLink = (status) => {
        if (status){
            setIsStatus(true)
        }else {
            setIsSms(true)
        }

        const payLoad = {
            claim_account_id: userDetails?.details?._id,
            claim_service_id: userDetails?.details?.find_user_service_details[0]?._id,
            profile_image: userDetails?.details?.profile_picture,
            status: status
        }
        dispatch(claimProviderSMS(payLoad,
            () => {
            },
            (a) => {
                setIsStatus(a)
            },
            () => {
            },
            (a) => {
                setSmsCall(a)
            }));
    }

    useEffect(() => {
        if (claim?.claimSms) {
            handleSendSMS()
        }
    }, [claim?.claimSms])

    const handleSendSMS = async () => {
        const accountSid = 'AC350f2256b7ac380ed8d8b09764a919f0'; // Your Twilio Account SID
        const authToken = '9c8fdd5bbd56483614c9d5b3b5f5a497'; // Your Twilio Auth Token
        const url = 'https://api.twilio.com/2010-04-01/Accounts/AC350f2256b7ac380ed8d8b09764a919f0/Messages.json';

        const body = new URLSearchParams({
            Body: `You received a message from Ad-Anima platform. \n\nLOGIN TO CLAIM THIS PROFILE\n\nLogin: ${claim?.claimSms?.emailAddress}\nPassword: ${claim?.claimSms?.Password}\nhttps://app.ad-anima.com/login\n\nChange your password in settings`,
            To: `+${claim?.claimSms?.country_code}${claim?.claimSms?.mobile_number}`,
            From: '+18446480071'
        }).toString();

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + btoa(`${accountSid}:${authToken}`),
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body
            }).then((data) => {
                if (data?.status == 201) {
                    setIsSms(false)
                    // console.log(data, 'data123')
                    toast.success("Message Sent Successfully");
                    setSmsCall(false)
                } else {
                    setIsSms(false)
                    setSmsCall(false)
                }
            });

            if (response.ok) {
                setSmsCall(false)
                const data = await response.json();
                // console.log('Message sent successfully:', data);
                // Handle successful response
            } else {
                setSmsCall(false)
                console.error('Failed to send message:', response.status, response.statusText);
                // Handle error
            }
        } catch (error) {
            setSmsCall(false)
            console.error('Error sending message:', error);
            // Handle network errors or other issues
        }
        setSmsCall(false)
    };

    // console.log(claim?.claimSms, "claim")


    return (
        <>
            <section className="main-page">
                {!userAuth?.token ? <GuestHeader/> : <Header select={"Home"}/>}

                <div className="main-inner" style={{height: "calc(100vh - 100px)"}}>
                    <div className="container position-relative" style={{height: '100%'}}>
                        <Grid container spacing={2}
                            // sx={{height: '100%'}}
                        >
                            <Grid item xs={12} md={6}>
                                <div className="border-gred">
                                    <div className="inner-gred d-flex align-items-center">
                                        <div className="p_image_shape me-2 me-sm-3"
                                            // onClick={() => handleOpenimg(userDetails?.details?.profile_picture)}
                                        >
                                            {userDetails?.details?.is_verified && (<>
                                                <img src="images/check.svg" className="blue_check"/>
                                            </>)}

                                            <img
                                                src={userDetails?.details?.is_admin_created ? (userDetails?.details?.profile_picture ? userDetails?.details?.profile_picture : logo) : userDetails?.details?.profile_picture} onError={(e) => (e.target.src = userIcon)}/>
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="mb-1" style={{fontSize: "25px"}}>
                                                    {userDetails?.details?.name}
                                                </h4>
                                            </div>
                                            <p className="mb-1" style={{fontSize: "15px"}}>
                                                {userDetails?.details?.is_ambassador && (<img
                                                    src="/images/ambassador.svg"
                                                    className="mb-1"
                                                    style={{height: "16px", marginLeft: "-5px"}}
                                                />)}{" "}
                                                Provider
                                            </p>
                                            <div
                                                className="d-block d-sm-flex justify-content-between align-items-center mt-1">
                                                <div className="d-flex align-items-end">
                                                    <div className="me-2 me-sm-2">
                                                        <img
                                                            src={MapDiscoveryIcon}
                                                            style={{width: "18px"}}
                                                        />
                                                    </div>
                                                    <p
                                                        className="map_des m-0 p-0"
                                                        style={{
                                                            fontSize: "14px", color: "#FFC107",
                                                        }}
                                                    >
                                                        {userDetails?.details?.address}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            {userDetails?.details?.provider_introduction ? <Grid item xs={12} md={6}>
                                <div className="border-gred">
                                    <div className="inner-gred d-flex align-items-center">
                                        <div className="w-100">
                                            <p className="t-18-bold mb-2">Description</p>
                                            <p className="t-14-bold"
                                               style={{color: '#767676'}}>{userDetails?.details?.provider_introduction?.trim()}</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid> : null}
                            <Grid item xs={12} sx={{marginTop: 'auto'}}>
                                <div className="d-flex flex-wrap align-items-center justify-content-center" style={{
                                    position: 'absolute',
                                    width: '100%',
                                    bottom: '0'
                                }}>
                                    {isStatus ? (<a
                                        href="#"
                                        style={{
                                            maxWidth: "350px", height: "45px"
                                        }}
                                        onClick={(e) => e.preventDefault()}
                                        className="massageBtn mt-3"
                                    >
                                        <div className="loader" style={{marginTop: '15px'}}></div>
                                    </a>) : (
                                        <button
                                            style={{
                                                maxWidth: "350px", height: "45px"
                                            }}
                                            className="massageBtn mt-3"
                                            onClick={() => handleSendLink(true)}
                                        >
                                            Resent Link
                                        </button>
                                    )}


                                    {ClaimData?.mobile_number && (isSms ? (<a
                                        href="#"
                                        style={{
                                            maxWidth: "350px", height: "45px"
                                        }}
                                        onClick={(e) => e.preventDefault()}
                                        className="massageBtn mt-3"
                                    >
                                        <div className="loader" style={{marginTop: '15px'}}></div>
                                    </a>) : (
                                        <button
                                            style={{
                                                maxWidth: "350px", height: "45px"
                                            }}
                                            className="massageBtn mt-3"
                                            // onClick={() => handleSendSMS()}
                                            onClick={() => handleSendSMSLink(false)}
                                        >
                                            Send Link to ***** ***{ClaimData?.mobile_number?.toString()?.slice(-2)}
                                        </button>
                                    ))}

                                    {/*{ClaimData?.mobile_number && <button*/}
                                    {/*    style={{*/}
                                    {/*        maxWidth: "350px", height: "45px"*/}
                                    {/*    }}*/}
                                    {/*    className="massageBtn mt-3"*/}
                                    {/*    onClick={() => handleSendSMS()}*/}
                                    {/*>*/}
                                    {/*    Send Link to ***** ***{ClaimData?.mobile_number?.toString()?.slice(-2)}*/}
                                    {/*</button>}*/}
                                    <button
                                        style={{
                                            maxWidth: "350px", height: "45px"
                                        }}
                                        className="massageBtn mt-3"
                                        onClick={() => setIsContact(true)}
                                    >
                                        Contact us
                                    </button>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            </section>
            <Claim_ContactUs open={isContact} setOpen={setIsContact} providerName={userDetails?.details?.name}
                             id={userDetails?.details?._id}/>
        </>
    )
}

export default Claim_profile;