import React from 'react';
import {googleMapApiKey} from "../Utils/GoogleMeet";
import locationIcon from "../assets/images/mapDiscovery.svg"

const GoogleMapIframe = ({lat, long, address}) => {
    const latitude = lat;
    const longitude = long;

    // Constructing the URL for embedding Google Maps
    // const mapUrl = `https://www.google.com/maps/embed/v1/view?key=${googleMapApiKey}&center=${latitude},${longitude}&zoom=15&markers=${latitude},${longitude}`;
    // const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs&q=Surat,Gujarat,India`;
    // const mapUrl = `https://www.google.com/maps/embed/v1/place?q=${latitude},${longitude}&zoom=15&marker=true`;
    // const mapUrl = `https://www.google.com/maps/embed/v1/view?key=${googleMapApiKey}&center=${latitude},${longitude}&zoom=15&markers=${latitude},${longitude}`;
    // const mapUrl = `https://www.google.com/maps/embed/v1/place?key=API_KEY&q=Latitude+Longitude`;
    const mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}&z=10&output=embed`;
    return (
        <>
            <div className="map-main-box mt-5">
                {/*<iframe*/}
                {/*    title="Map"*/}
                {/*    width="100%"*/}
                {/*    height="450"*/}
                {/*    loading="lazy"*/}
                {/*    allowFullScreen*/}
                {/*    src={mapUrl}*/}
                {/*></iframe>*/}

                {/*<iframe*/}
                {/*    width="100%"*/}
                {/*    height="450"*/}
                {/*    style={{border:"0"}}*/}
                {/*    loading="lazy"*/}
                {/*    allowFullScreen*/}
                {/*    // referrerPolicy="no-referrer-when-downgrade"*/}
                {/*    src={mapUrl}>*/}
                {/*</iframe>*/}


                <iframe src={mapUrl} width="100%"
                        height="450" frameBorder="0" style={{border:"0"}}></iframe>

                <div className="p-3 d-flex align-items-center gap-2 " style={{borderTop: "1px solid #D1D1D1"}}>
                    <img src={locationIcon} alt="" width="20px"/>
                    <p className="t-16-bold text-gray m-0">{address}</p>
                </div>
            </div>

        </>
    )
}

export default GoogleMapIframe;

