import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {hireNcollaborateConstant} from "./constant";
import {toast} from "react-toastify";

export const getUpcominEvents = (userId) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", JSON.parse(localStorage.getItem("AdAnima_auth"))?._id);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.upcoming_events_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.HIRE_N_COLLABORATE,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const acceptHireCollaNoti = (data, setOpen, getNotificationsList) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("notification_id", data?.notification_id);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.accept_hire_and_collaborate_noti}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.ACCEPT_HIRE_N_COLLABORATE,
                payload: response.data,
            });
            setOpen(false)
            getNotificationsList()
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};
export const calcleHireCollaNotiInEvent = (data, setOpen, getNotificationsList, setCancelLoader) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("hire_and_collaborate_id", data?.notification_id);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.cancel_hire_and_collaborate_noti}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.CANCLE_HIRE_N_COLLABORATE,
                payload: response.data,
            });
            setOpen(false)
            getNotificationsList()
            setCancelLoader(false)
            toast.success(response?.message);
        } else {
            setCancelLoader(false)
            toast.error(response?.message);
        }
    };
};
export const calcleHireCollaNoti = (data, setOpen, getNotificationsList, setCancelLoader) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("notification_id", data?.notification_id);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.cancel_hire_and_collaborate_noti}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.CANCLE_HIRE_N_COLLABORATE,
                payload: response.data,
            });
            setOpen(false)
            getNotificationsList()
            setCancelLoader(false)
            toast.success(response?.message);
        } else {
            setCancelLoader(false)
            toast.error(response?.message);
        }
    };
};
export const calcleHireCollaNotiModify = (data, setOpen, getNotificationsList) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("notification_id", data?.notification_id);
        fdata.append("hire_and_collaborate_id", data?.hire_and_collaborate_id);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.cancel_hire_collab_req}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.CANCLE_HIRE_N_COLLABORATE_MODIFY,
                payload: response.data,
            });
            setOpen(false)
            getNotificationsList()
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};

export const acceptHireCollaNotiModify = (data, setOpen, getNotificationsList) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("notification_id", data?.notification_id);
        fdata.append("hire_and_collaborate_id", data?.hire_and_collaborate_id);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.accept_hire_collab_req}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.ACCEPT_HIRE_N_COLLABORATE_MODIFY,
                payload: response.data,
            });
            setOpen(false)
            getNotificationsList()
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};

export const hireNcollaRequest = (data, setOpen, getNotificationsList) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("hire_and_collaborate_id", data?.hire_and_collaborate_id);
        if (data?.notification_id) {
            fdata.append("notification_id", data?.notification_id);
        }
        fdata.append("request_amount", data?.request_amount);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.modify_hire_collab_req}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.HIRE_N_COLLABORATE_REQ,
                payload: response.data,
            });
            toast.success(response?.message);
            getNotificationsList()
            setOpen(false)
        } else {
            toast.error(response?.message);
        }
    };
};

export const add_hire_and_collaborate = (data, setOpen) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("event_id", data?.event_id);
        fdata.append("provider_id", data?.provider_id);
        if (data?.description_id) {
            fdata.append("description_id", data?.description_id);
        }
        fdata.append("message", data?.message);
        fdata.append("amount", data?.amount);
        if (data?.activities) {
            fdata.append("activities", data?.activities);
        }
        fdata.append("event_request_type", data?.event_request_type);
        if (data?.service_time_detail) {
            fdata.append("service_time_detail", JSON.stringify(data?.service_time_detail));
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.add_hire_and_collaborate}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.ADD_HIRE_N_COLLABORATE,
                payload: {data: response.data, status: response?.success},
            });
            setOpen(false)
            toast.success(response?.message);
        } else {
            setOpen(false)
            toast.error(response?.message);
        }
    };
};
export const claim_add_hire_and_collaborate = (data, setOpen) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("event_id", data?.event_id);
        fdata.append("provider_id", data?.provider_id);
        if (data?.description_id) {
            fdata.append("description_id", data?.description_id);
        }
        fdata.append("message", data?.message);
        fdata.append("amount", data?.amount);
        if (data?.activities) {
            fdata.append("activities", data?.activities);
        }
        fdata.append("event_request_type", data?.event_request_type);
        if (data?.service_time_detail) {
            fdata.append("service_time_detail", JSON.stringify(data?.service_time_detail));
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.hire_or_collaborate}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: hireNcollaborateConstant?.CLAIM_ADD_HIRE_N_COLLABORATE,
                payload: {data: response.data, status: response?.success},
            });
            setOpen(false)
            toast.success(response?.message);
        } else {
            setOpen(false)
            toast.error(response?.message);
        }
    };
};

