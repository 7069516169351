import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconButton } from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import logo from "../assets/images/ad-anima_logo_without_bg.png";
import noImg from "../assets/images/noImg.jpg";
import locationIcon from "../assets/images/mapDiscovery.svg";
import {
  claimProviderBook,
  claimProviderContact,
  guestClaimProviderBook,
  claimEventBook,
} from "../action/claimConstant";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import bigLogo from "../assets/images/ad-anima_logo.png";

const Claim_book_request = ({ open, setOpen, userDetails, type }) => {
  const [serviceData, setServiceData] = useState(
    type == "event" ? null : userDetails?.find_user_service_details[0]
  );
  const [error, setError] = useState("");
  const [massage, setMassage] = useState("");
  const dispatch = useDispatch();
  const [isStatus, setIsStatus] = useState(false);
  const { providerId } = useParams();
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const [selectedMassage, setSelectedMassage] = useState({
    massage: "",
    id: 0,
    date: "",
  });

  const handleBookResquest = () => {
    setIsStatus(true);
    if (massage.trim() == "") {
      setIsStatus(false);
      setError("Please Add Message");
    } else {
      setError("");
      if (type == "event") {
        const payload = {
          request_book_user_id: userDetails?.user_id?._id,
          message: massage.trim(),
          pre_populate_event_id: userDetails?._id,
        };
        dispatch(
          claimEventBook(
            payload,
            (a) => {
              setIsStatus(a);
            },
            (a) => {
              setOpen(a);
            }
          )
        );
      } else {
        const payload = {
          request_book_user_id: providerId,
          message: massage.trim(),
          profile_image: userDetails?.profile_picture,
        };
        dispatch(
          claimProviderBook(
            payload,
            (a) => {
              setIsStatus(a);
            },
            (a) => {
              setOpen(a);
            }
          )
        );
      }
    }
  };
  const handleGuestBookResquest = () => {
    const massageOne = `Hi I am interested in booking a session with you, are you available ${selectedMassage?.date.toString()} ? Thank you.`;
    const massageTwo = `Hello I’m interested in your profile, what’s services do you offer ?`;
    setIsStatus(true);
    if (selectedMassage?.date == "" && selectedMassage?.id == 0) {
      setIsStatus(false);
      setError("Please Select Date");
    } else {
      setError("");
      if (type == "event") {
        const payload = {
          request_book_user_id: userDetails?.user_id?._id,
          message: massage.trim(),
          pre_populate_event_id: userDetails?._id,
        };
        dispatch(
          claimEventBook(
            payload,
            (a) => {
              setIsStatus(a);
            },
            (a) => {
              setOpen(a);
            }
          )
        );
      } else {
        const payload = {
          request_book_user_id: providerId,
          message: selectedMassage?.id == 0 ? massageOne : massageTwo,
          profile_image: userDetails?.profile_picture,
        };
        dispatch(
          guestClaimProviderBook(
            payload,
            (a) => {
              setIsStatus(a);
            },
            (a) => {
              setOpen(a);
            }
          )
        );
      }
    }
  };

  // const handleguestBook = () => {
  //     setIsStatus(true)
  //     if (massage == "") {
  //         setIsStatus(false)
  //         setError("Please Add Massage");
  //     } else {
  //         setError("");
  //         const payload = {
  //             request_book_user_id: providerId,
  //             message: massage,
  //             profile_image: userDetails?.profile_picture,
  //         }
  //         dispatch(claimProviderBook(payload, (a) => {
  //             setIsStatus(a)
  //         }, (a) => {
  //             setOpen(a)
  //         },))
  //     }
  // }

  useEffect(() => {
    if (type == "event") {
      setServiceData(null);
    } else {
      setServiceData(userDetails?.find_user_service_details[0]);
    }
  }, [userDetails]);

  // !userAuth?.token ?

  // console.log(userDetails, 'userDetails')

  return (
    <>
      <Modal
        centered
        show={open.requestTobook}
        backdrop="static"
        onHide={() => {
          setMassage("");
          setOpen((pre) => ({ ...pre, requestTobook: false }));
          setSelectedMassage({
            massage: "",
            id: 0,
            date: "",
          });
        }}
        dialogClassName="claim_account_modal"
      >
        <div className="Congratulations_msg p-3" style={{ maxWidth: "100%" }}>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="m-0">Request to Book</h3>
            <IconButton
              sx={{ background: "#EEEEEE" }}
              onClick={() => {
                setOpen((pre) => ({ ...pre, requestTobook: false }));
                setSelectedMassage({
                  massage: "",
                  id: 0,
                  date: "",
                });
              }}
            >
              <img src={closeIcon} alt="" />
            </IconButton>
          </div>
          <div
            className="border-gred mb-0 p-0 mt-3"
            style={{ border: "1px solid #D0DEE2" }}
          >
            <div className="inner-gred d-flex align-items-center p-2">
              <div className="position-relative">
                <div className="p_image_shape me-2 me-sm-3">
                  <img
                    src={
                      type == "event" && userDetails?.event_media
                        ? userDetails?.event_media[0]?.file_name
                        : userDetails?.is_admin_created
                        ? userDetails?.profile_picture
                          ? userDetails?.profile_picture
                          : bigLogo
                        : userDetails?.profile_picture
                    }
                    style={{ background: "white" }}
                    onError={(e) => {
                      e.target.src = noImg;
                    }}
                  />
                </div>
              </div>
              <div className="w-100">
                <h4 className="mb-1" style={{ fontSize: "14px", width: "90%" }}>
                  {type == "event"
                    ? userDetails?.event_name
                    : serviceData?.service_name || userDetails?.event_name}
                </h4>
                <p
                  className="mb-0 home-desc"
                  style={{
                    width: "80%",
                    fontSize: "10px",
                    wordBreak: "break-word",
                  }}
                >
                  {type == "event"
                    ? ""
                    : serviceData?.description || userDetails?.user_type}
                </p>
                <div className="d-flex  align-items-center mt-1">
                  <div className="d-flex align-items-baseline">
                    <div className="me-2 me-sm-2">
                      <img src={locationIcon} style={{ width: "14px" }} />
                    </div>
                    <p
                      className="map_des m-0 p-0"
                      style={{
                        fontSize: "10px",
                        color: "#FFC107",
                      }}
                    >
                      {userDetails?.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <p className="t-19-bold mb-0 text-black">Message:</p>
            {!userAuth?.token ? (
              <>
                <div>
                  <div
                    className={`border-gred mb-0 ${
                      selectedMassage?.id !== 0 ? "p-0" : ""
                    } mt-3`}
                    onClick={() =>
                      setSelectedMassage((pre) => ({
                        ...pre,
                        id: 0,
                        massage: `Hi I am interested in booking a session with you, are you available ${selectedMassage?.date.toString()} ? Thank you.`,
                      }))
                    }
                  >
                    <div
                      className="inner-gred"
                      style={{ padding: "5px 15px", cursor: "pointer" }}
                    >
                      <p>
                        {" "}
                        Hi I am interested in booking a session with you, are
                        you available
                        <span className="sd-container">
                          <input
                            onChange={(e) =>
                              setSelectedMassage((pre) => ({
                                ...pre,
                                date: e.target.value,
                              }))
                            }
                            min={new Date().toISOString().split("T")[0]}
                            type="date"
                            value={selectedMassage?.date}
                            style={{ border: "none", marginLeft: "8px" }}
                          />
                          <span className="open-button">
                            <button type="button">📅</button>
                          </span>
                        </span>
                        ? Thank you.
                      </p>
                    </div>
                  </div>
                  <p className="t-14-bold mt-0 text-danger">{error}</p>
                  <div
                    className={`border-gred mb-0 ${
                      selectedMassage?.id !== 1 ? "p-0" : ""
                    } mt-3`}
                    onClick={() =>
                      setSelectedMassage((pre) => ({
                        ...pre,
                        id: 1,
                        massage: `Hello I’m interested in your profile, what’s services do you offer ?`,
                      }))
                    }
                  >
                    <div
                      className="inner-gred"
                      style={{ padding: "5px 15px", cursor: "pointer" }}
                    >
                      <p>
                        Hello I’m interested in your profile, what’s services do
                        you offer ?
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <textarea
                  placeholder="Write here your message..."
                  onChange={(e) => setMassage(e.target.value)}
                  value={massage}
                  className="ContactTextArea mt-1"
                  name=""
                  id=""
                  cols="50"
                />
                <p className="t-14-bold mt-0 text-danger">{error}</p>
              </>
            )}
          </div>
          <div>
            <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
              {isStatus ? (
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="btn_admina"
                >
                  <div className="loader"></div>
                </a>
              ) : (
                <button
                  className="btn_admina"
                  onClick={() => {
                    if (!userAuth?.token) {
                      handleGuestBookResquest();
                    } else {
                      handleBookResquest();
                    }
                  }}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Claim_book_request;
