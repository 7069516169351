import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Grid, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment/moment";

const ServiceTimePopup = ({
  open,
  setOpen,
  setIsUpdate,
  setDetails,
  ditails,
  selectedIndex,
  selectedHourIndex,
  setTimeDetails,
  timeDetails,
  isEdit,
  setIsEdit,
  handleEditSubmit,
  setError,
  error,
  setSelectedIndex,
}) => {
  const handleStartTimeChange = (newStartTime) => {
    setTimeDetails((prevRegistration) => ({
      ...prevRegistration,
      startTime: newStartTime,
    }));
  };


  const handleEndTimeChange = (newStartTime) => {
    setTimeDetails((prevRegistration) => ({
      ...prevRegistration,
      endTime: newStartTime,
    }));
  };

  const handleSubmit = () => {
    if (!timeDetails?.date) {
      setError((pre) => ({ ...pre, date: "Please enter date" }));
    } else if (!timeDetails?.startTime) {
      setError((pre) => ({
        ...pre,
        startTime: "Please enter start time",
        date: "",
      }));
    } else if (!timeDetails?.endTime) {
      setError((pre) => ({
        ...pre,
        endTime: "Please enter end time",
        startTime: "",
        date: "",
      }));
    } else if (
      timeDetails?.startTime.toString() == timeDetails?.endTime.toString() ||
      timeDetails?.startTime > timeDetails?.endTime
    ) {
      setError((pre) => ({
        ...pre,
        endTime: "Please enter end time greater then start time.",
        startTime: "",
        date: "",
      }));
    } else {
      setError({
        date: "",
        startTime: "",
        endTime: "",
      });
      setDetails((pre) => ({
        ...pre,
        service: pre?.service?.map((service, i) => {
          if (i == selectedIndex) {
            return { ...service, hours: [...service.hours, timeDetails] };
          } else {
            return service;
          }
        }),
      }));
      setTimeDetails({
        date: ditails?.startDate,
        startTime: null,
        endTime: null,
      });
      setOpen(false);
      setSelectedIndex(null);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setTimeDetails({
      date: "",
      startTime: "",
      endTime: "",
    });
    setSelectedIndex(null);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <Modal
        centered
        show={open}
        backdrop="static"
        onHide={() => {
          handleClose();
          // setIsUpdate(false)
        }}
        dialogClassName="delete-modal"
      >
        <>
          <div className="Congratulations_msg">
            <div className="group" style={{ borderRadius: "10px" }}>
              <div className="py-2 px-2" style={{ borderRadius: "10px" }}>
                <div>
                  <p className="t-18-bold">Time</p>
                  <hr />
                </div>
                <div
                  className="p-3"
                  style={{
                    background: "#EFF3F7",
                    borderRadius: "5px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <div>
                        <p className="t-14-500 text-black m-0">Date</p>
                        <div className="group-input mt-2 p-15 clearfix">
                          <input
                            type="date"
                            onChange={(e) =>
                              setTimeDetails((pre) => ({
                                ...pre,
                                date: e.target.value,
                              }))
                            }
                            value={timeDetails?.date}
                            placeholder="MM/DD/YYYY"
                            min={getCurrentDate()}
                          />
                        </div>
                        <p className="t-13-500 text-danger">{error?.date}</p>
                      </div>
                    </Grid>
                      <Grid item xs={6} className="pe-2">
                        <div>
                          <p className="t-14-500 text-black m-0">Start time</p>
                          <div className="group-input mt-2 p-15 clearfix timePicker-Event ">
                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    onChange={(e) => setDetails((pre) => ({...pre, startTime: e.target.value}))}*/}
                            {/*    value={details?.startTime}*/}
                            {/*    placeholder="Select time"*/}
                            {/*/>*/}
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              sx={{ width: "100%" }}
                            >
                              <MobileTimePicker
                                // defaultValue={dayjs(registration.startTime)}
                                value={dayjs(timeDetails?.startTime)}
                                
                                onChange={handleStartTimeChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    style={{padding: '0px'}}
                                    InputLabelProps={{
                                      ...params.inputProps,
                                      placeholder: "Start Time",
                                    }}
                                    sx={{ padding: "0" }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                          <p className="t-13-500 text-danger">
                            {error?.startTime}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={6} className="ps-2">
                        <div>
                          <p className="t-14-500 text-black m-0">End time</p>
                          <div className="group-input mt-2 p-15 clearfix timePicker-Event ">
                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    onChange={(e) => setDetails((pre) => ({...pre, startTime: e.target.value}))}*/}
                            {/*    value={details?.startTime}*/}
                            {/*    placeholder="Select time"*/}
                            {/*/>*/}
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              sx={{ width: "100%" }}
                            >
                              <MobileTimePicker
                                // defaultValue={dayjs(timeDetails?.startTime)}
                                // minTime={dayjs(timeDetails?.startTime)}
                                value={dayjs(timeDetails?.endTime)}
                                onChange={handleEndTimeChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputLabelProps={{
                                      ...params.inputProps,
                                      placeholder: "Start Time",
                                    }}
                                    sx={{ padding: "0" }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                          <p
                            className="t-13-500 text-danger"
                            style={{ wordBreak: "break-word" }}
                          >
                            {error?.endTime}
                          </p>
                        </div>
                      </Grid>
                  </Grid>
                  <div className="w-100 d-flex mt-4">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn_admina delete-confirm-button"
                        style={{ lineHeight: "22px" }}
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        style={{ lineHeight: "22px" }}
                        className="btn_admina delete-button"
                        onClick={() => {
                          if (isEdit) {
                            handleEditSubmit();
                          } else {
                            handleSubmit();
                          }
                        }}
                        // onClick={() => {
                        //     if (!IsValidation()) {
                        //         if (isUpdate) {
                        //             handleEditSchedule()
                        //         } else {
                        //             handleCreateSchedule()
                        //         }
                        //     }
                        // }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ServiceTimePopup;
