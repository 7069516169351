import React, {useState} from "react";
import Cropper from "react-easy-crop";
import {getCroppedImg, getRotatedImage} from "../../Utils/canvasUtils";
import {Box, Button, Slider, Typography, withStyles} from "@mui/material";
import { styles } from './style'
import {getOrientation} from "get-orientation";
import Modal from "@mui/material/Modal";
import ReactDOM from "react-dom";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: "500px",
    height: "100%",
    maxHeight: "580px",
    // border: '2px solid #000',
    boxShadow: 24,
    background: "white",
    p: 2,
    paddingBottom: "83px",
};


const CropImage = ({open, setOpen, imageSrc, setregistration, registration, setprofileImage}) => {
    // const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    // console.log(imageSrc, "imageSrc")
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }
    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            onImageEdit(croppedImage)
            handleClose()
            // setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }

    const onClose = () => {
        setCroppedImage(null)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const getUrlExtension = (url) => {
        return url
            .split(/[#?]/)[0]
            .split(".")
            .pop()
            .trim();
    }

    const onImageEdit = async (imgUrl) => {
        // var imgExt = getUrlExtension(imgUrl);
        const imgexp = imgUrl.split("/")
        const response = await fetch(imgUrl);
        const blob = await response.blob();
        const file = new File([blob], "profileImage." + imgexp[imgexp?.length - 1] + ".jpg"  , {
            type: blob.type,
        });

        setprofileImage(
            window.URL.createObjectURL(
                file
            )
        );
        setregistration({
            ...registration,
            profile_picture: file,
        });
        setCroppedImage(file)
        // return file
    }




    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="position-relative" style={{
                        width: '100%',
                        height: '100%',
                        background: "white"
                    }}>
                        {imageSrc ? (
                            <React.Fragment>
                                <div className="p-3">
                                    <div className={styles.cropContainer}>
                                        <Cropper
                                            image={imageSrc}
                                            crop={crop}
                                            rotation={rotation}
                                            zoom={zoom}
                                            aspect={3 / 3}
                                            onCropChange={setCrop}
                                            onRotationChange={setRotation}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                        />
                                    </div>
                                </div>
                                <div className="position-absolute end-0" style={{bottom: "-56px"}}>
                                    <Button
                                        onClick={showCroppedImage}
                                        variant="contained"
                                        color="primary"
                                        classes={{ root: styles.cropButton }}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                                {/*<img src={croppedImage} alt=""/>*/}
                                {/*<ImgDialog img={croppedImage} onClose={onClose} />*/}
                            </React.Fragment>
                        ) : (
                            null
                        )}
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default CropImage;

// const StyledDemo = withStyles(styles)(CropImage)
// const rootElement = document.getElementById('root')
// ReactDOM.render(<StyledDemo />, rootElement)



// const StyledDemo = withStyles(styles)(Demo)
//
// const rootElement = document.getElementById('root')
// ReactDOM.render(<StyledDemo />, rootElement)