const auth_id = JSON.parse(localStorage.getItem("AdAnima_auth"));  // login user data

export const get_referral_code_percentage = (data, setRefCodeData) => {
    // debugger

    if (data?.provider_referral_code !== {}) {
        if (data?.provider_referral_code?.is_referee == true) {
            // if (data?.provider_referral_code?.reward_type)
            setRefCodeData((pre) => ({
                ...pre, referrer: {
                    id: data?.provider_referral_code?._id,
                    is_referee: false,
                    reward_type: data?.provider_referral_code?.reward_type,
                    percentage: data?.provider_referral_code?.referee_waved_fees_percentage,
                    is_expire: false
                }
            }))
        }
        else if (data?.provider_referral_code?.is_referee == false) {
            setRefCodeData((pre) => ({
                ...pre, referrer: {
                    id: data?.provider_referral_code?._id,
                    is_referee: false,
                    reward_type: data?.provider_referral_code?.reward_type,
                    percentage: data?.provider_referral_code?.referrer_waved_fees_percentage,
                    is_expire: false
                }
            }))
        }
    }

    if (data?.user_referral_code !== {}) {
        if (data?.user_referral_code?.is_referee == true) {
            // if (data?.provider_referral_code?.reward_type)
            setRefCodeData((pre) => ({
                ...pre, referee: {
                    id: data?.user_referral_code?._id,
                    is_referee: true,
                    reward_type: data?.user_referral_code?.reward_type,
                    percentage: data?.user_referral_code?.referee_waved_fees_percentage,
                    is_expire: false
                }
            }))
        } else if (data?.user_referral_code?.is_referee == false) {
            setRefCodeData((pre) => ({
                ...pre, referee: {
                    id: data?.user_referral_code?._id,
                    is_referee: true,
                    reward_type: data?.user_referral_code?.reward_type,
                    percentage: data?.user_referral_code?.referrer_waved_fees_percentage,
                    is_expire: false
                }
            }))
        }
    }
}