import React from 'react';
import {Grid} from "@mui/material";
import bannerImg from "../../assets/images/ref-banner.png";

const RefcodeBanner = ({handleCreateCode, loader}) => {
    return (
        <>
            <div>
                <h1 className="d-inline-block mt-4">REFER YOUR FRIENDS</h1>
            </div>

            <div className="mt-5">
                <Grid container spacing={2} sx={{alignItems: "center"}}>
                    <Grid item xs={12} md={6}>
                        <div className="w-100 align-items-center justify-content-center d-flex">
                            <img src={bannerImg} alt="" style={{
                                width: '100%',
                                maxWidth: '460px'
                            }}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div
                            className="w-100 align-items-center justify-content-md-start justify-content-center d-flex">
                            <div className="ref-desc-width">
                                <div className="d-flex align-items-center gap-3">
                                    <p className="step_bg m-0 t-17-bold">1</p>
                                    <p className="m-0 t-19-500 ref-desc" style={{color: '#363853'}}>Invite
                                        your friends
                                        using your unique referral code.</p>
                                </div>
                                <div className="d-flex align-items-center gap-3 mt-4">
                                    <p className="step_bg m-0 t-17-bold">2</p>
                                    <p className="m-0 t-19-500 ref-desc" style={{color: '#363853'}}>Once
                                        they make their
                                        first booking, your 1-month fee waiver begins.</p>
                                </div>
                                <div className="d-flex align-items-center gap-3 mt-4">
                                    <p className="step_bg m-0 t-17-bold">3</p>
                                    <p className="m-0 t-19-500 ref-desc" style={{color: '#363853'}}>Your
                                        friends enjoy a 5% discount on all bookings for their first
                                        month.</p>
                                </div>

                                <div
                                    className="d-flex flex-wrap align-items-center justify-content-center mt-4">
                                    {
                                        loader ? <a
                                                href="#"
                                                style={{
                                                    maxWidth: "350px",
                                                    height: "45px",
                                                    fontWeight: '600'
                                                }}
                                                onClick={(e) => e.preventDefault()}
                                                className="btn_White massageBtn mt-3"
                                            >
                                                {/* save */}
                                                <div className="loader"></div>
                                            </a> :
                                            <button className="massageBtn mt-3"
                                                    onClick={() => handleCreateCode()}
                                                    style={{
                                                        maxWidth: "350px",
                                                        height: "45px",
                                                        fontWeight: '600'
                                                    }}>Generate Code
                                            </button>
                                    }

                                </div>

                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default RefcodeBanner;