import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Bank from "./Bank";
import editIcon from "../../assets/images/edit.svg";
import { Grid, IconButton } from "@mui/material";
import { getBankInfo } from "../../action/walletConstant";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const BankView = ({ openBankInfo, setOpenBankInfo }) => {
  const [isEditInfo, setIsEditInfo] = useState(false);
  const dispatch = useDispatch();
  const { wallet } = useSelector((state) => state);
  useEffect(() => {
    dispatch(getBankInfo());
  }, []);
  const formattedDate = moment(
    `${wallet?.bankInformation?.individual?.dob?.day}/${wallet?.bankInformation?.individual?.dob?.month}/${wallet?.bankInformation?.individual?.dob?.year}`,
    "DD/MM/YYYY"
  ).format("YYYY MMM DD");

  const hideAccountNo = (numbers) => {
    const maskedPart = "*".repeat(Math.max(0, numbers?.length - 4));
    const lastFourDigits = numbers?.slice(-4);
    const formattedCreditCardNumber = `${maskedPart}${lastFourDigits}`;
    return formattedCreditCardNumber;
  };

  const fullAddrasse = (data) => {
    let fullAddress = "";
    if (data?.line1) {
      fullAddress = data?.line1 + ", ";
    }
    if (data?.line2) {
      fullAddress = fullAddress + data?.line2 + ", ";
    }
    if (data?.city) {
      fullAddress = fullAddress + data?.city + ", ";
    }
    if (data?.state) {
      fullAddress = fullAddress + data?.state + ", ";
    }
    if (data?.country) {
      fullAddress = fullAddress + data?.country + ", ";
    }
    if (data?.postal_code) {
      fullAddress = fullAddress + data?.postal_code + ", ";
    }

    return fullAddress;
    // return data?.line1 + "," + data?.line2 + "," + data?.city + "," + data?.state + "," + data?.country + "," + data?.postal_code
  };

  return (
    <>
      <Modal
        centered
        show={openBankInfo}
        backdrop="static"
        onHide={() => {
          setOpenBankInfo(false);
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <p className="t-24-bold m-0">Bank Info</p>
          <div className="d-flex align-items-center">
            {wallet?.bankInformation?.bank_account_status == "Restricted" && (
              <div
                className="me-2"
                style={{
                  padding: "5px 10px",
                  borderRadius: "3px",
                  fontSize: "14px",
                  backgroundColor: "#FFE7F2",
                  color: "#B3093C",
                }}
              >
                Restricted
              </div>
            )}
            {wallet?.bankInformation?.bank_account_status == "Complete" && (
              <div
                className="me-2"
                style={{
                  padding: "5px 10px",
                  borderRadius: "3px",
                  fontSize: "14px",
                  backgroundColor: "#D7F7C2",
                  color: "#006908",
                }}
              >
                Completed
              </div>
            )}

            {wallet?.bankInformation?.bank_account_status == "Rejected" && (
              <div
                className="me-2"
                style={{
                  padding: "5px 10px",
                  borderRadius: "3px",
                  fontSize: "14px",
                  backgroundColor: "#EBEEF1",
                  color: "#545969",
                }}
              >
                Rejected
              </div>
            )}
            {wallet?.bankInformation?.bank_account_status ==
              "Restricted_soon" && (
              <div
                className="me-2"
                style={{
                  padding: "5px 10px",
                  borderRadius: "3px",
                  fontSize: "14px",
                  backgroundColor: "#FCEDB9",
                  color: "#A82C00",
                }}
              >
                Restricted soon
              </div>
            )}

            <button
              type="button"
              className="close-modal"
              onClick={() => {
                setOpenBankInfo(false);
              }}
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="25" cy="25" r="25" fill="#EEEEEE" />
                <path
                  d="M25 38.4375C17.5875 38.4375 11.5625 32.4125 11.5625 25C11.5625 17.5875 17.5875 11.5625 25 11.5625C32.4125 11.5625 38.4375 17.5875 38.4375 25C38.4375 32.4125 32.4125 38.4375 25 38.4375ZM25 13.4375C18.625 13.4375 13.4375 18.625 13.4375 25C13.4375 31.375 18.625 36.5625 25 36.5625C31.375 36.5625 36.5625 31.375 36.5625 25C36.5625 18.625 31.375 13.4375 25 13.4375Z"
                  fill="#363853"
                />
                <path
                  d="M21.4627 29.4752C21.2252 29.4752 20.9877 29.3877 20.8002 29.2002C20.4377 28.8377 20.4377 28.2377 20.8002 27.8752L27.8752 20.8002C28.2377 20.4377 28.8377 20.4377 29.2002 20.8002C29.5627 21.1627 29.5627 21.7627 29.2002 22.1252L22.1252 29.2002C21.9502 29.3877 21.7002 29.4752 21.4627 29.4752Z"
                  fill="#363853"
                />
                <path
                  d="M28.5377 29.4752C28.3002 29.4752 28.0627 29.3877 27.8752 29.2002L20.8002 22.1252C20.4377 21.7627 20.4377 21.1627 20.8002 20.8002C21.1627 20.4377 21.7627 20.4377 22.1252 20.8002L29.2002 27.8752C29.5627 28.2377 29.5627 28.8377 29.2002 29.2002C29.0127 29.3877 28.7752 29.4752 28.5377 29.4752Z"
                  fill="#363853"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="mt-2">
          <div className="border-gred">
            <div className="inner-gred">
              <div className="d-flex align-items-center justify-content-between">
                <p className="t-17-bold text-black m-0">Banking Information</p>
                <IconButton
                  onClick={() => {
                    setOpenBankInfo(false);
                    setIsEditInfo(true);
                  }}
                >
                  <img src={editIcon} style={{ width: "20px" }} alt="" />
                </IconButton>
              </div>
              <hr />
              <div>
                <Grid container spacing={0} gap={0}>
                  <Grid item xs={12} sm={6} sx={{ paddingTop: "0" }}>
                    <p className="t-14-500 text-light-gray m-0">First Name</p>
                    <p className="t-13-bold text-black">
                      {wallet?.bankInformation?.individual?.first_name ?? "---"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ paddingTop: "0" }}>
                    <p className="t-14-500 text-light-gray m-0">Last Name</p>
                    <p className="t-13-bold text-black">
                      {wallet?.bankInformation?.individual?.last_name ?? "---"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ paddingTop: "0" }}>
                    <p className="t-14-500 text-light-gray m-0">
                      Account Number
                    </p>
                    <p className="t-13-bold text-black">
                      {wallet?.bankInformation?.metadata?.ac_no
                        ? hideAccountNo(
                            wallet?.bankInformation?.metadata?.ac_no
                          )
                        : "---"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ paddingTop: "0" }}>
                    <p className="t-14-500 text-light-gray m-0">
                      Routing Number
                    </p>
                    <p className="t-13-bold text-black">
                      {wallet?.bankInformation?.external_accounts?.data[0]
                        ?.routing_number
                        ? hideAccountNo(
                            wallet?.bankInformation?.external_accounts?.data[0]
                              ?.routing_number
                          )
                        : "---"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ paddingTop: "0" }}>
                    <p className="t-14-500 text-light-gray m-0">DOB</p>
                    <p className="t-13-bold text-black">
                      {formattedDate !== "Invalid date" && formattedDate
                        ? formattedDate
                        : "---"}
                    </p>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                                        <p className="t-14-500 text-light-gray m-0">Tax ID</p>
                                        <p className="t-13-bold text-black">{wallet?.bankInformation?.metadata?.tax_id ? hideAccountNo(wallet?.bankInformation?.metadata?.tax_id) : "---"}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <p className="t-14-500 text-light-gray m-0">Company Name</p>
                                        <p className="t-13-bold text-black">{wallet?.bankInformation?.company?.name ?? "---"}</p>
                                    </Grid> */}
                </Grid>
              </div>
              <div>
                <p className="t-17-bold text-black m-0 mt-3">
                  Banking Information
                </p>
              </div>
              <hr />
              <div>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <p className="t-14-500 text-light-gray m-0">Address</p>
                    <p
                      className="t-13-bold text-black"
                      style={{
                        width: "80%",
                        wordBreak: "break-word",
                      }}
                    >
                      {wallet?.bankInformation?.individual?.address
                        ? fullAddrasse(
                            wallet?.bankInformation?.individual?.address
                          )
                        : "---"}
                    </p>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                                        <p className="t-14-500 text-light-gray m-0">National ID</p>
                                        <p className="t-13-bold text-black">{wallet?.bankInformation?.metadata?.s_no ? hideAccountNo(wallet?.bankInformation?.metadata?.s_no) : "---"}</p>
                                    </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <p className="t-14-500 text-light-gray m-0">Email</p>
                    <p className="t-13-bold text-black">
                      {wallet?.bankInformation?.individual?.email ?? "---"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p className="t-14-500 text-light-gray m-0">Phone Number</p>
                    <p className="t-13-bold text-black">
                      {wallet?.bankInformation?.metadata?.phone ?? "---"}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Bank
        openBankInfo={isEditInfo}
        setOpenBankInfo={setIsEditInfo}
        setOpenBankInfoView={setOpenBankInfo}
      />
    </>
  );
};

export default BankView;
