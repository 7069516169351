import React, { useEffect, useState } from "react";
import GuestHeader from "../../Component/GuestHeader";
import Header from "../../Component/Header";
import { Box } from "@mui/material";
import AllTransactions from "./AllTransactions";
import { useDispatch, useSelector } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";
import {
  fatchMoreHistoryData,
  getPaymentHistory,
} from "../../action/paymentHistoryConstant";
import { fatcheMoreNotificationList } from "../../action/NotificationConstant";
import { Empty } from "antd";
import Loader from "../../Component/Loader";
import { useNavigate } from "react-router-dom";

const Wallet = () => {
  const [dataLoader, setDataLoader] = useState(false);
  const { paymentHistory } = useSelector((state) => state);
  const [isTabSelect, setIsTabSelect] = useState("Transactions");
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [pagePagination, setPagePagination] = useState(2);
  const getAllHistory = () => {
    setDataLoader(true);
    const payload = {
      list_type:
        isTabSelect == "Transactions"
          ? "all"
          : isTabSelect == "Received"
          ? "credited"
          : `debited`,
      page: "1",
      limit: "10",
    };
    dispatch(
      getPaymentHistory(payload, (a) => {
        setDataLoader(a);
      })
    );
  };

  const { wallet } = useSelector((state) => state);

  const fetchmoreData = () => {
    // console.log('slksjs')
    const payload = {
      list_type:
        isTabSelect == "Transactions"
          ? "all"
          : isTabSelect == "Received"
          ? "credited"
          : `debited`,
      page: pagePagination,
      limit: "10",
    };
    dispatch(
      fatchMoreHistoryData(payload, (a) => {
        setHasMore(a);
      })
    );
    setPagePagination((pre) => pre + 1);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getAllHistory();
  }, [isTabSelect]);

  // console.log(paymentHistory, "wallet")

  return (
    <>
      {dataLoader && <Loader />}
      <section className="main-page">
        <Header select={""} />
        <div className="main-inner">
          <div className="container">
            {/*.........page section.........*/}

            {(wallet?.bankInformation?.bank_account_status == "Restricted" ||
              wallet?.bankInformation?.bank_account_status ==
                "Restricted_soon" ||
              wallet?.bankInformation?.bank_account_status == "Rejected") && (
              <div
                className="p-2 d-flex align-items-center mb-3"
                style={{
                  backgroundColor: "#FA000026",
                  borderRadius: "5px",
                  width: "fit-content",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="me-2"
                >
                  <path
                    d="M23.5013 17.9977L15.0233 2.41292C13.6612 0.119744 10.3407 0.116698 8.9767 2.41292L0.499073 17.9977C-0.893396 20.341 0.792463 23.3082 3.52167 23.3082H20.478C23.2049 23.3082 24.8938 20.3434 23.5013 17.9977ZM12 20.4957C11.2248 20.4957 10.5937 19.8647 10.5937 19.0895C10.5937 18.3143 11.2248 17.6832 12 17.6832C12.7752 17.6832 13.4062 18.3143 13.4062 19.0895C13.4062 19.8647 12.7752 20.4957 12 20.4957ZM13.4062 14.8707C13.4062 15.646 12.7752 16.277 12 16.277C11.2248 16.277 10.5937 15.646 10.5937 14.8707V7.83949C10.5937 7.06428 11.2248 6.43324 12 6.43324C12.7752 6.43324 13.4062 7.06428 13.4062 7.83949V14.8707Z"
                    fill="#FF3F3F"
                  />
                </svg>
                Your bank account appears to be{" "}
                {wallet?.bankInformation?.bank_account_status == "Restricted" &&
                  "restricted"}
                {wallet?.bankInformation?.bank_account_status ==
                  "Restricted_soon" && "restricted soon"}
                {wallet?.bankInformation?.bank_account_status == "Rejected" &&
                  "rejected"}
                .{" "}
                <span
                  className="ms-2"
                  style={{
                    fontWeight: "600",
                    color: "#3A2727",
                    borderBottom: "2px solid #3A2727",
                  }}
                  onClick={() => {
                    navigate(`/bank_verification?bank_id=${wallet?.bankInformation?.id}`);
                  }}
                >
                  Please verify
                </span>
              </div>
            )}

            <Box>
              <h3 style={{ fontWeight: "600", marginBottom: "25px" }}>
                Transaction History
              </h3>
            </Box>

            {/*     ===== TABS =====   */}

            <div className="walletTabsdiv">
              <div
                className={`wallet_tabs mt-1 ${
                  isTabSelect == "Transactions" ? "wallet_tabs_select" : ""
                }`}
                onClick={() => setIsTabSelect("Transactions")}
              >
                <p>All Transactions</p>
              </div>
              <div
                className={`wallet_tabs mt-1 ${
                  isTabSelect == "Received" ? "wallet_tabs_select" : ""
                }`}
                onClick={() => setIsTabSelect("Received")}
              >
                <p>Pay In</p>
              </div>
              <div
                className={`wallet_tabs mt-1 ${
                  isTabSelect == "Pay" ? "wallet_tabs_select" : ""
                }`}
                onClick={() => setIsTabSelect("Pay")}
              >
                <p>Pay Out</p>
              </div>
            </div>

            <div className="mt-5">
              <div>
                <div
                  style={{
                    height: "calc(100vh - 200px)",
                    overflow: "auto",
                  }}
                  id="scrollableDiv1"
                  className="notificationScroll"
                >
                  <InfiniteScroll
                    dataLength={paymentHistory}
                    next={fetchmoreData}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv1"
                    className="infinity-list bg-white accordion"
                    id={"accordionExample"}
                  >
                    {paymentHistory.length ? (
                      paymentHistory?.map((ele, i) => (
                        <>
                          <AllTransactions
                            data={ele}
                            index={i}
                            getAllHistory={getAllHistory}
                          />
                        </>
                      ))
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>No History Found</span>}
                      />
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wallet;
