import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../../Utils/DateFormateUtils";
import moment from "moment";
import logo from "../../assets/images/tempImage/templogo.png";
import tempimgback from "../../assets/images/tempImage/tempimgback.png";
import tempaback from "../../assets/images/tempImage/tempaback.png";
import tempback2 from "../../assets/images/tempImage/tempback2.png";

const EventStoryTemp3 = ({ data }) => {
  const { eventData } = useSelector((state) => state.myEvent);

  const [lowestPrice, setlowestPrice] = useState(0);

  useEffect(() => {
    let lowPrice = 9999999999;

    eventData?.package_details?.map((packege) => {
      if (Number(packege?.value_per_person) < lowPrice) {
        lowPrice = Number(packege?.value_per_person);
      }
    });

    if (lowPrice !== 9999999999) {
      setlowestPrice(lowPrice);
    }
  }, [eventData]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 1080 1920"
      fill="none"
    >
      <g clip-path="url(#clip0_9172_7380)">
        <rect width="1080" height="1920" fill="white" />
        <rect
          x="-34"
          width="1281"
          height="1920"
          fill="url(#pattern0_9172_7380)"
        />
        <rect
          width="1080"
          height="1920"
          fill="url(#paint0_linear_9172_7380)"
          fill-opacity="0.8"
        />
        <rect
          y="1750"
          width="1080"
          height="170"
          fill="url(#paint1_linear_9172_7380)"
          fill-opacity="0.2"
        />
        <rect
          x="30"
          y="30"
          width="192"
          height="173"
          fill="url(#pattern1_9172_7380)"
        />
        <g filter="url(#filter0_d_9172_7380)">
        <foreignObject y="237" x="50" width="976" height="168">
            <div
              xmlns="http://www.w3.org/1999/xhtml"
              style={{
                fontSize: "65px",
                color: "#FFFFFF",
                fontWeight: "400",
                lineHeight: "84.36px",
              }}
              className="text-candal text-elips two-line p-0"
            >
              {eventData?.event_name}
            </div>
          </foreignObject>
        </g>
        <foreignObject y="450" x="50" width="516" height="50">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: "50px",
              color: "#F09B00",
              lineHeight: "50px",
              fontWeight: "700",
            }}
            className="text-source text-elips one-line p-0"
          >
            <u>STARTING AT ${lowestPrice}</u>
          
          </div>
        </foreignObject>
   
        <g filter="url(#filter1_d_9172_7380)">
          <path
            d="M280.691 963H123.309C121.525 963 119.875 962.049 118.981 960.504L41.4489 826.504C40.5526 824.955 40.5526 823.045 41.4488 821.496L118.981 687.496C119.875 685.951 121.525 685 123.309 685H280.691C282.475 685 284.125 685.951 285.019 687.496L362.551 821.496C363.447 823.045 363.447 824.955 362.551 826.504L285.019 960.504C284.125 962.049 282.475 963 280.691 963Z"
            fill="url(#pattern2_9172_7380)"
            shape-rendering="crispEdges"
          />
          <path
            d="M280.691 963H123.309C121.525 963 119.875 962.049 118.981 960.504L41.4489 826.504C40.5526 824.955 40.5526 823.045 41.4488 821.496L118.981 687.496C119.875 685.951 121.525 685 123.309 685H280.691C282.475 685 284.125 685.951 285.019 687.496L362.551 821.496C363.447 823.045 363.447 824.955 362.551 826.504L285.019 960.504C284.125 962.049 282.475 963 280.691 963Z"
            stroke="url(#paint2_linear_9172_7380)"
            stroke-width="2"
            shape-rendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_9172_7380)">
          <path
            d="M294.542 1336H108.459C106.674 1336 105.024 1335.05 104.13 1333.5L12.4481 1175C11.5523 1173.45 11.5522 1171.55 12.4481 1170L104.13 1011.5C105.024 1009.95 106.674 1009 108.458 1009H294.542C296.326 1009 297.976 1009.95 298.87 1011.5L390.552 1170C391.448 1171.55 391.448 1173.45 390.552 1175L298.87 1333.5C297.976 1335.05 296.326 1336 294.542 1336Z"
            fill="url(#pattern3_9172_7380)"
            shape-rendering="crispEdges"
          />
          <path
            d="M294.542 1336H108.459C106.674 1336 105.024 1335.05 104.13 1333.5L12.4481 1175C11.5523 1173.45 11.5522 1171.55 12.4481 1170L104.13 1011.5C105.024 1009.95 106.674 1009 108.458 1009H294.542C296.326 1009 297.976 1009.95 298.87 1011.5L390.552 1170C391.448 1171.55 391.448 1173.45 390.552 1175L298.87 1333.5C297.976 1335.05 296.326 1336 294.542 1336Z"
            stroke="url(#paint3_linear_9172_7380)"
            stroke-width="2"
            shape-rendering="crispEdges"
          />
        </g>
        <g filter="url(#filter3_d_9172_7380)">
          <path
            d="M337.774 631H79.8227C78.1043 631 76.5063 630.118 75.591 628.663L51.6761 590.663C50.6517 589.035 50.6517 586.965 51.6761 585.337L75.591 547.337C76.5063 545.882 78.1043 545 79.8227 545H337.774C339.492 545 341.09 545.882 342.005 547.337L365.92 585.337C366.945 586.965 366.945 589.035 365.92 590.663L342.005 628.663C341.09 630.118 339.492 631 337.774 631Z"
            fill="url(#paint4_linear_9172_7380)"
          />
          <path
            d="M337.774 630H79.8227C78.448 630 77.1695 629.294 76.4373 628.131L52.5224 590.131C51.7029 588.828 51.7029 587.172 52.5224 585.869L76.4373 547.869C77.1695 546.706 78.448 546 79.8227 546H337.774C339.148 546 340.427 546.706 341.159 547.869L365.074 585.869C365.894 587.172 365.894 588.828 365.074 590.131L341.159 628.131C340.427 629.294 339.148 630 337.774 630Z"
            stroke="url(#paint5_linear_9172_7380)"
            stroke-width="2"
          />
        </g>
        <path
          d="M297.022 577.423L290.232 599.316H286.144L281.663 584.997C281.592 584.796 281.522 584.575 281.451 584.334C281.381 584.092 281.316 583.836 281.255 583.564C281.195 583.836 281.13 584.092 281.059 584.334C280.989 584.575 280.918 584.796 280.848 584.997L276.322 599.316H272.218L265.443 577.423H269.245C269.638 577.423 269.965 577.514 270.226 577.695C270.498 577.876 270.674 578.117 270.754 578.419L274.149 590.761C274.229 591.103 274.31 591.475 274.39 591.877C274.481 592.27 274.566 592.687 274.647 593.13C274.737 592.677 274.833 592.255 274.934 591.862C275.034 591.47 275.145 591.103 275.265 590.761L279.218 578.419C279.299 578.168 279.47 577.941 279.731 577.74C280.003 577.529 280.33 577.423 280.712 577.423H282.04C282.432 577.423 282.749 577.519 282.99 577.71C283.242 577.891 283.428 578.127 283.549 578.419L287.487 590.761C287.607 591.083 287.718 591.435 287.818 591.817C287.919 592.189 288.015 592.587 288.105 593.009C288.186 592.587 288.261 592.189 288.331 591.817C288.412 591.435 288.492 591.083 288.573 590.761L291.968 578.419C292.038 578.158 292.209 577.926 292.481 577.725C292.752 577.524 293.079 577.423 293.461 577.423H297.022Z"
          fill="black"
        />
        <path
          d="M264.766 588.362C264.766 589.961 264.494 591.445 263.951 592.813C263.418 594.181 262.658 595.363 261.673 596.358C260.697 597.354 259.52 598.139 258.142 598.712C256.764 599.275 255.235 599.557 253.555 599.557C251.876 599.557 250.347 599.275 248.969 598.712C247.591 598.139 246.409 597.354 245.423 596.358C244.437 595.363 243.673 594.181 243.13 592.813C242.597 591.445 242.33 589.961 242.33 588.362C242.33 586.763 242.597 585.284 243.13 583.926C243.673 582.558 244.437 581.376 245.423 580.381C246.409 579.385 247.591 578.605 248.969 578.042C250.347 577.469 251.876 577.182 253.555 577.182C255.235 577.182 256.764 577.469 258.142 578.042C259.52 578.605 260.697 579.39 261.673 580.396C262.658 581.391 263.418 582.573 263.951 583.941C264.494 585.299 264.766 586.773 264.766 588.362ZM260.134 588.362C260.134 587.215 259.983 586.189 259.681 585.284C259.379 584.369 258.942 583.594 258.368 582.961C257.805 582.317 257.116 581.829 256.301 581.497C255.497 581.155 254.581 580.984 253.555 580.984C252.529 580.984 251.609 581.155 250.794 581.497C249.98 581.829 249.286 582.317 248.712 582.961C248.149 583.594 247.716 584.369 247.415 585.284C247.113 586.189 246.962 587.215 246.962 588.362C246.962 589.519 247.113 590.555 247.415 591.47C247.716 592.375 248.149 593.145 248.712 593.778C249.286 594.412 249.98 594.9 250.794 595.242C251.609 595.574 252.529 595.74 253.555 595.74C254.581 595.74 255.497 595.574 256.301 595.242C257.116 594.9 257.805 594.412 258.368 593.778C258.942 593.145 259.379 592.375 259.681 591.47C259.983 590.555 260.134 589.519 260.134 588.362Z"
          fill="black"
        />
        <path
          d="M239.027 577.423V599.316H236.689C236.337 599.316 236.04 599.26 235.799 599.15C235.557 599.029 235.321 598.828 235.09 598.546L224.181 584.666C224.211 585.018 224.231 585.365 224.242 585.707C224.262 586.049 224.272 586.365 224.272 586.657V599.316H220.273V577.423H222.657C222.848 577.423 223.009 577.433 223.14 577.454C223.281 577.464 223.407 577.499 223.517 577.559C223.628 577.609 223.734 577.68 223.834 577.77C223.935 577.861 224.045 577.982 224.166 578.133L235.15 592.074C235.11 591.691 235.08 591.324 235.059 590.972C235.039 590.61 235.029 590.268 235.029 589.946V577.423H239.027Z"
          fill="black"
        />
        <path
          d="M196.657 586.461H197.547C198.292 586.461 198.83 586.23 199.162 585.767L204.699 578.359C204.96 578.007 205.247 577.765 205.559 577.635C205.871 577.494 206.253 577.423 206.706 577.423H210.628L203.658 586.401C203.205 586.984 202.738 587.401 202.255 587.653C202.607 587.794 202.924 587.98 203.205 588.211C203.487 588.432 203.758 588.729 204.02 589.101L211.172 599.316H207.143C206.882 599.316 206.655 599.296 206.464 599.255C206.283 599.215 206.122 599.16 205.981 599.089C205.841 599.019 205.72 598.933 205.619 598.833C205.519 598.722 205.423 598.597 205.333 598.456L199.705 590.58C199.514 590.308 199.287 590.117 199.026 590.007C198.764 589.886 198.397 589.826 197.925 589.826H196.657V599.316H192.131V577.423H196.657V586.461Z"
          fill="black"
        />
        <path
          d="M188.826 588.362C188.826 589.961 188.554 591.445 188.011 592.813C187.478 594.181 186.719 595.363 185.733 596.358C184.757 597.354 183.581 598.139 182.203 598.712C180.825 599.275 179.296 599.557 177.616 599.557C175.936 599.557 174.407 599.275 173.029 598.712C171.651 598.139 170.469 597.354 169.484 596.358C168.498 595.363 167.733 594.181 167.19 592.813C166.657 591.445 166.391 589.961 166.391 588.362C166.391 586.763 166.657 585.284 167.19 583.926C167.733 582.558 168.498 581.376 169.484 580.381C170.469 579.385 171.651 578.605 173.029 578.042C174.407 577.469 175.936 577.182 177.616 577.182C179.296 577.182 180.825 577.469 182.203 578.042C183.581 578.605 184.757 579.39 185.733 580.396C186.719 581.391 187.478 582.573 188.011 583.941C188.554 585.299 188.826 586.773 188.826 588.362ZM184.194 588.362C184.194 587.215 184.043 586.189 183.741 585.284C183.44 584.369 183.002 583.594 182.429 582.961C181.866 582.317 181.177 581.829 180.362 581.497C179.557 581.155 178.642 580.984 177.616 580.984C176.59 580.984 175.67 581.155 174.855 581.497C174.04 581.829 173.346 582.317 172.773 582.961C172.209 583.594 171.777 584.369 171.475 585.284C171.173 586.189 171.023 587.215 171.023 588.362C171.023 589.519 171.173 590.555 171.475 591.47C171.777 592.375 172.209 593.145 172.773 593.778C173.346 594.412 174.04 594.9 174.855 595.242C175.67 595.574 176.59 595.74 177.616 595.74C178.642 595.74 179.557 595.574 180.362 595.242C181.177 594.9 181.866 594.412 182.429 593.778C183.002 593.145 183.44 592.375 183.741 591.47C184.043 590.555 184.194 589.519 184.194 588.362Z"
          fill="black"
        />
        <path
          d="M164.367 588.362C164.367 589.961 164.096 591.445 163.552 592.813C163.019 594.181 162.26 595.363 161.274 596.358C160.298 597.354 159.122 598.139 157.744 598.712C156.366 599.275 154.837 599.557 153.157 599.557C151.477 599.557 149.948 599.275 148.57 598.712C147.192 598.139 146.01 597.354 145.025 596.358C144.039 595.363 143.274 594.181 142.731 592.813C142.198 591.445 141.932 589.961 141.932 588.362C141.932 586.763 142.198 585.284 142.731 583.926C143.274 582.558 144.039 581.376 145.025 580.381C146.01 579.385 147.192 578.605 148.57 578.042C149.948 577.469 151.477 577.182 153.157 577.182C154.837 577.182 156.366 577.469 157.744 578.042C159.122 578.605 160.298 579.39 161.274 580.396C162.26 581.391 163.019 582.573 163.552 583.941C164.096 585.299 164.367 586.773 164.367 588.362ZM159.735 588.362C159.735 587.215 159.584 586.189 159.283 585.284C158.981 584.369 158.543 583.594 157.97 582.961C157.407 582.317 156.718 581.829 155.903 581.497C155.098 581.155 154.183 580.984 153.157 580.984C152.131 580.984 151.211 581.155 150.396 581.497C149.581 581.829 148.887 582.317 148.314 582.961C147.75 583.594 147.318 584.369 147.016 585.284C146.714 586.189 146.564 587.215 146.564 588.362C146.564 589.519 146.714 590.555 147.016 591.47C147.318 592.375 147.75 593.145 148.314 593.778C148.887 594.412 149.581 594.9 150.396 595.242C151.211 595.574 152.131 595.74 153.157 595.74C154.183 595.74 155.098 595.574 155.903 595.242C156.718 594.9 157.407 594.412 157.97 593.778C158.543 593.145 158.981 592.375 159.283 591.47C159.584 590.555 159.735 589.519 159.735 588.362Z"
          fill="black"
        />
        <path
          d="M131.609 595.846C132.333 595.846 132.927 595.76 133.389 595.589C133.862 595.408 134.239 595.172 134.521 594.88C134.803 594.588 134.999 594.256 135.109 593.884C135.22 593.502 135.275 593.11 135.275 592.707C135.275 592.265 135.21 591.867 135.079 591.515C134.948 591.163 134.737 590.867 134.445 590.625C134.154 590.374 133.772 590.183 133.299 590.052C132.836 589.921 132.263 589.856 131.579 589.856H127.731V595.846H131.609ZM127.731 580.863V586.778H130.704C131.337 586.778 131.901 586.727 132.394 586.627C132.886 586.516 133.299 586.345 133.631 586.114C133.963 585.883 134.214 585.581 134.385 585.209C134.566 584.826 134.657 584.369 134.657 583.836C134.657 583.293 134.581 582.83 134.43 582.448C134.29 582.065 134.068 581.759 133.766 581.527C133.465 581.296 133.083 581.13 132.62 581.029C132.157 580.919 131.609 580.863 130.975 580.863H127.731ZM130.975 577.423C132.444 577.423 133.696 577.564 134.732 577.846C135.778 578.117 136.628 578.51 137.282 579.023C137.946 579.526 138.429 580.139 138.73 580.863C139.032 581.588 139.183 582.397 139.183 583.293C139.183 583.795 139.108 584.278 138.957 584.741C138.816 585.204 138.59 585.641 138.278 586.054C137.976 586.456 137.584 586.823 137.101 587.155C136.618 587.487 136.045 587.769 135.381 588C136.87 588.352 137.971 588.925 138.685 589.72C139.399 590.515 139.756 591.546 139.756 592.813C139.756 593.738 139.575 594.598 139.213 595.393C138.861 596.187 138.343 596.877 137.659 597.46C136.975 598.043 136.13 598.501 135.124 598.833C134.129 599.155 132.982 599.316 131.684 599.316H123.205V577.423H130.975Z"
          fill="black"
        />
        <g filter="url(#filter4_d_9172_7380)">
          <path
            d="M1326.05 187H636.947C635.226 187 633.625 186.114 632.711 184.655L591.664 119.155C590.646 117.531 590.646 115.469 591.664 113.845L632.711 48.3449C633.625 46.8859 635.226 46 636.947 46H1326.05C1327.77 46 1329.38 46.8859 1330.29 48.3449L1371.34 113.845C1372.35 115.469 1372.35 117.531 1371.34 119.155L1330.29 184.655C1329.38 186.114 1327.77 187 1326.05 187Z"
            fill="url(#paint6_linear_9172_7380)"
          />
        </g>
        <foreignObject y="75" x="650" width="392" height="83">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{ fontSize: "60px", color: "#161F2C"  ,fontWeight:"400",lineHeight:"82.98px"}}
            className="text-oleo text-elips one-line p-0"
          >
            Upcoming Event!
          </div>
        </foreignObject>
        <foreignObject x="30" y="1472" width="1020" height="238">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display:"flex",
              justifyContent:"center",
              fontSize: "28px",
              color: "#FFFFFF",
              fontWeight: "700",
               alignItems:"center",
               height:"100%",
              lineHeight: "33.57px",
            }}
            className="text-inria text-elips three-line w-100 text-center"
          >
            {eventData?.about}
          </div>
        </foreignObject>
        <path
          d="M251.678 1876.43C255.934 1869.75 255.399 1870.58 255.522 1870.41C257.072 1868.22 257.891 1865.65 257.891 1862.97C257.891 1855.86 252.12 1850 245 1850C237.903 1850 232.109 1855.85 232.109 1862.97C232.109 1865.65 232.946 1868.29 234.546 1870.5L238.322 1876.43C234.285 1877.05 227.422 1878.9 227.422 1882.97C227.422 1884.45 228.39 1886.57 233.005 1888.22C236.227 1889.37 240.487 1890 245 1890C253.439 1890 262.578 1887.62 262.578 1882.97C262.578 1878.9 255.723 1877.05 251.678 1876.43ZM236.504 1869.21C236.491 1869.19 236.477 1869.17 236.463 1869.16C235.132 1867.32 234.453 1865.15 234.453 1862.97C234.453 1857.11 239.172 1852.34 245 1852.34C250.816 1852.34 255.547 1857.11 255.547 1862.97C255.547 1865.16 254.881 1867.25 253.621 1869.04C253.509 1869.19 254.097 1868.27 245 1882.55L236.504 1869.21ZM245 1887.66C235.782 1887.66 229.766 1884.95 229.766 1882.97C229.766 1881.64 232.857 1879.45 239.707 1878.6L244.012 1885.36C244.118 1885.52 244.264 1885.66 244.436 1885.75C244.609 1885.85 244.803 1885.9 245 1885.9C245.197 1885.9 245.391 1885.85 245.563 1885.75C245.736 1885.66 245.882 1885.52 245.988 1885.36L250.293 1878.6C257.143 1879.45 260.234 1881.64 260.234 1882.97C260.234 1884.93 254.272 1887.66 245 1887.66Z"
          fill="white"
        />
        <path
          d="M245 1857.11C241.769 1857.11 239.141 1859.74 239.141 1862.97C239.141 1866.2 241.769 1868.83 245 1868.83C248.231 1868.83 250.859 1866.2 250.859 1862.97C250.859 1859.74 248.231 1857.11 245 1857.11ZM245 1866.48C243.061 1866.48 241.484 1864.91 241.484 1862.97C241.484 1861.03 243.061 1859.45 245 1859.45C246.939 1859.45 248.516 1861.03 248.516 1862.97C248.516 1864.91 246.939 1866.48 245 1866.48Z"
          fill="white"
        />
         <foreignObject y="1852" x="295" width="557" height="36">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: "30px",
              color: "#FFFFFF",
              fontWeight: "400",
              lineHeight: "36px",
              letterSpacing: "2%",
            }}
            className="text-source text-elips  two-line p-0"
          >
            <u style={{ textDecoration: "none" }}>{eventData?.address}</u>
          </div>
        </foreignObject>
        <foreignObject y="1782" x="295" width="525" height="36">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: "30px",
              color: "#FFFFFF",
              fontWeight: "400",
              lineHeight: "36px",
              letterSpacing: "2%",
            }}
            className="text-yrsa    p-0"
          >
            <u style={{ textDecoration: "none" }}>
              {DateFormate(
                LocalFormate(eventData?.start_date, eventData?.date_timezone),
                LocalFormate(eventData?.end_date, eventData?.date_timezone)
              )}
              ,{" "}
              {moment(
                LocalFormate(eventData?.start_time, eventData?.date_timezone)
              ).format("LT")}{" "}
              to{" "}
              {moment(
                LocalFormate(eventData?.end_time, eventData?.date_timezone)
              ).format("LT")}{" "}
              (
              {timeZoneFormate(eventData?.start_time, eventData?.date_timezone)}
              )
            </u>
          </div>
        </foreignObject>
        <path
          d="M255.156 1797.97C256.019 1797.97 256.719 1797.27 256.719 1796.41C256.719 1795.54 256.019 1794.84 255.156 1794.84C254.293 1794.84 253.594 1795.54 253.594 1796.41C253.594 1797.27 254.293 1797.97 255.156 1797.97Z"
          fill="white"
        />
        <path
          d="M258.75 1783.12H256.719V1781.56C256.719 1780.7 256.019 1780 255.156 1780C254.293 1780 253.594 1780.7 253.594 1781.56V1783.12H246.484V1781.56C246.484 1780.7 245.785 1780 244.922 1780C244.059 1780 243.359 1780.7 243.359 1781.56V1783.12H236.328V1781.56C236.328 1780.7 235.629 1780 234.766 1780C233.903 1780 233.203 1780.7 233.203 1781.56V1783.12H231.25C227.804 1783.12 225 1785.93 225 1789.38V1813.75C225 1817.2 227.804 1820 231.25 1820H243.203C244.066 1820 244.766 1819.3 244.766 1818.44C244.766 1817.57 244.066 1816.88 243.203 1816.88H231.25C229.527 1816.88 228.125 1815.47 228.125 1813.75V1789.38C228.125 1787.65 229.527 1786.25 231.25 1786.25H233.203V1787.81C233.203 1788.68 233.903 1789.38 234.766 1789.38C235.629 1789.38 236.328 1788.68 236.328 1787.81V1786.25H243.359V1787.81C243.359 1788.68 244.059 1789.38 244.922 1789.38C245.785 1789.38 246.484 1788.68 246.484 1787.81V1786.25H253.594V1787.81C253.594 1788.68 254.293 1789.38 255.156 1789.38C256.019 1789.38 256.719 1788.68 256.719 1787.81V1786.25H258.75C260.473 1786.25 261.875 1787.65 261.875 1789.38V1798.28C261.875 1799.14 262.575 1799.84 263.438 1799.84C264.3 1799.84 265 1799.14 265 1798.28V1789.38C265 1785.93 262.196 1783.12 258.75 1783.12Z"
          fill="white"
        />
        <path
          d="M255.547 1801.09C250.334 1801.09 246.094 1805.33 246.094 1810.55C246.094 1815.76 250.334 1820 255.547 1820C260.759 1820 265 1815.76 265 1810.55C265 1805.33 260.759 1801.09 255.547 1801.09ZM255.547 1816.88C252.058 1816.88 249.219 1814.04 249.219 1810.55C249.219 1807.06 252.058 1804.22 255.547 1804.22C259.036 1804.22 261.875 1807.06 261.875 1810.55C261.875 1814.04 259.036 1816.88 255.547 1816.88Z"
          fill="white"
        />
        <path
          d="M257.812 1808.98H257.109V1807.34C257.109 1806.48 256.41 1805.78 255.547 1805.78C254.684 1805.78 253.984 1806.48 253.984 1807.34V1810.55C253.984 1811.41 254.684 1812.11 255.547 1812.11H257.812C258.675 1812.11 259.375 1811.41 259.375 1810.55C259.375 1809.68 258.675 1808.98 257.812 1808.98Z"
          fill="white"
        />
        <path
          d="M248.359 1797.97C249.222 1797.97 249.922 1797.27 249.922 1796.41C249.922 1795.54 249.222 1794.84 248.359 1794.84C247.496 1794.84 246.797 1795.54 246.797 1796.41C246.797 1797.27 247.496 1797.97 248.359 1797.97Z"
          fill="white"
        />
        <path
          d="M241.562 1804.77C242.425 1804.77 243.125 1804.07 243.125 1803.2C243.125 1802.34 242.425 1801.64 241.562 1801.64C240.7 1801.64 240 1802.34 240 1803.2C240 1804.07 240.7 1804.77 241.562 1804.77Z"
          fill="white"
        />
        <path
          d="M234.766 1797.97C235.629 1797.97 236.328 1797.27 236.328 1796.41C236.328 1795.54 235.629 1794.84 234.766 1794.84C233.903 1794.84 233.203 1795.54 233.203 1796.41C233.203 1797.27 233.903 1797.97 234.766 1797.97Z"
          fill="white"
        />
        <path
          d="M234.766 1804.77C235.629 1804.77 236.328 1804.07 236.328 1803.2C236.328 1802.34 235.629 1801.64 234.766 1801.64C233.903 1801.64 233.203 1802.34 233.203 1803.2C233.203 1804.07 233.903 1804.77 234.766 1804.77Z"
          fill="white"
        />
        <path
          d="M234.766 1811.56C235.629 1811.56 236.328 1810.86 236.328 1810C236.328 1809.14 235.629 1808.44 234.766 1808.44C233.903 1808.44 233.203 1809.14 233.203 1810C233.203 1810.86 233.903 1811.56 234.766 1811.56Z"
          fill="white"
        />
        <path
          d="M241.562 1811.56C242.425 1811.56 243.125 1810.86 243.125 1810C243.125 1809.14 242.425 1808.44 241.562 1808.44C240.7 1808.44 240 1809.14 240 1810C240 1810.86 240.7 1811.56 241.562 1811.56Z"
          fill="white"
        />
        <path
          d="M241.562 1797.97C242.425 1797.97 243.125 1797.27 243.125 1796.41C243.125 1795.54 242.425 1794.84 241.562 1794.84C240.7 1794.84 240 1795.54 240 1796.41C240 1797.27 240.7 1797.97 241.562 1797.97Z"
          fill="white"
        />
        <g filter="url(#filter5_d_9172_7380)">
          <path
            d="M1089.94 1425H578.056C576.274 1425 574.627 1424.05 573.732 1422.51L321.458 988.011C320.556 986.458 320.556 984.542 321.458 982.989L573.732 548.489C574.627 546.948 576.274 546 578.056 546H1089.94C1091.73 546 1093.37 546.948 1094.27 548.489L1346.54 982.989C1347.44 984.542 1347.44 986.458 1346.54 988.011L1094.27 1422.51C1093.37 1424.05 1091.73 1425 1089.94 1425Z"
            fill="white"
            fill-opacity="0.06"
            shape-rendering="crispEdges"
          />
        </g>

       <foreignObject y="666" x="492" width="550" height="640">
          <div
            className="d-flex flex-wrap align-items-center "
            style={{
              fontSize: "20px",
              color: "#363853",
              height: "100%",
              width: "100%",
            }}
            xmlns="http://www.w3.org/1999/xhtml"
          >
            <div className="w-100">
            <div
              style={{
                fontSize: "50px",
                color: "#FFDB98",
                fontWeight: 700,
                lineHeight: "50px",
                letterSpacing: "5%",
              }}
              className="text-source text-center p-0 w-100 mb-3"
            >
              <u className="mb-3">ACTIVITIES</u>
            </div>
            {eventData?.activities?.map((activities, i) => {
              return (
                <>
                {i<5 &&  <div
                    style={{
                      fontSize: "25px",
                      color: "#FFFFFF",

                      alignItems: "center",
                      lineHeight: "39px ",
                      fontWeight: "400",
                    }}
                    className="text-markoone text-elips w-100  p-0   "
                  >

                    <div className="mx-1">{i + 1}. </div> {activities}
                  </div>}
                 
                </>
              );
            })}
            </div>
          </div>
        </foreignObject>
   
      </g>
      <defs>
        <pattern
          id="pattern0_9172_7380"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_9172_7380"
            transform="scale(0.000366032 0.000244212)"
          />
        </pattern>
        <pattern
          id="pattern1_9172_7380"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1_9172_7380"
            transform="matrix(0.001362 0 0 0.00149172 -0.0597826 -0.113095)"
          />
        </pattern>
        <filter
          id="filter0_d_9172_7380"
          x="51.3945"
          y="255.457"
          width="942.09"
          height="144.463"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_7380"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_7380"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_9172_7380"
          x="22.0969"
          y="675.16"
          width="359.806"
          height="315.361"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.84021" />
          <feGaussianBlur stdDeviation="8.84021" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_7380"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_7380"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern2_9172_7380"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image2_9172_7380"
            transform="matrix(0.0025 0 0 0.00291367 0 -0.0827338)"
          />
        </pattern>
        <filter
          id="filter2_d_9172_7380"
          x="-6.90502"
          y="999.16"
          width="416.81"
          height="364.361"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.84021" />
          <feGaussianBlur stdDeviation="8.84021" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_7380"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_7380"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern3_9172_7380"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image3_9172_7380"
            transform="matrix(0.000514549 0 0 0.00059952 -0.0083744 0)"
          />
        </pattern>
        <filter
          id="filter3_d_9172_7380"
          x="42.9082"
          y="537"
          width="331.781"
          height="102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_7380"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_7380"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_9172_7380"
          x="545.9"
          y="1"
          width="871.199"
          height="231"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="22.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188333 0 0 0 0 0.4 0 0 0 0 0.220083 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_7380"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_7380"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_9172_7380"
          x="303.101"
          y="537.16"
          width="1061.8"
          height="914.361"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.84021" />
          <feGaussianBlur stdDeviation="8.84021" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_7380"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_7380"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_9172_7380"
          x1="1063"
          y1="1031.5"
          x2="1063"
          y2="-3.54744e-05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0C2329" />
          <stop offset="1" stop-color="#6D4706" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9172_7380"
          x1="540"
          y1="1750"
          x2="540"
          y2="1920"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0DBCEC" />
          <stop offset="1" stop-color="#FFC056" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9172_7380"
          x1="153.394"
          y1="685"
          x2="153.394"
          y2="963"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CCBEA" />
          <stop offset="1" stop-color="#EFCE96" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9172_7380"
          x1="144.343"
          y1="1009"
          x2="144.343"
          y2="1336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CCBEA" />
          <stop offset="1" stop-color="#EFCE96" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9172_7380"
          x1="17.5"
          y1="611.5"
          x2="362"
          y2="611.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0DBCEC" />
          <stop offset="1" stop-color="#FFC056" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9172_7380"
          x1="161.153"
          y1="545"
          x2="161.153"
          y2="631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CCBEA" />
          <stop offset="1" stop-color="#EFCE96" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9172_7380"
          x1="1373"
          y1="116.5"
          x2="591.612"
          y2="116.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ABD5B4" />
          <stop offset="1" stop-color="#DAE1AE" />
        </linearGradient>
        <clipPath id="clip0_9172_7380">
          <rect width="1080" height="1920" fill="white" />
        </clipPath>
        <image
          xmlns="http://www.w3.org/2000/svg"
          id="image0_9172_7380"
          width="2732"
          height="4096"
          xlinkHref={
            eventData?.event_media[1]?.file_name ??
            eventData?.user_id?.provider_banner_images[0]?.file_name
          }
          preserveAspectRatio="xMidYMid slice"
        />
        <image
          xmlns="http://www.w3.org/2000/svg"
          id="image1_9172_7380"
          width="822"
          height="822"
          xlinkHref={logo}
        />
        <image
          xmlns="http://www.w3.org/2000/svg"
          id="image2_9172_7380"
          width="400"
          height="400"
          xlinkHref={eventData?.user_id?.profile_picture}
          preserveAspectRatio="xMidYMid slice"
        />
        <image
          xmlns="http://www.w3.org/2000/svg"
          id="image3_9172_7380"
          width="1976"
          height="1668"
          xlinkHref={eventData?.event_media[0]?.file_name}
          preserveAspectRatio="xMidYMid slice"
        />
      </defs>
    </svg>
  );
};

export default EventStoryTemp3;
