import React from "react";
import {Empty} from 'antd';

const EmptyFile = () => {
    return (
        <div className="d-flex align-items-center justify-content-center w-100"
             style={{height: "60vh", transform: "scale(1.8)"}}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                   description={
                       <span>No Data Found</span>
                   }
            />
        </div>
    )
}
export default EmptyFile;