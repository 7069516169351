import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {claimConstants, refDescountConstants} from "./constant";
import {toast} from "react-toastify";

export const refCodeList = () => {
    return async (dispatch) => {
        let response;


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.user_referral_code_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            // body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: refDescountConstants.REFF_CODE_LIST,
                payload: response.data,
            });

        } else {
            toast.error(response?.message);
        }
    };
};

export const refCodeGenerate = (setShowBanner, getCodeList, setLoader) => {
    return async (dispatch) => {
        let response;


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.generate_referral_code}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            // body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: refDescountConstants.GENERATE_CODE,
                payload: response.data,
            });
            getCodeList()
            setShowBanner(false)
            setLoader(false)
        } else {
            setLoader(false)
            toast.error(response?.message);
        }
    };
};

export const getUserRefCode = (id) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("provider_id", id);

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_user_referral_code}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: refDescountConstants.CHECK_REF_CODE,
                payload: response.data,
            });
            // getCodeList()
            // setShowBanner(false)

        } else {
            toast.error(response?.message);
        }
    };
};