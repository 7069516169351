import React, { useEffect, useState } from "react";
import image from "../assets/images/event.png";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import img1 from "../assets/images/1.jpg";
import locationIcon from "../assets/images/Discovery.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBookingEvent,
  getBookingDetails,
} from "../action/myBokingConstant";
import moment from "moment";
import CancelEventPopup from "./CancelEventPopup";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import { capital } from "../Utils/capitalUtils";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../Utils/DateFormateUtils";
import userIcon from "../assets/images/userIconWithBg.png";

const ModalBtn = ({
  details,
  userAuth,
  HandleAcssept,
  setOpen,
  setIsOpen,
  navigate,
  selectId,
}) => {
  if (moment(details?.start_date_time) > moment(new Date())) {
    if (userAuth?._id !== details?.provider_id?._id) {
      if (
        details?.booking_status == "accept" &&
        details?.payment_status == "success"
      ) {
        // cancel modify btn
        return (
          <div className="w-100 d-flex">
            <div className="col-6">
              <button
                type="submit"
                className="btn_admina delete-button"
                onClick={() => {
                  setOpen(false);
                  setIsOpen(true);
                }}
                style={{ lineHeight: "30px" }}
              >
                {details.booking_type == "event"
                  ? "Cancel event"
                  : "Cancel Service"}
              </button>
            </div>
            <div className="col-6">
              <button
                type="submit"
                className="btn_admina delete-confirm-button"
                style={{ lineHeight: "30px" }}
                onClick={() =>
                  navigate(
                    details.booking_type == "event"
                      ? `/view-events/${details?.event_id?._id}/${details?.provider_id?._id}/${selectId}`
                      : `/modify-book-session/${details?.provider_id?._id}/${selectId}`
                  )
                }
              >
                Modify
              </button>
            </div>
          </div>
        );
      }
    } else {
      if (
        userAuth?._id == details?.user_id?._id &&
        details?.service_type == "vatual" &&
        details?.booking_status == "accept" &&
        details?.meeting_link == null
      ) {
        /// add miting link in condition
        //ganrate link
      } else {
        if (
          details?.booking_status == "pending" &&
          details?.modified_count == 0
        ) {
          // accept - decline
          return (
            <div className="w-100 d-flex">
              <div className="col-6">
                <button
                  type="submit"
                  className="btn_admina delete-button"
                  style={{ lineHeight: "26px" }}
                  onClick={() => {
                    HandleAcssept("accept", details._id);
                    setOpen(false);
                  }}
                >
                  Accept
                </button>
              </div>
              <div className="col-6">
                <button
                  type="submit"
                  className="btn_admina delete-confirm-button"
                  style={{ lineHeight: "23px" }}
                  onClick={() => {
                    HandleAcssept("decline", details._id);
                    setOpen(false);
                  }}
                >
                  Decline
                </button>
              </div>
            </div>
          );
        }
      }
    }
  }
};

const netWorkFees = (userAuth, details) => {
  if (userAuth?._id == details?.user_id?._id) {
    if (
      (details?.payment_history_id?.referral_code_info?.is_refree == true ||
        details?.modify_booking_payment_info?.is_referee == true) &&
      (details?.payment_history_id?.discount_code_info?.is_discount == false ||
        !details?.modify_booking_payment_info?.is_discount == false)
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees
                {/* ({details?.payment_history_id?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%) */}
              </p>
            </div>
            <div className="d-flex">
              {/* <p className="t-12-bold m-1" style={{
                            color: '#9E9E9E', textDecoration: 'line-through'
                        }}>$ {Number((details?.payment_history_id?.referral_code_info?.display_user_fees_amount || details?.modify_booking_payment_info?.referee_fees_amount_from_user) ?? 0).toFixed(2)}</p> */}
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (details?.payment_history_id?.fees_amount_from_user ||
                    details?.booking_payment_info?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          {/* <p className="yelloe-t-bold t-14-500 m-0"
                   style={{wordBreak: 'break-word'}}>You have received
                    a {(details?.payment_history_id?.referral_code_info?.display_user_fees_percentage || details?.modify_booking_payment_info.referee_fees_percentage) ?? 0}%
                    discount for using the referral
                    code.</p> */}
        </div>
      );
    } else if (
      (details?.payment_history_id?.referral_code_info?.is_refree == true ||
        details?.modify_booking_payment_info?.is_referee == true) &&
      (details?.payment_history_id?.discount_code_info?.is_discount == true ||
        details?.modify_booking_payment_info?.is_discount == true) &&
      (details?.payment_history_id?.discount_code_info?.is_admin_discount ==
        true ||
        details?.modify_booking_payment_info?.is_admin_discount == true)
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees
                {/* ({details?.payment_history_id?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%) */}
              </p>
            </div>
            <div className="d-flex">
              {/* <p className="t-12-bold m-1" style={{
                                color: '#9E9E9E', textDecoration: 'line-through'
                            }}>$ {Number((details?.payment_history_id?.referral_code_info?.display_user_fees_amount || details?.modify_booking_payment_info?.referee_fees_amount_from_user) ?? 0).toFixed(2)}</p> */}
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (details?.payment_history_id?.fees_amount_from_user ||
                    details?.booking_payment_info?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          {/* <p className="yelloe-t-bold t-14-500 m-0"
                       style={{wordBreak: 'break-word'}}>You have received
                        a {((Number(details?.payment_history_id?.referral_code_info?.display_user_fees_percentage ?? 0) + Number(details?.payment_history_id?.discount_percentage ?? 0)) || Number(details?.modify_booking_payment_info.referee_fees_percentage ?? 0) + Number(details?.modify_booking_payment_info.discount_percentage ?? 0)) ?? 0}%
                        discount for using the referral and discount
                        code.</p> */}
        </div>
      );
    } else if (
      (details?.payment_history_id?.referral_code_info?.is_refree == false ||
        details?.modify_booking_payment_info?.is_referee == false) &&
      (details?.payment_history_id?.discount_code_info?.is_discount == true ||
        details?.modify_booking_payment_info?.is_discount == true) &&
      (details?.payment_history_id?.discount_code_info?.is_admin_discount ==
        true ||
        details?.modify_booking_payment_info?.is_admin_discount == true)
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees
                {/* ({details?.payment_history_id?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%) */}
              </p>
            </div>
            <div className="d-flex">
              {/* <p className="t-12-bold m-1" style={{
                                color: '#9E9E9E', textDecoration: 'line-through'
                            }}>$ {Number((details?.payment_history_id?.referral_code_info?.display_user_fees_amount || details?.modify_booking_payment_info?.referee_fees_amount_from_user) ?? 0).toFixed(2)}</p> */}
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (details?.payment_history_id?.fees_amount_from_user ||
                    details?.booking_payment_info?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          {/* <p className="yelloe-t-bold t-14-500 m-0"
                       style={{wordBreak: 'break-word'}}>You have received
                        a {((Number(details?.payment_history_id?.discount_percentage ?? 0)) || Number(details?.modify_booking_payment_info.discount_percentage ?? 0)) ?? 0}%
                        discount for using the discount
                        code.</p> */}
        </div>
      );
    } else if (details?.modify_booking_payment_info?.is_referee) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees
                {/* ({details?.payment_history_id?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%) */}
              </p>
            </div>
            <div className="d-flex">
              {/* <p className="t-12-bold m-1" style={{
                                color: '#9E9E9E', textDecoration: 'line-through'
                            }}>$ {Number(details?.modify_booking_payment_info?.referee_fees_amount_from_user ?? 0).toFixed(2)}</p> */}
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (details?.payment_history_id?.fees_amount_from_user ||
                    details?.booking_payment_info?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          {/* <p className="yelloe-t-bold t-14-500 m-0"
                       style={{wordBreak: 'break-word'}}>You have received
                        a {details?.modify_booking_payment_info.referee_fees_percentage ?? 0}%
                        discount for using the referral
                        code.</p> */}
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <p className="t-12-bold m-1 ms-0">
            Network fees (
            {details?.payment_history_id?.fees_percentage_from_user ||
              details?.booking_payment_info?.fees_percentage_from_user ||
              0}
            %)
          </p>
          <p className="t-12-bold m-1">
            ${" "}
            {Number(
              (details?.payment_history_id?.fees_amount_from_user ||
                details?.booking_payment_info?.fees_amount_from_user) ??
                0
            )?.toFixed(2)}
          </p>
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p className="t-12-bold m-1 ms-0">
          Network fees (
          {details?.payment_history_id?.fees_percentage_from_user ||
            details?.booking_payment_info?.fees_percentage_from_user ||
            0}
          %)
        </p>
        <p className="t-12-bold m-1">
          ${" "}
          {Number(
            (details?.payment_history_id?.fees_amount_from_user ||
              details?.booking_payment_info?.fees_amount_from_user) ??
              0
          )?.toFixed(2)}
        </p>
      </div>
    );
  }
};

const initialNetworkFees = (userAuth, details) => {
  if (userAuth?._id == details?.user_id?._id) {
    if (
      details?.all_payment_details[0]?.referral_code_info?.is_refree &&
      details?.all_payment_details[0]?.discount_code_info?.is_discount == false
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees (
                {details?.all_payment_details[0]?.fees_percentage_from_user ||
                  details?.booking_payment_info?.fees_percentage_from_user ||
                  0}
                %)
              </p>
            </div>
            <div className="d-flex">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                }}
              >
                ${" "}
                {Number(
                  details?.all_payment_details[0]?.referral_code_info
                    ?.display_user_fees_amount ?? 0
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (details?.all_payment_details[0]?.fees_amount_from_user ||
                    details?.all_payment_details[0]?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          <p
            className="yelloe-t-bold t-14-500 m-0"
            style={{ wordBreak: "break-word" }}
          >
            You have received a{" "}
            {details?.all_payment_details[0]?.referral_code_info
              ?.display_user_fees_percentage ?? 0}
            % discount for using the referral code.
          </p>
        </div>
      );
    } else if (
      details?.all_payment_details[0]?.referral_code_info?.is_refree &&
      details?.all_payment_details[0]?.discount_code_info?.is_discount ==
        true &&
      details?.all_payment_details[0]?.discount_code_info?.is_admin_discount ==
        true
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees (
                {details?.all_payment_details[0]?.fees_percentage_from_user ||
                  details?.booking_payment_info?.fees_percentage_from_user ||
                  0}
                %)
              </p>
            </div>
            <div className="d-flex">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                }}
              >
                ${" "}
                {Number(
                  details?.all_payment_details[0]?.referral_code_info
                    ?.display_user_fees_amount ?? 0
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (details?.all_payment_details[0]?.fees_amount_from_user ||
                    details?.all_payment_details[0]?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          <p
            className="yelloe-t-bold t-14-500 m-0"
            style={{ wordBreak: "break-word" }}
          >
            You have received a{" "}
            {Number(
              details?.all_payment_details[0]?.referral_code_info
                ?.display_user_fees_percentage ?? 0
            ) +
              Number(
                details?.all_payment_details[0]?.discount_percentage ?? 0
              ) ?? 0}
            % discount for using the referral and discount code.
          </p>
        </div>
      );
    } else if (
      details?.all_payment_details[0]?.referral_code_info?.is_refree == false &&
      details?.all_payment_details[0]?.discount_code_info?.is_discount ==
        true &&
      details?.all_payment_details[0]?.discount_code_info?.is_admin_discount ==
        true
    ) {
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className="t-12-bold m-1 ms-0">
              Network fees (
              {details?.all_payment_details[0]?.fees_percentage_from_user ||
                details?.booking_payment_info?.fees_percentage_from_user ||
                0}
              %)
            </p>
          </div>
          <div className="d-flex">
            <p
              className="t-12-bold m-1"
              style={{
                color: "#9E9E9E",
                textDecoration: "line-through",
              }}
            >
              ${" "}
              {Number(
                details?.all_payment_details[0]?.referral_code_info
                  ?.display_user_fees_amount ?? 0
              ).toFixed(2)}
            </p>
            <p className="t-12-bold m-1">
              ${" "}
              {Number(
                (details?.all_payment_details[0]?.fees_amount_from_user ||
                  details?.all_payment_details[0]?.fees_amount_from_user) ??
                  0
              )?.toFixed(2)}
            </p>
          </div>
        </div>
        <p
          className="yelloe-t-bold t-14-500 m-0"
          style={{ wordBreak: "break-word" }}
        >
          You have received a{" "}
          {Number(details?.all_payment_details[0]?.discount_percentage ?? 0) ??
            0}
          % discount for using the discount code.
        </p>
      </div>;
    } else {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <p className="t-12-bold m-1 ms-0">
            Network fees (
            {details?.all_payment_details[0]?.fees_percentage_from_user ||
              details?.booking_payment_info?.fees_percentage_from_user ||
              0}
            %)
          </p>
          <p className="t-12-bold m-1">
            ${" "}
            {Number(
              (details?.all_payment_details[0]?.fees_amount_from_user ||
                details?.all_payment_details[0]?.fees_amount_from_user) ??
                0
            )?.toFixed(2)}
          </p>
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p className="t-12-bold m-1 ms-0">
          Network fees (
          {details?.all_payment_details[0]?.fees_percentage_from_user ||
            details?.booking_payment_info?.fees_percentage_from_user ||
            0}
          %)
        </p>
        <p className="t-12-bold m-1">
          ${" "}
          {Number(
            (details?.all_payment_details[0]?.fees_amount_from_user ||
              details?.all_payment_details[0]?.fees_amount_from_user) ??
              0
          )?.toFixed(2)}
        </p>
      </div>
    );
  }
};

const modifyNetworkFees = (ele, details, userAuth) => {
  if (userAuth?._id == details?.user_id?._id) {
    if (
      ele?.referral_code_info?.is_refree &&
      ele?.discount_code_info?.is_discount == false
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1">
                Network fees (
                {ele?.fees_percentage_from_user ||
                  ele?.fees_percentage_from_user ||
                  0}
                %)
              </p>
            </div>
            <div className="d-flex">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                }}
              >
                ${" "}
                {Number(
                  ele?.referral_code_info?.display_user_fees_amount ?? 0
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (ele?.fees_amount_from_user || ele?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          <p
            className="yelloe-t-bold t-14-500 m-0"
            style={{ wordBreak: "break-word" }}
          >
            You have received a{" "}
            {ele?.referral_code_info?.display_user_fees_percentage ?? 0}%
            discount for using the referral code.
          </p>
        </div>
      );
    } else if (
      ele?.referral_code_info?.is_refree &&
      ele?.discount_code_info?.is_discount == true &&
      ele?.discount_code_info?.is_admin_discount == true
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1">
                Network fees (
                {ele?.fees_percentage_from_user ||
                  ele?.fees_percentage_from_user ||
                  0}
                %)
              </p>
            </div>
            <div className="d-flex">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                }}
              >
                ${" "}
                {Number(
                  ele?.referral_code_info?.display_user_fees_amount ?? 0
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (ele?.fees_amount_from_user || ele?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          <p
            className="yelloe-t-bold t-14-500 m-0"
            style={{ wordBreak: "break-word" }}
          >
            You have received a{" "}
            {ele?.referral_code_info?.display_user_fees_percentage +
              ele?.discount_percentage ?? 0}
            % discount for using the referral and descount code.
          </p>
        </div>
      );
    } else if (
      ele?.referral_code_info?.is_refree == false &&
      ele?.discount_code_info?.is_discount == true &&
      ele?.discount_code_info?.is_admin_discount == true
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="t-12-bold m-1">
                Network fees (
                {ele?.fees_percentage_from_user ||
                  ele?.fees_percentage_from_user ||
                  0}
                %)
              </p>
            </div>
            <div className="d-flex">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                }}
              >
                ${" "}
                {Number(
                  ele?.referral_code_info?.display_user_fees_amount ?? 0
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1">
                ${" "}
                {Number(
                  (ele?.fees_amount_from_user || ele?.fees_amount_from_user) ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
          <p
            className="yelloe-t-bold t-14-500 m-0"
            style={{ wordBreak: "break-word" }}
          >
            You have received a {ele?.discount_percentage ?? 0}% discount for
            using the discount code.
          </p>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <p className="t-12-bold m-1 ms-0">
            Network fees ({ele?.fees_percentage_from_user || 0}%)
          </p>
          <p className="t-12-bold m-1">
            $ {ele?.fees_amount_from_user?.toFixed(2) || 0.0}
          </p>
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p className="t-12-bold m-1 ms-0">
          Network fees ({ele?.fees_percentage_from_user || 0}%)
        </p>
        <p className="t-12-bold m-1">
          $ {ele?.fees_amount_from_user?.toFixed(2) || 0.0}
        </p>
      </div>
    );
  }
};

const UserBookingViewPopup = ({
  open,
  setOpen,
  miles,
  selectId,
  getEvent,
  HandleAcssept,
  setSelectId,
}) => {
  const momentZone = moment.tz.guess();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const details = useSelector(
    (state) => state?.myBookingDetails?.bookingDetails
  );
  const { myBookingDetails } = useSelector((state) => state);
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const navigate = useNavigate();

  const [center, setCenter] = useState();

  useEffect(() => {
    if ("geolocation" in navigator) {
      //check geolocation available
      //try to get user current location using getCurrentPosition() method
      navigator?.geolocation?.getCurrentPosition(async (position) => {
        // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
        await setCenter({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    } else {
      console.log("Browser doesn't support geolocation!");
    }
  }, []);

  const gateData = () => {
    const payload = {
      id: selectId,
      latLong: center,
    };
    dispatch(getBookingDetails(payload));
  };

  useEffect(() => {
    if (selectId) {
      gateData();
    }
  }, [selectId]);

  const deleteEvent = () => {
    const isId = details.booking_type !== "event" ? details?._id : details?._id;
    const payload = {
      id: isId,
      type: details.booking_type !== "event" ? "booking_id" : "booking_id",
    };
    dispatch(
      deleteBookingEvent(
        payload,
        (a) => {
          setIsOpen(a);
        },
        () => {
          getEvent();
        }
      )
    );
  };

  const checkIsRefund = (type) => {
    if (type.includes("refund")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal
        centered
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        dialogClassName="delete-modal user-details-page"
      >
        <div
          className="Congratulations_msg auto-modla"
          style={{ width: "100%" }}
        >
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0 t-24-bold">Booking Details</p>
              <IconButton
                sx={{
                  background:
                    "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)",
                }}
                onClick={() => {
                  setSelectId("");
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              className="inner-gred box_shadow mt-4"
              style={{
                background:
                  details.booking_type == "event" && "rgba(255, 165, 0, 0.10)",
              }}
            >
              <div
                className="d-flex"
                onClick={() => {
                  navigate(
                    details?.event_id
                      ? `/view-events/${details?.event_id?._id}/${details?.provider_id?._id}`
                      : `/provider_details/${details?.provider_id?._id}`
                  );
                }}
              >
                {details.booking_type == "event" ? (
                  <div className="p_image_shape me-2 me-sm-3">
                    <img
                      src={
                        details?.event_id?.event_media?.length
                          ? details?.event_id?.event_media[0]?.file_name
                          : details?.provider_id?.provider_banner_images[0]
                              ?.file_name
                      }
                    />
                  </div>
                ) : (
                  <div className="p_image_shape me-2 me-sm-3">
                    <img
                      src={
                        details?.provider_id?.provider_banner_images[0]
                          ?.file_name
                      }
                    />
                  </div>
                )}
                <div>
                  <p className="t-15-bold mb-1" style={{ color: "black" }}>
                    {details?.service_id?.service_name ||
                      details?.event_id?.event_name}
                  </p>
                  {details.booking_type !== "event" && (
                    <div className="d-flex gap-2">
                      <div
                        className="p_image_shape"
                        style={{
                          width: "32px",
                          height: "35px",
                          minWidth: "32px",
                        }}
                      >
                        <img
                          src={
                            details?.provider_id?.profile_picture || userIcon
                          }
                          style={{
                            right: "0px",
                            bottom: "0px",
                            height: "30px",
                            width: "32px",
                          }}
                          onError={(e) => (e.target.src = userIcon)}
                        />
                      </div>
                      <p className="t-14-bold">{details?.provider_id?.name}</p>
                    </div>
                  )}
                  {details.booking_type == "event" && (
                    <div className="d-flex align-items-center">
                      <p className="m-0 t-12-bold">
                        {moment(
                          LocalFormate(
                            details?.event_id?.start_date,
                            details?.event_id?.date_timezone
                          )
                        ).format("MMM D YYYY")}
                      </p>
                      <FiberManualRecordIcon
                        style={{ transform: "scale(0.3)" }}
                      />
                      <p className="m-0 t-12-bold">
                        {moment(
                          LocalFormate(
                            details?.event_id?.start_time,
                            details?.event_id?.date_timezone
                          )
                        ).format("LT")}{" "}
                        to{" "}
                        {moment(
                          LocalFormate(
                            details?.event_id?.end_time,
                            details?.event_id?.date_timezone
                          )
                        ).format("LT")}{" "}
                        (
                        {timeZoneFormate(
                          details?.event_id?.start_time,
                          details?.event_id?.date_timezone
                        )}
                        )
                      </p>
                    </div>
                  )}
                  <div className="d-flex gap-1">
                    <img src={locationIcon} alt="" />
                    <p className="yelloe-t-bold m-0">{miles} Mile Away</p>
                  </div>
                  {details.booking_type !== "event" && (
                    <div
                      className="d-flex gap-1"
                      style={{ alignItems: "baseline" }}
                    >
                      <div style={{ color: "#FFC107" }}>
                        {details?.provider_id?.average_rating >= 0.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 1.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 2.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 3.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 4.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                      </div>
                      <p
                        style={{ fontSize: "14px", fontWeight: "600" }}
                        className="m-0"
                      >
                        ({Math.round(details?.provider_id?.average_rating)} / 5)
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="inner-gred box_shadow mt-2">
              <div
                className="hideScroll"
                style={{
                  maxHeight: "580px",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                {details.booking_type !== "event" ? (
                  <>
                    <p className="t-18-bold">
                      {details?.booking_type == "hire_provider"
                        ? "Hire Details"
                        : "Booking Schedule"}
                    </p>
                    {userAuth?._id !== details?.user_id?._id ? (
                      <div className="border-gred">
                        <div className="inner-gred" style={{ padding: "0px" }}>
                          <div className="d-flex align-items-center">
                            <div
                              className="p_image_shape me-2 me-sm-0"
                              style={{ transform: "scale(0.7)" }}
                            >
                              <img
                                src={
                                  details?.user_id?.profile_picture || userIcon
                                }
                                onError={(e) => (e.target.src = userIcon)}
                              />
                            </div>
                            <div>
                              <p className="m-0 t-16-bold">
                                {details?.user_id?.name}
                              </p>
                              <p className="m-0 t-10-bold">
                                {capital(details?.user_id?.user_type)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="border-gred">
                      <div className="inner-gred">
                        {/*<div className="d-flex justify-content-between align-items-center">*/}
                        {/*    <p className="t-12-bold m-1">Date</p>*/}
                        {/*    <p className="t-12-bold m-1">{moment(details?.booking_date).format("D MMM YYYY")}</p>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex justify-content-between align-items-center">*/}
                        {/*    <p className="t-12-bold m-1">Time</p>*/}
                        {/*    <p className="t-12-bold m-1">{moment(details?.start_date_time).format('LT')} to {moment(details.end_date_time).format('LT')}</p>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex justify-content-between align-items-center">*/}
                        {/*    <p className="t-12-bold m-1">Type</p>*/}
                        {/*    <p className="t-12-bold m-1">{details?.service_type}</p>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex justify-content-between align-items-center">*/}
                        {/*    <p className="t-12-bold mb-1">Location </p>*/}
                        {/*    <p className="t-12-bold mb-1">{details?.location_type}</p>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex justify-content-end">*/}
                        {/*    <p className="t-10-bold mt-1">{details?.service_id?.address}</p>*/}
                        {/*</div>*/}
                        <div className="d-flex align-items-center">
                          {/*<p className="t-12-bold mb-1">{DateFormate(LocalFormate(details?.start_date_time), LocalFormate(details.end_date_time))}, </p>*/}
                          <p className="t-12-bold mb-1 white-space">
                            {moment(
                              LocalFormate(
                                details?.booking_date ||
                                  details?.event_id?.start_date,
                                details?.service_id?.date_timezone ||
                                  details?.event_id?.date_timezone
                              )
                            ).format("MMM D YYYY")}
                            ,{" "}
                          </p>
                          <p className="t-12-bold mb-1 white-space">
                            {" "}
                            {moment(
                              LocalFormate(
                                details?.start_date_time,
                                details?.service_id?.date_timezone ||
                                  details?.event_id?.date_timezone
                              )
                            ).format("LT")}{" "}
                            to{" "}
                            {moment(
                              LocalFormate(
                                details.end_date_time,
                                details?.service_id?.date_timezone ||
                                  details?.event_id?.date_timezone
                              )
                            ).format("LT")}{" "}
                            (
                            {timeZoneFormate(
                              details?.start_date_time,
                              details?.service_id?.date_timezone ||
                                details?.event_id?.date_timezone
                            )}
                            )
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="t-12-bold mb-1">Type : </p>
                          <p className="t-12-bold mb-1 ms-1">
                            {capital(details?.service_type) ||
                              (details?.booking_type == "hire_provider"
                                ? "Hire Request"
                                : capital(details?.booking_type))}
                          </p>
                          <p></p>
                        </div>
                        {details?.booking_type == "hire_provider" ? (
                          <div className="d-flex align-items-center">
                            <p className="t-12-bold mb-1">Hire Amount : </p>
                            <p className="t-12-bold mb-1 ms-1">
                              $ {Number(details?.amount).toFixed(2)}
                            </p>
                            <p></p>
                          </div>
                        ) : null}
                        {details?.service_type == "in_person" && (
                          <>
                            <div className="d-flex align-items-start">
                              <p
                                className="t-12-bold mb-1"
                                style={{ textWrap: "nowrap" }}
                              >
                                {details.location_type == "their_place"
                                  ? "Their Place"
                                  : "My Place"}{" "}
                                :{" "}
                              </p>
                              <p className="t-12-bold mb-1 ms-1">
                                {details?.address ||
                                  details?.service_id?.address}
                              </p>
                            </div>
                          </>
                        )}
                        {details?.description && (
                          <div className="d-flex align-items-start">
                            <p
                              className="t-12-bold mb-1"
                              style={{ textWrap: "nowrap" }}
                            >
                              Location Note :{" "}
                            </p>
                            <p className="t-12-bold mb-1 ms-1">
                              {details?.description}
                            </p>
                            <p></p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : userAuth?._id !== details?.user_id?._id ? (
                  <>
                    <p className="t-18-bold">
                      {details?.booking_type == "hire_provider"
                        ? "Hire Details"
                        : "Booking Schedule"}
                    </p>
                    <div className="border-gred">
                      <div className="inner-gred" style={{ padding: "0px" }}>
                        <div className="d-flex align-items-center">
                          <div
                            className="p_image_shape me-2 me-sm-0"
                            style={{ transform: "scale(0.7)" }}
                          >
                            <img
                              src={
                                details?.user_id?.profile_picture || userIcon
                              }
                              onError={(e) => (e.target.src = userIcon)}
                            />
                          </div>
                          <div>
                            <p className="m-0 t-16-bold">
                              {details?.user_id?.name}
                            </p>
                            <p className="m-0 t-10-bold">
                              {capital(details?.user_id?.user_type)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {details?.event_id?.is_virtual_event ? (
                      <div className="border-gred">
                        <div className="inner-gred">
                          <div className="d-flex align-items-center">
                            <p className="t-12-bold mb-1">Type : </p>
                            <p className="t-12-bold mb-1 ms-1">Virtual</p>
                          </div>
                          <div className="d-flex align-items-center">
                            <a
                              href={details?.event_id?.event_join_link}
                              target="_blank"
                              style={{
                                fontSize: "14px",
                                color: "#090986",
                                textDecoration: "underline",
                              }}
                            >
                              {details?.event_id?.event_join_link}
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {details?.booking_type !== "hire_provider" ? (
                  <>
                    <p className="t-18-bold">Payment Details</p>

                    {details?.payment_history_id?.payment_status ==
                    "success" ? (
                      <div className="border-gred">
                        <div className="inner-gred">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="t-12-bold m-1">Transaction ID</p>
                            <p className="t-12-bold m-1">
                              {details?.payment_history_id?.transaction_id}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="t-12-bold m-1">Payment Time</p>
                            <p className="t-12-bold m-1 white-space">
                              {DateFormate(
                                LocalFormate(
                                  details?.payment_history_id?.createdAt,
                                  details?.payment_history_id?.date_timezone
                                ),
                                null,
                                true
                              )}{" "}
                              (
                              {timeZoneFormate(
                                details?.payment_history_id?.createdAt,
                                details?.payment_history_id?.date_timezone
                              )}
                              )
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="t-12-bold m-1">Status</p>
                            <p
                              className="t-12-bold m-1"
                              style={{ color: "green" }}
                            >
                              {capital(
                                details?.payment_history_id?.payment_status
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="border-gred">
                      <div className="inner-gred">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="t-12-bold m-1 ms-0">Participants</p>
                          <p className="t-12-bold m-1">
                            {details.no_of_participants || 0}
                          </p>
                        </div>

                        {(details?.payment_history_id?.discount_code_info
                          ?.is_discount == true ||
                          details?.modify_booking_payment_info?.is_discount ==
                            true) &&
                        (details?.payment_history_id?.discount_code_info
                          ?.is_admin_discount == false ||
                          details?.modify_booking_payment_info
                            ?.is_admin_discount == false) ? (
                          <div className="d-flex justify-content-between align-items-start">
                            <div className="d-flex flex-column">
                              <p className="t-12-bold m-1 ms-0">
                                Package amount
                              </p>
                              {/* <p className="yelloe-t-bold t-14-500 m-0"
                                                       style={{width: "92%"}}>You
                                                        have received
                                                        a {(details?.payment_history_id?.discount_percentage || details?.modify_booking_payment_info?.discount_percentage) ?? 0}%
                                                        discount for using the discount code.</p> */}
                            </div>
                            <div className="d-flex">
                              <p
                                className="t-12-bold m-1"
                                style={{
                                  color: "#9E9E9E",
                                  textDecoration: "line-through",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                ${" "}
                                {Number(
                                  (details?.payment_history_id
                                    ?.discount_code_info
                                    ?.amount_without_discount ||
                                    details?.modify_booking_payment_info
                                      ?.amount_without_discount) ??
                                    0
                                )?.toFixed(2) || 0.0}
                              </p>
                              <p
                                className="t-12-bold m-1"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                ${" "}
                                {Number(
                                  (details?.payment_history_id?.total_amount ||
                                    details?.booking_payment_info
                                      ?.total_amount) ??
                                    0
                                )?.toFixed(2) || 0.0}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="t-12-bold m-1 ms-0">Package amount</p>
                            <p className="t-12-bold m-1">
                              ${" "}
                              {Number(
                                (details?.payment_history_id?.total_amount ||
                                  details?.booking_payment_info
                                    ?.total_amount) ??
                                  0
                              )?.toFixed(2)}
                            </p>
                          </div>
                        )}

                        {netWorkFees(userAuth, details)}

                        {/*{(userAuth?._id == details?.user_id?._id) && (details?.payment_history_id?.referral_code_info?.is_refree || details?.modify_booking_payment_info?.is_referee == true) ?*/}
                        {/*    <div>*/}
                        {/*        <div className="d-flex justify-content-between align-items-center">*/}
                        {/*            <div>*/}
                        {/*                <p className="t-12-bold m-1">Network fees*/}
                        {/*                    ({details?.payment_history_id?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%)</p>*/}
                        {/*            </div>*/}
                        {/*            <div className="d-flex">*/}
                        {/*                <p className="t-12-bold m-1" style={{*/}
                        {/*                    color: '#9E9E9E',*/}
                        {/*                    textDecoration: 'line-through'*/}
                        {/*                }}>$ {Number((details?.payment_history_id?.referral_code_info?.display_user_fees_amount || details?.modify_booking_payment_info?.referee_fees_amount_from_user) ?? 0).toFixed(2)}</p>*/}
                        {/*                <p className="t-12-bold m-1">$ {Number((details?.payment_history_id?.fees_amount_from_user || details?.booking_payment_info?.fees_amount_from_user) ?? 0)?.toFixed(2)}</p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <p className="yelloe-t-bold t-14-500 m-0"*/}
                        {/*           style={{wordBreak: 'break-word'}}>You have received*/}
                        {/*            a {(details?.payment_history_id?.referral_code_info?.display_user_fees_percentage || details?.modify_booking_payment_info.referee_fees_percentage) ?? 0}%*/}
                        {/*            discount for using the referral*/}
                        {/*            code.</p>*/}
                        {/*    </div>*/}
                        {/*    : details?.modify_booking_payment_info?.is_referee ?*/}
                        {/*        <div>*/}
                        {/*            <div className="d-flex justify-content-between align-items-center">*/}
                        {/*                <div>*/}
                        {/*                    <p className="t-12-bold m-1">Network fees*/}
                        {/*                        ({details?.payment_history_id?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%)</p>*/}
                        {/*                </div>*/}
                        {/*                <div className="d-flex">*/}
                        {/*                    <p className="t-12-bold m-1" style={{*/}
                        {/*                        color: '#9E9E9E',*/}
                        {/*                        textDecoration: 'line-through'*/}
                        {/*                    }}>$ {Number(details?.modify_booking_payment_info?.referee_fees_amount_from_user ?? 0).toFixed(2)}</p>*/}
                        {/*                    <p className="t-12-bold m-1">$ {Number((details?.payment_history_id?.fees_amount_from_user || details?.booking_payment_info?.fees_amount_from_user) ?? 0)?.toFixed(2)}</p>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p className="yelloe-t-bold t-14-500 m-0"*/}
                        {/*               style={{wordBreak: 'break-word'}}>You have received*/}
                        {/*                a {details?.modify_booking_payment_info.referee_fees_percentage ?? 0}%*/}
                        {/*                discount for using the referral*/}
                        {/*                code.</p>*/}
                        {/*        </div>*/}
                        {/*        :*/}
                        {/*        <div className="d-flex justify-content-between align-items-center">*/}
                        {/*            <p className="t-12-bold m-1">Network fees*/}
                        {/*                ({details?.payment_history_id?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%)</p>*/}
                        {/*            <p className="t-12-bold m-1">$ {Number((details?.payment_history_id?.fees_amount_from_user || details?.booking_payment_info?.fees_amount_from_user) ?? 0)?.toFixed(2)}</p>*/}
                        {/*        </div>}*/}

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="t-12-bold m-1 ms-0">
                            Tax (
                            {details?.payment_history_id
                              ?.sales_tax_percentage ||
                              details?.booking_payment_info
                                ?.sales_tax_percentage ||
                              0}
                            %)
                          </p>
                          <p className="t-12-bold m-1">
                            ${" "}
                            {Number(
                              (details?.payment_history_id
                                ?.sales_tax_amount_from_user ||
                                details?.booking_payment_info
                                  ?.sales_tax_amount_from_user) ??
                                0
                            )?.toFixed(2) || 0.0}
                          </p>
                        </div>
                        <hr style={{ border: "1px dashed black" }} />
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="t-12-bold m-1 ms-0">Total Amount</p>
                          <p className="t-12-bold m-1">
                            ${" "}
                            {Number(
                              (details?.payment_history_id?.total_paid_amount ||
                                details?.booking_payment_info
                                  ?.total_paid_amount) ??
                                0
                            )?.toFixed(2) || 0.0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {
                  // details?.is_cancelled == true ?

                  <>
                    {/*........ initial payment details ........*/}
                    {details?.all_payment_details?.length > 1 && (
                      <div>
                        <p className="t-18-bold">Initial Payment Details</p>
                        {
                          <div>
                            <div className="border-gred">
                              <div className="inner-gred">
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="t-12-bold m-1">
                                    Transaction ID
                                  </p>
                                  <p className="t-12-bold m-1">
                                    {
                                      details?.all_payment_details[0]
                                        ?.transaction_id
                                    }
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="t-12-bold m-1">Payment Time</p>
                                  <p className="t-12-bold m-1 white-space">
                                    {DateFormate(
                                      LocalFormate(
                                        details?.all_payment_details[0]
                                          ?.createdAt,
                                        details?.all_payment_details[0]
                                          ?.date_timezone
                                      ),
                                      null,
                                      true
                                    )}{" "}
                                    (
                                    {timeZoneFormate(
                                      details?.all_payment_details[0]
                                        ?.createdAt,
                                      details?.all_payment_details[0]
                                        ?.date_timezone
                                    )}
                                    )
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="t-12-bold m-1">Status</p>
                                  <p
                                    className="t-12-bold m-1"
                                    style={{
                                      color:
                                        details?.all_payment_details[0]
                                          ?.payment_status == "success"
                                          ? "green"
                                          : "red",
                                    }}
                                  >
                                    {capital(
                                      details?.all_payment_details[0]
                                        ?.payment_status
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="border-gred">
                              <div className="inner-gred">
                                {/*<div className="d-flex justify-content-between align-items-center">*/}
                                {/*    <p className="t-12-bold m-1">Participants</p>*/}
                                {/*    <p className="t-12-bold m-1">{details.no_of_participants || 0}</p>*/}
                                {/*</div>*/}
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="t-12-bold m-1 ms-0">
                                    Package amount
                                  </p>
                                  <p className="t-12-bold m-1">
                                    ${" "}
                                    {details?.all_payment_details[0]?.total_amount?.toFixed(
                                      2
                                    ) || 0.0}
                                  </p>
                                </div>
                                {/*<div className="d-flex justify-content-between align-items-center">*/}
                                {/*    <p className="t-12-bold m-1">Network fees*/}
                                {/*        ({details?.all_payment_details[0]?.fees_percentage_from_user || 0}%)</p>*/}
                                {/*    <p className="t-12-bold m-1">$ {(details?.all_payment_details[0]?.fees_amount_from_user)?.toFixed(2) || 0.00}</p>*/}
                                {/*</div>*/}
                                {/*{(userAuth?._id == details?.user_id?._id && details?.all_payment_details[0]?.referral_code_info?.is_refree) ?*/}
                                {/*    <div>*/}
                                {/*        <div*/}
                                {/*            className="d-flex justify-content-between align-items-center">*/}
                                {/*            <div>*/}
                                {/*                <p className="t-12-bold m-1">Network fees*/}
                                {/*                    ({details?.all_payment_details[0]?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%)</p>*/}
                                {/*            </div>*/}
                                {/*            <div className="d-flex">*/}
                                {/*                <p className="t-12-bold m-1" style={{*/}
                                {/*                    color: '#9E9E9E', textDecoration: 'line-through'*/}
                                {/*                }}>$ {Number(details?.all_payment_details[0]?.referral_code_info?.display_user_fees_amount ?? 0).toFixed(2)}</p>*/}
                                {/*                <p className="t-12-bold m-1">$ {Number((details?.all_payment_details[0]?.fees_amount_from_user || details?.all_payment_details[0]?.fees_amount_from_user) ?? 0)?.toFixed(2)}</p>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <p className="yelloe-t-bold t-14-500 m-0"*/}
                                {/*           style={{wordBreak: 'break-word'}}>You have*/}
                                {/*            received*/}
                                {/*            a {details?.all_payment_details[0]?.referral_code_info?.display_user_fees_percentage ?? 0}%*/}
                                {/*            discount for using the referral*/}
                                {/*            code.</p>*/}
                                {/*    </div> : <div*/}
                                {/*        className="d-flex justify-content-between align-items-center">*/}
                                {/*        <p className="t-12-bold m-1">Network fees*/}
                                {/*            ({details?.all_payment_details[0]?.fees_percentage_from_user || details?.booking_payment_info?.fees_percentage_from_user || 0}%)</p>*/}
                                {/*        <p className="t-12-bold m-1">$ {Number((details?.all_payment_details[0]?.fees_amount_from_user || details?.all_payment_details[0]?.fees_amount_from_user) ?? 0)?.toFixed(2)}</p>*/}
                                {/*    </div>}*/}

                                {initialNetworkFees(userAuth, details)}

                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="t-12-bold m-1 ms-0">
                                    Tax (
                                    {details?.all_payment_details[0]
                                      ?.sales_tax_percentage || 0}
                                    %)
                                  </p>
                                  <p className="t-12-bold m-1">
                                    ${" "}
                                    {details?.all_payment_details[0]?.sales_tax_amount_from_user?.toFixed(
                                      2
                                    ) || 0.0}
                                  </p>
                                </div>
                                <hr style={{ border: "1px dashed black" }} />
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="t-12-bold m-1 ms-0">
                                    Total Amount
                                  </p>
                                  <p className="t-12-bold m-1">
                                    ${" "}
                                    {details?.all_payment_details[0]?.total_paid_amount?.toFixed(
                                      2
                                    ) || 0.0}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    )}

                    {/*........ modify details ........*/}
                    {details?.all_payment_details?.slice(1)?.length > 0 && (
                      <div>
                        {details?.all_payment_details?.slice(1)?.map((ele) => (
                          <div>
                            {ele?.is_refunded ||
                            checkIsRefund(ele?.payment_type) ? (
                              <>
                                <div>
                                  <div>
                                    <p className="t-18-bold">
                                      {"Refund Payment Details"}
                                    </p>
                                    <div className="border-gred">
                                      <div className="inner-gred">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="t-12-bold m-1">
                                            Transaction ID
                                          </p>
                                          <p className="t-12-bold m-1">
                                            {ele?.transaction_id}
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="t-12-bold m-1">
                                            Payment Time
                                          </p>
                                          <p className="t-12-bold m-1 white-space">
                                            {DateFormate(
                                              LocalFormate(
                                                ele?.createdAt,
                                                momentZone
                                              ),
                                              null,
                                              true
                                            )}{" "}
                                            (
                                            {timeZoneFormate(
                                              ele?.createdAt,
                                              momentZone
                                            )}
                                            )
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="t-12-bold m-1">
                                            Status
                                          </p>
                                          <p
                                            className="t-12-bold m-1"
                                            style={{ color: "#de9e28" }}
                                          >
                                            Refunded
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="t-12-bold m-1">
                                            Refund Amount
                                          </p>
                                          <p className="t-12-bold m-1">
                                            ${" "}
                                            {ele?.total_paid_amount?.toFixed(
                                              2
                                            ) || 0.0}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div>
                                <p className="t-18-bold">
                                  {"Modify Payment Details"}
                                </p>
                                <div className="border-gred">
                                  <div className="inner-gred">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="t-12-bold m-1">
                                        Transaction ID
                                      </p>
                                      <p className="t-12-bold m-1">
                                        {ele?.transaction_id}
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="t-12-bold m-1">
                                        Payment Time
                                      </p>
                                      <p className="t-12-bold m-1 white-space">
                                        {DateFormate(
                                          LocalFormate(
                                            ele?.createdAt,
                                            ele?.date_timezone
                                          ),
                                          null,
                                          true
                                        )}{" "}
                                        (
                                        {timeZoneFormate(
                                          ele?.createdAt,
                                          ele?.date_timezone
                                        )}
                                        )
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="t-12-bold m-1">Status</p>
                                      <p
                                        className="t-12-bold m-1"
                                        style={{
                                          color:
                                            ele?.payment_status == "success"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {capital(ele?.payment_status)}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="border-gred">
                                  <div className="inner-gred">
                                    {/*<div className="d-flex justify-content-between align-items-center">*/}
                                    {/*    <p className="t-12-bold m-1">Participants</p>*/}
                                    {/*    <p className="t-12-bold m-1">{details.no_of_participants || 0}</p>*/}
                                    {/*</div>*/}
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="t-12-bold m-1 ms-0">
                                        Package amount
                                      </p>
                                      <p className="t-12-bold m-1">
                                        $ {ele?.total_amount?.toFixed(2) || 0.0}
                                      </p>
                                    </div>
                                    {/*<div className="d-flex justify-content-between align-items-center">*/}
                                    {/*    <p className="t-12-bold m-1">Network fees*/}
                                    {/*        ({ele?.fees_percentage_from_user || 0}%)</p>*/}
                                    {/*    <p className="t-12-bold m-1">$ {(ele?.fees_amount_from_user)?.toFixed(2) || 0.00}</p>*/}
                                    {/*</div>*/}

                                    {/*{(userAuth?._id == details?.user_id?._id && ele?.referral_code_info?.is_refree) ?*/}
                                    {/*    <div>*/}
                                    {/*        <div*/}
                                    {/*            className="d-flex justify-content-between align-items-center">*/}
                                    {/*            <div>*/}
                                    {/*                <p className="t-12-bold m-1">Network*/}
                                    {/*                    fees*/}
                                    {/*                    ({ele?.fees_percentage_from_user || ele?.fees_percentage_from_user || 0}%)</p>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="d-flex">*/}
                                    {/*                <p className="t-12-bold m-1"*/}
                                    {/*                   style={{*/}
                                    {/*                       color: '#9E9E9E',*/}
                                    {/*                       textDecoration: 'line-through'*/}
                                    {/*                   }}>$ {Number(ele?.referral_code_info?.display_user_fees_amount ?? 0).toFixed(2)}</p>*/}
                                    {/*                <p className="t-12-bold m-1">$ {Number((ele?.fees_amount_from_user || ele?.fees_amount_from_user) ?? 0)?.toFixed(2)}</p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="yelloe-t-bold t-14-500 m-0"*/}
                                    {/*           style={{wordBreak: 'break-word'}}>You*/}
                                    {/*            have*/}
                                    {/*            received*/}
                                    {/*            a {ele?.referral_code_info?.display_user_fees_percentage ?? 0}%*/}
                                    {/*            discount for using the referral*/}
                                    {/*            code.</p>*/}
                                    {/*    </div> : <div*/}
                                    {/*        className="d-flex justify-content-between align-items-center">*/}
                                    {/*        <p className="t-12-bold m-1">Network fees*/}
                                    {/*            ({ele?.fees_percentage_from_user || 0}%)</p>*/}
                                    {/*        <p className="t-12-bold m-1">$ {(ele?.fees_amount_from_user)?.toFixed(2) || 0.00}</p>*/}
                                    {/*    </div>}*/}
                                    {modifyNetworkFees(ele, details, userAuth)}
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="t-12-bold m-1 ms-0">
                                        Tax ({ele?.sales_tax_percentage || 0}%)
                                      </p>
                                      <p className="t-12-bold m-1">
                                        ${" "}
                                        {ele?.sales_tax_amount_from_user?.toFixed(
                                          2
                                        ) || 0.0}
                                      </p>
                                    </div>
                                    <hr
                                      style={{ border: "1px dashed black" }}
                                    />
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="t-12-bold m-1 ms-0">
                                        Total Amount
                                      </p>
                                      <p className="t-12-bold m-1">
                                        ${" "}
                                        {ele?.total_paid_amount?.toFixed(2) ||
                                          0.0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                }
              </div>
            </div>

            {details?.booking_type !== "hire_provider" && (
              <ModalBtn
                details={details}
                userAuth={userAuth}
                setOpen={setOpen}
                HandleAcssept={HandleAcssept}
                setIsOpen={setIsOpen}
                navigate={navigate}
                selectId={selectId}
              />
            )}
          </div>
        </div>
      </Modal>
      <CancelEventPopup
        open={isOpen}
        setOpen={setIsOpen}
        getEvent={getEvent}
        deleteEvent={deleteEvent}
        setIsOpen={setOpen}
        status={myBookingDetails?.deletedEvent?.success?.status}
        isEvent={details.booking_type == "event" ? true : false}
      />
    </>
  );
};

export default UserBookingViewPopup;
