import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "react-bootstrap";
import { PayBooking } from "../../../action/paymentConstant";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { ENVIROMENT } from "../../../Configration/configration";

import { useNavigate } from "react-router-dom";

const CheckoutForm = ({
  details,
  handleClose,
  getNotificationsList,
  data,
  paymentSuccessful,
  isEvent,
}) => {
  const [loader, setLoader] = useState(false);
  const [latlong, setLatlong] = useState({
    lat: "",
    long: "",
  });
  const momentZone = moment.tz.guess();
  const [stateName, setStateName] = useState({
    country: "",
    state: "",
  });
  const [paymentDetails, setPaymentDetails] = useState({
    bank_account_id: "",
    total_paid_amount: "",
    application_fee_amount: "",
  });
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  let authToken;
  if (ENVIROMENT == "LIVE") {
    authToken =
      "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";
  } else {
    authToken =
      "sk_test_51Ng8S1CutznZ678nVvKamQkm2jhrPyeRgwZGDY2Uz0vlREZstr3OhzY5mRm0gajjLzRlWtnKnAsaOcSfG0jIvnzd009dUu3LNC";
  }

  const navigate = useNavigate();
  // const authToken = "sk_test_51Ng8S1CutznZ678nVvKamQkm2jhrPyeRgwZGDY2Uz0vlREZstr3OhzY5mRm0gajjLzRlWtnKnAsaOcSfG0jIvnzd009dUu3LNC"; // === test-client ===
  // const authToken = "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" // === live ===
  // const authToken = "sk_test_51OH23lF4Nm1dmKssCJVv10EJ0xwsq2xjajj9ln70BhCpL3dUB2xKx21vE4qCOsklhe1t99Eq0uOhwUc9uIMYEmR300DXadE2Xb" // === local ===
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("AdAnima_auth"));

  function calculateAmount(amount) {
    // if (typeof amount !== 'undefined') {
    //     const amountInCents = Number(amount).toFixed(2) * 100;
    //     return amountInCents;
    // }
    // Assuming 'amount' is a decimal value (e.g., dollars)

    if (typeof amount !== "undefined") {
      const amountInCents = Number(amount).toFixed(2) * 100;
      const floorAmount = Math.ceil(amountInCents);
      return floorAmount;
    } else {
      console.error("Amount is undefined.");
    }
  }

  useEffect(() => {
    const systemCharge = 3;
    const totalAmount = Number(details?.booking_payment_info?.total_amount);
    const price = Number(details?.booking_payment_info?.total_paid_amount);
    const tempTax = Number(
      details?.booking_payment_info?.sales_tax_amount_from_user
    );
    const tempFees = Number(
      details?.booking_payment_info?.fees_amount_from_user
    );
    const providerAmount = (totalAmount * systemCharge) / 100;

    setPaymentDetails({
      bank_account_id: details?.provider_id?.bank_account_id,
      total_paid_amount:
        details?.modified_count > 0
          ? details?.modify_booking_payment_info?.new_total_paid_amount ?? 0
          : details?.booking_payment_info?.total_paid_amount ?? 0,
      application_fee_amount:
        details?.modified_count > 0
          ? details?.modify_booking_payment_info?.application_fees ?? 0
          : tempTax + tempFees + providerAmount,
    });
  }, [details]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      setLoader(false);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    var paymentBody = {};

    if (!isEvent) {
      if (userData?.customer_id) {
        const servicePaymentBody = {
          amount: calculateAmount(paymentDetails?.total_paid_amount),
          currency: "usd",
          "automatic_payment_methods[enabled]": "true",
          // application_fee_amount: Math.round(details?.booking_payment_info?.total_paid_amount - details?.booking_payment_info?.total_transfered_amount),
          application_fee_amount: calculateAmount(
            paymentDetails?.application_fee_amount
          ),
          "transfer_data[destination]": paymentDetails?.bank_account_id,
          customer: userData?.customer_id,
          "metadata[userId]": userData?._id || "",
          "metadata[event_or_service_id]": details?._id || "",
          "metadata[isEvent]": `${isEvent}`,
          // 'transfer_data[destination]': 'acct_1OHNb22U4piPbSws',
        };
        paymentBody = servicePaymentBody;
      } else {
        const servicePaymentBody = {
          amount: calculateAmount(paymentDetails?.total_paid_amount),
          currency: "usd",
          "automatic_payment_methods[enabled]": "true",
          // application_fee_amount: Math.round(details?.booking_payment_info?.total_paid_amount - details?.booking_payment_info?.total_transfered_amount),
          application_fee_amount: calculateAmount(
            paymentDetails?.application_fee_amount
          ),
          "transfer_data[destination]": paymentDetails?.bank_account_id,
          "metadata[userId]": userData?._id || "",
          "metadata[event_or_service_id]": details?._id || "",
          "metadata[isEvent]": `${isEvent}`,
          // 'transfer_data[destination]': 'acct_1OHNb22U4piPbSws',
        };
        paymentBody = servicePaymentBody;
      }
    } else {
      if (userData?.customer_id) {
        const eventPaymentBody = {
          amount: calculateAmount(details?.total_paid_amount),
          currency: "usd",
          "automatic_payment_methods[enabled]": "true",
          customer: userData?.customer_id,
          "metadata[userId]": userData?._id || "",
          "metadata[event_or_service_id]": details?._id || "",
          "metadata[isEvent]": `${isEvent}`,
        };
        paymentBody = eventPaymentBody;
      } else {
        const eventPaymentBody = {
          amount: calculateAmount(details?.total_paid_amount),
          currency: "usd",
          "automatic_payment_methods[enabled]": "true",
          "metadata[userId]": userData?._id || "",
          "metadata[event_or_service_id]": details?._id || "",
          "metadata[isEvent]": `${isEvent}`,
        };
        paymentBody = eventPaymentBody;
      }
    }

    console.log(paymentBody, "paymentBody")

    try {
      const crePaymentIntent = await fetch(
        "https://api.stripe.com/v1/payment_intents",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(paymentBody),
        }
      );

      if (crePaymentIntent.ok) {
        const { client_secret, id, payment_method } =
          await crePaymentIntent.json();

        const { error, paymentIntent } = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          clientSecret: client_secret,
          confirmParams: {
            return_url: isEvent
              ? `${window?.location?.origin}/view-events/${details?._id}/${details?.user_id?._id}`
              : `${window?.location?.origin}/provider_details/${details?.provider_id?._id}`,
          },
        });

        if (error) {
          toast.error(error.message);
        } else {
          setLoader(false);
        }
      }
    } catch (error) {
      setLoader(false);
      toast.error("Internal Server Error");
    }

    //   setLoader(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        if ("geolocation" in navigator) {
          await navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;

              setLatlong((pre) => ({ ...pre, lat: latitude, long: longitude }));
              const googleMapApiKey = "AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs";

              const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapApiKey}`;
              const response = await fetch(apiUrl);

              if (response.ok) {
                const data = await response.json();

                // Check if the response contains results
                if (data.status === "OK") {
                  // Extract the state from the address components
                  const results = data.results || [];
                  for (const result of results) {
                    const addressComponents = result.address_components || [];
                    for (const component of addressComponents) {
                      const types = component.types || [];
                      if (types.includes("administrative_area_level_1")) {
                        setStateName((pre) => ({
                          ...pre,
                          state: component?.long_name,
                        }));
                        // return;
                      }
                      if (types.includes("country")) {
                        setStateName((pre) => ({
                          ...pre,
                          country: component?.long_name,
                        }));
                      }
                    }
                  }
                }
              } else {
                console.error(
                  `Failed to fetch data Status: ${response.status}`
                );
              }
            },
            (error) => {
              console.error("Error getting geolocation:", error);
            }
          );
        } else {
          console.log("Geolocation is not supported");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!isEvent) {
      handlePaymentBooking();
    }
  }, [latlong]);

  const handlePaymentBooking = (responseData) => {
    const systemCharge =
      details?.booking_payment_info?.fees_percentage_from_provider ?? 0;
    const totalAmount = Number(details?.booking_payment_info?.total_amount);
    const price = Number(details?.booking_payment_info?.total_paid_amount);
    const tempTax = Number(
      details?.booking_payment_info?.sales_tax_amount_from_user
    );
    const tempFees = Number(
      details?.booking_payment_info?.fees_amount_from_user
    );
    const providerAmount = (totalAmount * systemCharge) / 100;
    let payload = {};
    if (details?.modified_count == 0) {
      var Simplepayload = {
        booking_id: details?._id,
        notification_id: data?._id,
        total_amount: Number(details?.booking_payment_info?.total_amount ?? 0),
        total_paid_amount: Number(
          details?.booking_payment_info?.total_paid_amount ?? 0
        ),
        fees_percentage_from_user:
          details?.booking_payment_info?.fees_percentage_from_user,
        fees_amount_from_user: Number(
          details?.booking_payment_info?.fees_amount_from_user ?? 0
        ),
        // fees_percentage_from_provider: Number(systemCharge ?? 0),
        // fees_amount_from_provider: Number(providerAmount ?? 0),
        // total_transfered_amount: (price - (tempTax + tempFees + providerAmount)).toFixed(2),
        fees_percentage_from_provider: Number(
          details?.booking_payment_info?.fees_percentage_from_provider ?? 0
        ),
        fees_amount_from_provider: Number(
          details?.booking_payment_info?.fees_amount_from_provider ?? 0
        ),
        total_transfered_amount: (
          price -
          (tempTax + tempFees + providerAmount)
        ).toFixed(2),
        // stripe_charge_id: responseData?.latest_charge,
        // stripe_charge_id: "ch_3OVABQCutznZ678n1fqYNSdQ",
        // stripe_payment_intent_id: "pi_3OVABQCutznZ678n1dsM09mK",
        // stripe_payment_intent_id: responseData?.id,
        sales_tax_percentage: Number(
          details?.booking_payment_info?.sales_tax_percentage ?? 0
        ),
        sales_tax_amount_from_user: Number(
          details?.booking_payment_info?.sales_tax_amount_from_user ?? 0
        ),
        sales_tax_id: details?.booking_payment_info?.sales_tax_id,
        state: stateName?.state,
        country: stateName?.country,
        address: null,
        payment_date: new Date(),
        current_timezone: momentZone,
        location: {
          type: "Point",
          coordinates: [latlong?.long, latlong?.lat],
        },
        referral_code_info: {
          is_refree: details?.modify_booking_payment_info?.is_referee ?? false,
          display_user_fees_amount:
            details?.modify_booking_payment_info
              ?.referee_fees_amount_from_user ?? 0,
          display_user_fees_percentage:
            details?.modify_booking_payment_info?.referee_fees_percentage ?? 0,
        },
        discount_code_info: {
          is_discount:
            details?.modify_booking_payment_info?.is_discount ?? false,
          is_admin_discount:
            details?.modify_booking_payment_info?.is_admin_discount ?? 0,
          amount_without_discount:
            details?.modify_booking_payment_info?.amount_without_discount ?? 0,
        },
      };
      if (details?.modify_booking_payment_info?.is_referee) {
        Simplepayload.referral_discount_amount_user = Number(
          details?.booking_payment_info?.fees_amount_from_user ?? 0
        );
        Simplepayload.referral_discount_percentage_user = Number(
          details?.booking_payment_info?.fees_percentage_from_user ?? 0
        );
        if (details?.booking_payment_info?.referer_referral_code_id) {
          Simplepayload.referer_referral_code_id =
            details?.booking_payment_info?.referer_referral_code_id;
        }
        if (details?.booking_payment_info?.referree_referral_code_id) {
          Simplepayload.referree_referral_code_id =
            details?.booking_payment_info?.referree_referral_code_id;
        }
      }

      if (details?.modify_booking_payment_info?.is_discount == true) {
        Simplepayload.discount_code_id =
          details?.modify_booking_payment_info?.discount_code_id;
        Simplepayload.discount_amount =
          details?.modify_booking_payment_info?.discount_amount;
        Simplepayload.discount_percentage =
          details?.modify_booking_payment_info?.discount_percentage;
      }

      payload = Simplepayload;
    } else {
      var Simplepayload = {
        booking_id: details?._id,
        notification_id: data?._id,
        total_amount: Number(
          details?.modify_booking_payment_info?.new_amount ?? 0
        ),
        total_paid_amount: Number(
          details?.modify_booking_payment_info?.new_total_paid_amount ?? 0
        ),
        fees_percentage_from_user:
          details?.modify_booking_payment_info?.fees_percentage_from_user,
        fees_amount_from_user: Number(
          details?.modify_booking_payment_info?.fees_amount_from_user ?? 0
        ),
        // fees_percentage_from_provider: Number(systemCharge ?? 0),
        // fees_amount_from_provider: Number(providerAmount ?? 0),
        // total_transfered_amount: (price - (tempTax + tempFees + providerAmount)).toFixed(2),
        fees_percentage_from_provider: Number(
          details?.modify_booking_payment_info?.fees_percentage_from_provider ??
            0
        ),
        fees_amount_from_provider: Number(
          details?.modify_booking_payment_info?.fees_amount_from_provider ?? 0
        ),
        total_transfered_amount: Number(
          details?.modify_booking_payment_info?.total_transfered_amount ?? 0
        ),
        // stripe_charge_id: responseData?.latest_charge,
        // stripe_charge_id: "ch_3OVABQCutznZ678n1fqYNSdQ",
        // stripe_payment_intent_id: "pi_3OVABQCutznZ678n1dsM09mK",
        // stripe_payment_intent_id: responseData?.id,
        sales_tax_percentage: Number(
          details?.modify_booking_payment_info?.sales_tax_percentage ?? 0
        ),
        sales_tax_amount_from_user: Number(
          details?.modify_booking_payment_info?.sales_tax_amount_from_user ?? 0
        ),
        sales_tax_id: details?.booking_payment_info?.sales_tax_id,
        state: stateName?.state,
        country: stateName?.country,
        address: null,
        payment_date: new Date(),
        current_timezone: momentZone,
        referral_code_info: {
          is_refree: details?.modify_booking_payment_info?.is_referee ?? false,
          display_user_fees_amount:
            details?.modify_booking_payment_info
              ?.referee_fees_amount_from_user ?? 0,
          display_user_fees_percentage:
            details?.modify_booking_payment_info?.referee_fees_percentage ?? 0,
        },
        discount_code_info: {
          is_discount:
            details?.modify_booking_payment_info?.is_discount ?? false,
          is_admin_discount:
            details?.modify_booking_payment_info?.is_admin_discount ?? 0,
          amount_without_discount:
            details?.modify_booking_payment_info?.amount_without_discount ?? 0,
        },
        location: {
          type: "Point",
          coordinates: [latlong?.long, latlong?.lat],
        },
      };
      if (details?.modify_booking_payment_info?.is_referee) {
        Simplepayload.referral_discount_amount_user = Number(
          details?.booking_payment_info?.fees_amount_from_user ?? 0
        );
        Simplepayload.referral_discount_percentage_user = Number(
          details?.booking_payment_info?.fees_percentage_from_user ?? 0
        );
        if (details?.booking_payment_info?.referer_referral_code_id) {
          Simplepayload.referer_referral_code_id =
            details?.booking_payment_info?.referer_referral_code_id;
        }
        if (details?.booking_payment_info?.referree_referral_code_id) {
          Simplepayload.referree_referral_code_id =
            details?.booking_payment_info?.referree_referral_code_id;
        }
      }
      if (details?.modify_booking_payment_info?.is_discount == true) {
        Simplepayload.discount_code_id =
          details?.modify_booking_payment_info?.discount_code_id;
        Simplepayload.discount_amount =
          details?.modify_booking_payment_info?.discount_amount;
        Simplepayload.discount_percentage =
          details?.modify_booking_payment_info?.discount_percentage;
      }
      payload = Simplepayload;
    }

    localStorage.setItem("ServiceBookingData", JSON.stringify(payload));

    // dispatch(
    //   PayBooking(payload, () => {
    //     getNotificationsList();
    //   })
    // );
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: "5px" }}>
      <PaymentElement />
      {/*<button type="submit" >*/}
      {/*    Pay*/}
      {/*</button>*/}

      <div className="d-flex flex-wrap align-items-center justify-content-center">
        {loader ? (
          <a
            href="#"
            onClick={(e) => e.preventDefault()}
            className="massageBtn mt-3"
          >
            {/* save */}
            <div className="loader" style={{ top: "10px" }}></div>
          </a>
        ) : (
          <button
            type="submit"
            style={{
              maxWidth: "350px",
              height: "45px",
            }}
            disabled={!stripe || !elements}
            className="massageBtn mt-3"
            // onClick={() => navigate(`/book-session/${providerId}`)}
          >
            Pay
          </button>
        )}
      </div>

      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const Form = ({
  open,
  handleClose,
  details,
  setOpen,
  getNotificationsList,
  data,
  paymentSuccessful,
  isEvent,
}) => {
  let stripePromise;
  if (ENVIROMENT == "LIVE") {
    stripePromise = loadStripe(
      "pk_live_51Ng8S1CutznZ678njSmNdgEVll8UhobVbHMt1xd9cY4ULa0oyQEkW0M3DnnJgC13hrzjsOrP4g5qRN8PmTjC2Moc00AkBwbJzn"
    ); // === live ===
  } else {
    stripePromise = loadStripe(
      "pk_test_51Ng8S1CutznZ678nIvjTVNcyoqadEBVh15vX4Pb2bQm53cJkCkeszfqvQtXkkpU2KMaEy16gVchYLjmizjMub2oZ00xai7q6tT"
    ); // === test-live ===
  }
  //   const stripePromise = loadStripe(
  //     "pk_test_51Ng8S1CutznZ678nIvjTVNcyoqadEBVh15vX4Pb2bQm53cJkCkeszfqvQtXkkpU2KMaEy16gVchYLjmizjMub2oZ00xai7q6tT"
  //   ); // === test-live ===
  // const stripePromise = loadStripe('pk_live_51Ng8S1CutznZ678njSmNdgEVll8UhobVbHMt1xd9cY4ULa0oyQEkW0M3DnnJgC13hrzjsOrP4g5qRN8PmTjC2Moc00AkBwbJzn'); // === live ===
  // const stripePromise = loadStripe('pk_test_51OH23lF4Nm1dmKssg7fKsyIsViqr2V59cvmvztNEqgUPwPjuwxdasGfEMo9tQGU4XU51zJiOqdAVh0Zg167japLL00rtv5PhcC'); //  === local ===

  const [errorMessage, setErrorMessage] = useState(null);

  function calculateAmount(amount) {
    // Assuming 'amount' is a decimal value (e.g., dollars)
    const amountInCents = Math.round(parseFloat(amount) * 100);
    if (amountInCents > 0) {
      return amountInCents;
    } else {
      return 1;
    }
    // return amountInCents ?? 1;
  }

  const options = {
    mode: "payment",
    amount:
      calculateAmount(
        isEvent
          ? details?.total_paid_amount
          : details?.booking_payment_info?.total_paid_amount
      ) ?? 1,
    currency: "usd",
    appearance: {
      theme: "dark", // Change the theme to 'dark'
      elements: {
        card: {
          iconStyle: "solid", // Change the card icon style
          style: {
            base: {
              fontSize: "16px", // Change the base font size
              color: "#fff", // Change the font color
            },
          },
        },
      },
    },
  };

  // const options = {
  //     iconStyle: 'solid',
  //         style: {
  //         base: {
  //             fontSize: '16px',
  //                 color: '#fff',
  //         },
  //     },
  // };
  return (
    <Modal
      centered
      show={open}
      onHide={() => {
        handleClose();
        setOpen(true);
      }}
      dialogClassName="claim_account_modal_contact payment-modal"
      style={{ zIndex: "1000001", background: "#00000096" }}
    >
      <div
        className="Congratulations_msg ps-3 pe-3 pt-3 pb-3"
        style={{ maxWidth: "none" }}
      >
        <p className="t-22-bold">Payment</p>
        <div className="p-2">
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              details={details}
              handleClose={handleClose}
              getNotificationsList={getNotificationsList}
              data={data}
              paymentSuccessful={paymentSuccessful}
              isEvent={isEvent}
            />
          </Elements>
        </div>
      </div>
    </Modal>
  );
};

export default Form;
