import React from "react";
import soonImg from "../assets/images/c_soon.png"
import Header from "./Header";


const ComingSoon = () => {
    return (
        <>
            <section className="main-page">
                <Header/>
                <div className="main-inner">
                    <div className="container" style={{
                        textAlign: "center",
                        height: "calc(100vh - 220px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}>
                        <div>
                            <img src={soonImg} style={{ width: "350px" }} />
                        </div>
                        <h1 className="d-inline-block" style={{ fontSize: "55px" }}>COMING SOON!</h1>
                        <p className="para mb-3 mb-lg-5" style={{ fontSize: "20px" }}>
                            Thank you for joining us! Brace yourself for an array of thrilling features – coming soon to AD ANIMA. <br />Your wait will soon be rewarded with innovation and excitement!
                        </p>
                    </div>
                </div>
            </section>

        </>
)}

export default ComingSoon;