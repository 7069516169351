import React, {useState} from 'react'
import ImageModle from "../ImageModle";
import SliderPopup from "../SliderPopup";


const ImageLayouts = ({userDetails}) => {
    const [open, setOpen] = React.useState(false);
    const [imageUrl, setImageUrl] = useState("")
    const [sliderIndex, setSliderIndex] = useState(1)
    const handleOpen = (url) => {
        setImageUrl(url)
        setOpen(true);
    }
    const handleClose = () => setOpen(false);


    return (
        <>

            {/*.............7 up.............*/}
            {userDetails?.length > 7 &&
                <div className="round-image-7">
                    <div className="first d-flex justify-content-center">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(0)
                            handleOpen(userDetails[0]?.file_name ? userDetails[0]?.file_name : userDetails[0])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[0]?.file_name
                                            ? userDetails[0]
                                                ?.file_name
                                            : userDetails[0]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(1)
                            handleOpen(userDetails[1]?.file_name ? userDetails[1]?.file_name : userDetails[1])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[1]?.file_name
                                            ? userDetails[1]
                                                ?.file_name
                                            : userDetails[1]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="first d-flex justify-content-center">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(2)
                            handleOpen(userDetails[2]?.file_name
                                ? userDetails[2]
                                    ?.file_name
                                : userDetails[2])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[2]?.file_name
                                            ? userDetails[2]
                                                ?.file_name
                                            : userDetails[2]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(3)
                            handleOpen(userDetails[3]?.file_name
                                ? userDetails[3]
                                    ?.file_name
                                : userDetails[3])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[3]?.file_name
                                            ? userDetails[3]
                                                ?.file_name
                                            : userDetails[3]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(4)
                            handleOpen(userDetails[4]?.file_name
                                ? userDetails[4]
                                    ?.file_name
                                : userDetails[4])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[4]?.file_name
                                            ? userDetails[4]
                                                ?.file_name
                                            : userDetails[4]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="first d-flex justify-content-center">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(5)
                            handleOpen(userDetails[5]?.file_name
                                ? userDetails[5]
                                    ?.file_name
                                : userDetails[5])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[5]?.file_name
                                            ? userDetails[5]
                                                ?.file_name
                                            : userDetails[5]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(6)
                            handleOpen(userDetails[6]?.file_name
                                ? userDetails[6]
                                    ?.file_name
                                : userDetails[6])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[6]?.file_name
                                            ? userDetails[6]
                                                ?.file_name
                                            : userDetails[6]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>}

            {/*.............7.............*/}

            {userDetails?.length == 7 &&
                <div className="round-image-7">
                    <div className="first d-flex justify-content-center">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(0)
                            handleOpen(userDetails[0]?.file_name ? userDetails[0]?.file_name : userDetails[0])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[0]?.file_name
                                            ? userDetails[0]
                                                ?.file_name
                                            : userDetails[0]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(1)
                            handleOpen(userDetails[1]?.file_name ? userDetails[1]?.file_name : userDetails[1])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[1]?.file_name
                                            ? userDetails[1]
                                                ?.file_name
                                            : userDetails[1]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="first d-flex justify-content-center">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(2)
                            handleOpen(userDetails[2]?.file_name
                                ? userDetails[2]
                                    ?.file_name
                                : userDetails[2])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[2]?.file_name
                                            ? userDetails[2]
                                                ?.file_name
                                            : userDetails[2]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(3)
                            handleOpen(userDetails[3]?.file_name
                                ? userDetails[3]
                                    ?.file_name
                                : userDetails[3])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[3]?.file_name
                                            ? userDetails[3]
                                                ?.file_name
                                            : userDetails[3]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(4)
                            handleOpen(userDetails[4]?.file_name
                                ? userDetails[4]
                                    ?.file_name
                                : userDetails[4])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[4]?.file_name
                                            ? userDetails[4]
                                                ?.file_name
                                            : userDetails[4]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="first d-flex justify-content-center">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(5)
                            handleOpen(userDetails[5]?.file_name
                                ? userDetails[5]
                                    ?.file_name
                                : userDetails[5])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[5]?.file_name
                                            ? userDetails[5]
                                                ?.file_name
                                            : userDetails[5]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(6)
                            handleOpen(userDetails[6]?.file_name
                                ? userDetails[6]
                                    ?.file_name
                                : userDetails[6])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[6]?.file_name
                                            ? userDetails[6]
                                                ?.file_name
                                            : userDetails[6]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>}


            {/* ..............6................ */}

            {userDetails?.length == 6 && <div className="round-image6 round-image">
                <div className="first d-flex justify-content-center">
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(0)
                        handleOpen(userDetails[0]?.file_name
                            ? userDetails[0]
                                ?.file_name
                            : userDetails[0])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[0]?.file_name
                                        ? userDetails[0]
                                            ?.file_name
                                        : userDetails[0]
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="first d-flex justify-content-center">
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(1)
                        handleOpen(userDetails[1]?.file_name
                            ? userDetails[1]
                                ?.file_name
                            : userDetails[1])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[1]?.file_name
                                        ? userDetails[1]
                                            ?.file_name
                                        : userDetails[1]
                                }
                            />
                        </div>
                    </div>
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(2)
                        handleOpen(userDetails[2]?.file_name
                            ? userDetails[2]
                                ?.file_name
                            : userDetails[2])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[2]?.file_name
                                        ? userDetails[2]
                                            ?.file_name
                                        : userDetails[2]
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="first d-flex justify-content-center">
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(3)
                        handleOpen(userDetails[3]?.file_name
                            ? userDetails[3]
                                ?.file_name
                            : userDetails[3])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[3]?.file_name
                                        ? userDetails[3]
                                            ?.file_name
                                        : userDetails[3]
                                }
                            />
                        </div>
                    </div>
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(4)
                        handleOpen(userDetails[4]?.file_name
                            ? userDetails[4]
                                ?.file_name
                            : userDetails[4])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[4]?.file_name
                                        ? userDetails[4]
                                            ?.file_name
                                        : userDetails[4]
                                }
                            />
                        </div>
                    </div>
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(5)
                        handleOpen(userDetails[5]?.file_name
                            ? userDetails[5]
                                ?.file_name
                            : userDetails[5])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[5]?.file_name
                                        ? userDetails[5]
                                            ?.file_name
                                        : userDetails[5]
                                }
                            />
                        </div>
                    </div>
                </div>

            </div>}


            {/* ..............5...................... */}

            {userDetails?.length == 5 && <div className="round-image six-image">
                <div className="first d-flex justify-content-center">
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(0)
                        handleOpen(userDetails[0]?.file_name
                            ? userDetails[0]
                                ?.file_name
                            : userDetails[0])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[0]?.file_name
                                        ? userDetails[0]
                                            ?.file_name
                                        : userDetails[0]
                                }
                            />
                        </div>
                    </div>
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(1)
                        handleOpen(userDetails[1]?.file_name
                            ? userDetails[1]
                                ?.file_name
                            : userDetails[1])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[1]?.file_name
                                        ? userDetails[1]
                                            ?.file_name
                                        : userDetails[1]
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="first d-flex justify-content-center">
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(2)
                        handleOpen(userDetails[2]?.file_name
                            ? userDetails[2]
                                ?.file_name
                            : userDetails[2])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[2]?.file_name
                                        ? userDetails[2]
                                            ?.file_name
                                        : userDetails[2]
                                }
                            />
                        </div>
                    </div>
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(3)
                        handleOpen(userDetails[3]?.file_name
                            ? userDetails[3]
                                ?.file_name
                            : userDetails[3])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[3]?.file_name
                                        ? userDetails[3]
                                            ?.file_name
                                        : userDetails[3]
                                }
                            />
                        </div>
                    </div>
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(4)
                        handleOpen(userDetails[4]?.file_name
                            ? userDetails[4]
                                ?.file_name
                            : userDetails[4])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[4]?.file_name
                                        ? userDetails[4]
                                            ?.file_name
                                        : userDetails[4]
                                }
                            />
                        </div>
                    </div>
                </div>

            </div>}


            {/* ...............4................... */}

            {userDetails?.length == 4 && <div className="round-image round-image4">
                <div className="first d-flex justify-content-center">
                    <div className="first d-flex flex-column gap-4">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(0)
                            handleOpen(userDetails[0]?.file_name
                                ? userDetails[0]
                                    ?.file_name
                                : userDetails[0])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[0]?.file_name
                                            ? userDetails[0]
                                                ?.file_name
                                            : userDetails[0]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(1)
                            handleOpen(userDetails[1]?.file_name
                                ? userDetails[1]
                                    ?.file_name
                                : userDetails[1])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[1]?.file_name
                                            ? userDetails[1]
                                                ?.file_name
                                            : userDetails[1]
                                    }
                                />
                            </div>
                        </div>
                    </div>


                    <div style={{
                        marginTop: "100px",
                        marginLeft: "-21px"
                    }} className="first d-flex flex-column gap-4">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(2)
                            handleOpen(userDetails[2]?.file_name
                                ? userDetails[2]
                                    ?.file_name
                                : userDetails[2])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[2]?.file_name
                                            ? userDetails[2]
                                                ?.file_name
                                            : userDetails[2]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(3)
                            handleOpen(userDetails[3]?.file_name
                                ? userDetails[3]
                                    ?.file_name
                                : userDetails[3])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[3]?.file_name
                                            ? userDetails[3]
                                                ?.file_name
                                            : userDetails[3]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}


            {/* ................3..................... */}

            {userDetails?.length == 3 && <div className="round-image" style={{transform: "scale(0.9)"}}>
                <div className="first d-flex justify-content-center align-items-center">
                    <div className='d-flex flex-column gap-4'>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(0)
                            handleOpen(userDetails[0]?.file_name
                                ? userDetails[0]
                                    ?.file_name
                                : userDetails[0])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[0]?.file_name
                                            ? userDetails[0]
                                                ?.file_name
                                            : userDetails[0]
                                    }
                                />
                            </div>
                        </div>
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(1)
                            handleOpen(userDetails[1]?.file_name
                                ? userDetails[1]
                                    ?.file_name
                                : userDetails[1])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[1]?.file_name
                                            ? userDetails[1]
                                                ?.file_name
                                            : userDetails[1]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="polygon-imgae" style={{marginLeft: "-20px"}} onClick={() => {
                        setSliderIndex(2)
                        handleOpen(userDetails[2]?.file_name
                            ? userDetails[2]
                                ?.file_name
                            : userDetails[2])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[2]?.file_name
                                        ? userDetails[2]
                                            ?.file_name
                                        : userDetails[2]
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>}


            {/* ..............2............... */}

            {userDetails?.length == 2 && <div className="round-image mt-5" style={{transform: "scale(1.1)"}}>
                <div className="first d-flex justify-content-center">
                    <div className="polygon-imgae" onClick={() => {
                        setSliderIndex(0)
                        handleOpen(userDetails[0]?.file_name
                            ? userDetails[0]
                                ?.file_name
                            : userDetails[0])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[0]?.file_name
                                        ? userDetails[0]
                                            ?.file_name
                                        : userDetails[0]
                                }
                            />
                        </div>
                    </div>
                    <div className="polygon-imgae" style={{marginTop: "120px", marginLeft: "-36px"}} onClick={() => {
                        setSliderIndex(1)
                        handleOpen(userDetails[1]?.file_name
                            ? userDetails[1]
                                ?.file_name
                            : userDetails[1])
                    }}>
                        <div className="p_image_shape">
                            <img
                                src={
                                    userDetails[1]?.file_name
                                        ? userDetails[1]
                                            ?.file_name
                                        : userDetails[1]
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>}


            {/* .....................1...................... */}

            {userDetails?.length == 1 &&
                <div className="round-image" style={{transform: "scale(1.5)", marginTop: "60px", marginBottom: "60px"}}>
                    <div className="first d-flex justify-content-center">
                        <div className="polygon-imgae" onClick={() => {
                            setSliderIndex(0)
                            handleOpen(userDetails[0]?.file_name
                                ? userDetails[0]
                                    ?.file_name
                                : userDetails[0])
                        }}>
                            <div className="p_image_shape">
                                <img
                                    src={
                                        userDetails[0]?.file_name
                                            ? userDetails[0]
                                                ?.file_name
                                            : userDetails[0]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>}

            {/*<ImageModle open={open} handleClose={handleClose} imageUrl={imageUrl}/>*/}
            <SliderPopup open={open} handleClose={handleClose} imageUrl={imageUrl} userDetails={userDetails} sliderIndex={sliderIndex}/>
        </>
    )
}

export default ImageLayouts
