import React from "react";

const Congratulation = ({refCodeData, onContiue}) => {

    // console.log(refCodeData, "refCodeData")
    return (
        <>
            <div className="login-screen">
                <div className="Congratulations_msg">
                    <div className="group-parent m-0" style={{borderRadius: "10px"}}>
                        <div className="group " style={{borderRadius: "10px"}}>
                            <div className="padding-inner pt-2" style={{borderRadius: "10px"}}>
                                <div className="text-center">
                                    <img
                                        className="img-fluid logo"
                                        alt=""
                                        src="images/dot.png"
                                    />
                                </div>
                                <h3 className="mb-3 mb-md-3 page-title text-center">
                                    Congratulations!
                                </h3>

                                {refCodeData?.isRef == true ?
                                    <div>
                                        <p className="text-center mb-3 mb-md-5" style={{color: "#000"}}>
                                            Your account is ready to use. You will be redirected to the Home page in a
                                            few second.
                                        </p>
                                        <p className="text-center mb-3 mb-md-5 mt-2" style={{color: "#000"}}>
                                            {refCodeData?.limit} {refCodeData?.time} before the end of their waved fees that invites them to use their
                                            own referral code to invite more users and extend their waved fees.
                                        </p>
                                    </div> :
                                    <p className="text-center mb-3 mb-md-5" style={{color: "#000"}}>
                                        Your account is ready to use. You will be redirected to the
                                        Home page in a few second.
                                    </p>}
                                {/*<div className="anim-image text-center">*/}
                                {/*    <img src="images/animation.svg"/>*/}
                                {/*</div>*/}


                                <div className="d-flex flex-wrap align-items-center justify-content-center">
                                    <button
                                        type="submit"
                                        style={{
                                            maxWidth: "350px", height: "45px"
                                        }}
                                        onClick={() => {
                                            // console.log("handle click contuighgrh")
                                            onContiue()
                                        }}
                                        className="massageBtn mt-3"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Congratulation;
