import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import profileImg from "../../assets/images/layout1.jpg";
import moment from "moment/moment";
import AddEventPopup from "../AddEventPopup";
import { useDispatch, useSelector } from "react-redux";
import { getOtherProvider } from "../../action/otherProviderConstant";
import RemoveEventPopup from "../RemoveEventPopup";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import deleteIcon from "../../assets/images/deleteAccIcon.svg";
import userIcon from "../../assets/images/userIconWithBg.png";

const EventCard = ({
  data,
  formatBadge,
  getReviewTime,
  seenNotification,
  handleDeleteNotification,
}) => {
  const [open, setOpen] = useState({
    event: false,
  });
  const userDetails = useSelector((state) => state?.otherProviderDetails?.data);
  const dispatch = useDispatch();
  const [foundMassage, setFoundMassage] = useState("");
  const [removeModal, setRemoveModal] = useState(false);

  // sender_user?._id

  const getOtherProviders = () => {
    dispatch(
      getOtherProvider(data?.sender_user?._id, null, (a) => {
        setFoundMassage(a);
      })
    ).then(() => {
      foundModal();
    });
    foundModal();
  };

  const foundModal = () => {
    if (foundMassage == "Couldn't found user") {
      setOpen((pre) => ({ ...pre, event: false }));
      setRemoveModal(true);
    }
  };

  useEffect(() => {
    foundModal();
  }, [foundMassage]);

  const handleNavigate = (event) => {
    const clickedElement = event.target;
    if (clickedElement.classList.contains("btnn")) {
      // Button with the class "btnn" was clicked, handle API call logic
      return;
    } else {
      seenNotification();
    }
  };

  return (
    <>
      <div
        style={{ background: data?.is_seen ? "white" : "#E6F7FF" }}
        onClick={(e) => {
          handleNavigate(e);
        }}
      >
        <div className="notification-main-div d-flex align-items-start justify-content-between p-2 position-relative position-relative">
          <div className="d-flex align-items-start w-100">
            <div className="d-flex align-items-center">
              <FiberManualRecordIcon
                style={{
                  width: "13px",
                  color: data?.is_seen ? "transparent" : "#60CE74",
                }}
              />
              <div
                className="p_image_shape me-2 me-sm-0"
                style={{ transform: "scale(0.6)", marginLeft: "-15px" }}
              >
                <img
                  src={data?.sender_user?.profile_picture || userIcon}
                  onError={(e) => (e.target.src = userIcon)}
                />
              </div>
            </div>
            <div className="w-100">
              <p
                className="mb-0 t-13-800 mt-0 twoLineElips"
                style={{
                  wordBreak: "break-word",
                  width: "80%",
                }}
              >
                {data?.noti_msg
                  .replace("user_name", data?.sender_user?.name)
                  ?.replace("login_", "")
                  .replace("service_name", data?.service_name ?? "")
                  ?.replace("event_name", data?.event_name ?? "")
                  .replace("provider_name", data?.provider_name ?? "")}
              </p>

              <div className="d-flex justify-content-between w-100">
                <div>
                  <p className="mb-0 t-12-bold mt-0">
                    {data?.sender_user?.name}
                  </p>
                  <div className="d-flex align-items-center g-2">
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("MMMM D YYYY")}
                    </p>
                    <FiberManualRecordIcon
                      style={{ transform: "scale(0.4)", color: "#555555" }}
                    />
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("LT")}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="t-10-bold m-0"
                    style={{
                      color: "#2F8A40",
                      textAlign: "end",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {getReviewTime(data?.createdAt)}
                  </p>
                  <p
                    className="t-11-bold mt-1 d-flex align-items-center justify-content-center m-0"
                    style={{
                      borderRadius: "3px",
                      background: "#00ADFF",
                      padding: "2px 4px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formatBadge(
                      data?.noti_for,
                      data?.booking_type == "service" ? false : true
                    )}
                  </p>
                </div>
              </div>

              {data?.noti_for == "match_service_event" && (
                <>
                  <p className="t-12-bold m-0">
                    You are not invited in this event but if you want to join
                    this event Let's Talk with Provider.
                  </p>
                  <div className="d-flex justify-content-start">
                    <div className="col-10">
                      <button
                        type="submit"
                        className="btn_admina delete-button"
                        onClick={() => {
                          getOtherProviders();
                          setOpen((pre) => ({ ...pre, event: true }));
                        }}
                        style={{ transform: "scale(0.7)", marginLeft: "-44px", width : '85%' }}
                      >
                        Sent Request
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            className="position-absolute delete_notification"
            style={{ left: "0", top: "0" }}
          >
            <button className="btnn">
              <Tooltip placement="top" title={"Clear"}>
                <IconButton
                  style={{ padding: "3px 5px" }}
                  className="btnn"
                  onClick={() => {
                    handleDeleteNotification();
                  }}
                >
                  {/*<CloseIcon style={{width: '15px'}}/>*/}
                  <img
                    src={deleteIcon}
                    alt=""
                    className="btnn"
                    style={{ width: "24px" }}
                  />
                </IconButton>
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
      {!removeModal && (
        <AddEventPopup
          open={open}
          setOpen={setOpen}
          userDetails={userDetails}
        />
      )}
      <RemoveEventPopup open={removeModal} setOpen={setRemoveModal} />
    </>
  );
};

export default EventCard;
