import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {calenderConstant, hireNcollaborateConstant} from "./constant";
import {toast} from "react-toastify";

export const bookScheduleDate = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", data?.userId);
        fdata.append("schedule_month", data?.schedule_month);
        fdata.append("schedule_year", data?.schedule_year);
        if (data?.service_id) {
            fdata.append("service_id", data?.service_id);
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.book_schedule_date}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: calenderConstant.BOOK_SCHEDULE_DATE,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const guestBookScheduleDate = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", data?.userId);
        fdata.append("schedule_month", data?.schedule_month);
        fdata.append("schedule_year", data?.schedule_year);
        if (data?.service_id) {
            fdata.append("service_id", data?.service_id);
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_book_schedule_date}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: calenderConstant.BOOK_SCHEDULE_DATE,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};


export const myScheduleDate = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", data?.userId);
        fdata.append("schedule_month", data?.schedule_month);
        fdata.append("schedule_year", data?.schedule_year);
        if (data?.service_id) {
            fdata.append("service_id", data?.service_id);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.my_schedule_date}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: calenderConstant.MY_SCHEDULE_DATE,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const guestMyScheduleDate = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", data?.userId);
        fdata.append("schedule_month", data?.schedule_month);
        fdata.append("schedule_year", data?.schedule_year);
        if (data?.service_id) {
            fdata.append("service_id", data?.service_id);
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_my_schedule_date}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: calenderConstant.MY_SCHEDULE_DATE,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};

export const scheduleLink = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", data?.userId);
        fdata.append("schedule_date", data?.scheduleDate);

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.schedule_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: calenderConstant.SCHEDULE_LIST,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const guestScheduleLink = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", data?.userId);
        fdata.append("schedule_date", data?.scheduleDate);

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_schedule_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: calenderConstant.SCHEDULE_LIST,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const systemCharge = () => {
    return async (dispatch) => {
        let response;

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_system_charge}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: calenderConstant.GET_SYSTEM_SERVICE_CHARGE,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};