import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconButton } from "@mui/material";
import shareIcon from "../../assets/images/share_icon.png";
import copyIcon from "../../assets/images/copy_icon.png";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import DeleteImage from "../../assets/images/deletedot.png";

const checkCodeExpire = (date, ele) => {
  // data?.map((ele) => {
  const EXDATE = new Date(date).toLocaleDateString();
  const TODAY = new Date().toLocaleDateString();
  if (date != null) {
    if (TODAY <= EXDATE) {
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="t-15-500 m-0" style={{ color: "#8E93AE" }}>
              Expiration Date: {moment(date).format("D MMMM YYYY")}
            </p>
            <div>
              {ele?.is_admin_created ? (
                <p
                  className="t-15-bold m-0"
                  style={{ marginLeft: "auto !important" }}
                >
                  Created by Admin
                </p>
              ) : null}
            </div>
          </div>
          <p
            className="t-15-bold m-0"
            style={{
              color: "#0D982B",
              background: "#DDFFE4",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            ACTIVE
          </p>
        </div>
      );
    } else {
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="t-15-500 m-0" style={{ color: "#8E93AE" }}>
              Expiration Date: {moment(date).format("D MMMM YYYY")}
            </p>
            <div>
              {ele?.is_admin_created ? (
                <p
                  className="t-15-bold m-0"
                  style={{ marginLeft: "auto !important" }}
                >
                  Created by Admin
                </p>
              ) : null}
            </div>
          </div>
          <p
            className="t-15-bold m-0"
            style={{
              color: "#F46F6F",
              background: "#FFE1E1",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            EXPIRED
          </p>
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <p className="t-15-500 m-0" style={{ color: "#8E93AE" }}></p>
        <p
          className="t-15-bold m-0"
          style={{
            color: "#0D982B",
            background: "#DDFFE4",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          ACTIVE
        </p>
      </div>
    );
  }
};

const DiscountPopup = ({
  open,
  setOpen,
  data,
  setOpenShare,
  setSharedData,
  openShare,
  setIsEdit,
  editModle,
  handleDelete,
  is_generate,
}) => {
  const navigate = useNavigate();

  function myFunction(code) {
    // Get the text field
    navigator.clipboard.writeText(code);
    // Alert the copied text
    // alert("Copied the text: " + copyText.value);
  }

  const closeModle = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const [deleteOpen, setdeleteOpen] = useState(false);

  const codeType = (type) => {
    if (type == "specific") {
      return "Specific Products";
    } else if (type == "only_event") {
      return "Specific event";
    } else if (type == "only_service") {
      return "Specific service";
    } else {
      return "All Products";
    }
  };

  return (
    <>
      <>
        <Modal
          centered
          show={open}
          onHide={() => {
            closeModle();
          }}
          dialogClassName="delete-modal"
        >
          <div
            className="Congratulations_msg"
            style={{ display: openShare || is_generate ? "none" : "block" }}
          >
            <div className="p-3">
              <p className="t-20-bold m-0 mt-1" style={{ color: "#363853" }}>
                Code Details
              </p>
              <div>
                <div
                  className="ref-code-box mt-2"
                  style={{ boxShadow: "none", border: "2px solid #D0DEE2" }}
                >
                  <p className="m-0 t-16-bold" style={{ color: "#8E93AE" }}>
                    YOUR CODE
                  </p>
                  <div className="d-flex align-items-center justify-content-between">
                    <div style={{ display: "contents" }}>
                      <p
                        className="t-20-bold m-0 mt-1"
                        style={{ color: "#363853" }}
                      >
                        {data?.discount_code}
                      </p>
                    </div>
                    <div className="d-flex gap-2">
                      <IconButton
                        sx={{ padding: "0" }}
                        onClick={() => {
                          setOpenShare(true);
                          setSharedData(data);
                        }}
                      >
                        <img src={shareIcon} alt="" />
                      </IconButton>
                      <IconButton
                        sx={{ padding: "0" }}
                        onClick={() => {
                          myFunction(data?.discount_code);
                        }}
                      >
                        <img src={copyIcon} alt="" />
                      </IconButton>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="w-100">
                      {checkCodeExpire(data?.expire_date, data)}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ref-code-box mt-3"
                style={{ boxShadow: "none", border: "2px solid #D0DEE2" }}
              >
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <p className="t-14-500 m-0" style={{ color: "#8E93AE" }}>
                    Discount Percentage
                  </p>
                  <p className="t-14-bold m-0" style={{ color: "#363853" }}>
                    {data?.discount_percentage ?? 0} %
                  </p>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <p className="t-14-500 m-0" style={{ color: "#8E93AE" }}>
                      Type
                    </p>
                    <p className="t-14-bold m-0" style={{ color: "#363853" }}>
                      {codeType(data?.discount_type)}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <p className="t-14-500 m-0" style={{ color: "#8E93AE" }}>
                      Used By
                    </p>
                    <p className="t-14-bold m-0" style={{ color: "#363853" }}>
                      {data?.user_used_count ?? 0} Peoples
                    </p>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <p className="t-14-500 m-0" style={{ color: "#8E93AE" }}>
                      Limited Users
                    </p>
                    <p className="t-14-bold m-0" style={{ color: "#363853" }}>
                      {data?.limit_user_count ?? 0} Peoples
                    </p>
                  </div>
                </div>
              </div>

              {data?.service_ids?.length > 0 ||
              data?.events_ids_details?.length > 0 ? (
                <div
                  className="ref-code-box mt-3"
                  style={{ boxShadow: "none", border: "2px solid #D0DEE2" }}
                >
                  {data?.service_ids?.length > 0 ? (
                    <div>
                      <div>
                        <p
                          className="t-14-500 m-0"
                          style={{
                            color: "#8E93AE",
                            textDecoration: "underline",
                          }}
                        >
                          {data?.service_ids?.length} Specific Services
                        </p>
                        <div className="mt-2">
                          {data?.service_ids?.map((ele) => (
                            <p
                              className="t-14-bold m-0 service-in-discount"
                              onClick={() =>
                                navigate(`/provider_details/${ele?.user_id}`)
                              }
                              style={{ color: "#363853" }}
                            >
                              {ele?.service_name}
                            </p>
                          ))}
                        </div>
                      </div>
                      <hr style={{ margin: "10px 0px" }} />
                    </div>
                  ) : null}
                  {data?.events_ids_details?.length > 0 ? (
                    <div>
                      <p
                        className="t-14-500 m-0"
                        style={{
                          color: "#8E93AE",
                          textDecoration: "underline",
                        }}
                      >
                        {data?.events_ids_details?.length} Specific Event
                      </p>
                      <div className="mt-2">
                        {data?.events_ids_details?.map((ele) => (
                          <div
                            className="d-flex align-items-center c-pointer"
                            style={{ height: "55px" }}
                            onClick={() => {
                              navigate(
                                `/view-events/${ele?.event_id?._id}/${ele?.event_id?.user_id}`
                              );
                            }}
                          >
                            <div
                              className="p_image_shape me-2 me-sm-0"
                              style={{
                                transform: "scale(0.5)",
                                marginLeft: "-20px",
                              }}
                            >
                              <img
                                src={
                                  ele?.event_id?.event_media?.length > 0
                                    ? ele?.event_id?.event_media[0]?.file_name
                                    : null
                                }
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <p
                                className="t-14-bold m-0"
                                style={{ color: "#363853" }}
                              >
                                {ele?.event_id?.event_name}
                              </p>
                              {ele?.event_id?.is_event_cancel == true ? (
                                <p
                                  className="m-0"
                                  style={{
                                    backgroundColor: "#ffd1d1",
                                    fontSize: "13px",
                                    padding: "2px 8px",
                                    borderRadius: "5px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Canceled
                                </p>
                              ) : null}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {data?.notes && (
                <div
                  className="ref-code-box mt-3"
                  style={{ boxShadow: "none", border: "2px solid #D0DEE2" }}
                >
                  <div>
                    <p
                      className="t-14-500 m-0"
                      style={{
                        color: "#8E93AE",
                      }}
                    >
                      Notes
                    </p>
                    <div className="mt-2">
                      <div
                        className="d-flex"
                        onClick={() => {}}
                      >
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p
                            className="t-14-bold m-0"
                            style={{ color: "#363853" }}
                          >
                            {data?.notes}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="w-100 d-flex mt-3">
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn_admina delete-confirm-button"
                    style={{ lineHeight: "25px" }}
                    onClick={() => {
                      setOpen(false);
                      setdeleteOpen(true);
                    }}
                  >
                    Delete
                  </button>
                </div>

                <div className="col-6">
                  <button
                    type="submit"
                    style={{ lineHeight: "25px" }}
                    className="btn_admina delete-button"
                    onClick={() => {
                      setIsEdit(true);
                      editModle(true);
                    }}
                    // onClick={() => {
                    //     if (!IsValidation()) {
                    //         if (isUpdate) {
                    //             handleEditSchedule()
                    //         } else {
                    //             handleCreateSchedule()
                    //         }
                    //     }
                    // }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          centered
          show={deleteOpen}
          onHide={() => {
            setdeleteOpen(false);
          }}
          dialogClassName="delete-modal"
        >
          <div className="Congratulations_msg">
            <div className="group-parent m-0" style={{ borderRadius: "10px" }}>
              <div className="group " style={{ borderRadius: "10px" }}>
                <div
                  className="padding-inner pt-2"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="text-center">
                    <img className="img-fluid logo" alt="" src={DeleteImage} />
                  </div>
                  <h3 className="mb-3 mb-md-3 page-title text-center">
                    Delete Code!
                  </h3>

                  <p
                    className="text-center mb-3 mb-md-5"
                    style={{ color: "#000" }}
                  >
                    {`Are you sure you would like to Delete This Code?`}
                  </p>
                  <div className="w-100 d-flex">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn_admina delete-button"
                        onClick={() => {
                          setOpen(true);
                          setdeleteOpen(false);
                        }}
                        style={{ lineHeight: "15px" }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn_admina delete-confirm-button"
                        // onClick={() => {
                        //     onConfirm();
                        //     setOpen((pre) => ({...pre, link: false}));
                        // }}
                        onClick={() => {
                          setdeleteOpen(false);
                          handleDelete(data?._id);
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default DiscountPopup;
