import { ENVIROMENT, GOOGLE_CLIENT_ID, GOOGLE_SECRET_ID } from "../Configration/configration";

const clientID =
GOOGLE_CLIENT_ID; // old
// const clientID = "487362461575-qb4j37c39irk7jkigkjenqhfvk6ihaf5.apps.googleusercontent.com"; // prameshbhai
// const clientID = "730412730804-n1plid9o9lsrrgb9128604rutu5qn0u4.apps.googleusercontent.com"; // new
const clientSecret = GOOGLE_SECRET_ID; // old
// const clientSecret = "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"; // prameshbhai
// const clientSecret = "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"; // new

let redirectURL;
if (ENVIROMENT == "DEV") {
  redirectURL = "https://dev.ad-anima.com"; // dev
} else if (ENVIROMENT == "STAGE") {
  redirectURL = "https://stage.ad-anima.com"; // stage
} else if (ENVIROMENT == "LIVE") {
  redirectURL = "https://app.ad-anima.com"; // live
}

// const redirectURL = "https://dev.ad-anima.com/"; // dev
// const redirectURL = "https://stage.ad-anima.com/"; // stage
// const redirectURL = "https://app.ad-anima.com"; // live
export const googleMapApiKey = "AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs";

// const authURL = "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=$clientID&redirect_uri=$redirectURL&scope=https://www.googleapis.com/auth/calendar.events&access_type=offline&consent";
// const authURL = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientID}&redirect_uri=${redirectURL}&scope=https://www.googleapis.com/auth/calendar.events&access_type=offline&consent`;
// const authURL = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=796983402070-n800ijk6pnqb69rmbtlpcvlku173tv30.apps.googleusercontent.com&redirect_uri=https://dev.ad-anima.com/&scope=https://www.googleapis.com/auth/calendar.events&access_type=offline&consent`;
// const authURL = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientID}&redirect_uri=${redirectURL}&scope=https://www.googleapis.com/auth/calendar.events&access_type=offline&consent`;
// const authURL = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=${clientID}&redirect_uri=${redirectURL}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&access_type=offline&consent&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow`;
const authURL = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=${clientID}&redirect_uri=${redirectURL}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar&access_type=offline&consent&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow`;

export const googleUrl = () => {
  return authURL;
};

const getDateTime = (date, time) => {
  const startDate = date;
  const startTime = time;

  // Convert start date and time strings to Date objects
  const startDateObj = new Date(startDate);
  const startTimeObj = new Date(startTime);

  // Set the time of startDateObj to match startTimeObj
  startDateObj.setHours(startTimeObj?.getUTCHours());
  startDateObj.setMinutes(startTimeObj?.getUTCMinutes());
  startDateObj.setSeconds(startTimeObj?.getUTCSeconds());

  // Format the merged date and time as desired (e.g., ISO string)
  const mergedDateTime = startDateObj?.toISOString();

  return mergedDateTime;
};

async function getMeetingLink(accessToken, setMeetingLink, details) {
  const passData = {
    summary: details?.eventName,
    description: details?.eventName,
    start: { dateTime: getDateTime(details?.startDate, details?.startTime) },
    end: { dateTime: getDateTime(details?.endDate, details?.endTime) },
    conferenceData: {
      createRequest: { requestId: clientSecret },
    },
  };

  try {
    const response = await fetch(
      "https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", 
        },
        body: JSON.stringify(passData),
      }
    );

    const data = await response.json();
    setMeetingLink(data);
    // console.log("meeting data: ", data);

    // if (data) {
    //     meetingData = data;
    // }
  } catch (error) {
    console.error("Error creating meeting: ", error);
  }
}

async function getAccessToken(code, setMeetingLink, details) {
  const passData = {
    code: code,
    client_id: clientID,
    client_secret: clientSecret,
    redirect_uri: redirectURL,
    grant_type: "authorization_code",
  };
  // const fdata = new FormData();
  // fdata.append("code", code);
  // fdata.append("client_id", clientID);
  // fdata.append("client_secret", clientSecret);
  // fdata.append("redirect_uri", redirectURL);
  // fdata.append("grant_type", "authorization_code");

  try {
    const response = await fetch("https://oauth2.googleapis.com/token", {
      method: "POST",
      // headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      // },
      body: JSON.stringify(passData),
    });

    const data = await response.json();
    getMeetingLink(
      data.access_token,
      (a) => {
        setMeetingLink(a);
      },
      details
    );
    return data.access_token;
  } catch (error) {
    console.error("Error fetching access token: ", error);
    return null;
  }
}

export const GoogleMeet = (code, setMeetingLink, details) => {
  getAccessToken(
    code,
    (a) => {
      setMeetingLink(a);
    },
    details
  );
};
