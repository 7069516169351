import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Grid, IconButton } from "@mui/material";
import closeIcon from "../../assets/images/close-circle.svg";
import codeIcon from "../../assets/images/ticket.png";
import { Select } from "antd";
import checked from "../../assets/images/CheckedBox.svg";
import check from "../../assets/images/CheckBox.svg";
import calenderIcon from "../../assets/images/calender.png";
import {
  discountCodeList,
  editDiscount,
  generateDiscount,
  getProviderEvent,
  getProviderService,
} from "../../action/discountCodeConstant";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

const generateRandomCode = (length) => {
  const characters =
    // "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*-_";

  let randomCode = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomCode += characters.charAt(randomIndex);
  }

  return randomCode;
};

const GenerateCodePopup = ({
  open,
  setOpen,
  is_edit = false,
  data,
  setIsEdit,
  setOpenDiscountSucsess,
  setOpenShare,
}) => {
  const dispatch = useDispatch();
  const { discountCode } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  const [details, setDetails] = useState({
    percentage: "",
    discount_type: "non_specific",
    service: [],
    event: [],
    limit: "",
    is_expire: false,
    date: "",
    discout_code: null,
    note: "",
  });

  // console.log(details, "details")

  useEffect(() => {
    dispatch(getProviderEvent());
    dispatch(getProviderService());
  }, []);

  // console.log(discountCode?.eventList, "discountCode?.eventList")

  const getEventOptions = () => {
    const OPTION = [];
    discountCode?.eventList?.map((ele) => {
      if (ele?.is_event_cancel == false) {
        OPTION.push({
          value: ele?._id,
          label: ele?.event_name,
        });
      }
    });
    return OPTION;
  };
  const getServiceOptions = () => {
    const OPTION = [];
    discountCode?.serviceList?.map((ele) => {
      OPTION.push({
        value: ele?._id,
        label: ele?.service_name,
      });
    });
    return OPTION;
  };

  const recurrentJson = (EVENTID) => {
    return EVENTID?.map((ele) => {
      return {
        event_id: ele,
        is_recurrent_event: false,
        recurrent_event_ids: [],
      };
    });
  };

  const getDiscountList = () => {
    dispatch(discountCodeList());
  };

  const handleGenerateCode = () => {
    if (handleCheckError()) {
      setLoader(true);
      const payload = {
        discount_percentage: details?.percentage,
        discount_type: details?.discount_type,
        service_ids: details?.service,
        event_ids: details?.event,
        events_ids_details: recurrentJson(details?.event),
        is_set_expire_date: details?.is_expire,
        expire_date: details?.date,
        limit_user_count: details?.limit,
        note: details?.note,
      };
      dispatch(
        generateDiscount(
          payload,
          () => {
            getDiscountList();
          },
          (apiData) => {
            setDetails({
              ...details,
              discout_code: apiData?.discount_code,
              discount_code_id: apiData?._id,
            });
            setLoader(false);
          },
          () => {
            // setIsEdit(true);
          },
          () => {
            // handleClearData();
          },
          () => {
            // setOpenDiscountSucsess(true);
          },
          () => {}
        )
      );
    }
  };

  const handleCheckError = () => {
    let ERROR = true;
    let setErrors = {};
    if (!details?.percentage) {
      ERROR = false;
      setErrors.percentage = "Please entre percentage.";
    } else {
      setErrors.percentage = "";
    }

    if (
      details?.service?.length <= 0 &&
      details?.discount_type == "only_service"
    ) {
      ERROR = false;
      setErrors.service = "Please select minimum 1 service";
    } else {
      setErrors.service = "";
    }

    if (details?.event?.length <= 0 && details.discount_type == "only_event") {
      ERROR = false;
      setErrors.event = "Please select minimum 1 event";
    } else {
      setErrors.event = "";
    }

    if (
      details?.service?.length <= 0 &&
      details?.event?.length <= 0 &&
      details?.discount_type == "specific"
    ) {
      ERROR = false;
      setErrors.event = "Please select minimum 1 service/event";
    } else {
      setErrors.event = "";
    }

    // if (!details?.limit) {
    //     ERROR = false
    //     setErrors.limit = "Please entre limit"
    // } else {
    //     setErrors.limit = ""
    // }
    if (details?.date == "" && details?.is_expire == true) {
      ERROR = false;
      setErrors.date = "Please select expire date";
    } else {
      setErrors.date = "";
    }

    setError(setErrors);
    return ERROR;
  };

  const handleEditCode = () => {
    if (handleCheckError()) {
      setLoader(true);
      const payload = {
        discount_percentage: details?.percentage,
        discount_type: details?.discount_type,
        service_ids: details?.service,
        event_ids: details?.event,
        events_ids_details: recurrentJson(details?.event),
        is_set_expire_date: details?.is_expire,
        expire_date: details?.date,
        limit_user_count: details?.limit,
        discount_code_id: details?.discount_code_id,
        discount_code: details?.discout_code,
        note: details?.note,
      };
      dispatch(
        editDiscount(
          payload,
          () => {
            getDiscountList();
          },
          () => {
            setLoader(false);
          },
          () => {
            setIsEdit(false);
            setOpenShare(false);
          },
          () => {
            handleClearData();
          }
        )
      );
    }
  };

  const createServiceJson = () => {
    const IDS = [];
    data?.service_ids?.map((ele) => {
      IDS.push(ele?._id);
    });
    return IDS;
  };
  const createEventJson = () => {
    const IDS = [];
    data?.events_ids_details?.map((ele) => {
      if (ele?.event_id?.is_event_cancel == false) {
        IDS.push(ele?.event_id?._id);
      }
    });
    return IDS;
  };

  useEffect(() => {
    if (is_edit == true) {
      setDetails({
        percentage: data?.discount_percentage,
        discount_type: data?.discount_type,
        service: createServiceJson(),
        event: createEventJson(),
        limit: data?.limit_user_count,
        is_expire: data?.is_set_expire_date,
        date: moment(new Date(data?.expire_date)).format("YYYY-MM-DD"),
        discout_code: data?.discount_code || null,
        note: data?.notes || "",
        discount_code_id: data?._id,
      });
    } else {
      if (!open) {
        handleClearData();
      }
      // setIsEdit(false)
    }
  }, [is_edit, open]);

  const handleClearData = () => {
    setOpen(false);
    // setIsEdit(false)
    setDetails({
      percentage: "",
      discount_type: "non_specific",
      service: [],
      event: [],
      limit: "",
      is_expire: false,
      date: "",
      discout_code: null,
      note: "",
    });
  };

  console.log(details);

  return (
    <>
      <Modal
        centered
        show={open}
        onHide={() => {
          handleClearData();
        }}
        dialogClassName="delete-modal discount-modal"
      >
        <div className="Congratulations_msg" style={{ maxWidth: "900px" }}>
          <div className="p-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 t-19-bold">Generate Discount Code</p>
              <IconButton onClick={() => setOpen(false)}>
                <img src={closeIcon} alt="" />
              </IconButton>
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="ref-code-box">
                    <div className="d-flex gap-2 align-items-center">
                      <img src={codeIcon} alt="" />
                      <p className="m-0 t-17-bold">Set Discount</p>
                    </div>
                    <hr />
                    <div>
                      <div className="group-input">
                        <input
                          type="number"
                          name=""
                          min="0"
                          style={{
                            color: "#000000",
                          }}
                          max="100"
                          value={details?.percentage}
                          placeholder="Enter discount percentage"
                          // placeholder={`${registration?.provider_services?.length ? `${registration?.provider_services?.length} selected Services` : "Select service  Name"} `}
                          className="select-servcesss map-select"
                          onChange={(e) => {
                            if (
                              /[0-9]+$/.test(e.target.value) &&
                              Number(e.target.value) <= 100
                            ) {
                              setDetails((pre) => ({
                                ...pre,
                                percentage: e.target.value,
                              }));
                            }
                          }}
                        />
                      </div>
                      <p className="t-13-bold text-danger">
                        {error?.percentage}
                      </p>
                    </div>
                    <div>
                      <div className="d-flex border-gred session-select mt-3 font-size">
                        <Select
                          value={details.discount_type}
                          // defaultValue={providerService()[0]?.label}
                          placeholder={"Select Discount Type"}
                          style={{ width: "100%", height: "45px" }}
                          onChange={(value) => {
                            setDetails((pre) => ({
                              ...pre,
                              discount_type: value,
                            }));
                          }}
                          options={[
                            { value: "non_specific", label: "All Bookings" },
                            { value: "only_event", label: "Only Events " },
                            { value: "only_service", label: "Only Services" },
                            { value: "specific", label: "Specific Products" },
                          ]}
                          className="serviceSelector"
                        />
                      </div>
                    </div>
                    {details?.discount_type == "only_service" ||
                    details?.discount_type == "specific" ? (
                      <div>
                        <div className="d-flex border-gred session-select mt-3 discount font-size">
                          <Select
                            showSearch
                            mode="multiple"
                            value={details.service}
                            // defaultValue={providerService()[0]?.label}
                            placeholder="Select Services"
                            style={{ width: "100%", height: "45px" }}
                            onChange={(value) => {
                              setDetails((pre) => ({ ...pre, service: value }));
                            }}
                            options={getServiceOptions()}
                            maxTagCount={"responsive"}
                            className="serviceSelector"
                          />
                        </div>
                        <p className="t-13-bold text-danger">
                          {error?.service}
                        </p>
                      </div>
                    ) : null}
                    {details?.discount_type == "only_event" ||
                    details?.discount_type == "specific" ? (
                      <div>
                        <div className="d-flex border-gred session-select mt-3 discount font-size">
                          <Select
                            showSearch
                            mode="multiple"
                            value={details.event}
                            // defaultValue={providerService()[0]?.label}
                            placeholder="Select Events"
                            style={{ width: "100%", height: "45px" }}
                            onChange={(value) => {
                              setDetails((pre) => ({ ...pre, event: value }));
                            }}
                            options={getEventOptions()}
                            maxTagCount={"responsive"}
                            className="serviceSelector"
                          />
                        </div>
                        <p className="t-13-bold text-danger">{error?.event}</p>
                      </div>
                    ) : null}
                    <div>
                      <div className="group-input">
                        <input
                          style={{
                            color: "#000000",
                          }}
                          type="number"
                          name=""
                          min="0"
                          value={details?.limit}
                          placeholder={"How many people use the code"}
                          // placeholder={`${registration?.provider_services?.length ? `${registration?.provider_services?.length} selected Services` : "Select service  Name"} `}
                          className="select-servcesss map-select"
                          onChange={(e) => {
                            if (/[0-9]+$/.test(e.target.value)) {
                              setDetails((pre) => ({
                                ...pre,
                                limit: e.target.value,
                              }));
                            }
                          }}
                        />
                      </div>
                      <p className="t-13-bold text-danger">{error?.limit}</p>
                    </div>
                  </div>
                  {details?.discout_code !== null && (
                    <>
                      <p
                        className="t-17-bold mb-0"
                        style={{ marginTop: "20px" }}
                      >
                        Discount Code
                      </p>
                      <div className="group-input mt-1">
                        <input
                          style={{
                            color: "#000000",
                          }}
                          type="text"
                          name=""
                          maxLength={8}
                          value={details?.discout_code || ""}
                          placeholder={"Discount Code"}
                          // placeholder={`${registration?.provider_services?.length ? `${registration?.provider_services?.length} selected Services` : "Select service  Name"} `}
                          className="select-servcesss map-select"
                          onChange={(e) => {
                            if (/^\S*$/.test(e.target.value)) {
                              setDetails((pre) => ({
                                ...pre,
                                discout_code: e.target.value,
                              }));
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="ref-code-box">
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        src={calenderIcon}
                        alt=""
                        style={{ width: "22px" }}
                      />
                      <p className="m-0 t-17-bold">Set Timing</p>
                    </div>
                    <hr />
                    <div className="mt-2">
                      <p>You want to set expiration date?</p>
                      <div>
                        <div className="d-flex gap-2">
                          <div
                            className="d-flex align-items-center c-pointer"
                            onClick={() =>
                              setDetails((pre) => ({
                                ...pre,
                                is_expire: true,
                              }))
                            }
                          >
                            <img
                              // src={checked}
                              src={details?.is_expire ? checked : check}
                              alt=""
                            />
                            <p className="m-0">Yes</p>
                          </div>
                          <div
                            className="d-flex align-items-center c-pointer"
                            onClick={() =>
                              setDetails((pre) => ({
                                ...pre,
                                is_expire: false,
                              }))
                            }
                          >
                            <img
                              // src={check}
                              src={!details?.is_expire ? checked : check}
                              alt=""
                            />
                            <p className="m-0">No</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {details?.is_expire ? (
                        <div>
                          <div className="group-input mt-2 p-15 clearfix">
                            <input
                              required
                              style={{
                                color: "#000000",
                              }}
                              type="date"
                              onChange={(e) => {
                                // console.log({endDate: e.target.value})
                                setDetails((pre) => ({
                                  ...pre,
                                  date: e.target.value,
                                }));
                              }}
                              value={details?.date}
                              // disabled={details?.startDate ? false : true}
                              placeholder="MM/DD/YYYY"
                              min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`}-${(new Date().getDate()) < 10 ? `0${new Date().getDate()}` : `${new Date().getDate()}`}`}
                            />
                          </div>
                          <p className="t-13-bold text-danger">{error?.date}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <p className="mb-0 t-17-bold" style={{ marginTop: "20px" }}>
                    Note (Optional)
                  </p>
                  <div className="group-input mt-1">
                    <textarea
                      style={{
                        color: "#000000",
                      }}
                      type="text"
                      name=""
                      min="0"
                      maxLength={300}
                      value={details?.note}
                      placeholder={"Note"}
                      // placeholder={`${registration?.provider_services?.length ? `${registration?.provider_services?.length} selected Services` : "Select service  Name"} `}
                      className="select-servcesss map-select"
                      onChange={(e) => {
                        setDetails((pre) => ({
                          ...pre,
                          note: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div
                    className="d-flex flex-wrap align-items-center justify-content-center w-100 mt-4  g-code-btn"
                    style={{
                      // position: 'absolute',
                      width: "100%",
                      bottom: "0",
                      right: "0",
                    }}
                  >
                    {loader ? (
                      <a
                        href="#"
                        style={{
                          maxWidth: "350px",
                          height: "45px",
                          fontWeight: "600",
                          padding: "12px 15px",
                        }}
                        onClick={(e) => e.preventDefault()}
                        className="btn_White massageBtn mt-3"
                      >
                        {/* save */}
                        <div className="loader"></div>
                      </a>
                    ) : (
                      <>
                        {details?.discout_code !== null ? (
                          <button
                            className="massageBtn mt-3"
                            onClick={() => {
                              if(details?.discout_code?.length < 8){
                                toast.error("Please enter 8 character discount code.")
                              }else{
                              handleEditCode();
                              }
                            }}
                            style={{
                              maxWidth: "350px",
                              height: "45px",
                              fontWeight: "600",
                            }}
                          >
                            Save Code
                          </button>
                        ) : (
                          <button
                            className="massageBtn mt-3"
                            onClick={() => {
                              handleGenerateCode();
                            }}
                            style={{
                              maxWidth: "350px",
                              height: "45px",
                              fontWeight: "600",
                            }}
                          >
                            Generate Code
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenerateCodePopup;
