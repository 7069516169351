import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Grid, IconButton } from "@mui/material";
import closeIcon from "../../assets/images/close-circle.svg";
import codeIcon from "../../assets/images/ticket.png";
import { Select } from "antd";
import checked from "../../assets/images/CheckedBox.svg";
import check from "../../assets/images/CheckBox.svg";
import calenderIcon from "../../assets/images/calender.png";
import {
  discountCodeList,
  editDiscount,
  generateDiscount,
  getProviderEvent,
  getProviderService,
} from "../../action/discountCodeConstant";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  createDiscountEvent,
  editDiscountEvent,
  createEvent,
} from "../../action/myEventConstant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  APP_VERSION,
  BASE_URL,
  URL_PATH,
} from "../../Configration/configration";
import { toast } from "react-toastify";

const generateRandomCode = (length) => {
  const characters =
    // "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*-_";

  let randomCode = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomCode += characters.charAt(randomIndex);
  }

  return randomCode;
};

const GenerateCodePopup = ({
  EventMedia,
  dublicateEventMedia,
  duplicateAddedMedia,
  open,
  setOpen,
  is_edit = false,
  data,
  setIsEdit,
  setOpenDiscountSucsess,
  userDetails,
  validation,
  mergeDateTime,
  setIsBankAlart,
  setLoaderPre,
  getServiceJson,
  getProviderJson,
  getPackageJson,
  meetingLink,
  loactionDetails,
  handleCliearData,
  setIsLinkPopup,
  setPreviewPopup,
  eventDetails,
  seteventDetails,
}) => {
  const dispatch = useDispatch();
  const { discountCode } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { id } = useParams();
  const code = params.get("code");
  const is_duplicate_value = params.get("is_duplicate");
  let is_duplicate = /true/.test(is_duplicate_value);
  const [error, setError] = useState({});
  const momentZone = moment.tz.guess();
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    percentage: "",
    discount_type: "specific",
    service: [],
    event: [],
    limit: "",
    is_expire: false,
    date: "",
    discout_code: null,
    note: "",
  });

  useEffect(() => {
    dispatch(getProviderEvent());
    dispatch(getProviderService());
  }, []);

  const getEventOptions = () => {
    const OPTION = [];
    discountCode?.eventList?.map((ele) => {
      OPTION.push({
        value: ele?._id,
        label: ele?.event_name,
      });
    });
    return OPTION;
  };
  const getServiceOptions = () => {
    const OPTION = [];
    discountCode?.serviceList?.map((ele) => {
      OPTION.push({
        value: ele?._id,
        label: ele?.service_name,
      });
    });
    return OPTION;
  };

  const recurrentJson = (EVENTID) => {
    return EVENTID?.map((ele) => {
      return {
        event_id: ele,
        is_recurrent_event: false,
        recurrent_event_ids: [],
      };
    });
  };

  const getDiscountList = () => {
    dispatch(discountCodeList());
  };

  const handleGenerateCode = (data) => {
    // if (handleCheckError()) {
    if (eventDetails?.discount_code_details) {
      setLoader(true);
      const payload = {
        discount_percentage: details?.percentage,
        discount_type: details?.discount_type,
        service_ids: details?.service,
        event_ids: [eventDetails?._id],
        events_ids_details: recurrentJson([eventDetails?._id]),
        is_set_expire_date: details?.is_expire,
        expire_date: details?.date,
        limit_user_count: details?.limit,
        discount_code_id: eventDetails?.discount_code_details?._id,
      };
      dispatch(
        editDiscount(
          payload,
          () => {
            getDiscountList();
          },
          () => {
            setLoader(false);
          },
          () => {
            setIsEdit(false);
          },
          () => {
            handleClearData();
          },
          () => {
            setOpenDiscountSucsess(true);
          },
          () => {
            setIsLinkPopup(true);
          }
        )
      );
    } else {
      setLoader(true);
      const payload = {
        discount_percentage: details?.percentage,
        discount_type: details?.discount_type,
        service_ids: details?.service,
        event_ids: [data?._id],
        events_ids_details: recurrentJson([data?._id]),
        is_set_expire_date: details?.is_expire,
        expire_date: details?.date,
        limit_user_count: details?.limit,
        is_created_from_event: true,
      };
      dispatch(
        generateDiscount(
          payload,
          () => {
            getDiscountList();
          },
          () => {
            setLoader(false);
          },
          () => {
            setIsEdit(false);
          },
          () => {
            handleClearData();
          },
          () => {
            setOpenDiscountSucsess(true);
          },
          () => {
            setIsLinkPopup(true);
          }
        )
      );
    }

    // }
  };

  const handleDiscountCreateEvent = (e, preview) => {
    e.preventDefault();
    if (validation() && handleCheckError()) {
      // if (preview) {
      //   setLoaderPre(true);
      // } else {
      //   setLoader(true);
      // }
      let payload = {
        event_name: eventDetails?.eventName,
        start_date: mergeDateTime(
          eventDetails?.startDate,
          new Date(eventDetails?.startTime)
        ),
        end_date: mergeDateTime(
          eventDetails?.endDate,
          new Date(eventDetails?.endTime)
        ),
        // end_date: new Date(details?.endDate),
        start_time: mergeDateTime(
          eventDetails?.startDate,
          new Date(eventDetails?.startTime)
        ),
        end_time: mergeDateTime(
          eventDetails?.endDate,
          new Date(eventDetails?.endTime)
        ),
        about: eventDetails?.about,
        activities: eventDetails?.activity.filter((item) => item !== ""),
        website_link: eventDetails?.link ?? "",
        event_type: eventDetails?.eventType,
        need_provider: eventDetails?.needProvider,
        is_accomodations_provided: eventDetails?.accommodation,
        // package_details: getPackageJson(),
        is_duplicate: false,

        is_virtual_event: eventDetails?.virtual,
        is_publish: true,
        current_timezone: momentZone,
      };

      if (eventDetails?._id) {
        payload.event_id = eventDetails?._id;
      }
      if (is_duplicate == true) {
        payload.parent_event_id = id;
        payload.duplicate_event_file = dublicateEventMedia();
        payload.is_duplicate = true;
        if (duplicateAddedMedia()?.length > 0) {
          payload.event_media = duplicateAddedMedia();
        }
      } else {
        payload.event_media = EventMedia();
      }

      if (eventDetails?.event_id !== null) {
        payload.event_id = eventDetails?.event_id;
      }
      if (eventDetails?.service?.length && eventDetails?.needProvider) {
        payload.service_details = getServiceJson();
      }
      if (getProviderJson()?.length) {
        payload.add_provider = getProviderJson();
      }
      if (getPackageJson()?.length) {
        payload.package_details = getPackageJson();
      }
      if (meetingLink && eventDetails?.virtual) {
        payload.event_join_link = meetingLink?.hangoutLink;
      }
      if (!eventDetails?.virtual) {
        payload.location = {
          type: "Point",
          coordinates: [
            loactionDetails?.latlong?.lng,
            loactionDetails?.latlong?.lat,
          ],
        };
        payload.address = loactionDetails?.address;
      }

      const fdata = new FormData();
      if (details?.id) {
        fdata.append("discount_code_id", details?.id);
      }
      fdata.append("discount_code_string", details?.discout_code);

      fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.verify_discount_code}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
          }`,
        },
        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.success) {
            if (eventDetails?._id) {
              seteventDetails({
                ...eventDetails,
                discountCodeData: details,
                isEditDiscountCode: true,
              });
              setOpen(false);

              // dispatch(editDiscountEvent(payload, () => {
              //     navigate(preview ? null : `/my_events`)
              // }, (a) => {
              //     setLoader(a)
              // }, () => {
              //     handleCliearData()
              // }, preview, () => {
              //     setPreviewPopup(true)
              // }, (a) => {
              //     setLoaderPre(a)
              // },(data) => {
              //     handleGenerateCode(data)
              // }))
            } else {
              seteventDetails({
                ...eventDetails,
                discountCodeData: details,
                isCreateDiscountCode: true,
              });
              setOpen(false);
              // dispatch(createDiscountEvent(payload, () => {
              //     navigate(preview ? null : `/my_events`)
              // }, (a) => {
              //     setLoader(a)
              // }, () => {
              //     handleCliearData()
              // }, (a) => {
              //     setIsLinkPopup(false)
              // }, preview, () => {
              //     setPreviewPopup(true)
              // }, (a) => {
              //     setLoaderPre(a)
              // }, (data) => {
              //     handleGenerateCode(data)
              // }))
            }
          } else {
            toast.error(data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      localStorage.removeItem("event_draft");
      localStorage.removeItem("event_location_draft");
      // setPreviewPopup(true)
    }
  };

  const handalSubmit = () => {};

  const handleCheckError = () => {
    let ERROR = true;
    let setErrors = {};
    if (!details?.percentage) {
      ERROR = false;
      setErrors.percentage = "Please entre percentage.";
    } else {
      setErrors.percentage = "";
    }

    if (details?.date == "" && details?.is_expire == true) {
      ERROR = false;
      setErrors.date = "Please select expire date";
    } else {
      setErrors.date = "";
    }

    setError(setErrors);
    return ERROR;
  };

  const handleEditCode = () => {
    if (handleCheckError()) {
      setLoader(true);
      const payload = {
        discount_percentage: details?.percentage,
        discount_type: details?.discount_type,
        service_ids: details?.service,
        event_ids: details?.event,
        events_ids_details: recurrentJson(details?.event),
        is_set_expire_date: details?.is_expire,
        expire_date: details?.date,
        limit_user_count: details?.limit,
        discount_code_id: data?._id,
      };
      dispatch(
        editDiscount(
          payload,
          () => {
            getDiscountList();
          },
          () => {
            setLoader(false);
          },
          () => {
            setIsEdit(false);
          },
          () => {
            handleClearData();
          }
        )
      );
    }
  };

  const createServiceJson = () => {
    const IDS = [];
    data?.service_ids?.map((ele) => {
      IDS.push(ele?._id);
    });
    return IDS;
  };
  const createEventJson = () => {
    const IDS = [];
    data?.events_ids_details?.map((ele) => {
      IDS.push(ele?.event_id?._id);
    });
    return IDS;
  };


  useEffect(() => {
    // console.log(eventDetails,data, "eventDetails")
    if (is_duplicate) {
      if (eventDetails?.discount_code_details) {
        setDetails({
          percentage: eventDetails?.discount_code_details?.discount_percentage,
          discount_type: eventDetails?.discount_code_details?.discount_type,
          service: eventDetails?.discount_code_details?.service_ids,
          event: eventDetails?.discount_code_details?.event_ids,
          limit: eventDetails?.discount_code_details?.limit_user_count,
          is_expire: eventDetails?.discount_code_details?.is_set_expire_date,
          date: eventDetails?.discount_code_details?.expire_date
            ? moment(
                new Date(eventDetails?.discount_code_details?.expire_date)
              ).format("YYYY-MM-DD")
            : "",
          discout_code: null,
          note: eventDetails?.discount_code_details?.notes || "",
        });
      } else if (is_edit == true) {
        setDetails({
          percentage: data?.discount_percentage,
          discount_type: data?.discount_type,
          service: createServiceJson(),
          event: createEventJson(),
          limit: data?.limit_user_count,
          is_expire: data?.is_set_expire_date,
          date: moment(new Date(data?.expire_date)).format("YYYY-MM-DD"),
          discout_code: data?.discount_code || null,
          note: data?.notes || "",
        });
      }
    } else {
      if (eventDetails?.discount_code_details) {
        if(eventDetails?.discountCodeData){
          setDetails({
            percentage: eventDetails?.discountCodeData?.percentage,
            discount_type: eventDetails?.discountCodeData?.discount_type,
            service: eventDetails?.discountCodeData?.service,
            event: eventDetails?.discountCodeData?.event,
            limit: eventDetails?.discountCodeData?.limit,
            is_expire: eventDetails?.discountCodeData?.is_expire,
            date: eventDetails?.discountCodeData?.date
              ? moment(
                  new Date(eventDetails?.discountCodeData?.date)
                ).format("YYYY-MM-DD")
              : "",
            id: eventDetails?.discountCodeData?.id,
            discout_code:
              eventDetails?.discountCodeData?.discout_code || null,
            note: eventDetails?.discountCodeData?.note || "",
          });
        }else{
          setDetails({
            percentage: eventDetails?.discount_code_details?.discount_percentage,
            discount_type: eventDetails?.discount_code_details?.discount_type,
            service: eventDetails?.discount_code_details?.service_ids,
            event: eventDetails?.discount_code_details?.event_ids,
            limit: eventDetails?.discount_code_details?.limit_user_count,
            is_expire: eventDetails?.discount_code_details?.is_set_expire_date,
            date: eventDetails?.discount_code_details?.expire_date
              ? moment(
                  new Date(eventDetails?.discount_code_details?.expire_date)
                ).format("YYYY-MM-DD")
              : "",
            id: eventDetails?.discount_code_details?._id,
            discout_code:
              eventDetails?.discount_code_details?.discount_code || null,
            note: eventDetails?.discount_code_details?.notes || "",
          });
        }
        
      } else if (is_edit == true) {
        setDetails({
          percentage: data?.discount_percentage,
          discount_type: data?.discount_type,
          service: createServiceJson(),
          event: createEventJson(),
          limit: data?.limit_user_count,
          is_expire: data?.is_set_expire_date,
          date: moment(new Date(data?.expire_date)).format("YYYY-MM-DD"),
          discout_code: data?.discount_code || null,
          note: data?.notes || "",
        });
      }
    }
  }, [is_edit, eventDetails, open]);

  const handleClearData = () => {
    setOpen(false);
    setDetails({
      percentage: "",
      discount_type: "non_specific",
      service: [],
      event: [],
      limit: "",
      is_expire: false,
      date: "",
      discout_code: null,
      note: "",
    });
  };

  // console.log(open, "open")

  return (
    <>
      <Modal
        centered
        show={open}
        onHide={() => {
          handleClearData();
        }}
        dialogClassName="delete-modal discount-modal"
      >
        <div className="Congratulations_msg" style={{ maxWidth: "900px" }}>
          <div className="p-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 t-19-bold">Generate Discount Code</p>
              <IconButton onClick={() => setOpen(false)}>
                <img src={closeIcon} alt="" />
              </IconButton>
            </div>
            <div className="mt-2">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="ref-code-box">
                    <div className="d-flex gap-2 align-items-center">
                      <img src={codeIcon} alt="" />
                      <p className="m-0 t-17-bold">Set Discount</p>
                    </div>
                    <hr />
                    <div>
                      <div className="group-input">
                        <input
                          type="number"
                          min="0"
                          max="100"
                          name=""
                          style={{
                            color: "#000000",
                          }}
                          value={details?.percentage}
                          placeholder="Enter discount percentage"
                          // placeholder={`${registration?.provider_services?.length ? `${registration?.provider_services?.length} selected Services` : "Select service  Name"} `}
                          className="select-servcesss map-select"
                          onChange={(e) => {
                            if (
                              /[0-9]+$/.test(e.target.value) &&
                              Number(e.target.value) <= 100
                            ) {
                              setDetails((pre) => ({
                                ...pre,
                                percentage: e.target.value,
                              }));
                            }
                          }}
                        />
                      </div>
                      <p className="t-13-bold text-danger">
                        {error?.percentage}
                      </p>
                    </div>
                    {/*<div>*/}
                    {/*    <div className="d-flex border-gred session-select mt-3 font-size">*/}
                    {/*        <Select*/}
                    {/*            value={details.discount_type}*/}
                    {/*            // defaultValue={providerService()[0]?.label}*/}
                    {/*            placeholder={"Select Discount Type"}*/}
                    {/*            style={{width: "100%", height: "45px"}}*/}
                    {/*            onChange={(value) => {*/}
                    {/*                setDetails((pre) => ({...pre, discount_type: value}))*/}
                    {/*            }}*/}
                    {/*            options={[*/}
                    {/*                {value: "non_specific", label: "All Bookings"},*/}
                    {/*                {value: "non_specific", label: "Only Events "},*/}
                    {/*                {value: "only_service", label: "Only Services"},*/}
                    {/*                {value: "specific", label: "Specific Products"},*/}
                    {/*            ]}*/}
                    {/*            className="serviceSelector"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*{(details?.discount_type == "only_service" || details?.discount_type == "specific") ?*/}
                    {/*    <div>*/}
                    {/*        <div*/}
                    {/*            className="d-flex border-gred session-select mt-3 discount font-size">*/}
                    {/*            <Select*/}
                    {/*                showSearch*/}
                    {/*                mode="multiple"*/}
                    {/*                value={details.service}*/}
                    {/*                // defaultValue={providerService()[0]?.label}*/}
                    {/*                placeholder="Select Services"*/}
                    {/*                style={{width: "100%", height: "45px"}}*/}
                    {/*                onChange={(value) => {*/}
                    {/*                    setDetails((pre) => ({...pre, service: value}))*/}
                    {/*                }}*/}
                    {/*                options={getServiceOptions()}*/}
                    {/*                maxTagCount={'responsive'}*/}
                    {/*                className="serviceSelector"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <p className="t-13-bold text-danger">{error?.service}</p>*/}
                    {/*    </div> : null}*/}
                    {/*{(details?.discount_type == "non_specific" || details?.discount_type == "specific") ?*/}
                    {/*    <div>*/}
                    {/*        <div*/}
                    {/*            className="d-flex border-gred session-select mt-3 discount font-size">*/}
                    {/*            <Select*/}
                    {/*                showSearch*/}
                    {/*                mode="multiple"*/}
                    {/*                value={details.event}*/}
                    {/*                // defaultValue={providerService()[0]?.label}*/}
                    {/*                placeholder="Select Events"*/}

                    {/*                style={{width: "100%", height: "45px"}}*/}
                    {/*                onChange={(value) => {*/}
                    {/*                    setDetails((pre) => ({...pre, event: value}))*/}
                    {/*                }}*/}
                    {/*                options={getEventOptions()}*/}
                    {/*                maxTagCount={'responsive'}*/}
                    {/*                className="serviceSelector"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <p className="t-13-bold text-danger">{error?.event}</p>*/}
                    {/*    </div> : null}*/}
                    <div>
                      <div className="group-input">
                        <input
                          type="number"
                          style={{
                            color: "#000000",
                          }}
                          name=""
                          min="0"
                          value={details?.limit}
                          placeholder={"How many people use the code"}
                          // placeholder={`${registration?.provider_services?.length ? `${registration?.provider_services?.length} selected Services` : "Select service  Name"} `}
                          className="select-servcesss map-select"
                          onChange={(e) => {
                            setDetails((pre) => ({
                              ...pre,
                              limit: e.target.value,
                            }));
                          }}
                        />
                      </div>
                      <p className="t-13-bold text-danger">{error?.limit}</p>
                    </div>
                  </div>
                  {details?.discout_code !== null && (
                    <>
                      <p
                        className="t-17-bold mb-0"
                        style={{ marginTop: "20px" }}
                      >
                        Discount Code
                      </p>
                      <div className="group-input mt-1">
                        <input
                          style={{
                            color: "#000000",
                          }}
                          type="text"
                          name=""
                          maxLength={8}
                          value={details?.discout_code || ""}
                          placeholder={"Discount Code"}
                          // placeholder={`${registration?.provider_services?.length ? `${registration?.provider_services?.length} selected Services` : "Select service  Name"} `}
                          className="select-servcesss map-select"
                          onChange={(e) => {
                            if (/^\S*$/.test(e.target.value)) {
                              setDetails((pre) => ({
                                ...pre,
                                discout_code: e.target.value,
                              }));
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="ref-code-box">
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        src={calenderIcon}
                        alt=""
                        style={{ width: "22px" }}
                      />
                      <p className="m-0 t-17-bold">Set Timing</p>
                    </div>
                    <hr />
                    <div className="mt-2">
                      <p>You want to set expiration date?</p>
                      <div>
                        <div className="d-flex gap-2">
                          <div
                            className="d-flex align-items-center c-pointer"
                            onClick={() =>
                              setDetails((pre) => ({
                                ...pre,
                                is_expire: true,
                              }))
                            }
                          >
                            <img
                              // src={checked}
                              src={details?.is_expire ? checked : check}
                              alt=""
                            />
                            <p className="m-0">Yes</p>
                          </div>
                          <div
                            className="d-flex align-items-center c-pointer"
                            onClick={() =>
                              setDetails((pre) => ({
                                ...pre,
                                is_expire: false,
                              }))
                            }
                          >
                            <img
                              // src={check}
                              src={!details?.is_expire ? checked : check}
                              alt=""
                            />
                            <p className="m-0">No</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {details?.is_expire ? (
                        <div>
                          <div className="group-input mt-2 p-15 clearfix">
                            <input
                              required
                              style={{
                                color: "#000000",
                              }}
                              type="date"
                              onChange={(e) => {
                                // console.log({endDate: e.target.value})
                                setDetails((pre) => ({
                                  ...pre,
                                  date: e.target.value,
                                }));
                              }}
                              value={details?.date}
                              // disabled={details?.startDate ? false : true}
                              placeholder="MM/DD/YYYY"
                              min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`}-${(new Date().getDate()) < 10 ? `0${new Date().getDate()}` : `${new Date().getDate()}`}`}
                            />
                          </div>
                          <p className="t-13-bold text-danger">{error?.date}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <p className="mb-0 t-17-bold" style={{ marginTop: "20px" }}>
                    Note (Optional)
                  </p>
                  <div className="group-input mt-1">
                    <textarea
                      style={{
                        color: "#000000",
                      }}
                      type="text"
                      name=""
                      min="0"
                      maxLength={300}
                      value={details?.note}
                      placeholder={"Note"}
                      // placeholder={`${registration?.provider_services?.length ? `${registration?.provider_services?.length} selected Services` : "Select service  Name"} `}
                      className="select-servcesss map-select"
                      onChange={(e) => {
                        setDetails((pre) => ({
                          ...pre,
                          note: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="d-flex flex-wrap align-items-center justify-content-center w-100 mt-4  g-code-btn"
                    style={{
                      // position: 'absolute',
                      width: "100%",
                      bottom: "0",
                      right: "0",
                    }}
                  >
                    {loader ? (
                      <a
                        href="#"
                        style={{
                          maxWidth: "350px",
                          height: "45px",
                          fontWeight: "600",
                          padding: "12px 15px",
                        }}
                        onClick={(e) => e.preventDefault()}
                        className="btn_White massageBtn mt-3"
                      >
                        {/* save */}
                        <div className="loader"></div>
                      </a>
                    ) : (
                      <>
                        {details?.discout_code !== null ? (
                          <button
                            className="massageBtn mt-3"
                            onClick={(e) => {
                              if(details?.discout_code?.length < 8){
                                toast.error("Please enter 8 character discount code.")
                              }else{

                                handleDiscountCreateEvent(e);
                              }
                            }}
                            style={{
                              maxWidth: "350px",
                              height: "45px",
                              fontWeight: "600",
                            }}
                          >
                            {eventDetails?.discount_code_details
                              ? "Edit"
                              : "Save"}{" "}
                            Code
                          </button>
                        ) : (
                          <button
                            className="massageBtn mt-3"
                            onClick={() => {
                              setDetails((pre) => ({
                                ...pre,
                                discout_code: generateRandomCode(8),
                              }));
                            }}
                            style={{
                              maxWidth: "350px",
                              height: "45px",
                              fontWeight: "600",
                            }}
                          >
                            Generate Code
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenerateCodePopup;
