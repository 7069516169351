import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {Grid, IconButton} from "@mui/material";
// import profileimg from "../assets/images/1.jpg";
import locationcon from "../../../assets/images/Discovery.svg"
// import unsave from "../assets/images/saveIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../../../assets/images/Discovery.svg";
import {Empty, Select} from "antd";
// import {getGuestUpcominEvents, getUpcominEvents} from "../action/upcomingEventConstant";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
// import {getEvent} from "../action/eventConstant";
// import {add_hire_and_collaborate} from "../action/hireNcollaborateConstant";
import {getEvent} from "../../../action/eventConstant";
import {
    acceptHireCollaNoti, acceptHireCollaNotiModify,
    add_hire_and_collaborate, calcleHireCollaNoti, calcleHireCollaNotiModify,
    hireNcollaRequest
} from "../../../action/hireNcollaborateConstant";
// import {getUpcominEvents} from "../../../action/upcomingEventConstant";
import CloseIcon from '@mui/icons-material/Close';
import {DateFormate, LocalFormate, timeZoneFormate} from "../../../Utils/DateFormateUtils";
import userIcon from "../../../assets/images/userIconWithBg.png"


const MOdalBtn = ({
                      data,
                      eventDetails,
                      handleAddHireCollaborate,
                      handleCancleRequest,
                      handleRequest,
                      handleCancleModiRequest,
                      handleCancelParticipents,
                      handleAcceptModiRequest,
                      disable
                  }) => {
    if (data?.is_accepted == true) {
        // cancel participents
        return (
            <div className="col-6"
                 style={{
                     width: "100%", maxWidth: "100%", display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                 }}>
                <button
                    type="submit"
                    className="btn_admina delete-button"
                    style={{maxWidth: '180px'}}
                    disabled={disable}
                    onClick={() => {
                        handleCancelParticipents()
                    }}
                >
                    Cancel Participant
                </button>
            </div>
        )
    } else {
        if (data?.noti_for == "hire_request" || data?.noti_for == "collaborate_request") {
            if (data?.is_accepted == null) {
                if (eventDetails?.amount == data?.hire_and_collaborate_id?.amount || eventDetails?.amount == data?.hire_and_collaborate_id?.request_amount) {

                    //ACC?DEC

                    return (
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <div className="col-6"
                                 style={{width: "100%", maxWidth: "170px"}}>
                                <button
                                    type="submit"
                                    disabled={disable}
                                    className="btn_admina delete-button"
                                    onClick={() => handleAddHireCollaborate()}
                                >
                                    Accept
                                </button>
                            </div>
                            <div className="col-6"
                                 style={{width: "100%", maxWidth: "170px"}}>
                                <button
                                    type="submit"
                                    disabled={disable}
                                    className="btn_admina delete-confirm-button"
                                    onClick={() => {
                                        handleCancleRequest()
                                    }}
                                >
                                    Decline
                                </button>
                            </div>
                        </div>
                    )

                } else {
                    //modify btn

                    return (
                        <div className="col-6"
                             style={{
                                 width: "100%", maxWidth: "100%", display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center',
                             }}>
                            <button
                                type="submit"
                                className="btn_admina delete-button"
                                style={{maxWidth: '180px'}}
                                disabled={disable}
                                onClick={() => {
                                    handleRequest()
                                }}
                            >
                                Modify Request
                            </button>
                        </div>
                    )
                }
            }
        } else {
            if (data?.noti_for == "modify_amount_request") {
                if (data?.is_accepted == null) {
                    if (data?.hire_and_collaborate_id?.is_request_accepted == null) {
                        if (data?.is_accepted == null) {
                            if (eventDetails?.amount == data?.hire_and_collaborate_id?.amount || eventDetails?.amount == data?.hire_and_collaborate_id?.request_amount) {
                                //ACC?DEC

                                return (
                                    <div className="w-100 d-flex justify-content-center mt-3">
                                        <div className="col-6"
                                             style={{width: "100%", maxWidth: "170px"}}>
                                            <button
                                                type="submit"
                                                className="btn_admina delete-button"
                                                onClick={() => handleAcceptModiRequest()}
                                            >
                                                Accept
                                            </button>
                                        </div>
                                        <div className="col-6"
                                             style={{width: "100%", maxWidth: "170px"}}>
                                            <button
                                                type="submit"
                                                className="btn_admina delete-confirm-button"
                                                onClick={() => {
                                                    handleCancleModiRequest()
                                                }}
                                            >
                                                Decline
                                            </button>
                                        </div>
                                    </div>
                                )
                            } else {
                                //modify btn

                                return (
                                    <div className="col-6"
                                         style={{
                                             width: "100%", maxWidth: "100%", display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                         }}>
                                        <button
                                            type="submit"
                                            className="btn_admina delete-button"
                                            style={{maxWidth: '180px'}}
                                            onClick={() => {
                                                handleRequest()
                                            }}
                                        >
                                            Modify Request
                                        </button>
                                    </div>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}


const HireCollaborateModal = ({getNotificationsList, open, setOpen, userDetails, data, formatBadge,handleClose}) => {
    let {providerId} = useParams();
    const dispatch = useDispatch();
    const [selectedEvent, setSelectedEvent] = useState({})
    const {upComingEvents, notifications} = useSelector((state) => state);
    const [LangOpen, setLangOpen] = useState(false);
    const navigate = useNavigate();
    const [eventDetails, setEventDetails] = useState({
        amount: data?.hire_and_collaborate_id?.request_amount || data?.hire_and_collaborate_id?.amount
    })

    useEffect(() => {
        setEventDetails((pre) => ({
            ...pre,
            amount: data?.hire_and_collaborate_id?.request_amount || data?.hire_and_collaborate_id?.amount
        }))
    }, [data])

    // const getEventDitails = () => {
    //     if (userDetails?.details?._id) {
    //         dispatch(getUpcominEvents(userDetails?.details?._id))
    //     }
    // }



    // useEffect(() => {
    //     getEventDitails()
    // }, []);

    // const getEventDetails = () => {
    //     dispatch(getEvent(data?.event_id, () => {
    //     }))
    // }

    useEffect(() => {
        if (data?.event_id) {
            // getEventDetails()
        }
    }, [data]);
    const calculateHourDifference = (startTime, endTime) => {
        const start = new Date(`01/01/2000 ${startTime}`);
        const end = new Date(`01/01/2000 ${endTime}`);

        const diffInMilliseconds = Math.abs(end - start);
        const hours = Math.floor(diffInMilliseconds / 1000 / 60 / 60);

        return hours;
    };



    // useEffect(() => {
    // setEventDetails({
    //     amount: "",
    // })
    // if (event?.event?.service_details?.length) {
    //     setEventDetails((pre) => ({...pre, service: event?.event?.service_details[0]}))
    // } else {
    //     setEventDetails((pre) => ({...pre, activity: event?.event?.activities}))
    // }
    // }, [event, selectedEvent])

    // useEffect(() => {
    //     if (upComingEvents?.upComingEvents?.length) {
    //         setSelectedEvent(upComingEvents?.upComingEvents[0])
    //     }
    // }, [upComingEvents]);

    const handleAddHireCollaborate = () => {
        const payload = {
            notification_id: data?._id,
        }
        dispatch(acceptHireCollaNoti(payload, (a) => {
            setOpen(a)
        }, () => {
            getNotificationsList()
        }))
        // getNotificationsList()
    }
    const handleCancleRequest = () => {
        const payload = {
            notification_id: data?._id,
        }
        dispatch(calcleHireCollaNoti(payload, (a) => {
            setOpen(a)
        }, () => {
            getNotificationsList()
        }, () => {}))

    }

    const handleRequest = () => {
        const payload = {
            hire_and_collaborate_id: data?.hire_and_collaborate_id?._id,
            notification_id: data?._id,
            request_amount: eventDetails?.amount
        }
        dispatch(hireNcollaRequest(payload, (a) => {
            setOpen(a)
        }, () => {
            getNotificationsList()
        }))

    }

    const handleCancleModiRequest = () => {
        const payload = {
            notification_id: data?._id,
            hire_and_collaborate_id: data?.hire_and_collaborate_id?._id
        }
        dispatch(calcleHireCollaNotiModify(payload, () => {
        }, () => {
            getNotificationsList()
        }))
    }
    const handleAcceptModiRequest = () => {
        const payload = {
            notification_id: data?._id,
            hire_and_collaborate_id: data?.hire_and_collaborate_id?._id
        }
        dispatch(acceptHireCollaNotiModify(payload, () => {
        }, () => {
            getNotificationsList()
        }))
    }


    const handleCancelParticipents = () => {
        const payload = {
            notification_id: data?._id,
        }
        dispatch(calcleHireCollaNoti(payload, () => {
        }, () => {
            getNotificationsList()
        }, () => {}))
    }

    useEffect(() => {
        return () => {
            setOpen(false)
        }
    }, []);

    // console.log(data, "data")

    return (<div className="eventModal">
            <Modal
                centered
                size="lg"
                backdrop="static"
                show={open.event}
                style={{zIndex: '100001', background: '#00000096'}}
                onHide={() => {
                    setOpen((pre) => ({...pre, event: false}));
                }}
                // dialogClassName="collabrate-modale"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                {/*<div className={"Congratulations_msg"} style={{width: "100%", maxWidth: "865px"}}>*/}
                <div className="inner-gred" style={{padding: '10px'}}>
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="t-24-bold mb-0">{data?.hire_and_collaborate_id?.event_request_type.charAt(0).toUpperCase() + data?.hire_and_collaborate_id?.event_request_type.slice(1)}</p>
                        <IconButton style={{background: '#8080801c'}}
                                    onClick={() => setOpen((pre) => ({...pre, event: false}))}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>

                            {/*..............left side...........*/}

                            <div className="border-gred w-100 c-pointer mt-3" onClick={() => {
                                setOpen((pre) => ({...pre, event: false}));
                                navigate(`/provider_details/${userDetails?.details?._id}`)
                            }}>
                                <div className="inner-gred w-100">
                                    <div className="d-flex align-items-center">
                                        <div className="p_image_shape me-2 me-sm-3">
                                            <img src={userDetails?.details?.profile_picture || userIcon} onError={(e) => (e.target.src = userIcon)}/>
                                        </div>
                                        <div>
                                            <p className="t-20-bold m-0 mb-2"> {userDetails?.details?.name}</p>
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={locationcon} alt=""/>
                                                <p className="m-0 yelloe-t-bold location_on_modify_hire">{userDetails?.details?.address}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="inner-gred box_shadow collabrate-event-list"
                                 style={{padding: "20px 9px 20px 20px"}} onClick={() => {
                                setOpen(false)
                                handleClose(false)
                                navigate(`/view-events/${data?.hire_and_collaborate_id?.event_id?._id}/${userDetails?.details?._id}`)
                            }}
                            >
                                {/*...............1.............*/}

                                {
                                    // !upComingEvents?.upComingEvents?.length ?  <Empty
                                    //         image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    //         description={<span>No Event Found</span>}
                                    //     /> :
                                    //     upComingEvents?.upComingEvents?.map((ele, i) => (
                                    <div
                                        className={`evant-card-bg box_shadow inner-gred w-100 mt-2  yellow_border`}>
                                        <div className="d-flex align-items-center">
                                            <div className="p_image_shape me-2 me-sm-3">
                                                <img
                                                    src={data?.hire_and_collaborate_id?.event_id?.event_media?.length ? data?.hire_and_collaborate_id?.event_id?.event_media[0]?.file_name : null}/>
                                            </div>
                                            <div>
                                                <div className="">
                                                    <p className="t-16-bold m-0 mb-1">{data?.hire_and_collaborate_id?.event_id?.event_name}</p>
                                                </div>
                                                {/*<div className="d-flex align-items-center mb-1">*/}
                                                {/*    <p className="pro_carousel_time m-0 text-nowrap ">August 23*/}
                                                {/*        2023</p>*/}
                                                {/*    <FiberManualRecordIcon style={{transform: "scale(0.3)"}}/>*/}
                                                {/*    <p className="pro_carousel_time m-0 text-nowrap ">3PM to*/}
                                                {/*        4PM</p>*/}
                                                {/*</div>*/}
                                                <div
                                                    className="d-flex flex-sm-column flex-column align-items-start align-items-sm-start mb-1">
                                                    {/*<p className="pro_carousel_time m-0">{moment(data?.hire_and_collaborate_id?.event_id?.start_date).format('Do MMM') !== moment(data?.hire_and_collaborate_id?.event_id?.end_date).format('Do MMM') ? (`${moment(data?.hire_and_collaborate_id?.event_id?.start_date).format('Do MMM')} - ${moment(data?.hire_and_collaborate_id?.event_id?.end_date).format('Do MMM, YYYY')}`) : (`${moment(data?.hire_and_collaborate_id?.event_id?.start_date).format('MMMM Do YYYY')}`)}</p>*/}
                                                    <p className="pro_carousel_time m-0">{DateFormate(LocalFormate(data?.hire_and_collaborate_id?.event_id?.start_date, data?.hire_and_collaborate_id?.event_id?.date_timezone), LocalFormate(data?.hire_and_collaborate_id?.event_id?.end_date, data?.hire_and_collaborate_id?.event_id?.date_timezone))}</p>
                                                    {/*<FiberManualRecordIcon className="d-none d-sm-block"*/}
                                                    {/*                       style={{transform: "scale(0.3)"}}/>*/}
                                                    <p className="pro_carousel_time m-0">{moment(LocalFormate(data?.hire_and_collaborate_id?.event_id?.start_time, data?.hire_and_collaborate_id?.event_id?.date_timezone)).format('LT')} to {moment(LocalFormate(data?.hire_and_collaborate_id?.event_id?.end_time, data?.hire_and_collaborate_id?.event_id?.date_timezone)).format('LT')} ({timeZoneFormate(data?.hire_and_collaborate_id?.event_id?.start_time, data?.hire_and_collaborate_id?.event_id?.date_timezone)})</p>
                                                </div>
                                                {data?.hire_and_collaborate_id?.event_id?.address ? <div className="d-flex align-items-center gap-1">
                                                    <img src={locationIcon} alt=""/>
                                                    <p className="yelloe-t-bold m-0">{data?.hire_and_collaborate_id?.event_id?.address}</p>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                    // ))
                                }


                            </div>
                            {data?.hire_and_collaborate_id?.event_request_type == "hire" ?
                                <div className="group-input mt-3 p-15 clearfix">
                                    <div className="d-flex bg-white align-items-center">
                                        <p className="m-0" style={{
                                            fontSize: '19px',
                                            fontWeight: '600',
                                            padding: '0px 10px'
                                        }}>$</p>
                                        <input
                                            type="text"
                                            name=""
                                            style={{paddingLeft: '0'}}
                                            placeholder="Enter your hiring amount"
                                            value={String(eventDetails?.amount)?.replace(/\D/g, '')}
                                            // value={eventDetails?.amount?.replace(/\s/g, '')}
                                            onChange={(e) => {
                                                if (e.target.value.length < 8) {
                                                    setEventDetails((pre) => ({
                                                        ...pre,
                                                        amount: e.target.value
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div> : <div className="group-input mt-3 p-15 clearfix">
                                    <div className="d-flex bg-white align-items-center">
                                        <p className="m-0" style={{
                                            fontSize: '19px',
                                            fontWeight: '600',
                                            padding: '0px 10px'
                                        }}>%</p>
                                        <input
                                            type="text"
                                            name=""
                                            style={{paddingLeft: '0'}}
                                            placeholder="of revenue for this provider"
                                            value={String(eventDetails?.amount)?.replace(/\D/g, '')}
                                            // value={eventDetails?.amount?.replace(/\s/g, '')}
                                            onChange={(e) => {
                                                if (e.target.value.length < 3) {
                                                    setEventDetails((pre) => ({
                                                        ...pre,
                                                        amount: e.target.value
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>}

                            <div>
                                <p className="t-14-bold m-0 mt-3">{`Type Your Message Here For ${data?.hire_and_collaborate_id?.event_request_type == "hire" ? 'Hire' : 'Collaborate'} Provider`}</p>
                                <textarea className="eventtextarea mt-1"
                                          value={data?.hire_and_collaborate_id?.message}
                                          readOnly
                                />
                            </div>
                        </Grid>


                        {/*..............buttons...........*/}
                    </Grid>
                    {/*{(formatBadge(data?.noti_for, false) === "Hiring" ||*/}
                    {/*    formatBadge(data?.noti_for, false) === "Collaborate") && <>*/}
                    {/*    {(data?.is_accepted !== null) ? null : (eventDetails?.amount == data?.hire_and_collaborate_id?.amount || eventDetails?.amount == data?.hire_and_collaborate_id?.request_amount) ?*/}
                    {/*        <div className="w-100 d-flex justify-content-center mt-3">*/}
                    {/*            <div className="col-6"*/}
                    {/*                 style={{width: "100%", maxWidth: "170px"}}>*/}
                    {/*                <button*/}
                    {/*                    type="submit"*/}
                    {/*                    className="btn_admina delete-button"*/}
                    {/*                    onClick={() => handleAddHireCollaborate()}*/}
                    {/*                >*/}
                    {/*                    Accept*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-6"*/}
                    {/*                 style={{width: "100%", maxWidth: "170px"}}>*/}
                    {/*                <button*/}
                    {/*                    type="submit"*/}
                    {/*                    className="btn_admina delete-confirm-button"*/}
                    {/*                    onClick={() => {*/}
                    {/*                        handleCancleRequest()*/}
                    {/*                    }}*/}
                    {/*                >*/}
                    {/*                    Decline*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*        </div> :*/}
                    {/*        <div className="col-6"*/}
                    {/*             style={{*/}
                    {/*                 width: "100%", maxWidth: "100%", display: 'flex',*/}
                    {/*                 alignItems: 'center',*/}
                    {/*                 justifyContent: 'center',*/}
                    {/*             }}>*/}
                    {/*            <button*/}
                    {/*                type="submit"*/}
                    {/*                className="btn_admina delete-button"*/}
                    {/*                style={{maxWidth: '180px'}}*/}
                    {/*                onClick={() => {*/}
                    {/*                    handleRequest()*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                Modify Request*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*</>}*/}

                    {/*{*/}
                    <MOdalBtn data={data} eventDetails={eventDetails}
                              handleAddHireCollaborate={handleAddHireCollaborate}
                              handleCancleRequest={handleCancleRequest} handleRequest={handleRequest}
                              handleCancleModiRequest={handleCancleModiRequest}
                              handleCancelParticipents={handleCancelParticipents}
                              handleAcceptModiRequest={handleAcceptModiRequest}
                              disable={eventDetails?.amount <= 0 ? true : false}
                    />
                    {/*}*/}
                </div>
                {/*</div>*/}
            </Modal>
        </div>
    )
}

export default HireCollaborateModal;