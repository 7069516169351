import React, {useState} from 'react';
import {Grid, IconButton} from "@mui/material";
import shareIcon from "../../assets/images/share_icon.png"
import copyIcon from "../../assets/images/copy_icon.png"
import moment from "moment";
import CodeShare from "./CodeShare";
import {refCodeGenerate} from "../../action/ReffDiscountConstant";
import {useDispatch} from "react-redux";

const RefCodeCards = ({data, showBtn, setShowBanner}) => {
    const [openShare, setOpenShare] = useState(false);
    const [sharedData, setSharedData] = useState({});
    const dispatch = useDispatch()
    const checkCodeExpire = (date) => {
        // data?.map((ele) => {
        const EXDATE = new Date(date)
        const TODAY = new Date()
        // console.log(EXDATE, TODAY, "Expire")
        // console.log(new Date().toLocaleDateString(), "date")
        if (TODAY <= EXDATE) {
            return <p className="t-15-500 m-0" style={{color: '#8E93AE'}}>Expiration
                Date: {moment(date).format("D MMMM YYYY")}</p>
        } else {
            return <p className="t-15-bold m-0" style={{color: '#F46F6F'}}>EXPIRED</p>
        }
    }

    function myFunction(code) {
        // Get the text field
        navigator.clipboard.writeText(code);
        // Alert the copied text
        // alert("Copied the text: " + copyText.value);
    }


    return (
        <>
            <div>
                <h1 className="d-inline-block mt-4">REFER YOUR FRIENDS</h1>
            </div>
            <div className="position-relative" style={{height: 'calc(100vh - 365px)'}}>
                <div className="position-relative" style={{height: '80%'}}>
                    <p className="t-17-600 mt-3">Refer friends & enjoy a 1-month fee waiver once they make their first
                        booking. Plus, they get 5% off all
                        bookings for a month.</p>
                    <div className="scheduleScroll" style={{
                        height: '100%',
                        overflow: 'auto'
                    }}>
                        <Grid container spacing={2}>
                            {data?.map((ele) => (
                                <Grid item xs={12} md={6}>
                                    <div className="ref-code-box">
                                        <p className="m-0 t-16-bold" style={{color: '#8E93AE'}}>YOUR CODE</p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div style={{display: 'contents'}}>
                                                <p className="t-20-bold m-0 mt-1"
                                                   style={{color: '#363853'}}>{ele?.referral_code}</p>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <IconButton sx={{padding: '0'}} onClick={() => {
                                                    setOpenShare(true)
                                                    setSharedData(ele)
                                                }}>
                                                    <img src={shareIcon} alt=""/>
                                                </IconButton>
                                                <IconButton sx={{padding: '0'}} onClick={() => {
                                                    myFunction(ele?.referral_code)
                                                }}>
                                                    <img src={copyIcon} alt=""/>
                                                </IconButton>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="d-flex justify-content-between flex-wrap">
                                            {ele?.expire_date ? <div>
                                                {checkCodeExpire(ele?.expire_date)}
                                            </div> : null}
                                            {ele?.is_admin_created ?
                                                <p className="t-15-bold m-0"
                                                   style={{marginLeft: 'auto !important'}}>Created by Admin</p> : null}
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>

                </div>


                {
                    showBtn < 1 ?
                        <div
                            className="d-flex flex-wrap align-items-center justify-content-end mt-4 position-absolute g-code-btn"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                bottom: '0',
                                right: '0'
                            }}>
                            <button className="massageBtn mt-3"
                                    onClick={() => setShowBanner(true)}
                                    style={{
                                        maxWidth: "350px",
                                        height: "45px",
                                        fontWeight: '600'
                                    }}>Generate Code
                            </button>
                        </div>
                        : null
                }
            </div>
            <CodeShare open={openShare} setOpen={setOpenShare} data={sharedData}/>
        </>
    )
}

export default RefCodeCards;