import React, { useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import profileImg from "../../assets/images/layout1.jpg";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import deleteIcon from "../../assets/images/deleteAccIcon.svg";
import userIcon from "../../assets/images/userIconWithBg.png";

const MultiCard = ({
  data,
  formatBadge,
  getReviewTime,
  seenNotification,
  handleDeleteNotification,
}) => {
  const [navRout, setNavRout] = useState("/");
  const auth_id = JSON.parse(localStorage.getItem("AdAnima_auth"))?._id;
  const navigate = useNavigate();
  const setNavigateRout = () => {
    if (data?.noti_for == "create_service") {
      //provider Details Screen
    } else if (
      data?.noti_for == "accept_event_invitation" ||
      data?.noti_for == "decline_event_invitation"
    ) {
      if (data?.sender_user?._id !== auth_id) {
        // my event details page
      } else {
        navigate(`/view-events/${data?.event_id}/${data?.sender_user?._id}`);
      }
    } else if ("payment_refund" || "booking_confirm_refund") {
      // history
      navigate("/wallet");
    } else if ("meeting_link_generated") {
      //Service booking info screen
    } else if ("question_chat_message") {
      //pdng
    }
  };

  const handleNavigate = (event, status) => {
    const clickedElement = event.target;
    if (clickedElement.classList.contains("btnn")) {
      // Button with the class "btnn" was clicked, handle API call logic
      return;
    } else {
      seenNotification();
      setNavigateRout();
    }
  };

  return (
    <>
      <div
        style={{ background: data?.is_seen ? "white" : "#E6F7FF" }}
        onClick={(e) => {
          handleNavigate(e);
        }}
      >
        <div className="notification-main-div position-relative d-flex align-items-start justify-content-between p-2">
          <div className="d-flex align-items-center w-100">
            <FiberManualRecordIcon
              style={{
                width: "13px",
                color: data?.is_seen ? "transparent" : "#60CE74",
              }}
            />
            <div
              className="p_image_shape me-2 me-sm-0"
              style={{ transform: "scale(0.6)", marginLeft: "-15px" }}
            >
              <img
                src={data?.sender_user?.profile_picture || userIcon}
                onError={(e) => (e.target.src = userIcon)}
              />
            </div>
            <div className="w-100">
              <p
                className="mb-0 t-13-800 mt-0 twoLineElips"
                style={{ wordBreak: "break-word" }}
              >
                {data?.noti_msg
                  .replace("user_name", data?.sender_user?.name)
                  ?.replace("login_", "")
                  .replace("service_name", data?.service_name ?? "")
                  ?.replace("event_name", data?.event_name ?? "")
                  .replace("provider_name", data?.provider_name ?? "")}
              </p>

              <div className="w-100 d-flex justify-content-between">
                <div>
                  <p className="mb-0 t-12-bold mt-0" style={{ width: "80%" }}>
                    {data?.sender_user?.name}
                  </p>
                  <div className="d-flex align-items-center g-2">
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("MMMM D YYYY")}
                    </p>
                    <FiberManualRecordIcon
                      style={{ transform: "scale(0.4)", color: "#555555" }}
                    />
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("LT")}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="t-10-bold m-0"
                    style={{
                      color: "#2F8A40",
                      textAlign: "end",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {getReviewTime(data?.createdAt)}
                  </p>
                  <p
                    className="t-11-bold mt-1 d-flex align-items-center justify-content-center m-0"
                    style={{
                      borderRadius: "3px",
                      background: "#00ADFF",
                      padding: "2px 4px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formatBadge(
                      data?.noti_for,
                      data?.booking_type == "service" ? false : true
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="position-absolute delete_notification"
            style={{ left: "0", top: "0" }}
          >
            <button className="btnn">
              <Tooltip placement="top" title={"Clear"}>
                <IconButton
                  style={{ padding: "3px 5px" }}
                  className="btnn"
                  onClick={() => {
                    handleDeleteNotification();
                  }}
                >
                  {/*<CloseIcon style={{width: '15px'}}/>*/}
                  <img
                    src={deleteIcon}
                    alt=""
                    className="btnn"
                    style={{ width: "24px" }}
                  />
                </IconButton>
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiCard;
