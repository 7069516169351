import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {IconButton} from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import {Link, useNavigate} from "react-router-dom";
import logo from "../assets/images/ad-anima_logo_without_bg.png";
import bigLogo from "../assets/images/ad-anima_logo.png";
import noImg from "../assets/images/noImg.jpg";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import locationIcon from "../assets/images/mapDiscovery.svg";
import phoneIcon from "../assets/images/call.png";
import emailIcon from "../assets/images/sms.png";
import {useDispatch} from "react-redux";
import {claimProvider} from "../action/claimConstant";
import Claim_ContactUs_modle from "./Claim_ContactUs_modle";
import Claim_ContactUs from "./Claim_ContactUs";

const Claim_Account_popup = ({open, setOpen, userDetails, data}) => {
    const [serviceData, setServiceData] = useState(userDetails?.find_user_service_details[0]);
    const dispatch = useDispatch((state) => state);
    const [isStatus, setIsStatus] = useState(false);
    const navigate = useNavigate();
    const [isContact, setIsContact] = useState(false)
    useEffect(() => {
        setServiceData(userDetails?.find_user_service_details[0])
    }, [userDetails]);

    // console.log(userDetails, 'userDetails')

    const maskEmail = (email) => {
        const atIndex = email?.indexOf('@');
        if (atIndex > 1) {
            const maskedEmail =
                email?.substring(0, 3) + '*********' + email?.substring(atIndex - 1);
            return maskedEmail;
        }
        return email;
    };

    const maskPhoneNumber = (phoneNumber) => {
        // const visibleDigits = 5; // Number of visible digits at the start
        // const maskedLength = phoneNumber?.length - visibleDigits;
        // if (maskedLength <= 0) return phoneNumber;
        //
        // const visibleSection = phoneNumber.slice(-visibleDigits);
        // const maskedSection = '*'.repeat(maskedLength);
        // const maskedNumber =
        //     maskedSection + ' ' + visibleSection.replace(/\d/g, '*');
        // return maskedNumber;
        return phoneNumber;
    };

    const handleSendLink = () => {
        setIsStatus(true)
        const payLoad = {
            claim_account_id: userDetails?._id,
            claim_service_id: serviceData?._id,
            profile_image: userDetails?.profile_picture,
            status: true
        }
        dispatch(claimProvider(payLoad,
            (a) => {
                setOpen(a)
            },
            (a) => {
                setIsStatus(a)
            },
            () => {
                handleNavigate()
            },
            () => {
            }
        ))
    }

    const handleNavigate = () => {
        if (!isStatus) {
            navigate(`/claim-provider/${userDetails?._id}`)
        }
    }


    return (
        <>
            <Modal
                centered
                show={open.claimAccount}
                backdrop="static"
                onHide={() => {
                    setOpen((pre) => ({...pre, claimAccount: false}));
                }}
                dialogClassName="claim_account_modal"
            >
                <div className="Congratulations_msg p-3" style={{maxWidth: '100%'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="m-0">Claim this account</h3>
                        <IconButton
                            sx={{background: '#EEEEEE'}}
                            onClick={() => setOpen((pre) => ({...pre, claimAccount: false}))}
                        >
                            <img src={closeIcon} alt=""/>
                        </IconButton>
                    </div>
                    <div>
                        <div
                            className="w-100 p-2 mt-2 position-relative"
                        >
                            {/*<Link*/}
                            {/*    to={`/provider_details/${data?.user_id?._id}`}*/}
                            {/*>*/}
                            {serviceData ? <div
                                className="border-gred mb-0 p-0"
                                style={{border: "1px solid #D0DEE2"}}
                            >
                                <div
                                    className="inner-gred d-flex align-items-center p-2">
                                    <div className="position-relative">
                                        <div className="p_image_shape me-2 me-sm-3">
                                            <img
                                                src={userDetails?.is_admin_created ? (userDetails?.profile_picture ? userDetails?.profile_picture : bigLogo) : userDetails?.profile_picture}
                                                style={{background: "white"}}
                                                onError={(e) => {
                                                    e.target.src = noImg;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <h4
                                            className="mb-1"
                                            style={{fontSize: "14px", width: "90%"}}
                                        >
                                            {serviceData?.service_name}
                                        </h4>
                                        <p
                                            className="mb-0 home-desc"
                                            style={{
                                                width: '80%',
                                                fontSize: "10px",
                                                wordBreak: "break-word"
                                            }}
                                        >
                                            {serviceData?.description || userDetails?.provider_introduction}
                                        </p>
                                        <div
                                            className="d-flex  align-items-center mt-1">
                                            <div className="d-flex align-items-baseline">
                                                <div className="me-2 me-sm-2">
                                                    <img
                                                        src={locationIcon}
                                                        style={{width: "14px"}}
                                                    />
                                                </div>
                                                <p
                                                    className="map_des m-0 p-0"
                                                    style={{
                                                        fontSize: "10px",
                                                        color: "#FFC107",
                                                    }}
                                                >
                                                    {userDetails?.address}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}

                            <div className="border-gred mb-0 p-0 mt-3"
                                 style={{border: "1px solid #D0DEE2"}}>
                                <div className="inner-gred align-items-center p-3">
                                    <div className="d-flex gap-3 ">
                                        <div className="claimAccount_logo_bg">
                                            <img src={logo} alt=""/>
                                        </div>
                                        <div>
                                            <p className="mb-0 t-15-bold"
                                               style={{color: '#3E555B'}}>{userDetails?.name}</p>
                                            <p className="t-10-bold"
                                               style={{color: '#3E555B'}}>{userDetails?.user_type.charAt(0).toUpperCase() + userDetails?.user_type.slice(1)}</p>
                                        </div>
                                    </div>
                                    {userDetails?.provider_introduction ? <div className='mt-3'>
                                        <p className="t-13-bold mb-1">Description</p>
                                        <p className="t-14-500" style={{color: '#767676', wordBreak: 'break-word'}}>
                                            {userDetails?.provider_introduction}
                                        </p>
                                    </div> : null}
                                    <div className='mt-3 mb-4'>
                                        {userDetails?.mobile_number && <div className='d-flex align-items-end gap-2'>
                                            <img src={phoneIcon} alt=""/>
                                            <p className="mb-0 t-13-bold">*****
                                                ***{maskPhoneNumber(userDetails?.mobile_number?.toString()?.slice(-2))}</p>
                                        </div>}
                                        <div className='d-flex align-items-end gap-2 mt-2'>
                                            <img src={emailIcon} alt=""/>
                                            <p className="mb-0 t-13-bold">{maskEmail(userDetails?.email_address)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">


                                    {isStatus ? (<a
                                        href="#"
                                        onClick={(e) => e.preventDefault()}
                                        className="btn_admina"
                                    >
                                        <div className="loader"></div>
                                    </a>) : (<button

                                        className="btn_admina"
                                        onClick={() => {
                                            handleSendLink()
                                        }}

                                    >
                                        Send Link
                                    </button>)}
                                </div>
                                <p className="claim-popup-link" onClick={() => {
                                    setOpen((pre) => ({...pre, claimAccount: false}));
                                    setIsContact(true)
                                }}>I don’t recognize this email</p>
                            </div>
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </Modal>
            <Claim_ContactUs open={isContact} setOpen={setIsContact} providerName={userDetails?.name}
                             id={userDetails?._id}/>
        </>
    )
}

export default Claim_Account_popup;