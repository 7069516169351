import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment/moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../../../assets/images/Discovery.svg";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Modal } from "react-bootstrap";
import {
  getBookingDetails,
  setBookingStatus,
} from "../../../action/myBokingConstant";
import { useDispatch, useSelector } from "react-redux";
import { capital } from "../../../Utils/capitalUtils";
import { Link } from "react-router-dom";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../../../Utils/DateFormateUtils";
import userIcon from "../../../assets/images/userIconWithBg.png";
import {useNavigate} from "react-router-dom";

// const paramsDetails = ({details}) => {
//     console.log(details, "details")
//
//     ///Refund Logic
//     if (details?.modify_booking_payment_info?.is_refund != null && details?.modify_booking_payment_info?.is_refund !== true) {
//         console.log('inside 1', "conditions")
//         const payload = {
//             total_amount: details?.modify_booking_payment_info?.new_amount,
//             total_paid_amount: details?.modify_booking_payment_info?.new_total_paid_amount,
//             fees_percentage_from_user: details?.modify_booking_payment_info?.fees_percentage_from_user,
//             fees_amount_from_user: details?.modify_booking_payment_info?.fees_amount_from_user,
//             sales_tax_percentage: details?.modify_booking_payment_info?.sales_tax_percentage,
//             sales_tax_amount_from_user: details?.modify_booking_payment_info?.sales_tax_amount_from_user,
//             stripe_payment_intent_id: details?.payment_history_id?.stripe_payment_intent_id,
//             stripe_charge_id: details?.payment_history_id?.stripe_charge_id,
//             total_transfered_amount: details?.modify_booking_payment_info?.sales_tax_amount_from_user,
//             sales_tax_id: details?.modify_booking_payment_info?.sales_tax_id,
//             application_fees: details?.modify_booking_payment_info?.application_fees,
//             fees_percentage_from_provider: details?.modify_booking_payment_info?.fees_percentage_from_provider,
//             fees_amount_from_provider: details?.modify_booking_payment_info?.fees_amount_from_provider,
//         }
//         if (details?.payment_history_id?.stripe_transfer_id !== null && details?.payment_history_id?.stripe_transfer_id?.isEmpty() != true) {
//             console.log('inside 2', "conditions")
//
//             const stripeTransferIdKey = "stripe_transfer_id"
//             payload[stripeTransferIdKey] = details?.payment_history_id?.stripe_transfer_id
//         }
//         return payload
//
//     }
//
//     ///No Refund && Modify case handling
//     if (details?.modify_booking_payment_info?.new_amount == 0) {
//         console.log('inside 3', "conditions")
//
//         const payload = {
//             is_payment_changes: false
//         }
//         return payload
//     }
// }

const BookingInfo = ({
  open,
  setOpen,
  miles,
  selectId,
  setSelectId,
  formatBadge,
  data,
}) => {
  const dispatch = useDispatch();
  const details = useSelector(
    (state) => state?.myBookingDetails?.bookingDetails
  );
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  // console.log(details, "details")
  const [center, setCenter] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if ("geolocation" in navigator) {
      //check geolocation available
      //try to get user current location using getCurrentPosition() method
      navigator?.geolocation?.getCurrentPosition(async (position) => {
        // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
        await setCenter({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    } else {
      console.log("Browser doesn't support geolocation!");
    }
  }, []);
  const gateData = () => {
    const payload = {
      id: selectId,
      latLong: center,
    };
    dispatch(getBookingDetails(payload));
  };

  useEffect(() => {
    if (selectId) {
      gateData();
    }
  }, [selectId]);

  // console.log(paramsDetails(details), "paramsDetails(details)")

  const HandleAcssept = (status, id) => {
    let payload = {
      status: status,
      id: id,
    };

    if (data?.noti_for == "modified_booking") {
      const isModifiedKey = "is_modified";
      payload[isModifiedKey] = true;
    }

    // if (status == "accept") {
    if (
      details?.payment_history_id?.stripe_transfer_id !== null &&
      details?.payment_history_id?.stripe_transfer_id !== ""
    ) {
      const stripeTransferIdKey = "stripe_transfer_id";
      payload[stripeTransferIdKey] =
        details?.payment_history_id?.stripe_transfer_id;
      // payload.refund_booking_payment_info[stripeTransferIdKey] = details?.payment_history_id?.stripe_transfer_id;
    }

    ///No Refund && Modify case handling
    if (details?.modify_booking_payment_info?.new_amount == 0) {
      // console.log("helooooooooooooo")
      const isPaymentChangesKey = "is_payment_changes";
      payload = { ...payload, is_payment_changes: false };
    }

    if (
      details?.modify_booking_payment_info?.is_refund != null &&
      details?.modify_booking_payment_info?.is_refund == true
    ) {
      const refundBookingPaymentInfoKey = "refund_booking_payment_info";
      const refundPayload = {
        total_amount: details?.modify_booking_payment_info?.new_amount,
        total_paid_amount:
          details?.modify_booking_payment_info?.new_total_paid_amount,
        fees_percentage_from_user:
          details?.modify_booking_payment_info?.fees_percentage_from_user,
        fees_amount_from_user:
          details?.modify_booking_payment_info?.fees_amount_from_user,
        sales_tax_percentage:
          details?.modify_booking_payment_info?.sales_tax_percentage,
        sales_tax_amount_from_user:
          details?.modify_booking_payment_info?.sales_tax_amount_from_user,
        stripe_payment_intent_id:
          details?.payment_history_id?.stripe_payment_intent_id,
        stripe_charge_id: details?.payment_history_id?.stripe_charge_id,
        total_transfered_amount:
          details?.modify_booking_payment_info?.total_transfered_amount,
        sales_tax_id: details?.modify_booking_payment_info?.sales_tax_id,
        application_fees:
          details?.modify_booking_payment_info?.application_fees,
        fees_percentage_from_provider:
          details?.modify_booking_payment_info?.fees_percentage_from_provider,
        fees_amount_from_provider:
          details?.modify_booking_payment_info?.fees_amount_from_provider,
        stripe_transfer_id: details?.payment_history_id?.stripe_transfer_id,
      };
      payload[refundBookingPaymentInfoKey] = refundPayload;
    }
    // if (details?.payment_history_id?.stripe_transfer_id !== null && details?.payment_history_id?.stripe_transfer_id?.isEmpty() != true) {
    //     const stripeTransferIdKey = "stripe_transfer_id"
    //     payload[stripeTransferIdKey] = details?.payment_history_id?.stripe_transfer_id
    // }

    // }

    dispatch(setBookingStatus(payload));
    if (selectId) {
      gateData();
      setSelectId("");
    }
    // setTimeout(() => {
    //     getEvent()
    // }, 1000);
  };

  const HandleDecline = (status, id) => {
    var payload = {
      status: status,
      id: id,
    };

    if (data?.noti_for == "modified_booking") {
      const isModifiedKey = "is_modified";
      payload[isModifiedKey] = true;
    }

    if (status == "accept") {
      if (
        details?.payment_history_id?.stripe_transfer_id !== null &&
        details?.payment_history_id?.stripe_transfer_id !== ""
      ) {
        const stripeTransferIdKey = "stripe_transfer_id";
        payload[stripeTransferIdKey] =
          details?.payment_history_id?.stripe_transfer_id;
        // payload.refund_booking_payment_info[stripeTransferIdKey] = details?.payment_history_id?.stripe_transfer_id;
      }

      ///No Refund && Modify case handling
      if (details?.modify_booking_payment_info?.new_amount == 0) {
        const isPaymentChangesKey = "is_payment_changes";
        payload[isPaymentChangesKey] = false;
      }

      if (
        details?.modify_booking_payment_info?.is_refund != null &&
        details?.modify_booking_payment_info?.is_refund == true
      ) {
        const refundBookingPaymentInfoKey = "refund_booking_payment_info";
        const refundPayload = {
          total_amount: details?.modify_booking_payment_info?.new_amount,
          total_paid_amount:
            details?.modify_booking_payment_info?.new_total_paid_amount,
          fees_percentage_from_user:
            details?.modify_booking_payment_info?.fees_percentage_from_user,
          fees_amount_from_user:
            details?.modify_booking_payment_info?.fees_amount_from_user,
          sales_tax_percentage:
            details?.modify_booking_payment_info?.sales_tax_percentage,
          sales_tax_amount_from_user:
            details?.modify_booking_payment_info?.sales_tax_amount_from_user,
          stripe_payment_intent_id:
            details?.payment_history_id?.stripe_payment_intent_id,
          stripe_charge_id: details?.payment_history_id?.stripe_charge_id,
          total_transfered_amount:
            details?.modify_booking_payment_info?.total_transfered_amount,
          sales_tax_id: details?.modify_booking_payment_info?.sales_tax_id,
          application_fees:
            details?.modify_booking_payment_info?.application_fees,
          fees_percentage_from_provider:
            details?.modify_booking_payment_info?.fees_percentage_from_provider,
          fees_amount_from_provider:
            details?.modify_booking_payment_info?.fees_amount_from_provider,
          stripe_transfer_id: details?.payment_history_id?.stripe_transfer_id,
        };
        payload[refundBookingPaymentInfoKey] = refundPayload;
      }
      // if (details?.payment_history_id?.stripe_transfer_id !== null && details?.payment_history_id?.stripe_transfer_id?.isEmpty() != true) {
      //     const stripeTransferIdKey = "stripe_transfer_id"
      //     payload[stripeTransferIdKey] = details?.payment_history_id?.stripe_transfer_id
      // }
    }

    dispatch(setBookingStatus(payload));
    if (selectId) {
      gateData();
      setSelectId("");
    }
    // setTimeout(() => {
    //     getEvent()
    // }, 1000);
  };

  // console.log(details, "modifyetails")
  return (
    <>
      <Modal
        centered
        backdrop="static"
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        dialogClassName="delete-modal payment-details-modal-details"
        style={{ zIndex: "100000", background: "#00000096" }}
      >
        <div className="Congratulations_msg payment-details-modal-mx">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0 t-24-bold">Booking Details</p>
              <IconButton
                sx={{
                  background:
                    "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)",
                }}
                onClick={() => {
                  setSelectId("");
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              className="inner-gred box_shadow mt-4"
              style={{
                background:
                  details.booking_type == "event" && "rgba(255, 165, 0, 0.10)",
              }}
            >
              <div
                className="d-flex"
                onClick={() => {
                  navigate(
                    details?.event_id
                      ? `/view-events/${details?.event_id?._id}/${details?.provider_id?._id}`
                      : `/provider_details/${details?.provider_id?._id}`
                  );
                }}
              >
                <div className="p_image_shape me-2 me-sm-3">
                  <img
                    src={
                      details?.event_id?.event_media?.length
                        ? details?.event_id?.event_media[0]?.file_name
                        : details?.provider_id?.provider_banner_images[0]
                            ?.file_name
                    }
                  />
                </div>
                <div>
                  <p className="t-15-bold mb-1" style={{ color: "black" }}>
                    {details?.service_id?.service_name ||
                      details?.event_id?.event_name}
                  </p>
                  {details.booking_type !== "event" && (
                    <div className="d-flex gap-2">
                      <div
                        className="p_image_shape"
                        style={{
                          width: "32px",
                          height: "35px",
                          minWidth: "32px",
                        }}
                      >
                        <img
                          src={
                            details?.provider_id?.profile_picture || userIcon
                          }
                          style={{
                            right: "0px",
                            bottom: "0px",
                            height: "30px",
                            width: "32px",
                          }}
                          onError={(e) => (e.target.src = userIcon)}
                        />
                      </div>
                      <p className="t-14-bold">{details?.provider_id?.name}</p>
                    </div>
                  )}
                  {details.booking_type == "event" && (
                    //     <div className="d-flex align-items-center">
                    //     <p className="m-0 t-12-bold">{moment(details?.event_id?.start_date).format("D MMM YYYY")}</p>
                    //     <FiberManualRecordIcon style={{transform: "scale(0.3)"}}/>
                    //     <p className="m-0 t-12-bold">{moment(details?.event_id?.start_time).format("LT")} to {moment(details?.event_id?.start_date).format("LT")}</p>
                    // </div>

                    <div className="d-flex flex-sm-row flex-column flex-md-nowrap flex-wrap align-items-start align-items-sm-center mt-1">
                      {/*<p className="m-0 t-15-500">{(moment(event?.event?.start_date).format('MMM Do') == moment(event?.event?.end_date).format('MMM Do')) ? (`${moment(event?.event?.start_date).format('MMM Do')} - ${moment(event?.event?.end_date).format('MMM Do, YYYY')}`) : {moment(event?.event?.start_date).format('MMMM Do YYYY')}}</p>*/}
                      {/*<p className="m-0 t-15-500">{moment(LocalFormate(event?.event?.start_date)).format('MMM Do') !== moment(LocalFormate(event?.event?.end_date)).format('MMM Do') ? (`${moment(LocalFormate(event?.event?.start_date)).format('MMM Do')} - ${moment(LocalFormate(event?.event?.end_date)).format('MMM Do, YYYY')}`) : (`${moment(LocalFormate(event?.event?.start_date)).format('MMMM Do YYYY')}`)}</p>*/}
                      <p
                        className="m-0 t-13-bold"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {DateFormate(
                          LocalFormate(
                            details?.event_id?.start_date,
                            details?.event_id?.date_timezone
                          ),
                          LocalFormate(
                            details?.event_id?.end_date,
                            details?.event_id?.date_timezone
                          )
                        )}
                      </p>
                      <FiberManualRecordIcon
                        className="d-none d-sm-block"
                        style={{ transform: "scale(0.4)" }}
                      />
                      <p
                        className="m-0 t-13-bold"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {moment(
                          LocalFormate(
                            details?.event_id?.start_time,
                            details?.event_id?.date_timezone
                          )
                        ).format("LT")}{" "}
                        to{" "}
                        {moment(
                          LocalFormate(
                            details?.event_id?.end_time,
                            details?.event_id?.date_timezone
                          )
                        ).format("LT")}{" "}
                        (
                        {timeZoneFormate(
                          details?.event_id?.start_time,
                          details?.event_id?.date_timezone
                        )}
                        )
                      </p>
                    </div>
                  )}
                  <div className="d-flex gap-1 mt-1">
                    <img src={locationIcon} alt="" />
                    <p className="yelloe-t-bold m-0">
                      {miles || details?.miles_distance || 0} Mile Away
                    </p>
                  </div>
                  {details.booking_type !== "event" && (
                    <div
                      className="d-flex gap-1"
                      style={{ alignItems: "baseline" }}
                    >
                      <div style={{ color: "#FFC107" }}>
                        {details?.provider_id?.average_rating >= 0.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 1.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 2.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 3.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 4.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                      </div>
                      <p
                        style={{ fontSize: "14px", fontWeight: "600" }}
                        className="m-0"
                      >
                        ({Math.round(details?.provider_id?.average_rating)} / 5)
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="inner-gred collabrate-event-list box_shadow mt-2"
              style={{ maxHeight: "580px" }}
            >
              {details.booking_type !== "event" ? (
                <>
                  <p className="t-18-bold">Booking Schedule</p>
                  {userAuth?._id !== details?.user_id?._id ? (
                    <div className="border-gred">
                      <div className="inner-gred" style={{ padding: "0px" }}>
                        <div className="d-flex align-items-center">
                          <div
                            className="p_image_shape me-2 me-sm-0"
                            style={{ transform: "scale(0.7)" }}
                          >
                            <img
                              src={
                                details?.user_id?.profile_picture || userIcon
                              }
                              onError={(e) => (e.target.src = userIcon)}
                            />
                          </div>
                          <div>
                            <p className="m-0 t-16-bold">
                              {details?.user_id?.name}
                            </p>
                            <p className="m-0 t-10-bold">
                              {capital(details?.user_id?.user_type)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="border-gred">
                    <div className="inner-gred">
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold m-1">Date</p>*/}
                      {/*    <p className="t-12-bold m-1">{moment(details?.booking_date).format("D MMM YYYY")}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold m-1">Time</p>*/}
                      {/*    <p className="t-12-bold m-1">{moment(details?.start_date_time).format('LT')} to {moment(details.end_date_time).format('LT')}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold m-1">Type</p>*/}
                      {/*    <p className="t-12-bold m-1">{details?.service_type}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold mb-1">Location </p>*/}
                      {/*    <p className="t-12-bold mb-1">{details?.location_type}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-end">*/}
                      {/*    <p className="t-10-bold mt-1">{details?.service_id?.address}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex align-items-center">*/}
                      {/*    <p className="t-12-bold mb-1">{moment(details?.booking_date).format("D MMM YYYY")} , </p>*/}
                      {/*    <p className="t-12-bold mb-1">{moment(details?.start_date_time).format('LT')} to {moment(details.end_date_time).format('LT')}</p>*/}
                      {/*</div>*/}
                      <div className="d-flex align-items-center">
                        {/*<p className="t-12-bold mb-1">{DateFormate(LocalFormate(details?.start_date_time), LocalFormate(details.end_date_time))}, </p>*/}
                        <p className="t-12-bold mb-1">
                          {moment(
                            LocalFormate(
                              details?.booking_date ||
                                details?.event_id?.start_date,
                              details?.service_id?.date_timezone ||
                                details?.event_id?.date_timezone
                            )
                          ).format("MMM D YYYY")}
                          ,{" "}
                        </p>
                        <p className="t-12-bold mb-1">
                          {" "}
                          {moment(
                            LocalFormate(
                              details?.start_date_time,
                              details?.service_id?.date_timezone ||
                                details?.event_id?.date_timezone
                            )
                          ).format("LT")}{" "}
                          to{" "}
                          {moment(
                            LocalFormate(
                              details.end_date_time,
                              details?.service_id?.date_timezone ||
                                details?.event_id?.date_timezone
                            )
                          ).format("LT")}{" "}
                          (
                          {timeZoneFormate(
                            details?.start_date_time,
                            details?.date_timezone ||
                              details?.service_id?.date_timezone ||
                              details?.event_id?.date_timezone
                          )}
                          )
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="t-12-bold mb-1">Type : </p>
                        <p className="t-12-bold mb-1 ms-1">
                          {capital(details?.service_type)}
                        </p>
                        <p></p>
                      </div>
                      {details?.service_type == "in_person" && (
                        <>
                          <div className="d-flex align-items-start">
                            <p
                              className="t-12-bold mb-1"
                              style={{ textWrap: "nowrap" }}
                            >
                              {details.location_type == "their_place"
                                ? "Their Place"
                                : "My Place"}{" "}
                              :{" "}
                            </p>
                            <p className="t-12-bold mb-1 ms-1">
                              {details?.address}
                            </p>
                          </div>
                        </>
                      )}
                      {details?.description && (
                        <div className="d-flex align-items-start">
                          <p
                            className="t-12-bold mb-1"
                            style={{ textWrap: "nowrap" }}
                          >
                            Location Note :{" "}
                          </p>
                          <p className="t-12-bold mb-1 ms-1">
                            {details?.description}
                          </p>
                          <p></p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : userAuth?._id !== details?.user_id?._id ? (
                <>
                  <p className="t-18-bold">
                    {details?.booking_type == "hire_provider"
                      ? "Hire Details"
                      : "Booking Schedule"}
                  </p>
                  <div className="border-gred">
                    <Link to={`/provider_details/${details?.user_id?._id}`}>
                      <div className="inner-gred" style={{ padding: "0px" }}>
                        <div className="d-flex align-items-center">
                          <div
                            className="p_image_shape me-2 me-sm-0"
                            style={{ transform: "scale(0.7)" }}
                          >
                            <img
                              src={
                                details?.user_id?.profile_picture || userIcon
                              }
                              onError={(e) => (e.target.src = userIcon)}
                            />
                          </div>
                          <div>
                            <p className="m-0 t-16-bold">
                              {details?.user_id?.name}
                            </p>
                            <p className="m-0 t-10-bold">
                              {capital(details?.user_id?.user_type)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ) : null}

              {/* =========== PAYMENT DETAILS ===============*/}
              <div>
                <p className="t-18-bold">Payment Details</p>

                {details?.payment_history_id?.payment_status == "success" ? (
                  <div className="border-gred">
                    <div className="inner-gred">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1">Transaction ID</p>
                        <p className="t-12-bold m-1">
                          {details?.payment_history_id?.transaction_id || "---"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1">Payment Time</p>
                        <p className="t-12-bold m-1">
                          {DateFormate(
                            LocalFormate(
                              details?.payment_history_id?.createdAt,
                              details?.payment_history_id?.date_timezone
                            ),
                            null,
                            true
                          ) ?? "---"}{" "}
                          (
                          {timeZoneFormate(
                            details?.payment_history_id?.createdAt,
                            details?.payment_history_id?.date_timezone ||
                              details?.date_timezone
                          )}
                          )
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1">Status</p>
                        <p className="t-12-bold m-1" style={{ color: "green" }}>
                          {capital(
                            details?.payment_history_id?.payment_status
                          ) || "---"}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="border-gred">
                  <div className="inner-gred">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="t-12-bold m-1">Participants</p>
                      <p className="t-12-bold m-1">
                        {details.no_of_participants || 0}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="t-12-bold m-1">Package amount</p>
                      <p className="t-12-bold m-1">
                        ${" "}
                        {details?.payment_history_id?.total_amount?.toFixed(
                          2
                        ) ||
                          Number(
                            details?.booking_payment_info?.total_amount
                          )?.toFixed(2) ||
                          0.0}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="t-12-bold m-1">
                        Network fees (
                        {details?.payment_history_id
                          ?.fees_percentage_from_user ||
                          details?.booking_payment_info
                            ?.fees_percentage_from_user ||
                          0}
                        %)
                      </p>
                      <p className="t-12-bold m-1">
                        ${" "}
                        {details?.payment_history_id?.fees_amount_from_user?.toFixed(
                          2
                        ) ||
                          Number(
                            details?.booking_payment_info?.fees_amount_from_user
                          )?.toFixed(2) ||
                          0.0}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="t-12-bold m-1">
                        Tax (
                        {details?.payment_history_id?.sales_tax_percentage ||
                          details?.booking_payment_info?.sales_tax_percentage ||
                          0}
                        %)
                      </p>
                      <p className="t-12-bold m-1">
                        ${" "}
                        {details?.payment_history_id?.sales_tax_amount_from_user?.toFixed(
                          2
                        ) ||
                          Number(
                            details?.booking_payment_info
                              ?.sales_tax_amount_from_user
                          )?.toFixed(2) ||
                          0.0}
                      </p>
                    </div>
                    <hr style={{ border: "1px dashed black" }} />
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="t-12-bold m-1">Total Amount</p>
                      <p className="t-12-bold m-1">
                        ${" "}
                        {details?.payment_history_id?.total_paid_amount?.toFixed(
                          2
                        ) ||
                          Number(
                            details?.booking_payment_info?.total_paid_amount
                          )?.toFixed(2) ||
                          0.0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* =========== MODIFY DETAILS ===============*/}
              {(details?.modify_booking_payment_info &&
                details?.modified_count) > 0 ? (
                <div>
                  <p className="t-18-bold">
                    {details?.modify_booking_payment_info?.is_refund
                      ? "Refund Payment Details"
                      : "Modify Payment Details"}
                  </p>
                  <div className="border-gred">
                    <div className="inner-gred">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1">Participants</p>
                        <p className="t-12-bold m-1">
                          {Number(
                            Math.abs(
                              details?.modify_booking_payment_info
                                ?.new_participate
                            )
                          ) || 0}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1">Package amount</p>
                        <p className="t-12-bold m-1">
                          ${" "}
                          {Number(
                            details?.modify_booking_payment_info
                              ?.total_transfered_amount
                          )?.toFixed(2) || 0.0}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1">
                          Network fees (
                          {details?.modify_booking_payment_info
                            ?.fees_percentage_from_user || 0}
                          %)
                        </p>
                        <p className="t-12-bold m-1">
                          ${" "}
                          {Number(
                            details?.modify_booking_payment_info
                              ?.fees_amount_from_user
                          )?.toFixed(2) || 0.0}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1">
                          Tax (
                          {details?.modify_booking_payment_info
                            ?.sales_tax_percentage || 0}
                          %)
                        </p>
                        <p className="t-12-bold m-1">
                          ${" "}
                          {Number(
                            details?.modify_booking_payment_info
                              ?.sales_tax_amount_from_user
                          )?.toFixed(2) || 0.0}
                        </p>
                      </div>
                      <hr style={{ border: "1px dashed black" }} />
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1">Total Amount</p>
                        <p className="t-12-bold m-1">
                          ${" "}
                          {Number(
                            details?.modify_booking_payment_info
                              ?.new_total_paid_amount
                          )?.toFixed(2) || 0.0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {
              // ((userAuth?.user_type !== "user" && userAuth?._id !== details?.provider_id?._id) && (details?.booking_status == "accept")) ?
              //     <div className="w-100 d-flex">
              //         <div className="col-6">
              //             <button type="submit" className="btn_admina delete-button"
              //                     onClick={() => setIsOpen(true)} style={{lineHeight: "23px"}}>
              //                 {details.booking_type == "event" ? "Cancel event" : "Cancel Service"}
              //             </button>
              //         </div>
              //         <div className="col-6">
              //             <button
              //                 type="submit"
              //                 className="btn_admina delete-confirm-button"
              //                 style={{lineHeight: "23px"}}
              //             >
              //                 Modify
              //             </button>
              //         </div>
              //     </div> : (userAuth?.user_type !== "user" && (userAuth?._id == details?.provider_id?._id && details?.service_type !== "in_person" && details?.booking_status == "accept")) ?
              //
              //         <div className="w-100 d-flex">
              //             <div className="col-6">
              //                 <button
              //                     type="submit"
              //                     className="btn_admina delete-confirm-button"
              //                     style={{lineHeight: "23px"}}
              //                 >
              //                     Generate link
              //                 </button>
              //             </div>
              //         </div> : (userAuth?.user_type !== "user" && (details?.booking_status == "pending" )) && <div className="w-100 d-flex">
              //         <div className="col-6">
              //             <button type="submit" className="btn_admina delete-button"
              //                     style={{lineHeight: "23px"}} onClick={() => {
              //                 HandleAcssept("accept", details._id)
              //                 setOpen(false);
              //             }}>
              //                 Accept
              //             </button>
              //         </div>
              //         <div className="col-6">
              //             <button
              //                 type="submit"
              //                 className="btn_admina delete-confirm-button"
              //                 style={{lineHeight: "23px"}}
              //                 onClick={() => {
              //                     HandleAcssept("decline", details._id)
              //                     setOpen(false);
              //                 }}
              //             >
              //                 Decline
              //             </button>
              //         </div>
              //     </div>
              // userAuth?.user_type !== "user" &&  details.booking_status !== "pending" && (details.user_id == JSON.parse(localStorage.getItem("AdAnima_auth"))?._id && <div className="w-100 d-flex">
              //         <div className="col-6">
              //             <button type="submit" className="btn_admina delete-button"
              //                     style={{lineHeight: "23px"}} onClick={() => {
              //                 HandleAcssept("accept", details._id)
              //                 setOpen(false);
              //             }}>
              //                 Accept
              //             </button>
              //         </div>
              //         <div className="col-6">
              //             <button
              //                 type="submit"
              //                 className="btn_admina delete-confirm-button"
              //                 style={{lineHeight: "23px"}}
              //                 onClick={() => {
              //                     HandleAcssept("decline", details._id)
              //                     setOpen(false);
              //                 }}
              //             >
              //                 Decline
              //             </button>
              //         </div>
              //     </div>)
            }

            {
              (formatBadge(data?.noti_for, false) == "Service Booking" ||
                formatBadge(data?.noti_for, false) == "Modify Booking") &&
                (details.booking_status == "pending" ||
                  details.booking_status == "modify_pending") && (
                  <div className="w-100 d-flex mt-3">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn_admina delete-button"
                        style={{ lineHeight: "36px" }}
                        onClick={() => {
                          HandleAcssept("accept", details._id);
                          setOpen(false);
                        }}
                      >
                        Accept
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn_admina delete-confirm-button"
                        style={{ lineHeight: "36px" }}
                        onClick={() => {
                          HandleDecline("decline", details._id);
                          setOpen(false);
                        }}
                      >
                        Decline
                      </button>
                    </div>
                  </div>
                )
              // :
              //     ((details?.booking_status == "accept") && <div className="w-100 d-flex">
              //     <div className="col-6">
              //         <button type="submit" className="btn_admina delete-button"
              //                 onClick={() => {
              //                     // setOpen(false);
              //                     // setIsOpen(true)
              //                 }} style={{lineHeight: "23px"}}>
              //             {details.booking_type == "event" ? "Cancel event" : "Cancel Service"}
              //         </button>
              //     </div>
              //     <div className="col-6">
              //         <button
              //             type="submit"
              //             className="btn_admina delete-confirm-button"
              //             style={{lineHeight: "26px"}}
              //         >
              //             Modify
              //         </button>
              //     </div>
              // </div>)
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BookingInfo;
