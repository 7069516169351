import logo from "./logo.svg";
import "./App.css";
import Login from "./Pages/Login";
import UserProfile from "./Pages/UserProfile";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/bootstrap.css";
import ForgetPassword from "./Pages/ForgetPassword";
import { useSelector } from "react-redux";
import Home from "./Pages/Home";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndCondition from "./Pages/TermsAndCondition";
import ProviderDetailsPage from "./Pages/ProviderDetailsPage";

import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import MyPages from "./Pages/MyPages";
import Booking from "./Pages/Booking";
import SessionBooking from "./Component/SessionBooking";
import UpcomingEvent from "./Pages/UpcomingEvent";
import Schedule from "./Pages/Schedule";
import ViewEvent from "./Pages/ViewEvent";
import Events from "./Pages/Events";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import EventBooking from "./Component/EventBooking";
import EventInfo from "./Component/EventInfo/EventInfo";
import CreateEvent from "./Component/CreateEvent/CreateEvent";
import { useEffect, useState } from "react";
import ComingSoon from "./Component/ComingSoon";
import MySchedule from "./Pages/MySchedule";
import Save from "./Pages/Save";
import Claim_profile from "./Pages/Claim_profile";
import Wallet from "./Pages/Wallet";
import ModifySessionBooking from "./Component/ModifySessionBooking";
import ModifyEventBooking from "./Component/ModifyEventBooking";
import VirtualEventIframe from "./Component/VirtualEventIframe";
import PreviewEvent from "./Component/PreviewEvent";
import Code_Discount from "./Pages/RefDiscount/Code_Discount";
import moment from "moment-timezone";
import "swiper/css";
import "swiper/css/navigation";

import { ENVIROMENT, GOOGLE_CLIENT_ID, GOOGLE_API_KEY } from "./Configration/configration";
import { gapi } from "gapi-script";
import BankVerification from "./Pages/BankVerification";
// import { loadAuth2, loadAuth2WithProps, loadClientAuth2 } from "gapi-script";

function App() {
  const authDetails = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const eventData = JSON.parse(localStorage.getItem("eventData"));
  // for get virtual event code and redirect to virtual event
  const getCodeUrl = () => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    // const dublicateCode = "?code=4/0AfJohXmbSojow4mt5kCYEgdzreEQBX7L-JULyWzCfAnuj7eKcdWURkGb-xUJc9JYEx59Nw&scope=https://www.googleapis.com/auth/calendar.events"
    const dublicateCode = decodeURIComponent(code);
    if (code) {
      localStorage.setItem("EVENT_CODE", code);
      navigate(`${eventData?.url}?code=${dublicateCode}`);
      // Do something with the code
    }
  };

  const CLIENT_ID = GOOGLE_CLIENT_ID;
  const API_KEY = GOOGLE_API_KEY;
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  const SCOPES = "https://www.googleapis.com/auth/calendar"; 


  useEffect(() => {
    getCodeUrl();
  }, [location.search]);


  useEffect(() => {
    //         const date = new Date();
    //         console.log(date, "new date")
    //         console.log(date?.toUTCString(), "toUTCString")
    //         console.log(date?.getUTCDate(), "getUTCDate")
    //         console.log(date?.toLocaleString(), "toLocaleString")
    //         console.log(date?.toISOString(), "toISOString")
    //         var isoTime = new Date(date).toISOString()
    //         var americanTime = new Date(isoTime).toLocaleString(undefined, {timeZone: 'America/Los_Angeles'});
    //         var setAmericanTime = new Date(americanTime)
    //         var timeZoneOffset = setAmericanTime.getTimezoneOffset();
    //         var getTimeZoneShortName = moment.tz.zone("America/Los_Angeles").abbr(timeZoneOffset);
    // // 360
    //
    //         console.log(iso, "timezone-iso")
    //         console.log(s, "timezone-s")
    //         console.log(s2, "timezone-s2")
    //         console.log(s3, "timezone-s3")

    // const dateString = "Tue Mar 19 2024 14:23:19 GMT+0530 (India Standard Time)";
    // const date = new Date(dateString);

    // Get the time zone offset in minutes
    //         const timeZoneOffset = date.getTimezoneOffset();

    // Convert the offset to hours and minutes
    //         const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60));
    //         const timeZoneOffsetMinutes = Math.abs(timeZoneOffset % 60);
    //
    // // Determine the sign of the offset (either + or -)
    //         const timeZoneSign = timeZoneOffset < 0 ? '+' : '-';
    //
    // // Construct the time zone string
    //         const timeZoneName = `GMT${timeZoneSign}${String(timeZoneOffsetHours).padStart(2, '0')}:${String(timeZoneOffsetMinutes).padStart(2, '0')}`;
    //
    //         console.log(timeZoneName, "timeZoneName");

    // var date = new Date()
    // var isoTime = date.toISOString()
    // var americanTime = new Date(isoTime).toLocaleString(undefined, {timeZone: 'America/Los_Angeles'});
    // console.log(isoTime)
    // console.log(new Date(americanTime), "timezone")

    const utcDate = "2024-03-20T06:11:23.637Z"; // UTC date
    const convertedDate = moment.utc(utcDate).tz("Asia/Kolkata"); // TIMEZONE
    const formattedDate = convertedDate.format("YYYY-MM-DDTHH:mm:ss"); // DATE FORMATE
    const istAbbreviation = convertedDate.format("z");
    // console.log(formattedDate, "istAbbreviation")

    var timezone = moment.tz.guess();

    // console.log(timezone, "timezone")
  }, []);

  return (
    <>
      {authDetails !== null && authDetails?.token ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/my_page" element={<MyPages />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/bank_verification" element={<BankVerification />} />
          <Route
            path="/book-session/:providerId"
            element={<SessionBooking />}
          />
          <Route
            path="/modify-book-session/:providerId/:bookingId"
            element={<ModifySessionBooking />}
          />
          <Route
            path="/book-session/:providerId/:serviceId"
            element={<SessionBooking />}
          />{" "}
          {/* ----- for share ------ */}
          <Route
            path="/upcoming-events/:providerId"
            element={<UpcomingEvent />}
          />
          <Route path="/view-events/:id/:providerId" element={<ViewEvent />} />
          <Route
            path="/view-events/:id/:providerId/:bookingId"
            element={<ViewEvent />}
          />
          <Route
            path="/view-events/:id/:providerId/:bookingId/:reviewOpen/:userId"
            element={<ViewEvent />}
          />
          <Route path="/preview-events/:id" element={<PreviewEvent />} />
          <Route
            path="/event-booking/:id/:packageId/:participants/:providerId"
            element={<EventBooking />}
          />{" "}
          {/*.....view booking....*/}
          <Route
            path="/event-modify/:id/:packageId/:participants/:providerId/:bookingId"
            element={<ModifyEventBooking />}
          />{" "}
          {/*.....Modify booking....*/}
          <Route path="/my_schedule" element={<Schedule />} />
          <Route path="/my_events" element={<Events />} />
          <Route path="/my_events/:id" element={<EventInfo />} />
          <Route path="/edit-events/:id" element={<CreateEvent />} />{" "}
          {/* ..... for select edit event ....*/}
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_and_condition" element={<TermsAndCondition />} />
          <Route
            path="/provider_details/:providerId"
            element={<ProviderDetailsPage />}
          />
          <Route
            path="/provider_details/:providerId/:serviceId"
            element={<ProviderDetailsPage />}
          />{" "}
          <Route
            path="/provider_details/:providerId/:serviceId/:reviewOpen/:userId"
            element={<ProviderDetailsPage />}
          />{" "}
          {/* ..... for select service ....*/}
          <Route path="/my-schedule/cominsoon" element={<MySchedule />} />
          <Route path="/save" element={<Save />} />
          <Route path="/create-event" element={<CreateEvent />} />{" "}
          {/* ..... for select create event ....*/}
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/comingSoon" element={<ComingSoon />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/claim-provider/:id" element={<Claim_profile />} />{" "}
          {/*.....Claim profile....*/}
          <Route
            path="/VirtualEventIframe"
            element={<VirtualEventIframe />}
          />{" "}
          {/*.....Claim profile....*/}
          <Route path="/refer-discount" element={<Code_Discount />} />{" "}
          {/*.....Claim profile....*/}
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Home />} /> {/*.....home....*/}
          <Route path="/bank_verification" element={<BankVerification />} />
          <Route path="/login" element={<Login />} />
          <Route path="/comingSoon" element={<ComingSoon />} />
          <Route path="/forgot_password" element={<ForgetPassword />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_and_condition" element={<TermsAndCondition />} />
          <Route
            path="/provider_details/:providerId"
            element={<ProviderDetailsPage />}
          />{" "}
           <Route
            path="/provider_details/:providerId/:serviceId/:reviewOpen/:userId"
            element={<ProviderDetailsPage />}
          />{" "}
          {/*.....details....*/}
          <Route
            path="/book-session/:providerId"
            element={<SessionBooking />}
          />{" "}
          {/*.....booking....*/}
          <Route
            path="/book-session/:providerId/:serviceId"
            element={<SessionBooking />}
          />{" "}
          {/*.....booking....*/}
          <Route
            path="/upcoming-events/:providerId"
            element={<UpcomingEvent />}
          />{" "}
          {/*.....upcoming....*/}
          <Route path="/view-events/:id/:providerId" element={<ViewEvent />} />
          <Route
            path="/view-events/:id/:providerId/:bookingId/:reviewOpen/:userId"
            element={<ViewEvent />}
          />
          {/*.....view event....*/}
          <Route
            path="/event-booking/:id/:packageId/:participants/:providerId"
            element={<EventBooking />}
          />{" "}
          {/*.....view booking....*/}
          <Route path="/claim-provider/:id" element={<Claim_profile />} />{" "}
          {/*.....Claim profile....*/}
          <Route
            path="/provider_details/:providerId/:serviceId"
            element={<ProviderDetailsPage />}
          />{" "}
          {/* ..... for select service ....*/}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
      {/* <UserProfile /> */}
    </>
  );
}


// const CLIENT_ID = GOOGLE_CLIENT_ID;
// const API_KEY = GOOGLE_API_KEY;
// const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
// const SCOPES = "https://www.googleapis.com/auth/calendar.events";

// function NewApp() {

//   const [isSignedIn, setIsSignedIn] = useState(false);

//   useEffect(() => {
//     function start() {
//       gapi.client.init({
//         apiKey: API_KEY,
//         clientId: CLIENT_ID,
//         discoveryDocs: DISCOVERY_DOCS,
//         scope: SCOPES,
//       }).then(() => {
//         gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
//         setIsSignedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
//       });
//     }

//     gapi.load('client:auth2', start);
//   }, []);

//   const handleAuthClick = () => {
//     gapi.auth2.getAuthInstance().signIn();
//   };

//   const handleSignOutClick = () => {
//     gapi.auth2.getAuthInstance().signOut();
//   };

//   const createEvent = () => {
//     const event = {
//       summary: 'Sample Event',
//       start: {
//         dateTime: '2024-05-30T09:00:00-07:00',
//         timeZone: 'America/Los_Angeles',
//       },
//       end: {
//         dateTime: '2024-05-30T17:00:00-07:00',
//         timeZone: 'America/Los_Angeles',
//       },
//     };

//     const request = gapi.client.calendar.events.insert({
//       calendarId: 'primary',
//       resource: event,
//     });

//     request.execute((event) => {
//       console.log('Event created: ', event.htmlLink);
//       alert('Event created: ' + event.htmlLink);
//     });
//   };

//   return (
//     <div>
//       {!isSignedIn ? (
//         <button onClick={handleAuthClick}>Sign in with Google</button>
//       ) : (
//         <div>
//           <button onClick={handleSignOutClick}>Sign out</button>
//           <button onClick={createEvent}>Create Event</button>
//         </div>
//       )}
//     </div>
//   );
// }

export default App;
