import React, {useState} from "react";
import {IconButton} from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {Modal} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import EventSharePopup from "./EventSharePopup";
import shareIocn from "../assets/images/shareIcon.svg"

const ServicePopUP = ({open, setOpen, data, providerId, path, typeSharing}) => {
    const [isShare, setShare] = useState(false);
    return (
        <>
            <Modal
                centered
                show={open.service}
                onHide={() => {
                    setOpen((pre) => ({...pre, service: false}));
                }}
                dialogClassName="delete-modal"
            >
                <div
                    className="Congratulations_msg p-4"
                    style={{boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.12)"}}
                >
                    <div className='d-flex justify-content-between align-items-center'>
                        {(JSON.parse(localStorage.getItem("AdAnima_auth"))?._id == providerId) && <IconButton
                            sx={{
                                transform: "scale(1.8)",
                                display: "flex",
                                alignItems: "flex-start",
                            }}
                            onClick={() => {
                                setShare(true)
                                setOpen((pre) => ({...pre, service: false}));
                            }}

                        >
                            <img src={shareIocn} alt=""/>
                        </IconButton>}
                        <IconButton
                            sx={{
                                background:
                                    "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)",
                                display: "flex",
                                marginLeft: "auto",
                                alignItems: "flex-end",
                            }}
                            onClick={() => setOpen((pre) => ({...pre, service: false}))}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <div className="text-center mt-3">
                        <h3>{data?.service_name}</h3>
                         <h3>${Number(data?.session_price ?? 0)?.toFixed(2)}</h3>
                        <p>{data?.session_minute > 0 ? `${data?.session_hour}:${data?.session_minute}` : `${data?.session_hour}`} Hours</p>
                    </div>
                    {data?.description && <div className="mt-4">
                        <h5 style={{fontWeight: "600"}}>Description</h5>
                        <p style={{wordBreak: "break-word"}}>{data?.description}</p>
                    </div>}
                </div>
            </Modal>

            <EventSharePopup setOpen={setShare} open={isShare} typeSharing={typeSharing} path={"provider_details"}
                             providerId={providerId} data={data}/>
        </>
    );
};

export default ServicePopUP;
