import React from 'react';
import {IconButton} from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import {Rate} from "antd";
import {addRating} from "../action/otherProviderConstant";
import {Modal} from "react-bootstrap";

const ViewReviewPopup = ({open, setOpen, selectedReview, setSelectedReview, title}) => {
    return (<>
        <Modal
            centered
            show={open}
            onHide={() => {
                setOpen(false);
                setSelectedReview("")
            }}
            dialogClassName="delete-modal"
        >
            <div className="Congratulations_msg p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="m-0">{title}</h3>
                    <IconButton
                        onClick={() => {
                            setSelectedReview("")
                            setOpen(false)
                        }}
                    >
                        <img src={closeIcon} alt=""/>
                    </IconButton>
                </div>
                <div className="mt-3 removeScroll" style={{height: "calc(100vh - 300px)", overflow: "scroll"}}>
                    <p style={{wordWrap: "break-word"}}>{selectedReview}</p>
                </div>

            </div>
        </Modal>
    </>)
}

export default ViewReviewPopup;