import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import {IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import imageLoader from '../assets/images/imgLoader.gif';
import imgNotIcon from "../assets/images/alartIcon.jpg"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '100%',
    maxHeight: '400px',
    // background : 'white',
    // bgcolor: 'white',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const ImageModle = ({open, handleClose, imageUrl}) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const handleImageLoaded = () => {
        setLoaded(true);
    };

    const handleImageError = () => {
        setError(true);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    setLoaded(false)
                    setError(false)
                    handleClose()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={style}>
                    <div className="position-relative">
                        <IconButton className="position-absolute" onClick={() => handleClose()} style={{
                            right: "8px",
                            top: "8px",
                            background: "rgb(128 128 128 / 82%)"
                        }}>
                            <CloseIcon/>
                        </IconButton>
                        {/*{error ? <img src={imgNotIcon} alt="" style={{width: "100%"}}/> :*/}
                        <img src={imageUrl} alt="" style={{width: "100%"}}
                            // onLoad={handleImageLoaded}
                            // onError={handleImageError}
                        />
                        {/*}*/}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ImageModle;