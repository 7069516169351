import React from 'react';
import {Grid, IconButton} from "@mui/material";
import plusIcon from '../../assets/images/plus.png'
import closeIcon from '../../assets/images/close-red-icon.png'

const AddActivity = ({setDetails, details}) => {

    const handleAdd = () => {
        if (details?.activity?.length < 6) {
            setDetails((pre) => ({...pre, activity: [...pre?.activity, ""]}))
        }
    }

    const handleOnChange = (e, index) => {
        setDetails((pre) => ({
            ...pre, activity: pre?.activity?.map((ele, i) => {
                if (i == index) {
                    return e.target.value
                } else {
                    return ele
                }
            })
        }))
    }


    const handleDelete = (i) => {
        const newValue = details?.activity?.filter((value, j) => {
            return j !== i;
        });
        setDetails((pre) => ({...pre, activity: newValue}))
    }


    return (
        <>
            <div className="inner-gred box_shadow" style={{height: '100%'}}>
                <div className="d-flex align-items-center justify-content-between">
                    <p className="t-16-bold m-0">Add Activities</p>
                    <IconButton onClick={() => handleAdd()}>
                        <img src={plusIcon} alt=""/>
                    </IconButton>
                </div>
                <div className="mt-2">
                    <Grid container spacing={2}>
                        {details?.activity?.map((ele, i) => (
                            <Grid item xs={details?.activity?.length > 1 ? 6 : 12}>
                                <div>
                                    <div className="group-input mt-2 p-15 clearfix position-relative">
                                        <input
                                            // aria-required="true"
                                            required={i == 0 ? true : false}
                                            type="text"
                                            onChange={(e) => {
                                                if (e.target.value?.length <= 80) {
                                                    handleOnChange(e, i)
                                                }
                                            }}
                                            value={ele}
                                            placeholder="Enter activity"
                                        />
                                        {details?.activity?.length > 1 ?
                                            <IconButton onClick={() => handleDelete(i)} className="position-absolute"
                                                        sx={{
                                                            right: '0',
                                                            top: '10%'
                                                        }}>
                                                <img src={closeIcon} alt=""/>
                                            </IconButton> : null}
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default AddActivity;