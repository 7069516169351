import React, {useEffect} from 'react';
import {Modal} from "react-bootstrap";
import {IconButton} from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg"
import {useNavigate} from "react-router-dom";

const LoginPopup = ({open, setOpen}) => {
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            setOpen(false);
        }
    }, []);

    return (<>
        <Modal
            centered
            show={open.review}
            backdrop="static"
            onHide={() => {
                setOpen((pre) => ({...pre, review: false}));
            }}
            dialogClassName="delete-modal"
        >
            <div className="Congratulations_msg p-3">
                <div className="d-flex justify-content-end">
                    <IconButton sx={{background: "#EEE"}}  onClick={() => setOpen((pre) => ({...pre, review: false}))}>
                        <img src={closeIcon} alt=""/>
                    </IconButton>
                </div>
                <p className="t-24-bold text-center text-black mt-3">You need to login or sign up to
                    add a review</p>

                <div className="d-flex align-items-center justify-content-center" style={{
                    marginBottom: "13px"
                }}>
                    <button
                        className="massageBtn mt-3"
                        style={{maxWidth: "346px", height: "45px"}}
                        onClick={() => {
                            setOpen((pre) => ({...pre, review: false}))
                            navigate("/login")
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
        </Modal>
    </>)
}

export default LoginPopup;