import React from 'react';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import img1 from "../../assets/images/1.jpg";
import varificationIcon from "../../assets/images/varificationIcon.svg";
import improveIcon from "../../assets/images/green_arrow.svg";
import {Grid} from "@mui/material";
import {capital} from "../../Utils/capitalUtils";
import moment from "moment";
import {LocalFormate} from "../../Utils/DateFormateUtils";
import userIcon from "../../assets/images/userIconWithBg.png"

const ParticipantCard = ({data, setOpenUserModal, setOpenProDetails}) => {

    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                <div className="border-gred mb-0 c-pointer" onClick={() => {
                    setOpenUserModal(true)
                    setOpenProDetails(data)
                }}>
                    <div className="inner-gred" style={{padding: "10px 14px"}}>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center w-100">
                                <div className="position-relative">
                                    <div className="p_image_shape me-2 me-sm-0"
                                         style={{transform: "scale(0.8)", marginLeft: "-8px"}}>
                                        <img src={data?.user_id?.profile_picture || userIcon} onError={(e) => (e.target.src = userIcon)}/>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="m-0 t-16-bold text-black">{data?.user_id?.name}</p>
                                        <p className="t-15-bold m-0 m-0 text-black text-end">$ {Number(data?.total_transfered_amount ?? 0).toFixed(2)}</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                        <p className="m-0 t-10-bold text-gray">{data?.user_id?.dob ? moment(LocalFormate(data?.user_id?.dob)).fromNow() : null}</p>
                                        <div>
                                            {(data?.payment_status == "pending" || data?.payment_status == "processing" || data?.payment_status == "modify_pending") ?
                                                <p className="event-partisipents-panding-status t-13-bold m-0">PAYMENT
                                                    PENDING</p> : data?.payment_status == "failed" ?
                                                    <p className="event-partisipents-cancel-status t-13-bold m-0">PAYMENT
                                                        CANCEL</p> : null
                                            }


                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="m-0 t-14-bold">{data?.event_package_id?.package_name}</p>
                                        {/*<p className="m-0 t-12-bold text-black" style={{color: "585858"}}>{capital(data?.user_id?.user_type)}</p>*/}
                                        <div className="text-center">

                                            <p className="t-12-bold m-0"
                                               style={{textAlign: 'end'}}>{data?.no_of_participants} Participants</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </>
    )
}

export default ParticipantCard;