import React from 'react';
import DeleteImage from "../assets/images/deletedot.png";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const UserFoundPopup = ({open, setOpen}) => {
    const navigate = useNavigate();
    return(
        <>
            <Modal
                centered
                show={open}
                backdrop="static"
                onHide={() => {
                    setOpen(false);
                }}
                dialogClassName="delete-modal"
            >
                <div className="Congratulations_msg">
                    <div className="group-parent m-0" style={{ borderRadius: "10px" }}>
                        <div className="group " style={{ borderRadius: "10px" }}>
                            <div className="padding-inner pt-2" style={{ borderRadius: "10px" }}>
                                <div className="text-center">
                                    <img
                                        className="img-fluid logo"
                                        alt=""
                                        src={DeleteImage}
                                    />
                                </div>
                                <h3 className="mb-3 mb-md-3 page-title text-center">
                                    Removed Provider!
                                </h3>

                                <p className="text-center mb-3 mb-md-5" style={{ color: "#000" }}>
                                    This Provider a not available currently.
                                </p>
                                <div className="w-100 d-flex">
                                    <div className="col-6 offset-3" >
                                        <button type="submit" style={{lineHeight: "15px"}} className="btn_admina delete-button" onClick={() => {
                                            navigate("/")
                                            setOpen(false)
                                        }} >
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UserFoundPopup;