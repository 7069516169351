import React, {useEffect, useState} from 'react';
import {Box, Grid} from "@mui/material";
import Header from "../Component/Header";
import ComingSoon from "../Component/ComingSoon";
import soonImg from "../assets/images/c_soon.png";
import {getSavedData} from "../action/saveConstant";
import {useDispatch, useSelector} from "react-redux";
import SaveServiceCard from "../Component/SaveCards/SaveServiceCard";
import SaveEventCard from "../Component/SaveCards/SaveEventCard";
import Loader from "../Component/Loader";
import EmptyFile from "../Component/EmptyFile";
import logo from "../assets/images/ad-anima_logo.png"
import SaveClaimCard from "../Component/SaveCards/SaveClaimCard";

const Save = () => {
    const dispatch = useDispatch();
    const {save} = useSelector((state) => state);
    const [center, setCenter] = useState();
    const [allSavedData, setAllSavedData] = useState([])
    const gatSavedList = () => {
        const payload = {
            page: 1,
            limit: 10,
            latLong: center
        }
        dispatch(getSavedData(payload))
    }

    useEffect(() => {
        gatSavedList()
    }, [center]);

    useEffect(() => {
        setAllSavedData(save?.savedData)
    }, [save?.savedData]);

    useEffect(() => {
        if ("geolocation" in navigator) {
            //check geolocation available
            //try to get user current location using getCurrentPosition() method
            navigator?.geolocation?.getCurrentPosition(async (position) => {
                // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
                await setCenter({
                    lat: position?.coords?.latitude, lng: position?.coords?.longitude,
                });
            });
        } else {
            console.log("Browser doesn't support geolocation!");
        }
    }, []);

    return (
        <Box>
            <section className="main-page">
                <Header select={"Save"}/>
                <div className="main-inner">
                    <div className="container">
                        <p className="t-30-bold">Saved Event & Services</p>
                        {allSavedData?.length ? <Grid container spacing={2}>
                            {allSavedData?.map((ele, i) => (
                                <Grid item xs={12} md={6} key={i}>
                                    {(ele?.user_id?.is_admin_created && !ele?.user_id?.is_already_claimed) ? <SaveClaimCard ele={ele} gatSavedList={gatSavedList}/> : (ele?.save_type == "event" ?
                                        <SaveEventCard ele={ele} gatSavedList={gatSavedList}/> :
                                        <SaveServiceCard ele={ele} gatSavedList={gatSavedList}/>)}
                                </Grid>
                            ))}
                        </Grid> : <EmptyFile/>}
                    </div>
                </div>
            </section>
        </Box>
    )
}

export default Save;