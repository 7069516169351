import React, { useState } from "react";
import { Link } from "react-router-dom";
import imgErrorIcon from "../assets/images/alartIcon.jpg";
import logo from "../assets/images/ad-anima_logo.png";
import placeImg from "../assets/images/userIconWithBg.png";
import imgLoader from "../assets/images/imgLoader.gif";
import noImg from "../assets/images/userIconWithBg.png";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Collapse from "react-bootstrap/Collapse";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../Utils/DateFormateUtils";

const HomeCard = ({
  data,
  index,
  saveClaimProvider,
  saveProvider,
  userAuth,
  saveEvent,
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imageNotFound, setImageNotFound] = useState(false);

  const [collapseOpen, setcollapseOpen] = useState(false);

  return (
    <>
      {data?.is_service_array ? (
        <>
          {data?.services?.length > 1 ? (
            <>
              <div
                className="w-100 p-2 position-relative"
                key={`key _${index}`}
              >
                <div
                  className="border-gred mb-0 p-0"
                  style={{
                    border: "1px solid #D0DEE2",
                    background: "#fff",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="inner-gred d-flex align-items-center p-2"
                    style={{
                      background: "white",
                    }}
                    
                  >
                    <div className="position-relative">
                      <div className="p_image_shape me-2 me-sm-3">
                        {
                          imageNotFound ? (
                            <img
                              src={imgErrorIcon}
                              style={{ background: "white" }}
                            />
                          ) : (
                            // data?.service_name ?
                            <img
                              src={
                                imgLoaded
                                  ? data?.user_id?.provider_banner_images
                                  : imgLoader
                              }
                              style={{ background: "white" }}
                              onLoad={() => setImgLoaded(true)}
                              onError={() => setImageNotFound(true)}
                              // onError={(e) => {
                              //   e.target.src = noImg;
                              // }}
                            />
                          )
                          // :
                          // <img
                          //     src={ data?.profile_picture || placeImg}
                          //     style={{background: "white"}}
                          //     onError={(e) => {
                          //         e.target.src = noImg;
                          //     }}
                          // />
                        }
                      </div>
                      <img
                        src={data?.user_id?.profile_picture || placeImg}
                        style={{
                          right: "14px",
                          bottom: "-3px",
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                          zIndex: "11",
                          border: "3px solid white",
                          background: "white",
                        }}
                        className="position-absolute"
                        onError={(e) => {
                          e.target.src = noImg;
                        }}
                      />
                    </div>
                    <div className="w-100">
                      <h4
                        className="mb-0"
                        style={{ fontSize: "14px", width: "90%" }}
                      >
                        {data?.user_id?.name || ""}
                      </h4>
                      <div className="d-flex align-items-end gap-1 mb-1">
                        <div style={{ color: "#FFC107" }}>
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 0.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 1.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 2.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 3.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 4.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                        </div>
                        <p className="m-0 t-12-bold">
                          (
                          {Math.round(
                            data?.user_id?.average_rating ||
                              data?.average_rating ||
                              0
                          )}
                          /5)
                        </p>
                      </div>
                      <div
                        className="d-flex align-items-center p-1 ps-2"
                        onClick={() => {
                          setcollapseOpen(!collapseOpen);
                        }}
                        style={{
                          background:
                            "linear-gradient(270deg, rgba(218, 225, 174, 0.5) 0%, rgba(171, 213, 180, 0.5) 99.79%)",
                          borderRadius: "20px",
                          width: "fit-content",
                          cursor: "pointer"
                        }}
                      >
                        <p className="m-0 me-2" style={{ fontSize: "12px" }}>
                          View Services
                        </p>
                        <button
                          className={
                            collapseOpen
                              ? "btn collepsActive d-flex align-items-center justify-content-center p-0"
                              : "btn d-flex align-items-center justify-content-center p-0"
                          }
                          style={{
                            backgroundColor: "#fff",
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Group 21861">
                              <path
                                id="Shape"
                                d="M5 7.5L10 12.5L15 7.5"
                                stroke="#585858"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <Collapse in={collapseOpen}>
                    <div className="px-2">
                      {data?.services?.map((services) => {
                        return (
                          <>
                            <div
                              className="inner-gred d-flex align-items-center p-2 position-relative"
                              style={{
                                background: "white",
                                borderTop: "1px solid #D0DEE2",
                                borderRadius: "0px",
                              }}
                            >
                              <Link
                                to={`/provider_details/${data?.user_id?._id}/${services?._id}`}
                                className="w-100"
                              >
                                <div className="w-100 py-1">
                                  <div
                                    className={`d-flex justify-content-between align-items-center mt-0 ${
                                      userAuth?.token && "pe-3"
                                    }`}
                                  >
                                    <h4
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                      }}
                                      className="mb-0"
                                    >
                                      {services?.service_name}
                                    </h4>
                                    {data?.is_service !== null ? (
                                      <div className="d-flex align-items-center">
                                        <div className="me-2 me-sm-2 d-flex">
                                          <img
                                            src="images/mapDiscovery.svg"
                                            style={{ width: "14px" }}
                                          />
                                        </div>
                                        <p
                                          className="map_des m-0 p-0"
                                          style={{
                                            fontSize: "10px",
                                            color: "#FFC107",
                                          }}
                                        >
                                          {services?.miles_distance} Mile Away
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                  <p
                                    className="mb-0 home-desc mt-1"
                                    style={{
                                      fontSize: "10px",
                                      wordBreak: "break-word",
                                      lineHeight: "normal"
                                    }}
                                  >
                                    {services?.description || ""}
                                  </p>
                                </div>
                              </Link>
                              {userAuth?.token && (
                                <img
                                  className="position-absolute"
                                  src={
                                    services?.is_save
                                      ? "images/save.svg"
                                      : "images/saveIcon.svg"
                                  }
                                  height="18px"
                                  onClick={() => {
                                    if (services?.is_save) {
                                      saveProvider(services?._id, false);
                                    } else {
                                      saveProvider(services?._id, true);
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    top: "14px",
                                    right: "0px",
                                  }}
                                />
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="w-100 p-2 position-relative"
                key={`key _${index}`}
              >
                <Link
                  to={`/provider_details/${data?.user_id?._id}/${data?.services[0]?._id}`}
                >
                  <div
                    className="border-gred mb-0 p-0"
                    style={{ border: "1px solid #D0DEE2", background: "#fff" }}
                  >
                    <div
                      className="inner-gred d-flex align-items-center p-2"
                      style={{
                        background: "white",
                      }}
                    >
                      <div className="position-relative">
                        <div className="p_image_shape me-2 me-sm-3">
                          {
                            imageNotFound ? (
                              <img
                                src={imgErrorIcon}
                                style={{ background: "white" }}
                              />
                            ) : (
                              // data?.service_name ?
                              <img
                                src={
                                  imgLoaded
                                    ? data?.user_id?.provider_banner_images
                                    : imgLoader
                                }
                                style={{ background: "white" }}
                                onLoad={() => setImgLoaded(true)}
                                onError={() => setImageNotFound(true)}
                                // onError={(e) => {
                                //   e.target.src = noImg;
                                // }}
                              />
                            )
                            // :
                            // <img
                            //     src={ data?.profile_picture || placeImg}
                            //     style={{background: "white"}}
                            //     onError={(e) => {
                            //         e.target.src = noImg;
                            //     }}
                            // />
                          }
                        </div>
                        <img
                          src={data?.user_id?.profile_picture || placeImg}
                          style={{
                            right: "14px",
                            bottom: "-3px",
                            height: "35px",
                            width: "35px",
                            borderRadius: "50%",
                            zIndex: "11",
                            border: "3px solid white",
                            background: "white",
                          }}
                          className="position-absolute"
                          onError={(e) => {
                            e.target.src = noImg;
                          }}
                        />
                      </div>
                      <div className="w-100">
                        <h4
                          className="mb-1"
                          style={{ fontSize: "14px", width: "90%" }}
                        >
                          {data?.services[0]?.service_name || ""}
                        </h4>
                        <p
                          className="mb-0 home-desc"
                          style={{
                            fontSize: "10px",
                            wordBreak: "break-word",
                          }}
                        >
                          {data?.services[0]?.description || ""}
                        </p>
                        <div className="d-flex align-items-end gap-1">
                          <div style={{ color: "#FFC107" }}>
                            {Number(
                              data?.user_id?.average_rating ||
                                data?.average_rating
                            ) >= 0.5 ? (
                              <StarIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            ) : (
                              <StarBorderIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            )}
                            {Number(
                              data?.user_id?.average_rating ||
                                data?.average_rating
                            ) >= 1.5 ? (
                              <StarIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            ) : (
                              <StarBorderIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            )}
                            {Number(
                              data?.user_id?.average_rating ||
                                data?.average_rating
                            ) >= 2.5 ? (
                              <StarIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            ) : (
                              <StarBorderIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            )}
                            {Number(
                              data?.user_id?.average_rating ||
                                data?.average_rating
                            ) >= 3.5 ? (
                              <StarIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            ) : (
                              <StarBorderIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            )}
                            {Number(
                              data?.user_id?.average_rating ||
                                data?.average_rating
                            ) >= 4.5 ? (
                              <StarIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            ) : (
                              <StarBorderIcon
                                style={{
                                  height: "14px",
                                  width: "14px",
                                }}
                              />
                            )}
                          </div>
                          <p className="m-0 t-12-bold">
                            (
                            {Math.round(
                              data?.user_id?.average_rating ||
                                data?.average_rating ||
                                0
                            )}
                            /5)
                          </p>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <h5
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                            className="mb-0"
                          >
                            {data?.user_id?.name}
                          </h5>
                          {data?.is_service !== null ? (
                            <div className="d-flex align-items-center">
                              <div className="me-2 me-sm-2 d-flex">
                                <img
                                  src="images/mapDiscovery.svg"
                                  style={{ width: "14px" }}
                                />
                              </div>
                              <p
                                className="map_des m-0 p-0"
                                style={{
                                  fontSize: "10px",
                                  color: "#FFC107",
                                }}
                              >
                                {data?.services[0]?.miles_distance} Mile Away
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                {(data?.is_service == null || data?.is_service == true) &&
                  userAuth?.token && (
                    <img
                      className="position-absolute"
                      src={
                        data?.services[0]?.is_save
                          ? "images/save.svg"
                          : "images/saveIcon.svg"
                      }
                      height="20px"
                      onClick={() => {
                        if (data?.services[0]?.is_save) {
                          saveProvider(data?.services[0]?._id, false);
                        } else {
                          saveProvider(data?.services[0]?._id, true);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        top: "20px",
                        right: "20px",
                      }}
                    />
                  )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="w-100 p-2 position-relative" key={`key _${index}`}>
            <Link
              to={
                data?.is_service == true
                  ? `/provider_details/${data?.user_id?._id}/${data?._id}`
                  : data?.is_service == null
                  ? `/view-events/${data?._id}/${data?.user_id?._id}`
                  : `/provider_details/${data?._id}`
              }
            >
              <div
                className="border-gred mb-0 p-0"
                style={{ border: "1px solid #D0DEE2", background: "#fff" }}
              >
                <div
                  className="inner-gred d-flex align-items-center p-2"
                  style={{
                    background: data?.is_pre_populate_event
                      ? "linear-gradient(270deg, rgba(218, 225, 174, 0.5) 0%, rgba(171, 213, 180, 0.5) 99.79%)"
                      : data?.user_id?.is_admin_created ||
                        data?.is_admin_created
                      ? "#EAEAEA"
                      : data?.is_service == true
                      ? "white"
                      : data?.is_service == null
                      ? "#fff6e5"
                      : "#f0fcff",
                  }}
                >
                  <div className="position-relative">
                    <div className="p_image_shape me-2 me-sm-3">
                      {
                        imageNotFound ? (
                          <img
                            src={imgErrorIcon}
                            style={{ background: "white" }}
                          />
                        ) : (
                          // data?.service_name ?
                          <img
                            src={
                              imgLoaded
                                ? (data?.user_id?.is_admin_created ||
                                    data?.is_admin_created) &&
                                  !data?.user_id?.is_already_claimed
                                  ? data?.profile_picture ||
                                    data?.user_profile_picture
                                    ? data?.profile_picture ||
                                      data?.user_profile_picture
                                    : logo
                                  : data?.banner_image
                                  ? data?.banner_image
                                  : placeImg
                                : imgLoader
                            }
                            style={{ background: "white" }}
                            onLoad={() => setImgLoaded(true)}
                            onError={() => setImageNotFound(true)}
                            // onError={(e) => {
                            //   e.target.src = noImg;
                            // }}
                          />
                        )
                        // :
                        // <img
                        //     src={ data?.profile_picture || placeImg}
                        //     style={{background: "white"}}
                        //     onError={(e) => {
                        //         e.target.src = noImg;
                        //     }}
                        // />
                      }
                    </div>
                    <img
                      src={
                        (data?.user_id?.is_admin_created ||
                          data?.is_admin_created) &&
                        !data?.user_id?.is_already_claimed
                          ? data?.provider_banner_images?.length > 0
                            ? data?.provider_banner_images[0]
                            : data?.banner_image
                            ? data?.banner_image
                            : logo
                          : data?.user_profile_picture || data?.profile_picture
                          ? data?.user_profile_picture || data?.profile_picture
                          : placeImg
                      }
                      style={{
                        right: "14px",
                        bottom: "-3px",
                        height: "35px",
                        width: "35px",
                        borderRadius: "50%",
                        zIndex: "11",
                        border: "3px solid white",
                        background: "white",
                      }}
                      className="position-absolute"
                      onError={(e) => {
                        e.target.src = noImg;
                      }}
                    />
                  </div>
                  <div className="w-100">
                    <h4
                      className="mb-1"
                      style={{ fontSize: "14px", width: "90%" }}
                    >
                      {data?.service_name || data?.event_name || data?.name}
                    </h4>
                    {data?.is_service !== null ? (
                      <p
                        className="mb-0 home-desc"
                        style={{
                          fontSize: "10px",
                          wordBreak: "break-word",
                        }}
                      >
                        {data?.description || data?.about}
                      </p>
                    ) : (
                      <p
                        className="mb-0 home-desc"
                        style={{
                          fontSize: "12px",
                          wordBreak: "break-word",
                        }}
                      >
                        {DateFormate(
                          LocalFormate(data?.start_date, data?.date_timezone),
                          LocalFormate(data?.end_date, data?.date_timezone)
                        )}{" "}
                        (
                        {timeZoneFormate(data?.start_date, data?.date_timezone)}
                        )
                      </p>
                    )}
                    {data?.is_service !== null ? (
                      <div className="d-flex align-items-end gap-1">
                        <div style={{ color: "#FFC107" }}>
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 0.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 1.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 2.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 3.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                          {Number(
                            data?.user_id?.average_rating ||
                              data?.average_rating
                          ) >= 4.5 ? (
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          )}
                        </div>
                        <p className="m-0 t-12-bold">
                          (
                          {Math.round(
                            data?.user_id?.average_rating ||
                              data?.average_rating ||
                              0
                          )}
                          /5)
                        </p>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <div className="me-2 me-sm-2 d-flex">
                          <img
                            src="images/mapDiscovery.svg"
                            style={{ width: "14px" }}
                          />
                        </div>
                        <p
                          className="map_des m-0 p-0"
                          style={{
                            fontSize: "10px",
                            color: "#FFC107",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "63%",
                          }}
                        >
                          {data?.address}
                        </p>
                      </div>
                    )}
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <h5
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                        className="mb-0"
                      >
                        {data?.user_id?.name}
                      </h5>
                      {data?.is_service !== null ? (
                        <div className="d-flex align-items-center">
                          <div className="me-2 me-sm-2 d-flex">
                            <img
                              src="images/mapDiscovery.svg"
                              style={{ width: "14px" }}
                            />
                          </div>
                          <p
                            className="map_des m-0 p-0"
                            style={{
                              fontSize: "10px",
                              color: "#FFC107",
                            }}
                          >
                            {data?.miles_distance} Mile Away
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            {(data?.is_service == null || data?.is_service == true) &&
              userAuth?.token && (
                <img
                  className="position-absolute"
                  src={
                    data?.is_save ? "images/save.svg" : "images/saveIcon.svg"
                  }
                  height="20px"
                  onClick={() => {
                    if (data?.is_already_claimed && data?.is_admin_created) {
                      if (data?.is_save) {
                        saveClaimProvider(data?._id, false);
                      } else {
                        saveClaimProvider(data?._id, true);
                      }
                    } else {
                      if (data?.is_service == null) {
                        if (data?.is_save) {
                          saveEvent(data?._id, false);
                        } else {
                          saveEvent(data?._id, true);
                        }
                      } else {
                        if (data?.is_save) {
                          saveProvider(data?._id, false);
                        } else {
                          saveProvider(data?._id, true);
                        }
                      }
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    top: "20px",
                    right: "20px",
                  }}
                />
              )}
          </div>
        </>
      )}
    </>
  );
};

export default HomeCard;
