import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../../Utils/DateFormateUtils";
import moment from "moment";
import logo from "../../assets/images/tempImage/templogo.png";
import tempimgback from "../../assets/images/tempImage/tempimgback.png";
import tempaback from "../../assets/images/tempImage/tempaback.png";
import tempback2 from "../../assets/images/tempImage/tempback2.png";

const EventPostTemp3 = ({ data }) => {
  const { eventData } = useSelector((state) => state.myEvent);

  const [lowestPrice, setlowestPrice] = useState(0);

  useEffect(() => {
    let lowPrice = 9999999999;

    eventData?.package_details?.map((packege) => {
      if (Number(packege?.value_per_person) < lowPrice) {
        lowPrice = Number(packege?.value_per_person);
      }
    });

    if (lowPrice !== 9999999999) {
      setlowestPrice(lowPrice);
    }
  }, [eventData]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 1080 1080"
      fill="none"
    >
      <g clip-path="url(#clip0_9172_8902)">
        <rect width="1080" height="1080" fill="white" />
        <rect
          y="-438"
          width="1080"
          height="1619"
          fill="url(#pattern0_9172_8902)"
        />
        <rect
          width="1080"
          height="1080"
          fill="url(#paint0_linear_9172_8902)"
          fill-opacity="0.8"
        />
        <rect
          y="910"
          width="1080"
          height="170"
          fill="url(#paint1_linear_9172_8902)"
          fill-opacity="0.2"
        />
        <path
          d="M251.678 1036.43C255.934 1029.75 255.399 1030.58 255.522 1030.41C257.072 1028.22 257.891 1025.65 257.891 1022.97C257.891 1015.86 252.12 1010 245 1010C237.903 1010 232.109 1015.85 232.109 1022.97C232.109 1025.65 232.946 1028.29 234.546 1030.5L238.322 1036.43C234.285 1037.05 227.422 1038.9 227.422 1042.97C227.422 1044.45 228.39 1046.57 233.005 1048.22C236.227 1049.37 240.487 1050 245 1050C253.439 1050 262.578 1047.62 262.578 1042.97C262.578 1038.9 255.723 1037.05 251.678 1036.43ZM236.504 1029.21C236.491 1029.19 236.477 1029.17 236.463 1029.16C235.132 1027.32 234.453 1025.15 234.453 1022.97C234.453 1017.11 239.172 1012.34 245 1012.34C250.816 1012.34 255.547 1017.11 255.547 1022.97C255.547 1025.16 254.881 1027.25 253.621 1029.04C253.509 1029.19 254.097 1028.27 245 1042.55L236.504 1029.21ZM245 1047.66C235.782 1047.66 229.766 1044.95 229.766 1042.97C229.766 1041.64 232.857 1039.45 239.707 1038.6L244.012 1045.36C244.118 1045.52 244.264 1045.66 244.436 1045.75C244.609 1045.85 244.803 1045.9 245 1045.9C245.197 1045.9 245.391 1045.85 245.563 1045.75C245.736 1045.66 245.882 1045.52 245.988 1045.36L250.293 1038.6C257.143 1039.45 260.234 1041.64 260.234 1042.97C260.234 1044.93 254.272 1047.66 245 1047.66Z"
          fill="white"
        />

        <path
          d="M245 1017.11C241.769 1017.11 239.141 1019.74 239.141 1022.97C239.141 1026.2 241.769 1028.83 245 1028.83C248.231 1028.83 250.859 1026.2 250.859 1022.97C250.859 1019.74 248.231 1017.11 245 1017.11ZM245 1026.48C243.061 1026.48 241.484 1024.91 241.484 1022.97C241.484 1021.03 243.061 1019.45 245 1019.45C246.939 1019.45 248.516 1021.03 248.516 1022.97C248.516 1024.91 246.939 1026.48 245 1026.48Z"
          fill="white"
        />
        <foreignObject y="1020" x="295" width="557" height="36">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: "30px",
              color: "#FFFFFF",
              fontWeight: "400",
              lineHeight: "36px",
              letterSpacing: "2%",
            }}
            className="text-source text-elips  two-line p-0"
          >
            <u style={{ textDecoration: "none" }}>{eventData?.address}</u>
          </div>
        </foreignObject>
        <foreignObject y="942" x="295" width="525" height="36">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: "30px",
              color: "#FFFFFF",
              fontWeight: "400",
              lineHeight: "36px",
              letterSpacing: "2%",
            }}
            className="text-yrsa    p-0"
          >
            <u style={{ textDecoration: "none" }}>
              {DateFormate(
                LocalFormate(eventData?.start_date, eventData?.date_timezone),
                LocalFormate(eventData?.end_date, eventData?.date_timezone)
              )}
              ,{" "}
              {moment(
                LocalFormate(eventData?.start_time, eventData?.date_timezone)
              ).format("LT")}{" "}
              to{" "}
              {moment(
                LocalFormate(eventData?.end_time, eventData?.date_timezone)
              ).format("LT")}{" "}
              (
              {timeZoneFormate(eventData?.start_time, eventData?.date_timezone)}
              )
            </u>
          </div>
        </foreignObject>
        <path
          d="M255.156 957.969C256.019 957.969 256.719 957.269 256.719 956.406C256.719 955.543 256.019 954.844 255.156 954.844C254.293 954.844 253.594 955.543 253.594 956.406C253.594 957.269 254.293 957.969 255.156 957.969Z"
          fill="white"
        />
        <path
          d="M258.75 943.125H256.719V941.562C256.719 940.7 256.019 940 255.156 940C254.293 940 253.594 940.7 253.594 941.562V943.125H246.484V941.562C246.484 940.7 245.785 940 244.922 940C244.059 940 243.359 940.7 243.359 941.562V943.125H236.328V941.562C236.328 940.7 235.629 940 234.766 940C233.903 940 233.203 940.7 233.203 941.562V943.125H231.25C227.804 943.125 225 945.929 225 949.375V973.75C225 977.196 227.804 980 231.25 980H243.203C244.066 980 244.766 979.3 244.766 978.438C244.766 977.575 244.066 976.875 243.203 976.875H231.25C229.527 976.875 228.125 975.473 228.125 973.75V949.375C228.125 947.652 229.527 946.25 231.25 946.25H233.203V947.812C233.203 948.675 233.903 949.375 234.766 949.375C235.629 949.375 236.328 948.675 236.328 947.812V946.25H243.359V947.812C243.359 948.675 244.059 949.375 244.922 949.375C245.785 949.375 246.484 948.675 246.484 947.812V946.25H253.594V947.812C253.594 948.675 254.293 949.375 255.156 949.375C256.019 949.375 256.719 948.675 256.719 947.812V946.25H258.75C260.473 946.25 261.875 947.652 261.875 949.375V958.281C261.875 959.144 262.575 959.844 263.438 959.844C264.3 959.844 265 959.144 265 958.281V949.375C265 945.929 262.196 943.125 258.75 943.125Z"
          fill="white"
        />
        <path
          d="M255.547 961.094C250.334 961.094 246.094 965.334 246.094 970.547C246.094 975.759 250.334 980 255.547 980C260.759 980 265 975.759 265 970.547C265 965.334 260.759 961.094 255.547 961.094ZM255.547 976.875C252.058 976.875 249.219 974.036 249.219 970.547C249.219 967.057 252.058 964.219 255.547 964.219C259.036 964.219 261.875 967.057 261.875 970.547C261.875 974.036 259.036 976.875 255.547 976.875Z"
          fill="white"
        />
        <path
          d="M257.812 968.984H257.109V967.344C257.109 966.481 256.41 965.781 255.547 965.781C254.684 965.781 253.984 966.481 253.984 967.344V970.547C253.984 971.41 254.684 972.109 255.547 972.109H257.812C258.675 972.109 259.375 971.41 259.375 970.547C259.375 969.684 258.675 968.984 257.812 968.984Z"
          fill="white"
        />
        <path
          d="M248.359 957.969C249.222 957.969 249.922 957.269 249.922 956.406C249.922 955.543 249.222 954.844 248.359 954.844C247.496 954.844 246.797 955.543 246.797 956.406C246.797 957.269 247.496 957.969 248.359 957.969Z"
          fill="white"
        />
        <path
          d="M241.562 964.766C242.425 964.766 243.125 964.066 243.125 963.203C243.125 962.34 242.425 961.641 241.562 961.641C240.7 961.641 240 962.34 240 963.203C240 964.066 240.7 964.766 241.562 964.766Z"
          fill="white"
        />
        <path
          d="M234.766 957.969C235.629 957.969 236.328 957.269 236.328 956.406C236.328 955.543 235.629 954.844 234.766 954.844C233.903 954.844 233.203 955.543 233.203 956.406C233.203 957.269 233.903 957.969 234.766 957.969Z"
          fill="white"
        />
        <path
          d="M234.766 964.766C235.629 964.766 236.328 964.066 236.328 963.203C236.328 962.34 235.629 961.641 234.766 961.641C233.903 961.641 233.203 962.34 233.203 963.203C233.203 964.066 233.903 964.766 234.766 964.766Z"
          fill="white"
        />
        <path
          d="M234.766 971.562C235.629 971.562 236.328 970.863 236.328 970C236.328 969.137 235.629 968.438 234.766 968.438C233.903 968.438 233.203 969.137 233.203 970C233.203 970.863 233.903 971.562 234.766 971.562Z"
          fill="white"
        />
        <path
          d="M241.562 971.562C242.425 971.562 243.125 970.863 243.125 970C243.125 969.137 242.425 968.438 241.562 968.438C240.7 968.438 240 969.137 240 970C240 970.863 240.7 971.562 241.562 971.562Z"
          fill="white"
        />
        <path
          d="M241.562 957.969C242.425 957.969 243.125 957.269 243.125 956.406C243.125 955.543 242.425 954.844 241.562 954.844C240.7 954.844 240 955.543 240 956.406C240 957.269 240.7 957.969 241.562 957.969Z"
          fill="white"
        />
        <rect
          x="20"
          y="20"
          width="111"
          height="100"
          fill="url(#pattern1_9172_8902)"
        />
        <g filter="url(#filter0_d_9172_8902)">
          <path
            d="M1241.25 120H759.746C758.023 120 756.422 119.113 755.508 117.652L727.66 73.1524C726.645 71.5299 726.645 69.4701 727.66 67.8476L755.508 23.3476C756.422 21.8871 758.023 21 759.746 21H1241.25C1242.98 21 1244.58 21.8871 1245.49 23.3476L1273.34 67.8476C1274.36 69.4701 1274.36 71.5299 1273.34 73.1524L1245.49 117.652C1244.58 119.113 1242.98 120 1241.25 120Z"
            fill="url(#paint2_linear_9172_8902)"
          />
        </g>
        <foreignObject y="42" x="784" width="268" height="57">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{ fontSize: "41px", color: "#161F2C" }}
            className="text-oleo text-elips one-line p-0"
          >
            Upcoming Event!
          </div>
        </foreignObject>
        <g filter="url(#filter1_d_9172_8902)">
          <foreignObject y="146" x="20" width="1018" height="58">
            <div
              xmlns="http://www.w3.org/1999/xhtml"
              style={{
                fontSize: "45px",
                color: "#FFFFFF",
                fontWeight: "400",
                lineHeight: "58.4px",
              }}
              className="text-candal text-elips two-line p-0"
            >
              {eventData?.event_name}
            </div>
          </foreignObject>
        </g>
        <foreignObject y="228" x="20" width="361" height="35">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: "35px",
              color: "#F09B00",
              lineHeight: "35px",
              fontWeight: "700",
            }}
            className="text-source text-elips one-line p-0"
          >
            <u>STARTING AT ${lowestPrice}</u>
            {/* <u>
              STARTING AT
              {DateFormate(
                LocalFormate(eventData?.start_date, eventData?.date_timezone),
                LocalFormate(eventData?.end_date, eventData?.date_timezone)
              )}
              ,{" "}
              {moment(
                LocalFormate(eventData?.start_time, eventData?.date_timezone)
              ).format("LT")}{" "}
              to{" "}
              {moment(
                LocalFormate(eventData?.end_time, eventData?.date_timezone)
              ).format("LT")}{" "}
              (
              {timeZoneFormate(eventData?.start_time, eventData?.date_timezone)}
              )
            </u> */}
          </div>
        </foreignObject>

        <g filter="url(#filter2_d_9172_8902)">
          <path
            d="M307.774 373H49.8227C48.1043 373 46.5063 372.118 45.591 370.663L21.6761 332.663C20.6517 331.035 20.6517 328.965 21.6761 327.337L45.591 289.337C46.5063 287.882 48.1043 287 49.8227 287H307.774C309.492 287 311.09 287.882 312.005 289.337L335.92 327.337C336.945 328.965 336.945 331.035 335.92 332.663L312.005 370.663C311.09 372.118 309.492 373 307.774 373Z"
            fill="url(#paint3_linear_9172_8902)"
          />
          <path
            d="M307.774 372H49.8227C48.448 372 47.1695 371.294 46.4373 370.131L22.5224 332.131C21.7029 330.828 21.7029 329.172 22.5224 327.869L46.4373 289.869C47.1695 288.706 48.448 288 49.8227 288H307.774C309.148 288 310.427 288.706 311.159 289.869L335.074 327.869C335.894 329.172 335.894 330.828 335.074 332.131L311.159 370.131C310.427 371.294 309.148 372 307.774 372Z"
            stroke="url(#paint4_linear_9172_8902)"
            stroke-width="2"
          />
        </g>
        <path
          d="M267.022 319.424L260.232 341.316H256.144L251.663 326.998C251.592 326.796 251.522 326.575 251.451 326.334C251.381 326.092 251.316 325.836 251.255 325.564C251.195 325.836 251.13 326.092 251.059 326.334C250.989 326.575 250.918 326.796 250.848 326.998L246.322 341.316H242.218L235.443 319.424H239.245C239.638 319.424 239.965 319.514 240.226 319.695C240.498 319.876 240.674 320.118 240.754 320.419L244.149 332.761C244.229 333.103 244.31 333.475 244.39 333.878C244.481 334.27 244.566 334.687 244.647 335.13C244.737 334.677 244.833 334.255 244.934 333.863C245.034 333.47 245.145 333.103 245.265 332.761L249.218 320.419C249.299 320.168 249.47 319.942 249.731 319.74C250.003 319.529 250.33 319.424 250.712 319.424H252.04C252.432 319.424 252.749 319.519 252.99 319.71C253.242 319.891 253.428 320.128 253.549 320.419L257.487 332.761C257.607 333.083 257.718 333.435 257.818 333.817C257.919 334.189 258.015 334.587 258.105 335.009C258.186 334.587 258.261 334.189 258.331 333.817C258.412 333.435 258.492 333.083 258.573 332.761L261.968 320.419C262.038 320.158 262.209 319.927 262.481 319.725C262.752 319.524 263.079 319.424 263.461 319.424H267.022Z"
          fill="black"
        />
        <path
          d="M234.766 330.362C234.766 331.961 234.494 333.445 233.951 334.813C233.418 336.181 232.658 337.363 231.673 338.359C230.697 339.354 229.52 340.139 228.142 340.712C226.764 341.276 225.235 341.557 223.555 341.557C221.876 341.557 220.347 341.276 218.969 340.712C217.591 340.139 216.409 339.354 215.423 338.359C214.437 337.363 213.673 336.181 213.13 334.813C212.597 333.445 212.33 331.961 212.33 330.362C212.33 328.763 212.597 327.284 213.13 325.926C213.673 324.558 214.437 323.377 215.423 322.381C216.409 321.385 217.591 320.605 218.969 320.042C220.347 319.469 221.876 319.182 223.555 319.182C225.235 319.182 226.764 319.469 228.142 320.042C229.52 320.605 230.697 321.39 231.673 322.396C232.658 323.392 233.418 324.573 233.951 325.941C234.494 327.299 234.766 328.773 234.766 330.362ZM230.134 330.362C230.134 329.215 229.983 328.189 229.681 327.284C229.379 326.369 228.942 325.594 228.368 324.961C227.805 324.317 227.116 323.829 226.301 323.497C225.497 323.155 224.581 322.984 223.555 322.984C222.529 322.984 221.609 323.155 220.794 323.497C219.98 323.829 219.286 324.317 218.712 324.961C218.149 325.594 217.716 326.369 217.415 327.284C217.113 328.189 216.962 329.215 216.962 330.362C216.962 331.519 217.113 332.555 217.415 333.47C217.716 334.375 218.149 335.145 218.712 335.779C219.286 336.412 219.98 336.9 220.794 337.242C221.609 337.574 222.529 337.74 223.555 337.74C224.581 337.74 225.497 337.574 226.301 337.242C227.116 336.9 227.805 336.412 228.368 335.779C228.942 335.145 229.379 334.375 229.681 333.47C229.983 332.555 230.134 331.519 230.134 330.362Z"
          fill="black"
        />
        <path
          d="M209.027 319.424V341.316H206.689C206.337 341.316 206.04 341.261 205.799 341.15C205.557 341.029 205.321 340.828 205.09 340.546L194.181 326.666C194.211 327.018 194.231 327.365 194.242 327.707C194.262 328.049 194.272 328.366 194.272 328.657V341.316H190.273V319.424H192.657C192.848 319.424 193.009 319.434 193.14 319.454C193.281 319.464 193.407 319.499 193.517 319.559C193.628 319.61 193.734 319.68 193.834 319.771C193.935 319.861 194.045 319.982 194.166 320.133L205.15 334.074C205.11 333.692 205.08 333.324 205.059 332.972C205.039 332.61 205.029 332.268 205.029 331.946V319.424H209.027Z"
          fill="black"
        />
        <path
          d="M166.657 328.461H167.547C168.292 328.461 168.83 328.23 169.162 327.767L174.699 320.359C174.96 320.007 175.247 319.766 175.559 319.635C175.871 319.494 176.253 319.424 176.706 319.424H180.628L173.658 328.401C173.205 328.984 172.738 329.402 172.255 329.653C172.607 329.794 172.924 329.98 173.205 330.211C173.487 330.433 173.758 330.729 174.02 331.101L181.172 341.316H177.143C176.882 341.316 176.655 341.296 176.464 341.256C176.283 341.215 176.122 341.16 175.981 341.09C175.841 341.019 175.72 340.934 175.619 340.833C175.519 340.722 175.423 340.597 175.333 340.456L169.705 332.58C169.514 332.308 169.287 332.117 169.026 332.007C168.764 331.886 168.397 331.826 167.925 331.826H166.657V341.316H162.131V319.424H166.657V328.461Z"
          fill="black"
        />
        <path
          d="M158.826 330.362C158.826 331.961 158.554 333.445 158.011 334.813C157.478 336.181 156.719 337.363 155.733 338.359C154.757 339.354 153.581 340.139 152.203 340.712C150.825 341.276 149.296 341.557 147.616 341.557C145.936 341.557 144.407 341.276 143.029 340.712C141.651 340.139 140.469 339.354 139.484 338.359C138.498 337.363 137.733 336.181 137.19 334.813C136.657 333.445 136.391 331.961 136.391 330.362C136.391 328.763 136.657 327.284 137.19 325.926C137.733 324.558 138.498 323.377 139.484 322.381C140.469 321.385 141.651 320.605 143.029 320.042C144.407 319.469 145.936 319.182 147.616 319.182C149.296 319.182 150.825 319.469 152.203 320.042C153.581 320.605 154.757 321.39 155.733 322.396C156.719 323.392 157.478 324.573 158.011 325.941C158.554 327.299 158.826 328.773 158.826 330.362ZM154.194 330.362C154.194 329.215 154.043 328.189 153.741 327.284C153.44 326.369 153.002 325.594 152.429 324.961C151.866 324.317 151.177 323.829 150.362 323.497C149.557 323.155 148.642 322.984 147.616 322.984C146.59 322.984 145.67 323.155 144.855 323.497C144.04 323.829 143.346 324.317 142.773 324.961C142.209 325.594 141.777 326.369 141.475 327.284C141.173 328.189 141.023 329.215 141.023 330.362C141.023 331.519 141.173 332.555 141.475 333.47C141.777 334.375 142.209 335.145 142.773 335.779C143.346 336.412 144.04 336.9 144.855 337.242C145.67 337.574 146.59 337.74 147.616 337.74C148.642 337.74 149.557 337.574 150.362 337.242C151.177 336.9 151.866 336.412 152.429 335.779C153.002 335.145 153.44 334.375 153.741 333.47C154.043 332.555 154.194 331.519 154.194 330.362Z"
          fill="black"
        />
        <path
          d="M134.367 330.362C134.367 331.961 134.096 333.445 133.552 334.813C133.019 336.181 132.26 337.363 131.274 338.359C130.298 339.354 129.122 340.139 127.744 340.712C126.366 341.276 124.837 341.557 123.157 341.557C121.477 341.557 119.948 341.276 118.57 340.712C117.192 340.139 116.01 339.354 115.025 338.359C114.039 337.363 113.274 336.181 112.731 334.813C112.198 333.445 111.932 331.961 111.932 330.362C111.932 328.763 112.198 327.284 112.731 325.926C113.274 324.558 114.039 323.377 115.025 322.381C116.01 321.385 117.192 320.605 118.57 320.042C119.948 319.469 121.477 319.182 123.157 319.182C124.837 319.182 126.366 319.469 127.744 320.042C129.122 320.605 130.298 321.39 131.274 322.396C132.26 323.392 133.019 324.573 133.552 325.941C134.096 327.299 134.367 328.773 134.367 330.362ZM129.735 330.362C129.735 329.215 129.584 328.189 129.283 327.284C128.981 326.369 128.543 325.594 127.97 324.961C127.407 324.317 126.718 323.829 125.903 323.497C125.098 323.155 124.183 322.984 123.157 322.984C122.131 322.984 121.211 323.155 120.396 323.497C119.581 323.829 118.887 324.317 118.314 324.961C117.75 325.594 117.318 326.369 117.016 327.284C116.714 328.189 116.564 329.215 116.564 330.362C116.564 331.519 116.714 332.555 117.016 333.47C117.318 334.375 117.75 335.145 118.314 335.779C118.887 336.412 119.581 336.9 120.396 337.242C121.211 337.574 122.131 337.74 123.157 337.74C124.183 337.74 125.098 337.574 125.903 337.242C126.718 336.9 127.407 336.412 127.97 335.779C128.543 335.145 128.981 334.375 129.283 333.47C129.584 332.555 129.735 331.519 129.735 330.362Z"
          fill="black"
        />
        <foreignObject x="20" y="760" width="1040" height="120">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display:"flex",
              justifyContent:"center",
              fontSize: "20px",
              color: "#FFFFFF",
              fontWeight: "700",
               alignItems:"center",
               height:"100%",
              lineHeight: "23.98px",
            }}
            className="text-inria text-elips three-line w-100 text-center"
          >
            {eventData?.about}
          </div>
        </foreignObject>
        <path
          d="M101.609 337.846C102.333 337.846 102.927 337.76 103.389 337.589C103.862 337.408 104.239 337.172 104.521 336.88C104.803 336.588 104.999 336.256 105.109 335.884C105.22 335.502 105.275 335.11 105.275 334.707C105.275 334.265 105.21 333.868 105.079 333.516C104.948 333.163 104.737 332.867 104.445 332.625C104.154 332.374 103.772 332.183 103.299 332.052C102.836 331.921 102.263 331.856 101.579 331.856H97.7314V337.846H101.609ZM97.7314 322.864V328.778H100.704C101.337 328.778 101.901 328.728 102.394 328.627C102.886 328.516 103.299 328.345 103.631 328.114C103.963 327.883 104.214 327.581 104.385 327.209C104.566 326.827 104.657 326.369 104.657 325.836C104.657 325.293 104.581 324.83 104.43 324.448C104.29 324.066 104.068 323.759 103.766 323.527C103.465 323.296 103.083 323.13 102.62 323.03C102.157 322.919 101.609 322.864 100.975 322.864H97.7314ZM100.975 319.424C102.444 319.424 103.696 319.564 104.732 319.846C105.778 320.118 106.628 320.51 107.282 321.023C107.946 321.526 108.429 322.139 108.73 322.864C109.032 323.588 109.183 324.398 109.183 325.293C109.183 325.796 109.108 326.278 108.957 326.741C108.816 327.204 108.59 327.641 108.278 328.054C107.976 328.456 107.584 328.823 107.101 329.155C106.618 329.487 106.045 329.769 105.381 330C106.87 330.352 107.971 330.925 108.685 331.72C109.399 332.515 109.756 333.546 109.756 334.813C109.756 335.738 109.575 336.598 109.213 337.393C108.861 338.188 108.343 338.877 107.659 339.46C106.975 340.043 106.13 340.501 105.124 340.833C104.129 341.155 102.982 341.316 101.684 341.316H93.2051V319.424H100.975Z"
          fill="black"
        />

        {/* <g filter="url(#filter3_d_9172_8902)">
          <path
            d="M1075 727H526.113C524.331 727 522.683 726.052 521.789 724.51L382.457 484.51C381.556 482.958 381.556 481.042 382.457 479.49L521.789 239.49C522.683 237.948 524.331 237 526.113 237H1075C1077.76 237 1080 239.239 1080 242V722C1080 724.761 1077.76 727 1075 727Z"
            fill="white"
            fill-opacity="0.06"
            shape-rendering="crispEdges"
          />
        </g> */}
        <g filter="url(#filter3_d_9172_8902)">
          <path
            d="M1075 727H526.113C524.331 727 522.683 726.052 521.789 724.51L382.457 484.51C381.556 482.958 381.556 481.042 382.457 479.49L521.789 239.49C522.683 237.948 524.331 237 526.113 237H1075C1077.76 237 1080 239.239 1080 242V722C1080 724.761 1077.76 727 1075 727Z"
            fill="white"
            fill-opacity="0.06"
            shape-rendering="crispEdges"
          />
        </g>
        <foreignObject y="300" x="492" width="566" height="370">
          <div
            className="d-flex flex-wrap align-items-center "
            style={{
              fontSize: "20px",
              color: "#363853",
              height: "100%",
              width: "566px",
            }}
            xmlns="http://www.w3.org/1999/xhtml"
          >
            <div className="w-100">
            <div
              style={{
                fontSize: "30px",
                color: "#FFDB98",
                fontWeight: 700,
                lineHeight: "30px",
                letterSpacing: "5%",
              }}
              className="text-source text-center p-0 w-100 mb-3"
            >
              <u>ACTIVITIES</u>
            </div>
            {eventData?.activities?.map((activities, i) => {
              return (
                <>
                {i<5 &&  <div
                    style={{
                      fontSize: "20px",
                      color: "#FFFFFF",

                      alignItems: "center",
                      lineHeight: "31px ",
                      fontWeight: "400",
                    }}
                    className="text-markoone text-elips w-100  p-0   "
                  >

                    <div className="mx-1">{i + 1}. </div> {activities}
                  </div>}
                 
                </>
              );
            })}
            </div>
          </div>
        </foreignObject>

        <g filter="url(#filter4_d_9172_8902)">
          <path
            d="M176.491 578H75.5085C73.7234 578 72.0738 577.048 71.1801 575.503L21.4475 489.503C20.552 487.954 20.552 486.046 21.4475 484.497L71.1802 398.497C72.0738 396.952 73.7235 396 75.5085 396H176.491C178.277 396 179.926 396.952 180.82 398.497L230.553 484.497C231.448 486.046 231.448 487.954 230.553 489.503L180.82 575.503C179.926 577.048 178.277 578 176.491 578Z"
            fill="url(#pattern2_9172_8902)"
            shape-rendering="crispEdges"
          />
          <path
            d="M176.491 578H75.5085C73.7234 578 72.0738 577.048 71.1801 575.503L21.4475 489.503C20.552 487.954 20.552 486.046 21.4475 484.497L71.1802 398.497C72.0738 396.952 73.7235 396 75.5085 396H176.491C178.277 396 179.926 396.952 180.82 398.497L230.553 484.497C231.448 486.046 231.448 487.954 230.553 489.503L180.82 575.503C179.926 577.048 178.277 578 176.491 578Z"
            stroke="url(#paint5_linear_9172_8902)"
            stroke-width="2"
            shape-rendering="crispEdges"
          />
        </g>
        <g filter="url(#filter5_d_9172_8902)">
          <path
            d="M365.305 709H245.695C243.909 709 242.258 708.047 241.365 706.501L182.445 604.501C181.551 602.953 181.551 601.047 182.445 599.499L241.365 497.499C242.258 495.953 243.909 495 245.695 495H365.305C367.091 495 368.742 495.953 369.635 497.499L428.555 599.499C429.449 601.047 429.449 602.953 428.555 604.501L369.635 706.501C368.742 708.047 367.091 709 365.305 709Z"
            fill="url(#pattern3_9172_8902)"
            shape-rendering="crispEdges"
          />
          <path
            d="M365.305 709H245.695C243.909 709 242.258 708.047 241.365 706.501L182.445 604.501C181.551 602.953 181.551 601.047 182.445 599.499L241.365 497.499C242.258 495.953 243.909 495 245.695 495H365.305C367.091 495 368.742 495.953 369.635 497.499L428.555 599.499C429.449 601.047 429.449 602.953 428.555 604.501L369.635 706.501C368.742 708.047 367.091 709 365.305 709Z"
            stroke="url(#paint6_linear_9172_8902)"
            stroke-width="2"
            shape-rendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <pattern
          id="pattern0_9172_8902"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_9172_8902"
            transform="scale(0.000366032 0.000244172)"
          />
        </pattern>
        <pattern
          id="pattern1_9172_8902"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1_9172_8902"
            transform="matrix(0.001362 0 0 0.00149172 -0.0597826 -0.113095)"
          />
        </pattern>
        <filter
          id="filter0_d_9172_8902"
          x="681.898"
          y="-24"
          width="637.203"
          height="189"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="22.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188333 0 0 0 0 0.4 0 0 0 0 0.220083 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_8902"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_8902"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_9172_8902"
          x="19.7344"
          y="159.316"
          width="1020.54"
          height="44.3208"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_8902"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_8902"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_9172_8902"
          x="12.9082"
          y="279"
          width="331.781"
          height="102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_8902"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_8902"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_9172_8902"
          x="364.101"
          y="228.16"
          width="733.58"
          height="525.361"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.84021" />
          <feGaussianBlur stdDeviation="8.84021" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_8902"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_8902"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_9172_8902"
          x="2.09498"
          y="386.16"
          width="247.81"
          height="219.361"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.84021" />
          <feGaussianBlur stdDeviation="8.84021" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_8902"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_8902"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern2_9172_8902"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image2_9172_8902"
            transform="matrix(0.0025 0 0 0.00291209 0 -0.0824176)"
          />
        </pattern>
        <filter
          id="filter5_d_9172_8902"
          x="163.093"
          y="485.16"
          width="284.814"
          height="251.361"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.84021" />
          <feGaussianBlur stdDeviation="8.84021" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9172_8902"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9172_8902"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern3_9172_8902"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image3_9172_8902"
            transform="matrix(0.00051525 0 0 0.00059952 -0.00906744 0)"
          />
        </pattern>
        <linearGradient
          id="paint0_linear_9172_8902"
          x1="1063"
          y1="580.219"
          x2="1063"
          y2="-1.99546e-05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0C2329" />
          <stop offset="1" stop-color="#6D4706" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9172_8902"
          x1="540"
          y1="910"
          x2="540"
          y2="1080"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0DBCEC" />
          <stop offset="1" stop-color="#FFC056" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9172_8902"
          x1="1275"
          y1="70.5"
          x2="727.13"
          y2="70.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ABD5B4" />
          <stop offset="1" stop-color="#DAE1AE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9172_8902"
          x1="-12.5"
          y1="353.5"
          x2="332"
          y2="353.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0DBCEC" />
          <stop offset="1" stop-color="#FFC056" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9172_8902"
          x1="131.153"
          y1="287"
          x2="131.153"
          y2="373"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CCBEA" />
          <stop offset="1" stop-color="#EFCE96" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9172_8902"
          x1="94.1959"
          y1="396"
          x2="94.1959"
          y2="578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CCBEA" />
          <stop offset="1" stop-color="#EFCE96" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9172_8902"
          x1="268.145"
          y1="495"
          x2="268.145"
          y2="709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CCBEA" />
          <stop offset="1" stop-color="#EFCE96" />
        </linearGradient>
        <clipPath id="clip0_9172_8902">
          <rect width="1080" height="1080" fill="white" />
        </clipPath>
        <image
          id="image0_9172_8902"
          width="2732"
          height="4096"
          xlinkHref={
            eventData?.event_media[1]?.file_name ??
            eventData?.user_id?.provider_banner_images[0]?.file_name
          }
          preserveAspectRatio="xMidYMid slice"
        />
        <image
          id="image1_9172_8902"
          width="822"
          height="822"
          xlinkHref={logo}
        />
        <image
          id="image2_9172_8902"
          width="400"
          height="400"
          xlinkHref={eventData?.user_id?.profile_picture}
          preserveAspectRatio="xMidYMid slice"
        />
        <image
          id="image3_9172_8902"
          width="1976"
          height="1668"
          xlinkHref={eventData?.event_media[0]?.file_name}
          preserveAspectRatio="xMidYMid slice"
        />
      </defs>
    </svg>
  );
};

export default EventPostTemp3;
