import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";
import { upcomingEventsConstant, walletConstant } from "./constant";
import { toast } from "react-toastify";
// import {getMyBookingDetails} from "./myBokingConstant";

export const saveCards = (data) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    // fdata.append("user_id", JSON.parse(localStorage.getItem("AdAnima_auth"))?._id);
    fdata.append("card_holder_name", data.name);
    fdata.append("card_number", data.number);
    fdata.append("expire_year", data.year);
    fdata.append("expire_month", data.month);
    fdata.append("cvv", data.cvv);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.save_wallet}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch(cardsLists());
      dispatch({
        type: walletConstant.SAVE_CARDS,
        payload: response.data,
      });
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
};

export const cardsLists = () => {
  return async (dispatch) => {
    let response;

    // await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_card}`, {
    //     method: "POST",
    //     headers: {
    //         Authorization: `Bearer ${
    //             JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
    //         }`,
    //     },
    // })
    //     .then((response) => response.json())
    //     .then((data) => {
    //         response = data;
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    //
    // if (response?.success) {
    //     dispatch({
    //         type : walletConstant.CARD_LIST,
    //         payload : response.data,
    //     });
    // } else {
    //     toast.error(response?.message);
    // }
  };
};
export const deleteCards = (id) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("card_id", id);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.delete_card}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: walletConstant.DELETE_CART,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
};

export const RefundRequest = (id, getAllHistory) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("payment_history_id", id);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.payment_refund_request}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: walletConstant.REFUND_REQUEST,
        payload: response.data,
      });
      toast.success(response?.message);
      getAllHistory();
    } else {
      toast.error(response?.message);
    }
  };
};
export const RefundPayment = (id, getAllHistory) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("payment_history_id", id);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.refund_payment}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: walletConstant.REFUND_REQUEST,
        payload: response.data,
      });
      toast.success(response?.message);
      getAllHistory();
    } else {
      toast.error(response?.message);
    }
  };
};
export const getBankInfo = () => {
  return async (dispatch) => {
    let response;
    // const fdata = new FormData();
    // fdata.append("user_id", id);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_bank_info}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      // body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: walletConstant.GET_BANK_DETAILS,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
};
export const addBankDetails = (
  data,
  setLoader,
  setOpenBankInfo,
  getUserDetails,
  navigate
) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("account_no", data?.account_no);
    // fdata.append("ssn_number", data?.ssn_number);
    fdata.append("routing_no", data?.routing_no);
    fdata.append("mobile_number", data?.mobile_number);
    fdata.append("email_address", data?.email_address);
    fdata.append("day", data?.day);
    fdata.append("month", data?.month);
    fdata.append("year", data?.year);
    fdata.append("line1", data?.line1);
    fdata.append("city", data?.city);
    fdata.append("state", data?.state);
    fdata.append("postal_code", data?.postal_code);
    fdata.append("first_name", data?.first_name);
    fdata.append("last_name", data?.last_name);
    if (data?.bank_account_id) {
      fdata.append("bank_account_id", data?.bank_account_id);
    }
    // fdata.append("company_name", data?.company_name);
    // fdata.append("tax_id", data?.tax_id);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.save_bank_info}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: walletConstant.SAVE_BANK_DETAILS,
        payload: response.data,
      });
      if (response?.data?.bank_account_status == "Restricted") {
        navigate(response?.data?.id);
      }
      setLoader(false);
      setOpenBankInfo(false);
      getUserDetails();
      toast.success(response?.message);
    } else {
      setLoader(false);
      toast.error(response?.message);
    }
  };
};
