import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment/moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../../../assets/images/Discovery.svg";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getBookingDetails,
  setBookingStatus,
} from "../../../action/myBokingConstant";
import { useDispatch, useSelector } from "react-redux";
import { PayBooking } from "../../../action/paymentConstant";

// import {
//   Elements,
//   CardElement,
//   useElements,
//   useStripe,
//   PaymentElement,
// } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../Payment/PaymentScreen";
import Form from "../Payment/PaymentScreen";
import { capital } from "../../../Utils/capitalUtils";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../../../Utils/DateFormateUtils";
import userIcon from "../../../assets/images/userIconWithBg.png"

const modifyNetworkFees = (ele, details, userAuth) => {
  if (userAuth?._id == details?.user_id?._id) {
    if (
      details?.modify_booking_payment_info?.is_referee &&
      details?.modify_booking_payment_info?.is_discount == false
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees (
                {details?.modify_booking_payment_info
                  ?.fees_percentage_from_user || 0}
                %)
              </p>

              <p
                className="yelloe-t-bold t-14-500 m-0"
                style={{ width: "92%" }}
              >
                You have received a{" "}
                {details?.modify_booking_payment_info
                  ?.referee_fees_percentage ?? 0}
                % discount for using the referral code.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                  whiteSpace: "nowrap",
                }}
              >
                ${" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_amount_from_user
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1" style={{ whiteSpace: "nowrap" }}>
                ${" "}
                {Number(
                  details?.modify_booking_payment_info?.fees_amount_from_user ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (
      details?.modify_booking_payment_info?.is_referee == true &&
      details?.modify_booking_payment_info?.is_discount == true &&
      details?.modify_booking_payment_info?.is_admin_discount == false
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees (
                {details?.modify_booking_payment_info
                  ?.fees_percentage_from_user || 0}
                %)
              </p>

              <p
                className="yelloe-t-bold t-14-500 m-0"
                style={{ width: "92%" }}
              >
                You have received a{" "}
                {details?.modify_booking_payment_info
                  ?.referee_fees_percentage ?? 0}
                % discount for using the referral code.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                  whiteSpace: "nowrap",
                }}
              >
                ${" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_amount_from_user
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1" style={{ whiteSpace: "nowrap" }}>
                ${" "}
                {Number(
                  details?.modify_booking_payment_info?.fees_amount_from_user ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (
      details?.modify_booking_payment_info?.is_referee == true &&
      details?.modify_booking_payment_info?.is_discount == true &&
      details?.modify_booking_payment_info?.is_admin_discount == true
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees (
                {details?.modify_booking_payment_info
                  ?.fees_percentage_from_user || 0}
                %)
              </p>

              <p
                className="yelloe-t-bold t-14-500 m-0"
                style={{ width: "92%" }}
              >
                You have received a{" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_percentage ?? 0
                ) +
                  Number(
                    details?.modify_booking_payment_info.discount_percentage ??
                      0
                  ) ?? 0}
                % discount for using the referral and discount code.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                  whiteSpace: "nowrap",
                }}
              >
                ${" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_amount_from_user
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1" style={{ whiteSpace: "nowrap" }}>
                ${" "}
                {Number(
                  details?.modify_booking_payment_info?.fees_amount_from_user ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (
      details?.modify_booking_payment_info?.is_referee == false &&
      details?.modify_booking_payment_info?.is_discount == true &&
      details?.modify_booking_payment_info?.is_admin_discount == true
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees (
                {details?.modify_booking_payment_info
                  ?.fees_percentage_from_user || 0}
                %)
              </p>

              <p
                className="yelloe-t-bold t-14-500 m-0"
                style={{ width: "92%" }}
              >
                You have received a{" "}
                {details?.modify_booking_payment_info.discount_percentage ?? 0}%
                discount for using the discount code.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                  whiteSpace: "nowrap",
                }}
              >
                ${" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_amount_from_user
                ).toFixed(2)}
              </p>
              <p className="t-12-bold m-1" style={{ whiteSpace: "nowrap" }}>
                ${" "}
                {Number(
                  details?.modify_booking_payment_info?.fees_amount_from_user ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <p className="t-12-bold m-1">
            Network fees (
            {details?.modify_booking_payment_info?.fees_percentage_from_user ||
              0}
            %)
          </p>
          <p className="t-12-bold m-1">
            ${" "}
            {Number(
              details?.modify_booking_payment_info?.fees_amount_from_user ?? 0
            )?.toFixed(2)}
          </p>
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p className="t-12-bold m-1">
          Network fees (
          {details?.modify_booking_payment_info?.fees_percentage_from_user || 0}
          %)
        </p>
        <p className="t-12-bold m-1">
          ${" "}
          {Number(
            details?.modify_booking_payment_info?.fees_amount_from_user ?? 0
          )?.toFixed(2)}
        </p>
      </div>
    );
  }
};

const NormalPaymentInfo = (userAuth, isModify, details) => {
  if (userAuth?._id == details?.user_id?._id) {
    if (
      details?.modify_booking_payment_info?.is_referee &&
      details?.modify_booking_payment_info?.is_discount == false
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees 
                {/* (
                {details?.booking_payment_info?.fees_percentage_from_user || 0}
                %) */}
              </p>

              {/* <p
                className="yelloe-t-bold t-14-500 m-0"
                style={{ width: "92%" }}
              >
                You have received a{" "}
                {details?.modify_booking_payment_info
                  ?.referee_fees_percentage ?? 0}
                % discount for using the referral code.
              </p> */}
            </div>
            <div className="d-flex align-items-center">
              {/* <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                  whiteSpace: "nowrap",
                }}
              >
                ${" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_amount_from_user
                ).toFixed(2)}
              </p> */}
              <p className="t-12-bold m-1" style={{ whiteSpace: "nowrap" }}>
                ${" "}
                {Number(
                  details?.booking_payment_info?.fees_amount_from_user ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (
      details?.modify_booking_payment_info?.is_referee == true &&
      details?.modify_booking_payment_info?.is_discount == true &&
      details?.modify_booking_payment_info?.is_admin_discount == false
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees 
                {/* (
                {details?.booking_payment_info?.fees_percentage_from_user || 0}
                %) */}
              </p>

              {/* <p
                className="yelloe-t-bold t-14-500 m-0"
                style={{ width: "92%" }}
              >
                You have received a{" "}
                {details?.modify_booking_payment_info
                  ?.referee_fees_percentage ?? 0}
                % discount for using the referral code.
              </p> */}
            </div>
            <div className="d-flex align-items-center">
              {/* <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                  whiteSpace: "nowrap",
                }}
              >
                ${" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_amount_from_user
                ).toFixed(2)}
              </p> */}
              <p className="t-12-bold m-1" style={{ whiteSpace: "nowrap" }}>
                ${" "}
                {Number(
                  details?.booking_payment_info?.fees_amount_from_user ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (
      details?.modify_booking_payment_info?.is_referee == true &&
      details?.modify_booking_payment_info?.is_discount == true &&
      details?.modify_booking_payment_info?.is_admin_discount == true
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees 
                {/* (
                {details?.booking_payment_info?.fees_percentage_from_user || 0}
                %) */}
              </p>

              {/* <p
                className="yelloe-t-bold t-14-500 m-0"
                style={{ width: "92%" }}
              >
                You have received a{" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_percentage ?? 0
                ) +
                  Number(
                    details?.modify_booking_payment_info.discount_percentage ??
                      0
                  ) ?? 0}
                % discount for using the referral and discount code.
              </p> */}
            </div>
            <div className="d-flex align-items-center">
              {/* <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                  whiteSpace: "nowrap",
                }}
              >
                ${" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_amount_from_user
                ).toFixed(2)}
              </p> */}
              <p className="t-12-bold m-1" style={{ whiteSpace: "nowrap" }}>
                ${" "}
                {Number(
                  details?.booking_payment_info?.fees_amount_from_user ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (
      details?.modify_booking_payment_info?.is_referee == false &&
      details?.modify_booking_payment_info?.is_discount == true &&
      details?.modify_booking_payment_info?.is_admin_discount == true
    ) {
      return (
        <div>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <p className="t-12-bold m-1 ms-0">
                Network fees 
                {/* (
                {details?.booking_payment_info?.fees_percentage_from_user || 0}
                %) */}
              </p>

              {/* <p
                className="yelloe-t-bold t-14-500 m-0"
                style={{ width: "92%" }}
              >
                You have received a{" "}
                {details?.modify_booking_payment_info.discount_percentage ?? 0}%
                discount for using the discount code.
              </p> */}
            </div>
            <div className="d-flex align-items-center">
              {/* <p
                className="t-12-bold m-1"
                style={{
                  color: "#9E9E9E",
                  textDecoration: "line-through",
                  whiteSpace: "nowrap",
                }}
              >
                ${" "}
                {Number(
                  details?.modify_booking_payment_info
                    ?.referee_fees_amount_from_user
                ).toFixed(2)}
              </p> */}
              <p className="t-12-bold m-1" style={{ whiteSpace: "nowrap" }}>
                ${" "}
                {Number(
                  details?.booking_payment_info?.fees_amount_from_user ??
                    0
                )?.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <p className="t-12-bold m-1">
            Network fees (
            {details?.booking_payment_info?.fees_percentage_from_user || 0}%)
          </p>
          <p className="t-12-bold m-1">
            ${" "}
            {Number(
              details?.booking_payment_info?.fees_amount_from_user ?? 0
            )?.toFixed(2)}
          </p>
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p className="t-12-bold m-1">
          Network fees (
          {details?.booking_payment_info?.fees_percentage_from_user || 0}%)
        </p>
        <p className="t-12-bold m-1">
          ${" "}
          {Number(
            details?.booking_payment_info?.fees_amount_from_user ?? 0
          )?.toFixed(2)}
        </p>
      </div>
    );
  }

  // <div className="d-flex justify-content-between align-items-start">
  //     <div>
  //         <p className="t-12-bold m-1 ms-0">Network fees
  //             ({details?.booking_payment_info?.fees_percentage_from_user || 0}%)</p>
  //         {details?.modify_booking_payment_info?.is_referee && isModify !== true ?
  //             <p className="yelloe-t-bold t-14-500 m-0" style={{width: "92%"}}>You
  //                 have received
  //                 a {details?.modify_booking_payment_info?.referee_fees_percentage ?? 0}%
  //                 discount for using the referral code.</p> : null}
  //         {details?.payment_history_id?.referral_code_info?.is_refree ?
  //             <p className="yelloe-t-bold t-14-500 m-0"
  //                style={{width: "92%", wordBreak: "break-word"}}>You
  //                 have received
  //                 a {details?.payment_history_id?.referral_code_info?.display_user_fees_percentage ?? 0}%
  //                 discount for using the referral code.</p> : null}
  //     </div>
  //     <div className="d-flex align-items-center">
  //         {isModify !== true && details?.modify_booking_payment_info?.is_referee ?
  //             <p className="t-12-bold m-1" style={{
  //                 color: '#9E9E9E',
  //                 textDecoration: 'line-through',
  //                 whiteSpace: 'nowrap',
  //             }}>${Number(details?.modify_booking_payment_info?.referee_fees_amount_from_user).toFixed(2)}</p> : null}
  //         {details?.payment_history_id?.referral_code_info?.is_refree ?
  //             <p className="t-12-bold m-1" style={{
  //                 color: '#9E9E9E',
  //                 textDecoration: 'line-through',
  //                 whiteSpace: 'nowrap',
  //             }}>${Number(details?.payment_history_id?.referral_code_info?.display_user_fees_amount).toFixed(2)}</p> : null}
  //         <p className="t-12-bold m-1"
  //            style={{whiteSpace: 'nowrap'}}>$ {Number((details?.payment_history_id?.fees_amount_from_user || details?.booking_payment_info?.fees_amount_from_user) ?? 0)?.toFixed(2) || 0.00}</p>
  //     </div>
  // </div>
};

const PaymentInfo = ({
  open,
  setOpen,
  miles,
  selectId,
  setSelectId,
  formatBadge,
  data,
  getNotificationsList,
}) => {
  const dispatch = useDispatch();
  const details = useSelector(
    (state) => state?.myBookingDetails?.bookingDetails
  );
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const [isPayment, setIsPayment] = useState(false);
  const [center, setCenter] = useState();
  const isModify = details?.modified_count > 0;

  useEffect(() => {
    if ("geolocation" in navigator) {
      //check geolocation available
      //try to get user current location using getCurrentPosition() method
      navigator?.geolocation?.getCurrentPosition(async (position) => {
        // console.log(
        //   "Found your location \nLat : " +
        //     position?.coords?.latitude +
        //     " \nLang :" +
        //     position?.coords?.longitude
        // );
        await setCenter({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    } else {
      console.log("Browser doesn't support geolocation!");
    }
  }, []);

  const gateData = () => {
    const payload = {
      id: selectId,
      latLong: center,
    };
    dispatch(getBookingDetails(payload));
  };

  const handleClose = () => setIsPayment(false);

  useEffect(() => {
    if (selectId) {
      gateData();
    }
  }, [selectId]);

  const HandleAcssept = (status, id) => {
    // const payload = {
    //     status: status, id: id
    // }
    // dispatch(setBookingStatus(payload))
    // if (selectId) {
    //     gateData()
    //     setSelectId("")
    // }
    // // setTimeout(() => {
    // //     getEvent()
    // // }, 1000);
  };

  // async function makePayment() {
  //     console.log('makePayment------------------------>');
  //
  //     try {
  //         const paymentIntentData = await createPayment({
  //             amount: totalPrice,
  //             currency: 'usd',
  //             applicationFeesAmount: applicationFees,
  //             bankAccountID: accountID // Replace with the actual bank account ID
  //         });
  //
  //         const clientSecret = paymentIntentData.client_secret;
  //         const pi = paymentIntentData.id;
  //
  //         if (paymentIntentData.error) {
  //             MyToasts.errorToast({ toast: "Some Error Occurred. Please Try Again later." });
  //             return;
  //         }
  //
  //         await initPaymentSheet({
  //             paymentIntentClientSecret: clientSecret,
  //             merchantDisplayName: merchantDisplayName,
  //             customerId: paymentIntentData.customer,
  //             customerEphemeralKeySecret: paymentIntentData.ephemeralKey,
  //             customFlow: true,
  //             appearance: StripeStyle.paymentSheetAppearance,
  //             returnURL: "www.test.com"
  //         });
  //
  //         displayPaymentSheet();
  //     } catch (error) {
  //         console.error('makePayment() Error:', error);
  //     }
  // }
  //
  // async function displayPaymentSheet() {
  //     console.log('displayPaymentSheet------------------------>');
  //
  //     try {
  //         await presentPaymentSheet();
  //         MyToasts.successToast({ toast: "Payment Successfully." });
  //         await onAddBookingCalled();
  //
  //         // Update state if needed
  //         setIsButtonAvailable(true);
  //     } catch (error) {
  //         if (error instanceof StripeException) {
  //             console.error('displayPaymentSheet() Error:', error);
  //         } else {
  //             console.error('displayPaymentSheet() Error:', error);
  //             MyToasts.successToast({ toast: "Payment Failed." });
  //         }
  //
  //         // Reset state
  //         setPaymentIntentData(null);
  //         setPi(null);
  //         setIsButtonAvailable(false);
  //     }
  // }


  return (
    <>
      <Modal
        centered
        backdrop="static"
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        dialogClassName="delete-modal payment-details-modal"
        style={{ zIndex: "100000", background: "#00000096" }}
      >
        <div className="Congratulations_msg payment-details-modal-mx">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0 t-24-bold">
                {data?.booking_type?.charAt(0).toUpperCase() +
                  data?.booking_type?.slice(1)}{" "}
                Payment
              </p>
              <IconButton
                sx={{
                  background:
                    "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)",
                }}
                onClick={() => {
                  setSelectId("");
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              className="inner-gred box_shadow mt-4"
              style={{
                background:
                  details.booking_type == "event" && "rgba(255, 165, 0, 0.10)",
              }}
            >
              <div className="d-flex">
                <div className="p_image_shape me-2 me-sm-3">
                  <img
                    src={
                      details?.event_id?.event_media?.length
                        ? details?.event_id?.event_media[0]?.file_name
                        : details?.provider_id?.provider_banner_images[0]
                            ?.file_name
                    }
                  />
                </div>
                <div>
                  <p className="t-15-bold mb-1" style={{ color: "black" }}>
                    {details?.service_id?.service_name ||
                      details?.event_id?.event_name}
                  </p>
                  {details.booking_type !== "event" && (
                    <div className="d-flex gap-2">
                      <div
                        className="p_image_shape"
                        style={{
                          width: "32px",
                          height: "35px",
                          minWidth: "32px",
                        }}
                      >
                        <img
                          src={details?.provider_id?.profile_picture || userIcon}
                          style={{
                            right: "0px",
                            bottom: "0px",
                            height: "30px",
                            width: "32px",
                          }}
                          onError={(e) => (e.target.src = userIcon)}
                        />
                      </div>
                      <p className="t-14-bold">{details?.provider_id?.name}</p>
                    </div>
                  )}
                  {details.booking_type == "event" && (
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="m-0 t-12-bold">
                        {DateFormate(
                          LocalFormate(
                            details?.event_id?.start_date,
                            details?.event_id?.date_timezone
                          ),
                          null
                        )}
                      </p>
                      <FiberManualRecordIcon
                        style={{ transform: "scale(0.3)" }}
                      />
                      <p className="m-0 t-12-bold">
                        {moment(
                          LocalFormate(
                            details?.event_id?.start_time,
                            details?.event_id?.date_timezone
                          )
                        ).format("LT")}{" "}
                        to{" "}
                        {moment(
                          LocalFormate(
                            details?.event_id?.end_time,
                            details?.event_id?.date_timezone
                          )
                        ).format("LT")}{" "}
                        (
                        {timeZoneFormate(
                          details?.event_id?.start_time,
                          details?.event_id?.date_timezone
                        )}
                        )
                      </p>
                    </div>
                  )}
                  <div className="d-flex gap-1 mt-2">
                    <img src={locationIcon} alt="" />
                    <p className="yelloe-t-bold m-0">
                      {miles || details?.miles_distance || 0} Mile Away
                    </p>
                  </div>
                  {details.booking_type !== "event" && (
                    <div
                      className="d-flex gap-1"
                      style={{ alignItems: "baseline" }}
                    >
                      <div style={{ color: "#FFC107" }}>
                        {details?.provider_id?.average_rating >= 0.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 1.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 2.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 3.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                        {details?.provider_id?.average_rating >= 4.5 ? (
                          <StarIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        )}
                      </div>
                      <p
                        style={{ fontSize: "14px", fontWeight: "600" }}
                        className="m-0"
                      >
                        ({Math.round(details?.provider_id?.average_rating)} / 5)
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="inner-gred box_shadow mt-2">
              {details.booking_type !== "event" ? (
                <>
                  <p className="t-18-bold">Booking Schedule</p>
                  {userAuth?._id !== details?.user_id?._id ? (
                    <div className="border-gred">
                      <div className="inner-gred" style={{ padding: "0px" }}>
                        <div className="d-flex align-items-center">
                          <div
                            className="p_image_shape me-2 me-sm-0"
                            style={{ transform: "scale(0.7)" }}
                          >
                            <img src={details?.user_id?.profile_picture || userIcon} onError={(e) => (e.target.src = userIcon)} />
                          </div>
                          <div>
                            <p className="m-0 t-16-bold">
                              {details?.user_id?.name}
                            </p>
                            <p className="m-0 t-10-bold">
                              {capital(details?.user_id?.user_type)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="border-gred">
                    <div className="inner-gred">
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold m-1">Date</p>*/}
                      {/*    <p className="t-12-bold m-1">{moment(details?.booking_date).format("D MMM YYYY")}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold m-1">Time</p>*/}
                      {/*    <p className="t-12-bold m-1">{moment(details?.start_date_time).format('LT')} to {moment(details.end_date_time).format('LT')}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold m-1">Type</p>*/}
                      {/*    <p className="t-12-bold m-1">{details?.service_type}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold mb-1">Location </p>*/}
                      {/*    <p className="t-12-bold mb-1">{details?.location_type}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-end">*/}
                      {/*    <p className="t-10-bold mt-1">{details?.service_id?.address}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex align-items-center">*/}
                      {/*    <p className="t-12-bold mb-1">{moment(details?.booking_date).format("D MMM YYYY")} , </p>*/}
                      {/*    <p className="t-12-bold mb-1">{moment(details?.start_date_time).utc().format('LT')} to {moment(details.end_date_time).utc().format('LT')}</p>*/}
                      {/*</div>*/}
                      <div className="d-flex align-items-center">
                        {/*<p className="t-12-bold mb-1">{DateFormate(LocalFormate(details?.start_date_time), LocalFormate(details.end_date_time))}, </p>*/}
                        <p className="t-12-bold mb-1">
                          {moment(
                            LocalFormate(
                              details?.booking_date ||
                                details?.event_id?.start_date,
                              details?.service_id?.date_timezone ||
                                details?.event_id?.date_timezone
                            )
                          ).format("MMM D YYYY")}
                          ,{" "}
                        </p>
                        <p className="t-12-bold mb-1">
                          {" "}
                          {moment(
                            LocalFormate(
                              details?.start_date_time,
                              details?.service_id?.date_timezone ||
                                details?.event_id?.date_timezone
                            )
                          ).format("LT")}{" "}
                          to{" "}
                          {moment(
                            LocalFormate(
                              details.end_date_time,
                              details?.service_id?.date_timezone ||
                                details?.event_id?.date_timezone
                            )
                          ).format("LT")}{" "}
                          (
                          {timeZoneFormate(
                            details?.start_date_time,
                            details?.service_id?.date_timezone ||
                              details?.event_id?.date_timezone
                          )}
                          )
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="t-12-bold mb-1">Type : </p>
                        <p className="t-12-bold mb-1 ms-1">
                          {capital(details?.service_type)}
                        </p>
                        <p></p>
                      </div>
                      {details?.service_type == "in_person" && (
                        <>
                          <div className="d-flex align-items-start">
                            <p
                              className="t-12-bold mb-1"
                              style={{ textWrap: "nowrap" }}
                            >
                              {details.location_type == "their_place"
                                ? "Their Place"
                                : "My Place"}{" "}
                              :{" "}
                            </p>
                            <p className="t-12-bold mb-1 ms-1">
                              {details?.address}
                            </p>
                          </div>
                        </>
                      )}
                      {details?.description && (
                        <div className="d-flex align-items-start">
                          <p
                            className="t-12-bold mb-1"
                            style={{ textWrap: "nowrap" }}
                          >
                            Location Note :{" "}
                          </p>
                          <p className="t-12-bold mb-1 ms-1">
                            {details?.description}
                          </p>
                          <p></p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : userAuth?._id !== details?.user_id?._id ? (
                <>
                  <p className="t-18-bold">
                    {details?.booking_type == "hire_provider"
                      ? "Hire Details"
                      : "Booking Schedule"}
                  </p>
                  <div className="border-gred">
                    <Link to={`/provider_details/${details?.user_id?._id}`}>
                    <div className="inner-gred" style={{ padding: "0px" }}>
                      <div className="d-flex align-items-center">
                        <div
                          className="p_image_shape me-2 me-sm-0"
                          style={{ transform: "scale(0.7)" }}
                        >
                          <img src={details?.user_id?.profile_picture || userIcon} onError={(e) => (e.target.src = userIcon)} />
                        </div>
                        <div>
                          <p className="m-0 t-16-bold">
                            {details?.user_id?.name}
                          </p>
                          <p className="m-0 t-10-bold">
                            {capital(details?.user_id?.user_type)}
                          </p>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                </>
              ) : null}

              <div>
                <p className="t-18-bold">Payment Details</p>

                <div className="border-gred mt-1">
                  <div className="inner-gred">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="t-12-bold m-1 ms-0">Participants</p>
                      <p className="t-12-bold m-1">
                        {details?.modify_booking_payment_info
                          ?.old_participate ||
                          details.no_of_participants ||
                          0}
                      </p>
                    </div>

                    {(
                      isModify
                        ? details?.payment_history_id?.discount_code_info
                            ?.is_discount == true &&
                          details?.payment_history_id?.discount_code_info
                            ?.is_admin_discount == false
                        : details?.modify_booking_payment_info?.is_discount ==
                            true &&
                          details?.modify_booking_payment_info
                            ?.is_admin_discount == false
                    ) ? (
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="d-flex flex-column">
                          <p className="t-12-bold m-1 ms-0">Package amount</p>
                          {/* <p
                            className="yelloe-t-bold t-14-500 m-0"
                            style={{ width: "92%" }}
                          >
                            You have received a{" "}
                            {isModify
                              ? details?.payment_history_id?.discount_percentage
                              : details?.modify_booking_payment_info
                                  ?.discount_percentage ?? 0}
                            % discount for using the discount code.
                          </p> */}
                        </div>
                        <div className="d-flex">
                          {/* <p
                            className="t-12-bold m-1"
                            style={{
                              color: "#9E9E9E",
                              textDecoration: "line-through",
                              whiteSpace: "nowrap",
                            }}
                          >
                            ${" "}
                            {Number(
                              (isModify
                                ? details?.payment_history_id
                                    ?.discount_code_info
                                    ?.amount_without_discount
                                : details?.modify_booking_payment_info
                                    ?.amount_without_discount) ?? 0
                            )?.toFixed(2) || 0.0}
                          </p> */}
                          <p
                            className="t-12-bold m-1"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ${" "}
                            {Number(
                              (isModify
                                ? details?.payment_history_id?.total_amount
                                : details?.booking_payment_info
                                    ?.total_amount) ?? 0
                            )?.toFixed(2) || 0.0}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1 ms-0">Package amount</p>
                        <p className="t-12-bold m-1">
                          ${" "}
                          {Number(
                            (isModify
                              ? details?.payment_history_id?.total_amount
                              : details?.booking_payment_info?.total_amount) ??
                              0
                          )?.toFixed(2) || 0.0}
                        </p>
                      </div>
                    )}

                    {/*<div className="d-flex justify-content-between align-items-start">*/}
                    {/*    <div>*/}
                    {/*        <p className="t-12-bold m-1 ms-0">Network fees*/}
                    {/*            ({details?.booking_payment_info?.fees_percentage_from_user || 0}%)</p>*/}
                    {/*        {details?.modify_booking_payment_info?.is_referee && isModify !== true ?*/}
                    {/*            <p className="yelloe-t-bold t-14-500 m-0" style={{width: "92%"}}>You*/}
                    {/*                have received*/}
                    {/*                a {details?.modify_booking_payment_info?.referee_fees_percentage ?? 0}%*/}
                    {/*                discount for using the referral code.</p> : null}*/}
                    {/*        {details?.payment_history_id?.referral_code_info?.is_refree ?*/}
                    {/*            <p className="yelloe-t-bold t-14-500 m-0"*/}
                    {/*               style={{width: "92%", wordBreak: "break-word"}}>You*/}
                    {/*                have received*/}
                    {/*                a {details?.payment_history_id?.referral_code_info?.display_user_fees_percentage ?? 0}%*/}
                    {/*                discount for using the referral code.</p> : null}*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex align-items-center">*/}
                    {/*        {isModify !== true && details?.modify_booking_payment_info?.is_referee ?*/}
                    {/*            <p className="t-12-bold m-1" style={{*/}
                    {/*                color: '#9E9E9E',*/}
                    {/*                textDecoration: 'line-through',*/}
                    {/*                whiteSpace: 'nowrap',*/}
                    {/*            }}>${Number(details?.modify_booking_payment_info?.referee_fees_amount_from_user).toFixed(2)}</p> : null}*/}
                    {/*        {details?.payment_history_id?.referral_code_info?.is_refree ?*/}
                    {/*            <p className="t-12-bold m-1" style={{*/}
                    {/*                color: '#9E9E9E',*/}
                    {/*                textDecoration: 'line-through',*/}
                    {/*                whiteSpace: 'nowrap',*/}
                    {/*            }}>${Number(details?.payment_history_id?.referral_code_info?.display_user_fees_amount).toFixed(2)}</p> : null}*/}
                    {/*        <p className="t-12-bold m-1"*/}
                    {/*           style={{whiteSpace: 'nowrap'}}>$ {Number((details?.payment_history_id?.fees_amount_from_user || details?.booking_payment_info?.fees_amount_from_user) ?? 0)?.toFixed(2) || 0.00}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {NormalPaymentInfo(userAuth, isModify, details)}
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="t-12-bold m-1 ms-0">
                        Tax (
                        {details?.booking_payment_info?.sales_tax_percentage ||
                          0}
                        %)
                      </p>
                      <p className="t-12-bold m-1">
                        ${" "}
                        {Number(
                          (details?.payment_history_id
                            ?.sales_tax_amount_from_user ||
                            details?.booking_payment_info
                              ?.sales_tax_amount_from_user) ??
                            0
                        )?.toFixed(2) || 0.0}
                      </p>
                    </div>
                    <hr style={{ border: "1px dashed black" }} />
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="t-12-bold m-1 ms-0">Total Amount</p>
                      <p className="t-12-bold m-1">
                        ${" "}
                        {Number(
                          (details?.payment_history_id?.total_paid_amount ||
                            details?.booking_payment_info?.total_paid_amount) ??
                            0
                        )?.toFixed(2) || 0.0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {isModify == true && details?.modify_booking_payment_info ? (
                <div>
                  <p className="t-18-bold">
                    {details?.modify_booking_payment_info?.is_refund
                      ? "Refund Payment Details"
                      : "Modify Payment Details"}
                  </p>

                  <div className="border-gred mt-1">
                    <div className="inner-gred">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1 ms-0">Participants</p>
                        <p className="t-12-bold m-1">
                          {Math.abs(
                            details?.modify_booking_payment_info
                              ?.new_participate
                          ) || 0}
                        </p>
                      </div>
                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold m-1">Package amount</p>*/}
                      {/*    <p className="t-12-bold m-1">$ {Number(details?.modify_booking_payment_info?.new_amount ?? 0)?.toFixed(2)}</p>*/}
                      {/*</div>*/}

                      {details?.modify_booking_payment_info?.is_discount ==
                        true &&
                      details?.modify_booking_payment_info?.is_admin_discount ==
                        false ? (
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="d-flex flex-column">
                            <p className="t-12-bold m-1 ms-0">Package amount</p>
                            <p
                              className="yelloe-t-bold t-14-500 m-0"
                              style={{ width: "92%" }}
                            >
                              You have received a{" "}
                              {details?.modify_booking_payment_info
                                ?.discount_percentage ?? 0}
                              % discount for using the discount code.
                            </p>
                          </div>
                          <div className="d-flex">
                            <p
                              className="t-12-bold m-1"
                              style={{
                                color: "#9E9E9E",
                                textDecoration: "line-through",
                                whiteSpace: "nowrap",
                              }}
                            >
                              ${" "}
                              {Number(
                                details?.modify_booking_payment_info
                                  ?.amount_without_discount ?? 0
                              )?.toFixed(2) || 0.0}
                            </p>
                            <p
                              className="t-12-bold m-1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              ${" "}
                              {Number(
                                details?.modify_booking_payment_info
                                  ?.new_amount ?? 0
                              )?.toFixed(2) || 0.0}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="t-12-bold m-1 ms-0">Package amount</p>
                          <p className="t-12-bold m-1">
                            ${" "}
                            {Number(
                              details?.modify_booking_payment_info
                                ?.new_amount ?? 0
                            )?.toFixed(2) || 0.0}
                          </p>
                        </div>
                      )}

                      {/*<div className="d-flex justify-content-between align-items-center">*/}
                      {/*    <p className="t-12-bold m-1">Network fees*/}
                      {/*        ({details?.modify_booking_payment_info?.fees_percentage_from_user || 0}%)</p>*/}
                      {/*    <p className="t-12-bold m-1">$ {Number(details?.modify_booking_payment_info?.fees_amount_from_user ?? 0)?.toFixed(2)}</p>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex justify-content-between align-items-start">*/}
                      {/*    <div>*/}
                      {/*        <p className="t-12-bold m-1 ms-0">Network fees*/}
                      {/*            ({details?.modify_booking_payment_info?.fees_percentage_from_user || 0}%)</p>*/}
                      {/*        {details?.modify_booking_payment_info?.is_referee ?*/}
                      {/*            <p className="yelloe-t-bold t-14-500 m-0" style={{width: "92%"}}>You*/}
                      {/*                have received*/}
                      {/*                a {details?.modify_booking_payment_info?.referee_fees_percentage ?? 0}%*/}
                      {/*                discount for using the referral code.</p> : null}*/}
                      {/*    </div>*/}
                      {/*    <div className="d-flex align-items-center">*/}
                      {/*        {details?.modify_booking_payment_info?.is_referee ?*/}
                      {/*            <p className="t-12-bold m-1" style={{*/}
                      {/*                color: '#9E9E9E',*/}
                      {/*                textDecoration: 'line-through',*/}
                      {/*                whiteSpace: 'nowrap',*/}
                      {/*            }}>$ {Number(details?.modify_booking_payment_info?.referee_fees_amount_from_user).toFixed(2)}</p> : null}*/}
                      {/*        <p className="t-12-bold m-1"*/}
                      {/*           style={{whiteSpace: 'nowrap'}}>$ {Number(details?.modify_booking_payment_info?.fees_amount_from_user ?? 0)?.toFixed(2)}</p>*/}

                      {/*    </div>*/}
                      {/*</div>*/}

                      {modifyNetworkFees({}, details, userAuth)}
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1 ms-0">
                          Tax (
                          {details?.modify_booking_payment_info
                            ?.sales_tax_percentage || 0}
                          %)
                        </p>
                        <p className="t-12-bold m-1">
                          ${" "}
                          {Number(
                            details?.modify_booking_payment_info
                              ?.sales_tax_amount_from_user ?? 0
                          )?.toFixed(2)}
                        </p>
                      </div>
                      <hr style={{ border: "1px dashed black" }} />
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="t-12-bold m-1 ms-0">Total Amount</p>
                        <p className="t-12-bold m-1">
                          ${" "}
                          {Number(
                            details?.modify_booking_payment_info
                              ?.new_total_paid_amount ?? 0
                          )?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="d-flex flex-wrap align-items-center justify-content-center">
                <button
                  style={{
                    maxWidth: "350px",
                    height: "45px",
                  }}
                  className="massageBtn mt-3"
                  onClick={() => {
                    HandleAcssept("accept", details._id);
                    setOpen(false);
                    setIsPayment(true);
                  }}
                  // onClick={() => navigate(`/book-session/${providerId}`)}
                >
                  Pay
                </button>
              </div>
            </div>
            {
              // ((userAuth?.user_type !== "user" && userAuth?._id !== details?.provider_id?._id) && (details?.booking_status == "accept")) ?
              //     <div className="w-100 d-flex">
              //         <div className="col-6">
              //             <button type="submit" className="btn_admina delete-button"
              //                     onClick={() => setIsOpen(true)} style={{lineHeight: "23px"}}>
              //                 {details.booking_type == "event" ? "Cancel event" : "Cancel Service"}
              //             </button>
              //         </div>
              //         <div className="col-6">
              //             <button
              //                 type="submit"
              //                 className="btn_admina delete-confirm-button"
              //                 style={{lineHeight: "23px"}}
              //             >
              //                 Modify
              //             </button>
              //         </div>
              //     </div> : (userAuth?.user_type !== "user" && (userAuth?._id == details?.provider_id?._id && details?.service_type !== "in_person" && details?.booking_status == "accept")) ?
              //
              //         <div className="w-100 d-flex">
              //             <div className="col-6">
              //                 <button
              //                     type="submit"
              //                     className="btn_admina delete-confirm-button"
              //                     style={{lineHeight: "23px"}}
              //                 >
              //                     Generate link
              //                 </button>
              //             </div>
              //         </div> : (userAuth?.user_type !== "user" && (details?.booking_status == "pending" )) && <div className="w-100 d-flex">
              //         <div className="col-6">
              //             <button type="submit" className="btn_admina delete-button"
              //                     style={{lineHeight: "23px"}} onClick={() => {
              //                 HandleAcssept("accept", details._id)
              //                 setOpen(false);
              //             }}>
              //                 Accept
              //             </button>
              //         </div>
              //         <div className="col-6">
              //             <button
              //                 type="submit"
              //                 className="btn_admina delete-confirm-button"
              //                 style={{lineHeight: "23px"}}
              //                 onClick={() => {
              //                     HandleAcssept("decline", details._id)
              //                     setOpen(false);
              //                 }}
              //             >
              //                 Decline
              //             </button>
              //         </div>
              //     </div>
              // userAuth?.user_type !== "user" &&  details.booking_status !== "pending" && (details.user_id == JSON.parse(localStorage.getItem("AdAnima_auth"))?._id && <div className="w-100 d-flex">
              //         <div className="col-6">
              //             <button type="submit" className="btn_admina delete-button"
              //                     style={{lineHeight: "23px"}} onClick={() => {
              //                 HandleAcssept("accept", details._id)
              //                 setOpen(false);
              //             }}>
              //                 Accept
              //             </button>
              //         </div>
              //         <div className="col-6">
              //             <button
              //                 type="submit"
              //                 className="btn_admina delete-confirm-button"
              //                 style={{lineHeight: "23px"}}
              //                 onClick={() => {
              //                     HandleAcssept("decline", details._id)
              //                     setOpen(false);
              //                 }}
              //             >
              //                 Decline
              //             </button>
              //         </div>
              //     </div>)
            }

            {
              // <div className="w-100 d-flex mt-2">
              //     <div className="col-12">
              //         <button type="submit" className="btn_admina delete-button"
              //                 style={{lineHeight: "6px", backgroundSize: '100% 55px'}} onClick={() => {
              //             HandleAcssept("accept", details._id)
              //             setOpen(false);
              //         }}>
              //             Pay
              //         </button>
              //     </div>
              // </div>
              // :
              //     ((details?.booking_status == "accept") && <div className="w-100 d-flex">
              //     <div className="col-6">
              //         <button type="submit" className="btn_admina delete-button"
              //                 onClick={() => {
              //                     // setOpen(false);
              //                     // setIsOpen(true)
              //                 }} style={{lineHeight: "23px"}}>
              //             {details.booking_type == "event" ? "Cancel event" : "Cancel Service"}
              //         </button>
              //     </div>
              //     <div className="col-6">
              //         <button
              //             type="submit"
              //             className="btn_admina delete-confirm-button"
              //             style={{lineHeight: "26px"}}
              //         >
              //             Modify
              //         </button>
              //     </div>
              // </div>)
            }
          </div>
        </div>
      </Modal>

      {isPayment ? (
        <Form
          data={data}
          open={isPayment}
          details={details}
          handleClose={handleClose}
          setOpen={setOpen}
          getNotificationsList={getNotificationsList}
        />
      ) : null}
    </>
  );
};

export default PaymentInfo;
