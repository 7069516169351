import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Box, Grid, IconButton } from "@mui/material";
import moment from "moment/moment";
import cardIcon from "../assets/images/cardIcon.svg";
import cardImg from "../assets/images/Cardimg.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WalletCardPopup from "./WalletCardPopup";
import { addMyPageDetails } from "../action/myPageConstant";
import PhoneInput from "react-phone-input-2";
import GuestHeader from "./GuestHeader";
import {
  bookEvent,
  bookEventRequest,
  getEvent,
  getGuestEvent,
  guestEventBooking,
  guestEventBookingRequest,
} from "../action/eventConstant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { systemCharge } from "../action/calenderConstant";
import { cardsLists, deleteCards, saveCards } from "../action/walletConstant";
import { toast } from "react-toastify";
import { guestsessionBooking } from "../action/myBokingConstant";
import BookingPopup from "./BookingPopup";
import { LocalFormate, timeZoneFormate } from "../Utils/DateFormateUtils";
import { get_referral_code_percentage } from "../Utils/reffercode";
import { getUserRefCode } from "../action/ReffDiscountConstant";
import Loader from "./Loader";
import { checkDiscountCode } from "../action/discountCodeConstant";
import { discountConstant } from "../action/constant";
import Form from "./Notification/Payment/PaymentScreen";
import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";

const referralData = (
  refCodeData,
  loginUserDetails,
  calendar,
  getSelectedPackage,
  calculationData,
  discountDetails
) => {
  // console.log(calculationData?.refreePercentage, "discountDetails")
  // console.log(discountDetails, "discountDetails")

  if (
    refCodeData?.referee?.is_expire == false &&
    discountDetails?.is_expire == true
  ) {
    return (
      <div className="d-flex justify-content-between mt-1">
        <div>
          <span className="review-Name">
            Network fees{" "}
            {loginUserDetails?.is_ambassador
              ? ""
              : `( ${calculationData?.refreePercentage}% )`}
          </span>
          <p className="yelloe-t-bold t-14-500 m-0">
            You have received a {refCodeData?.referee?.percentage}% discount for
            using the referral code.
          </p>
        </div>
        <span className="review-Name">
          <span
            className="me-2"
            style={{
              color: "#9E9E9E",
              textDecoration: "line-through",
            }}
          >
            ${" "}
            {!loginUserDetails?.is_ambassador
              ? getSelectedPackage()
                ? calculationData?.network
                : 0.0
              : 0.0}
          </span>
          <span>$ {calculationData?.refreeAmount}</span>
        </span>
      </div>
    );
  } else if (
    refCodeData?.referee?.is_expire == true &&
    discountDetails?.is_expire == false &&
    discountDetails?.is_admin_created == true
  ) {
    return (
      <div className="d-flex justify-content-between mt-1">
        <div>
          <span className="review-Name">
            Network fees{" "}
            {loginUserDetails?.is_ambassador
              ? ""
              : `( ${calculationData?.refreePercentage}% )`}
          </span>
          <p className="yelloe-t-bold t-14-500 m-0">
            You have received a {discountDetails?.discount_percentage}% discount
            for using the discount code.
          </p>
        </div>
        <span className="review-Name">
          <span
            className="me-2"
            style={{
              color: "#9E9E9E",
              textDecoration: "line-through",
            }}
          >
            ${" "}
            {!loginUserDetails?.is_ambassador
              ? getSelectedPackage()
                ? calculationData?.network
                : 0.0
              : 0.0}
          </span>
          <span>$ {calculationData?.refreeAmount}</span>
        </span>
      </div>
    );
  } else if (
    refCodeData?.referee?.is_expire == false &&
    discountDetails?.is_expire == false
  ) {
    if (discountDetails?.is_admin_created == false) {
      return (
        <div className="d-flex justify-content-between mt-1">
          <div>
            <span className="review-Name">
              Network fees{" "}
              {loginUserDetails?.is_ambassador
                ? ""
                : `( ${calculationData?.refreePercentage}% )`}
            </span>
            <p className="yelloe-t-bold t-14-500 m-0">
              You have received a {refCodeData?.referee?.percentage}% discount
              for using the referral code.
            </p>
          </div>
          <span className="review-Name">
            <span
              className="me-2"
              style={{
                color: "#9E9E9E",
                textDecoration: "line-through",
              }}
            >
              ${" "}
              {!loginUserDetails?.is_ambassador
                ? getSelectedPackage()
                  ? calculationData?.network
                  : 0.0
                : 0.0}
            </span>
            <span>$ {calculationData?.refreeAmount}</span>
          </span>
        </div>
      );
    } else if (discountDetails?.is_admin_created == true) {
      return (
        <div className="d-flex justify-content-between mt-1">
          <div>
            <span className="review-Name">
              Network fees{" "}
              {loginUserDetails?.is_ambassador
                ? ""
                : `( ${calculationData?.refreePercentage}% )`}
            </span>
            <p className="yelloe-t-bold t-14-500 m-0">
              You have received a{" "}
              {Number(refCodeData?.referee?.percentage ?? 0) +
                Number(discountDetails?.discount_percentage ?? 0)}
              % discount for using the referral and discount code.
            </p>
          </div>
          <span className="review-Name">
            <span
              className="me-2"
              style={{
                color: "#9E9E9E",
                textDecoration: "line-through",
              }}
            >
              ${" "}
              {!loginUserDetails?.is_ambassador
                ? getSelectedPackage()
                  ? calculationData?.network
                  : 0.0
                : 0.0}
            </span>
            <span>$ {calculationData?.refreeAmount}</span>
          </span>
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex justify-content-between mt-1">
        <div>
          <span className="review-Name">
            Network fees{" "}
            {loginUserDetails?.is_ambassador
              ? ""
              : `( ${calendar?.systemCharge?.tem_fees}% )`}
          </span>
        </div>
        <span className="review-Name">
          ${" "}
          {!loginUserDetails?.is_ambassador
            ? getSelectedPackage()
              ? calculationData?.network
              : 0.0
            : 0.0}
        </span>
      </div>
    );
  }
};

const EventBooking = () => {
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const navigate = useNavigate();
  const [walletMenu, setwalletMenu] = React.useState(null);
  const [isChecking, setIsCheching] = useState(false);
  const { id, packageId, participants, providerId } = useParams();
  const Walletopen = Boolean(walletMenu);
  const walletId = Walletopen ? "simple-popover" : undefined;
  const [CardSelectId, setCardSelectId] = useState("");
  const dispatch = useDispatch();
  const [btnLoader, setBtnLoader] = useState(false);
  const { event, calendar, wallet, refDescount, discountCode } = useSelector(
    (state) => state
  );
  const [calculationData, setCalculationData] = useState({});
  const [loader, setLoader] = useState(false);
  const [isDiscountCode, setIsDiscountCode] = useState("");
  const [discountDetails, setDiscountDetails] = useState({
    is_admin_created: null,
    is_expire: true,
    discount_percentage: 0,
    discount_code: null,
    _id: null,
    is_discount: false,
  });
  const [addCard, setAddCard] = useState({
    name: "",
    number: "",
    day: "",
    cvv: "",
  });
  const loginUserDetails = useSelector((state) => state.user);
  const sslector = useSelector((state) => state);
  const [fixTax, setFixTax] = useState({
    tax: 0,
    tax_id: 0,
  });
  const [stateName, setStateName] = useState("");
  const [guestUser, setGuestUser] = useState({
    name: "",
    email: "",
    mobile_number: "",
    country_code: "",
  });
  const handleWalletMenuClick = (event) => {
    setwalletMenu(event.currentTarget);
  };
  const [latlong, setLatlong] = useState({
    lat: "",
    long: "",
  });
  const momentZone = moment?.tz?.guess();
  const [stateNameA, setStateNameA] = useState({
    country: "",
    state: "",
  });

  // console.log(calculationData, "calculationData")

  const [refCodeData, setRefCodeData] = useState({
    referee: {
      id: null,
      is_referee: false,
      reward_type: null,
      percentage: null,
      is_expire: true,
    },
    referrer: {
      id: null,
      is_referee: false,
      reward_type: null,
      percentage: null,
      is_expire: true,
    },
  });

  const handleWalletMenuClose = () => {
    setwalletMenu(null);
  };

  const geteventInfo = () => {
    if (!userAuth?.token) {
      setLoader(true);
      dispatch(
        getGuestEvent(id, (a) => {
          setLoader(a);
        })
      );
    } else {
      setLoader(true);
      dispatch(
        getEvent(
          id,
          () => {},
          (a) => {
            setLoader(a);
          }
        )
      );
    }
    dispatch(systemCharge());
  };

  useEffect(() => {
    if (event?.bookedEvent?.success?.status == true) {
      setIsCheching(true);
    } else {
      setIsCheching(false);
    }
  }, [event]);

  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));

  function validateEmail(email) {
    // Regular expression for validating an Email
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const getMyStateTax = () => {
    calendar?.systemCharge?.sales_tax?.map((ele) => {
      if (ele?.unique_code == stateName) {
        // if (ele?.unique_code == "CA") {
        setFixTax({
          tax: ele?.tax_rate,
          tax_id: ele?._id,
        });
      }
    });
  };

  useEffect(() => {
    getMyStateTax();
  }, [calendar?.systemCharge, stateName]);

  const getDateNtime = (date, time) => {
    // console.log(date, time, 'event-time')
    const firstDate = moment(date);
    const secondDate = moment(time);
    // Calculate the duration between the two dates
    const duration = moment.duration(secondDate.diff(firstDate));
    // Add the duration to the first date
    const resultDate = firstDate.add(duration);

    return resultDate.toISOString();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;

              setLatlong((pre) => ({ ...pre, lat: latitude, long: longitude }));
              const googleMapApiKey = "AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs";

              const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapApiKey}`;
              const response = await fetch(apiUrl);

              if (response.ok) {
                const data = await response.json();

                // Check if the response contains results
                if (data.status === "OK") {
                  // Extract the state from the address components
                  const results = data.results || [];
                  for (const result of results) {
                    const addressComponents = result.address_components || [];
                    for (const component of addressComponents) {
                      const types = component.types || [];
                      if (types.includes("administrative_area_level_1")) {
                        // console.log(component, "component");
                        setStateNameA((pre) => ({
                          ...pre,
                          state: component?.long_name,
                        }));
                        // return;
                      }
                      if (types.includes("country")) {
                        // console.log(component, "component");
                        setStateNameA((pre) => ({
                          ...pre,
                          country: component?.long_name,
                        }));
                      }
                    }
                  }
                }
              } else {
                console.error(
                  `Failed to fetch data Status: ${response.status}`
                );
              }
            },
            (error) => {
              console.error("Error getting geolocation:", error);
            }
          );
        } else {
          console.log("Geolocation is not supported");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlebookEvent = (responseData) => {
    if (!stateName?.length) {
      openSiteInfo();
    } else {
      // setBtnLoader(true);
      if (!userAuth?.token && !validateEmail(guestUser.email)) {
        setBtnLoader(false);
        toast.error("Please Enter valid email");
      } else {
        if (!userAuth?.token) {
          const date = moment(addCard?.day?.split("/")[1], "YY");
          const year = date.format("YYYY");
          const selectedPackage = getSelectedPackage();
          const systemCharge = calendar?.systemCharge;
          const valuePerPerson = selectedPackage?.value_per_person || 0;
          const systemCharges =
            calendar?.systemCharge?.from_provider_percentage;
          const totalAmount = Number(
            getSelectedPackage()?.value_per_person * participants
          );
          const providerAmount = (totalAmount * systemCharges) / 100;
          var payload = {
            // booking_type: "event",
            // event_id: id,
            // provider_id: providerId,
            // no_of_participants: participants,
            // event_package_id: packageId,
            // total_amount: Number(getSelectedPackage()?.value_per_person * participants),
            // fees_percentage_from_user: userAuth?.is_ambassador ? Number(calendar?.systemCharge?.tem_fees) : Number(calendar?.systemCharge?.tem_fees) + Number(calendar?.systemCharge?.tem_tax),
            // fees_amount_from_user: texCount((Number(getSelectedPackage()?.value_per_person * participants)), Number(calendar?.systemCharge?.tem_fees), Number(calendar?.systemCharge?.tem_tax),
            // total_paid_amount: texCount((getSelectedPackage()?.value_per_person * participants), Number(calendar?.systemCharge?.tem_fees), Number(calendar?.systemCharge?.tem_tax), "amount"),
            // card_holder_name: addCard?.name,
            // card_number: addCard?.number,
            // expire_year: year,
            // expire_month: moment(addCard.day, 'MM/YY').format('MM'),
            // cvv: addCard.cvv,
            // is_guest_user: true,
            // name: guestUser?.name,
            // email_address: guestUser?.email,
            // phone_number: guestUser?.mobile_number,
            // country_code: guestUser?.country_code,
            // booking_date: event?.event?.start_date,
            // start_date_time: getDateNtime(event?.event?.start_date, event?.event?.start_time),
            // end_date_time: getDateNtime(event?.event?.end_date, event?.event?.end_time)
            booking_type: "event",
            event_id: id,
            provider_id: providerId,
            no_of_participants: participants,
            event_package_id: packageId,
            total_amount: calculationData?.AmountWithDiscount ?? 1,
            fees_percentage_from_user: loginUserDetails?.is_ambassador
              ? Number(0)
              : refCodeData?.referee?.is_expire
              ? calendar?.systemCharge?.tem_fees
              : calculationData?.refreePercentage,
            fees_amount_from_user: !loginUserDetails?.is_ambassador
              ? getSelectedPackage()
                ? refCodeData?.referee?.is_expire
                  ? calculationData?.network
                  : calculationData?.refreeAmount
                : 0.0
              : 0.0,
            total_paid_amount: calculationData?.TotalAmount ?? 1,
            card_holder_name: addCard?.name,
            card_number: addCard?.number,
            expire_year: year,
            expire_month: moment(addCard?.day, "MM/YY").format("MM"),
            cvv: addCard?.cvv,
            is_guest_user: true,
            name: guestUser?.name,
            email_address: guestUser?.email,
            phone_number: guestUser?.mobile_number,
            country_code: guestUser?.country_code,
            booking_date: event?.event?.start_date,
            start_date_time: getDateNtime(
              event?.event?.start_date,
              event?.event?.start_time
            ),
            end_date_time: getDateNtime(
              event?.event?.end_date,
              event?.event?.end_time
            ),
            sales_tax_percentage: Number(fixTax?.tax),
            sales_tax_amount_from_user: calculationData?.tax,
            sales_tax_id: fixTax?.tax_id,
            fees_percentage_from_provider:
              calculationData?.providerChargePercentage ?? 0,
            fees_amount_from_provider:
              calculationData?.providerChargeAmount ?? 0,
            modify_booking_payment_info: {
              referee_fees_amount_from_user: !loginUserDetails?.is_ambassador
                ? getSelectedPackage()
                  ? calculationData?.network
                  : 0.0
                : 0.0,
              is_referee: refCodeData?.referee?.is_referee,
              referee_fees_percentage: refCodeData?.referee?.percentage,
              is_discount: discountDetails?.is_discount,
              amount_without_discount: Number(valuePerPerson * participants),
              discount_amount: calculationData?.discountAmount ?? 0,
              discount_percentage: discountDetails?.discount_percentage,
              is_admin_discount: discountDetails?.is_admin_created,
            },
            state: stateNameA?.state,
            payment_date: new Date(),
            current_timezone: momentZone,
            location: {
              type: "Point",
              coordinates: [latlong?.long, latlong?.lat],
            },
            country: stateNameA?.country,
          };
          if (discountDetails?.is_discount) {
            payload.modify_booking_payment_info.discount_code_id =
              discountDetails?._id;
          }
          if (discountDetails?.is_discount) {
            payload.discount_code_id = discountDetails?._id;
          }
          if (!refCodeData?.referrer?.is_expire) {
            payload.modify_booking_payment_info.referer_referral_code_id =
              refCodeData?.referrer?.id;
          }
          if (!refCodeData?.referee?.is_expire) {
            payload.modify_booking_payment_info.referree_referral_code_id =
              refCodeData?.referee?.id;
          }
          // dispatch(guestEventBooking(payload, (a) => {
          //     setBtnLoader(a)
          // }))
          // dispatch(
          //   guestEventBookingRequest(payload, (a) => {
          //     setBtnLoader(a);
          //   })
          // );
          localStorage.setItem('EventBookingData', JSON.stringify(payload));

        } else {
          const systemCharge = calendar?.systemCharge?.from_provider_percentage;
          const totalAmount = Number(
            getSelectedPackage()?.value_per_person * participants
          );
          const providerAmount = (totalAmount * systemCharge) / 100;
          var payload = {
            booking_type: "event",
            event_id: id,
            provider_id: providerId,
            no_of_participants: participants,
            event_package_id: packageId,
            card_id: selectedWalletId,
            total_amount: calculationData?.AmountWithDiscount ?? 1,
            // stripe_charge_id: responseData?.latest_charge,
            // stripe_payment_intent_id: responseData?.id || responseData?.payment_intent,
            fees_percentage_from_user: loginUserDetails?.is_ambassador
              ? Number(0)
              : refCodeData?.referee?.is_expire
              ? calendar?.systemCharge?.tem_fees
              : calculationData?.refreePercentage,
            fees_amount_from_user: !loginUserDetails?.is_ambassador
              ? getSelectedPackage()
                ? refCodeData?.referee?.is_expire
                  ? calculationData?.network
                  : calculationData?.refreeAmount
                : 0.0
              : 0.0,
            total_paid_amount: calculationData?.TotalAmount ?? 1,
            booking_date: event?.event?.start_date,
            start_date_time: getDateNtime(
              event?.event?.start_date,
              event?.event?.start_time
            ),
            end_date_time: getDateNtime(
              event?.event?.end_date,
              event?.event?.end_time
            ),
            sales_tax_percentage: Number(fixTax?.tax),
            sales_tax_amount_from_user: calculationData?.tax,
            sales_tax_id: fixTax?.tax_id,
            fees_percentage_from_provider:
              calculationData?.providerChargePercentage ?? 0,
            fees_amount_from_provider:
              calculationData?.providerChargeAmount ?? 0,
            modify_booking_payment_info: {
              referee_fees_amount_from_user: !loginUserDetails?.is_ambassador
                ? getSelectedPackage()
                  ? calculationData?.network
                  : 0.0
                : 0.0,
              is_referee: refCodeData?.referee?.is_referee,
              referee_fees_percentage: refCodeData?.referee?.percentage,
              is_discount: discountDetails?.is_discount,
              amount_without_discount: Number(
                getSelectedPackage()?.value_per_person * participants
              ),
              discount_amount: calculationData?.discountAmount ?? 0,
              discount_percentage: discountDetails?.discount_percentage,
              is_admin_discount: discountDetails?.is_admin_created,
            },
            state: stateNameA?.state,
            payment_date: new Date(),
            current_timezone: momentZone,
            location: {
              type: "Point",
              coordinates: [latlong?.long, latlong?.lat],
            },
            country: stateNameA?.country,
          };
          if (discountDetails?.is_discount) {
            payload.modify_booking_payment_info.discount_code_id =
              discountDetails?._id;
          }
          if (discountDetails?.is_discount) {
            payload.discount_code_id = discountDetails?._id;
          }
          if (!refCodeData?.referrer?.is_expire) {
            payload.modify_booking_payment_info.referer_referral_code_id =
              refCodeData?.referrer?.id;
          }
          if (!refCodeData?.referee?.is_expire) {
            payload.modify_booking_payment_info.referree_referral_code_id =
              refCodeData?.referee?.id;
          }
          // dispatch(
          //   bookEventRequest(payload, (a) => {
          //     setBtnLoader(a);
          //   })
          // );

          localStorage.setItem("EventBookingData", JSON.stringify(payload));
        }
      }
    }
  };

  const handleDeleteCard = () => {
    dispatch(deleteCards(CardSelectId));
    setSelectedWalletId(null);
    setTimeout(() => {
      getAllCards();
    }, 500);
  };

  const getSelectedPackage = () => {
    const selectedPackage = event?.event?.package_details?.find((ele) => {
      if (ele._id == packageId) {
        return ele;
      }
    });

    return selectedPackage;
  };

  const texCount = (
    totalAmount,
    network,
    tax,
    key,
    refCodeData,
    discountDetails
  ) => {
    // setLoader(true)
    var TOTALAMOUNT = Number(totalAmount ?? 0);
    var discountAmount = 0;
    var discountPercent = discountDetails?.discount_percentage;
    if (discountDetails?.is_expire == false) {
      if (discountDetails?.is_admin_created == false) {
        let discAmount =
          Number(totalAmount ?? 0) *
          (Number(discountDetails?.discount_percentage ?? 0) / 100);
        discountAmount = discAmount;
        TOTALAMOUNT = Number(totalAmount ?? 0) - Number(discAmount ?? 0);
      }
    }

    // console.log(TOTALAMOUNT, "TOTALAMOUNT")

    // debugger;

    const NETWORK = Number(network ?? 0);
    const TAX = Number(tax ?? 0);
    const taxPercentage = Number(TAX ?? 0);
    const normaltex = Number(totalAmount ?? 0) * (taxPercentage / 100);
    const nexText = Number(totalAmount ?? 0) * (Number(NETWORK ?? 0) / 100);
    var plusText = loginUserDetails?.is_ambassador
      ? taxPercentage
      : taxPercentage + NETWORK;
    var systemCharges = Number(
      calendar?.systemCharge?.from_provider_percentage ?? 0
    );
    var refreeAmount = 0;
    var refreePercentages = 0;
    var refrrerAmount = 0;
    var refrrerPercentages = 0;
    // const totalAmount = Number(getOneService()?.session_price * details?.participants);
    var providerAmount = (Number(TOTALAMOUNT) * systemCharges) / 100;
    var providerPercent = Number(
      calendar?.systemCharge?.from_provider_percentage ?? 0
    );
    var networkPercentage = NETWORK;

    if (refCodeData?.referee?.is_expire == false) {
      const refPercentage =
        NETWORK - Number(refCodeData?.referee?.percentage ?? 0);
      // console.log(refPercentage, "refPercentage")
      if (Math.sign(refPercentage) > 0) {
        networkPercentage = refPercentage;
        refreeAmount = totalAmount * (refPercentage / 100);
        refreePercentages = refPercentage;
        plusText = loginUserDetails?.is_ambassador
          ? taxPercentage
          : taxPercentage + refPercentage;
      } else if (Math.sign(refPercentage) < 0) {
        networkPercentage = 100;
        refreeAmount = totalAmount * (0 / 100);
        refreePercentages = 0;
        plusText = loginUserDetails?.is_ambassador
          ? taxPercentage
          : taxPercentage + 0;
      }
    }
    if (refCodeData?.referrer?.is_expire == false) {
      const referePercentage =
        systemCharges - Number(refCodeData?.referrer?.percentage ?? 0);
      // console.log(refPercentage, "refPercentage")
      if (Math.sign(referePercentage) > 0) {
        refrrerAmount = (totalAmount * referePercentage) / 100;
        refrrerPercentages = referePercentage;
        providerAmount = (totalAmount * referePercentage) / 100;
        providerPercent = referePercentage;
      } else if (Math.sign(referePercentage) < 0) {
        refrrerAmount = totalAmount * (0 / 100);
        refrrerPercentages = 0;
        providerAmount = totalAmount * (0 / 100);
        providerPercent = 0;
      }
    }

    if (discountDetails?.is_expire == false) {
      if (discountDetails?.is_admin_created == true) {
        const refPercentage =
          networkPercentage - Number(discountDetails?.discount_percentage);
        if (Math.sign(refPercentage) > 0) {
          networkPercentage = refPercentage;
          refreeAmount = totalAmount * (refPercentage / 100);
          refreePercentages = refPercentage;
          plusText = loginUserDetails?.is_ambassador
            ? taxPercentage
            : taxPercentage + refPercentage;
        } else if (Math.sign(refPercentage) < 0) {
          networkPercentage = 100;
          refreeAmount = totalAmount * (0 / 100);
          refreePercentages = 0;
          plusText = loginUserDetails?.is_ambassador
            ? taxPercentage
            : taxPercentage + 0;
        }
      }
    }

    const taxDecimal = plusText / 100;
    var taxAmount = totalAmount * taxDecimal;
    var totalAmountWithTax = TOTALAMOUNT + taxAmount;
    // var totalAmountWithTax = TOTALAMOUNT + Number(nexText ?? 0) + Number(normaltex ?? 0);

    // console.log(TOTALAMOUNT , plusText, "nexText")

    setCalculationData((pre) => ({
      ...pre,
      tax: Number(normaltex ?? 0).toFixed(2),
      taxPercentage: Number(taxPercentage ?? 0),
      TotalAmount: Number(totalAmountWithTax ?? 0)?.toFixed(2),
      network: Number(nexText ?? 0).toFixed(2),
      netWorkPercentage: Number(NETWORK ?? 0),
      refreeAmount: Number(refreeAmount ?? 0).toFixed(2),
      refreePercentage: Number(refreePercentages ?? 0),
      reffrerAmount: Number(refrrerAmount ?? 0).toFixed(2),
      reffrerPercentage: Number(refrrerPercentages ?? 0),
      providerChargeAmount: Number(providerAmount ?? 0),
      providerChargePercentage: Number(providerPercent ?? 0),
      discountPercentage: discountPercent,
      discountAmount: discountAmount,
      AmountWithDiscount: TOTALAMOUNT,
    }));

    // console.log({ tax: Number(normaltex ?? 0).toFixed(2),
    //     taxPercentage: Number(taxPercentage ?? 0),
    //     TotalAmount: Number(totalAmountWithTax ?? 0)?.toFixed(2),
    //     network: Number(nexText ?? 0).toFixed(2),
    //     netWorkPercentage: Number(NETWORK ?? 0),
    //     refreeAmount: Number(refreeAmount ?? 0).toFixed(2),
    //     refreePercentage: Number(refreePercentages ?? 0),
    //     reffrerAmount: Number(refrrerAmount ?? 0).toFixed(2),
    //     reffrerPercentage: Number(refrrerPercentages ?? 0),
    //     providerChargeAmount: Number(providerAmount ?? 0),
    //     providerChargePercentage: Number(providerPercent ?? 0),
    //     discountPercentage: discountPercent,
    //     discountAmount: discountAmount,
    //     AmountWithDiscount: TOTALAMOUNT}, "Fees")

    setTimeout(() => {
      setLoader(false);
    }, 1000);

    // if (key == "tax") {
    //     return taxAmount?.toFixed(2)
    // } else if (key == "amount") {
    //     return totalAmountWithTax?.toFixed(2)
    // } else if (key == "network") {
    //     return nexText.toFixed(2)
    // } else if (key == "oneTax") {
    //     return normaltex.toFixed(2)
    // } else if (key == "ref") {
    //     return refTex.toFixed(2)
    // } else {
    //     return "00"
    // }
  };

  // console.log(discountDetails, "discountDetails")

  useEffect(() => {
    texCount(
      Number(getSelectedPackage()?.value_per_person * participants),
      Number(calendar?.systemCharge?.tem_fees),
      Number(fixTax?.tax),
      "network",
      refCodeData,
      discountDetails
    );
  }, [getSelectedPackage(), refCodeData, discountDetails, calendar, fixTax]);

  const handleAddCard = () => {
    const date = moment(addCard?.day?.split("/")[1], "YY");
    const year = date.format("YYYY");
    const payload = {
      name: addCard.name,
      number: addCard.number,
      year: year,
      month: moment(addCard.day, "MM/YY").format("MM"),
      cvv: addCard.cvv,
    };
    dispatch(saveCards(payload));
    setAddCard({
      name: "",
      number: "",
      day: "",
      cvv: "",
    });
    // setTimeout(() => {
    //     getAllCards()
    // }, 500);
  };

  const getPackageName = (id) => {
    const name = event?.event?.package_details?.find((ele) => {
      if (ele._id == id) {
        return ele;
      }
    });

    return name?.package_name;
  };

  const cardNumberReplace = (number) => {
    const numberStr = number.replace(/.(?=.{4})/g, "X");
    return numberStr;
  };

  const getAllCards = () => {
    if (userAuth?.token) {
      dispatch(cardsLists());
    }
  };

  useEffect(() => {
    getSelectedPackage();
  }, [event]);

  useEffect(() => {
    geteventInfo();
    getAllCards();
    return () => {
      setIsCheching(false);
    };
  }, []);

  const [isPayment, setIsPayment] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;

              const googleMapApiKey = "AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs";

              const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapApiKey}`;
              const response = await fetch(apiUrl);

              if (response.ok) {
                const data = await response.json();

                // Check if the response contains results
                if (data.status === "OK") {
                  // Extract the state from the address components
                  const results = data.results || [];
                  for (const result of results) {
                    const addressComponents = result.address_components || [];
                    for (const component of addressComponents) {
                      const types = component.types || [];
                      if (types.includes("administrative_area_level_1")) {
                        setStateName(component.short_name);
                        return;
                      }
                    }
                  }
                }
              } else {
                console.error(
                  `Failed to fetch data Status: ${response.status}`
                );
              }
            },
            (error) => {
              console.error("Error getting geolocation:", error);
            }
          );
        } else {
          console.log("Geolocation is not supported");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const openSiteInfo = () => {
    alert(
      "Please provide location permission.\nTo manage location permissions, click on the lock icon next to the website's URL in the browser's address bar."
    );
    // For Firefox:
    // window.location = 'about:preferences#privacy';
  };

  const checkRefCode = () => {
    if (userAuth?.token) {
      dispatch(getUserRefCode(providerId));
    }
  };

  useEffect(() => {
    checkRefCode();
  }, [providerId]);

  useEffect(() => {
    if (refDescount?.userRefCodeData) {
      get_referral_code_percentage(
        refDescount?.userRefCodeData,
        setRefCodeData
      );
    }
  }, [refDescount?.userRefCodeData]);

  const handleCheckdiscountCode = () => {
    setLoader(true);
    const payload = {
      discount_code_string: isDiscountCode, // provider_id: userDetails?.details?._id,
      event_id: event?.event?._id,
      provider_id: event?.event?.user_id?._id,
      is_event: true,
    };
    dispatch(
      checkDiscountCode(payload, () => {
        setLoader(false);
      })
    );
  };

  const handleSetDiscount = () => {
    if (discountCode?.checkCodeDetails?.discount_code) {
      setDiscountDetails({
        is_admin_created: discountCode?.checkCodeDetails?.is_admin_created,
        is_expire: false,
        discount_percentage:
          discountCode?.checkCodeDetails?.discount_percentage,
        discount_code: discountCode?.checkCodeDetails?.discount_code,
        _id: discountCode?.checkCodeDetails?._id,
        is_discount: true,
      });
    } else {
      setDiscountDetails({
        is_admin_created: false,
        is_expire: true,
        discount_percentage: 0,
        discount_code: null,
        _id: null,
        is_discount: false,
      });
    }
  };

  const clianData = () => {
    setDiscountDetails({
      is_admin_created: false,
      is_expire: true,
      discount_percentage: 0,
      discount_code: null,
      _id: null,
    });
  };

  useEffect(() => {
    return () => {
      clianData();
      dispatch({
        type: discountConstant.CLEAN_DISCOUNT_CODE,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    handleSetDiscount();
  }, [discountCode?.checkCodeDetails]);

  return (
    <>
      <div>
        <section className="main-page">
          {!userAuth?.token ? <GuestHeader /> : <Header select={"Home"} />}
          <div className="main-inner">
            <div className="container">
              <div
                style={{
                  height: "calc(100vh - 227px)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p className="t-30-bold">Event Booking</p>
                  {!userAuth?.token && (
                    <div className="inner-gred  box_shadow mb-5">
                      <p className="m-0 t-24-bold">Basic Account Info </p>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className="group-input mt-3">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.25 6C15.25 7.79493 13.7949 9.25 12 9.25V10.75C14.6234 10.75 16.75 8.62335 16.75 6H15.25ZM12 9.25C10.2051 9.25 8.75 7.79493 8.75 6H7.25C7.25 8.62335 9.37665 10.75 12 10.75V9.25ZM8.75 6C8.75 4.20507 10.2051 2.75 12 2.75V1.25C9.37665 1.25 7.25 3.37665 7.25 6H8.75ZM12 2.75C13.7949 2.75 15.25 4.20507 15.25 6H16.75C16.75 3.37665 14.6234 1.25 12 1.25V2.75ZM9 13.75H15V12.25H9V13.75ZM15 20.25H9V21.75H15V20.25ZM9 20.25C7.20507 20.25 5.75 18.7949 5.75 17H4.25C4.25 19.6234 6.37665 21.75 9 21.75V20.25ZM18.25 17C18.25 18.7949 16.7949 20.25 15 20.25V21.75C17.6234 21.75 19.75 19.6234 19.75 17H18.25ZM15 13.75C16.7949 13.75 18.25 15.2051 18.25 17H19.75C19.75 14.3766 17.6234 12.25 15 12.25V13.75ZM9 12.25C6.37665 12.25 4.25 14.3766 4.25 17H5.75C5.75 15.2051 7.20507 13.75 9 13.75V12.25Z"
                                  fill="#363853"
                                />
                              </svg>
                              <input
                                type="text"
                                required
                                placeholder="Full Name"
                                value={guestUser.name.replace(/\d/g, "")}
                                onChange={(e) => {
                                  if (e.target.value.length <= 25) {
                                    setGuestUser((pre) => ({
                                      ...pre,
                                      name: e.target.value,
                                    }));
                                  }
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={6}>
                            <div className="group-input mt-3">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="Icons">
                                  <path
                                    id="Vector"
                                    d="M2.88539 8.84875C3.55805 6.13983 5.70602 4.04534 8.43056 3.44162L8.88443 3.34105C10.9366 2.88632 13.0634 2.88632 15.1156 3.34105L15.5694 3.44162C18.294 4.04534 20.442 6.13984 21.1146 8.84875C21.6285 10.9182 21.6285 13.0819 21.1146 15.1512C20.442 17.8602 18.294 19.9547 15.5694 20.5584L15.1156 20.659C13.0634 21.1137 10.9366 21.1137 8.88443 20.659L8.43056 20.5584C5.70601 19.9547 3.55805 17.8602 2.88539 15.1513C2.37154 13.0819 2.37154 10.9181 2.88539 8.84875Z"
                                    stroke="#363853"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M21.1146 15.1512C21.6285 13.0819 21.6285 10.9182 21.1146 8.84875C20.442 6.13984 18.294 4.04534 15.5694 3.44162L15.1156 3.34105C13.0634 2.88632 10.9366 2.88632 8.88443 3.34105L8.43056 3.44162C5.70602 4.04534 3.55805 6.13983 2.88539 8.84875C2.37154 10.9181 2.37154 13.0819 2.88539 15.1513C3.55805 17.8602 5.70601 19.9547 8.43056 20.5584L8.88443 20.659"
                                    stroke="#363853"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    id="Vector_3"
                                    d="M8.15112 10.3501L10.7216 12.1866C11.4864 12.7329 12.5136 12.7329 13.2783 12.1866L15.8489 10.3501"
                                    stroke="#363853"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                  />
                                </g>
                              </svg>
                              <input
                                type="email"
                                required
                                placeholder="Email Address"
                                value={guestUser.email}
                                onChange={(e) => {
                                  setGuestUser((pre) => ({
                                    ...pre,
                                    email: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={6}>
                            <div className="group-input mt-3">
                              <PhoneInput
                                placeholder="Phone Number"
                                enableSearch={true}
                                value={`${guestUser.country_code}${guestUser.mobile_number}`}
                                onChange={(phone, data) => {
                                  setGuestUser((pre) => ({
                                    ...pre,
                                    mobile_number: phone.slice(
                                      data?.dialCode?.length
                                    ),
                                    country_code: data.dialCode,
                                  }));
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  )}

                  <div className="inner-gred box_shadow mt-4">
                    <h3>Payment Details </h3>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6} className="mt-3">
                        <Grid item xs={12} md={12} className="mt-0">
                          <span>Booking Details</span>
                          <div className="border-gred w-100 mt-2">
                            <div className="inner-gred w-100">
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">Date</span>
                                <span className="review-Name">
                                  {moment(
                                    LocalFormate(
                                      event?.event?.start_date,
                                      event?.event?.date_timezone
                                    )
                                  ).format("D MMMM YYYY")}{" "}
                                </span>
                              </div>
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">Time</span>
                                <span className="review-Name">
                                  {moment(
                                    LocalFormate(
                                      event?.event?.start_time,
                                      event?.event?.date_timezone
                                    )
                                  ).format("LT")}{" "}
                                  to{" "}
                                  {moment(
                                    LocalFormate(
                                      event?.event?.end_time,
                                      event?.event?.date_timezone
                                    )
                                  ).format("LT")}{" "}
                                  (
                                  {timeZoneFormate(
                                    event?.event?.start_time,
                                    event?.event?.date_timezone
                                  )}
                                  )
                                </span>
                              </div>
                              {event?.event?.is_virtual_event ? (
                                <div className="d-flex justify-content-between mt-1">
                                  <span className="review-Name">
                                    Event Type
                                  </span>
                                  <span
                                    className="review-Name"
                                    style={{
                                      width: "75%",
                                      textAlign: "end",
                                    }}
                                  >
                                    Virtual
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-between mt-1">
                                  <span className="review-Name">Location</span>
                                  <span
                                    className="review-Name"
                                    style={{
                                      width: "75%",
                                      textAlign: "end",
                                    }}
                                  >
                                    {event?.event?.address}
                                  </span>
                                </div>
                              )}
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">Package</span>
                                <span
                                  className="review-Name"
                                  style={{
                                    width: "75%",
                                    textAlign: "end",
                                  }}
                                >
                                  {getPackageName(packageId)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12} className="mt-3">
                          <div>
                            <div
                              className="border-gred"
                              style={{ padding: "2px" }}
                            >
                              <div
                                className="session-input d-flex"
                                style={{
                                  padding: "0px 0px 0px 10px",
                                  height: "44px",
                                }}
                              >
                                <div className="p-1 w-100 d-flex">
                                  <input
                                    type="text"
                                    disabled={
                                      discountDetails?.is_expire == false
                                    }
                                    value={isDiscountCode}
                                    placeholder="Enter Discount code"
                                    className="showArrow"
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                      setIsDiscountCode(e.target.value);
                                    }}
                                  />
                                </div>
                                {/*{discountDetails?.is_expire == true ? */}
                                <button
                                  disabled={discountDetails?.is_expire == false}
                                  onClick={() => handleCheckdiscountCode()}
                                  style={{
                                    background:
                                      "linear-gradient(180deg, #0DBCEC 0%, #FFC056 100%)",
                                    padding: "8px 26px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Apply
                                </button>
                                {/*: null}*/}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={6} className="mt-3">
                        <span>Payment Details </span>
                        <div className="border-gred w-100 mt-2">
                          <div className="inner-gred w-100">
                            <div className="d-flex justify-content-between mt-1">
                              <span className="review-Name">Participants</span>
                              <span className="review-Name">
                                {participants}
                              </span>
                            </div>
                            {/*<div className="d-flex justify-content-between mt-1">*/}
                            {/*    <span className="review-Name">Amount </span>*/}
                            {/*    <span*/}
                            {/*        className="review-Name">$ {getSelectedPackage() ? (getSelectedPackage()?.value_per_person * participants)?.toFixed(2) : 0.00}</span>*/}
                            {/*</div>*/}

                            {discountDetails?.is_expire == false &&
                            discountDetails?.is_admin_created == false ? (
                              <div className="d-flex justify-content-between mt-1">
                                <div className="d-flex flex-column">
                                  <span className="review-Name">Amount </span>
                                  <p className="yelloe-t-bold t-14-500 m-0">
                                    You have received a{" "}
                                    {discountDetails?.discount_percentage ?? 0}%
                                    discount for using the discount code.
                                  </p>
                                </div>
                                <div className="d-flex gap-2">
                                  <span
                                    style={{
                                      color: "#9E9E9E",
                                      textDecoration: "line-through",
                                    }}
                                    className="review-Name"
                                  >
                                    ${" "}
                                    {getSelectedPackage()
                                      ? (
                                          getSelectedPackage()
                                            ?.value_per_person * participants
                                        )?.toFixed(2)
                                      : 0.0}
                                  </span>
                                  <span className="review-Name">
                                    ${" "}
                                    {getSelectedPackage()
                                      ? calculationData?.AmountWithDiscount?.toFixed(
                                          2
                                        )
                                      : 0.0}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">Amount </span>
                                <span className="review-Name">
                                  ${" "}
                                  {getSelectedPackage()
                                    ? (
                                        getSelectedPackage()?.value_per_person *
                                        participants
                                      )?.toFixed(2)
                                    : 0.0}
                                </span>
                              </div>
                            )}

                            {/*    <div className="d-flex justify-content-between mt-1">*/}
                            {/*<span*/}
                            {/*    className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${calendar?.systemCharge?.tem_fees}% )`}</span>*/}
                            {/*        /!*<span*!/*/}
                            {/*        /!*    className="review-Name">$ {!loginUserDetails?.is_ambassador ? getSelectedPackage() ? texCount((Number(getSelectedPackage()?.value_per_person * participants)), Number(calendar?.systemCharge?.tem_fees), Number(fixTax?.tax), "network") : 0.00 : 0.00}</span>*!/*/}
                            {/*    </div>*/}
                            {/*<div className="d-flex justify-content-between mt-1">*/}
                            {/*<div>*/}
                            {/*    <span*/}
                            {/*        className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${refCodeData?.referee?.is_expire ? calendar?.systemCharge?.tem_fees : calculationData?.refreePercentage}% )`}</span>*/}
                            {/*    {refCodeData?.referee?.is_expire ? null :*/}
                            {/*        <p className="yelloe-t-bold t-14-500 m-0">You have*/}
                            {/*            received a {refCodeData?.referee?.percentage}%*/}
                            {/*            discount for using the referral*/}
                            {/*            code.</p>}*/}
                            {/*</div>*/}
                            {/*{refCodeData?.referee?.is_expire ? <span*/}
                            {/*        className="review-Name">$ {!loginUserDetails?.is_ambassador ? getSelectedPackage() ? calculationData?.network : 0.00 : 0.00}</span> :*/}
                            {/*    <span className="review-Name">*/}
                            {/*        <span className="me-2" style={{*/}
                            {/*            color: '#9E9E9E', textDecoration: 'line-through'*/}
                            {/*        }}>$ {!loginUserDetails?.is_ambassador ? getSelectedPackage() ? calculationData?.network : 0.00 : 0.00}</span>*/}
                            {/*        <span>$ {calculationData?.refreeAmount}</span>*/}
                            {/*    </span>}*/}
                            {referralData(
                              refCodeData,
                              loginUserDetails,
                              calendar,
                              getSelectedPackage,
                              calculationData,
                              discountDetails
                            )}
                            {/*</div>*/}
                            <div className="d-flex justify-content-between mt-1">
                              <span className="review-Name">
                                Tax {`( ${fixTax?.tax}% )`}
                              </span>
                              {/*<span*/}
                              {/*    className="review-Name">$ {getSelectedPackage() ? texCount((Number(getSelectedPackage()?.value_per_person * participants)), Number(calendar?.systemCharge?.tem_fees), Number(fixTax?.tax), "oneTax") : 0.00}</span>*/}

                              {/*<span*/}
                              {/*    className="review-Name">$ {texCount((Number(getSelectedPackage()?.value_per_person * participants)), Number(calendar?.systemCharge?.tem_fees), Number(fixTax?.tax), "oneTax") }</span>*/}
                              <span className="review-Name">
                                ${" "}
                                {getSelectedPackage()
                                  ? calculationData?.tax
                                  : 0.0}
                              </span>
                            </div>

                            <hr style={{ border: "1px dashed black" }} />
                            <div className="d-flex justify-content-between mt-1">
                              <span className="review-Name">Total Amount</span>
                              <span className="review-Name">
                                ${" "}
                                {getSelectedPackage()
                                  ? calculationData?.TotalAmount
                                  : 0.0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">
                  <button
                    className="btn_White ms-lg-auto"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>

                  {btnLoader ? (
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className="btn_admina "
                    >
                      {/* save */}
                      <div className="loader"></div>
                    </a>
                  ) : (
                    <button
                      className="btn_admina"
                      onClick={async () => {
                        if (!userAuth?.token) {
                          if (!validateEmail(guestUser.email) || guestUser.name == "" || guestUser.mobile_number == "" ) {
                            if(!validateEmail(guestUser.email) ){

                              toast.error("Please Enter valid email.");
                            }else{
                              toast.error("Please Fill all details.");
                            }
                          } else {
                            const fdata = new FormData();
                            fdata.append("email_address", guestUser?.email);

                            fetch(
                              `${BASE_URL}${APP_VERSION}${URL_PATH.check_mail}`,
                              {
                                method: "POST",
                                body: fdata,
                              }
                            )
                              .then((response) => response.json())
                              .then((data) => {
                                if (data.success) {
                                  setIsPayment(true);
                                  handlebookEvent();
                                } else {
                                  toast.error(data.message);
                                }
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }
                        } else {
                          handlebookEvent();
                          if (event?.event?.is_booked_already) {
                            toast.error("You have already booked this event.");
                          } else {
                            setIsPayment(true);
                          }
                        }
                      }}
                    >
                      Check Out
                    </button>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </section>
      </div>
      <WalletCardPopup
        id={walletId}
        anchorEl={walletMenu}
        setwalletMenu={setwalletMenu}
        handleClose={handleWalletMenuClose}
        open={Walletopen}
        handleDeleteCard={handleDeleteCard}
      />
      {/* <BookingPopup open={isChecking} setOpen={setIsCheching} /> */}
      {loader && <Loader />}
      {isPayment ? (
        <Form
          //   data={data}
          open={isPayment}
          details={{
            ...event?.event,
            total_paid_amount: calculationData?.TotalAmount ?? 0,
            calculationData: calculationData,
          }}
          handleClose={() => setIsPayment(false)}
          setOpen={() => {}}
          getNotificationsList={() => {}}
          paymentSuccessful={(responseData) => {
            handlebookEvent(responseData);
          }}
          isEvent={true}
        />
      ) : null}
    </>
  );
};
export default EventBooking;
