import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import { Box, Grid, IconButton } from "@mui/material";
import locationIcon from "../assets/images/Discovery.svg";
import ShareIcon from "../assets/images/shareIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EventSharePopup from "../Component/EventSharePopup";
import { useNavigate } from "react-router-dom";
import ComingSoon from "../Component/ComingSoon";
import soonImg from "../assets/images/c_soon.png";
import { getEvents, getMoreEvents } from "../action/myEventConstant";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../Utils/DateFormateUtils";
import { capital } from "../Utils/capitalUtils";
import { getMoreMyBookingDetails } from "../action/myBokingConstant";
import InfiniteScroll from "react-infinite-scroll-component";
import { Empty } from "antd";
import EmptyFile from "../Component/EmptyFile";
import EventLinkPopup from "../Component/EventLinkPopup";
import BankAlartPopup from "../Component/BankAlartPopup";
import BankView from "./Bank/BankView";
import Loader from "../Component/Loader";

const Events = () => {
  const [isShare, setShare] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { myEvent } = useSelector((state) => state);
  const [pagePagination, setPagePagination] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [shareId, setShareId] = useState();
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const [isBankAlart, setIsBankAlart] = useState(false);
  const [openBankInfo, setOpenBankInfo] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isTabSelect, setIsTabSelect] = useState("upcoming");

  const getALlEvent = () => {
    setLoader(true);
    const payload = {
      page: 1,
      limit: 10,
      isTabSelect: isTabSelect,
    };
    dispatch(
      getEvents(payload, (a) => {
        setTimeout(() => {
          setLoader(a);
        }, 200);
      })
    );
  };

  useEffect(() => {
    getALlEvent();
    setPagePagination(2);
  }, [isTabSelect]);

  const fetchmoreData = () => {
    const payload = {
      page: pagePagination,
      limit: 10,
      isTabSelect: isTabSelect,
    };
    dispatch(
      getMoreEvents(payload, (e) => {
        setHasMore(e);
      })
    );

    setPagePagination((pre) => pre + 1);
  };

  return (
    <>
      <div>
        <InfiniteScroll
          dataLength={myEvent?.myEventData}
          next={fetchmoreData}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          className="infinity-list"
        >
          <section className="main-page">
            <Header select={"My Events"} />
            <div className="main-inner">
              <div className="container">
                <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mb-3">
                  <button
                    className="btn_admina ms-lg-auto"
                    onClick={() => {
                      // if (!userAuth?.bank_account_id) {
                      //     setIsBankAlart(true)
                      // } else {
                      navigate("/create-event");
                      // }
                    }}
                  >
                    Create New Event
                  </button>
                </Box>
                <div className="walletTabsdiv mb-4">
                  <div
                    className={`wallet_tabs mt-1 ${
                      isTabSelect == "upcoming" ? "wallet_tabs_select" : ""
                    }`}
                    onClick={() => setIsTabSelect("upcoming")}
                  >
                    <p>Upcoming</p>
                  </div>
                  <div
                    className={`wallet_tabs mt-1 ${
                      isTabSelect == "past" ? "wallet_tabs_select" : ""
                    }`}
                    onClick={() => setIsTabSelect("past")}
                  >
                    <p>Past</p>
                  </div>
                  <div
                    className={`wallet_tabs mt-1 ${
                      isTabSelect == "cancel" ? "wallet_tabs_select" : ""
                    }`}
                    onClick={() => setIsTabSelect("cancel")}
                  >
                    <p>Cancelled</p>
                  </div>
                </div>
                <Grid container spacing={2}>
                  {myEvent?.myEventData?.length ? (
                    <>
                      {myEvent?.myEventData?.map((ele, i) => (
                        <Grid item xs={12} md={6} key={i}>
                          <div
                            className="inner-gred grey_border box_shadow position-relative"
                            style={{
                              background:
                                ele?.user_id !== userAuth?._id
                                  ? "#EFF3F7"
                                  : "white",
                            }}
                          >
                            <div
                              onClick={() => {
                                if (isTabSelect !== "cancel") {
                                  navigate(`/my_events/${ele?._id}`);
                                }
                              }}
                              style={{ cursor: isTabSelect !== "cancel" && "pointer" }}
                            >
                              <div className="d-flex justify-content-between align-items-center mb-1">
                                <p className="t-20-bold m-0">
                                  {ele?.event_name}
                                </p>
                              </div>
                              {ele?.is_virtual_event ? (
                                <p className="m-0 t-16-bold mb-1">
                                  Type: Virtual Event
                                </p>
                              ) : ele?.address ? (
                                <div className="d-flex gap-2 align-items-center mb-1">
                                  <img src={locationIcon} alt="" />
                                  <p className="m-0 t-16-bold">{ele?.address}</p>
                                </div>
                              ) : null}
                              <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center gap-1">
                                  <p className="m-0 t-11-500">
                                    {DateFormate(
                                      LocalFormate(
                                        ele?.start_date,
                                        ele?.date_timezone
                                      ),
                                      LocalFormate(
                                        ele?.end_date,
                                        ele?.date_timezone
                                      )
                                    )}
                                  </p>
                                  <FiberManualRecordIcon
                                    style={{
                                      transform: "scale(0.5)",
                                      color: "#919191",
                                    }}
                                  />
                                  <p className="m-0 t-11-500">
                                    {moment(
                                      LocalFormate(
                                        ele?.start_time,
                                        ele?.date_timezone
                                      )
                                    )
                                      .local()
                                      .format("LT")}{" "}
                                    to{" "}
                                    {moment(
                                      LocalFormate(
                                        ele?.end_time,
                                        ele?.date_timezone
                                      )
                                    )
                                      .local()
                                      .format("LT")}{" "}
                                    (
                                    {timeZoneFormate(
                                      ele?.start_time,
                                      ele?.date_timezone
                                    )}
                                    )
                                  </p>
                                </div>
                                <p className="m-0 t-16-bold">
                                  {capital(ele?.event_type)}
                                </p>
                              </div>
                            </div>
                            <div
                              className="position-absolute"
                              style={{
                                top: "20px",
                                right: "10px",
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  setShareId(ele?._id);
                                  setShare(true);
                                }}
                              >
                                <img src={ShareIcon} alt="" width="21px" />
                              </IconButton>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* <EmptyFile /> */}
                      <div
                        className="d-flex align-items-center justify-content-center w-100"
                        style={{ height: "60vh" }}
                      >
                        <div style={{ transform: "scale(1.8)" }}>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>No Data Found</span>}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Grid>
              </div>
            </div>
            {/*<div className="main-inner">*/}
            {/*    <div className="container" style={{*/}
            {/*        textAlign: "center",*/}
            {/*        height: "calc(100vh - 220px)",*/}
            {/*        display: "flex",*/}
            {/*        flexDirection: "column",*/}
            {/*        justifyContent: "center",*/}
            {/*    }}>*/}
            {/*        <div>*/}
            {/*            <img src={soonImg} style={{ width: "350px" }} />*/}
            {/*        </div>*/}
            {/*        <h1 className="d-inline-block" style={{ fontSize: "55px" }}>COMING SOON!</h1>*/}
            {/*        <p className="para mb-3 mb-lg-5" style={{ fontSize: "20px" }}>*/}
            {/*            Thank you for joining us! Brace yourself for an array of thrilling features – coming soon to AD ANIMA. <br />Your wait will soon be rewarded with innovation and excitement!*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </section>
        </InfiniteScroll>
      </div>

      {/*    ...popup.....   */}

      {shareId && (
        <EventSharePopup
          setOpen={setShare}
          open={isShare}
          typeSharing={"event_type"}
          path={"view-events"}
          providerId={userAuth?._id}
          eventId={shareId}
        />
      )}
      <BankAlartPopup
        setOpen={setIsBankAlart}
        open={isBankAlart}
        setOpenBankInfo={setOpenBankInfo}
        isEvent={true}
      />
      <BankView setOpenBankInfo={setOpenBankInfo} openBankInfo={openBankInfo} />
      {loader && <Loader />}
    </>
  );
};

export default Events;
