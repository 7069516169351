import React from 'react';
import {Grid, IconButton} from "@mui/material";
import closeIcon from "../../assets/images/close-red-icon.png";
import img1 from "../../assets/images/1.jpg";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {Empty} from "antd";
import editIcon from "../../assets/images/edit.svg";
import userIcon from "../../assets/images/userIconWithBg.png"


const PackageCard = ({
                         details,
                         setDetails,
                         setPackageDetails,
                         setPackageEdit,
                         setPackageIndex,
                         setOpenService,
                         setDeletedPackageId
                     }) => {
    const handleDeletePackage = (i) => {
        const newValue = details?.package?.filter((value, j) => {
            return j !== i;
        });
        setDetails((pre) => ({...pre, package: newValue}))
    }

    const EventMedia = (data) => {
        // console.log(data, "data")
        // Check if the data is in file format
        const isFileFormat = (data) => data?.hasOwnProperty('file_name') || data?.hasOwnProperty('_id');

        if (isFileFormat(data)) {
            // console.log(true, "data")
            return data?.file_url || data?.file_name; // If already in file format, return as it is
        } else if (data instanceof Blob || data instanceof File) {
            // console.log(false, "data")
            // If not in file format, convert and return
            return window.URL.createObjectURL(data);
        } else {
            // console.log(false, "data")
            return userIcon
        }

    }


    return (
        <>
            {details?.package?.length ? <Grid container spacing={2}>
                    {details?.package?.map((ele, i) => (
                        <Grid item xs={12} md={6}>
                            <div className="inner-gred box_shadow">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="t-18-bold m-0">Package</p>
                                    <div className="d-flex gap-2">
                                        <IconButton sx={{padding: "3px", background: "#EFF3F7"}} onClick={() => {
                                            setPackageDetails({
                                                name: ele?.name,
                                                capacity: ele?.capacity,
                                                description: ele?.description,
                                                dackageDetails: ele?.dackageDetails,
                                                value: ele?.value,
                                                media: ele?.media,
                                                id: ele?.id ? ele?.id : null
                                            })
                                            setPackageIndex(i)
                                            setPackageEdit(true)
                                            setOpenService(true)
                                        }}>
                                            <img src={editIcon} alt=""/>
                                        </IconButton>
                                        <IconButton sx={{padding: "3px"}} onClick={() => {
                                            handleDeletePackage(i)
                                            if (ele?.id) {
                                                setDeletedPackageId((pre) => ([...pre, ele?.id]))
                                            }
                                        }}>
                                            <img src={closeIcon} alt="" style={{width: '30px'}}/>
                                        </IconButton>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div className="d-flex gap-3 align-items-center">
                                        <div className="p_image_shape me-2 me-sm-3">
                                            {/*<img src={ele?.media?.length ? (ele?.file_name ||  window.URL.createObjectURL(ele?.media[0])) : userIcon }/>*/}
                                            <img src={EventMedia(ele?.media[0])}/>
                                        </div>
                                        <div>
                                            <p className="t-18-bold m-0 mb-2 text-black">{ele?.name}</p>
                                            <div className="d-flex align-items-center gap-2">
                                                <p className="t-12-500 m-0 text-gray">Up to - {ele?.capacity} ppl</p>
                                                <p className="t-12-bold text-black m-0">Value ${ele?.value} / Person</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex align-items-center gap-5 card-width"
                                        style={{
                                            justifyContent: "space-between",
                                            width: "100%",
                                            maxWidth: "100%",
                                            marginTop: "21px",
                                        }}
                                    >
                                        {/*<div>*/}
                                        <Grid container spacing={0}>

                                            {
                                                ele?.dackageDetails?.split(",")?.map((ele) => (
                                                    <Grid item>
                                                        <div className="d-flex align-items-baseline gap-2 mt-1 ms-3">
                                                            <FiberManualRecordIcon style={{
                                                                background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)",
                                                                color: "transparent",
                                                                borderRadius: "50%",
                                                                width: "8px",
                                                                height: "8px"
                                                            }}/>
                                                            <p className="m-0 t-18-bold text-black">{ele}</p>
                                                        </div>
                                                    </Grid>
                                                ))
                                            }

                                        </Grid>

                                        {/*<div className="d-flex align-items-center gap-2 mt-1">*/}
                                        {/*    <FiberManualRecordIcon style={{*/}
                                        {/*        background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)",*/}
                                        {/*        color: "transparent",*/}
                                        {/*        borderRadius: "50%",*/}
                                        {/*        width: "8px",*/}
                                        {/*        height: "8px"*/}
                                        {/*    }}/>*/}
                                        {/*    <p className="m-0 t-18-bold text-black">Bathroom</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="d-flex align-items-center gap-2 mt-1">*/}
                                        {/*    <FiberManualRecordIcon style={{*/}
                                        {/*        background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)",*/}
                                        {/*        color: "transparent",*/}
                                        {/*        borderRadius: "50%",*/}
                                        {/*        width: "8px",*/}
                                        {/*        height: "8px"*/}
                                        {/*    }}/>*/}
                                        {/*    <p className="m-0 t-18-bold text-black">Activities</p>*/}
                                        {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    <div className="d-flex align-items-center gap-2 mt-1">*/}
                                        {/*        <FiberManualRecordIcon style={{*/}
                                        {/*            background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)",*/}
                                        {/*            color: "transparent",*/}
                                        {/*            borderRadius: "50%",*/}
                                        {/*            width: "8px",*/}
                                        {/*            height: "8px"*/}
                                        {/*        }}/>*/}
                                        {/*        <p className="m-0 t-18-bold text-black">Shared Room</p>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="d-flex align-items-center gap-2 mt-1">*/}
                                        {/*        <FiberManualRecordIcon style={{*/}
                                        {/*            background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)",*/}
                                        {/*            color: "transparent",*/}
                                        {/*            borderRadius: "50%",*/}
                                        {/*            width: "8px",*/}
                                        {/*            height: "8px"*/}
                                        {/*        }}/>*/}
                                        {/*        <p className="m-0 t-18-bold text-black">Includes Stay</p>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="d-flex align-items-center gap-2 mt-1">*/}
                                        {/*        <FiberManualRecordIcon style={{*/}
                                        {/*            background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)",*/}
                                        {/*            color: "transparent",*/}
                                        {/*            borderRadius: "50%",*/}
                                        {/*            width: "8px",*/}
                                        {/*            height: "8px"*/}
                                        {/*        }}/>*/}
                                        {/*        <p className="m-0 t-18-bold text-black">Food</p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="mt-3">
                                        <p className="m-0 t-16-bold">Details:</p>
                                        <p className="t-14-500 m-0">{ele?.description}</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    ))
                    }
                </Grid> :
                <div className="bg-white"  style={{padding: "11%"}}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                           description={
                               <span>No Package Added.</span>
                           }
                    />
                </div>}
        </>
    )
}

export default PackageCard;