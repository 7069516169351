import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {event, notificationConstant} from "./constant";
import {toast} from "react-toastify";

export const notificationList = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", data?.page);
        fdata.append("limit", data?.limit);
        if (data?.lat) {
            fdata.append("lat", data?.lat);
        }
        if (data?.long) {
            fdata.append("long", data?.long);
        }
        if (data?.filter_type) {
            fdata.append("filter_type", data?.filter_type);
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.notification_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: notificationConstant?.ALL_NOTIFICATION,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const fatcheMoreNotificationList = (data, setHasMore) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", data?.page);
        fdata.append("limit", data?.limit);
        if (data?.lat) {
            fdata.append("lat", data?.lat);
        }
        if (data?.long) {
            fdata.append("long", data?.long);
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.notification_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: notificationConstant?.FATCH_MORE_NOTIFICATION,
                payload: response.data,
            });
            if (response.data.length == 0) {
                setHasMore(false)
            }
        } else {
            toast.error(response?.message);
        }
    };
};
export const seen_unseen_notification = (id) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("notification_id", id);

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.notification_view}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: notificationConstant?.NOTIFICATION_SEEN,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const updateNotification = (id) => {
    return async (dispatch) => {
        // let response;
        // const fdata = new FormData();
        // fdata.append("notification_id", id);
        //
        // await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.notification_view}`, {
        //     method: "POST",
        //     headers: {
        //         Authorization: `Bearer ${
        //             JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        //         }`,
        //     },
        //     body: fdata,
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         response = data;
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });

        if (id) {
            dispatch({
                type: notificationConstant?.NOTIFICATION_STATUS,
                payload: id,
            });
        }
    };
};

export const getEvent = (userId, setFoundEvent) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("event_id", userId);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_event_detail}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: notificationConstant?.GET_EVENTS,
                payload: response.data,
            });
            if (response?.message == "Couldn't found event") {
                setFoundEvent(true)
            }
        } else {
            if (response?.message == "Couldn't found event") {
                setFoundEvent(true)
            }
            toast.error(response?.message);
        }
    };
};

export const deleteNotification = (id) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("notification_id", id);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.delete_notifications}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: notificationConstant?.DELETE_NOTIFICATION,
                payload: response.data,
            });
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};