import React, {useState} from 'react';
import img1 from "../../assets/images/1.jpg";
import {Grid} from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import improveIcon from "../../assets/images/green_arrow.svg";
import varificationIcon from "../../assets/images/varificationIcon.svg";
import DeclinedIcon from "../../assets/images/DeclinedIcon.png";
import moment from "moment";
import userIcon from "../../assets/images/userIconWithBg.png"
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {LocalFormate, timeZoneFormate} from "../../Utils/DateFormateUtils";
import {useNavigate} from "react-router-dom";
// import ProviderDetailsScreen from "./ProviderDetailsScreen";

const ProviderCard = ({data, event, setOpenProviderScreen, setOpenProDetails}) => {
    const navigate = useNavigate();
    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                {
                    data?.is_registered_user == false ?
                        <>
                            <div className="border-gred mb-0">
                                <div className="inner-gred" style={{padding: "10px 14px"}}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            {/*<FiberManualRecordIcon style={{*/}
                                            {/*    color: "#60CE74",*/}
                                            {/*    boxShadow: "drop-shadow(0px 0px 20px rgba(0, 128, 0, 0.50))",*/}
                                            {/*    transform: "scale(0.6)"*/}
                                            {/*}}/>*/}
                                            <div className="position-relative">
                                                <div className="p_image_shape me-2 me-sm-0"
                                                     style={{transform: "scale(0.8)", marginLeft: "-8px"}}>
                                                    <img src={userIcon}/>
                                                </div>
                                                {/*<img src={varificationIcon} alt=""/>*/}
                                            </div>
                                            <div>
                                                <p className="m-0 t-16-bold text-black">{data?.provider_name}</p>
                                                {/*<p className="m-0 t-12-bold text-gray">{data?.is_request_accepted == null ? "Review" : (!data?.is_request_accepted ? "Request to Join" : "")}</p>*/}
                                                {/*<p className="m-0 t-10-bold text-black mt-2">{moment(LocalFormate(event?.start_date)).format("MMM")} /{moment(LocalFormate(event?.start_date)).format("D") == moment(LocalFormate(event?.end_date)).format("D") ? moment(LocalFormate(event?.start_date)).format("D") : `${moment(LocalFormate(event?.start_date)).format("D")} to ${moment(LocalFormate(event?.end_date)).format("D")}`}/ {moment(LocalFormate(event?.start_date)).format("YYYY")}</p>*/}
                                            </div>
                                        </div>
                                        {/*<div className="text-center">*/}
                                        {/*    {*/}
                                        {/*        data?.is_request_accepted == null ?*/}
                                        {/*            <p className="t-12-bold p-1" style={{*/}
                                        {/*                background: "#FFF0D6",*/}
                                        {/*                border: "1px solid #FFA500",*/}
                                        {/*                width: "85px",*/}
                                        {/*                textAlign: "center",*/}
                                        {/*                borderRadius: "5px"*/}
                                        {/*            }}>Waiting For Response</p>*/}
                                        {/*            :*/}
                                        {/*            <div>*/}
                                        {/*                <p className="t-14-bold m-0 mb-2">${(Number(data?.request_amount ?? 0)?.toFixed(2) || Number(data?.amount ?? 0)?.toFixed(2)) ?? 0} {data?.is_request_accepted ? "Declined" : 'Accepted'}</p>*/}
                                        {/*                <img*/}
                                        {/*                    src={data?.is_request_accepted ? improveIcon : DeclinedIcon}*/}
                                        {/*                    alt=""/>*/}
                                        {/*            </div>*/}
                                        {/*    }*/}


                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </> :

                        <>
                            <div className="border-gred mb-0 c-pointer" onClick={() => {
                                if (data?.event_request_type == "collaborate" || data?.event_request_type == "hire") {
                                    setOpenProviderScreen(true)
                                    setOpenProDetails(data)
                                } else {
                                    navigate(`/provider_details/${data?.provider_id?._id}`)
                                }
                            }}>
                                <div className="inner-gred" style={{padding: "10px 14px"}}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            {/*<FiberManualRecordIcon style={{*/}
                                            {/*    color: "#60CE74",*/}
                                            {/*    boxShadow: "drop-shadow(0px 0px 20px rgba(0, 128, 0, 0.50))",*/}
                                            {/*    transform: "scale(0.6)"*/}
                                            {/*}}/>*/}
                                            <div className="position-relative">
                                                <div className="p_image_shape me-2 me-sm-0"
                                                     style={{transform: "scale(0.8)", marginLeft: "-8px"}}>
                                                    <img src={data?.provider_id?.profile_picture || userIcon}/>
                                                </div>
                                                {data?.provider_id?.is_verified ?
                                                    <div className="position-absolute" style={{
                                                        top: "1px",
                                                        right: "8px",
                                                        zIndex: "11"
                                                    }}>
                                                        <img src={varificationIcon} alt=""/>
                                                    </div> : null}
                                            </div>
                                            <div>
                                                <div className="d-flex align-items-center gap-2 flex-wrap">
                                                    <p className="m-0 t-16-bold text-black">{data?.provider_id?.name}</p>
                                                    <div className="d-flex gap-1" style={{alignItems: "baseline"}}>
                                                        <div style={{color: "#FFC107"}}>
                                                            {data?.provider_id?.average_rating >= 0.5 ? (
                                                                <StarIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <StarBorderIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            )}
                                                            {data?.provider_id?.average_rating >= 1.5 ? (
                                                                <StarIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <StarBorderIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            )}
                                                            {data?.provider_id?.average_rating >= 2.5 ? (
                                                                <StarIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <StarBorderIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            )}
                                                            {data?.provider_id?.average_rating >= 3.5 ? (
                                                                <StarIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <StarBorderIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            )}
                                                            {data?.provider_id?.average_rating >= 4.5 ? (
                                                                <StarIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <StarBorderIcon
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <p style={{fontSize: "14px", fontWeight: "600"}}
                                                           className="m-0">({Math.round(data?.provider_id?.average_rating)} /
                                                            5)</p>
                                                    </div>
                                                </div>
                                                <p className="m-0 t-12-bold text-gray">{data?.is_request_accepted == null ? "Review" : (!data?.is_request_accepted ? "Request to Join" : "")}</p>
                                                <p className="m-0 t-10-bold text-black mt-2">{moment(LocalFormate(event?.start_date, event?.date_timezone)).format("MMM")} / {moment(LocalFormate(event?.start_date, event?.date_timezone)).format("D") == moment(LocalFormate(event?.end_date, event?.date_timezone)).format("D") ? moment(LocalFormate(event?.start_date, event?.date_timezone)).format("D") : `${moment(LocalFormate(event?.start_date, event?.date_timezone)).format("D")} to ${moment(LocalFormate(event?.end_date, event?.date_timezone)).format("D")}`} / {moment(LocalFormate(event?.start_date, event?.date_timezone)).format("YYYY")} ({timeZoneFormate(event?.start_date, event?.date_timezone)})</p>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            {
                                                data?.is_request_accepted == true || data?.provider_status == "accept" ?

                                                    <div>
                                                        {
                                                            <p className="t-14-bold m-0 mb-2"
                                                               style={{whiteSpace: 'nowrap'}}>{(data?.amount || data?.request_amount) ?
                                                                ((data?.event_request_type == "hire" ? "$" : "%") + (Number(data?.amount ?? 0)?.toFixed(2) || Number(data?.request_amount ?? 0)?.toFixed(2)) ?? 0) : null} {"Accepted"}</p>}
                                                        <img
                                                            src={improveIcon}
                                                            alt=""/>
                                                    </div> : (data?.is_request_accepted == false || data?.provider_status == "decline") ?

                                                        <div>
                                                            <p className="t-14-bold m-0 mb-2"
                                                               style={{whiteSpace: 'nowrap'}}>{data?.event_request_type == "hire" ? "$" : "%"}{(Number(data?.amount ?? 0)?.toFixed(2) || Number(data?.request_amount ?? 0)?.toFixed(2)) ?? 0} {'Declined'}</p>
                                                            <img
                                                                src={DeclinedIcon}
                                                                alt=""/>
                                                        </div>
                                                        :
                                                        <p className="t-12-bold p-1" style={{
                                                            background: "#FFF0D6",
                                                            border: "1px solid #FFA500",
                                                            width: "85px",
                                                            textAlign: "center",
                                                            borderRadius: "5px"
                                                        }}>Waiting For Response</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                }

            </Grid>

        </>
    )
}

export default ProviderCard;