import React from 'react';
import Logo from "../assets/images/logo-2.png";

const Loader = () => {
    return (<>
        <div className="preloader">
            <div className="position-relative">
                <div>
                    <svg
                        className="loader-svg"
                        width="150"
                        height="150"
                        viewBox="-0.1 -0.1 1.2 1.2"
                    >
                        <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="0%" stop-color="#00bc9b"/>
                                <stop offset="100%" stop-color="#EFCE96 "/>
                            </linearGradient>
                        </defs>
                        <path
                            className="hexagon background"
                            d="M0.4625 0.01165063509
      a0.075 0.075 0 0 1 0.075 0
      l0.3666729559 0.2116987298
      a0.075 0.075 0 0 1 0.0375 0.06495190528
      l0 0.4233974596
      a0.075 0.075 0 0 1 -0.0375 0.06495190528
      l-0.3666729559 0.2116987298
      a0.075 0.075 0 0 1 -0.075 0
      l-0.3666729559 -0.2116987298
      a0.075 0.075 0 0 1 -0.0375 -0.06495190528
      l0 -0.4233974596
      a0.075 0.075 0 0 1 0.0375 -0.06495190528 Z"
                            stroke="url(#gradient)"
                        />
                        <path
                            id="trace"
                            className="hexagon trace"
                            d="M0.4625 0.01165063509
      a0.075 0.075 0 0 1 0.075 0
      l0.3666729559 0.2116987298
      a0.075 0.075 0 0 1 0.0375 0.06495190528
      l0 0.4233974596
      a0.075 0.075 0 0 1 -0.0375 0.06495190528
      l-0.3666729559 0.2116987298
      a0.075 0.075 0 0 1 -0.075 0
      l-0.3666729559 -0.2116987298
      a0.075 0.075 0 0 1 -0.0375 -0.06495190528
      l0 -0.4233974596
      a0.075 0.075 0 0 1 0.0375 -0.06495190528 Z"
                        />
                    </svg>
                </div>

                <img src={Logo} alt="" className="position-absolute" style={{left: "20%", top: "20%"}}/>
            </div>
        </div>
    </>)
}

export default Loader;