import React, {useEffect, useState} from 'react';
import {Grid, IconButton} from "@mui/material";
import closeIcon from "../../assets/images/close-red-icon.png";
import {AutoComplete, Empty} from "antd";
import addIcon from "../../assets/images/roundAdd-Icon.png";
import editIcon from "../../assets/images/edit.svg";
import DleteIcon from "../../assets/images/deleteRedIcon.svg";
import PhoneInput from "react-phone-input-2";

const ProviderCard = ({details, setDetails, setDeletedProviderId}) => {
    const [isError, setIsError] = useState(false);
    const [country, setCountry] = useState("US")
    const handleChangename = (e, id) => {
        setDetails((pre) => ({
            ...pre, provider: pre.provider?.map((prov, i) => {
                if (i == id) {
                    return {
                        ...prov, name: e.target.value
                    }
                } else {
                    return prov
                }
            })
        }))
    }
    const handleChangeEmail = (e, id) => {
        setDetails((pre) => ({
            ...pre, provider: pre.provider?.map((prov, i) => {
                if (i == id) {
                    return {
                        ...prov, email: e.target.value
                    }
                } else {
                    return prov
                }
            })
        }))
    }
    const handleChangePhone = (e, id) => {
        // console.log(e, "phone")
        setDetails((pre) => ({
            ...pre, provider: pre.provider?.map((prov, i) => {
                if (i == id) {
                    return {
                        ...prov, phone: e
                    }
                } else {
                    return prov
                }
            })
        }))
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        async (position) => {
                            const {latitude, longitude} = position.coords;


                            const googleMapApiKey = 'AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs';

                            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapApiKey}`;
                            const response = await fetch(apiUrl);

                            if (response.ok) {
                                const data = await response.json();

                                // Check if the response contains results
                                if (data.status === 'OK') {
                                    // Extract the state from the address components
                                    const results = data.results || [];
                                    for (const result of results) {
                                        const addressComponents = result.address_components || [];
                                        for (const component of addressComponents) {
                                            const types = component.types || [];
                                                // console.log(component,addressComponents, "component.short_name")
                                            if (types.includes('country')) {
                                                setCountry(component?.short_name?.toLowerCase())
                                                // console.log(component, "component.short_name")
                                                // setStateName(component.short_name);
                                                return;
                                            }
                                        }
                                    }
                                }
                            } else {
                                console.error(`Failed to fetch data Status: ${response.status}`);
                            }
                        },
                        (error) => {
                            console.error("Error getting geolocation:", error);
                        }
                    );
                } else {
                    console.log("Geolocation is not supported");
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    return (
        <>
            {details?.provider?.length ? <Grid container spacing={2}>
                    {details?.provider?.map((ele, i) => (
                        <Grid item xs={12} md={6} key={i}>
                            <div className="inner-gred box_shadow">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="t-18-bold m-0">Provider</p>
                                    <IconButton sx={{padding: "3px"}} onClick={() => {
                                        const newValue = details?.provider?.filter((value, j) => {
                                            return j !== i;
                                        });
                                        if (ele?.id) {
                                            setDeletedProviderId((pre) => ([...pre, ele?.id]))
                                        }
                                        setDetails((pre) => ({...pre, provider: newValue}))
                                    }}>
                                        <img src={closeIcon} alt=""/>
                                    </IconButton>
                                </div>
                                <hr/>
                                <div style={{
                                    background: "#eff3f7",
                                    padding: "15px",
                                    borderRadius: "10px"
                                }}>
                                    <div className="">
                                        <p className="t-14-500 text-black m-0">Provider Name</p>
                                        <div className="group-input mt-2 p-15 clearfix">
                                            <input
                                                required
                                                type="text"
                                                name="name"
                                                style={{
                                                    color: "#000000",
                                                }}
                                                value={ele?.name}
                                                placeholder="name"
                                                onChange={(e) => {
                                                    if (e.target.value?.length <= 30) {
                                                        handleChangename(e, i)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <p className="t-13-500 text-danger">{ele?.nameError}</p>
                                    </div>
                                    <div className="mt-3">
                                        <p className="t-14-500 text-black m-0">Email Address</p>
                                        <div className="group-input mt-2 p-15 clearfix">
                                            <input
                                                required
                                                type="email"
                                                style={{
                                                    color: "#000000",
                                                }}
                                                placeholder={"Email Address"}
                                                name="email"
                                                value={ele?.email}
                                                onChange={(e) => handleChangeEmail(e, i)}
                                            />
                                        </div>
                                        <p className="t-13-500 text-danger">{ele?.emailError}</p>
                                    </div>
                                    <div className="mt-3">
                                        <p className="t-14-500 text-black m-0">Phone Number</p>
                                        <div className="group-input mt-2 p-15 clearfix">
                                            {/*<input*/}
                                            {/*    required*/}
                                            {/*    type="text"*/}
                                            {/*    placeholder={"Phone Number"}*/}
                                            {/*    pattern="[789][0-9]{9}"*/}
                                            {/*    name="phone"*/}
                                            {/*    value={ele?.phone}*/}
                                            {/*    title="Phone number with 7-9 and remaing 9 digit with 0-9"*/}
                                            {/*    onChange={(e) => {*/}
                                            {/*        handleChangePhone(e, i)*/}
                                            {/*        // if (!pattern.test(e.target.value))*/}
                                            {/*        //     setIsError(true);*/}
                                            {/*        // else setIsError(false);*/}
                                            {/*    }}*/}
                                            {/*/>*/}

                                            {/*<PhoneInput*/}
                                            {/*    placeholder="Phone Number"*/}
                                            {/*    // enableSearch={true}*/}
                                            {/*    value={ele?.phone}*/}
                                            {/*    // country={country}*/}
                                            {/*    disableDropdown={true}*/}
                                            {/*    disableCountryCode={true}*/}
                                            {/*    // enableAreaCodeStretch*/}
                                            {/*    onChange={(phone, data) => handleChangePhone(phone, i)}*/}
                                            {/*    // onChange={(phone, data) => {*/}
                                            {/*    //     setsignUpChange({*/}
                                            {/*    //         ...signUpChange,*/}
                                            {/*    //         mobile_number: phone.slice(data?.dialCode?.length),*/}
                                            {/*    //         country_code: data.dialCode,*/}
                                            {/*    //     });*/}
                                            {/*    // }}*/}
                                            {/*/>*/}
                                            <input
                                                required
                                                type="number"
                                                placeholder={"Phone Number"}
                                                name="phoneNumber"
                                                style={{
                                                    color: "#000000",
                                                }}
                                                value={ele?.phone}
                                                // onChange={(e) => handleChangeEmail(e, i)}
                                                onChange={(e) => handleChangePhone(e.target.value, i)}
                                            />
                                        </div>
                                        <p className="t-13-500 text-danger">
                                            {ele?.phoneError ? ele?.phoneError : isError ? "Enter Valid Phone Number" : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    ))}

                </Grid>
                : <div className="bg-white "  style={{padding: "11%"}}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                           description={
                               <span>No provider Added.</span>
                           }
                    />
                </div>
            }
        </>
    )
}

export default ProviderCard;