import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {calenderConstant, claimConstants} from "./constant";
import {toast} from "react-toastify";

export const claimProvider = (data, setOpen, setIsStatus, handleNavigate, setSmsCall) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("claim_account_id", data?.claim_account_id);
        fdata.append("claim_service_id", data?.claim_service_id);
        if (data?.profile_image) {
            fdata.append("profile_image", data?.profile_image);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.claim_account}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: claimConstants.CLAIM_PROVIDER,
                payload: response.data,
            });
            setSmsCall(true)
            localStorage.setItem('claims', JSON.stringify(response.data))
            setIsStatus(false)
            setOpen((pre) => ({...pre, claimAccount: false}));
            if (data?.status) {
                toast.success(response?.message);
            }
            handleNavigate()
        } else {
            setIsStatus(false)
            if (data?.status) {
                toast.error(response?.message);
            }

        }
    };
};
export const claimProviderSMS = (data, setOpen, setIsStatus, handleNavigate, setSmsCall) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("claim_account_id", data?.claim_account_id);
        fdata.append("claim_service_id", data?.claim_service_id);
        if (data?.profile_image) {
            fdata.append("profile_image", data?.profile_image);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.claim_account_without_send_mail}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: claimConstants.CLAIM_PROVIDER_SMS,
                payload: response.data,
            });
            setSmsCall(true)
            localStorage.setItem('claims', JSON.stringify(response.data))
            setIsStatus(false)
            setOpen((pre) => ({...pre, claimAccount: false}));
            // if (data?.status) {
                toast.success(response?.message);
            // }
            handleNavigate()
        } else {
            setIsStatus(false)
            // if (data?.status) {
                toast.error(response?.message);
            // }

        }
    };
};
export const claimProviderContact = (data, setIsStatus, setOpen, setIsSuccess) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("contact_user_id", data?.contact_user_id);
        fdata.append("message", data?.message);


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.claim_contact_us}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setIsStatus(false)
            setOpen(false)
            setIsSuccess(true)
            dispatch({
                type: claimConstants.CLAIM_PROVIDER_CONTACT,
                payload: response.data,
            });
            toast.success(response?.message);

        } else {
            setIsStatus(false)
            toast.error(response?.message);
        }
    };
};
export const claimProviderBook = (data, setIsStatus, setOpen) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("request_book_user_id", data?.request_book_user_id);
        fdata.append("message", data?.message);
        // fdata.append("profile_image", data?.profile_image);


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.claim_add_message}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setIsStatus(false)
            setOpen(false)
            dispatch({
                type: claimConstants.CLAIM_PROVIDER_BOOK_REQUEST,
                payload: response.data,
            });
            toast.success(response?.message);

        } else {
            setIsStatus(false)
            toast.error(response?.message);
        }
    };
};
export const guestClaimProviderBook = (data, setIsStatus, setOpen) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("request_book_user_id", data?.request_book_user_id);
        fdata.append("message", data?.message);
        // fdata.append("profile_image", data?.profile_image);


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_claim_add_message}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setIsStatus(false)
            setOpen(false)
            dispatch({
                type: claimConstants.CLAIM_PROVIDER_BOOK_REQUEST,
                payload: response.data,
            });
            toast.success(response?.message);

        } else {
            setIsStatus(false)
            toast.error(response?.message);
        }
    };
};
export const claimEventBook = (data, setIsStatus, setOpen) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("pre_populate_event_id", data?.pre_populate_event_id);
        fdata.append("pre_populate_user_id", data?.request_book_user_id);
        fdata.append("message", data?.message);
        // fdata.append("profile_image", data?.profile_image);


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.send_message_pre_populate_event}`, {
            method: "POST",
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setIsStatus(false)
            setOpen(false)
            // dispatch({
            //     type: claimConstants.CLAIM_PROVIDER_BOOK_REQUEST,
            //     payload: response.data,
            // });
            toast.success(response?.message);

        } else {
            setIsStatus(false)
            toast.error(response?.message);
        }
    };
};

export const viewClaimProfile = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("viewing_user_id", data?.id);
        fdata.append("is_view_status", data?.status);

        // fdata.append("profile_image", data?.profile_image);


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.view_profile}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {

            dispatch({
                type: claimConstants.CLAIM_VIEW_COUNT,
                payload: response.data,
            });
            // toast.success(response?.message);

        } else {

            toast.error(response?.message);
        }
    };
};
export const viewGuestClaimProfile = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("viewing_user_id", data?.id);
        fdata.append("is_view_status", data?.status);

        // fdata.append("profile_image", data?.profile_image);


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.view_profile_guest}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {

            dispatch({
                type: claimConstants.CLAIM_VIEW_COUNT,
                payload: response.data,
            });
            // toast.success(response?.message);

        } else {

            toast.error(response?.message);
        }
    };
};