import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {saveConstant} from "./constant";
import {toast} from "react-toastify";



export const getSavedData = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", data?.page);
        fdata.append("limit", data?.limit);
        if (data?.latLong) {
            fdata.append("lat", data?.latLong?.lat);
            fdata.append("long", data?.latLong?.lng);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.saved_service_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: saveConstant.GET_SAVE_DATA,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};