import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Box, Grid, IconButton } from "@mui/material";
import moment from "moment/moment";
import cardIcon from "../assets/images/cardIcon.svg";
import cardImg from "../assets/images/Cardimg.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WalletCardPopup from "./WalletCardPopup";
import { addMyPageDetails } from "../action/myPageConstant";
import PhoneInput from "react-phone-input-2";
import GuestHeader from "./GuestHeader";
import {
  bookEvent,
  bookEventRequest,
  getEvent,
  getGuestEvent,
  guestEventBooking,
  sessionBookingModify,
} from "../action/eventConstant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { systemCharge } from "../action/calenderConstant";
import { cardsLists, deleteCards, saveCards } from "../action/walletConstant";
import { toast } from "react-toastify";
import {
  getBookingDetails,
  guestsessionBooking,
} from "../action/myBokingConstant";
import BookingPopup from "./BookingPopup";
import {
  priceCalculations,
  Rules,
  modifyEventPriceCalculations,
} from "../Utils/priceCalculations";
import { getUserRefCode } from "../action/ReffDiscountConstant";
import { get_referral_code_percentage } from "../Utils/reffercode";
import { discountConstant } from "../action/constant";
import { checkDiscountCode } from "../action/discountCodeConstant";
import Form from "./Notification/Payment/PaymentScreen";

const previousNetworkFees = (myBookingDetails) => {
  if (
    myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info
      ?.is_refree == true &&
    myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info
      ?.is_discount == false
  ) {
    return (
      <div>
        <div className="d-flex justify-content-between mt-1">
          <span className="review-Name">
            Network fees ({" "}
            {myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_percentage_from_user ||
              myBookingDetails?.bookingDetails?.booking_payment_info
                ?.fees_percentage_from_user}
            % )
          </span>
          <div className="d-flex gap-1">
            <span
              className="review-Name"
              style={{
                color: "#9E9E9E",
                textDecoration: "line-through",
              }}
            >
              ${" "}
              {
                myBookingDetails?.bookingDetails?.payment_history_id
                  ?.referral_code_info?.display_user_fees_amount
              }
            </span>
            <span className="review-Name">
              ${" "}
              {Number(
                myBookingDetails?.bookingDetails?.payment_history_id
                  ?.fees_amount_from_user ||
                  myBookingDetails?.bookingDetails?.booking_payment_info
                    ?.fees_amount_from_user
              ).toFixed(2)}
            </span>
          </div>
        </div>
        <p className="yelloe-t-bold t-14-500 m-0">
          You have received a{" "}
          {
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.referral_code_info?.display_user_fees_percentage
          }
          % discount for using the referral code.
        </p>
      </div>
    );
  } else if (
    myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info
      ?.is_refree == true &&
    myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info
      ?.is_discount == true &&
    myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info
      ?.is_admin_discount == false
  ) {
    return (
      <div>
        <div className="d-flex justify-content-between mt-1">
          <span className="review-Name">
            Network fees ({" "}
            {myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_percentage_from_user ||
              myBookingDetails?.bookingDetails?.booking_payment_info
                ?.fees_percentage_from_user}
            % )
          </span>
          <div className="d-flex gap-1">
            <span
              className="review-Name"
              style={{
                color: "#9E9E9E",
                textDecoration: "line-through",
              }}
            >
              ${" "}
              {
                myBookingDetails?.bookingDetails?.payment_history_id
                  ?.referral_code_info?.display_user_fees_amount
              }
            </span>
            <span className="review-Name">
              ${" "}
              {Number(
                myBookingDetails?.bookingDetails?.payment_history_id
                  ?.fees_amount_from_user ||
                  myBookingDetails?.bookingDetails?.booking_payment_info
                    ?.fees_amount_from_user
              ).toFixed(2)}
            </span>
          </div>
        </div>
        <p className="yelloe-t-bold t-14-500 m-0">
          You have received a{" "}
          {
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.referral_code_info?.display_user_fees_percentage
          }
          % discount for using the referral code.
        </p>
      </div>
    );
  } else if (
    myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info
      ?.is_refree == true &&
    myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info
      ?.is_discount == true &&
    myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info
      ?.is_admin_discount == true
  ) {
    return (
      <div>
        <div className="d-flex justify-content-between mt-1">
          <span className="review-Name">
            Network fees (
            {myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_percentage_from_user ||
              myBookingDetails?.bookingDetails?.booking_payment_info
                ?.fees_percentage_from_user}
            % )
          </span>
          <div className="d-flex gap-1">
            <span
              className="review-Name"
              style={{
                color: "#9E9E9E",
                textDecoration: "line-through",
              }}
            >
              ${" "}
              {
                myBookingDetails?.bookingDetails?.payment_history_id
                  ?.referral_code_info?.display_user_fees_amount
              }
            </span>
            <span className="review-Name">
              ${" "}
              {Number(
                myBookingDetails?.bookingDetails?.payment_history_id
                  ?.fees_amount_from_user ||
                  myBookingDetails?.bookingDetails?.booking_payment_info
                    ?.fees_amount_from_user
              ).toFixed(2)}
            </span>
          </div>
        </div>
        <p className="yelloe-t-bold t-14-500 m-0">
          You have received a{" "}
          {Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.referral_code_info?.display_user_fees_percentage ?? 0
          ) +
            Number(
              myBookingDetails?.bookingDetails?.payment_history_id
                ?.discount_percentage ?? 0
            )}
          % discount for using the referral and discount code.
        </p>
      </div>
    );
  } else if (
    myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info
      ?.is_refree == false &&
    myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info
      ?.is_discount == true &&
    myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info
      ?.is_admin_discount == true
  ) {
    return (
      <div>
        <div className="d-flex justify-content-between mt-1">
          <span className="review-Name">
            Network fees (
            {myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_percentage_from_user ||
              myBookingDetails?.bookingDetails?.booking_payment_info
                ?.fees_percentage_from_user}
            % )
          </span>
          <div className="d-flex gap-1">
            <span
              className="review-Name"
              style={{
                color: "#9E9E9E",
                textDecoration: "line-through",
              }}
            >
              ${" "}
              {
                myBookingDetails?.bookingDetails?.payment_history_id
                  ?.referral_code_info?.display_user_fees_amount
              }
            </span>
            <span className="review-Name">
              ${" "}
              {Number(
                myBookingDetails?.bookingDetails?.payment_history_id
                  ?.fees_amount_from_user ||
                  myBookingDetails?.bookingDetails?.booking_payment_info
                    ?.fees_amount_from_user
              ).toFixed(2)}
            </span>
          </div>
        </div>
        <p className="yelloe-t-bold t-14-500 m-0">
          You have received a{" "}
          {
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.discount_percentage
          }
          % discount for using the discount code.
        </p>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-between mt-1">
        <span className="review-Name">
          Network fees ({" "}
          {
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_percentage_from_user
          }
          % )
        </span>
        <span className="review-Name">
          ${" "}
          {Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_amount_from_user
          ).toFixed(2)}
        </span>
      </div>
    );
  }
};

const modifyNetworkFees = (
  loginUserDetails,
  refCodeData,
  modifyBookingInfo
) => {
  // console.log(modifyBookingInfo, refCodeData, "modifyBookingInfo")

  if (
    refCodeData?.referee?.is_expire == false &&
    modifyBookingInfo?.is_discount == true &&
    modifyBookingInfo?.is_admin_discount == true
  ) {
    // console.log("modifyBookingInfo", 1)
    return (
      <div className="d-flex justify-content-between align-items-center mt-1">
        <div>
          <span className="review-Name">
            Network fees{" "}
            {loginUserDetails?.is_ambassador
              ? ""
              : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}
          </span>
          {refCodeData?.referee?.is_expire ? null : (
            <p className="yelloe-t-bold t-14-500 m-0">
              You have received a{" "}
              {Number(refCodeData?.referee?.percentage ?? 0) +
                Number(modifyBookingInfo?.discount_percentage ?? 0)}
              % discount for using the referral and dicount code.
            </p>
          )}
        </div>
        <div className="d-flex gap-2">
          <span
            className="review-Name"
            style={{
              color: "#9E9E9E",
              textDecoration: "line-through",
            }}
          >
            ${" "}
            {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}
          </span>
          <span className="review-Name">
            ${" "}
            {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(
              2
            )}
          </span>
        </div>
      </div>
    );
  } else if (
    refCodeData?.referee?.is_expire == true &&
    modifyBookingInfo?.is_discount == true &&
    modifyBookingInfo?.is_admin_discount == true
  ) {
    // console.log("modifyBookingInfo", 2)
    return (
      <div className="d-flex justify-content-between align-items-center mt-1">
        <div>
          <span className="review-Name">
            Network fees{" "}
            {loginUserDetails?.is_ambassador
              ? ""
              : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}
          </span>
          {refCodeData?.referee?.is_expire ? null : (
            <p className="yelloe-t-bold t-14-500 m-0">
              You have received a {modifyBookingInfo?.discount_percentage}%
              discount for using the discount code.
            </p>
          )}
        </div>
        <div className="d-flex gap-2">
          <span
            className="review-Name"
            style={{
              color: "#9E9E9E",
              textDecoration: "line-through",
            }}
          >
            ${" "}
            {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}
          </span>
          <span className="review-Name">
            ${" "}
            {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(
              2
            )}
          </span>
        </div>
      </div>
    );
  } else if (
    refCodeData?.referee?.is_expire == false &&
    modifyBookingInfo?.is_discount == false
  ) {
    // console.log("modifyBookingInfo", 3)
    return (
      <div className="d-flex justify-content-between align-items-center mt-1">
        <div>
          <span className="review-Name">
            Network fees{" "}
            {loginUserDetails?.is_ambassador
              ? ""
              : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}
          </span>
          {refCodeData?.referee?.is_expire ? null : (
            <p className="yelloe-t-bold t-14-500 m-0">
              You have received a {refCodeData?.referee?.percentage}% discount
              for using the referral code.
            </p>
          )}
        </div>
        <div className="d-flex gap-2">
          <span
            className="review-Name"
            style={{
              color: "#9E9E9E",
              textDecoration: "line-through",
            }}
          >
            ${" "}
            {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}
          </span>
          <span className="review-Name">
            ${" "}
            {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(
              2
            )}
          </span>
        </div>
      </div>
    );
  } else {
    // console.log("modifyBookingInfo", 4)
    return (
      <div className="d-flex justify-content-between align-items-center mt-1">
        <div>
          <span className="review-Name">
            Network fees{" "}
            {loginUserDetails?.is_ambassador
              ? ""
              : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}
          </span>
          {refCodeData?.referee?.is_expire ? null : (
            <p className="yelloe-t-bold t-14-500 m-0">
              You have received a {refCodeData?.referee?.percentage}% discount
              for using the referral code.
            </p>
          )}
        </div>
        <div className="d-flex gap-2">
          {refCodeData?.referee?.is_expire ? null : (
            <span
              className="review-Name"
              style={{
                color: "#9E9E9E",
                textDecoration: "line-through",
              }}
            >
              ${" "}
              {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(
                2
              )}
            </span>
          )}
          <span className="review-Name">
            ${" "}
            {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(
              2
            )}
          </span>
        </div>
      </div>
    );
  }
};

const ModifyEventBooking = () => {
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const navigate = useNavigate();
  const [walletMenu, setwalletMenu] = React.useState(null);
  const [isDiscountCode, setIsDiscountCode] = useState("");
  const [isChecking, setIsCheching] = useState(false);
  const { id, packageId, participants, providerId, bookingId } = useParams();
  const Walletopen = Boolean(walletMenu);
  const walletId = Walletopen ? "simple-popover" : undefined;
  const [CardSelectId, setCardSelectId] = useState("");
  const dispatch = useDispatch();
  const [btnLoader, setBtnLoader] = useState(false);
  const [refCodeData, setRefCodeData] = useState({
    referee: {
      id: null,
      is_referee: false,
      reward_type: null,
      percentage: null,
      is_expire: true,
    },
    referrer: {
      id: "",
      is_referee: false,
      reward_type: null,
      percentage: null,
      is_expire: true,
    },
  });
  const [discountDetails, setDiscountDetails] = useState({
    is_admin_created: null,
    is_expire: true,
    discount_percentage: 0,
    discount_code: null,
    _id: null,
    is_discount: false,
  });
  const [latlong, setLatlong] = useState({
    lat: "",
    long: "",
  });
  const momentZone = moment?.tz?.guess();
  const [stateNameA, setStateNameA] = useState({
    country: "",
    state: "",
  });
  const {
    event,
    calendar,
    wallet,
    myBookingDetails,
    refDescount,
    discountCode,
  } = useSelector((state) => state);
  // const [modifyCharges, setModifyCharges] = useState({
  //     modifyPercentage: '',
  //     systemPercentage: '',
  //     modifyAmount: '',
  //     systemAmount: '',
  //     total: '',
  //     amount: '',
  //     totalParcentage: ''
  // })

  // console.log(myBookingDetails , 'myBookingDetails')
  const [modifyCharges, setModifyCharges] = useState();
  const [modifyBookingInfo, setModifyBookingInfo] = useState({});
  const loginUserDetails = useSelector((state) => state.user);

  const [addCard, setAddCard] = useState({
    name: "",
    number: "",
    day: "",
    cvv: "",
  });
  const [fixTax, setFixTax] = useState({
    tax: 0,
    tax_id: 0,
  });
  const [stateName, setStateName] = useState("");
  const [guestUser, setGuestUser] = useState({
    name: "",
    email: "",
    mobile_number: "",
    country_code: "",
  });
  const handleWalletMenuClick = (event) => {
    setwalletMenu(event.currentTarget);
  };

  const handleWalletMenuClose = () => {
    setwalletMenu(null);
  };

  const [isPayment, setIsPayment] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;

              setLatlong((pre) => ({ ...pre, lat: latitude, long: longitude }));
              const googleMapApiKey = "AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs";

              const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapApiKey}`;
              const response = await fetch(apiUrl);

              if (response.ok) {
                const data = await response.json();

                // Check if the response contains results
                if (data.status === "OK") {
                  // Extract the state from the address components
                  const results = data.results || [];
                  for (const result of results) {
                    const addressComponents = result.address_components || [];
                    for (const component of addressComponents) {
                      const types = component.types || [];
                      if (types.includes("administrative_area_level_1")) {
                        // console.log(component, "component");
                        setStateNameA((pre) => ({
                          ...pre,
                          state: component?.long_name,
                        }));
                        // return;
                      }
                      if (types.includes("country")) {
                        // console.log(component, "component");
                        setStateNameA((pre) => ({
                          ...pre,
                          country: component?.long_name,
                        }));
                      }
                    }
                  }
                }
              } else {
                console.error(
                  `Failed to fetch data Status: ${response.status}`
                );
              }
            },
            (error) => {
              console.error("Error getting geolocation:", error);
            }
          );
        } else {
          console.log("Geolocation is not supported");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const geteventInfo = () => {
    if (!userAuth?.token) {
      dispatch(getGuestEvent(id));
    } else {
      dispatch(
        getEvent(
          id,
          () => {},
          () => {}
        )
      );
    }
    dispatch(systemCharge());
  };

  // useEffect(() => {
  //   if (
  //     event?.bookedEvent?.success?.status == true ||
  //     event?.modifyedEvent?.success?.status == true
  //   ) {
  //     setIsCheching(true);
  //   } else {
  //     setIsCheching(false);
  //   }
  // }, [event]);

  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));

  function validateEmail(email) {
    // Regular expression for validating an Email
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const getMyStateTax = () => {
    calendar?.systemCharge?.sales_tax?.map((ele) => {
      if (ele?.unique_code == stateName) {
        // if (ele?.unique_code == "CA") {
        setFixTax({
          tax: ele?.tax_rate,
          tax_id: ele?._id,
        });
      }
    });
  };

  useEffect(() => {
    getMyStateTax();
  }, [calendar?.systemCharge, stateName]);

  const getDateNtime = (date, time) => {
    const firstDate = moment(date);
    const secondDate = moment(time);

    // Calculate the duration between the two dates
    const duration = moment.duration(secondDate.diff(firstDate));

    // Add the duration to the first date
    const resultDate = firstDate.add(duration);

    return resultDate;
  };

  useEffect(() => {
    getDateNtime(event?.event?.start_date, event?.event?.start_time);
  }, [event?.event]);

  const handlebookEvent = (responseData) => {
    if (!stateName.length) {
      openSiteInfo();
    } else {
      setBtnLoader(true);
      if (!userAuth?.token && !validateEmail(guestUser.email)) {
        setBtnLoader(false);
        toast.error("Please Enter valid email");
      } else {
        if (!userAuth?.token) {
          const date = moment(addCard?.day?.split("/")[1], "YY");
          const year = date.format("YYYY");
          const selectedPackage = getSelectedPackage();
          const systemCharge = calendar?.systemCharge;
          const valuePerPerson = selectedPackage?.value_per_person || 0;
          const payload = {
            // booking_type: "event",
            // event_id: id,
            // provider_id: providerId,
            // no_of_participants: participants,
            // event_package_id: packageId,
            // total_amount: Number(getSelectedPackage()?.value_per_person * participants),
            // fees_percentage_from_user: userAuth?.is_ambassador ? Number(calendar?.systemCharge?.tem_fees) : Number(calendar?.systemCharge?.tem_fees) + Number(calendar?.systemCharge?.tem_tax),
            // fees_amount_from_user: texCount((Number(getSelectedPackage()?.value_per_person * participants)), Number(calendar?.systemCharge?.tem_fees), Number(calendar?.systemCharge?.tem_tax),
            // total_paid_amount: texCount((getSelectedPackage()?.value_per_person * participants), Number(calendar?.systemCharge?.tem_fees), Number(calendar?.systemCharge?.tem_tax), "amount"),
            // card_holder_name: addCard?.name,
            // card_number: addCard?.number,
            // expire_year: year,
            // expire_month: moment(addCard.day, 'MM/YY').format('MM'),
            // cvv: addCard.cvv,
            // is_guest_user: true,
            // name: guestUser?.name,
            // email_address: guestUser?.email,
            // phone_number: guestUser?.mobile_number,
            // country_code: guestUser?.country_code,
            // booking_date: event?.event?.start_date,
            // start_date_time: getDateNtime(event?.event?.start_date, event?.event?.start_time),
            // end_date_time: getDateNtime(event?.event?.end_date, event?.event?.end_time)
            booking_type: "event",
            event_id: id,
            provider_id: providerId,
            no_of_participants: participants,
            event_package_id: packageId,
            total_amount: Number(valuePerPerson * participants),
            fees_percentage_from_user: loginUserDetails?.is_ambassador
              ? Number(0)
              : Number(calendar?.systemCharge?.tem_fees),
            fees_amount_from_user: texCount(
              Number(valuePerPerson * participants),
              Number(systemCharge?.tem_fees),
              Number(systemCharge?.tem_tax)
            ),
            total_paid_amount: texCount(
              valuePerPerson * participants,
              Number(systemCharge?.tem_fees),
              Number(fixTax?.tax),
              "amount"
            ),
            card_holder_name: addCard?.name,
            card_number: addCard?.number,
            expire_year: year,
            expire_month: moment(addCard?.day, "MM/YY").format("MM"),
            cvv: addCard?.cvv,
            is_guest_user: true,
            name: guestUser?.name,
            email_address: guestUser?.email,
            phone_number: guestUser?.mobile_number,
            country_code: guestUser?.country_code,
            booking_date: event?.event?.start_date,
            start_date_time: getDateNtime(
              event?.event?.start_date,
              event?.event?.start_time
            ),
            end_date_time: getDateNtime(
              event?.event?.end_date,
              event?.event?.end_time
            ),
            sales_tax_percentage: Number(fixTax?.tax),
            sales_tax_amount_from_user: texCount(
              Number(valuePerPerson * participants),
              Number(systemCharge?.tem_fees),
              Number(fixTax?.tax),
              "oneTax"
            ),
            sales_tax_id: fixTax?.tax_id,
          };
          if (modifyBookingInfo?.is_refund) {
            dispatch(
              guestEventBooking(payload, (a) => {
                setBtnLoader(a);
              })
            );
          } else {
            localStorage.setItem("EventModifyData", JSON.stringify(payload));
          }
        } else {
          const payload = {
            booking_type: "event",
            event_id: id,
            provider_id: providerId,
            no_of_participants: participants,
            event_package_id: packageId,
            card_id: selectedWalletId,

            total_amount: Math.abs(Number(modifyCharges?.totalAmount ?? 0)),
            fees_percentage_from_user: Math.abs(
              Number(modifyCharges?.feesPercentageFromUser ?? 0)
            ),
            fees_amount_from_user: Math.abs(
              Number(modifyCharges?.feesAmountFromUser ?? 0)
            ),
            total_paid_amount: Math.abs(
              Number(modifyCharges?.totalPaidAmount ?? 0)
            ),

            booking_date: event?.event?.start_date,
            start_date_time: getDateNtime(
              event?.event?.start_date,
              event?.event?.start_time
            ),
            end_date_time: getDateNtime(
              event?.event?.end_date,
              event?.event?.end_time
            ),

            sales_tax_percentage: Number(
              modifyCharges?.salesTaxPercentage ?? 0
            ),
            sales_tax_amount_from_user: Math.abs(
              Number(modifyCharges?.salesTaxAmountFromUser ?? 0)
            ),

            sales_tax_id: fixTax?.tax_id,
            booking_id: bookingId,
            modify_booking_payment_info: modifyBookingInfo,
            state: stateNameA?.state,
            payment_date: new Date(),
            current_timezone: momentZone,
            location: {
              type: "Point",
              coordinates: [latlong?.long, latlong?.lat],
            },
            country: stateNameA?.country,
            stripe_charge_id: modifyBookingInfo?.is_refund
              ? myBookingDetails?.bookingDetails?.payment_history_id
                  ?.stripe_charge_id
              : null,
            stripe_payment_intent_id: modifyBookingInfo?.is_refund
              ? myBookingDetails?.bookingDetails?.payment_history_id
                  ?.stripe_payment_intent_id
              : null,
            stripe_transfer_id: modifyBookingInfo?.is_refund
              ? myBookingDetails?.bookingDetails?.payment_history_id
                  ?.stripe_transfer_id
              : "",
            // modify_booking_payment_info: {
            //     old_participate: Number(myBookingDetails?.bookingDetails?.no_of_participants ?? 0),
            //     new_participate: participants ?? 0,
            //     old_amount: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount ?? 0)?.toFixed(2),
            //     new_amount: modifyCharges?.amount ?? 0,
            //     old_fees_percentage_from_user: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user ?? 0),
            //     old_fees_amount_from_user: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user ?? 0)?.toFixed(2),
            //     old_sales_tax_percentage: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_percentage ?? 0),
            //     old_sales_tax_amount_from_user: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_amount_from_user ?? 0)?.toFixed(2),
            //     fees_percentage_from_user: modifyCharges?.modifyPercentage ?? 0,
            //     fees_amount_from_user: Number(modifyCharges?.modifyAmount ?? 0)?.toFixed(2),
            //     sales_tax_percentage: modifyCharges?.systemPercentage ?? 0,
            //     sales_tax_amount_from_user: Number(modifyCharges?.systemAmount ?? 0)?.toFixed(2),
            //     old_total_paid_amount: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.total_paid_amount ?? 0),
            //     new_total_paid_amount: Number(modifyCharges?.total ?? 0)?.toFixed(2)
            // }
            // modify_booking_payment_info: {
            //     old_participate: myBookingDetails?.bookingDetails?.no_of_participants ?? 0,
            //     new_participate: participants ?? 0,
            //     old_amount: myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount ?? 0,
            //     new_amount: modifyCharges?.amount ?? 0,
            //     old_fees_percentage_from_user: myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user ?? 0,
            //     old_fees_amount_from_user: myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user ?? 0,
            //     old_sales_tax_percentage: myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_percentage ?? 0,
            //     old_sales_tax_amount_from_user: myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_amount_from_user ?? 0,
            //     fees_percentage_from_user: modifyCharges?.modifyPercentage ?? 0,
            //     fees_amount_from_user: modifyCharges?.modifyAmount ?? 0,
            //     sales_tax_percentage: modifyCharges?.systemPercentage ?? 0,
            //     sales_tax_amount_from_user: modifyCharges?.systemAmount ?? 0,
            //     old_total_paid_amount: myBookingDetails?.bookingDetails?.booking_payment_info?.total_paid_amount ?? 0,
            //     new_total_paid_amount: modifyCharges?.total ?? 0
            // }
          };
          // dispatch(bookEvent(payload, (a) => {
          //     setBtnLoader(a)
          // }))

          if (modifyBookingInfo?.is_refund) {
            dispatch(
              bookEventRequest(payload, (a) => {
                setBtnLoader(a);
                navigate(-1)
              })
            );
          } else {
            localStorage.setItem("EventModifyData", JSON.stringify(payload));
          }
        }
      }
    }
  };

  const handleDeleteCard = () => {
    dispatch(deleteCards(CardSelectId));
    setSelectedWalletId(null);
    setTimeout(() => {
      getAllCards();
    }, 500);
  };

  const getSelectedPackage = () => {
    const selectedPackage = event?.event?.package_details?.find((ele) => {
      if (ele._id == packageId) {
        return ele;
      }
    });

    return selectedPackage;
  };

  const texCount = (totalAmount, network, tax, key) => {
    const taxPercentage = tax;

    const normaltex = totalAmount * (taxPercentage / 100);

    const nexText = totalAmount * (network / 100);

    const plusText = loginUserDetails?.is_ambassador
      ? taxPercentage
      : taxPercentage + network;

    // Convert tax percentage to decimal
    const taxDecimal = plusText / 100;

    // Calculate the tax amount
    const taxAmount = totalAmount * taxDecimal;

    // Calculate the updated total amount with tax included
    const totalAmountWithTax = totalAmount + taxAmount;

    if (key == "tax") {
      return taxAmount?.toFixed(2);
    } else if (key == "amount") {
      return totalAmountWithTax?.toFixed(2);
    } else if (key == "network") {
      return nexText.toFixed(2);
    } else if (key == "oneTax") {
      return normaltex.toFixed(2);
    } else {
      return "00";
    }
  };

  const handleAddCard = () => {
    const date = moment(addCard?.day?.split("/")[1], "YY");
    const year = date.format("YYYY");
    const payload = {
      name: addCard.name,
      number: addCard.number,
      year: year,
      month: moment(addCard.day, "MM/YY").format("MM"),
      cvv: addCard.cvv,
    };
    dispatch(saveCards(payload));
    setAddCard({
      name: "",
      number: "",
      day: "",
      cvv: "",
    });
    // setTimeout(() => {
    //     getAllCards()
    // }, 500);
  };

  const getPackageName = (id) => {
    const name = event?.event?.package_details?.find((ele) => {
      if (ele._id == id) {
        return ele;
      }
    });

    return name?.package_name;
  };

  const cardNumberReplace = (number) => {
    const numberStr = number.replace(/.(?=.{4})/g, "X");
    return numberStr;
  };

  const getAllCards = () => {
    if (userAuth?.token) {
      dispatch(cardsLists());
    }
  };

  useEffect(() => {
    getSelectedPackage();
  }, [event]);

  useEffect(() => {
    geteventInfo();
    getAllCards();
    return () => {
      setIsCheching(false);
    };
  }, []);

  const [center, setCenter] = useState();

  useEffect(() => {
    if ("geolocation" in navigator) {
      //check geolocation available
      //try to get user current location using getCurrentPosition() method
      navigator?.geolocation?.getCurrentPosition(async (position) => {
        // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
        await setCenter({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    } else {
      console.log("Browser doesn't support geolocation!");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;

              const googleMapApiKey = "AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs";

              const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapApiKey}`;
              const response = await fetch(apiUrl);

              if (response.ok) {
                const data = await response.json();

                // Check if the response contains results
                if (data.status === "OK") {
                  // Extract the state from the address components
                  const results = data.results || [];
                  for (const result of results) {
                    const addressComponents = result.address_components || [];
                    for (const component of addressComponents) {
                      const types = component.types || [];
                      if (types.includes("administrative_area_level_1")) {
                        setStateName(component.short_name);
                        return;
                      }
                    }
                  }
                }
              } else {
                console.error(
                  `Failed to fetch data Status: ${response.status}`
                );
              }
            },
            (error) => {
              console.error("Error getting geolocation:", error);
            }
          );
        } else {
          console.log("Geolocation is not supported");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const openSiteInfo = () => {
    alert(
      "Please provide location permission.\nTo manage location permissions, click on the lock icon next to the website's URL in the browser's address bar."
    );
    // For Firefox:
    // window.location = 'about:preferences#privacy';
  };

  const gateData = () => {
    const payload = {
      id: bookingId,
      latLong: center,
    };
    dispatch(getBookingDetails(payload));
  };

  useEffect(() => {
    if (bookingId) {
      gateData();
    }
  }, [bookingId]);

  const checkRuls = () => {
    // console.log(myBookingDetails?.bookingDetails, "DateTime")
    if ((myBookingDetails?.bookingDetails?.modified_count ?? 0) >= 2) {
      ///This will  calculate the 2 time modified payment case.
      // Rule_2,

      const payload = {
        ruls: Rules?.Rule_2,
        packagePrice: Number(getSelectedPackage()?.value_per_person),
        previousPrice: Number(
          myBookingDetails?.bookingDetails?.payment_history_id?.total_amount ??
            0
        ),
        totalParticipants: participants,
        previous_participants:
          myBookingDetails?.bookingDetails?.no_of_participants,
        is_ambassador: loginUserDetails?.is_ambassador,
        systemCharge: calendar?.systemCharge,
        newParticipants:
          participants -
          (myBookingDetails?.bookingDetails?.no_of_participants ?? 0),
        tax_rate: fixTax?.tax,
        tax_id: fixTax?.tax_id,
        old_fees_percentage_from_user:
          myBookingDetails?.bookingDetails?.payment_history_id
            ?.fees_percentage_from_user,
        old_fees_amount_from_user: Number(
          myBookingDetails?.bookingDetails?.payment_history_id
            ?.fees_amount_from_user ?? 0
        )?.toFixed(2),
        old_sales_tax_percentage:
          myBookingDetails?.bookingDetails?.payment_history_id
            ?.sales_tax_percentage ?? 0,
        old_sales_tax_amount_from_user: Number(
          myBookingDetails?.bookingDetails?.payment_history_id
            ?.sales_tax_amount_from_user ?? 0
        )?.toFixed(2),
        totalPaidAmount: Number(
          myBookingDetails?.bookingDetails?.payment_history_id
            ?.total_paid_amount ?? 0
        )?.toFixed(2),
        is_package_change: !(
          packageId == myBookingDetails?.bookingDetails?.event_package_id
        ),
      };
      priceCalculations(
        payload,
        setModifyCharges,
        setModifyBookingInfo,
        refCodeData,
        discountDetails
      );
    } else {
      const currentTime = new Date();
      const oneHourLater = new Date(currentTime.getTime() + 60 * 60 * 1000); // Calculate 1 hour later

      if (
        currentTime < myBookingDetails?.bookingDetails?.event_id?.start_date &&
        myBookingDetails?.bookingDetails?.event_id?.start_date < oneHourLater
      ) {
        ///This will calculate the 1 hour ago payment case.
        // Rule_1,

        const payload = {
          ruls: Rules?.Rule_1,
          packagePrice: Number(getSelectedPackage()?.value_per_person),
          previousPrice: Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.total_amount ?? 0
          ),
          totalParticipants: participants,
          previous_participants:
            myBookingDetails?.bookingDetails?.no_of_participants,
          is_ambassador: loginUserDetails?.is_ambassador,
          systemCharge: calendar?.systemCharge,
          newParticipants:
            participants -
            (myBookingDetails?.bookingDetails?.no_of_participants ?? 0),
          tax_rate: fixTax?.tax,
          tax_id: fixTax?.tax_id,
          old_fees_percentage_from_user:
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_percentage_from_user,
          old_fees_amount_from_user: Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_amount_from_user ?? 0
          )?.toFixed(2),
          old_sales_tax_percentage:
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.sales_tax_percentage ?? 0,
          old_sales_tax_amount_from_user: Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.sales_tax_amount_from_user ?? 0
          )?.toFixed(2),
          totalPaidAmount: Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.total_paid_amount ?? 0
          )?.toFixed(2),
          is_package_change: !(
            packageId == myBookingDetails?.bookingDetails?.event_package_id
          ),
        };
        priceCalculations(
          payload,
          setModifyCharges,
          setModifyBookingInfo,
          refCodeData,
          discountDetails
        );
      } else {
        ///This will  calculate the 24 hour payment case.
        // Rule_3,

        const payload = {
          ruls: Rules?.Rule_3,
          packagePrice: Number(getSelectedPackage()?.value_per_person),
          previousPrice: Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.total_amount ?? 0
          ),
          totalParticipants: participants,
          previous_participants:
            myBookingDetails?.bookingDetails?.no_of_participants,
          is_ambassador: loginUserDetails?.is_ambassador,
          systemCharge: calendar?.systemCharge,
          newParticipants:
            participants -
            (myBookingDetails?.bookingDetails?.no_of_participants ?? 0),
          tax_rate: fixTax?.tax,
          tax_id: fixTax?.tax_id,
          old_fees_percentage_from_user:
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_percentage_from_user,
          old_fees_amount_from_user: Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.fees_amount_from_user ?? 0
          )?.toFixed(2),
          old_sales_tax_percentage:
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.sales_tax_percentage ?? 0,
          old_sales_tax_amount_from_user: Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.sales_tax_amount_from_user ?? 0
          )?.toFixed(2),
          totalPaidAmount: Number(
            myBookingDetails?.bookingDetails?.payment_history_id
              ?.total_paid_amount ?? 0
          )?.toFixed(2),
          is_package_change: !(
            packageId == myBookingDetails?.bookingDetails?.event_package_id
          ),
        };
        priceCalculations(
          payload,
          setModifyCharges,
          setModifyBookingInfo,
          refCodeData,
          discountDetails
        );
      }
    }
  };

  useEffect(() => {
    if (bookingId) {
      // modifyCount()
      checkRuls();
    }
  }, [myBookingDetails?.bookingDetails, getSelectedPackage(), discountDetails]);

  const checkRefCode = () => {
    dispatch(getUserRefCode(providerId));
  };

  useEffect(() => {
    checkRefCode();
  }, [providerId]);

  useEffect(() => {
    if (refDescount?.userRefCodeData) {
      get_referral_code_percentage(
        refDescount?.userRefCodeData,
        setRefCodeData
      );
    }
  }, [refDescount?.userRefCodeData]);

  // .......

  const handleCheckdiscountCode = () => {
    // setLoader(true)
    const payload = {
      discount_code_string: isDiscountCode, // provider_id: userDetails?.details?._id,
      event_id: event?.event?._id,
      provider_id: event?.event?.user_id?._id,
      is_event: true,
    };
    dispatch(checkDiscountCode(payload, () => {}));
  };

  const handleSetDiscount = () => {
    if (discountCode?.checkCodeDetails?.discount_code) {
      setDiscountDetails({
        is_admin_created: discountCode?.checkCodeDetails?.is_admin_created,
        is_expire: false,
        discount_percentage:
          discountCode?.checkCodeDetails?.discount_percentage,
        discount_code: discountCode?.checkCodeDetails?.discount_code,
        _id: discountCode?.checkCodeDetails?._id,
        is_discount: true,
      });
    } else {
      setDiscountDetails({
        is_admin_created: false,
        is_expire: true,
        discount_percentage: 0,
        discount_code: null,
        _id: null,
        is_discount: false,
      });
    }
  };

  const clianData = () => {
    setDiscountDetails({
      is_admin_created: false,
      is_expire: true,
      discount_percentage: 0,
      discount_code: null,
      _id: null,
    });
  };

  useEffect(() => {
    return () => {
      clianData();
      dispatch({
        type: discountConstant.CLEAN_DISCOUNT_CODE,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    handleSetDiscount();
  }, [discountCode?.checkCodeDetails]);

  return (
    <>
      <div>
        <section className="main-page">
          {!userAuth?.token ? <GuestHeader /> : <Header select={"Home"} />}
          <div className="main-inner">
            <div className="container">
              <div
                style={{
                  height: "calc(100vh - 227px)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p className="t-30-bold">Event Modify</p>
                  {!userAuth?.token && (
                    <div className="inner-gred  box_shadow mb-5">
                      <p className="m-0 t-24-bold">Basic Account Info </p>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className="group-input mt-3">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.25 6C15.25 7.79493 13.7949 9.25 12 9.25V10.75C14.6234 10.75 16.75 8.62335 16.75 6H15.25ZM12 9.25C10.2051 9.25 8.75 7.79493 8.75 6H7.25C7.25 8.62335 9.37665 10.75 12 10.75V9.25ZM8.75 6C8.75 4.20507 10.2051 2.75 12 2.75V1.25C9.37665 1.25 7.25 3.37665 7.25 6H8.75ZM12 2.75C13.7949 2.75 15.25 4.20507 15.25 6H16.75C16.75 3.37665 14.6234 1.25 12 1.25V2.75ZM9 13.75H15V12.25H9V13.75ZM15 20.25H9V21.75H15V20.25ZM9 20.25C7.20507 20.25 5.75 18.7949 5.75 17H4.25C4.25 19.6234 6.37665 21.75 9 21.75V20.25ZM18.25 17C18.25 18.7949 16.7949 20.25 15 20.25V21.75C17.6234 21.75 19.75 19.6234 19.75 17H18.25ZM15 13.75C16.7949 13.75 18.25 15.2051 18.25 17H19.75C19.75 14.3766 17.6234 12.25 15 12.25V13.75ZM9 12.25C6.37665 12.25 4.25 14.3766 4.25 17H5.75C5.75 15.2051 7.20507 13.75 9 13.75V12.25Z"
                                  fill="#363853"
                                />
                              </svg>
                              <input
                                type="text"
                                required
                                placeholder="Full Name"
                                value={guestUser.name.replace(/\d/g, "")}
                                onChange={(e) => {
                                  if (e.target.value.length <= 25) {
                                    setGuestUser((pre) => ({
                                      ...pre,
                                      name: e.target.value,
                                    }));
                                  }
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={6}>
                            <div className="group-input mt-3">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="Icons">
                                  <path
                                    id="Vector"
                                    d="M2.88539 8.84875C3.55805 6.13983 5.70602 4.04534 8.43056 3.44162L8.88443 3.34105C10.9366 2.88632 13.0634 2.88632 15.1156 3.34105L15.5694 3.44162C18.294 4.04534 20.442 6.13984 21.1146 8.84875C21.6285 10.9182 21.6285 13.0819 21.1146 15.1512C20.442 17.8602 18.294 19.9547 15.5694 20.5584L15.1156 20.659C13.0634 21.1137 10.9366 21.1137 8.88443 20.659L8.43056 20.5584C5.70601 19.9547 3.55805 17.8602 2.88539 15.1513C2.37154 13.0819 2.37154 10.9181 2.88539 8.84875Z"
                                    stroke="#363853"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M21.1146 15.1512C21.6285 13.0819 21.6285 10.9182 21.1146 8.84875C20.442 6.13984 18.294 4.04534 15.5694 3.44162L15.1156 3.34105C13.0634 2.88632 10.9366 2.88632 8.88443 3.34105L8.43056 3.44162C5.70602 4.04534 3.55805 6.13983 2.88539 8.84875C2.37154 10.9181 2.37154 13.0819 2.88539 15.1513C3.55805 17.8602 5.70601 19.9547 8.43056 20.5584L8.88443 20.659"
                                    stroke="#363853"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    id="Vector_3"
                                    d="M8.15112 10.3501L10.7216 12.1866C11.4864 12.7329 12.5136 12.7329 13.2783 12.1866L15.8489 10.3501"
                                    stroke="#363853"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                  />
                                </g>
                              </svg>
                              <input
                                type="email"
                                required
                                placeholder="Email Address"
                                value={guestUser.email}
                                onChange={(e) => {
                                  setGuestUser((pre) => ({
                                    ...pre,
                                    email: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={6}>
                            <div className="group-input mt-3">
                              <PhoneInput
                                placeholder="Phone Number"
                                enableSearch={true}
                                value={`${guestUser.country_code}${guestUser.mobile_number}`}
                                onChange={(phone, data) => {
                                  setGuestUser((pre) => ({
                                    ...pre,
                                    mobile_number: phone.slice(
                                      data?.dialCode?.length
                                    ),
                                    country_code: data.dialCode,
                                  }));
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  )}

                  <div className="inner-gred box_shadow mt-4">
                    <h3>Payment Details</h3>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6} className="mt-3">
                        <span>Booking Details</span>
                        <div className="border-gred w-100 mt-2">
                          <div className="inner-gred w-100">
                            <div className="d-flex justify-content-between mt-1">
                              <span className="review-Name">Date</span>
                              <span className="review-Name">
                                {moment(event?.event?.start_date).format(
                                  "D MMMM YYYY"
                                )}{" "}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between mt-1">
                              <span className="review-Name">Time</span>
                              <span className="review-Name">
                                {moment(event?.event?.start_time).format("LT")}{" "}
                                to {moment(event?.event?.end_time).format("LT")}
                              </span>
                            </div>
                            {event?.event?.is_virtual_event ? (
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">Event Type</span>
                                <span
                                  className="review-Name"
                                  style={{
                                    width: "75%",
                                    textAlign: "end",
                                  }}
                                >
                                  Virtual
                                </span>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">Location</span>
                                <span
                                  className="review-Name"
                                  style={{
                                    width: "75%",
                                    textAlign: "end",
                                  }}
                                >
                                  {event?.event?.address}
                                </span>
                              </div>
                            )}
                            <div className="d-flex justify-content-between mt-1">
                              <span className="review-Name">Package</span>
                              <span
                                className="review-Name"
                                style={{
                                  width: "75%",
                                  textAlign: "end",
                                }}
                              >
                                {getPackageName(packageId)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span>Payment Details </span>
                          <div className="border-gred w-100 mt-2">
                            <div className="inner-gred w-100">
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">
                                  Participants
                                </span>
                                <span className="review-Name">
                                  {
                                    myBookingDetails?.bookingDetails
                                      ?.no_of_participants
                                  }
                                </span>
                              </div>
                              {myBookingDetails?.payment_history_id
                                ?.discount_code_info?.is_discount == true &&
                              myBookingDetails?.payment_history_id
                                ?.discount_code_info?.is_admin_discount ==
                                false ? (
                                <div className="d-flex justify-content-between mt-1">
                                  <div className="d-flex flex-column">
                                    <span className="review-Name">Amount </span>
                                    <p className="yelloe-t-bold t-14-500 m-0">
                                      You have received a{" "}
                                      {myBookingDetails?.payment_history_id
                                        ?.discount_percentage ?? 0}
                                      % discount for using the discount code.
                                    </p>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <span
                                      style={{
                                        color: "#9E9E9E",
                                        textDecoration: "line-through",
                                      }}
                                      className="review-Name"
                                    >
                                      ${" "}
                                      {Number(
                                        myBookingDetails?.payment_history_id
                                          ?.discount_code_info
                                          ?.amount_without_discount ?? 0
                                      ).toFixed(2)}
                                    </span>
                                    <span className="review-Name">
                                      ${" "}
                                      {Number(
                                        myBookingDetails?.bookingDetails
                                          ?.payment_history_id?.total_amount ??
                                          0
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-between mt-1">
                                  <span className="review-Name">Amount </span>
                                  <span className="review-Name">
                                    ${" "}
                                    {Number(
                                      myBookingDetails?.bookingDetails
                                        ?.payment_history_id?.total_amount
                                    ).toFixed(2)}
                                  </span>
                                </div>
                              )}

                              {previousNetworkFees(myBookingDetails)}

                              {/*{myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.is_refree ?*/}
                              {/*    <div>*/}
                              {/*        <div*/}
                              {/*            className="d-flex justify-content-between mt-1">*/}
                              {/*            <span*/}
                              {/*                className="review-Name">Network fees ( {myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user}% )*/}
                              {/*            </span>*/}
                              {/*            <div className="d-flex gap-1">*/}
                              {/*                <span className="review-Name" style={{*/}
                              {/*                    color: '#9E9E9E',*/}
                              {/*                    textDecoration: 'line-through'*/}
                              {/*                }}>$ {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_amount}</span>*/}
                              {/*                <span*/}
                              {/*                    className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user).toFixed(2)}</span>*/}
                              {/*            </div>*/}
                              {/*        </div>*/}
                              {/*        <p className="yelloe-t-bold t-14-500 m-0">You have*/}
                              {/*            received*/}
                              {/*            a {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_percentage}%*/}
                              {/*            discount for using the referral code.</p>*/}
                              {/*    </div> :*/}
                              {/*    <div className="d-flex justify-content-between mt-1">*/}
                              {/*        <span*/}
                              {/*            className="review-Name">Network fees ( {myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user}% )</span>*/}
                              {/*        <span*/}
                              {/*            className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user).toFixed(2)}</span>*/}
                              {/*    </div>}*/}
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">
                                  Tax{" "}
                                  {`( ${myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_percentage}% )`}
                                </span>
                                <span className="review-Name">
                                  ${" "}
                                  {Number(
                                    myBookingDetails?.bookingDetails
                                      ?.payment_history_id
                                      ?.sales_tax_amount_from_user
                                  ).toFixed(2)}
                                </span>
                              </div>
                              <span className="review-Name text-dark-gray t-10-500">
                                {loginUserDetails?.is_ambassador
                                  ? "( As an ambassador user. you are not liable for any charges. )"
                                  : ""}
                              </span>

                              <hr style={{ border: "1px dashed black" }} />
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">
                                  Total Amount
                                </span>
                                <span className="review-Name">
                                  ${" "}
                                  {Number(
                                    myBookingDetails?.bookingDetails
                                      ?.payment_history_id?.total_paid_amount
                                  ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} className="mt-3">
                        {/*    ........ apply........  */}

                        {!modifyBookingInfo?.is_refund && (
                          <div style={{ marginTop: "33px" }}>
                            <div
                              className="border-gred"
                              style={{ padding: "2px" }}
                            >
                              <div
                                className="session-input d-flex"
                                style={{
                                  padding: "0px 0px 0px 10px",
                                  height: "44px",
                                }}
                              >
                                <div className="p-1 w-100 d-flex">
                                  <input
                                    type="text"
                                    disabled={
                                      discountDetails?.is_expire == false
                                    }
                                    value={isDiscountCode}
                                    placeholder="Enter Discount code"
                                    className="showArrow"
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                      setIsDiscountCode(e.target.value);
                                    }}
                                  />
                                </div>
                                {discountDetails?.is_expire == true ? (
                                  <button
                                    onClick={() => handleCheckdiscountCode()}
                                    style={{
                                      background:
                                        "linear-gradient(180deg, #0DBCEC 0%, #FFC056 100%)",
                                      padding: "8px 26px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Apply
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}

                        <div>
                          <span>
                            {modifyBookingInfo?.is_refund
                              ? "Refund Payment Details"
                              : "Modify Payment Details"}
                          </span>
                          <div className="border-gred w-100 mt-2">
                            <div className="inner-gred w-100">
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">
                                  Participants
                                </span>
                                <span className="review-Name">
                                  {Math.abs(modifyBookingInfo?.new_participate)}
                                </span>
                              </div>

                              {modifyBookingInfo?.is_discount == true &&
                              modifyBookingInfo?.is_admin_discount == false ? (
                                <div className="d-flex justify-content-between mt-1">
                                  <div className="d-flex flex-column">
                                    <span className="review-Name">Amount </span>
                                    <p className="yelloe-t-bold t-14-500 m-0">
                                      You have received a{" "}
                                      {discountDetails?.discount_percentage ??
                                        0}
                                      % discount for using the discount code.
                                    </p>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <span
                                      style={{
                                        color: "#9E9E9E",
                                        textDecoration: "line-through",
                                      }}
                                      className="review-Name"
                                    >
                                      ${" "}
                                      {Number(
                                        modifyCharges?.amount_without_discount ??
                                          0
                                      ).toFixed(2)}
                                    </span>
                                    <span className="review-Name">
                                      ${" "}
                                      {Number(
                                        modifyBookingInfo?.new_amount ?? 0
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-between mt-1">
                                  <span className="review-Name">Amount </span>
                                  <span className="review-Name">
                                    ${" "}
                                    {Number(
                                      modifyBookingInfo?.new_amount ?? 0
                                    ).toFixed(2)}
                                  </span>
                                </div>
                              )}
                              {/*<div className="d-flex justify-content-between mt-1">*/}
                              {/*<span*/}
                              {/*    className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}</span>*/}
                              {/*    <span*/}
                              {/*        className="review-Name">$ {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(2)}</span>*/}
                              {/*</div>*/}

                              {/*<div*/}
                              {/*    className="d-flex justify-content-between align-items-center mt-1">*/}
                              {/*    <div>*/}
                              {/*            <span*/}
                              {/*                className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}</span>*/}
                              {/*        {refCodeData?.referee?.is_expire ? null :*/}
                              {/*            <p className="yelloe-t-bold t-14-500 m-0">You*/}
                              {/*                have received*/}
                              {/*                a {refCodeData?.referee?.percentage}%*/}
                              {/*                discount for using the referral*/}
                              {/*                code.</p>}*/}
                              {/*    </div>*/}
                              {/*    <div className="d-flex gap-2">*/}
                              {/*        {refCodeData?.referee?.is_expire ? null : <span*/}
                              {/*            className="review-Name" style={{*/}
                              {/*            color: '#9E9E9E', textDecoration: 'line-through'*/}
                              {/*        }}>$ {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}</span>}*/}
                              {/*        <span*/}
                              {/*            className="review-Name">$ {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(2)}</span>*/}
                              {/*    </div>*/}
                              {/*</div>*/}

                              {modifyNetworkFees(
                                loginUserDetails,
                                refCodeData,
                                modifyBookingInfo
                              )}
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">
                                  Tax{" "}
                                  {`( ${modifyBookingInfo?.sales_tax_percentage}% )`}
                                </span>
                                <span className="review-Name">
                                  ${" "}
                                  {Number(
                                    modifyBookingInfo?.sales_tax_amount_from_user ??
                                      0
                                  ).toFixed(2)}
                                </span>

                                {/*<span*/}
                                {/*    className="review-Name">$ {texCount((Number(getSelectedPackage()?.value_per_person * participants)), Number(calendar?.systemCharge?.tem_fees), Number(fixTax?.tax), "oneTax") }</span>*/}
                              </div>
                              <span className="review-Name text-dark-gray t-10-500">
                                {loginUserDetails?.is_ambassador
                                  ? "( As an ambassador user. you are not liable for any Fees charges. )"
                                  : ""}
                              </span>

                              <hr style={{ border: "1px dashed black" }} />
                              <div className="d-flex justify-content-between mt-1">
                                <span className="review-Name">
                                  Total Amount
                                </span>
                                <span className="review-Name">
                                  ${" "}
                                  {Number(
                                    modifyBookingInfo?.new_total_paid_amount ??
                                      0
                                  ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      {/*<Grid item xs={12} md={6}>*/}
                      {/*    <div className='inner-gred box_shadow '>*/}
                      {/*        <div*/}
                      {/*            className='d-flex mb-3 w-100 align-items-center justify-content-center gap-2'>*/}
                      {/*            <img src={cardIcon} alt=""/>*/}
                      {/*            <p className='m-0 input-label'>{!userAuth?.token ? "Payment" : "Credit / Debit Card"}</p>*/}
                      {/*        </div>*/}

                      {/*        {userAuth?.token && wallet?.cardlist?.map((e, i) => (*/}
                      {/*            <div*/}
                      {/*                className={`inner-gred box_shadow d-flex justify-content-between mt-2 ${selectedWalletId == e._id && "yellow_border"}`}*/}
                      {/*                key={i} onClick={() => setSelectedWalletId(e._id)}>*/}
                      {/*                <div className='d-flex align-items-center gap-3'>*/}
                      {/*                    <img src={cardImg} alt=""/>*/}
                      {/*                    <div className='d-flex flex-column'>*/}
                      {/*                        <h6 className="cardName">{cardNumberReplace(e.card_number)}</h6>*/}
                      {/*                        <span*/}
                      {/*                            className="cardexpire">{`Expires on ${e.expire_month}/${e.expire_year}`}</span>*/}
                      {/*                    </div>*/}
                      {/*                </div>*/}
                      {/*                <IconButton onClick={(event) => {*/}
                      {/*                    handleWalletMenuClick(event)*/}
                      {/*                    setCardSelectId(e._id)*/}
                      {/*                }} aria-describedby={walletId}><MoreVertIcon/></IconButton>*/}
                      {/*            </div>))}*/}

                      {/*        {userAuth?.token &&*/}
                      {/*            <p className='d-flex w-100 input-label mt-4 align-items-center justify-content-center'>Add*/}
                      {/*                Card</p>}*/}
                      {/*        <div>*/}
                      {/*            <Grid container spacing={2}>*/}
                      {/*                <Grid item xs={12}>*/}
                      {/*                    <div className="group-input mt-2 p-15 clearfix">*/}
                      {/*                        <input*/}
                      {/*                            onChange={(e) => {*/}
                      {/*                                if (e.target.value.length <= 20) {*/}
                      {/*                                    setAddCard((pre) => ({*/}
                      {/*                                        ...pre, name: e.target.value*/}
                      {/*                                    }))*/}
                      {/*                                }*/}
                      {/*                            }}*/}
                      {/*                            type="text"*/}
                      {/*                            value={addCard.name.replace(/\d/g, '')}*/}
                      {/*                            name=""*/}
                      {/*                            placeholder="Card Name"*/}
                      {/*                        />*/}
                      {/*                    </div>*/}
                      {/*                </Grid>*/}
                      {/*                <Grid item xs={12}>*/}
                      {/*                    <div className="group-input mt-2 p-15 clearfix">*/}
                      {/*                        <input*/}
                      {/*                            type="text"*/}
                      {/*                            value={addCard.number}*/}
                      {/*                            onChange={(e) => {*/}
                      {/*                                if (e.target.value.length <= 16) {*/}
                      {/*                                    setAddCard((pre) => ({*/}
                      {/*                                        ...pre, number: e.target.value*/}
                      {/*                                    }))*/}
                      {/*                                }*/}
                      {/*                            }}*/}
                      {/*                            name=""*/}
                      {/*                            placeholder="Card Number"*/}
                      {/*                        />*/}
                      {/*                    </div>*/}
                      {/*                </Grid>*/}
                      {/*                <Grid item xs={6}>*/}
                      {/*                    <div className="group-input mt-2 p-15 clearfix">*/}
                      {/*                        <input*/}
                      {/*                            value={addCard.day.replace(*/}
                      {/*                                /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/*/}
                      {/*                            ).replace(*/}
                      {/*                                /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/*/}
                      {/*                            ).replace(*/}
                      {/*                                /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3*/}
                      {/*                            ).replace(*/}
                      {/*                                /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41*/}
                      {/*                            ).replace(*/}
                      {/*                                /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0*/}
                      {/*                            ).replace(*/}
                      {/*                                /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`*/}
                      {/*                            ).replace(*/}
                      {/*                                /\/\//g, '/' // Prevent entering more than 1 `/`*/}
                      {/*                            )}*/}
                      {/*                            onChange={(e) => setAddCard((pre) => ({*/}
                      {/*                                ...pre, day: e.target.value*/}
                      {/*                            }))}*/}
                      {/*                            maxlength='5' placeholder="MM/YY" type="text"*/}
                      {/*                        />*/}
                      {/*                    </div>*/}
                      {/*                </Grid>*/}
                      {/*                <Grid item xs={6}>*/}
                      {/*                    <div className="group-input mt-2 p-15 clearfix">*/}
                      {/*                        <input*/}
                      {/*                            value={addCard?.cvv?.replace(/\s/g, '')}*/}
                      {/*                            onChange={(e) => {*/}
                      {/*                                if (e.target.value.length <= 4) {*/}
                      {/*                                    setAddCard((pre) => ({*/}
                      {/*                                        ...pre, cvv: e.target.value*/}
                      {/*                                    }))*/}
                      {/*                                }*/}
                      {/*                            }}*/}
                      {/*                            type="text"*/}
                      {/*                            name=""*/}
                      {/*                            placeholder="cvv"*/}
                      {/*                        />*/}
                      {/*                    </div>*/}
                      {/*                </Grid>*/}
                      {/*                {userAuth?.token && <Grid item xs={12}>*/}
                      {/*                    <div className="d-flex justify-content-center">*/}
                      {/*                        <div className=""*/}
                      {/*                             style={{width: "100%", maxWidth: "170px"}}>*/}
                      {/*                            <button*/}
                      {/*                                type="submit"*/}
                      {/*                                className="btn_admina delete-button"*/}
                      {/*                                onClick={handleAddCard}*/}
                      {/*                            >*/}
                      {/*                                Save*/}
                      {/*                            </button>*/}
                      {/*                        </div>*/}
                      {/*                        <div className=""*/}
                      {/*                             style={{width: "100%", maxWidth: "170px"}}>*/}
                      {/*                            <button*/}
                      {/*                                type="submit"*/}
                      {/*                                className="btn_admina delete-confirm-button"*/}
                      {/*                                // onClick={() => handleCancelCard()}*/}
                      {/*                            >*/}
                      {/*                                Cancel*/}
                      {/*                            </button>*/}
                      {/*                        </div>*/}
                      {/*                    </div>*/}
                      {/*                </Grid>}*/}
                      {/*            </Grid>*/}

                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*</Grid>*/}
                    </Grid>
                  </div>
                </div>
                <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">
                  <button
                    className="btn_White ms-lg-auto"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>

                  {btnLoader ? (
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className="btn_admina "
                    >
                      {/* save */}
                      <div className="loader"></div>
                    </a>
                  ) : (
                    <button
                      className="btn_admina"
                      onClick={() => {
                        if (modifyBookingInfo?.is_refund) {
                          handlebookEvent();

                        } else {
                          setIsPayment(true);
                          handlebookEvent();
                        }
                      }}
                    >
                      Modify Booking
                    </button>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </section>
      </div>
      <WalletCardPopup
        id={walletId}
        anchorEl={walletMenu}
        setwalletMenu={setwalletMenu}
        handleClose={handleWalletMenuClose}
        open={Walletopen}
        handleDeleteCard={handleDeleteCard}
      />
      {/* <BookingPopup
        open={isChecking}
        setOpen={setIsCheching}
        modify={event?.modifyedEvent?.success?.status}
      /> */}
      {isPayment ? (
        <Form
          //   data={data}
          open={isPayment}
          details={{
            ...event?.event,
            total_paid_amount:
              Math.abs(Number(modifyCharges?.totalPaidAmount ?? 0)) ?? 1,
              calculationData : modifyCharges
          }}
          handleClose={() => setIsPayment(false)}
          setOpen={() => {}}
          getNotificationsList={() => {}}
          paymentSuccessful={(responseData) => {
            handlebookEvent(responseData);
          }}
          isEvent={true}
        />
      ) : null}
    </>
  );
};
export default ModifyEventBooking;
