import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {upcomingEventsConstant} from "./constant";
import {toast} from "react-toastify";
// import {getMyBookingDetails} from "./myBokingConstant";

export const getUpcominEvents = (userId) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", userId);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.upcoming_events_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type : upcomingEventsConstant.GET_UPCOMING_EVENTS,
                payload : response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
}
export const getUpcominEventsForHire = (userId) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", userId);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.upcoming_events_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type : upcomingEventsConstant.GET_UPCOMING_EVENTS_FOR_HIRE,
                payload : response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const getGuestUpcominEvents = (userId) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("user_id", userId);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.upcoming_guest_events_list}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type : upcomingEventsConstant.GET_UPCOMING_EVENTS,
                payload : response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};
export const saveUnSaveEvent = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append( "event_id", data?.id);
        fdata.append("is_save_status", data?.status);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.saveUnSaveEvent}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            // dispatch(getMyBookingDetails())
            dispatch({
                type : upcomingEventsConstant.SAVE_UNSAVE_EVENTS,
                payload : response.data,
            });
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};