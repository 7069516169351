import React from 'react';

const Massage = () => {
    return(
        <>
            <div className="massage">
               <div>
                   <div className="massage_user">
                       <p className="text_massage_user m-0">hello</p>
                   </div>
                   <div className="massage_df">
                       <p className="text_massage_df m-0">hi, how are you?</p>
                   </div>
                   <div className="massage_user">
                       <p className="text_massage_user m-0">hello</p>
                   </div>
                   <div className="massage_df">
                       <p className="text_massage_df m-0">hi, how are you?</p>
                   </div>
                   <div className="massage_user">
                       <p className="text_massage_user m-0">hello</p>
                   </div>
                   <div className="massage_df">
                       <p className="text_massage_df m-0">hi, how are you?</p>
                   </div>
                   <div className="massage_user">
                       <p className="text_massage_user m-0">hello</p>
                   </div>
                   <div className="massage_df">
                       <p className="text_massage_df m-0">hi, how are you?</p>
                   </div>
                   <div className="massage_user">
                       <p className="text_massage_user m-0">hello</p>
                   </div>
                   <div className="massage_df">
                       <p className="text_massage_df m-0">hi, how are you?</p>
                   </div>
               </div>
            </div>
        </>
    )
}

export default Massage;