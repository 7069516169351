import React from 'react';
import {IconButton} from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import {Modal} from "react-bootstrap";

const UnComplatePopup = ({open, setOpen, seteditModal}) => {
    return(
        <Modal
            centered
            show={open}
            backdrop="static"
            onHide={() => {
                setOpen(false);
            }}
            dialogClassName="delete-modal"
        >
            <div className="Congratulations_msg p-3">

                <p className="t-20-500 text-center text-black mt-3">Your profile is incomplete. <br/> Please complete your profile</p>

                <div className="d-flex align-items-center justify-content-center">
                    <button
                        className="massageBtn mt-3"
                        style={{maxWidth: "346px", height: "45px"}}
                        onClick={() => {
                            setOpen(false)
                            seteditModal(true)
                        }}
                    >
                        Edit Profile
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default UnComplatePopup;