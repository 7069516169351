import { toast } from "react-toastify";
import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";
import {serviceConstant, userServiceConstant} from "./constant";

export const getservice = () => {
  return async (dispatch) => {
    let response;
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.service_list}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });


    if (response?.success) {
      dispatch({
        type: serviceConstant.GET_SERVICE,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
};

export const GetProviderService = (id) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append(
        "user_id",
        id
    );
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_user_service}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
        .then((response) => response.json())
        .then((data) => {
          response = data;
        })
        .catch((err) => {
          console.log(err);
        });

    if (response?.success) {
      dispatch({
        type: serviceConstant.GET_PROVIDER_SERVICE,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
}
export const guestGetProviderService = (id) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append(
        "user_id",
        id
    );
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_get_user_service}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
        .then((response) => response.json())
        .then((data) => {
          response = data;
        })
        .catch((err) => {
          console.log(err);
        });

    if (response?.success) {
      dispatch({
        type: serviceConstant.GET_PROVIDER_SERVICE,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
}