import React from 'react';
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../../assets/images/Discovery.svg";
import {Box, IconButton} from "@mui/material";
import saveIcon from "../../assets/images/save.svg";
import unsave from "../../assets/images/saveIcon.svg";
import {useNavigate} from "react-router-dom";
import {saveUnSaveEvent} from "../../action/upcomingEventConstant";
import {useDispatch} from "react-redux";
import {DateFormate, LocalFormate, timeZoneFormate} from "../../Utils/DateFormateUtils";
import userIcon from "../../assets/images/userIconWithBg.png"

const SaveEventCard = ({ele, gatSavedList}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const savedUnsaved = (id, status) => {
        dispatch(saveUnSaveEvent({id: id, status: status}))
        gatSavedList()
    }
    const handleNavigate = (id, proId) => {
        navigate(`/view-events/${id}/${proId}`)
    } 
    return (
        <>
            <Box>
                <div
                    className={`inner-gred box_shadow position-relative cursor-pointer`}
                    style={{cursor: 'pointer', background: ele?.is_pre_populate_event
                        ? "linear-gradient(270deg, rgba(218, 225, 174, 0.5) 0%, rgba(171, 213, 180, 0.5) 99.79%)"
                        : "rgba(255, 165, 0, 0.10)"}}>
                    <div className={`position-relative`}>
                        <div
                            className={`d-flex`}
                            onClick={() => handleNavigate(ele._id, ele?.user_id?._id)}
                        >
                            <div className="p_image_shape me-2 me-sm-3">
                                <img src={ele?.banner_image || userIcon} onError={(e) => (e.target.src = userIcon)}/>
                            </div>
                            <div style={{flexGrow: "1"}}>
                                <div
                                    className="d-flex justify-content-between align-items-center">
                                    <p className="t-16-bold m-0 mb-1">{ele?.event_name}</p>
                                </div>
                                <div
                                    className="d-flex flex-sm-row flex-column align-items-start align-items-sm-center mb-1">
                                    {/*<p className="pro_carousel_time m-0">{moment(LocalFormate(ele?.start_date)).format('MMM Do') !== moment(LocalFormate(ele?.end_date)).format('MMM Do') ? (`${moment(LocalFormate(ele?.start_date)).format('MMM Do')} - ${moment(LocalFormate(ele?.end_date)).format('MMM Do, YYYY')}`) : (`${moment(LocalFormate(ele?.start_date)).format('MMMM Do YYYY')}`)}</p>*/}
                                    <p className="pro_carousel_time m-0">{DateFormate(LocalFormate(ele?.start_time, ele?.date_timezone), LocalFormate(ele?.end_date, ele?.date_timezone))}</p>
                                    {/*<p className="pro_carousel_time m-0">{DateFormate(LocalFormate(ele?.start_date), LocalFormate(ele?.end_date))}</p>*/}
                                    <FiberManualRecordIcon className="d-none d-sm-block"
                                                           style={{transform: "scale(0.3)"}}/>
                                    <p className="pro_carousel_time m-0">{moment(LocalFormate(ele?.start_time, ele?.date_timezone)).format('LT')} to {moment(LocalFormate(ele?.end_time, ele?.date_timezone)).format('LT')} ({timeZoneFormate(ele?.start_time, ele?.date_timezone)})</p>
                                </div>
                                {(ele?.is_virtual_event !== true) ? <div className="d-flex align-items-center gap-1">
                                    <img src={locationIcon} alt=""/>
                                    <p className="yelloe-t-bold m-0">{ele?.address}</p>
                                </div> :
                                <div>
                                    <p className="t-14-bold">Type: Virtual</p>
                                </div>}
                                <div className="w-100 d-flex mt-3 row">
                                    <div className="col-sm-12 col-12"
                                         style={{maxWidth: "240px"}}>
                                        <button
                                            type="submit"
                                            className="btn_admina delete-confirm-button btnn white-border-btn"
                                            // onClick={() => HandleAcssept("decline", data?._id)}
                                        >
                                            Book Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="save-page-btn d-flex mt-3">*/}
                        {/*    <button*/}
                        {/*        type="submit"*/}
                        {/*        className="btn_admina delete-confirm-button white-border-btn"*/}
                        {/*    >*/}
                        {/*        Book Now*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        <div className="position-absolute" style={{
                            right: "0",
                            top: "0",
                        }}>
                            <IconButton style={{padding: "3px"}}
                                        onClick={() => {
                                            savedUnsaved(ele?._id, false)
                                        }}
                            >
                                <img src={saveIcon} alt=""
                                     width="28px"/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default SaveEventCard;