import React from 'react';
import image from "../assets/images/event.png";
import {Modal} from "react-bootstrap";
import DeleteImage from "../assets/images/sucsses-img.png";

const Claim_ContactUs_modle = ({open, setOpen}) => {
    return(
        <>
            <Modal
                centered
                // backdrop="static"
                show={open}
                onHide={() => {
                    setOpen(false);
                }}
                dialogClassName="delete-modal"
            >
                <div className="Congratulations_msg">
                    <div className="group-parent m-0" style={{ borderRadius: "10px" }}>
                        <div className="group " style={{ borderRadius: "10px" }}>
                            <div className="padding-inner pt-2" style={{ borderRadius: "10px", padding: "14px" }}>
                                <div className="text-center">
                                    <img
                                        className="img-fluid logo"
                                        alt=""
                                        src={DeleteImage}
                                    />
                                </div>
                                <h3 className="mb-3 mb-md-3 page-title text-center">
                                    Submitted successfully
                                </h3>

                                <p className="text-center mb-3 mb-md-5" style={{ color: "#000" }}>
                                    Thank You for submitting your request. We will review it in the shortest delay possible.
                                </p>
                                {/*<div className="w-100 d-flex">*/}
                                {/*    <div className="col-6">*/}
                                {/*        <button type="submit" className="btn_admina delete-button" onClick={() => {*/}
                                {/*            setOpen((pre) => ({...pre, link: false}));*/}
                                {/*        }} style={{lineHeight: "15px"}}>*/}
                                {/*            Cancel*/}
                                {/*        </button>*/}
                                {/*    </div>*/}
                                    {/*<div className="col-6">*/}
                                    {/*    <button*/}
                                    {/*        type="submit"*/}
                                    {/*        className="btn_admina delete-confirm-button"*/}
                                    {/*        onClick={() => {*/}
                                    {/*            onConfirm();*/}
                                    {/*            setOpen((pre) => ({...pre, link: false}));*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        Confirm*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Claim_ContactUs_modle;