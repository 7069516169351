import React, {useEffect, useState} from 'react';
import "./Chatbot.css";
import {Modal} from "react-bootstrap";
import closeIcon from "../../assets/images/close-circle.svg"
import {IconButton} from "@mui/material";
import sendIcon from "../../assets/images/PaperPlaneRight.png"
// import { ChatInput, ChatMessage } from './components';
// import { SessionsClient } from '@google-cloud/dialogflow';
import Massage from "./Massage";
import axios from "axios";
// import {response} from "../../reducer/chatReducer";

// const projectId = 'ad-anima-de6d7';
// const sessionId = 'your-session-id';
// const languageCode = 'en-US';
//
// const sessionsClient = new SessionsClient();
// const sessionPath = sessionsClient.projectAgentSessionPath(projectId, sessionId);


const Chatbot = ({open, setOpen}) => {

    // const [messages, setMessages] = useState([]);

    // const ChatComponent = () => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const projectId = 'formal-grammar-389900';
    const sessionId = Math.random().toString(36).substring(7);

    const apiUrl = `https://dialogflow.googleapis.com/v2/projects/${projectId}/agent/sessions/${sessionId}:detectIntent`;

    const sendMessage = async (text) => {
        try {
            const response = await axios.post(apiUrl, {
                queryInput: {
                    text: {
                        text,
                        languageCode: 'en-US',
                    },
                },
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer YOUR_ACCESS_TOKEN', // Replace YOUR_ACCESS_TOKEN with your actual access token
                },
            });

            const { fulfillmentText } = response.data.queryResult;
            setMessages([...messages, { text: fulfillmentText, isUser: false }]);
        } catch (error) {
            console.error('Error sending message to Dialogflow:', error);
        }
    };

    const handleSendMessage = (event) => {
        event.preventDefault()

        if (inputValue.trim() !== '') {
            setMessages([...messages, {text: inputValue, isUser: true}]);
            sendMessage(inputValue);
            setInputValue('');
        }
    };


    return (
        <>
            {/*<div className="chatbot">*/}

            {/*</div>*/}

            <div className="eventModal">
                <Modal
                    centered
                    size="lg"
                    backdrop="static"
                    show={open}
                    style={{zIndex: '100001', background: '#00000096'}}
                    onHide={() => {
                        setOpen(false);
                    }}
                    dialogClassName="question-modale"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <div style={{width: "100%", maxWidth: "728px"}}>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="m-0">Question</p>
                            <IconButton
                                style={{background: "rgba(238, 238, 238, 1)", width: '40px', height: '40px'}}>
                                <img src={closeIcon} alt=""/>
                            </IconButton>
                        </div>
                        <div className="chatbot_body collabrate-event-list">
                            <Massage/>
                        </div>
                        <div className="chatbot_footer">
                            <form className="w-100" onSubmit={(e) => handleSendMessage(e)}>
                                <div className="d-flex align-items-center g-2">
                                    <input type="text" onChange={(e) => setInputValue(e.target.value)}
                                           className="footer_input" placeholder="Start typing..."/>
                                    <IconButton type="submit">
                                        <img src={sendIcon} alt=""/>
                                    </IconButton>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/*<div className={"Congratulations_msg"} style={{width: "100%", maxWidth: "728px"}}>*/}
                    {/*<div className="inner-gred">*/}
                    {/**/}
                    {/*</div>*/}
                    {/*</div>*/}
                </Modal>
            </div>
        </>
    )
}

export default Chatbot;