import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import EventStoryTemp1 from "./InstaTemplate/EventStoryTemp1";
import EventPostTemp1 from "./InstaTemplate/EventPostTemp1";
import EventStoryTemp2 from "./InstaTemplate/EventStoryTemp2";
import EventPostTemp2 from "./InstaTemplate/EventPostTemp2";


import ServiceStoryTemp1 from "./InstaTemplate/ServiceStoryTemp1";
import ServiceStoryTemp2 from "./InstaTemplate/ServiceStoryTemp2";
import ServiceStoryTemp3 from "./InstaTemplate/ServiceStoryTemp3";
import ServicePostTemp1 from "./InstaTemplate/ServicePostTemp1";
import ServicePostTemp2 from "./InstaTemplate/ServicePostTemp2";
import ServicePostTemp3 from "./InstaTemplate/ServicePostTemp3";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import EventPostTemp3 from "./InstaTemplate/EventPostTemp3";
import EventStoryTemp3 from "./InstaTemplate/EventStoryTemp3";

const InstaTempModal = ({ open, setOpen, details, type }) => {
  const [tab, setTab] = useState("Story");
  const { eventData } = useSelector((state) => state.myEvent);

  const [svgs, setsvgs] = useState({ story: [], post: [] });
  const [loader, setLoader] = useState(false);

  const [selectedTemp, setselectedTemp] = useState({
    index: 0,
    svg: <></>,
  });

  console.log(details);
  useEffect(() => {
    setTab("Story")
    if (type == "Event") {
      setsvgs({
        ...svgs,
        story: [
          // <EventStoryTemp1 data={details} />,
          // <EventStoryTemp2 data={details} />,
          <EventStoryTemp3 data={details} />,
        ],
        post: [
          // <EventPostTemp1 data={details} />,
          // <EventPostTemp2 data={details} />,
          <EventPostTemp3 data={details} />,
        ],
      });
      setselectedTemp({
        index: 0,
        svg: <EventStoryTemp3 data={details} />,
      });
    } else {
      setsvgs({
        ...svgs,
        story: [
          // <ServiceStoryTemp1 data={details} />,
          // <ServiceStoryTemp2 data={details} />,
          <ServiceStoryTemp3 data={details} />,
        ],
        post: [
          // <ServicePostTemp1 data={details} />,
          // <ServicePostTemp2 data={details} />,
          <ServicePostTemp3 data={details} />,
        ],
      });
      setselectedTemp({
        index: 0,
        svg: <ServiceStoryTemp3 data={details} />,
      });
    }
  }, [details]);

  useEffect(() => {
    if (type == "Event") {
      if (tab == "Story") {
        setselectedTemp({
          index: 0,
          svg: <EventStoryTemp3 data={details} />,
        });
      } else {
        setselectedTemp({
          index: 0,
          svg: <EventPostTemp3 data={details} />,
        });
      }
    } else {
      if (tab == "Story") {
        setselectedTemp({
          index: 0,
          svg: <ServiceStoryTemp3 data={details} />,
        });
      } else {
        setselectedTemp({
          index: 0,
          svg: <ServicePostTemp3 data={details} />,
        });
      }
    }
  }, [tab]);
  return (
    <Modal
      centered
      show={open}
      onHide={() => {
        setOpen(false);
      }}
      dialogClassName="delete-modal instaTemp"
    >
      <div className="Congratulations_msg">
        <div className="p-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p className="m-0 t-22-bold">Download Template</p>
            <IconButton
              sx={{
                background:
                  "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)",
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-lg-6">
              <div className="eventTab">
                <p
                  className={`${
                    tab == "Story" ? "tab--Active" : ""
                  } tab-button m-0`}
                  style={{ width: "50%", textAlign: "center" }}
                  onClick={() => {
                    setTab("Story");
                    setselectedTemp({
                      index: 0,
                      svg: svgs?.story[0],
                    });
                  }}
                >
                  Story
                </p>
                <p
                  className={`${
                    tab == "Post" ? "tab--Active" : ""
                  } tab-button m-0`}
                  style={{ width: "50%", textAlign: "center" }}
                  onClick={() => {
                    setTab("Post");
                    setselectedTemp({
                      index: 0,
                      svg: svgs?.post[0],
                    });
                  }}
                >
                  Post
                </p>
              </div>

              <div className="d-flex flex-wrap mt-2">
                {(tab == "Story" ? svgs?.story : svgs?.post)?.map((svg, i) => {
                  return (
                    <div className="col-3 p-1">
                      <div
                        className="w-100"
                        onClick={() => {
                          setselectedTemp({
                            index: i,
                            svg: svg,
                          });
                        }}
                      >
                        <div
                          className={
                            i == selectedTemp?.index ? "border-gred" : ""
                          }
                          style={{ padding: "1px", borderRadius: "5px" }}
                        >
                          <div
                            className={
                              i == selectedTemp?.index ? "overflow-hidden" : ""
                            }
                            style={{ borderRadius: "4px" }}
                          >
                            {svg}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-12 col-lg-6 px-2">
              <div className="col-12 col-lg-10 m-auto">
                <div className="border-gred">
                  <div
                    className="overflow-hidden"
                    style={{ borderRadius: "8px" }}
                  >
                    {selectedTemp?.svg}
                  </div>
                  <div
                    className="position-relative"
                    style={{ width: "0", height: "0", overflow: "hidden" }}
                  >
                    <div
                      className="downloadImage position-absolute"
                      style={{ borderRadius: "8px", width: "1080px" }}
                    >
                      {selectedTemp?.svg}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex ">
              {loader ? (
                <a
                  href="#"
                  style={{ lineHeight: "22px" }}
                  onClick={(e) => e.preventDefault()}
                  className="btn_admina m-auto mt-4"
                >
                  {/* save */}
                  <div className="loader"></div>
                </a>
              ) : (
                <button
                  className="btn_admina m-auto mt-4"
                  onClick={() => {
                    setLoader(true);
                    htmlToImage
                      .toPng(document.querySelector(".downloadImage svg"))
                      .then(function (dataUrl) {
                        var link = document.createElement("a");
                        link.download = `${type == "Event" ? eventData?.event_name : details?.service_name}_${tab}.jpeg`;
                        link.href = dataUrl;
                        link.click();
                        setLoader(false);
                      });
                  }}
                >
                  Download
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

{
  /* <text fontSize="57" y="294" x="540" fill="#1E3550">Sunshine Serenity Summit Event 
with John Smith</text> */
}
export default InstaTempModal;
