import React, {useEffect, useState} from 'react';
import {Grid, IconButton} from "@mui/material";
import closeIcon from "../../assets/images/close-red-icon.png"
import {AutoComplete, Empty} from "antd";
import addIcon from "../../assets/images/roundAdd-Icon.png"
import editIcon from "../../assets/images/edit.svg";
import DleteIcon from "../../assets/images/deleteRedIcon.svg";
import {getservice} from "../../action/serviceConstant";
import {useDispatch, useSelector} from "react-redux";
import ServiceTimePopup from "./ServiceTimePopup";
import moment from "moment";
import {useParams} from "react-router-dom";

const options = [
    {value: 'option 1'},
    {value: 'option 2'},
    {value: 'option 3'},
];

const ServiceCard = ({details, setDetails, setDeletedServiceId}) => {
    const [selectedService, setSectedService] = useState([])
    const select = useSelector((state) => state);
    const dispatch = useDispatch();
    const serviceList = useSelector((state) => state.service.serviceList);
    const [openTime, setOpenTime] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [selectedHourIndex, setselectedHourIndex] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const {id} = useParams();
    const [error, seterror] = useState({
        date: "",
        startTime: "",
        endTime: "",
    })
    // const []
    const [timeDetails, setTimeDetails] = useState({
        date:  details?.startDate,
        startTime: null,
        endTime: null,
    })

    const serviceDropDownOptions = () => {

        let newServiceList = serviceList?.filter((ele) => {
            return !selectedService.includes(ele?.description_name)
        })
        return newServiceList?.map((ele) => {
            return {value: ele?.description_name}
        })
    }

    const getServices = () => {
        dispatch(getservice())
    }


    useEffect(() => {
        getServices()
    }, []);

    const handleDeleteSubmit = (j) => {
        setDetails((pre) => ({
            ...pre, service: pre?.service?.map((service, i) => {
                if (i == selectedIndex) {
                    return {
                        ...service, hours: service?.hours?.filter((hour, k) => {
                            return k !== j
                        })
                    }
                } else {
                    return service
                }
            })
        }))
    }


    const handleEditSubmit = () => {
        if (!timeDetails?.date) {
            seterror((pre) => ({...pre, date: "Please enter date"}))
        }  else if (!timeDetails?.startTime) {
            seterror((pre) => ({...pre, startTime: "Please enter start time", date: ""}))
        } else if (!timeDetails?.endTime) {
            seterror((pre) => ({...pre, endTime: "Please enter end time", startTime: "", date: "",}))
        } else if (timeDetails?.startTime.toString() == timeDetails?.endTime.toString() || timeDetails?.startTime > timeDetails?.endTime) {
            seterror((pre) => ({...pre, endTime: "Please enter end time greater then start time.", startTime: "", date: "",}))
        } else {
            setDetails((pre) => ({
                ...pre, service: pre?.service?.map((service, i) => {
                    if (i == selectedIndex) {
                        return {
                            ...service, hours: service?.hours?.map((hour, j) => {
                                if (j == selectedHourIndex) {
                                    return timeDetails
                                } else {
                                    return hour
                                }
                            })
                        }
                    } else {
                        return service
                    }
                })
            }))
            setTimeDetails({
                date:  details?.startDate,
                startTime: null,
                endTime: null,
            })
            setOpenTime(false)
        }
    }

    const handleChangeEVentName = (e, id, info) => {
        setDetails((pre) => ({
            ...pre, service: pre.service?.map((service, i) => {
                if (i == id) {
                    if (info?.id) {
                        return {
                            ...service, service: {...service?.service, Requirement: e.target.value, id: info?.id}
                        }
                    } else {
                        return {
                            ...service, service: {...service?.service, Requirement: e.target.value}
                        }
                    }

                } else {
                    return service
                }
            })
        }))
    }

    const onServiceChange = (data, index, info, preData) => {
        // if(selectedService.includes(preData)){
        //     setSectedService(() => {
        //         return selectedService.map((selser) => {
        //             if(selser == preData){
        //                 return data
        //             }else {
        //                 return selser
        //             }
        //         })
        //     })
        // }else {
        //     setSectedService([...selectedService,data ])
        // }
        serviceList?.map((ele) => {
            if (ele?.description_name == data) {
                setDetails((pre) => ({
                    ...pre, service: pre.service?.map((service, i) => {
                        if (i == index) {
                            if (info?.id) {
                                return {
                                    ...service,
                                    service: {
                                        ...service?.service,
                                        serviceName: ele?.description_name,
                                        serviceId: ele?._id,
                                        id: info?.id
                                    }
                                }
                            } else {
                                return {
                                    ...service,
                                    service: {
                                        ...service?.service,
                                        serviceName: ele?.description_name,
                                        serviceId: ele?._id,
                                    }
                                }
                            }
                        } else {
                            return service
                        }
                    })
                }))
            }
        })
    };


    const getBetweenHours = (start, end) => {
        const startShiftTime = moment(start);
        const endShiftTime = moment(end);

        const duration = moment.duration(startShiftTime.diff(endShiftTime));
        const hours = Math.abs(duration.asHours()); // use Math.abs to get a positive value

        const roundHours = Math.round(hours)
        return roundHours;
    };

    return (
        <>
            {details?.service?.length ?
                details?.service?.map((ele, i) => (
                    <Grid container spacing={2} key={i} sx={{marginTop: '5px'}}>
                        <Grid item xs={12} md={6}>
                            <div className="inner-gred box_shadow">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="t-18-bold m-0">Service</p>
                                    {details?.service?.length > 1 ? <IconButton sx={{padding: "3px"}} onClick={() => {
                                        const newValue = details?.service?.filter((value, j) => {
                                            return j !== i;
                                        });
                                        if (ele?.id) {
                                            setDeletedServiceId((pre) => ([...pre, ele?.id]))
                                        }
                                        setDetails((pre) => ({...pre, service: newValue}))
                                    }}>
                                        <img src={closeIcon} alt=""/>
                                    </IconButton> : null}
                                </div>
                                <hr/>
                                <div>
                                    <p className="t-14-500 text-black m-0">Service</p>
                                    <div className="group-input mt-2 p-15 clearfix">
                                        <div className="antdAutoComplate">
                                            <AutoComplete
                                                style={{
                                                    width: "100%",
                                                    color: "#000000",
                                                }}
                                                // value={ele?.service?.serviceName}
                                                defaultValue={ele?.service?.serviceName}
                                                onSelect={(e) => onServiceChange(e, i, ele, ele?.service?.serviceName)}
                                                options={serviceDropDownOptions()}
                                                placeholder="Select the service"
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                        </div>
                                    </div>
                                    <p className="t-13-500 text-danger">{ele?.serviceError}</p>
                                </div>
                                <div className="mt-3">
                                    <p className="t-14-500 text-black m-0">Requirement (Optional)</p>
                                    <div className="group-input mt-2 p-15 clearfix">
                                        <input
                                            type="text"
                                            style={{
                                                color: "#000000",
                                            }}
                                            placeholder="Enter requirement"
                                            value={ele?.service?.Requirement}
                                            onChange={(e) => handleChangeEVentName(e, i, ele)}
                                            name=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="inner-gred box_shadow" style={{height: '100%'}}>
                                <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
                                    <p className="t-20-bold m-0">Add Hours</p>
                                    <IconButton sx={{padding: "0"}} onClick={() => {
                                        setSelectedIndex(i)
                                        setOpenTime(true)
                                    }}>
                                        <img src={addIcon} alt=""/>
                                    </IconButton>
                                </div>
                                {ele?.hours?.length ? <div className="border-gred">
                                    <div className="inner-gred" style={{padding : '10px'}}>
                                        {ele?.hours.map((time, j) => (
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="m-0 t-13-bold">{moment(time?.date).format("L")} - {moment(time?.startTime?.$d || time?.startTime).format("LT")} to {moment(time?.endTime?.$d || time?.endTime).format("LT")} - {getBetweenHours((time?.startTime?.$d || time?.startTime), (time?.endTime?.$d || time?.endTime))} Hours</p>
                                                <div className="d-flex align-items-center gap-2">
                                                    <IconButton sx={{padding: "3px", background: "#EFF3F7"}}
                                                                onClick={() => {
                                                                    setIsEdit(true)
                                                                    setOpenTime(true)
                                                                    setselectedHourIndex(j)
                                                                    setSelectedIndex(i)
                                                                    setTimeDetails({
                                                                        date: time?.date,
                                                                        startTime: time?.startTime,
                                                                        endTime: time?.endTime
                                                                    })
                                                                }}>
                                                        <img src={editIcon} alt=""/>
                                                    </IconButton>
                                                    <IconButton sx={{padding: "3px"}} onClick={() => {
                                                        handleDeleteSubmit(j)
                                                        setSelectedIndex(i)
                                                    }}>
                                                        <img src={DleteIcon} alt=""/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div> : null}
                            </div>
                            <p className="t-13-500 text-danger">{ele?.hoursError}</p>
                        </Grid>
                    </Grid>
                )) :
                <div className="bg-white " style={{padding: "11%"}}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                           description={
                               <span>No Service Added.</span>
                           }
                    />
                </div>
            }
            <ServiceTimePopup selectedHourIndex={selectedHourIndex} setIsEdit={setIsEdit} isEdit={isEdit}
                              setTimeDetails={setTimeDetails} setOpen={setOpenTime} open={openTime}
                              timeDetails={timeDetails} ditails={details} setDetails={setDetails}
                              selectedIndex={selectedIndex} handleEditSubmit={handleEditSubmit} error={error}
                              setError={seterror} setSelectedIndex={setSelectedIndex}/>
        </>
    )
}

export default ServiceCard;