import React from 'react';
import profileImg from "../../assets/images/layout1.jpg"
import TransactionDetailsCard from "./TransactionDetailsCard";
import moment from "moment";
import {DateFormate, LocalFormate, timeZoneFormate} from "../../Utils/DateFormateUtils";

const AllTransactions = ({data, index, getAllHistory}) => {
    // console.log(data, "data")
    return (
        <>
            <div className="accordion-item transaction_accodian_item">
                <h2 className="accordion-header" id={"heading" + index}>
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse" + index}
                        aria-expanded="false"
                        aria-controls={"collapse" + index}
                        style={{padding: '0px 12px 0px 0px'}}
                    >
                        <div className="d-flex align-items-center w-100 justify-content-between"
                             style={{padding: '0px 25px 0px 0px'}}>
                            <div className="d-flex align-items-center">
                                <div
                                    className="p_image_shape me-2 me-sm-0"
                                    style={{transform: "scale(0.6)"}}>
                                    <img
                                        src={data?.display_image}/>
                                </div>
                                <div>
                                    <p className="m-0 t-17-bold text-black">{data?.transaction_message}</p>
                                    <p className="m-0 t-12-500 text-dark-gray mt-2">{DateFormate(LocalFormate(data?.createdAt, data?.date_timezone))} at {moment(LocalFormate(data?.createdAt, data?.date_timezone)).format("LT")} ({timeZoneFormate(data?.createdAt, data?.date_timezone)})</p>
                                </div>
                            </div>
                            <p className={data?.transaction_type == "credited" ? "wallet_green" : "wallet_red"}>{data?.transaction_type == "credited" ? "+ $" : '- $'}{Number(data?.total_paid_amount ?? 0).toFixed(2)}</p>

                        </div>
                    </button>
                </h2>
                <div
                    id={"collapse" + index}
                    className="accordion-collapse collapse"
                    aria-labelledby={"heading" + index}
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body">
                        <TransactionDetailsCard data={data} getAllHistory={getAllHistory}/>
                    </div>
                </div>
            </div>
            {/*</div>*/}
        </>
    )
}

export default AllTransactions;