import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {Box, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import userIcon from "../../assets/images/userIconWithBg.png";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../../assets/images/Discovery.svg";
import {hireNcollaRequest} from "../../action/hireNcollaborateConstant";
import {useDispatch} from "react-redux";

const ModifyScreen = ({open, setOpen, details, event}) => {
    const dispatch = useDispatch();
    const [eventDetails, setEventDetails] = useState({
        amount: 0
    })
    const calculateHourDifference = (startTime, endTime) => {
        const start = new Date(`01/01/2000 ${startTime}`);
        const end = new Date(`01/01/2000 ${endTime}`);

        const diffInMilliseconds = Math.abs(end - start);
        const hours = Math.floor(diffInMilliseconds / 1000 / 60 / 60);

        return hours;
    };

    const handleRequest = () => {
        const payload = {
            hire_and_collaborate_id: details?._id,
            request_amount: eventDetails?.amount
        }
        dispatch(hireNcollaRequest(payload, (a) => {setOpen(a)}, () => {}));
    }

    useEffect(() => {
        setEventDetails((pre) => ({...pre, amount: details?.amount}))
    }, [details]);
    return(
        <>
            <Modal
                centered
                show={open}
                onHide={() => {
                    setOpen(false);
                }}
                dialogClassName="delete-modal"

            >
                <div className="Congratulations_msg ">
                    <div className="p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="m-0 t-22-bold">{details?.event_request_type == "collaborate" ? "Collaborate" : "Hire"}</p>
                            <IconButton sx={{background: "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)"}}
                                        onClick={() => {
                                            setOpen(false)
                                        }}><CloseIcon/></IconButton>
                        </div>
                        <div className="inner-gred box_shadow mt-4"
                             style={{background: details?.booking_type == "event" && "rgba(255, 165, 0, 0.10)"}}>
                            <div className="d-flex">
                                <div className="p_image_shape me-2 me-sm-3">
                                    <img
                                        src={details?.provider_id?.profile_picture ?? userIcon}/>
                                </div>
                                <div>
                                    <p className="t-15-bold mb-1"
                                       style={{color: "black"}}>{details?.provider_id?.name}</p>

                                    <div className="d-flex align-items-center">
                                        <p className="m-0 t-12-bold">Provider</p>
                                    </div>
                                    <div className="d-flex gap-1">
                                        <img src={locationIcon} alt=""/>
                                        <p className="yelloe-t-bold m-0">{details?.provider_id?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <div className="group-input mt-0">
                                <input
                                    type="text"
                                    name=""
                                    value={details?.description_id?.description_name || details?.activities}
                                    className="select-servcesss"
                                    readOnly
                                />
                            </div>

                            <div>
                                <p className="t-14-bold m-0 mt-3">Type Your Message Here
                                    For {details?.event_request_type == "collaborate" ? " Collaborate " : " Hire "}
                                    Provider</p>
                                <textarea className="eventtextarea mt-1 p-1" value={details?.message}
                                          readOnly/>
                                {/*<p className="t-12-bold text-danger">{error?.massage}</p>*/}
                            </div>

                            <div className="group-input mt-3 p-15 clearfix">
                                <input
                                    type="text"
                                    name=""
                                    placeholder="Enter your hiring amount"
                                    // value={details?.amount?.replace(/\D/g, '')}
                                    value={eventDetails?.amount}
                                    onChange={(e) => {
                                        if (e.target.value.length < 8) {
                                            setEventDetails((pre) => ({
                                                ...pre,
                                                amount: e.target.value
                                            }))
                                        }

                                    }}
                                />
                            </div>
                            {details?.service_time_detail?.length ?
                                <div className="box_shadow inner-gred mt-2">
                                    <p className="t-14-bold mb-2">Hours</p>
                                    <div className="border-gred">
                                        <div className="inner-gred">
                                            {details?.service_time_detail?.map((ele) => (
                                                <p className="t-12-bold">{`${moment(ele?.provider_service_date).format('L')} - ${moment(ele?.provider_start_time).local().format('LT')} to ${moment(ele?.provider_end_time).local().format('LT')} - ${calculateHourDifference(moment(ele?.provider_start_time).local().format('LT'), moment(ele?.provider_end_time).local().format('LT'))} Hours`}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            : null}
                        </div>


                        <div style={{marginTop: '50%'}}>
                            <Box
                                className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center  mt-2">
                                <button
                                    className="btn_admina  ms-lg-auto "
                                    style={{
                                        backgroundSize: 'contain',
                                        lineHeight: "16px",
                                        opacity : details?.amount == eventDetails?.amount ? "0.8" : "1"
                                    }}
                                    disabled={details?.amount == eventDetails?.amount ? (eventDetails?.amount > 0 ? true : false) : false}
                                    onClick={() => handleRequest()}
                                >
                                    Modify
                                </button>
                            </Box>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModifyScreen;