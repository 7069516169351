import React from "react";
import "../assets/css/Privacypolicy.css"
const PrivacyPolicy = () => {
    return (
        <>
        <section className="page-title">
            <div className="container">
                <h1 className="p_title_text_pr">Privacy Policy</h1>
                <div className="title_desc">
                    <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure
                        of Your
                        information when You use the Service and tells You about Your privacy rights and how the law
                        protects
                        You.</p>
                    <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to
                        the
                        collection and use of information in accordance with this Privacy Policy.
                    </p>
                </div>
            </div>
        </section>
        <section className="main-page pt-0">
            <div className="main-inner">
                <div className="container">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading1">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse1"
                                    aria-expanded="true"
                                    aria-controls="collapse1"
                                >
                                    What information does the app collect when using
                                    location-based services?
                                </button>
                            </h2>
                            <div
                                id="collapse1"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading1"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <div className="sub_box">
                                        <h3 className="sub_heading mb-30">Types of Data Collected</h3>
                                        <h4>1. Personal Data</h4>
                                        <p>While using Our Service, We may ask You to provide Us with certain personally
                                            identifiable
                                            information that can be used to contact or identify You. Personally
                                            identifiable
                                            information
                                            may include, but is not limited to.
                                        </p>
                                    </div>
                                    <div className="sub_box">
                                        <h4>2. Usage Data</h4>
                                        <p>Usage Data is collected automatically when using the Service.</p>
                                        <p>Usage Data may include information such as Your Device's Internet Protocol
                                            address
                                            (e.g. IP
                                            address), browser type, browser version, the pages of our Service that You
                                            visit,
                                            the time
                                            and date of Your visit, the time spent on those pages, unique device
                                            identifiers and
                                            other
                                            diagnostic data.</p>
                                        <p>When You access the Service by or through a mobile device, We may collect
                                            certain
                                            information
                                            automatically, including, but not limited to, the type of mobile device You
                                            use,
                                            Your mobile
                                            device unique ID, the IP address of Your mobile device, Your mobile
                                            operating
                                            system, the
                                            type of mobile Internet browser You use, unique device identifiers and other
                                            diagnostic
                                            data.</p>
                                        <p>We may also collect information that Your browser sends whenever You visit
                                            our
                                            Service or
                                            when You access the Service by or through a mobile device.</p>
                                    </div>
                                    <div className="sub_box">
                                        <h4>3. Information from Third-Party Social Media Services</h4>
                                        <p>The Company allows You to create an account and log in to use the Service
                                            through the
                                            following Third-party Social Media Services:</p>
                                        <ul className="mb-2">
                                            <li>Google</li>
                                            <li>Facebook</li>
                                            <li>Apple</li>

                                        </ul>
                                        <p>If You decide to register through or otherwise grant us access to a
                                            Third-Party
                                            Social Media
                                            Service, We may collect Personal data that is already associated with Your
                                            Third-Party
                                            Social Media Service's account, such as Your name, Your email address, Your
                                            activities or
                                            Your contact list associated with that account.</p>
                                        <p>You may also have the option of sharing additional information with the
                                            Company
                                            through Your
                                            Third-Party Social Media Service's account. If You choose to provide such
                                            information and
                                            Personal Data, during registration or otherwise, You are giving the Company
                                            permission to
                                            use, share, and store it in a manner consistent with this Privacy
                                            Policy.</p>
                                    </div>
                                    <div className="sub_box">
                                        <h4>4. Information Collected while Using the Application</h4>
                                        <p>While using Our Application, in order to provide features of Our Application,
                                            We may
                                            collect,
                                            with Your prior permission:</p>
                                        <blockquote>Information regarding your location<br/>
                                            Pictures and other information from your Device's camera and photo library
                                        </blockquote>
                                        <p>We use this information to provide features of Our Service, to improve and
                                            customize
                                            Our
                                            Service. The information may be uploaded to the Company's servers and/or a
                                            Service
                                            Provider's server or it may be simply stored on Your device.</p>
                                        <p>You can enable or disable access to this information at any time, through
                                            Your Device
                                            settings.</p>
                                    </div>
                                    <div className="sub_box">
                                        <h3 className="sub_heading mb-30 mt-4">Use of Your Personal Data</h3>
                                        <p>The Company may use Personal Data for the following purposes:</p>
                                        <ul className="mb-2">
                                            <li><strong>To provide and maintain our Service,</strong> including to
                                                monitor the
                                                usage of
                                                our Service.
                                            </li>
                                            <li><strong>To manage Your Account:</strong> to manage Your registration as
                                                a user
                                                of the
                                                Service. The Personal Data You provide can give You access to different
                                                functionalities
                                                of the Service that are available to You as a registered user.
                                            </li>
                                            <li><strong>For the performance of a contract:</strong> the development,
                                                compliance
                                                and
                                                undertaking of the purchase contract for the products, items or services
                                                You
                                                have
                                                purchased or of any other contract with Us through the Service.
                                            </li>
                                            <li><strong>To contact You:</strong> To contact You by email, telephone
                                                calls, SMS,
                                                or other
                                                equivalent forms of electronic communication, such as a mobile
                                                application's
                                                push
                                                notifications regarding updates or informative communications related to
                                                the
                                                functionalities, products or contracted services, including the security
                                                updates, when
                                                necessary or reasonable for their implementation.
                                            </li>
                                            <li><strong>To provide You</strong> with news, special offers and general
                                                information about
                                                other goods, services and events which we offer that are similar to
                                                those that
                                                you have
                                                already purchased or enquired about unless You have opted not to receive
                                                such
                                                information.
                                            </li>
                                            <li><strong>To manage Your requests:</strong> To attend and manage Your
                                                requests to
                                                Us.
                                            </li>
                                            <li><strong>For business transfers:</strong> We may use Your information to
                                                evaluate
                                                or
                                                conduct a merger, divestiture, restructuring, reorganization,
                                                dissolution, or
                                                other sale
                                                or transfer of some or all of Our assets, whether as a going concern or
                                                as part
                                                of
                                                bankruptcy, liquidation, or similar proceeding, in which Personal Data
                                                held by
                                                Us about
                                                our Service users is among the assets transferred.
                                            </li>
                                            <li><strong>For other purposes:</strong> We may use Your information for
                                                other
                                                purposes,
                                                such as data analysis, identifying usage trends, determining the
                                                effectiveness
                                                of our
                                                promotional campaigns and to evaluate and improve our Service, products,
                                                services,
                                                marketing and your experience.
                                            </li>
                                        </ul>
                                        <p>We may share Your personal information in the following situations:</p>
                                        <ul>
                                            <li><strong>With Service Providers:</strong> We may share Your personal
                                                information
                                                with
                                                Service Providers to monitor and analyze the use of our Service, for
                                                payment
                                                processing,
                                                to contact You.
                                            </li>
                                            <li><strong>For business transfers:</strong> We may share or transfer Your
                                                personal
                                                information in connection with, or during negotiations of, any merger,
                                                sale of
                                                Company
                                                assets, financing, or acquisition of all or a portion of Our business to
                                                another
                                                company.
                                            </li>
                                            <li><strong>With Affiliates:</strong> We may share Your information with Our
                                                affiliates, in
                                                which case we will require those affiliates to honor this Privacy
                                                Policy.
                                                Affiliates
                                                include Our parent company and any other subsidiaries, joint venture
                                                partners or
                                                other
                                                companies that We control or that are under common control with Us.
                                            </li>
                                            <li><strong>With business partners:</strong> We may share Your information
                                                with Our
                                                business
                                                partners to offer You certain products, services or promotions.
                                            </li>
                                            <li><strong>With other users:</strong> when You share personal information
                                                or
                                                otherwise
                                                interact in the public areas with other users, such information may be
                                                viewed by
                                                all
                                                users and may be publicly distributed outside. If You interact with
                                                other users
                                                or
                                                register through a Third-Party Social Media Service, Your contacts on
                                                the
                                                Third-Party
                                                Social Media Service may see Your name, profile, pictures and
                                                description of
                                                Your
                                                activity. Similarly, other users will be able to view descriptions of
                                                Your
                                                activity,
                                                communicate with You and view Your profile.
                                            </li>
                                            <li><strong>With Your consent:</strong> We may disclose Your personal
                                                information
                                                for any
                                                other purpose with Your consent.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sub_box">
                                        <h3 className="sub_heading mb-30">Retention of Your Personal Data</h3>
                                        <p>The Company will retain Your Personal Data only for as long as is necessary
                                            for the
                                            purposes
                                            set out in this Privacy Policy. We will retain and use Your Personal Data to
                                            the
                                            extent
                                            necessary to comply with our legal obligations (for example, if we are
                                            required to
                                            retain
                                            your data to comply with applicable laws), resolve disputes, and enforce our
                                            legal
                                            agreements and policies.</p>
                                        <p>The Company will also retain Usage Data for internal analysis purposes. Usage
                                            Data is
                                            generally retained for a shorter period of time, except when this data is
                                            used to
                                            strengthen
                                            the security or to improve the functionality of Our Service, or We are
                                            legally
                                            obligated to
                                            retain this data for longer time periods.</p>
                                    </div>
                                    <div className="sub_box">
                                        <h3 className="sub_heading mb-30">Transfer of Your Personal Data</h3>
                                        <p>Your information, including Personal Data, is processed at the Company's
                                            operating
                                            offices
                                            and in any other places where the parties involved in the processing are
                                            located. It
                                            means
                                            that this information may be transferred to — and maintained on — computers
                                            located
                                            outside
                                            of Your state, province, country or other governmental jurisdiction where
                                            the data
                                            protection laws may differ than those from Your jurisdiction.</p>
                                        <p>Your consent to this Privacy Policy followed by Your submission of such
                                            information
                                            represents Your agreement to that transfer.</p>
                                        <p>The Company will take all steps reasonably necessary to ensure that Your data
                                            is
                                            treated
                                            securely and in accordance with this Privacy Policy and no transfer of Your
                                            Personal
                                            Data
                                            will take place to an organization or a country unless there are adequate
                                            controls
                                            in place
                                            including the security of Your data and other personal information.</p>
                                    </div>
                                    <div>
                                        <h3 className="sub_heading mb-30">Disclosure of Your Personal Data</h3>
                                        <div className="sub_box">
                                            <h4>Business Transactions</h4>
                                            <p>If the Company is involved in a merger, acquisition or asset sale, Your
                                                Personal
                                                Data may
                                                be transferred. We will provide notice before Your Personal Data is
                                                transferred
                                                and
                                                becomes subject to a different Privacy Policy.</p>
                                        </div>
                                        <div className="sub_box">
                                            <h4>Law enforcement</h4>
                                            <p>Under certain circumstances, the Company may be required to disclose Your
                                                Personal Data
                                                if required to do so by law or in response to valid requests by public
                                                authorities (e.g.
                                                a court or a government agency).</p>
                                        </div>
                                        <div className="sub_box">
                                            <h4>Other legal requirements</h4>
                                            <p>The Company may disclose Your Personal Data in the good faith belief that
                                                such
                                                action is
                                                necessary to:</p>
                                            <ul className="mb-3">
                                                <li>Comply with a legal obligation</li>
                                                <li>Protect and defend the rights or property of the Company</li>
                                                <li>Prevent or investigate possible wrongdoing in connection with the
                                                    Service
                                                </li>
                                                <li>Protect the personal safety of Users of the Service or the public
                                                </li>
                                                <li>Protect against legal liability</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="sub_box">
                                        <h3 className="sub_heading mb-30">Security of Your Personal Data</h3>
                                        <p>The security of Your Personal Data is important to Us, but remember that no
                                            method of
                                            transmission over the Internet, or method of electronic storage is 100%
                                            secure.
                                            While We
                                            strive to use commercially acceptable means to protect Your Personal Data,
                                            We cannot
                                            guarantee its absolute security.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading2">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse2"
                                    aria-expanded="false"
                                    aria-controls="collapse2"
                                >
                                    How is the collected location data used and shared?
                                </button>
                            </h2>
                            <div
                                id="collapse2"
                                className="accordion-collapse collapse show"
                                aria-labelledby="heading2"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    The collected location data is stored securely on our server
                                    and will only be shared with the provider that you choose to
                                    interact with in order to enhance user experience and result
                                    accuracy.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading3">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse3"
                                    aria-expanded="false"
                                    aria-controls="collapse3"
                                >
                                    Is the location data sold to any third-party companies?
                                </button>
                            </h2>
                            <div
                                id="collapse3"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading3"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    Your private location will not be sold to Third-party
                                    companies. The app might display advertisement based in
                                    location but your information will never be sold to
                                    third-party companies
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading7">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse7"
                                    aria-expanded="false"
                                    aria-controls="collapse6"
                                >

                                    How is the payment information collected and stored?
                                </button>
                            </h2>
                            <div
                                id="collapse7"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading7"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <p>
                                        The Service Providers We use may have access to Your Personal Data. These
                                        third-party
                                        vendors collect, store, use, process and transfer information about Your
                                        activity on Our
                                        Service in accordance with their Privacy Policies.
                                    </p>
                                    <h3 className="sub_heading mb-30">Payments</h3>
                                    <p>We may provide paid products and/or services within the Service. In that case, we
                                        may use
                                        third-party services for payment processing (e.g. payment processors).</p>
                                    <p>
                                        We will not store or collect Your payment card details. That information is
                                        provided
                                        directly to Our third-party payment processors whose use of Your personal
                                        information is
                                        governed by their Privacy Policy. These payment processors adhere to the
                                        standards set
                                        by PCI-DSS as managed by the PCI Security Standards Council, which is a joint
                                        effort of
                                        brands like Visa, Mastercard, American Express and Discover. PCI-DSS
                                        requirements help
                                        ensure the secure handling of payment information.
                                    </p>
                                    <blockquote><strong>Stripe</strong> Their Privacy Policy can be viewed at
                                        https://stripe.com/us/privacy
                                    </blockquote>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading5">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse5"
                                    aria-expanded="false"
                                    aria-controls="collapse5"
                                >
                                    How can users delete their location data or payment
                                    information from the app?
                                </button>
                            </h2>
                            <div
                                id="collapse5"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading5"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    The system does not keep a log of your location data, it is
                                    used as you search only, therefore there is no location data
                                    to delete.You can remove your payment information at any
                                    time directly from your settings.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading6">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse6"
                                    aria-expanded="false"
                                    aria-controls="collapse6"
                                >
                                    What is the app's policy on data breaches and how are users
                                    notified in case of a breach?
                                </button>
                            </h2>
                            <div
                                id="collapse6"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading6"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <p>
                                        Our app takes data breaches very seriously and has
                                        implemented strict policies to prevent and address such
                                        incidents. In the event of a data breach, our team will
                                        take immediate action to mitigate the impact and
                                        investigate the breach.
                                    </p>
                                    <p>
                                        If a data breach occurs and user data is compromised, we
                                        will promptly notify affected users via email or through
                                        in-app notifications. We will provide clear and concise
                                        information about the breach, the type of data that may
                                        have been accessed, and the steps users can take to
                                        protect themselves. Additionally, we will work diligently
                                        to rectify the breach, strengthen our security measures,
                                        and prevent similar incidents in the future. Safeguarding
                                        our users' data is of utmost importance to us, and we
                                        strive to ensure transparency and communication in the
                                        event of a breach.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading8">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse8"
                                    aria-expanded="false"
                                    aria-controls="collapse8"
                                >
                                    Children's Privacy
                                </button>
                            </h2>
                            <div
                                id="collapse8"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading8"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <p>
                                        Our Service does not address anyone under the age of 13. We do not knowingly
                                        collect
                                        personally identifiable information from anyone under the age of 13. If You are
                                        a parent
                                        or guardian and You are aware that Your child has provided Us with Personal
                                        Data, please
                                        contact Us. If We become aware that We have collected Personal Data from anyone
                                        under
                                        the age of 13 without verification of parental consent, We take steps to remove
                                        that
                                        information from Our servers.
                                    </p>
                                    <p>If We need to rely on consent as a legal basis for processing Your information
                                        and Your
                                        country requires consent from a parent, We may require Your parents consent
                                        before We
                                        collect and use that information.</p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading9">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse9"
                                    aria-expanded="false"
                                    aria-controls="collapse9"
                                >
                                    GDPR Privacy
                                </button>
                            </h2>
                            <div
                                id="collapse9"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading9"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h3 className="sub_heading mb-30">Legal Basis for Processing Personal Data under
                                        GDPR</h3>
                                    <p>We may process Personal Data under the following conditions:</p>
                                    <ul className="mb-2">
                                        <li><strong>Consent:</strong> You have given Your consent for processing
                                            Personal Data
                                            for one or more specific purposes.
                                        </li>
                                        <li><strong>Performance of a contract:</strong> Provision of Personal Data is
                                            necessary
                                            for the performance of an agreement with You and/or for any pre-contractual
                                            obligations thereof.
                                        </li>
                                        <li><strong>Legal obligations:</strong> Processing Personal Data is necessary
                                            for
                                            compliance with a legal obligation to which the Company is subject.
                                        </li>
                                        <li><strong>Vital interests:</strong> Processing Personal Data is necessary in
                                            order to
                                            protect Your vital interests or of another natural person.
                                        </li>
                                        <li><strong>Public interests:</strong> Processing Personal Data is related to a
                                            task
                                            that is carried out in the public interest or in the exercise of official
                                            authority
                                            vested in the Company.
                                        </li>
                                        <li><strong>Legitimate interests:</strong> Processing Personal Data is necessary
                                            for the
                                            purposes of the legitimate interests pursued by the Company.
                                        </li>
                                    </ul>
                                    <p>In any case, the Company will gladly help to clarify the specific legal basis
                                        that
                                        applies to the processing, and in particular whether the provision of Personal
                                        Data is a
                                        statutory or contractual requirement, or a requirement necessary to enter into a
                                        contract.</p>
                                    <div className="sub_box">
                                        <h3 className="sub_heading mb-30">Your Rights under the GDPR</h3>
                                        <p>The Company undertakes to respect the confidentiality of Your Personal Data
                                            and to
                                            guarantee You can exercise Your rights.</p>
                                        <p>You have the right under this Privacy Policy, and by law if You are within
                                            the EU,
                                            to:</p>
                                        <ul>
                                            <li><strong>Request access to Your Personal Data.</strong> The right to
                                                access,
                                                update
                                                or delete the information We have on You. Whenever made possible, you
                                                can
                                                access,
                                                update or request deletion of Your Personal Data directly within Your
                                                account
                                                settings section. If you are unable to perform these actions yourself,
                                                please
                                                contact Us to assist You. This also enables You to receive a copy of the
                                                Personal
                                                Data We hold about You.
                                            </li>
                                            <li><strong>Request correction of the Personal Data that We hold about
                                                You.</strong>
                                                You
                                                have the right to have any incomplete or inaccurate information We hold
                                                about
                                                You
                                                corrected.
                                            </li>
                                            <li><strong>Object to processing of Your Personal Data.</strong> This right
                                                exists
                                                where
                                                We are relying on a legitimate interest as the legal basis for Our
                                                processing
                                                and
                                                there is something about Your particular situation, which makes You want
                                                to
                                                object
                                                to our processing of Your Personal Data on this ground. You also have
                                                the right
                                                to
                                                object where We are processing Your Personal Data for direct marketing
                                                purposes.
                                            </li>
                                            <li><strong>Request erasure of Your Personal Data.</strong> You have the
                                                right to
                                                ask Us
                                                to delete or remove Personal Data when there is no good reason for Us to
                                                continue
                                                processing it.
                                            </li>
                                            <li><strong>Request the transfer of Your Personal Data.</strong> We will
                                                provide to
                                                You,
                                                or to a third-party You have chosen, Your Personal Data in a structured,
                                                commonly
                                                used, machine-readable format. Please note that this right only applies
                                                to
                                                automated
                                                information which You initially provided consent for Us to use or where
                                                We used
                                                the
                                                information to perform a contract with You.
                                            </li>
                                            <li><strong>Withdraw Your consent.</strong> You have the right to withdraw
                                                Your
                                                consent
                                                on using your Personal Data. If You withdraw Your consent, We may not be
                                                able to
                                                provide You with access to certain specific functionalities of the
                                                Service.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sub_box">
                                        <h3 className="sub_heading mb-30">Exercising of Your GDPR Data Protection
                                            Rights</h3>
                                        <p>You may exercise Your rights of access, rectification, cancellation and
                                            opposition by
                                            contacting Us. Please note that we may ask You to verify Your identity
                                            before
                                            responding
                                            to such requests. If You make a request, We will try our best to respond to
                                            You as
                                            soon
                                            as possible.</p>
                                        <p>You have the right to complain to a Data Protection Authority about Our
                                            collection
                                            and
                                            use of Your Personal Data. For more information, if You are in the European
                                            Economic
                                            Area (EEA), please contact Your local data protection authority in the
                                            EEA.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading10">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse10"
                                    aria-expanded="false"
                                    aria-controls="collapse10"
                                >
                                    Links to Other Websites
                                </button>
                            </h2>
                            <div
                                id="collapse10"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading10"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <p>
                                        Our Service may contain links to other websites that are not operated by Us. If
                                        You
                                        click on a third party link, You will be directed to that third party's site. We
                                        strongly advise You to review the Privacy Policy of every site You visit.
                                    </p>
                                    <p>We have no control over and assume no responsibility for the content, privacy
                                        policies or
                                        practices of any third party sites or services.</p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading11">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse11"
                                    aria-expanded="false"
                                    aria-controls="collapse11"
                                >
                                    Changes to this Privacy Policy
                                </button>
                            </h2>
                            <div
                                id="collapse11"
                                className="accordion-collapse collapse"
                                aria-labelledby="heading11"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <p>
                                        We may update Our Privacy Policy from time to time. We will notify You of any
                                        changes by
                                        posting the new Privacy Policy on this page.
                                    </p>

                                    <p>We will let You know via email and/or a prominent notice on Our Service, prior to
                                        the
                                        change becoming effective and update the "Last updated" date at the top of this
                                        Privacy
                                        Policy.</p>

                                    <p>You are advised to review this Privacy Policy periodically for any changes. This
                                        Privacy
                                        Policy changes are effective when posted on this page.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        {/*</div>*/}
        </section>
</>
)
    ;
};

export default PrivacyPolicy;
