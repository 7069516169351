import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const leftarrowStyle = {
  background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)",
  border: 0,
  color: "white",
  fontSize: "80px",
  position: "absolute",
  top: "40%",
  left: "0",
  height: "30px",
  width: "30px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const rightarrowStyle = {
  background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)",
  border: 0,
  color: "white",
  fontSize: "80px",
  position: "absolute",
  top: "40%",
  right: "0",
  height: "30px",
  width: "30px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};


const leftGreyarrowStyle = {
  background: "#00000075",
  border: 0,
  color: "white",
  fontSize: "80px",
  position: "absolute",
  top: "24%",
  left: "0",
  height: "30px",
  width: "30px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const rightGreyarrowStyle = {
  background: "#00000075",
  border: 0,
  color: "white",
  fontSize: "80px",
  position: "absolute",
  top: "24%",
  right: "0",
  height: "30px",
  width: "30px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const CustomLeftArrow = ({ onClick }) => {
  return (
    <button onClick={onClick} style={leftarrowStyle}>
      <ChevronLeftIcon style={{ display: "flex" }} />
    </button>
  );
};
export const CustomRightArrow = ({ onClick }) => {
  return (
    <button onClick={onClick} style={rightarrowStyle}>
      <ChevronRightIcon style={{ display: "flex" }} />
    </button>
  );
};
export const CustomGreyLeftArrow = ({ onClick }) => {
  return (
    <button onClick={onClick} style={leftGreyarrowStyle}>
      <ChevronLeftIcon style={{ display: "flex" }} />
    </button>
  );
};
export const CustomGreyRightArrow = ({ onClick }) => {
  return (
    <button onClick={onClick} style={rightGreyarrowStyle}>
      <ChevronRightIcon style={{ display: "flex" }} />
    </button>
  );
};
