import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";
import { event, myPageConstant, sessionBookingConstant } from "./constant";
import { toast } from "react-toastify";

export const getEvent = (userId, setFoundEvent, setLoader) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("event_id", userId);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_event_detail}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: event?.GET_EVENTS,
        payload: response.data,
      });
      if (response?.message == "Couldn't found event") {
        setFoundEvent(true);
      }
      setLoader(false);
    } else {
      if (response?.message == "Couldn't found event") {
        setFoundEvent(true);
      }
      setLoader(false);
      toast.error(response?.message);
    }
  };
};
export const getGuestEvent = (userId, setLoader) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("event_id", userId);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_guest_event_detail}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: event?.GET_EVENTS,
        payload: response.data,
      });
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(response?.message);
    }
  };
};
export const checkModifyPayment = (data, navigate, setBtnLoader) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("modified_booking_id", data?.modified_booking_id);
    fdata.append("total_paid_amount", data?.total_paid_amount);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.check_modify_payment}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      const data = response.data;
      dispatch({
        type: event?.CHECK_MODIFY_PAYMENT,
        payload: { ...data, success: response.success },
      });
      if (response.data.amount > 0) {
        toast.success(response?.message);
        setBtnLoader(false);
      } else {
        navigate();
      }
    } else {
      toast.error(response?.message);
    }
  };
};
export const bookEvent = (data, setBtnLoader) => {
  return async (dispatch) => {
    let success = { status: false };
    let response;
    const fdata = new FormData();
    fdata.append("booking_type", data?.booking_type);
    fdata.append("event_id", data?.event_id);
    fdata.append("provider_id", data?.provider_id);
    fdata.append("no_of_participants", data?.no_of_participants);
    fdata.append("event_package_id", data?.event_package_id);
    if (data?.card_id) {
      fdata.append("card_id", data?.card_id);
    }
    fdata.append("total_amount", data?.total_amount);
    fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
    fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
    fdata.append("total_paid_amount", data?.total_paid_amount);
    fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
    fdata.append(
      "sales_tax_amount_from_user",
      data?.sales_tax_amount_from_user
    );
    if (data?.sales_tax_id !== 0) {
      fdata.append("sales_tax_id", data?.sales_tax_id);
    }
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.sessionBooking}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        success = { status: data?.success };
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      setBtnLoader(false);
      dispatch({
        type: event?.BOOK_EVENT,
        payload: { data: response.data, success: success },
      });
      toast.success(response?.message);
    } else {
      setBtnLoader(false);
      toast.error(response?.message);
    }
  };
};

export const sessionBookingModify = (data, setBtnLoader) => {
  return async (dispatch) => {
    let success = { status: false };
    let response;
    const fdata = new FormData();
    fdata.append("booking_type", data?.booking_type);
    fdata.append("event_id", data?.event_id);
    fdata.append("provider_id", data?.provider_id);
    fdata.append("no_of_participants", data?.no_of_participants);
    fdata.append("event_package_id", data?.event_package_id);
    if (data?.card_id) {
      fdata.append("card_id", data?.card_id);
    }
    fdata.append("total_amount", data?.total_amount);
    fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
    fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
    fdata.append("total_paid_amount", data?.total_paid_amount);
    fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
    fdata.append(
      "sales_tax_amount_from_user",
      data?.sales_tax_amount_from_user
    );
    fdata.append("booking_id", data?.booking_id);
    if (data?.sales_tax_id !== 0) {
      fdata.append("sales_tax_id", data?.sales_tax_id);
    }
    if (data?.modify_booking_payment_info) {
      fdata.append(
        "modify_booking_payment_info",
        JSON.stringify(data?.modify_booking_payment_info)
      );
    }
    await fetch(
      `${BASE_URL}${APP_VERSION}${URL_PATH.modified_service_booking}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
          }`,
        },
        body: fdata,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        success = { status: data?.success };
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      setBtnLoader(false);
      dispatch({
        type: event?.MODIFY_EVENT,
        payload: { data: response.data, success: success },
      });
      toast.success(response?.message);
    } else {
      setBtnLoader(false);
      toast.error(response?.message);
    }
  };
};
export const bookEventRequest = (data, setBtnLoader, tokenData) => {
  return async (dispatch) => {
    let success = { status: false };
    let response;
    const fdata = new FormData();

    if (data?.booking_id) {
      fdata.append("booking_id", data?.booking_id);
    }

    fdata.append("booking_type", data?.booking_type);
    fdata.append("event_id", data?.event_id);
    fdata.append("provider_id", data?.provider_id);
    fdata.append("no_of_participants", data?.no_of_participants);
    fdata.append("event_package_id", data?.event_package_id);
    fdata.append("start_date_time", data?.start_date_time);
    fdata.append("end_date_time", data?.end_date_time);
    if (data?.card_id) {
      fdata.append("card_id", data?.card_id);
    }
    if (data?.discount_code_id) {
      fdata.append("discount_code_id", data?.discount_code_id);
    }
    fdata.append("total_amount", data?.total_amount);
    fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
    fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
    fdata.append("total_paid_amount", data?.total_paid_amount);
    fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
    fdata.append(
      "sales_tax_amount_from_user",
      data?.sales_tax_amount_from_user
    );
    fdata.append(
      "fees_percentage_from_provider",
      data?.fees_percentage_from_provider?.toString() ? data?.fees_percentage_from_provider?.toString() :
        data?.modify_booking_payment_info?.fees_percentage_from_provider?.toString()
    );

    console.log(data?.fees_percentage_from_provider?.toString(), "fees_percentage_from_provider")
    fdata.append(
      "fees_amount_from_provider",
      data?.fees_amount_from_provider?.toString() ? data?.fees_amount_from_provider?.toString() :
        data?.modify_booking_payment_info?.fees_amount_from_provider?.toString()
    );
    fdata.append(
      "modify_booking_payment_info",
      JSON.stringify(data?.modify_booking_payment_info)
    );
    if (data?.sales_tax_id !== 0) {
      fdata.append("sales_tax_id", data?.sales_tax_id);
    }
    fdata.append("address", null);
    fdata.append("state", data?.state);
    fdata.append("payment_date", data?.payment_date);
    fdata.append("current_timezone", data?.current_timezone);
    fdata.append("country", data?.country);
    fdata.append("location", JSON.stringify(data?.location));

    if (!data?.modify_booking_payment_info?.is_refund) {
      fdata.append("total_transfered_amount", data?.total_paid_amount);
      fdata.append("stripe_charge_id", data?.stripe_charge_id);
      fdata.append("stripe_payment_intent_id", data?.stripe_payment_intent_id);
      fdata.append(
        "referral_code_info",
        JSON.stringify({
          is_refree: data?.modify_booking_payment_info?.is_referee ?? false,
          display_user_fees_amount:
            data?.modify_booking_payment_info?.referee_fees_amount_from_user ??
            0,
          display_user_fees_percentage:
            data?.modify_booking_payment_info?.referee_fees_percentage ?? 0,
        })
      );
      fdata.append(
        "discount_code_info",
        JSON.stringify({
          is_discount: data?.modify_booking_payment_info?.is_discount ?? false,
          is_admin_discount:
            data?.modify_booking_payment_info?.is_admin_discount ?? 0,
          amount_without_discount:
            data?.modify_booking_payment_info?.amount_without_discount ?? 0,
        })
      );

      fdata.append(
        "discount_amount",
        data?.modify_booking_payment_info?.discount_amount
      );
      fdata.append(
        "discount_percentage",
        data?.modify_booking_payment_info?.discount_percentage
      );
      if (data?.fees_percentage_from_user && data?.fees_amount_from_user) {
        fdata.append(
          "referral_discount_amount_user",
          data?.fees_amount_from_user
        );
        fdata.append(
          "referral_discount_percentage_user",
          data?.fees_percentage_from_user
        );
        // fdata.append("referral_code_id", data?.referral_code_id);
      }
      if (data?.referer_referral_code_id) {
        fdata.append(
          "referer_referral_code_id",
          data?.modify_booking_payment_info.referer_referral_code_id
        );
      }
      if (data?.referree_referral_code_id) {
        fdata.append(
          "referree_referral_code_id",
          data?.modify_booking_payment_info.referree_referral_code_id
        );
      }
    } else {
      const refundPayload = {
        total_amount: data?.total_amount,
        total_paid_amount: data?.total_paid_amount,
        fees_percentage_from_user: data?.fees_percentage_from_user,
        fees_amount_from_user: data?.fees_amount_from_user,
        sales_tax_percentage: data?.sales_tax_percentage,
        sales_tax_amount_from_user: data?.sales_tax_amount_from_user,
        stripe_payment_intent_id: data?.stripe_payment_intent_id,
        stripe_charge_id: data?.stripe_charge_id,
        total_transfered_amount: data?.total_paid_amount,
        sales_tax_id: data?.sales_tax_id,
        application_fees: data?.modify_booking_payment_info?.application_fees,
        fees_percentage_from_provider:
          data?.fees_percentage_from_provider ||
          data?.modify_booking_payment_info?.fees_percentage_from_provider,
        fees_amount_from_provider:
          data?.fees_amount_from_provider ||
          data?.modify_booking_payment_info?.fees_amount_from_provider,
        stripe_transfer_id: data?.stripe_transfer_id,
      };
      fdata.append("refund_booking_payment_info", JSON.stringify(refundPayload));

    }
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.add_booking_request}`, {
      method: "POST",
      headers: {
        Authorization: tokenData?.token
          ? `Bearer ${tokenData?.token}`
          : `Bearer ${JSON.parse(localStorage.getItem("AdAnima_auth"))?.token}`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        success = { status: data?.success };
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      setBtnLoader(false);
      dispatch({
        type: event?.BOOK_EVENT,
        payload: { data: response.data, success: success },
      });
      toast.success(response?.message);
    } else {
      setBtnLoader(false);
      toast.error(response?.message);
    }
  };
};
export const guestEventBooking = (data, setBtnLoader) => {
  return async (dispatch) => {
    let success = { status: false };
    let response;
    const fdata = new FormData();
    fdata.append("booking_type", data?.booking_type);
    fdata.append("event_id", data?.event_id);
    fdata.append("provider_id", data?.provider_id);
    fdata.append("no_of_participants", data?.no_of_participants);
    fdata.append("event_package_id", data?.event_package_id);
    fdata.append("total_amount", data?.total_amount);
    fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
    fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
    fdata.append("total_paid_amount", data?.total_paid_amount);
    if (data?.discount_code_id) {
      fdata.append("discount_code_id", data?.discount_code_id);
    }

    // fdata.append("card_holder_name", data?.card_holder_name);
    // fdata.append("card_number", data?.card_number);
    // fdata.append("expire_year", data?.expire_year);
    // fdata.append("expire_month", data?.expire_month);
    // fdata.append("cvv", data?.cvv);

    fdata.append("is_guest_user", data?.is_guest_user);
    fdata.append("name", data?.name);
    fdata.append("email_address", data?.email_address);
    fdata.append("phone_number", data?.phone_number);
    fdata.append("country_code", data?.country_code);
    fdata.append(
      "fees_percentage_from_provider",
      data?.fees_percentage_from_provider
    );
    fdata.append("fees_amount_from_provider", data?.fees_amount_from_provider);
    if (data?.sales_tax_id !== 0) {
      fdata.append("sales_tax_id", data?.sales_tax_id);
    }
    fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
    fdata.append(
      "sales_tax_amount_from_user",
      data?.sales_tax_amount_from_user
    );

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_sessionBooking}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        success = { status: data?.success };
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      setBtnLoader(false);
      dispatch({
        type: event?.BOOK_EVENT,
        payload: { data: response.data, success: success },
      });
      toast.success(response?.message);
    } else {
      setBtnLoader(false);
      toast.error(response?.message);
    }
  };
};

export const guestEventBookingRequest = (data, setBtnLoader) => {
  return async (dispatch) => {
    let success = { status: false };
    let response;
    const fdata = new FormData();
    fdata.append("booking_type", data?.booking_type);
    fdata.append("event_id", data?.event_id);
    fdata.append("provider_id", data?.provider_id);
    fdata.append("no_of_participants", data?.no_of_participants);
    fdata.append("event_package_id", data?.event_package_id);
    fdata.append("total_amount", data?.total_amount);
    fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
    fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
    fdata.append("total_paid_amount", data?.total_paid_amount);
    if (data?.discount_code_id) {
      fdata.append("discount_code_id", data?.discount_code_id);
    }

    // fdata.append("card_holder_name", data?.card_holder_name);
    // fdata.append("card_number", data?.card_number);
    // fdata.append("expire_year", data?.expire_year);
    // fdata.append("expire_month", data?.expire_month);
    // fdata.append("cvv", data?.cvv);

    fdata.append("is_guest_user", data?.is_guest_user);
    fdata.append("name", data?.name);
    fdata.append("email_address", data?.email_address);
    fdata.append("phone_number", data?.phone_number);
    fdata.append("country_code", data?.country_code);
    fdata.append(
      "fees_percentage_from_provider",
      data?.fees_percentage_from_provider
    );
    fdata.append("fees_amount_from_provider", data?.fees_amount_from_provider);
    if (data?.sales_tax_id !== 0) {
      fdata.append("sales_tax_id", data?.sales_tax_id);
    }
    fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
    fdata.append(
      "sales_tax_amount_from_user",
      data?.sales_tax_amount_from_user
    );

    await fetch(
      `${BASE_URL}${APP_VERSION}${URL_PATH.add_guest_booking_request}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
          }`,
        },
        body: fdata,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        success = { status: data?.success };
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      // setBtnLoader(false);
      // dispatch({
      //   type: event?.BOOK_EVENT,
      //   payload: { data: response.data, success: success },
      // });
      // toast.success(response?.message);

      dispatch(
        bookEventRequest(
          data,
          (a) => {
            setBtnLoader(a);
          },
          response.data
        )
      );
    } else {
      setBtnLoader(false);
      toast.error(response?.message);
    }
  };
};

export const eventNotify = (data) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append(
      "user_id",
      JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
    );
    fdata.append("event_id", data?.event_id);
    fdata.append("is_notify", data?.is_notify);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.event_notify_user}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: event?.EVENT_NOTIFY,
        payload: response.data,
      });
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
};

export const acceptEventNotification = (data, getNotificationsList) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("notification_id", data?.notification_id);
    fdata.append("event_id", data?.event_id);
    fdata.append("provider_id", data?.provider_id);
    await fetch(
      `${BASE_URL}${APP_VERSION}${URL_PATH.accept_event_notification}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
          }`,
        },
        body: fdata,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: event?.ACCEPT_EVENT_NOTIFICATION,
        payload: response.data,
      });
      getNotificationsList();
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
};

export const cancelEventNotification = (data, getNotificationsList) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("notification_id", data?.notification_id);
    fdata.append("event_id", data?.event_id);
    fdata.append("provider_id", data?.provider_id);
    await fetch(
      `${BASE_URL}${APP_VERSION}${URL_PATH.cancel_event_notification}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
          }`,
        },
        body: fdata,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: event?.ACCEPT_EVENT_NOTIFICATION,
        payload: response.data,
      });
      getNotificationsList();
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
};
