import {toast} from "react-toastify";
import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {myBookingConstant, serviceConstant, upcomingEventsConstant, userServiceConstant} from "./constant";

export const getUserService = (props, setLoader) => {
    return async (dispatch) => {
        // const fdata = new FormData();
        // fdata.append("page", props.page);
        // fdata.append("limit", props.limit);
        //
        // if (props.latLong) {
        //     fdata.append("lat", props?.latLong?.lat);
        //     fdata.append("long", props?.latLong?.lng);
        // }
        // if (props?.miles_distance) {
        //     fdata.append("miles_distance", props?.miles_distance);
        // }
        //
        // if (props?.service_id?.length > 0) {
        //     fdata.append("service_id", JSON.stringify(props.service_id));
        // }
        // if (props?.service_name) {
        //     fdata.append("service_name", props?.service_name);
        // }
        //
        // let response;
        // const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
        // await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.home_service_list}`, {
        //     method: "POST",
        //     headers: {
        //         Authorization: `Bearer ${userAuth?.token}`,
        //     },
        //     body: fdata,
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         response = data;
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        //
        // if (response?.success) {
        //     dispatch({
        //         type: userServiceConstant.GET_USER_SERVICE,
        //         payload: response.data,
        //     });
        //     if (response.data.length < 10) {
        //         props.hasMore();
        //     }
        // } else {
        //     toast.error(response?.message);
        // }
        let page = props.page
        let response = [];


        for (let i = 0; i < 10000; i++) {


            let newResponse = await fetchDataEvent(props, page)

            page++

            response = [...response, ...newResponse];
            if(newResponse?.length < 1 || response?.length >= 10 ){
                if(newResponse?.length < 1){
                    props.hasMore()
                }
                break;
            }


        }

        props.setuserServiceQuery((pre) => (
            {
                ...pre,
                page: page,
                limit: 10
            })
        )

        dispatch({
            type: userServiceConstant.GET_USER_SERVICE,
            payload: response,
        });
        setLoader()
    };
};

export const getGuestUserService = (props, setLoader) => {
    return async (dispatch) => {
        // const fdata = new FormData();
        // fdata.append("page", props.page);
        // fdata.append("limit", props.limit);
        // if (props.latLong) {
        //     fdata.append("lat", props?.latLong?.lat);
        //     fdata.append("long", props?.latLong?.lng);
        // }
        //
        // if (props?.miles_distance) {
        //     fdata.append("miles_distance", props?.miles_distance);
        // }
        //
        // if (props?.service_name) {
        //     fdata.append("service_name", props?.service_name);
        // }
        //
        // if (props?.service_id?.length > 0) {
        //     fdata.append("service_id", JSON.stringify(props.service_id));
        // }
        //
        // let response;
        // const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
        // await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.home_guest_service_list}`, {
        //     method: "POST",
        //     headers: {
        //         Authorization: `Bearer ${userAuth?.token}`,
        //     },
        //     body: fdata,
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         response = data;
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        //
        // if (response?.success) {
        //     dispatch({
        //         type: userServiceConstant.GET_USER_SERVICE,
        //         payload: response.data,
        //     });
        //     if (response.data.length < 10) {
        //         props.hasMore();
        //     }
        // } else {
        //     toast.error(response?.message);
        // }

        let page = props.page
        let response = [];



        for (let i = 0; i < 10000; i++) {

            let newResponse = await fetchGuestDataEvent(props, page)

            page++

            response = [...response, ...newResponse];
            if(newResponse?.length < 1 || response?.length >= 10 ){
                if(newResponse?.length < 1){
                    props.hasMore()
                }
                break;
            }


        }

        props.setuserServiceQuery((pre) => (
            {
                ...pre,
                page: page,
                limit: 10
            })
        )


        dispatch({
            type: userServiceConstant.GET_USER_SERVICE,
            payload: response,
        });

        setLoader()

    };
};

const fetchDataEvent = async (props,page) => {
    const fdata = new FormData();
    fdata.append("page", page);
    fdata.append("limit", props.limit);
    fdata.append("is_current_location", props.isCurruntLocations);
    if (props.latLong) {
        fdata.append("lat", props.latLong.lat);
        fdata.append("long", props.latLong.lng);
    }
    if (props?.service_name) {
        fdata.append("service_name", props?.service_name);
    }
    if (props?.miles_distance) {
        fdata.append("miles_distance", props?.miles_distance);
    }
    if (props?.service_id?.length > 0) {
        fdata.append("service_id", JSON.stringify(props.service_id));
    }

    let response = []

    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.home_service_list}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${userAuth?.token}`,
        },
        body: fdata,
    })
        .then((response) => response.json())
        .then((data) => {
            response = data.data
        })
        .catch((err) => {
            console.log(err);
        });



    // if (response?.success) {
    //     // dispatch({
    //     //     type: userServiceConstant.FETCH_MORE_SERVICE,
    //     //     payload: response.data,
    //     // });
    //     if (response.data.length < 1) {
    //         props.hasMore();
    //     }
    // } else {
    //     toast.error(response?.message);
    // }

    return response
}


const fetchGuestDataEvent = async (props,page) => {
    const fdata = new FormData();
    fdata.append("page", page);
    fdata.append("limit", props.limit);
    fdata.append("is_current_location", props.isCurruntLocations);
    if (props.latLong) {
        fdata.append("lat", props.latLong.lat);
        fdata.append("long", props.latLong.lng);
    }
    if (props?.service_name) {
        fdata.append("service_name", props?.service_name);
    }
    if (props?.miles_distance) {
        fdata.append("miles_distance", props?.miles_distance);
    }
    if (props?.service_id?.length > 0) {
        fdata.append("service_id", JSON.stringify(props.service_id));
    }

    let response = []

    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.home_guest_service_list}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${userAuth?.token}`,
        },
        body: fdata,
    })
        .then((response) => response.json())
        .then((data) => {
            response = data.data
        })
        .catch((err) => {
            console.log(err);
        });



    // if (response?.success) {
    //     // dispatch({
    //     //     type: userServiceConstant.FETCH_MORE_SERVICE,
    //     //     payload: response.data,
    //     // });
    //     if (response.data.length < 1) {
    //         props.hasMore();
    //     }
    // } else {
    //     toast.error(response?.message);
    // }

    return response ?? []
}

export const fetchMoreService = (props) => {
    return async (dispatch) => {

        let page = props.page
        let response = [];


        for (let i = 0; i < 10000; i++) {

            // const fdata = new FormData();
            // fdata.append("page", page);
            // fdata.append("limit", props.limit);
            // if (props.latLong) {
            //     fdata.append("lat", props.latLong.lat);
            //     fdata.append("long", props.latLong.lng);
            // }
            // if (props?.service_name) {
            //     fdata.append("service_name", props?.service_name);
            // }
            // if (props?.miles_distance) {
            //     fdata.append("miles_distance", props?.miles_distance);
            // }
            // if (props?.service_id?.length > 0) {
            //     fdata.append("service_id", JSON.stringify(props.service_id));
            // }
            //
            //
            // const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
            // await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.home_service_list}`, {
            //     method: "POST",
            //     headers: {
            //         Authorization: `Bearer ${userAuth?.token}`,
            //     },
            //     body: fdata,
            // })
            //     .then((response) => response.json())
            //     .then((data) => {
            //         response = [...response, ...data.data];
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     });
            //
            // if (response?.success) {
            //     // dispatch({
            //     //     type: userServiceConstant.FETCH_MORE_SERVICE,
            //     //     payload: response.data,
            //     // });
            //     if (response.data.length < 1) {
            //         props.hasMore();
            //         break;
            //     }
            // } else {
            //     toast.error(response?.message);
            // }

            let newResponse = await fetchDataEvent(props, page)

            page++

            response = [...response, ...newResponse];
            if(newResponse?.length < 1 || response?.length >= 10 ){
                if(newResponse?.length < 1){
                    props.hasMore()
                }
                break;
            }


        }

        props.setuserServiceQuery((pre) => (
            {
                ...pre,
                page: page,
                limit: 10
            })
        )

        dispatch({
            type: userServiceConstant.FETCH_MORE_SERVICE,
            payload: response,
        });

    };
};

export const fetchMoreGuestService = (props) => {
    return async (dispatch) => {
        // const fdata = new FormData();
        // fdata.append("page", props.page);
        // fdata.append("limit", props.limit);
        // if (props.latLong) {
        //     fdata.append("lat", props.latLong.lat);
        //     fdata.append("long", props.latLong.lng);
        // }
        // if (props?.service_name) {
        //     fdata.append("service_name", props?.service_name);
        // }
        // if (props?.miles_distance) {
        //     fdata.append("miles_distance", props?.miles_distance);
        // }
        // if (props?.service_id?.length > 0) {
        //     fdata.append("service_id", JSON.stringify(props.service_id));
        // }
        //
        // let response;
        // const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
        // await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.home_guest_service_list}`, {
        //     method: "POST",
        //     headers: {
        //         Authorization: `Bearer ${userAuth?.token}`,
        //     },
        //     body: fdata,
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         response = data;
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        //
        // if (response?.success) {
        //     dispatch({
        //         type: userServiceConstant.FETCH_MORE_SERVICE,
        //         payload: response.data,
        //     });
        //     if (response.data.length < 10) {
        //         props.hasMore();
        //     }
        // } else {
        //     toast.error(response?.message);
        // }


        let page = props.page
        let response = [];


        for (let i = 0; i < 10000; i++) {

            let newResponse = await fetchGuestDataEvent(props, page)

            page++

            response = [...response, ...newResponse];
            if(newResponse?.length < 1 || response?.length >= 10 ){
                if(newResponse?.length < 1){
                    props.hasMore()
                }
                break;
            }


        }

        props.setuserServiceQuery((pre) => (
            {
                ...pre,
                page: page,
                limit: 10
            })
        )

        dispatch({
            type: userServiceConstant.FETCH_MORE_SERVICE,
            payload: response,
        });


    };
};

export const saveService = (props) => {
    return async (dispatch) => {
        dispatch({
            type: userServiceConstant.SAVE_SERVICE,
            payload: {...props},
        });
        const fdata = new FormData();
        if (props.event == true) {
            fdata.append("event_id", props.id);
        } else {
            fdata.append("service_id", props.id);
        }
        fdata.append("is_save_status", props.status);

        let response;
        const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.save_service}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: userServiceConstant.SAVEUNSAVE_SERVICE,
                payload: {data: response?.data, status: response?.success},
            });
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};
export const saveHomeEvent = (props) => {
    return async (dispatch) => {
        dispatch({
            type: userServiceConstant.SAVE_SERVICE,
            payload: {...props},
        });
        const fdata = new FormData();
        fdata.append("event_id", props?.id);
        fdata.append("is_save_status", props?.status);

        let response;
        const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.saveUnSaveEvent}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: upcomingEventsConstant.SAVE_UNSAVE_EVENTS,
                payload: {data: response?.data, status: response?.success},
            });
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};
export const saveClaimService = (props) => {
    return async (dispatch) => {
        dispatch({
            type: userServiceConstant.SAVE_SERVICE,
            payload: {...props},
        });
        const fdata = new FormData();

        fdata.append("service_id", props.id);

        fdata.append("is_save_status", props.status);

        let response;
        const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.save_service}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userAuth?.token}`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: userServiceConstant.SAVE_CLAIM_SERVICE,
                payload: {data: response?.data, status: response?.success},
            });
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};
