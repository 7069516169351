import DeleteImage from "../assets/images/deletedot.png";
import {Modal} from "react-bootstrap";
import React from "react";

const BankAlartPopup = ({open, setOpen, setOpenBankInfo, isEvent}) => {
    return (
        <>
            <Modal
                centered
                show={open}
                backdrop="static"
                onHide={() => {
                    setOpen(false);
                }}
                style={{zIndex: '100001', background: '#00000096'}}
                dialogClassName="delete-modal"
            >
                <div className="Congratulations_msg">
                    <div className="group-parent m-0" style={{borderRadius: "10px"}}>
                        <div className="group " style={{borderRadius: "10px"}}>
                            <div className="padding-inner pt-2" style={{borderRadius: "10px", paddingBottom: '10px'}}>
                                <div className="text-center">
                                    <img
                                        className="img-fluid logo"
                                        alt=""
                                        src={DeleteImage}
                                    />
                                </div>
                                <h3 className="mb-3 mb-md-3 page-title text-center">
                                    No Bank Details!!
                                </h3>

                                <p className="text-center mb-3 mb-md-5" style={{color: "#000"}}>
                                    Please complete your bank details to create your {isEvent ? 'event' : 'service'}. It's necessary for user
                                    access.
                                </p>
                                <div className="w-100 d-flex mt-5">
                                    <div className="col-6">
                                        <button type="submit" className="btn_admina delete-button"
                                                onClick={() => setOpen(false)}
                                                style={{lineHeight: '15px', fontSize: '15px'}}>
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            type="submit"
                                            style={{fontSize: '15px'}}
                                            className="btn_admina delete-confirm-button"
                                            // onClick={() => {
                                            //     onConfirm();
                                            //     setOpen((pre) => ({...pre, link: false}));
                                            // }}
                                            onClick={() => {
                                                setOpenBankInfo(true);
                                                setOpen(false);
                                            }}
                                        >
                                            Fill bank Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default BankAlartPopup;