import React, {useState} from 'react';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import profileImg from "../../assets/images/layout1.jpg";
import moment from "moment";
import logo from "../../assets/images/ad-anima_logo.png"
import BookingInfo from "./InfoPopup/BookingInfo";
import {Tooltip} from "antd";
import {IconButton} from "@mui/material";
import deleteIcon from "../../assets/images/deleteAccIcon.svg";

const Reminder = ({data, formatBadge, setOpen, getReviewTime, seenNotification, handleDeleteNotification}) => {
    const [selectId, setSelectId] = useState("")
    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleNavigate = (event, status) => {
        const clickedElement = event.target;
        if (clickedElement.classList.contains('btnn')) {
            // Button with the class "btnn" was clicked, handle API call logic
            return;
        } else {
            setIsShowDetails(true)
            seenNotification()
            setSelectId(data?.booking_id)
        }
    }

    return (
        <>
            <div style={{background: data?.is_seen ? "white" : '#E6F7FF', cursor: 'pointer'}} onClick={(e) => {
                handleNavigate(e)
                // setOpen(null)
            }}>
                <div className="notification-main-div position-relative d-flex align-items-start justify-content-between p-2">
                    <div className="d-flex align-items-start w-100">
                        <div className="d-flex align-items-center">
                            <FiberManualRecordIcon style={{width: '13px', color: data?.is_seen ? 'transparent' : '#60CE74'}}/>
                            <div className="p_image_shape me-2 me-sm-0"
                                 style={{transform: "scale(0.6)", marginLeft: '-15px'}}>
                                <img src={data?.sender_user?.profile_picture || logo}/>
                            </div>
                        </div>
                        <div className="w-100">
                            <p className="mb-0 t-13-bold mt-0">Reminder</p>
                            
                            <div className="w-100 d-flex justify-content-between">
                                <div>
                                <p className="mb-0 t-12-bold mt-0 twoLineElips" style={{width: '80%'}}>{data?.noti_msg}</p>
                            <div className="d-flex align-items-center g-2">
                                {/*<p className="mb-0 t-11-500 mt-0">August 23 2023</p>*/}
                                {/*<FiberManualRecordIcon style={{transform: "scale(0.4)", color: '#555555'}}/>*/}
                                {/*<p className="mb-0 t-11-500 mt-0">14:00PM</p>*/}
                            </div>
                                </div>
                                <div>
                        <p className="t-10-bold m-0" style={{color: '#2F8A40', textAlign: 'end',
                            whiteSpace: 'nowrap'}}>{getReviewTime(data?.createdAt)}</p>
                        <p className='t-11-bold mt-1 d-flex align-items-center justify-content-center m-0' style={{
                            borderRadius: '3px',
                            background: '#00ADFF',
                            padding: "2px 4px",
                            whiteSpace: 'nowrap'
                        }}>{formatBadge(data?.noti_for, data?.booking_type == "service" ? false : true)}</p>
                    </div>
                            </div>
                            
                            <div className="d-flex justify-content-start">
                                    <div className="col-10">
                                <button type="submit"
                                        className="btn_admina delete-button"
                                    // onClick={() => {
                                    //     setPackageDetails(ele)
                                    //     setOpen(true)
                                    // }}
                                        style={{transform: "scale(0.7)", marginLeft: '-44px'}}>
                                    Generate Link
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="position-absolute delete_notification" style={{left: '0', top: '0'}}>
                        <button className="btnn">
                            <Tooltip placement="top" title={"Clear"}>
                                <IconButton style={{padding: '3px 5px'}} className="btnn" onClick={() => {
                                    handleDeleteNotification()
                                }}>
                                    {/*<CloseIcon style={{width: '15px'}}/>*/}
                                    <img src={deleteIcon} alt="" className="btnn" style={{width: '24px'}}/>
                                </IconButton>
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <BookingInfo setOpen={setIsShowDetails} open={isShowDetails} selectId={selectId}
                         setSelectId={setSelectId} formatBadge={formatBadge} data={data}/>
        </>
    )
}

export default Reminder;