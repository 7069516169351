import React from 'react';
import {Box, Container} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import Logo from "../assets/images/logo-2.png";
import {Select} from "antd";

const GuestHeader = () => {

    const navigate = useNavigate()
    const handleChange = (value) => {
        // console.log(`selected ${value}`);
    };
    return (
        <header>
            <Box className="">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="ps-0">
                            <Link to={"/"}>
                                <img src={Logo} alt="logo"/>
                            </Link>
                        </div>
                        <div className="d-flex justify-content-end align-items-center gap-3">
                            <button className="btn_admina mini-button" onClick={() => navigate('/login')}>Login</button>
                            {/*<div className="d-flex border-gred session-select mb-0" style={{width: "82px", height: "49px"}}>*/}
                            {/*    <Select*/}
                            {/*        defaultValue="en"*/}
                            {/*        style={{width: "100%"}}*/}
                            {/*        onChange={handleChange}*/}
                            {/*        className="serviceSelector"*/}
                            {/*        options={[*/}
                            {/*            {value: 'en', label: 'En'},*/}
                            {/*            {value: 'hin', label: 'Hin'},*/}
                            {/*            {value: 'guj', label: 'Guj'},*/}
                            {/*        ]}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    </div>

                </div>
            </Box>
        </header>
    )
}

export default GuestHeader;