import moment from "moment/moment";

export const DateFormate = (startDate, endDate, time = false) => {
    if (startDate && endDate) {
        if (moment(startDate).format('MMM Do') !== moment(endDate).format('MMM Do')) {
            if (moment(startDate).format('YYYY') !== moment(endDate).format('YYYY')) {
                return `${moment(startDate).format('MMM D YYYY')} - ${moment(endDate).format('MMM D YYYY')}`
            } else if (moment(startDate).format('MMM') == moment(endDate).format('MMM')) {
                return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('D, YYYY')}`
            } else {
                return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D, YYYY')}`
            }
        } else {
            return `${moment(startDate).format('MMM Do YYYY')}`
        }
    } else if (time) {
        return `${moment(startDate).format('MMM D YYYY, h:mm A')}`
    } else {
        return `${moment(startDate).format('MMM D YYYY')}`
    }

    // {moment(startDate).format('MMM Do') !== moment(endDate).format('MMM Do') ? (`${moment(startDate).format('MMM Do')} - ${moment(endDate).format('MMM Do, YYYY')}`) : (`${moment(startDate).format('MMMM Do YYYY')}`)}
}

export const IsoFormate = (date) => {
    // return date?.toISOString();

    // console.log({
    //     passDate: date
    // }, 'passdate')
    return date;

//     const momentZone = moment.tz.guess()
//     // Assume eventTime is the local event time in the event's timezone
//     const eventTime = moment.tz(date, momentZone);
//
// // Convert event time to UTC
//     const eventTimeUtc = eventTime.utc().format();
//
//
//
//     return eventTimeUtc

// Store UTC time in the database
// Example code to store eventTimeUtc in the database
}

export const LocalFormate = (date, timeZone) => {
    const momentZone = moment.tz.guess()
    // const NEWDATE = new Date(date)
    // return date?.toLocaleString();
    if (date && timeZone) {
        const utcDate = date; // UTC date
        const convertedDate = moment(utcDate).tz(timeZone); // TIMEZONE
        const formattedDate = convertedDate.format('YYYY-MM-DDTHH:mm:ss'); // DATE FORMATE
        // console.log({formattedDate: formattedDate})
        return formattedDate
    } else {
        const utcDate = date; // UTC date
        const convertedDate = moment(utcDate).tz(momentZone); // TIMEZONE
        const formattedDate = convertedDate.format('YYYY-MM-DDTHH:mm:ss'); // DATE FORMATE
        // console.log({formattedDate: formattedDate})
        return formattedDate
    }

    // const istAbbreviation = convertedDate.format('z');
    // console.log(formattedDate, "istAbbreviation")
}

export const timeZoneFormate = (date, timeZone) => {

    const momentZone = moment.tz.guess()
    if (timeZone) {
        const utcDate = date; // UTC date
        const convertedDate = moment(utcDate).tz(timeZone); // TIMEZONE
        // const formattedDate = convertedDate.format('YYYY-MM-DDTHH:mm:ss'); // DATE FORMATE
        const istAbbreviation = convertedDate.format('z');
        const customTimezoneName = timezoneAbbreviationMap[timeZone] || '';

        if (hasNumber(istAbbreviation)) {
            return customTimezoneName
        } else {
            return istAbbreviation
        }
    } else {
        const utcDate = date; // UTC date
        const convertedDate = moment(utcDate).tz(momentZone); // TIMEZONE
        // const formattedDate = convertedDate.format('YYYY-MM-DDTHH:mm:ss'); // DATE FORMATE
        const istAbbreviation = convertedDate.format('z');
        const customTimezoneName = timezoneAbbreviationMap[momentZone] || '';

        if (hasNumber(istAbbreviation)) {
            return customTimezoneName
        } else {
            return istAbbreviation
        }
    }
}

function hasNumber(str) {
    return str.match(/\d+/) !== null;
}

const timezoneAbbreviationMap = {
    'Africa/Abidjan': 'GMT',
    'Africa/Accra': 'GMT',
    'Africa/Addis_Ababa': 'EAT',
    'Africa/Algiers': 'CET',
    'Africa/Asmara': 'EAT',
    'Africa/Bamako': 'GMT',
    'Africa/Bangui': 'WAT',
    'Africa/Banjul': 'GMT',
    'Africa/Bissau': 'GMT',
    'Africa/Blantyre': 'CAT',
    'Africa/Brazzaville': 'WAT',
    'Africa/Bujumbura': 'CAT',
    'Africa/Cairo': 'EET',
    'Africa/Casablanca': 'WEST',
    'Africa/Ceuta': 'CET',
    'Africa/Conakry': 'GMT',
    'Africa/Dakar': 'GMT',
    'Africa/Dar_es_Salaam': 'EAT',
    'Africa/Djibouti': 'EAT',
    'Africa/Douala': 'WAT',
    'Africa/El_Aaiun': 'WEST',
    'Africa/Freetown': 'GMT',
    'Africa/Gaborone': 'CAT',
    'Africa/Harare': 'CAT',
    'Africa/Johannesburg': 'SAST',
    'Africa/Juba': 'EAT',
    'Africa/Kampala': 'EAT',
    'Africa/Khartoum': 'EAT',
    'Africa/Kigali': 'CAT',
    'Africa/Kinshasa': 'WAT',
    'Africa/Lagos': 'WAT',
    'Africa/Libreville': 'WAT',
    'Africa/Lome': 'GMT',
    'Africa/Luanda': 'WAT',
    'Africa/Lubumbashi': 'CAT',
    'Africa/Lusaka': 'CAT',
    'Africa/Malabo': 'WAT',
    'Africa/Maputo': 'CAT',
    'Africa/Maseru': 'SAST',
    'Africa/Mbabane': 'SAST',
    'Africa/Mogadishu': 'EAT',
    'Africa/Monrovia': 'GMT',
    'Africa/Nairobi': 'EAT',
    'Africa/Ndjamena': 'WAT',
    'Africa/Niamey': 'WAT',
    'Africa/Nouakchott': 'GMT',
    'Africa/Ouagadougou': 'GMT',
    'Africa/Porto-Novo': 'WAT',
    'Africa/Sao_Tome': 'GMT',
    'Africa/Tripoli': 'EET',
    'Africa/Tunis': 'CET',
    'Africa/Windhoek': 'CAT',
    'America/Adak': 'HDT',
    'America/Anchorage': 'AKDT',
    'America/Anguilla': 'AST',
    'America/Antigua': 'AST',
    'America/Araguaina': 'BRT',
    'America/Argentina/Buenos_Aires': 'ART',
    'America/Argentina/Catamarca': 'ART',
    'America/Argentina/ComodRivadavia': 'ART',
    'America/Argentina/Cordoba': 'ART',
    'America/Argentina/Jujuy': 'ART',
    'America/Argentina/La_Rioja': 'ART',
    'America/Argentina/Mendoza': 'ART',
    'America/Argentina/Rio_Gallegos': 'ART',
    'America/Argentina/Salta': 'ART',
    'America/Argentina/San_Juan': 'ART',
    'America/Argentina/San_Luis': 'ART',
    'America/Argentina/Tucuman': 'ART',
    'America/Argentina/Ushuaia': 'ART',
    'America/Aruba': 'AST',
    'America/Asuncion': 'PYT',
    'America/Atikokan': 'EST',
    'America/Bahia': 'BRT',
    'America/Bahia_Banderas': 'CDT',
    'America/Barbados': 'AST',
    'America/Belem': 'BRT',
    'America/Belize': 'CST',
    'America/Blanc-Sablon': 'AST',
    'America/Boa_Vista': 'AMT',
    'America/Bogota': 'COT',
    'America/Boise': 'MDT',
    'America/Buenos_Aires': 'ART',
    'America/Cambridge_Bay': 'MDT',
    'America/Campo_Grande': 'AMT',
    'America/Cancun': 'EST',
    'America/Caracas': 'VET',
    'America/Catamarca': 'ART',
    'America/Cayenne': 'GFT',
    'America/Cayman': 'EST',
    'America/Chicago': 'CDT',
    'America/Chihuahua': 'MDT',
    'America/Coral_Harbour': 'EST',
    'America/Cordoba': 'ART',
    'America/Costa_Rica': 'CST',
    'America/Creston': 'MST',
    'America/Cuiaba': 'AMT',
    'America/Curacao': 'AST',
    'America/Danmarkshavn': 'WET',
    'America/Dawson': 'PDT',
    'America/Dawson_Creek': 'MST',
    'America/Denver': 'MDT',
    'America/Detroit': 'EDT',
    'America/Dominica': 'AST',
    'America/Edmonton': 'MDT',
    'America/Eirunepe': 'ACT',
    'America/El_Salvador': 'CST',
    'America/Ensenada': 'PDT',
    'America/Fort_Nelson': 'MST',
    'America/Fort_Wayne': 'EDT',
    'America/Fortaleza': 'BRT',
    'America/Glace_Bay': 'ADT',
    'America/Godthab': 'WGT',
    'America/Goose_Bay': 'ADT',
    'America/Grand_Turk': 'EDT',
    'America/Grenada': 'AST',
    'America/Guadeloupe': 'AST',
    'America/Guatemala': 'CST',
    'America/Guayaquil': 'ECT',
    'America/Guyana': 'GYT',
    'America/Halifax': 'ADT',
    'America/Havana': 'CDT',
    'America/Hermosillo': 'MST',
    'America/Indiana/Indianapolis': 'EDT',
    'America/Indiana/Knox': 'CDT',
    'America/Indiana/Marengo': 'EDT',
    'America/Indiana/Petersburg': 'EDT',
    'America/Indiana/Tell_City': 'CDT',
    'America/Indiana/Vevay': 'EDT',
    'America/Indiana/Vincennes': 'EDT',
    'America/Indiana/Winamac': 'EDT',
    'America/Indianapolis': 'EDT',
    'America/Inuvik': 'MDT',
    'America/Iqaluit': 'EDT',
    'America/Jamaica': 'EST',
    'America/Jujuy': 'ART',
    'America/Juneau': 'AKDT',
    'America/Kentucky/Louisville': 'EDT',
    'America/Kentucky/Monticello': 'EDT',
    'America/Knox_IN': 'CDT',
    'America/Kralendijk': 'AST',
    'America/La_Paz': 'BOT',
    'America/Lima': 'PET',
    'America/Los_Angeles': 'PDT',
    'America/Louisville': 'EDT',
    'America/Lower_Princes': 'AST',
    'America/Maceio': 'BRT',
    'America/Managua': 'CST',
    'America/Manaus': 'AMT',
    'America/Marigot': 'AST',
    'America/Martinique': 'AST',
    'America/Matamoros': 'CDT',
    'America/Mazatlan': 'MDT',
    'America/Mendoza': 'ART',
    'America/Menominee': 'CDT',
    'Antarctica/Casey': 'CAST',
    'Antarctica/Davis': 'DAVT',
    'Antarctica/DumontDUrville': 'DDUT',
    'Antarctica/Macquarie': 'AEDT',
    'Antarctica/Mawson': 'MAWT',
    'Antarctica/McMurdo': 'NZDT',
    'Antarctica/Palmer': 'CLT',
    'Antarctica/Rothera': 'ROTT',
    'Antarctica/Syowa': 'SYOT',
    'Antarctica/Troll': 'UTC',
    'Antarctica/Vostok': 'VOST',
    'Asia/Aden': 'AST',
    'Asia/Almaty': 'ALMT',
    'Asia/Amman': 'EET',
    'Asia/Anadyr': 'ANAT',
    'Asia/Aqtau': 'AQTT',
    'Asia/Aqtobe': 'AQTT',
    'Asia/Ashgabat': 'TMT',
    'Asia/Atyrau': 'AQTT',
    'Asia/Baghdad': 'AST',
    'Asia/Bahrain': 'AST',
    'Asia/Baku': 'AZT',
    'Asia/Bangkok': 'ICT',
    'Asia/Barnaul': 'KRAT',
    'Asia/Beirut': 'EET',
    'Asia/Bishkek': 'KGT',
    'Asia/Brunei': 'BNT',
    'Asia/Chita': 'IRKT',
    'Asia/Choibalsan': 'CHOT',
    'Asia/Colombo': 'IST',
    'Asia/Damascus': 'EET',
    'Asia/Dhaka': 'BDT',
    'Asia/Dili': 'TLT',
    'Asia/Dubai': 'GST',
    'Asia/Dushanbe': 'TJT',
    'Asia/Famagusta': 'EET',
    'Asia/Gaza': 'EET',
    'Asia/Hebron': 'EET',
    'Asia/Ho_Chi_Minh': 'ICT',
    'Asia/Hong_Kong': 'HKT',
    'Asia/Hovd': 'HOVT',
    'Asia/Irkutsk': 'IRKT',
    'Asia/Jakarta': 'WIB',
    'Asia/Jayapura': 'WIT',
    'Asia/Jerusalem': 'IST',
    'Asia/Kabul': 'AFT',
    'Asia/Kamchatka': 'PETT',
    'Asia/Karachi': 'PKT',
    'Asia/Kathmandu': 'NPT',
    'Asia/Khandyga': 'YAKT',
    'Asia/Kolkata': 'IST',
    'Asia/Krasnoyarsk': 'KRAT',
    'Asia/Kuala_Lumpur': 'MYT',
    'Asia/Kuching': 'MYT',
    'Asia/Kuwait': 'AST',
    'Asia/Macau': 'CST',
    'Asia/Magadan': 'MAGT',
    'Asia/Makassar': 'WITA',
    'Asia/Manila': 'PHT',
    'Asia/Muscat': 'GST',
    'Asia/Nicosia': 'EET',
    'Asia/Novokuznetsk': 'KRAT',
    'Asia/Novosibirsk': 'NOVT',
    'Asia/Omsk': 'OMST',
    'Asia/Oral': 'ORAT',
    'Asia/Phnom_Penh': 'ICT',
    'Asia/Pontianak': 'WIB',
    'Asia/Pyongyang': 'KST',
    'Asia/Qatar': 'AST',
    'Asia/Qostanay': 'QYZT',
    'Asia/Qyzylorda': 'QYZT',
    'Asia/Riyadh': 'AST',
    'Asia/Sakhalin': 'SAKT',
    'Asia/Samarkand': 'UZT',
    'Asia/Seoul': 'KST',
    'Asia/Shanghai': 'CST',
    'Asia/Singapore': 'SGT',
    'Asia/Srednekolymsk': 'SRET',
    'Asia/Taipei': 'CST',
    'Asia/Tashkent': 'UZT',
    'Asia/Tbilisi': 'GET',
    'Asia/Tehran': 'IRST',
    'Asia/Thimphu': 'BTT',
    'Asia/Tokyo': 'JST',
    'Asia/Tomsk': 'KRAT',
    'Asia/Ulaanbaatar': 'ULAT',
    'Asia/Urumqi': 'CST',
    'Asia/Ust-Nera': 'VLAT',
    'Asia/Vientiane': 'ICT',
    'Asia/Vladivostok': 'VLAT',
    'Asia/Yakutsk': 'YAKT',
    'Asia/Yangon': 'MMT',
    'Asia/Yekaterinburg': 'YEKT',
    'Asia/Yerevan': 'AMT',
    'Atlantic/Azores': 'AZOT',
    'Atlantic/Bermuda': 'ADT',
    'Atlantic/Canary': 'WET',
    'Atlantic/Cape_Verde': 'CVT',
    'Atlantic/Faroe': 'WET',
    'Atlantic/Madeira': 'WET',
    'Atlantic/Reykjavik': 'GMT',
    'Atlantic/South_Georgia': 'GST',
    'Atlantic/St_Helena': 'GMT',
    'Atlantic/Stanley': 'FKST',
    'Australia/Adelaide': 'ACDT',
    'Australia/Brisbane': 'AEST',
    'Australia/Broken_Hill': 'ACDT',
    'Australia/Currie': 'AEST',
    'Australia/Darwin': 'ACST',
    'Australia/Eucla': 'ACWST',
    'Australia/Hobart': 'AEST',
    'Australia/Lindeman': 'AEST',
    'Australia/Lord_Howe': 'LHDT',
    'Australia/Melbourne': 'AEST',
    'Australia/Perth': 'AWST',
    'Australia/Sydney': 'AEST',
    'Europe/Amsterdam': 'CET',
    'Europe/Andorra': 'CET',
    'Europe/Astrakhan': 'MSK',
    'Europe/Athens': 'EET',
    'Europe/Belgrade': 'CET',
    'Europe/Berlin': 'CET',
    'Europe/Bratislava': 'CET',
    'Europe/Brussels': 'CET',
    'Europe/Bucharest': 'EET',
    'Europe/Budapest': 'CET',
    'Europe/Busingen': 'CET',
    'Europe/Chisinau': 'EET',
    'Europe/Copenhagen': 'CET',
    'Europe/Dublin': 'GMT',
    'Europe/Gibraltar': 'CET',
    'Europe/Guernsey': 'GMT',
    'Europe/Helsinki': 'EET',
    'Europe/Isle_of_Man': 'GMT',
    'Europe/Istanbul': 'TRT',
    'Europe/Jersey': 'GMT',
    'Europe/Kaliningrad': 'EET',
    'Europe/Kiev': 'EET',
    'Europe/Kirov': 'MSK',
    'Europe/Lisbon': 'WET',
    'Europe/Ljubljana': 'CET',
    'Europe/London': 'GMT',
    'Europe/Luxembourg': 'CET',
    'Europe/Madrid': 'CET',
    'Europe/Malta': 'CET',
    'Europe/Mariehamn': 'EET',
    'Europe/Minsk': 'FET',
    'Europe/Monaco': 'CET',
    'Europe/Moscow': 'MSK',
    'Europe/Oslo': 'CET',
    'Europe/Paris': 'CET',
    'Europe/Podgorica': 'CET',
    'Europe/Prague': 'CET',
    'Europe/Riga': 'EET',
    'Europe/Rome': 'CET',
    'Europe/Samara': 'SAMT',
    'Europe/San_Marino': 'CET',
    'Europe/Sarajevo': 'CET',
    'Europe/Saratov': 'MSK',
    'Europe/Simferopol': 'MSK',
    'Europe/Skopje': 'CET',
    'Europe/Sofia': 'EET',
    'Europe/Stockholm': 'CET',
    'Europe/Tallinn': 'EET',
    'Europe/Tirane': 'CET',
    'Europe/Ulyanovsk': 'MSK',
    'Europe/Uzhgorod': 'EET',
    'Europe/Vaduz': 'CET',
    'Europe/Vatican': 'CET',
    'Europe/Vienna': 'CET',
    'Europe/Vilnius': 'EET',
    'Europe/Volgograd': 'MSK',
    'Europe/Warsaw': 'CET',
    'Europe/Zagreb': 'CET',
    'Europe/Zaporozhye': 'EET',
    'Europe/Zurich': 'CET',
    'Indian/Chagos': 'IOT',
    'Indian/Cocos': 'CCT',
    'Indian/Christmas': 'CXT',
    'Indian/Maldives': 'MVT',
    'Indian/Mauritius': 'MUT',
    'Indian/Reunion': 'RET',
    'Indian/Comoro': 'EAT',
    'Indian/Mahe': 'SCT',
    'Indian/Antananarivo': 'EAT',
    'Pacific/Apia': 'WST',
    'Pacific/Auckland': 'NZST',
    'Pacific/Bougainville': 'BST',
    'Pacific/Chatham': 'CHAST',
    'Pacific/Chuuk': 'CHUT',
    'Pacific/Easter': 'EAST',
    'Pacific/Efate': 'VUT',
    'Pacific/Enderbury': 'PHOT',
    'Pacific/Fakaofo': 'TKT',
    'Pacific/Fiji': 'FJST',
    'Pacific/Funafuti': 'TVT',
    'Pacific/Galapagos': 'GALT',
    'Pacific/Gambier': 'GAMT',
    'Pacific/Guadalcanal': 'SBT',
    'Pacific/Guam': 'ChST',
    'Pacific/Honolulu': 'HST',
    'Pacific/Johnston': 'HST',
    'Pacific/Kiritimati': 'LINT',
    'Pacific/Kosrae': 'KOST',
    'Pacific/Kwajalein': 'MHT',
    'Pacific/Majuro': 'MHT',
    'Pacific/Marquesas': 'MART',
    'Pacific/Midway': 'SST',
    'Pacific/Nauru': 'NRT',
    'Pacific/Niue': 'NUT',
    'Pacific/Norfolk': 'NFT',
    'Pacific/Noumea': 'NCT',
    'Pacific/Pago_Pago': 'SST',
    'Pacific/Palau': 'PWT',
    'Pacific/Pitcairn': 'PST',
    'Pacific/Pohnpei': 'PONT',
    'Pacific/Port_Moresby': 'PGT',
    'Pacific/Rarotonga': 'CKT',
    'Pacific/Saipan': 'ChST',
    'Pacific/Tahiti': 'TAHT',
    'Pacific/Tarawa': 'GILT',
    'Pacific/Tongatapu': 'TOT',
    'Pacific/Wake': 'WAKT',
    'Pacific/Wallis': 'WFT'
};