import React, {useEffect, useState} from "react";
import {Box, Grid, IconButton} from "@mui/material";
import Header from "../Component/Header";
import locationIcon from "../assets/images/Discovery.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import greenArrow from "../assets/images/green_arrow.svg";
import redArrow from "../assets/images/canceledIcon.png";
import {useDispatch, useSelector} from "react-redux";
import {
    getBookingDetails, getMoreMyBookingCancelDetails,
    getMoreMyBookingDetails, getMoreMyBookingPastDetails, getMoreMyBookingUpcomingDetails, getMyBookingCancelDetails,
    getMyBookingPastDetails, getMyBookingUpcomingDetails,
    setBookingStatus
} from "../action/myBokingConstant";
import moment from "moment";
import EmptyFile from "../Component/EmptyFile";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import unsaveIcon from "../assets/images/saveIcon.svg";
import UserBookingViewPopup from "../Component/UserBookingViewPopup";
import {saveUnSaveEvent} from "../action/upcomingEventConstant";
import unsave from "../assets/images/saveIcon.svg";
import saveIcon from "../assets/images/save.svg";
import {saveService} from "../action/userServiceConstant";
import Loader from "../Component/Loader";
import {useNavigate} from "react-router-dom";
import {Empty} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import AllTransactions from "./Wallet/AllTransactions";
import {DateFormate, LocalFormate, timeZoneFormate} from "../Utils/DateFormateUtils";


const CardBtns = ({data, HandleAcssept, userAuth}) => {
    const auth_id = JSON.parse(localStorage.getItem("AdAnima_auth"))?._id;
    if (userAuth?.user_type !== "user" && (data?.booking_type == "event" || data?.booking_type == "service") && data?.is_cancelled !== true) {
        if (data?.booking_status == 'accept') {
            return (
                <div>
                    <button className="massageBtn massageBtn3 mt-3 btnn"
                            style={{
                                marginTop: "41px !important",
                                height: "60px",
                                maxWidth: "360px"
                            }}>
                        Send Message
                    </button>
                </div>
            )
        } else {
            if (data?.booking_status == 'pending') {
                if (data?.user_id?._id == auth_id || data?.modified_count !== 0) {
                    // waiting
                    return (
                        <div style={{
                            border: '1px solid #FFA500',
                            background: "#FFF0D6",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "200px",
                            padding: "10px",
                            marginTop: "10px"
                        }}>
                            <p className="t-12-bold m-0">Waiting for
                                Response</p>
                        </div>
                    )
                }
            }
        }

        if(data?.user_id?._id !== auth_id){
            if (data?.booking_status == 'accept') {
                // send massage
    
                return (
                    <div>
                        <button className="massageBtn massageBtn3 mt-3 btnn"
                                style={{
                                    marginTop: "41px !important",
                                    height: "60px",
                                    maxWidth: "360px"
                                }}>
                            Send Message
                        </button>
                    </div>
                )
    
            } else {
                if (data?.booking_status == 'pending') {
                    if (data.user_id !== auth_id && data?.modified_count == 0) {
                        // acc-dec
    
                        return (
                            <div className="w-100 d-flex mt-3">
                                <div className="col-sm-6 col-6"
                                     style={{maxWidth: "170px"}}>
                                    <button
                                        type="submit"
                                        className="btn_admina delete-button btnn"
                                        onClick={() => HandleAcssept("accept", data?._id)}>
                                        Accept
                                    </button>
                                </div>
                                <div className="col-sm-6 col-6"
                                     style={{maxWidth: "170px"}}>
                                    <button
                                        type="submit"
                                        className="btn_admina delete-confirm-button btnn"
                                        onClick={() => HandleAcssept("decline", data?._id)}
                                    >
                                        Decline
                                    </button>
                                </div>
                            </div>
                        )
    
                    } else {
                        // massage
                        return (
                            <div>
                                <button className="massageBtn massageBtn3 mt-3 btnn"
                                        style={{
                                            marginTop: "41px !important",
                                            height: "60px",
                                            maxWidth: "360px"
                                        }}>
                                    Send Message
                                </button>
                            </div>
                        )
                    }
                } else {
                    // massage
    
                    return (
                        <div>
                            <button className="massageBtn massageBtn3 mt-3 btnn"
                                    style={{
                                        marginTop: "41px !important",
                                        height: "60px",
                                        maxWidth: "360px"
                                    }}>
                                Send Message
                            </button>
                        </div>
                    )
                }
            }
        }
       


        
    }

    // if (data?.booking_status !== 'accept') {
    //
    // } else if (data?.booking_status == 'pending') {
    //     if (data.user_id !== auth_id || data?.modified_count !== 0) {
    //         // waiting
    //
    //     }
    // }
    // else {
    //     if (data?.booking_status == 'pending') {
    //         if (data.user_id !== auth_id && data?.modified_count == 0) {
    //                  // acc-dec
    //         }else {
    //             // massage
    //         }
    //     }else {
    //         // massage
    //     }
    // }
}


const Booking = () => {
    const [isShowDetails, setIsShowDetails] = useState(false);
    const bookingstatus = useSelector((state) => state);
    const [selectId, setSelectId] = useState("")
    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    const dispatch = useDispatch();
    const myBookingDetails = useSelector((state) => state.myBookingDetails.myBookingDetails);
    const [miles, setMiles] = useState(0)
    const [center, setCenter] = useState();
    const [dataLoader, setDataLoader] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isTabSelect, setIsTabSelect] = useState("Upcoming");
    const [pageCount, setPageCount] = useState({
        upcoming: 2,
        past: 2,
        cancel: 2
    })
    const [userServiceQuery, setuserServiceQuery] = useState({
        page: 1, limit: 10, latLong: center,
    });
    const [pagePagination, setPagePagination] = useState(2);

    const getEvent = () => {
        setDataLoader(true);
        if (isTabSelect == "Upcoming") {
            dispatch(getMyBookingUpcomingDetails(userServiceQuery, (a) => {
                setDataLoader(a)
            }));
        } else if (isTabSelect == "Past") {
            dispatch(getMyBookingPastDetails(userServiceQuery, (a) => {
                setDataLoader(a)
            }));
        } else {
            dispatch(getMyBookingCancelDetails(userServiceQuery, (a) => {
                setDataLoader(a)
            }));
        }
    }

    useEffect(() => {
        if (userServiceQuery) {
            getEvent()
        }
    }, [userServiceQuery, isTabSelect]);


    const savedUnsaved = (id, status, type) => {
        if (type == "service" && "service_id") {
            dispatch(saveService({id: id, status: status}))
        } else {
            dispatch(saveUnSaveEvent({id: id, status: status}))
        }

        setTimeout(() => {
            getEvent()
        }, 1000);

        setIsShowDetails(false)
    }

    useEffect(() => {
        if ("geolocation" in navigator) {
            //check geolocation available
            //try to get user current location using getCurrentPosition() method
            navigator?.geolocation?.getCurrentPosition(async (position) => {
                // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
                await setCenter({
                    lat: position?.coords?.latitude, lng: position?.coords?.longitude,
                });
            });
        } else {
            console.log("Browser doesn't support geolocation!");
        }
    }, []);

    useEffect(() => {
        setuserServiceQuery((pre) => ({...pre, latLong: center}))
    }, [center])

    const gateData = () => {
        const payload = {
            id: selectId,
            latLong: center
        }
        dispatch(getBookingDetails(payload))
    }

    const fetchmoreData = () => {
        if (isTabSelect == "Upcoming") {
            const payload = {
                page: pageCount?.upcoming,
                limit: 10,
                latLong: center
            }
            dispatch(getMoreMyBookingUpcomingDetails(payload, (a) => {
                setDataLoader(a)
            }, (a) => {
                setHasMore(a)
            }));
            setPageCount((pre) => ({...pre, upcoming: pre.upcoming + 1}))
        } else if (isTabSelect == "Past") {
            const payload = {
                page: pageCount?.past,
                limit: 10,
                latLong: center
            }
            dispatch(getMoreMyBookingPastDetails(payload, (a) => {
                setDataLoader(a)
            }, (a) => {
                setHasMore(a)
            }));
            setPageCount((pre) => ({...pre, past: pre.past + 1}))
        } else {
            const payload = {
                page: pageCount?.cancel,
                limit: 10,
                latLong: center
            }
            dispatch(getMoreMyBookingCancelDetails(payload, (a) => {
                setDataLoader(a)
            }, (a) => {
                setHasMore(a)
            }));
            setPageCount((pre) => ({...pre, cancel: pre.cancel + 1}))
        }

        // setPagePagination((pre) => (pre + 1))
    }

    const HandleAcssept = (status, id) => {
        const payload = {
            status: status, id: id
        }
        dispatch(setBookingStatus(payload))
        if (selectId && bookingstatus?.myBookingDetails?.bokingStatus?.status) {
            gateData()
            setSelectId("")
        }
        setTimeout(() => {
            getEvent()
        }, 1000);
    }

    const handleOpenCard = (event, boolean) => {
        if (event?.target?.classList?.contains('btnn')) {
            return;
        } else {
            setIsShowDetails(true)
        }
    }

    const navigate = useNavigate();

    return (<Box>
        {/*{!myBookingDetails.length && <Loader/>}*/}
        <section className="main-page">
            <Header select={"Bookings"}/>
            <div className="main-inner">
                <div className="container">

                    {/*     ===== TABS =====   */}

                    <div className="walletTabsdiv">
                        <div className={`wallet_tabs mt-1 ${isTabSelect == "Upcoming" ? 'wallet_tabs_select' : ''}`}
                             onClick={() => setIsTabSelect("Upcoming")}><p>Upcoming</p></div>
                        <div className={`wallet_tabs mt-1 ${isTabSelect == "Past" ? 'wallet_tabs_select' : ''}`}
                             onClick={() => setIsTabSelect("Past")}><p>Past</p>
                        </div>
                        <div className={`wallet_tabs mt-1 ${isTabSelect == "Cancelled" ? 'wallet_tabs_select' : ''}`}
                             onClick={() => setIsTabSelect("Cancelled")}><p>Cancelled</p>
                        </div>
                    </div>

                    <div>
                        {dataLoader ? <div className="d-flex align-items-center justify-content-center w-100"
                                           style={{height: "60vh"}}>
                                <div style={{transform: "scale(1.8)"}}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                           description={
                                               <span>Data Loading...</span>
                                           }
                                    />
                                </div>
                            </div> :
                            myBookingDetails?.length ? <div className="mt-5">
                                <div>
                                    <div style={{
                                        height: "calc(100vh - 200px)",
                                        overflow: "auto"
                                    }} id="scrollableDiv1" className="notificationScroll">
                                        <InfiniteScroll
                                            dataLength={myBookingDetails}
                                            next={fetchmoreData}
                                            hasMore={hasMore}
                                            scrollableTarget="scrollableDiv1"
                                            className="infinity-list accordion"
                                            id={'accordionExample'}
                                        >

                                            <div>
                                                <Grid container spacing={2}>
                                                    {myBookingDetails?.map((ele, i) => (<>
                                                        <Grid item xs={12} md={6}>
                                                            <Box
                                                                className="position-relative"
                                                                style={{cursor: "pointer"}}
                                                            >
                                                                <div
                                                                    className={`w-100 d-flex  box_shadow position-relative ${userAuth?.user_type == "user" ? (ele?.booking_type !== "event" ? "inner-gred" : "inner-gred-yellow") : "inner-gred"} ${userAuth?.user_type !== "user" && (ele?.booking_status == "pending" && "yellow_border")}`}
                                                                    onClick={(e) => {
                                                                        setSelectId(ele._id)
                                                                        setMiles(ele?.miles_distance)
                                                                        if (ele?.booking_type == "invite_provider") {
                                                                            navigate(`/view-events/${ele?.event_id?._id}/${ele?.event_id?.user_id}`)
                                                                        } else {
                                                                            handleOpenCard(e, true)
                                                                        }
                                                                    }}>
                                                                    <div className="position-relative"
                                                                         style={{height: '95px'}}>
                                                                        <div className="p_image_shape me-2 me-sm-3">
                                                                            <img src={ele?.display_picture}/>
                                                                        </div>
                                                                        {(userAuth?.user_type == "user" && ele?.provider_image) &&
                                                                            <img src={ele?.provider_image}
                                                                                 className="position-absolute"
                                                                                 alt=""
                                                                                 style={{
                                                                                     right: "14px",
                                                                                     bottom: "-3px",
                                                                                     height: "35px",
                                                                                     width: "35px",
                                                                                     borderRadius: "50%",
                                                                                     zIndex: "11",
                                                                                     border: "3px solid white",
                                                                                 }}/>}
                                                                    </div>
                                                                    <div style={{flexGrow: "1"}}>
                                                                        {/*<h3 className="mb-0 input-label"*/}
                                                                        {/*    style={{width: "88%"}}>*/}
                                                                        {/*    {ele?.booking_type == "service" ? ele?.service_id?.service_name : ele?.event_id?.event_name}*/}
                                                                        {/*</h3>*/}
                                                                        <h3 className="mb-0 input-label"
                                                                            style={{width: "88%"}}>
                                                                            {ele?.provider_id?._id == userAuth?._id ? ele?.user_id?.name : ele?.provider_id?.name}
                                                                        </h3>
                                                                        <p className="m-0 t-14-bold"
                                                                            style={{width: "88%"}}>
                                                                            {ele?.booking_type == "service" ? ele?.service_id?.service_name : ele?.event_id?.event_name}
                                                                        </p>
                                                                        <div className="d-flex gap-3 mt-1">


                                                                            {/*............star rating................*/}

                                                                            <>
                                                                                {userAuth?.user_type == "user" && (ele?.booking_type !== "event" &&
                                                                                    <div
                                                                                        className="d-flex align-items-end gap-2">
                                                                                        <div style={{color: "#FFC107"}}>
                                                                                            {ele?.provider_id?.average_rating >= 0.5 ? (
                                                                                                <StarIcon
                                                                                                    style={{
                                                                                                        height: "16px",
                                                                                                        width: "16px",
                                                                                                    }}
                                                                                                />) : (<StarBorderIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />)}
                                                                                            {ele?.provider_id?.average_rating >= 1.5 ? (
                                                                                                <StarIcon
                                                                                                    style={{
                                                                                                        height: "16px",
                                                                                                        width: "16px",
                                                                                                    }}
                                                                                                />) : (<StarBorderIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />)}
                                                                                            {ele?.provider_id?.average_rating >= 2.5 ? (
                                                                                                <StarIcon
                                                                                                    style={{
                                                                                                        height: "16px",
                                                                                                        width: "16px",
                                                                                                    }}
                                                                                                />) : (<StarBorderIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />)}
                                                                                            {ele?.provider_id?.average_rating >= 3.5 ? (
                                                                                                <StarIcon
                                                                                                    style={{
                                                                                                        height: "16px",
                                                                                                        width: "16px",
                                                                                                    }}
                                                                                                />) : (<StarBorderIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />)}
                                                                                            {ele?.provider_id?.average_rating >= 4.5 ? (
                                                                                                <StarIcon
                                                                                                    style={{
                                                                                                        height: "16px",
                                                                                                        width: "16px",
                                                                                                    }}
                                                                                                />) : (<StarBorderIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />)}
                                                                                        </div>
                                                                                        <p style={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: "600"
                                                                                        }}
                                                                                           className="m-0">({Math.round(ele?.provider_id?.average_rating)} /
                                                                                            5)</p>
                                                                                    </div>)}
                                                                            </>


                                                                            <>
                                                                                {
                                                                                    userAuth?.user_type == "user" ? (ele?.booking_type == "event" &&
                                                                                            <div>
                                                                                                <p className="m-0 selected-item text-dark">
                                                                                                    {DateFormate(LocalFormate(ele?.start_date, ele?.service_id?.date_timezone || ele?.event_id?.date_timezone))}
                                                                                                </p>

                                                                                                {userAuth?.user_type !== "user" &&
                                                                                                    <p className="m-0 selected-item text-dark">
                                                                                                        {ele?.participants_count} Participants
                                                                                                    </p>}
                                                                                                {userAuth?.user_type !== "user" &&
                                                                                                    <div
                                                                                                        className="d-flex align-items-center">
                                                                                                        {(ele?.booking_type !== "service" && ele?.booking_type !== "event") ?
                                                                                                            <>
                                                                                                                <p className="m-0 selected-item text-dark">
                                                                                                                    {ele?.booking_type == "invite_provider" ? "Event Invitation" : ele?.booking_type == "hire_provider" ? "Hire Request" : ele?.booking_type}
                                                                                                                </p>
                                                                                                            </> : null}
                                                                                                    </div>}
                                                                                            </div>) :
                                                                                        <div>
                                                                                            <p className="m-0 selected-item text-dark">
                                                                                                {/*{moment(ele?.booking_date).format("MMMM D YYYY")}*/}

                                                                                                {ele?.booking_type !== "event" ?
                                                                                                    DateFormate(LocalFormate(ele?.booking_date, ele?.service_id?.date_timezone || ele?.event_id?.date_timezone))
                                                                                                    :
                                                                                                    DateFormate(LocalFormate(ele?.start_date_time,  ele?.event_id?.date_timezone) , LocalFormate(ele?.end_date_time, ele?.event_id?.date_timezone) )

                                                                                                }
                                                                                                {/*{moment(LocalFormate(ele?.start_date) || LocalFormate(ele?.event_id?.start_date) || LocalFormate(ele?.start_date_time)).format('MMM Do') !== moment(LocalFormate(ele?.end_date) || LocalFormate(ele?.event_id?.end_date) || LocalFormate(ele?.end_date_time)).format('MMM Do') ? (`${moment(LocalFormate(ele?.start_date) || LocalFormate(ele?.event_id?.start_date) || LocalFormate(ele?.start_date_time)).format('D')} - ${moment(LocalFormate(ele?.end_date) || LocalFormate(ele?.event_id?.end_date) || LocalFormate(ele?.end_date_time)).format('D MMM, YYYY')}`) : (`${moment(LocalFormate(ele?.start_date) || LocalFormate(ele?.event_id?.start_date) || LocalFormate(ele?.start_date_time)).format('D MMMM YYYY')}`)}*/}
                                                                                            </p>
                                                                                            {(userAuth?.user_type !== "user" && (ele?.booking_type == "service" || ele?.booking_type == "event")) &&
                                                                                                <p className="m-0 selected-item text-dark">
                                                                                                    {ele?.no_of_participants ? ele?.no_of_participants : ele?.participants_count} Participants
                                                                                                </p>}
                                                                                            {userAuth?.user_type !== "user" &&
                                                                                                <div
                                                                                                    className="d-flex align-items-center">
                                                                                                    {(ele?.booking_type !== "service" && ele?.booking_type !== "event") ?
                                                                                                        <>
                                                                                                            <p className="m-0 selected-item text-dark">
                                                                                                                {ele?.booking_type == "invite_provider" ? "Event Invitation" : ele?.booking_type == "hire_provider" ? "Hire Request" : ele?.booking_type}
                                                                                                            </p>
                                                                                                        </> : null}
                                                                                                </div>}
                                                                                        </div>
                                                                                }
                                                                                <div>
                                                                                    {userAuth?.user_type == "user" ? (ele?.booking_type == "event" &&
                                                                                            <div
                                                                                                className="d-flex align-items-center">
                                                                                                <FiberManualRecordIcon
                                                                                                    style={{
                                                                                                        transform: "scale(0.4)",
                                                                                                        fill: "#919191",
                                                                                                    }}
                                                                                                />
                                                                                                <p className="m-0 selected-item text-dark">
                                                                                                    {moment(LocalFormate(ele?.start_date_time, ele?.event_id?.date_timezone)).format('LT')} to {moment(LocalFormate(ele?.end_date_time, ele?.event_id?.date_timezone)).format('LT')} ({timeZoneFormate(ele?.start_date_time, ele?.event_id?.date_timezone)})
                                                                                                </p>
                                                                                            </div>) :
                                                                                        <div
                                                                                            className="d-flex align-items-center">
                                                                                            <FiberManualRecordIcon
                                                                                                style={{
                                                                                                    transform: "scale(0.4)",
                                                                                                    fill: "#919191",
                                                                                                }}
                                                                                            />
                                                                                            <p className="m-0 selected-item text-dark">
                                                                                                {moment(LocalFormate(ele?.start_date_time, ele?.service_id?.date_timezone || ele?.event_id?.date_timezone) || LocalFormate(ele?.event_id?.start_time, ele?.service_id?.date_timezone || ele?.event_id?.date_timezone)).format('LT')} ({timeZoneFormate(ele?.event_id?.start_time, ele?.service_id?.date_timezone || ele?.event_id?.date_timezone)})
                                                                                            </p>
                                                                                        </div>}
                                                                                    {userAuth?.user_type !== "user" &&
                                                                                        <div
                                                                                            className="d-flex align-items-center">
                                                                                            {(ele?.booking_type == "service" || ele?.booking_type == "event") ?
                                                                                                <>
                                                                                                    <FiberManualRecordIcon
                                                                                                        style={{
                                                                                                            transform: "scale(0.4)",
                                                                                                            fill: "#919191",
                                                                                                        }}
                                                                                                    />

                                                                                                    <p className="m-0 selected-item text-dark">
                                                                                                        {ele?.booking_type == "invite_provider" ? "Event Invitation" : ele?.booking_type == "hire_provider" ? "Hire Request" : ele?.booking_type}
                                                                                                    </p>
                                                                                                </> : null}
                                                                                        </div>}
                                                                                </div>
                                                                            </>
                                                                        </div>
                                                                        {userAuth?.user_type !== "user" && (ele?.booking_type == "hire_provider" ?
                                                                            (ele?.event_id?.is_virtual_event == true) ?
                                                                                <p className="m-0 selected-item yelloe-t-bold">Type:
                                                                                    Virtual</p> :
                                                                                <div
                                                                                    className="d-flex align-items-center gap-2 mt-1">
                                                                                    <img src={locationIcon} alt=""/>
                                                                                    <p className="m-0 selected-item yelloe-t-bold">{(userAuth?.user_type == "user") ? (ele?.booking_type == "event" ? ele?.address || ele?.event_id?.address || ele?.service_id?.address : `${ele.miles_distance} Mile Away`) : ele?.address || ele?.event_id?.address || ele?.service_id?.address}</p>
                                                                                </div>
                                                                            : <div>
                                                                                {/*<p className="m-0 t-13-bold">{ele?.provider_id?._id == userAuth?._id ? ele?.user_id?.name : ele?.provider_id?.name}</p>*/}
                                                                            </div>)}
                                                                        <>
                                                                            {userAuth?.user_type == "user" && ele?.booking_type == "event" ?
                                                                                <div
                                                                                    className="d-flex align-items-end gap-2">
                                                                                    <div style={{color: "#FFC107"}}>
                                                                                        {ele?.provider_id?.average_rating >= 0.5 ? (
                                                                                            <StarIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />) : (<StarBorderIcon
                                                                                            style={{
                                                                                                height: "16px",
                                                                                                width: "16px",
                                                                                            }}
                                                                                        />)}
                                                                                        {ele?.provider_id?.average_rating >= 1.5 ? (
                                                                                            <StarIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />) : (<StarBorderIcon
                                                                                            style={{
                                                                                                height: "16px",
                                                                                                width: "16px",
                                                                                            }}
                                                                                        />)}
                                                                                        {ele?.provider_id?.average_rating >= 2.5 ? (
                                                                                            <StarIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />) : (<StarBorderIcon
                                                                                            style={{
                                                                                                height: "16px",
                                                                                                width: "16px",
                                                                                            }}
                                                                                        />)}
                                                                                        {ele?.provider_id?.average_rating >= 3.5 ? (
                                                                                            <StarIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />) : (<StarBorderIcon
                                                                                            style={{
                                                                                                height: "16px",
                                                                                                width: "16px",
                                                                                            }}
                                                                                        />)}
                                                                                        {ele?.provider_id?.average_rating >= 4.5 ? (
                                                                                            <StarIcon
                                                                                                style={{
                                                                                                    height: "16px",
                                                                                                    width: "16px",
                                                                                                }}
                                                                                            />) : (<StarBorderIcon
                                                                                            style={{
                                                                                                height: "16px",
                                                                                                width: "16px",
                                                                                            }}
                                                                                        />)}
                                                                                    </div>
                                                                                    <p style={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: "600"
                                                                                    }}
                                                                                       className="m-0">({Math.round(ele?.provider_id?.average_rating)} /
                                                                                        5)</p>
                                                                                </div> : null}
                                                                        </>
                                                                        <div
                                                                            className="d-flex align-items-center gap-5 mt-1">
                                                                            {/*{userAuth?.user_type == "user" && (ele?.booking_type !== "event" &&*/}
                                                                            {/*    <p className="t-14-bold m-0">{ele?.provider_id?._id == userAuth?._id ? ele?.user_id?.name : ele?.provider_id?.name}</p>)}*/}
                                                                            {(ele?.booking_type == "event" && ele?.event_id?.is_virtual_event == true) ?
                                                                                <p className="m-0 selected-item yelloe-t-bold">Type:
                                                                                    Virtual</p> : (ele?.address && ele?.address !== "null") &&
                                                                                <div
                                                                                    className="d-flex align-items-center gap-2">
                                                                                    <img src={locationIcon} alt=""/>
                                                                                    <p className="m-0 selected-item yelloe-t-bold">{(userAuth?.user_type == "user") ? (ele?.booking_type == "event" ? ele?.address || ele?.event_id?.address || ele?.service_id?.address : `${ele.miles_distance} Mile Away`) : ele?.address || ele?.event_id?.address || ele?.service_id?.address}</p>
                                                                                </div>}
                                                                        </div>
                                                                        {/*{(ele?.booking_type !== "hire_provider" && moment(ele?.start_date_time).format('MMMM Do YYYY, h:mm:ss a') >= moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')) &&*/}
                                                                        {/*    <div>*/}
                                                                        {/*        {<div>*/}
                                                                        {/*            {userAuth?.user_type !== "user" && (ele?.booking_status == 'accept' ? (*/}
                                                                        {/*                <div>*/}
                                                                        {/*                    <button className="massageBtn massageBtn3 mt-3 btnn"*/}
                                                                        {/*                            style={{*/}
                                                                        {/*                                marginTop: "41px !important",*/}
                                                                        {/*                                height: "60px",*/}
                                                                        {/*                                maxWidth: "360px"*/}
                                                                        {/*                            }}>*/}
                                                                        {/*                        Send Message*/}
                                                                        {/*                    </button>*/}
                                                                        {/*                </div>) : (ele.booking_status == "pending" && (ele.user_id == JSON.parse(localStorage.getItem("AdAnima_auth"))?._id ?*/}
                                                                        {/*                <div style={{*/}
                                                                        {/*                    border: '1px solid #FFA500',*/}
                                                                        {/*                    background: "#FFF0D6",*/}
                                                                        {/*                    borderRadius: "10px",*/}
                                                                        {/*                    display: "flex",*/}
                                                                        {/*                    alignItems: "center",*/}
                                                                        {/*                    justifyContent: "center",*/}
                                                                        {/*                    width: "200px",*/}
                                                                        {/*                    padding: "10px",*/}
                                                                        {/*                    marginTop: "10px"*/}
                                                                        {/*                }}>*/}
                                                                        {/*                    <p className="t-12-bold m-0">Waiting for*/}
                                                                        {/*                        Response</p>*/}
                                                                        {/*                </div> : <div className="w-100 d-flex mt-3">*/}
                                                                        {/*                    <div className="col-sm-6 col-6"*/}
                                                                        {/*                         style={{maxWidth: "170px"}}>*/}
                                                                        {/*                        <button*/}
                                                                        {/*                            type="submit"*/}
                                                                        {/*                            className="btn_admina delete-button btnn"*/}
                                                                        {/*                            onClick={() => HandleAcssept("accept", ele?._id)}>*/}
                                                                        {/*                            Accept*/}
                                                                        {/*                        </button>*/}
                                                                        {/*                    </div>*/}
                                                                        {/*                    <div className="col-sm-6 col-6"*/}
                                                                        {/*                         style={{maxWidth: "170px"}}>*/}
                                                                        {/*                        <button*/}
                                                                        {/*                            type="submit"*/}
                                                                        {/*                            className="btn_admina delete-confirm-button btnn"*/}
                                                                        {/*                            onClick={() => HandleAcssept("decline", ele?._id)}*/}
                                                                        {/*                        >*/}
                                                                        {/*                            Decline*/}
                                                                        {/*                        </button>*/}
                                                                        {/*                    </div>*/}
                                                                        {/*                </div>)))}*/}
                                                                        {/*        </div>}*/}
                                                                        {/*    </div>}*/}

                                                                        {
                                                                            // (ele?.booking_type !== "hire_provider" && moment(ele?.start_date_time).format('MMMM Do YYYY, h:mm:ss a') >= moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')) &&
                                                                            <CardBtns data={ele}
                                                                                      HandleAcssept={HandleAcssept}
                                                                                      userAuth={userAuth}/>}
                                                                    </div>
                                                                </div>
                                                                <div className="card_green_arrow">
                                                                    {userAuth?.user_type !== "user" ? (ele?.booking_status == 'accept' ?
                                                                        <img src={greenArrow} alt="validate"
                                                                        /> : ele?.is_cancelled ? <img src={redArrow} alt=""
                                                                                                      style={{width: '25px'}}/> : null) : (
                                                                        <button className="btnn">
                                                                            <IconButton onClick={() => {
                                                                                handleOpenCard("", false)
                                                                                if (ele.is_save) {
                                                                                    savedUnsaved(ele?.service_id?._id || ele?.event_id?._id, false, ele?.booking_type)
                                                                                } else {
                                                                                    savedUnsaved(ele?.service_id?._id || ele?.event_id?._id, true, ele?.booking_type)
                                                                                }
                                                                            }}>
                                                                                <img src={ele.is_save ? saveIcon : unsave}
                                                                                     alt=""
                                                                                     style={{width: "25px"}}/>
                                                                            </IconButton>
                                                                        </button>)}
                                                                </div>
                                                            </Box>
                                                        </Grid>
                                                    </>))}
                                                </Grid>
                                            </div>


                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                            // : dataLoader ?
                            // <div className="d-flex align-items-center justify-content-center w-100"
                            //      style={{height: "60vh", transform: "scale(1.8)"}}>
                            //     <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                            //            description={
                            //                <span>Data Loading...</span>
                            //            }
                            //     />
                            // </div>
                            :
                            <div className="d-flex align-items-center justify-content-center w-100"
                                 style={{height: "60vh"}}>
                                <div style={{transform: "scale(1.8)"}}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                           description={
                                               <span>No Data Found</span>
                                           }
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>

        <UserBookingViewPopup setOpen={setIsShowDetails} miles={miles} open={isShowDetails} selectId={selectId}
                              getEvent={getEvent} HandleAcssept={HandleAcssept} setSelectId={setSelectId}/>
    </Box>);
};

export default Booking;
