import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GOOGLE_API_KEY } from "../Configration/configration";
import { saveHomeEvent, saveService } from "../action/userServiceConstant";
import EventSharePopup from "./EventSharePopup";
import CustomMarkerIcon from "../assets/images/blue_dot.png";
import placeImg from "../assets/images/userIconWithBg.png";
import redMarker from "../assets/images/redLocationIcon.svg";
import yellowMarker from "../assets/images/yellowLocationIcon.svg";

const HomeMap = (props) => {
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const [info, setInfo] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  });

  const [isShare, setShare] = useState(false);
  const [popUpData, setPopUpData] = useState({});
  const [mapCenter, setMapCenter] = useState(props?.center);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onMarkerClick = (props, marker, e, data) => {
    setInfo({
      selectedPlace: data,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  const onClose = () => {
    setInfo({
      showingInfoWindow: false,
      activeMarker: null,
    });
  };

  useEffect(() => {
    if (!mapCenter) {
      setMapCenter({
        lat: props?.serviceList[0]?.location?.coordinates[1] || 0,
        lng: props?.serviceList[0]?.location?.coordinates[0] || 0,
      });
    } else {
      setMapCenter(props?.center);
    }
  }, [props?.center]);

  const handleCenterChanged = (map) => {
    setMapCenter({
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
    });
  };

  const handleShare = (data) => {
    setShare(true);
    let payLoad = {};
    if (data?.is_service == null) {
      payLoad = {
        user_id: { _id: data?.user_id?._id },
        _id: data?._id,
        typeSharing: "event_type",
        path: "view-events",
      };
    } else {
      payLoad = {
        user_id: { _id: data?.user_id?._id || data?._id },
        _id: data?.user_id?._id,
        typeSharing: "service_type",
        path: "provider_details",
      };
    }
    setPopUpData(payLoad);
  };
  const handleOpenCard = (event, boolean) => {
    if (event?.target?.classList?.contains("btnn")) {
      return;
    } else {
      if (info?.selectedPlace?.is_service == null) {
        navigate(
          `/view-events/${info?.selectedPlace?._id}/${info?.selectedPlace?.user_id?._id}`
        );
      } else {
        if (info?.selectedPlace?.service_name) {
          navigate(`/provider_details/${info?.selectedPlace?.user_id?._id}`);
        } else {
          navigate(`/provider_details/${info?.selectedPlace?._id}`);
        }
      }
    }
  };

  const saveProvider = (id, status) => {
    dispatch(saveService({ id: id, status: status }));
  };

  const saveEvent = (id, status) => {
    dispatch(saveHomeEvent({ id: id, status: status }));
  };

  const applyMapStyle = (map) => {
    const mapStyle = [{ featureType: "poi", stylers: [{ visibility: "off" }] }];
    map.setOptions({ styles: mapStyle });
  };

  return isLoaded ? (
    <>
      <GoogleMap
        google={props.google}
      
        zoom={10}
       
        maxZoom={20}
        minZoom={5}
        center={mapCenter}
      
        options={{
          fullscreenControl: false,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          panControl: false,
          rotateControl: false,
        }}
        onReady={(mapProps, map) => applyMapStyle(map)}
        onDragend={(e, map) => handleCenterChanged(map)}
        onLoad={(map) => applyMapStyle(map)}
        
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      >
        {props?.serviceList?.map((data) => {
          if (data?.is_service_array == true) {
            return data?.services?.map((service) => {
              return (
                service?.location?.coordinates[1] && (
                  <Marker
                    key={`${service?._id}`}
                    title={service?.address}
                    name={service?.service_name || "Provider"}
                    position={{
                      lat: service?.location?.coordinates[1],
                      lng: service?.location?.coordinates[0],
                    }}
                    onClick={(props, marker, e) =>
                      onMarkerClick(props, marker, e, {
                        ...service,
                        user_id: data?.user_id,
                      })
                    }
                    icon={{
                      url: redMarker, // Use the imported custom marker icon
                      scaledSize: new window.google.maps.Size(40, 40), // Adjust the size if needed
                      // Add any other necessary properties
                    }}
                  >
                    {" "}
                    {info.showingInfoWindow &&
                      service?._id === info.selectedPlace._id && (
                        <InfoWindow
                          marker={info.activeMarker}
                          visible={info.showingInfoWindow}
                          //  position={info.activeMarker}
                          onCloseClick={onClose}
                        >
                          <div
                            className="map-tooltip"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleOpenCard(e)}
                          >
                            <h6
                              className="map_title"
                              style={{ wordBreak: "break-word" }}
                            >
                              {info?.selectedPlace?.service_name ||
                                info?.selectedPlace?.event_name ||
                                "Provider"}
                            </h6>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <div className="d-flex align-items-center">
                                  <div className="p_image_shape me-2 me-sm-2 p_image_shape_map">
                                    <img
                                      src={
                                        info?.selectedPlace
                                          ?.user_profile_picture ||
                                        info?.selectedPlace?.profile_picture ||
                                        info?.selectedPlace?.user_id
                                          ?.user_profile_picture ||
                                        info?.selectedPlace?.user_id
                                          ?.profile_picture ||
                                        placeImg
                                      }
                                    />
                                  </div>
                                  <p className="map_name m-0 p-0">
                                    {info?.selectedPlace?.user_id?.name ||
                                      info?.selectedPlace?.name}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center mt-1">
                                  <div className="me-2 me-sm-2 ms-1">
                                    <img
                                      src="images/mapDiscovery.svg"
                                      style={{ width: "18px" }}
                                    />
                                  </div>
                                  <p className="map_des m-0 p-0">
                                    Approx. -{" "}
                                    {info?.selectedPlace?.miles_distance} Mile
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="p_image_shape me-2 me-sm-2 p_image_map_icon d-flex align-items-center justify-content-center">
                                  <button className="btnn">
                                    <IconButton
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleShare(info?.selectedPlace);
                                      }}
                                    >
                                      <img src="images/shareIcon.svg" />
                                    </IconButton>
                                  </button>
                                </div>
                                {props.userAuth?.token && (
                                    <button
                                      className="p_image_shape me-2 me-sm-2 p_image_map_icon d-flex align-items-center justify-content-center btnn"
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        // if (
                                        //   info?.selectedPlace?.is_service ==
                                        //   null
                                        // ) {
                                        //   saveEvent(
                                        //     info?.selectedPlace?._id,
                                        //     !info?.selectedPlace?.is_save
                                        //   );
                                        // } else {
                                          saveProvider(
                                            info?.selectedPlace?._id,
                                            !info?.selectedPlace?.is_save
                                          );
                                        // }
                                        setInfo({
                                          ...info,
                                          selectedPlace: {
                                            ...info?.selectedPlace,
                                            is_save:
                                              !info?.selectedPlace?.is_save,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={
                                          info?.selectedPlace?.is_save
                                            ? "images/save.svg"
                                            : "images/saveIcon.svg"
                                        }
                                      />
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                  </Marker>
                )
              );
            });
          } else if (data?.is_service == null) {
            // if (data?.is_service == null){
            return (
              data?.location?.coordinates[1] && (
                <Marker
                  key={`${data?._id}`}
                  title={data?.address}
                  name={data?.service_name || "Provider"}
                  position={{
                    lat: data?.location?.coordinates[1],
                    lng: data?.location?.coordinates[0],
                  }}
                  onClick={(props, marker, e) =>
                    onMarkerClick(props, marker, e, data)
                  }
                  icon={{
                    url: yellowMarker, // Use the imported custom marker icon
                    scaledSize: new window.google.maps.Size(40, 40), // Adjust the size if needed
                    // Add any other necessary properties
                  }}
                >
                  {info.showingInfoWindow &&
                    data?._id === info.selectedPlace._id && (
                      <InfoWindow
                        marker={info.activeMarker}
                        visible={info.showingInfoWindow}
                        //  position={info.activeMarker}
                        onCloseClick={onClose}
                      >
                        <div
                          className="map-tooltip"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleOpenCard(e)}
                        >
                          <h6
                            className="map_title"
                            style={{ wordBreak: "break-word" }}
                          >
                            {info?.selectedPlace?.service_name ||
                              info?.selectedPlace?.event_name ||
                              "Provider"}
                          </h6>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <div className="d-flex align-items-center">
                                <div className="p_image_shape me-2 me-sm-2 p_image_shape_map">
                                  <img
                                    src={
                                      info?.selectedPlace
                                        ?.user_profile_picture ||
                                      info?.selectedPlace?.profile_picture ||
                                      info?.selectedPlace?.user_id
                                        ?.user_profile_picture ||
                                      info?.selectedPlace?.user_id
                                        ?.profile_picture ||
                                      placeImg
                                    }
                                  />
                                </div>
                                <p className="map_name m-0 p-0">
                                  {info?.selectedPlace?.user_id?.name ||
                                    info?.selectedPlace?.name}
                                </p>
                              </div>
                              <div className="d-flex align-items-center mt-1">
                                <div className="me-2 me-sm-2 ms-1">
                                  <img
                                    src="images/mapDiscovery.svg"
                                    style={{ width: "18px" }}
                                  />
                                </div>
                                <p className="map_des m-0 p-0">
                                  Approx. -{" "}
                                  {info?.selectedPlace?.miles_distance} Mile
                                </p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="p_image_shape me-2 me-sm-2 p_image_map_icon d-flex align-items-center justify-content-center">
                                <button className="btnn">
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleShare(info?.selectedPlace);
                                    }}
                                  >
                                    <img src="images/shareIcon.svg" />
                                  </IconButton>
                                </button>
                              </div>
                              {props.userAuth?.token && (
                                  <button
                                    className="p_image_shape me-2 me-sm-2 p_image_map_icon d-flex align-items-center justify-content-center btnn"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      // if (
                                      //   info?.selectedPlace?.is_service == null
                                      // ) {
                                        saveEvent(
                                          info?.selectedPlace?._id,
                                          !info?.selectedPlace?.is_save
                                        );
                                      // } else {
                                      //   saveProvider(
                                      //     info?.selectedPlace?._id,
                                      //     !info?.selectedPlace?.is_save
                                      //   );
                                      // }
                                      setInfo({
                                        ...info,
                                        selectedPlace: {
                                          ...info?.selectedPlace,
                                          is_save:
                                            !info?.selectedPlace?.is_save,
                                        },
                                      });
                                    }}
                                  >
                                    <img
                                      src={
                                        info?.selectedPlace?.is_save
                                          ? "images/save.svg"
                                          : "images/saveIcon.svg"
                                      }
                                    />
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                </Marker>
              )
            );
            // }
            // else {

            // }
          } else {
            return (
              data?.location?.coordinates[1] && (
                <Marker
                  key={`${data?._id}`}
                  title={data.address}
                  name={data.service_name || "Provider"}
                  position={{
                    lat: data?.location?.coordinates[1],
                    lng: data?.location?.coordinates[0],
                  }}
                  onClick={(props, marker, e) =>
                    onMarkerClick(props, marker, e, data)
                  }
                  icon={{
                    url: CustomMarkerIcon, // Use the imported custom marker icon
                    scaledSize: new window.google.maps.Size(20, 20), // Adjust the size if needed
                    // Add any other necessary properties
                  }}
                >
                  {" "}
                  {info.showingInfoWindow &&
                    data?._id === info.selectedPlace._id && (
                      <InfoWindow
                        marker={info.activeMarker}
                        visible={info.showingInfoWindow}
                        //  position={info.activeMarker}
                        onCloseClick={onClose}
                      >
                        <div
                          className="map-tooltip"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleOpenCard(e)}
                        >
                          <h6
                            className="map_title"
                            style={{ wordBreak: "break-word" }}
                          >
                            {info?.selectedPlace?.service_name ||
                              info?.selectedPlace?.event_name ||
                              "Provider"}
                          </h6>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <div className="d-flex align-items-center">
                                <div className="p_image_shape me-2 me-sm-2 p_image_shape_map">
                                  <img
                                    src={
                                      info?.selectedPlace
                                        ?.user_profile_picture ||
                                      info?.selectedPlace?.profile_picture ||
                                      info?.selectedPlace?.user_id
                                        ?.user_profile_picture ||
                                      info?.selectedPlace?.user_id
                                        ?.profile_picture ||
                                      placeImg
                                    }
                                  />
                                </div>
                                <p className="map_name m-0 p-0">
                                  {info?.selectedPlace?.user_id?.name ||
                                    info?.selectedPlace?.name}
                                </p>
                              </div>
                              <div className="d-flex align-items-center mt-1">
                                <div className="me-2 me-sm-2 ms-1">
                                  <img
                                    src="images/mapDiscovery.svg"
                                    style={{ width: "18px" }}
                                  />
                                </div>
                                <p className="map_des m-0 p-0">
                                  Approx. -{" "}
                                  {info?.selectedPlace?.miles_distance} Mile
                                </p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="p_image_shape me-2 me-sm-2 p_image_map_icon d-flex align-items-center justify-content-center">
                                <button className="btnn">
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleShare(info?.selectedPlace);
                                    }}
                                  >
                                    <img src="images/shareIcon.svg" />
                                  </IconButton>
                                </button>
                              </div>
                              {props.userAuth?.token &&
                                (info?.selectedPlace?.is_service == null ||
                                  info?.selectedPlace?.is_service == true) && (
                                  <button
                                    className="p_image_shape me-2 me-sm-2 p_image_map_icon d-flex align-items-center justify-content-center btnn"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      if (
                                        info?.selectedPlace?.is_service == null
                                      ) {
                                        saveEvent(
                                          info?.selectedPlace?._id,
                                          !info?.selectedPlace?.is_save
                                        );
                                      } else {
                                        saveProvider(
                                          info?.selectedPlace?._id,
                                          !info?.selectedPlace?.is_save
                                        );
                                      }
                                      setInfo({
                                        ...info,
                                        selectedPlace: {
                                          ...info?.selectedPlace,
                                          is_save:
                                            !info?.selectedPlace?.is_save,
                                        },
                                      });
                                    }}
                                  >
                                    <img
                                      src={
                                        info?.selectedPlace?.is_save
                                          ? "images/save.svg"
                                          : "images/saveIcon.svg"
                                      }
                                    />
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                </Marker>
              )
            );
          }
        })}
      </GoogleMap>
      <EventSharePopup
        setOpen={setShare}
        open={isShare}
        typeSharing={popUpData?.typeSharing}
        path={popUpData?.path}
        providerId={popUpData?._id}
        data={popUpData}
        eventId={popUpData?._id}
      />
    </>
  ) : (
    <></>
  );
};

export default HomeMap;
