import React, {useEffect, useState} from 'react';
import {IconButton} from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import {Modal} from "react-bootstrap";
import {Select} from "antd";
import {UpdatRadius} from "../action/versionConstant";
import {useDispatch} from "react-redux";


const RADIUS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", '19', "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', "49", '50']

const RadiusPopup = ({open, setOpen}) => {
    const [isRadius, setIsRadius] = useState("22");
    const local_radius = localStorage.getItem("radius")
    const dispatch = useDispatch();
    const onChange = (value) => {
        setIsRadius(value)
    };
    // const onSearch = (value) => {
    //     setIsRadius(value)
    // };

// Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    useEffect(() => {
        setIsRadius(local_radius)
    }, [local_radius]);

    return (
        <>
            <Modal
                centered
                show={open}
                onHide={() => {
                    setOpen(false);
                }}
                dialogClassName="delete-modal"
            >
                <div className="Congratulations_msg">
                    <div className="group" style={{borderRadius: "10px"}}>
                        <div className="py-2 px-2" style={{borderRadius: "10px"}}>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-22-bold m-0">Radius</p>
                                <IconButton onClick={() => setOpen(false)}>
                                    <img src={closeIcon} alt=""/>
                                </IconButton>
                            </div>
                            <div className="mt-2">
                                <Select
                                    MenuProps={{
                                        style: {zIndex: "2000"}
                                    }}
                                    showSearch
                                    size={"large"}
                                    style={{width: "100%"}}
                                    placeholder="Select a person"
                                    defaultValue="22"
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    value={isRadius}
                                    // onSearch={onSearch}
                                    filterOption={filterOption}
                                    options={RADIUS?.map((ele) => {
                                        return {
                                            value: ele,
                                            label: ele,
                                        }
                                    })}
                                />
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <button className="massageBtn mt-3"
                                        onClick={() => {
                                            dispatch(UpdatRadius(isRadius))
                                            localStorage.setItem("radius", isRadius)
                                            setOpen(false);
                                        }}
                                    // onClick={(e) => {
                                    //     navigator.clipboard.writeText(shortLink)
                                    //     setIsCopy(true)
                                    //     setTimeout(() => {
                                    //         setIsCopy(false)
                                    //     }, 1000);
                                    // }}
                                    // onMouseEnter={handlePopoverOpen}
                                    // onMouseLeave={handlePopoverClose}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default RadiusPopup;