import React from 'react';
import {Grid} from "@mui/material";
import bannerImg from "../../assets/images/discount_banner.png";

const DiscountBanner = ({handleCreateCode, loader, setIs_generate}) => {
    return (
        <>
            <div>
                <h1 className="d-inline-block mt-4">Discount Code</h1>
            </div>

            <div className="mt-5">
                <Grid container spacing={2} sx={{alignItems: "center"}}>
                    <Grid item xs={12} md={12}>
                        <div className="w-100 align-items-center justify-content-center d-flex">
                            <img src={bannerImg} alt="" style={{
                                width: '100%',
                                maxWidth: '460px'
                            }}/>
                        </div>
                        <div
                            className="w-100 align-items-center justify-content-center d-flex mt-3">
                            <div className="ref-desc-width">
                                <div className="d-flex align-items-center gap-3">
                                    <p className="m-0 t-19-500 ref-desc text-center" style={{color: '#363853'}}>Discounts you create
                                        only apply to your earnings, not platform fees. Even with a 100% discount,
                                        participants still pay fees on the original price. For any queries, contact
                                        support.</p>
                                </div>

                                <div
                                    className="d-flex flex-wrap align-items-center justify-content-center mt-4">
                                    {
                                        loader ? <a
                                                href="#"
                                                style={{
                                                    maxWidth: "350px",
                                                    height: "45px",
                                                    fontWeight: '600',
                                                    padding: '12px 15px'
                                                }}
                                                onClick={(e) => e.preventDefault()}
                                                className="btn_White massageBtn mt-3"
                                            >
                                                {/* save */}
                                                <div className="loader"></div>
                                            </a> :
                                            <button className="massageBtn mt-3"
                                                    onClick={() => setIs_generate(true)}
                                                    style={{
                                                        maxWidth: "350px",
                                                        height: "45px",
                                                        fontWeight: '600'
                                                    }}>Generate Discount Code
                                            </button>
                                    }

                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>

                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default DiscountBanner;