import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import AddMedia from "./AddMedia";
import { addMyPageDetails } from "../../action/myPageConstant";
import checked from "../../assets/images/CheckedBox.svg";
import check from "../../assets/images/CheckBox.svg";
import ServiceCard from "./ServiceCard";
import ProviderCard from "./ProviderCard";
import PackageCard from "./PackageCard";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import radioIcon from "../../assets/images/blueCheck.png";
import radioCheckedIcon from "../../assets/images/blueChecked.png";
import PackagePopup from "./PackagePopup";
import plusIcon from "../../assets/images/whiteplus.png";
import { toast } from "react-toastify";
import AddActivity from "./AddActivity";
import { createEvent, editEvent } from "../../action/myEventConstant";
import { useDispatch, useSelector } from "react-redux";
import PlacePopUp from "../PlacePopUp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getEvent } from "../../action/eventConstant";
import moment from "moment";
import closeIcon from "../../assets/images/close-red-icon.png";
import addIcon from "../../assets/images/whiteplus.png";
import { GoogleMeet, googleUrl } from "../../Utils/GoogleMeet";
import EventLinkPopup from "../EventLinkPopup";
import EventSharePopup from "../EventSharePopup";
import { LocalFormate } from "../../Utils/DateFormateUtils";
import PreviewEvent from "../PreviewEvent";
import BankAlartPopup from "../BankAlartPopup";
import BankView from "../../Pages/Bank/BankView";
import AddEventMedia from "./AddEventMedia";
import { v4 as uuidv4 } from "uuid";
import {
  imgUrl,
  ENVIROMENT,
  GOOGLE_CLIENT_ID,
  GOOGLE_API_KEY,
} from "../../Configration/configration";
import ticket from "../../assets/images/ticket.png";
import GenerateCodePopup from "./GenerateCodePopup";
import { generateDiscount } from "../../action/discountCodeConstant";
import { gapi } from "gapi-script";
import {
  discountCodeList,
  editDiscount,
} from "../../action/discountCodeConstant";

const getDateTime = (date, time) => {
  const startDate = date;
  const startTime = time;

  // Convert start date and time strings to Date objects
  const startDateObj = new Date(startDate);
  const startTimeObj = new Date(startTime);

  // Set the time of startDateObj to match startTimeObj
  startDateObj.setHours(startTimeObj?.getUTCHours());
  startDateObj.setMinutes(startTimeObj?.getUTCMinutes());
  startDateObj.setSeconds(startTimeObj?.getUTCSeconds());

  // Format the merged date and time as desired (e.g., ISO string)
  const mergedDateTime = startDateObj?.toISOString();

  return mergedDateTime;
};

const CLIENT_ID = GOOGLE_CLIENT_ID;
const API_KEY = GOOGLE_API_KEY;
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
const SCOPES = "https://www.googleapis.com/auth/calendar";

const CreateEvent = () => {
  const { id } = useParams();
  const getDraftData = JSON.parse(localStorage.getItem("event_draft"));
  const getDraftLocation = JSON.parse(
    localStorage.getItem("event_location_draft")
  );
  const [openDicPopup, setOpenDicPopup] = useState(false);
  const [eventStaps, setEventStaps] = useState({
    one: true,
    two: false,
    three: false,
    forth: false,
  });

  const [openService, setOpenService] = useState(false);
  const [isPackageEdit, setPackageEdit] = useState(false);
  const [packageIndex, setPackageIndex] = useState(null);
  const dispatch = useDispatch();
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const [loaderPre, setLoaderPre] = useState(false);
  const selector = useSelector((state) => state);
  const { myEvent } = useSelector((state) => state);
  const [isLoaction, setIsLocation] = useState(false);
  const [deletedId, setDeletedId] = useState([]);
  const [error, setError] = useState({});
  const [isBankAlart, setIsBankAlart] = useState(false);
  const [openBankInfo, setOpenBankInfo] = useState(false);
  const [deletedPackageId, setDeletedPackageId] = useState([]);
  const [deletedProviderId, setDeletedProviderId] = useState([]);
  const [deletedServiceId, setDeletedServiceId] = useState([]);
  const [isLinkPopup, setIsLinkPopup] = useState(false);
  const [isEventPopup, setIsEventPopup] = useState(false);
  const userDetails = useSelector((state) => state.user);
  const createselector = useSelector((state) => state);
  const [isPreviewOpen, setPreviewPopup] = useState(false);
  const momentZone = moment.tz.guess();

  const [loactionDetails, setLoactionDetails] = useState({
    address: "",
    latlong: "",
  });
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [firstTime, setFirstTime] = useState(true);
  const [meetingLink, setMeetingLink] = useState(null);

  const [details, setDetails] = useState({
    virtual: false,
    eventName: "",
    location: "",
    about: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    link: "",
    media: [],
    eventType: "public",
    needProvider: true,
    accommodation: true,
    service: [],
    provider: [],
    package: [],
    activity: [""],
    publish: false,
    event_id: null,
    isCreateDiscountCode: false,
    isEditDiscountCode: false,
    discountCodeData: null,
  });

  const [packageDetails, setPackageDetails] = useState({
    name: "",
    capacity: "",
    description: "",
    dackageDetails: "",
    value: "",
    media: [],
    id: "",
  });
  const VIRTUAL_EVENT_DETAILS = JSON.parse(localStorage.getItem("eventData"));
  const params = new URLSearchParams(location.search);
  const code = params.get("code");
  const is_duplicate_value = params.get("is_duplicate");
  let is_duplicate = /true/.test(is_duplicate_value);

  const [urlCode, setUrlCode] = useState(code ?? null);
  useEffect(() => {
    setUrlCode(code);
    if (code) {
      navigate(location?.pathname);
    }
  }, [code]);

  const handleStartTimeChange = (newStartTime) => {
    // console.log({newStartTime: newStartTime.toString()}, "start-newStartTime")
    setDetails((prevRegistration) => ({
      ...prevRegistration,
      startTime: newStartTime,
    }));
  };
  const handleEndTimeChange = (newStartTime) => {
    // console.log({newStartTime: newStartTime.toString()}, "end-newStartTime")
    setDetails((prevRegistration) => ({
      ...prevRegistration,
      endTime: newStartTime,
    }));
  };

  const getServiceJson = () => {
    const SERVICE = [];
    details?.service?.map((ele) => {
      if (ele?.id) {
        SERVICE.push({
          service_detail_id: ele?.id,
          description_id: ele.service.serviceId,
          service_time_detail: ele.hours.map((hour) => ({
            provider_service_date: new Date(hour.date).toISOString(),
            provider_start_time: new Date(hour.startTime).toISOString(),
            provider_end_time: new Date(hour.endTime).toISOString(),
          })),
          requirements: ele.service.Requirement,
        });
      } else {
        SERVICE.push({
          description_id: ele.service.serviceId,
          service_time_detail: ele.hours.map((hour) => ({
            provider_service_date: new Date(hour.date).toISOString(),
            provider_start_time: new Date(hour.startTime).toISOString(),
            provider_end_time: new Date(hour.endTime).toISOString(),
          })),
          requirements: ele.service.Requirement,
        });
      }
    });

    if (deletedServiceId?.length) {
      deletedServiceId?.map((ele) => {
        SERVICE?.push({
          service_detail_id: ele,
          is_deleted: true,
        });
      });
    }

    return SERVICE;
  };
  const getProviderJson = () => {
    const PROVIDER = [];
    details?.provider?.map((ele) => {
      if (ele?.id) {
        PROVIDER.push({
          provider_detail_id: ele?.id,
          provider_name: ele?.name,
          provider_email: ele?.email,
          provider_mobile_number: ele?.phone,
        });
      } else {
        PROVIDER.push({
          provider_name: ele?.name,
          provider_email: ele?.email,
          provider_mobile_number: ele?.phone,
        });
      }
    });

    if (deletedProviderId?.length) {
      deletedProviderId?.map((ele) => {
        PROVIDER?.push({
          provider_detail_id: ele,
          is_deleted: true,
        });
      });
    }

    return PROVIDER;
  };
  const getPackageJson = () => {
    const PACKAGE = [];
    const media = [];

    // console.log(details?.package, "details?.package")

    details?.package?.map((ele, i) => {
      if (ele?.id != null) {
        let OBJECT = {};
        // OBJECT.package_details_id = ele?.id
        OBJECT.package_details_id = ele?.id;
        OBJECT.package_name = ele?.name;
        OBJECT.capacity = ele?.capacity;
        OBJECT.description = ele?.description;
        OBJECT.package_detail = ele?.dackageDetails;
        OBJECT.value_per_person = ele?.value;
        OBJECT.package_media = ele.media?.map((img) => {
          // console.log(img, "heer")
          return {
            file_name: img.file_name.replaceAll(imgUrl, ""),
          };
        });
        PACKAGE.push(OBJECT);
      } else {
        let OBJECT = {};
        OBJECT.package_name = ele?.name;
        OBJECT.capacity = ele?.capacity;
        OBJECT.description = ele?.description;
        OBJECT.package_detail = ele?.dackageDetails;
        OBJECT.value_per_person = ele?.value;
        if (ele.media) {
          OBJECT.package_media = ele.media?.map((img) => {
            // console.log(img, "details?.package")
            if (img?.file_name) {
              return {
                file_name: img.file_name.replaceAll(imgUrl, ""),
              };
            } else {
              return {
                file_name: img,
              };
            }
          });
        }
        PACKAGE.push(OBJECT);
      }
    });

    if (deletedPackageId?.length) {
      deletedPackageId?.map((ele) => {
        PACKAGE?.push({
          package_details_id: ele,
          is_deleted: true,
        });
      });
    }

    return PACKAGE;
  };
  const EventMedia = () => {
    // Check if the data is in file format
    const isFileFormat = (data) =>
      data.hasOwnProperty("file_name") && data.hasOwnProperty("_id");

    const processedArray = details?.media?.map((element) => {
      if (isFileFormat(element)) {
        // console.log(true, "--sevice")
        return element?.file_name; // If already in file format, return as it is
      } else {
        // console.log(false, "--sevice")
        // If not in file format, convert and return
        return element;
      }
    });

    // console.log(processedArray, "--sevice")

    return processedArray;
  };

  const dublicateEventMedia = () => {
    const isFileFormat = (data) =>
      data.hasOwnProperty("file_name") && data.hasOwnProperty("_id");

    const dulplicateFile = details?.media?.filter((element) => {
      if (isFileFormat(element)) {
        // console.log(true, "--sevice")
        return element; // If already in file format, return as it is
      }
    });

    const updatedJson = dulplicateFile?.map((ele) => {
      return { file_name: ele?.file_name.replaceAll(imgUrl, "") };
    });

    return updatedJson;
  };

  // console.log(dublicateEventMedia(), "dublicateEventMedia")

  const duplicateAddedMedia = () => {
    const isFileFormat = (data) =>
      data.hasOwnProperty("file_name") && data.hasOwnProperty("_id");

    const processedArray = details?.media?.filter((element) => {
      if (!isFileFormat(element)) {
        // console.log(true, "--sevice")
        return element; // If already in file format, return as it is
      }
    });

    // console.log(processedArray, "--sevice")

    return processedArray;
  };

  function mergeDateTime(dateString, timeString) {
    const startDate = dateString;
    const startTime = timeString;

    const formatedDate = moment(startDate, "YYYY-MM-DD").format(
      "ddd MMM DD YYYY"
    );
    const splitTime = startTime.toString().split(" ");

    const sliceJoin = splitTime.slice(4, splitTime.length).join(" ");

    const margeDateTime = formatedDate + " " + sliceJoin;

    return margeDateTime;

    // console.log(margeDateTime, "margeDateTime")

    // console.log(, "startTime")
    // console.log(formatedDate, "formatedDate")

    // Parse the date and time strings
    // var dateParts = dateString.toString().split(" ");
    // var timeParts = timeString.toString().split(" ");
    //
    // // Get the date and time components
    // var date = dateParts.slice(0, 4).join(" ");
    // var time = timeParts.slice(4, 0).join(" ");
    //
    //
    // console.log(date, time)
    //
    //
    // // Concatenate the date and time strings
    // var mergedDateTimeString = date + " " + time;
    //
    // // Create a new Date object with the merged date and time
    // var mergedDateTime = new Date(mergedDateTimeString);
    //
    // // Format the merged date and time in local format
    // var formattedDateTime = mergedDateTime;
    //
    // return formattedDateTime;
  }

  const recurrentJson = (EVENTID) => {
    return EVENTID?.map((ele) => {
      return {
        event_id: ele,
        is_recurrent_event: false,
        recurrent_event_ids: [],
      };
    });
  };

  const handleGenerateCode = (data, preview, createTime) => {
    // if (handleCheckError()) {
    if (details?.discount_code_details && !is_duplicate) {
      // setLoader(true)
      const payload = {
        discount_percentage: details?.discountCodeData?.percentage,
        discount_type: details?.discountCodeData?.discount_type,
        service_ids: details?.discountCodeData?.service,
        event_ids: [details?._id],
        events_ids_details: recurrentJson([details?._id]),
        is_set_expire_date: details?.discountCodeData?.is_expire,
        expire_date: details?.discountCodeData?.date,
        limit_user_count: details?.discountCodeData?.limit,
        discount_code_id: details?.discount_code_details?._id,
        discount_code: details?.discountCodeData?.discout_code,
        note: details?.discountCodeData?.note,
      };
      dispatch(
        editDiscount(
          payload,
          () => {
            // getDiscountList()
            if (!createTime) {
              navigate(preview ? null : `/my_events`);
            }
          },
          () => {
            // setLoader(false)
          },
          () => {
            // setIsEdit(false)
          },
          () => {
            // handleClearData()
          },
          () => {
            // setOpenDiscountSucsess(true)
          },
          () => {
            // setIsLinkPopup(true)
          }
        )
      );
    } else {
      // setLoader(true)
      const payload = {
        discount_percentage: details?.discountCodeData?.percentage,
        discount_type: details?.discountCodeData?.discount_type,
        service_ids: details?.discountCodeData?.service,
        event_ids: [data?._id],
        events_ids_details: recurrentJson([data?._id]),
        is_set_expire_date: details?.discountCodeData?.is_expire,
        expire_date: details?.discountCodeData?.date,
        limit_user_count: details?.discountCodeData?.limit,
        is_created_from_event: true,
        discount_code: details?.discountCodeData?.discout_code,
        note: details?.discountCodeData?.note,
      };
      dispatch(
        generateDiscount(
          payload,
          () => {
            // getDiscountList()
            navigate(preview ? null : `/my_events`);
          },
          () => {
            // setLoader(false)
          },
          () => {
            // setIsEdit(false)
          },
          () => {
            // handleClearData()
          },
          () => {
            // setOpenDiscountSucsess(true)
          },
          () => {
            // setIsLinkPopup(true)
          }
        )
      );
    }

    // }
  };

  const handleCreateEvent = (e, preview) => {
    e.preventDefault();
    if (!userDetails?.bank_account_id && !preview) {
      setIsBankAlart(true);
    } else {
      if (validation()) {
        if (preview) {
          setLoaderPre(true);
        } else {
          setLoader(true);
        }
        let payload = {
          // event_id: selector?.event?.event?._id,
          event_name: details?.eventName,
          start_date: mergeDateTime(
            details?.startDate,
            new Date(details?.startTime)
          ),
          end_date: mergeDateTime(details?.endDate, new Date(details?.endTime)),
          // end_date: new Date(details?.endDate),
          start_time: mergeDateTime(
            details?.startDate,
            new Date(details?.startTime)
          ),
          end_time: mergeDateTime(details?.endDate, new Date(details?.endTime)),
          about: details?.about,
          activities: details?.activity.filter((item) => item !== ""),
          website_link: details?.link ?? "",
          event_type: details?.eventType,
          need_provider: details?.needProvider,
          is_accomodations_provided: details?.accommodation,
          // package_details: getPackageJson(),
          is_duplicate: false,

          is_virtual_event: details?.virtual,
          is_publish: preview == true ? false : true,
          current_timezone: momentZone,
        };
        if (is_duplicate == true) {
          payload.parent_event_id = id;
          payload.duplicate_event_file = dublicateEventMedia();
          payload.is_duplicate = true;
          if (duplicateAddedMedia()?.length > 0) {
            payload.event_media = duplicateAddedMedia();
          }
        } else {
          payload.event_media = EventMedia();
        }

        if (details?.event_id !== null) {
          payload.event_id = details?.event_id;
        }
        if (details?.service?.length && details?.needProvider) {
          payload.service_details = getServiceJson();
        }
        if (getProviderJson()?.length) {
          payload.add_provider = getProviderJson();
        }
        if (getPackageJson()?.length) {
          payload.package_details = getPackageJson();
        }
        if (meetingLink && details?.virtual) {
          payload.event_join_link = meetingLink?.hangoutLink;
        }
        if (!details?.virtual) {
          payload.location = {
            type: "Point",
            coordinates: [
              loactionDetails?.latlong?.lng,
              loactionDetails?.latlong?.lat,
            ],
          };
          payload.address = loactionDetails?.address;
        }

        dispatch(
          createEvent(
            payload,
            (data) => {
              if (
                details?.isCreateDiscountCode ||
                details?.isEditDiscountCode
              ) {
                handleGenerateCode(data, preview, true);
              } else {
                // navigate(preview ? null : `/my_events`);
              }
            },
            (a) => {
              setLoader(a);
            },
            () => {
              handleCliearData();
            },
            (a) => {
              setIsLinkPopup(a);
            },
            preview,
            () => {
              setPreviewPopup(true);
            },
            (a) => {
              setLoaderPre(a);
            }
          )
        );

        // setPreviewPopup(true)
      }
    }
  };

  const handleEditEvent = (e, preview) => {
    e.preventDefault();
    if (validation()) {
      if (preview) {
        setLoaderPre(true);
      } else {
        setLoader(true);
      }
      let payload = {
        event_id: selector?.event?.event?._id,
        event_name: details?.eventName,
        start_date: mergeDateTime(
          details?.startDate,
          new Date(details?.startTime)
        ),
        end_date: mergeDateTime(details?.endDate, new Date(details?.endTime)),
        // end_date: new Date(details?.endDate),
        start_time: mergeDateTime(
          details?.startDate,
          new Date(details?.startTime)
        ),
        end_time: mergeDateTime(details?.endDate, new Date(details?.endTime)),
        about: details?.about,
        activities: details?.activity.filter((item) => item !== ""),
        website_link: details?.link ?? "",
        event_type: details?.eventType,
        need_provider: details?.needProvider,
        // service_details: getServiceJson(),
        // add_provider: getProviderJson(),
        is_accomodations_provided: details?.accommodation,
        // package_details: getPackageJson(),
        event_media: EventMedia(),
        is_duplicate: false,
        // parent_event_id: "",
        // duplicate_event_file: "",
        is_virtual_event: details?.virtual,
        is_publish: preview ? false : true,
        current_timezone: momentZone,
        delete_package_image_ids : details?.delete_package_image_ids,
        // event_join_link: "",
      };
      if (deletedId?.length) {
        payload.delete_event_image_ids = deletedId;
      }
      if (details?.service?.length && details?.needProvider) {
        payload.service_details = getServiceJson();
      }
      if (getProviderJson()?.length) {
        payload.add_provider = getProviderJson();
      }
      if (getPackageJson()?.length) {
        payload.package_details = getPackageJson();
      }
      if (meetingLink && details?.virtual) {
        payload.event_join_link = meetingLink?.hangoutLink;
      }
      if (!details?.virtual) {
        payload.location = {
          type: "Point",
          coordinates: [
            loactionDetails?.latlong?.lng,
            loactionDetails?.latlong?.lat,
          ],
        };
        payload.address = loactionDetails?.address;
      }
      dispatch(
        editEvent(
          payload,
          (data) => {
            console.log(data);
            if (details?.isCreateDiscountCode || details?.isEditDiscountCode) {
              handleGenerateCode({ _id: details?._id }, preview, false);
            } else {
              navigate(preview ? null : `/my_events`);
            }
          },
          (a) => {
            setLoader(a);
          },
          () => {
            handleCliearData();
          },
          preview,
          () => {
            setPreviewPopup(true);
          },
          (a) => {
            setLoaderPre(a);
          }
        )
      );
    }
  };

  // for the clear data
  const handleCliearData = () => {
    setDetails({
      eventName: "",
      location: "",
      about: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      link: "",
      media: [],
      eventType: "public",
      needProvider: true,
      accommodation: true,
      service: [],
      provider: [],
      package: [],
      activity: [""],
      virtual: false,
      publish: false,
      event_id: null,
      isCreateDiscountCode: false,
      isEditDiscountCode: false,
      discountCodeData: null,
    });
    setDeletedId([]);
    setLoactionDetails({
      address: "",
      latlong: "",
    });
    setDeletedServiceId([]);
    setDeletedProviderId([]);
    setDeletedPackageId([]);
    setMeetingLink(null);
    setUrlCode(null);
    localStorage.removeItem("EVENT_CODE")
    localStorage.removeItem("eventData")
    localStorage.removeItem("meetingLink");
  };

  useEffect(() => {
    if(meetingLink){

      localStorage.setItem("meetingLink", JSON.stringify(meetingLink))
    }
  }, [meetingLink])
  useEffect(() => {
    const localmeetingLink = JSON.parse(localStorage.getItem("meetingLink"));
    if(localmeetingLink){
      setMeetingLink(localmeetingLink)
    }
  }, [])
  useEffect(() => {
    return () => {
      handleCliearData();
    };
  }, []);
  const getEventDetails = () => {
    dispatch(
      getEvent(
        id,
        () => {},
        () => {}
      )
    );
  };
  useEffect(() => {
    if (id) {
      getEventDetails();
    }
  }, [id]);
  const isUpdateData = () => {
    setDetails({
      eventName: selector?.event?.event?.event_name,
      publish: false,
      event_id: selector?.event?.event?._id,
      about: selector?.event?.event?.about,
      startDate: moment(
        LocalFormate(
          selector?.event?.event?.start_date,
          selector?.event?.event?.date_timezone
        )
      ).format("YYYY-MM-DD"),
      startTime: LocalFormate(
        selector?.event?.event?.start_time,
        selector?.event?.event?.date_timezone
      ),
      endDate: moment(
        LocalFormate(
          selector?.event?.event?.end_date,
          selector?.event?.event?.date_timezone
        )
      ).format("YYYY-MM-DD"),
      endTime: LocalFormate(
        selector?.event?.event?.end_time,
        selector?.event?.event?.date_timezone
      ),
      link: selector?.event?.event?.website_link ?? "",
      media: selector?.event?.event?.event_media,
      eventType: selector?.event?.event?.event_type,
      needProvider: selector?.event?.event?.need_provider,
      accommodation: selector?.event?.event?.is_accomodations_provided,
      virtual: selector?.event?.event?.is_virtual_event,
      service: selector?.event?.event?.service_details?.map((ele, i) => {
        return {
          id: ele?._id,
          service: {
            serviceName: ele?.description_id?.description_name,
            serviceId: ele?.description_id?._id,
            Requirement: ele?.requirements,
          },
          hours: ele?.service_time_detail?.map((time) => {
            return {
              date: moment(LocalFormate(time?.provider_service_date)).format(
                "YYYY-MM-D"
              ),
              startTime: LocalFormate(time?.provider_start_time),
              endTime: LocalFormate(time?.provider_end_time),
            };
          }),
        };
      }),
      provider: selector?.event?.event?.provider_details?.map((ele) => {
        return {
          id: ele?._id,
          name: ele?.provider_name,
          email: ele?.provider_email,
          phone: ele?.provider_mobile_number,
        };
      }),

      package: selector?.event?.event?.package_details?.map((ele) => {
        return {
          id: ele?._id,
          name: ele?.package_name,
          capacity: ele?.capacity,
          description: ele?.description,
          dackageDetails: ele?.package_detail,
          value: ele?.value_per_person,
          media: ele?.package_media,
        };
      }),
      activity: selector?.event?.event?.activities,
      discount_code_details: selector?.event?.event?.discount_code_details,
      _id: selector?.event?.event?._id,
    });

    setLoactionDetails({
      address: selector?.event?.event?.address,
      latlong: {
        lat: selector?.event?.event?.location?.coordinates[1],
        lng: selector?.event?.event?.location?.coordinates[0],
      },
    });
    const meetingJson = {
      hangoutLink: selector?.event?.event?.event_join_link,
    };
    if (selector?.event?.event?.is_virtual_event) {
      setMeetingLink(meetingJson);
    }
  };
  const [isSignedIn, setIsSignedIn] = useState(false);

  // console.log(selector?.event?.event?.package_details, "selector?.event?.event?.package_details")
  const auth2 = gapi?.auth2?.getAuthInstance();

  useEffect(() => {
    function start() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(() => {
          gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
          setIsSignedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
        });
    }

    gapi.load("client:auth2", start);
  }, []);

  const validation = () => {
    let valid = true;
    let ERROR = {};
    // debugger;
    // setLoader(true);
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!details?.eventName) {
      // setLoader(false)
      valid = false;
      ERROR.name = "Please enter name of your event.";
    }
    if (details?.virtual) {
      if (!meetingLink) {
        valid = false;
        ERROR.location = "Please generate meeting link";
      }
    } else {
      if (!loactionDetails?.address) {
        // setLoader(false)
        valid = false;
        ERROR.location = "Please enter location";
      }
    }
    if (!details?.about) {
      // setLoader(false)
      valid = false;
      ERROR.about = "Please enter about";
    }
    if (!details?.startDate) {
      // setLoader(false)
      valid = false;
      ERROR.startDate = "Please select start date";
    }
    if (!details?.startTime) {
      // setLoader(false)
      valid = false;
      ERROR.startTime = "Please select start time";
    }
    if (!details?.endDate) {
      // setLoader(false)
      valid = false;
      ERROR.endDate = "Please select end date";
    }
    if (!details?.endTime) {
      // setLoader(false)
      valid = false;
      ERROR.endTime = "Please select end time.";
    }
    if (!details?.media?.length) {
      // setLoader(false)
      valid = false;
      ERROR.media = "Please add at least 1 media.";
    }
    if (!details?.activity?.length) {
      // setLoader(false)
      valid = false;
      ERROR.activity = "Please enter at least 1 activity.";
    }
    if (details?.activity?.length) {
      // setLoader(false)
      details?.activity?.map((ele, i) => {
        if (!ele?.trim() && i == 0) {
          // setLoader(false)
          valid = false;
          ERROR.activity = "Please enter at least 1 activity.";
        }
      });
    }
    if (!details?.service?.length && details?.needProvider) {
      // setLoader(false)
      valid = false;
      ERROR.service = "Please enter at least 1 service.";
    }
    if (!details?.package?.length || details?.package?.length < 1) {
      // setLoader(false)
      valid = false;
      ERROR.package = "Please enter at least 1 package.";
    }

    if (details?.provider?.length) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        provider: prevDetails.provider.map((ele) => {
          let updatedProvider = { ...ele }; // Make a copy of the original object
          if (!ele?.name?.length) {
            valid = false;
            // setLoader(false);
            updatedProvider.nameError = "Please enter name";
          } else {
            // valid = true;
            updatedProvider.nameError = ""; // Clear existing nameError if name is provided
          }
          if (!ele?.email?.length) {
            valid = false;
            // setLoader(false);
            updatedProvider.emailError = "Please enter email address";
          } else if (!emailRegex.test(ele?.email)) {
            valid = false;
            // setLoader(false);
            updatedProvider.emailError = "Invalid email address";
          } else {
            // valid = true;
            updatedProvider.emailError = ""; // Clear existing emailError if email is provided and valid
          }
          if (!ele?.phone?.length) {
            valid = false;
            // setLoader(false);
            updatedProvider.phoneError = "Please enter phone number";
          } else {
            // setLoader(false);
            // valid = true;
            updatedProvider.phoneError = ""; // Clear existing phoneError if phone number is provided
          }
          return updatedProvider; // Return the updated provider object
        }),
      }));
    }

    setError(ERROR);
    return valid;
  };
  useEffect(() => {
    if (urlCode) {
      setIsVirtualData();
    } else if (id) {
      isUpdateData();
    } else {
      // handleCliearData()
    }
  }, [selector?.event?.event, urlCode]);
  const getCurrentDate = (status) => {
    const today = status == "start" ? new Date() : new Date(details?.startDate);
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  const selectTimeValidation = () => {
    if (details?.startDate == details?.endDate) {
      return dayjs(details.startTime);
    } else {
      return null;
    }
  };
  const virtualEventValidation = () => {
    let valid = true;
    let ERROR = {};
    // setLoader(true);
    if (!details?.eventName) {
      // setLoader(false)
      valid = false;
      ERROR.name = "Please enter name of your event.";
    }
    if (!details?.startDate) {
      // setLoader(false)
      valid = false;
      ERROR.startDate = "Please select start date";
    }
    if (!details?.startTime) {
      // setLoader(false)
      valid = false;
      ERROR.startTime = "Please select start time";
    }
    if (!details?.endDate) {
      // setLoader(false)
      valid = false;
      ERROR.endDate = "Please select end date";
    }
    if (!details?.endTime) {
      // setLoader(false)
      valid = false;
      ERROR.endTime = "Please select end time.";
    }

    setError(ERROR);
    return valid;
  };
  const handleCreateEventLink = async () => {
    if (virtualEventValidation()) {
      const EVENT_DATA = {
        data: details,
        id_edit: id ? true : false,
        url: location?.pathname,
        deletedServiceId: deletedServiceId,
        deletedProviderId: deletedServiceId,
        deletedPackageId: deletedPackageId,
        is_duplicate: is_duplicate ? true : false,
      };
      localStorage.setItem("eventData", JSON.stringify(EVENT_DATA));

      const uuid = uuidv4();
      const passData = {
        summary: details?.eventName,
        description: details?.eventName,
        start: {
          dateTime: getDateTime(details?.startDate, details?.startTime),
        },
        end: {
          dateTime: getDateTime(details?.endDate, details?.endTime),
        },
        conferenceData: {
          createRequest: {
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
            requestId: uuid,
          },
        },
      };

      if (isSignedIn) {
        // const request = gapi.client.calendar.events.insert({
        //   calendarId: "primary",
        //   conferenceDataVersion: 1,
        //   resource: passData,
        // });
        // request.execute(function (eventData) {
        //   console.log(eventData, "eventData");
        // });

        gapi.client.calendar.events
          .insert({
            calendarId: "primary",
            conferenceDataVersion: 1,
            resource: passData,
          })
          .then(
            function (response) {
              const event = response.result;
              console.log(event, "event");

              if (event.conferenceData) {
                const meetLink = event.conferenceData.entryPoints.find(
                  (point) => point.entryPointType === "video"
                ).uri;
                console.log("Google Meet Link:", meetLink);
                setMeetingLink({ hangoutLink: meetLink });
              } else {
                console.log("No conference data found.");
              }
            },
            function (err) {
              console.error("Execute error", err);
            }
          );
      } else {
        await auth2?.signIn()?.then(function () {});

        if (auth2?.isSignedIn?.get()) {
          gapi.client.calendar.events
            .insert({
              calendarId: "primary",
              conferenceDataVersion: 1,
              resource: passData,
            })
            .then(
              function (response) {
                const event = response.result;
                console.log(event, "event");

                if (event.conferenceData) {
                  const meetLink = event.conferenceData.entryPoints.find(
                    (point) => point.entryPointType === "video"
                  ).uri;
                  console.log("Google Meet Link:", meetLink);
                  setMeetingLink({ hangoutLink: meetLink });
                } else {
                  console.log("No conference data found.");
                }
              },
              function (err) {
                console.error("Execute error", err);
              }
            );
        }
      }

      // window.location.replace(googleUrl());
    } else {
      // console.log("validation", false);
    }
  };

  const setIsVirtualData = () => {
    setDetails({
      publish: false,
      event_id: null,
      virtual: VIRTUAL_EVENT_DETAILS?.data?.virtual,
      eventName: VIRTUAL_EVENT_DETAILS?.data?.eventName,
      location: VIRTUAL_EVENT_DETAILS?.data?.location,
      about: VIRTUAL_EVENT_DETAILS?.data?.about,
      startDate: VIRTUAL_EVENT_DETAILS?.data?.startDate,
      startTime: VIRTUAL_EVENT_DETAILS?.data?.startTime,
      endDate: VIRTUAL_EVENT_DETAILS?.data?.endDate,
      endTime: VIRTUAL_EVENT_DETAILS?.data?.endTime,
      link: VIRTUAL_EVENT_DETAILS?.data?.link,
      media: VIRTUAL_EVENT_DETAILS?.data?.media,
      eventType: VIRTUAL_EVENT_DETAILS?.data?.eventType,
      needProvider: VIRTUAL_EVENT_DETAILS?.data?.needProvider,
      accommodation: VIRTUAL_EVENT_DETAILS?.data?.accommodation,
      service: VIRTUAL_EVENT_DETAILS?.data?.service,
      provider: VIRTUAL_EVENT_DETAILS?.data?.provider,
      package: VIRTUAL_EVENT_DETAILS?.data?.package,
      activity: VIRTUAL_EVENT_DETAILS?.data?.activity,
    });
    setDeletedPackageId(VIRTUAL_EVENT_DETAILS?.deletedPackageId ?? []);
    setDeletedProviderId(VIRTUAL_EVENT_DETAILS?.deletedProviderId ?? []);
    setDeletedServiceId(VIRTUAL_EVENT_DETAILS?.deletedServiceId ?? []);
    if (urlCode && firstTime) {
      setFirstTime(false);

      const passData = {
        summary: VIRTUAL_EVENT_DETAILS?.data?.eventName,
        start: {
          dateTime: getDateTime(
            VIRTUAL_EVENT_DETAILS?.data?.startDate,
            VIRTUAL_EVENT_DETAILS?.data?.startTime
          ),
        },
        end: {
          dateTime: getDateTime(
            VIRTUAL_EVENT_DETAILS?.data?.endDate,
            VIRTUAL_EVENT_DETAILS?.data?.endTime
          ),
        },
        conferenceData: {
          createRequest: {
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
            requestId: "ABCD",
          },
        },
      };

      // GoogleMeet(
      //   decodeURIComponent(urlCode),
      //   (a) => {
      //     setMeetingLink(a);
      //   },
      //   VIRTUAL_EVENT_DETAILS?.data
      // );
    }
  };

  const firstNext = () => {
    let valid = true;
    let ERROR = {};
    // debugger;
    // setLoader(true);
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!details?.eventName) {
      // setLoader(false)
      valid = false;
      ERROR.name = "Please enter name of your event.";
    }
    if (details?.virtual) {
      if (!meetingLink) {
        valid = false;
        ERROR.location = "Please generate meeting link";
      }
    } else {
      if (!loactionDetails?.address) {
        // setLoader(false)
        valid = false;
        ERROR.location = "Please enter location";
      }
    }
    if (!details?.about) {
      // setLoader(false)
      valid = false;
      ERROR.about = "Please enter about";
    }
    if (!details?.startDate) {
      // setLoader(false)
      valid = false;
      ERROR.startDate = "Please select start date";
    }
    if (!details?.startTime) {
      // setLoader(false)
      valid = false;
      ERROR.startTime = "Please select start time";
    }
    if (!details?.endDate) {
      // setLoader(false)
      valid = false;
      ERROR.endDate = "Please select end date";
    }
    if (!details?.endTime) {
      // setLoader(false)
      valid = false;
      ERROR.endTime = "Please select end time.";
    } else if (checkTime()) {
      valid = false;
      ERROR.endTime = "Please enter end time greater then start time.";
    }
    if (!details?.media?.length) {
      // setLoader(false)
      valid = false;
      ERROR.media = "Please add at least 1 media.";
    }
    if (details?.media?.length > 7) {
      valid = false;
      ERROR.media = "Please add Maximum 7 media.";
    }
    if (!details?.activity?.length) {
      // setLoader(false)
      valid = false;
      ERROR.activity = "Please enter at least 1 activity.";
    }
    if (details?.activity?.length) {
      // setLoader(false)
      details?.activity?.map((ele, i) => {
        if (!ele?.trim() && i == 0) {
          // setLoader(false)
          valid = false;
          ERROR.activity = "Please enter at least 1 activity.";
        }
      });
    }
    setError(ERROR);

    if (valid && details?.needProvider == true) {
      setEventStaps((pre) => ({
        ...pre,
        two: true,
        one: false,
        three: false,
        forth: false,
      }));
    } else if (valid) {
      setEventStaps((pre) => ({
        ...pre,
        two: false,
        one: false,
        three: true,
        forth: false,
      }));
    }
  };

  const checkTime = () => {
    if (details?.startDate == details?.endDate) {
      if (
        details?.startTime.toString() == details?.endTime.toString() ||
        details?.startTime > details?.endTime
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const secoundNext = () => {
    let valid = true;
    let ERROR = {};
    if (!details?.service?.length && details?.needProvider) {
      // setLoader(false)
      valid = false;
      ERROR.service = "Please enter at least 1 service.";
    }

    // if (details?.service?.length) {
    const newServiceDetails = details.service.map((ele) => {
      let updatedProvider = { ...ele }; // Make a copy of the original object
      if (!ele?.service?.serviceName?.length) {
        valid = false;
        // setLoader(false);
        updatedProvider.serviceError = "Please select service";
      } else {
        // valid = true;
        updatedProvider.serviceError = "";
      }
      if (!ele?.hours?.length) {
        valid = false;
        // setLoader(false);
        updatedProvider.hoursError = "Please add service hours";
      } else {
        // valid = true;
        updatedProvider.hoursError = "";
      }

      return updatedProvider; // Return the updated provider object
    });

    // console.log(newServiceDetails, "newServiceDetails")

    setDetails({ ...details, service: newServiceDetails });

    // setDetails(prevDetails => ({
    //     ...prevDetails,
    //     service: prevDetails.service.map(ele => {
    //         let updatedProvider = {...ele}; // Make a copy of the original object
    //         if (!ele?.service?.serviceName?.length) {
    //             valid = false;
    //             // setLoader(false);
    //             updatedProvider.serviceError = 'Please select service';
    //         } else {
    //             valid = true;
    //             updatedProvider.serviceError = '';
    //         }
    //         if (!ele?.hours?.length) {
    //             valid = false;
    //             // setLoader(false);
    //             updatedProvider.hoursError = 'Please add service hours';
    //         } else {
    //             valid = true;
    //             updatedProvider.hoursError = '';
    //         }
    //
    //         return updatedProvider; // Return the updated provider object
    //     })
    // }));
    // }
    var valueArr = details?.service.map(function (item) {
      return item?.service?.serviceName;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });

    if (isDuplicate) {
      valid = false;
      toast.error("You can't select the same service again.");
    }

    setError(ERROR);
    if (valid) {
      setEventStaps((pre) => ({
        ...pre,
        two: false,
        one: false,
        three: true,
        forth: false,
      }));
    }
  };

  const thirdNext = () => {
    let valid = true;
    // let ERROR = {}
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const newProviderDetails = details?.provider?.map((ele) => {
      let updatedProvider = { ...ele }; // Make a copy of the original object
      if (ele?.name == "") {
        valid = false;
        updatedProvider.nameError = "Please enter name";
      } else {
        updatedProvider.nameError = "";
      }
      if (ele?.email == "") {
        valid = false;
        updatedProvider.emailError = "Please enter email address";
      } else if (!emailRegex.test(ele?.email)) {
        valid = false;
        updatedProvider.emailError = "Invalid email address";
      } else if (ele?.email == userAuth?.email_address) {
        valid = false;
        updatedProvider.emailError =
          "you can not mention yourself in the provider section";
      } else {
        updatedProvider.emailError = "";
      }
      if (ele?.phone == "") {
        valid = false;
        updatedProvider.phoneError = "Please enter phone number";
      } else {
        updatedProvider.phoneError = "";
      }
      if (ele?.phone?.length > 10 || ele?.phone?.length < 10) {
        valid = false;
        updatedProvider.phoneError = "Please enter valid number";
      } else {
        updatedProvider.phoneError = "";
      }
      return updatedProvider;
    });

    setDetails({ ...details, provider: newProviderDetails });

    var valueArr = details?.provider.map(function (item) {
      return item.email;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });

    if (isDuplicate) {
      valid = false;
      toast.error("You can't add the same provider twice.");
    }
    // setError(ERROR);
    if (valid) {
      setEventStaps((pre) => ({
        ...pre,
        two: false,
        one: false,
        three: false,
        forth: true,
      }));
    }
  };

  const handleCancel = () => {
    if (eventStaps?.two) {
      setEventStaps((pre) => ({
        ...pre,
        two: false,
        one: true,
        three: false,
        forth: false,
      }));
    } else if (eventStaps?.three) {
      if (details?.needProvider) {
        setEventStaps((pre) => ({
          ...pre,
          two: true,
          one: false,
          three: false,
          forth: false,
        }));
      } else {
        setEventStaps((pre) => ({
          ...pre,
          two: false,
          one: true,
          three: false,
          forth: false,
        }));
      }
    } else if (eventStaps?.forth) {
      setEventStaps((pre) => ({
        ...pre,
        two: false,
        one: false,
        three: true,
        forth: false,
      }));
    }
  };

  useEffect(() => {
    if (!id && is_duplicate !== true) {
      const convertAndStoreImages = async () => {
        const base64Images = await Promise.all(
          (details?.media || []).map(fileToBase64)
        );

        // const base64PackageImages = await Promise.all(
        //     (details?.package || []).map(async (ele) => {
        //         const media = await Promise.all(
        //             (ele.media || []).map(fileToBase64)
        //         );
        //         return { ...ele, media };
        //     })
        // );

        localStorage.setItem(
          "event_draft",
          JSON.stringify({
            ...details,
            media: base64Images,
            // package: base64PackageImages
          })
        );
      };

      convertAndStoreImages();
      localStorage.setItem(
        "event_location_draft",
        JSON.stringify(loactionDetails)
      );
    }
  }, [details, loactionDetails]);

  const drafPackageDetails = (data) => {
    return data?.map((ele) => {
      return {
        ...ele,
        media: ele?.media?.map((img) => {
          return base64ToFile(img);
        }),
      };
    });
  };

  useEffect(() => {
    if (!id && is_duplicate !== true) {
      if (getDraftData) {
        setDetails({
          ...getDraftData,
          // package:drafPackageDetails(getDraftData.package),
          media: getDraftData?.media?.map((ref) => {
            return base64ToFile(ref);
          }),
        });
      }

      setLoactionDetails(getDraftLocation);
    }
  }, [id, is_duplicate]);

  const base64ToFile = (base64String) => {
    const filename = "example.jpg";
    const arr = base64String.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const fileToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(image);
    });
  };

  return (
    <>
      {!isPreviewOpen ? (
        <div>
          <section className="main-page">
            <Header select={"My Events"} />
            <div className="main-inner">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="container">
                  {eventStaps?.one ? (
                    <p className="t-30-bold">
                      {details?._id ? "Edit" : "Create"} Event
                    </p>
                  ) : null}
                  <Grid container spacing={2}>
                    {eventStaps?.one ? (
                      <>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <div
                                className="inner-gred box_shadow"
                                style={{ padding: "9px 20px" }}
                              >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                  <div>
                                    <p className="t-18-bold text-gray m-0">
                                      Is this a virtual event?
                                    </p>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <div
                                      className="d-flex align-items-center c-pointer"
                                      onClick={() =>
                                        setDetails((pre) => ({
                                          ...pre,
                                          virtual: true,
                                        }))
                                      }
                                    >
                                      <img
                                        src={details?.virtual ? checked : check}
                                        alt=""
                                      />
                                      <p className="m-0">Yes</p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center c-pointer"
                                      onClick={() =>
                                        setDetails((pre) => ({
                                          ...pre,
                                          virtual: false,
                                        }))
                                      }
                                    >
                                      <img
                                        src={
                                          !details?.virtual ? checked : check
                                        }
                                        alt=""
                                      />
                                      <p className="m-0">No</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="inner-gred box_shadow">
                                <div>
                                  <p className="t-14-500 text-black m-0">
                                    Name Your Event
                                  </p>
                                  <div className="group-input mt-2 p-15 clearfix">
                                    <input
                                      required
                                      style={{ color: "#000000" }}
                                      type="text"
                                      onChange={(e) => {
                                        if (e?.target?.value?.length <= 35) {
                                          setDetails((pre) => ({
                                            ...pre,
                                            eventName: e.target.value,
                                          }));
                                        }
                                      }}
                                      placeholder={"Enter Name For Your Event"}
                                      value={details?.eventName}
                                    />
                                  </div>
                                  <p className="t-13-500 text-danger">
                                    {error?.name}
                                  </p>
                                </div>
                                {!details?.virtual ? (
                                  <div className="mt-3">
                                    <p className="t-14-500 text-black m-0">
                                      Location
                                    </p>
                                    <div className="group-input mt-2 p-15 clearfix position-relative">
                                      <input
                                        required
                                        onClick={() => setIsLocation(true)}
                                        readOnly
                                        type="text"
                                        onChange={(e) =>
                                          setDetails((pre) => ({
                                            ...pre,
                                            location: e.target.value,
                                          }))
                                        }
                                        style={{ color: "#000000" }}
                                        placeholder={"Enter Location"}
                                        value={loactionDetails?.address}
                                      />
                                      {loactionDetails?.address ? (
                                        <IconButton
                                          onClick={() =>
                                            setLoactionDetails((pre) => ({
                                              ...pre,
                                              address: "",
                                            }))
                                          }
                                          className="position-absolute"
                                          style={{
                                            right: "0px",
                                          }}
                                        >
                                          <img src={closeIcon} alt="" />
                                        </IconButton>
                                      ) : null}
                                    </div>
                                    <p className="t-13-500 text-danger">
                                      {error?.location}
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {meetingLink ? (
                                      <div>
                                        <p className="t-14-500 text-black m-0">
                                          Event Link
                                        </p>
                                        <div className="group-input mt-2 p-15 clearfix">
                                          <input
                                            required
                                            readOnly
                                            type="text"
                                            value={meetingLink?.hangoutLink}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="mt-3">
                                        <p className="t-14-500 text-black m-0">
                                          Event Link
                                        </p>
                                        <div className="d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn_admina"
                                            style={{ maxWidth: "400px" }}
                                            onClick={() =>
                                              handleCreateEventLink()
                                            }
                                          >
                                            Generate Link
                                          </button>
                                        </div>
                                        <p className="t-13-500 text-danger">
                                          {error?.location}
                                        </p>
                                      </div>
                                    )}
                                  </>
                                )}
                                <div className="mt-3">
                                  <p className="t-14-500 text-black m-0">
                                    About
                                  </p>
                                  <div className="group-input mt-2 p-15 clearfix">
                                    <textarea
                                      required
                                      onChange={(e) => {
                                        if (e?.target?.value?.length <= 600) {
                                          setDetails((pre) => ({
                                            ...pre,
                                            about: e.target.value,
                                          }));
                                        }
                                      }}
                                      style={{
                                        color: "#000000",
                                        resize: "none",
                                      }}
                                      value={details?.about}
                                      rows="6"
                                      placeholder={
                                        "Describe your intentions and give more details about purpose of the retreat / methods, etc."
                                      }
                                    />
                                  </div>
                                  <p className="t-13-500 text-danger">
                                    {error?.about}
                                  </p>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <div className="inner-gred box_shadow">
                                <Grid container>
                                  <Grid item xs={6} className="pe-2">
                                    <div>
                                      <p className="t-14-500 text-black m-0">
                                        Start Date
                                      </p>
                                      <div className="group-input mt-2 p-15 clearfix">
                                        <input
                                          required
                                          type="date"
                                          onChange={(e) => {
                                            // console.log({startDate: e.target.value})
                                            setDetails((pre) => ({
                                              ...pre,
                                              startDate: e.target.value,
                                            }));
                                          }}
                                          style={{
                                            color: "#000000",
                                            height: "45px",
                                          }}
                                          value={details?.startDate}
                                          placeholder="MM/DD/YYYY"
                                          min={getCurrentDate("start")}
                                        />
                                      </div>
                                      <p className="t-13-500 text-danger">
                                        {error?.startDate}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} className="ps-2">
                                    <div>
                                      <p className="t-14-500 text-black m-0">
                                        Start Time
                                      </p>
                                      <div className="group-input mt-2 p-15 clearfix timePicker-Event ">
                                        {/*<input*/}
                                        {/*    type="text"*/}
                                        {/*    onChange={(e) => setDetails((pre) => ({...pre, startTime: e.target.value}))}*/}
                                        {/*    value={details?.startTime}*/}
                                        {/*    placeholder="Select time"*/}
                                        {/*/>*/}
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                          sx={{ width: "100%" }}
                                        >
                                          <MobileTimePicker
                                            className="w-100"
                                            defaultValue={dayjs(new Date())}
                                            // defaultValue={dayjs(registration.startTime)}
                                            value={dayjs(details?.startTime)}
                                            onChange={handleStartTimeChange}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                InputLabelProps={{
                                                  ...params.inputProps,
                                                  placeholder: "Start Time",
                                                }}
                                                sx={{ padding: "0" }}
                                              />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </div>
                                      <p className="t-13-500 text-danger">
                                        {error?.startTime}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} className="pe-2">
                                    <div>
                                      <p className="t-14-500 text-black m-0">
                                        End Date
                                      </p>
                                      <div className="group-input mt-2 p-15 clearfix">
                                        <input
                                          required
                                          type="date"
                                          onChange={(e) => {
                                            // console.log({endDate: e.target.value})
                                            setDetails((pre) => ({
                                              ...pre,
                                              endDate: e.target.value,
                                            }));
                                          }}
                                          style={{
                                            color: "#000000",
                                            height: "45px",
                                          }}
                                          value={details?.endDate}
                                          // disabled={details?.startDate ? false : true}
                                          placeholder="MM/DD/YYYY"
                                          min={getCurrentDate("end")}
                                        />
                                      </div>
                                      <p className="t-13-500 text-danger">
                                        {error?.endDate}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} className="ps-2">
                                    <div>
                                      <p className="t-14-500 text-black m-0">
                                        End Time
                                      </p>
                                      <div className="group-input mt-2 p-15 clearfix timePicker-Event">
                                        {/*<input*/}
                                        {/*    type="text"*/}
                                        {/*    onChange={(e) => setDetails((pre) => ({...pre, endTime: e.target.value}))}*/}
                                        {/*    value={details?.endTime}*/}
                                        {/*    placeholder="Select time"*/}
                                        {/*/>*/}
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <MobileTimePicker
                                            className="w-100"
                                            // minTime={selectTimeValidation()}
                                            value={dayjs(details?.endTime)}
                                            onChange={handleEndTimeChange}
                                            // defaultValue={registration.endTime}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                InputLabelProps={{
                                                  ...params.inputProps,
                                                  placeholder: "End Time",
                                                }}
                                                sx={{ padding: "0" }}
                                              />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </div>
                                      <p className="t-13-500 text-danger">
                                        {error?.endTime}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <div>
                                      <p className="t-14-500 text-black m-0">
                                        Link
                                      </p>
                                      <div className="group-input mt-2 p-15 clearfix">
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setDetails((pre) => ({
                                              ...pre,
                                              link: e.target.value,
                                            }))
                                          }
                                          style={{ color: "#000000" }}
                                          value={details?.link}
                                          placeholder="Enter link (Optional)"
                                        />
                                      </div>
                                      {/*<p className="t-13-500 text-danger">{error?.startTime}</p>*/}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              {/*<AddMedia details={details}*/}
                              {/*          setDetails={setDetails}*/}
                              {/*          media={selector?.event?.event?.event_media}*/}
                              {/*          isEventEdit={true} setDeletedId={setDeletedId}/>*/}
                              <AddEventMedia
                                details={details}
                                setDetails={setDetails}
                                setDeletedId={setDeletedId}
                              />
                              <p className="t-13-500 text-danger">
                                {error?.media}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/*.........Select Event Type .........*/}

                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <div className="inner-gred box_shadow">
                                <p className="t-17-bold">Select Event Type</p>
                                <Box className="">
                                  <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6} md={12} lg={6}>
                                      <button
                                        type="button"
                                        className={`${
                                          details?.eventType == "public"
                                            ? "btn_admina checkColorFull"
                                            : "btn_White checkBtnwhite"
                                        } bnt-lignHight `}
                                        style={{
                                          backgroundSize: "100%",
                                          lineHeight: "40px",
                                          marginRight: "10px",
                                        }}
                                        onClick={() =>
                                          setDetails((pre) => ({
                                            ...pre,
                                            eventType: "public",
                                          }))
                                        }
                                      >
                                        <span className="d-flex align-items-center justify-content-center">
                                          <img
                                            src={
                                              details?.eventType == "public"
                                                ? radioCheckedIcon
                                                : radioIcon
                                            }
                                            alt=""
                                            style={{
                                              width: "25px",
                                              marginRight: "10px",
                                            }}
                                          />
                                          <p className="t-17-bold m-0">
                                            Public Event
                                          </p>
                                        </span>
                                      </button>
                                    </Grid>

                                    {/*<div>*/}
                                    <Grid item xs={12} sm={6} md={12} lg={6}>
                                      <button
                                        type="button"
                                        className={`${
                                          details?.eventType == "private"
                                            ? "btn_admina checkColorFull"
                                            : "btn_White checkBtnwhite"
                                        } bnt-lignHight `}
                                        style={{
                                          backgroundSize: "100%",
                                          lineHeight: "40px",
                                          marginRight: "10px",
                                        }}
                                        onClick={() =>
                                          setDetails((pre) => ({
                                            ...pre,
                                            eventType: "private",
                                          }))
                                        }
                                      >
                                        <span className="d-flex align-items-center justify-content-center">
                                          <img
                                            src={
                                              details?.eventType == "private"
                                                ? radioCheckedIcon
                                                : radioIcon
                                            }
                                            alt=""
                                            style={{
                                              width: "25px",
                                              marginRight: "10px",
                                            }}
                                          />
                                          <p className="t-17-bold m-0">
                                            Private Event
                                          </p>
                                        </span>
                                      </button>
                                      <p className="text-center t-12-bold">
                                        (only people with a link will be able to
                                        access the event)
                                      </p>
                                    </Grid>
                                    {/*<p>(only people with a link will be able to*/}
                                    {/*    access the event)</p>*/}
                                    {/*</div>*/}
                                  </Grid>
                                </Box>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="inner-gred box_shadow">
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                  <div>
                                    <p className="t-18-bold text-gray m-0">
                                      Do you need providers for your event?
                                    </p>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <div
                                      className="d-flex align-items-center c-pointer"
                                      onClick={() =>
                                        setDetails((pre) => ({
                                          ...pre,
                                          needProvider: true,
                                        }))
                                      }
                                    >
                                      <img
                                        src={
                                          details?.needProvider
                                            ? checked
                                            : check
                                        }
                                        alt=""
                                      />
                                      <p className="m-0">Yes</p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center c-pointer"
                                      onClick={() =>
                                        setDetails((pre) => ({
                                          ...pre,
                                          needProvider: false,
                                        }))
                                      }
                                    >
                                      <img
                                        src={
                                          !details?.needProvider
                                            ? checked
                                            : check
                                        }
                                        alt=""
                                      />
                                      <p className="m-0">No</p>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                  <div>
                                    <p className="t-18-bold text-gray m-0">
                                      Are accommodation provided?
                                    </p>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <div
                                      className="d-flex align-items-center c-pointer"
                                      onClick={() =>
                                        setDetails((pre) => ({
                                          ...pre,
                                          accommodation: true,
                                        }))
                                      }
                                    >
                                      <img
                                        src={
                                          details?.accommodation
                                            ? checked
                                            : check
                                        }
                                        alt=""
                                      />
                                      <p className="m-0">Yes</p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center c-pointer"
                                      onClick={() =>
                                        setDetails((pre) => ({
                                          ...pre,
                                          accommodation: false,
                                        }))
                                      }
                                    >
                                      <img
                                        src={
                                          !details?.accommodation
                                            ? checked
                                            : check
                                        }
                                        alt=""
                                      />
                                      <p className="m-0">No</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <AddActivity
                            details={details}
                            setDetails={setDetails}
                          />
                          <p className="t-13-500 text-danger">
                            {error?.activity}
                          </p>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">
                            <button
                              type="button"
                              className="btn_White ms-lg-auto"
                              onClick={() => {
                                handleCliearData();
                                navigate(-1);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn_admina"
                              onClick={() => firstNext()}
                              // onClick={() => }
                              // onClick={() => {
                              //     if (inputData?.provider_introduction.trim()) {
                              //
                              //
                              //         dispatch(addMyPageDetails({...inputData, deleteId: deleteId},));
                              //         handleSave()
                              //         setLinkError("")
                              //     } else {
                              //         toast.error("Please Add Provider Introductions.")
                              //     }
                              // }}
                            >
                              Next
                            </button>
                          </Box>
                        </Grid>
                      </>
                    ) : eventStaps?.two ? (
                      <>
                        {details?.needProvider ? (
                          <Grid item xs={12}>
                            <div className="d-block d-lg-flex align-items-center flex-wrap">
                              <p className="t-30-bold">
                                Do you need providers for your event?
                              </p>
                              <Box
                                className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3"
                                style={{ flexGrow: "1", marginLeft: "auto" }}
                              >
                                <button
                                  type="button"
                                  className="btn_admina"
                                  style={{
                                    marginLeft: "auto",
                                    maxWidth: "400px",
                                  }}
                                  onClick={() =>
                                    setDetails((pre) => ({
                                      ...pre,
                                      service: [
                                        ...details?.service,
                                        {
                                          service: {
                                            serviceName: "",
                                            serviceId: "",
                                            Requirement: "",
                                          },
                                          hours: [],
                                        },
                                      ],
                                    }))
                                  }
                                >
                                  <img
                                    src={addIcon}
                                    alt=""
                                    style={{
                                      width: "25px",
                                      marginRight: "10px",
                                    }}
                                  />{" "}
                                  Add Service
                                </button>
                              </Box>
                            </div>
                            <ServiceCard
                              details={details}
                              setDetails={setDetails}
                              setDeletedServiceId={setDeletedServiceId}
                            />
                            <p className="t-13-500 text-danger">
                              {error?.service}
                            </p>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} md={12}>
                          <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">
                            <button
                              type="button"
                              className="btn_White ms-lg-auto"
                              onClick={() => {
                                handleCancel();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn_admina"
                              onClick={() => secoundNext()}
                              // onClick={() => }
                              // onClick={() => {
                              //     if (inputData?.provider_introduction.trim()) {
                              //
                              //
                              //         dispatch(addMyPageDetails({...inputData, deleteId: deleteId},));
                              //         handleSave()
                              //         setLinkError("")
                              //     } else {
                              //         toast.error("Please Add Provider Introductions.")
                              //     }
                              // }}
                            >
                              Next
                            </button>
                          </Box>
                        </Grid>
                      </>
                    ) : eventStaps?.three ? (
                      <>
                        <Grid item xs={12}>
                          <div className="d-block d-md-flex align-items-center flex-wrap">
                            <p className="t-30-bold">Add Providers manually</p>
                            <Box
                              className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3"
                              style={{ flexGrow: "1", marginLeft: "auto" }}
                            >
                              <button
                                type="button"
                                className="btn_admina"
                                style={{
                                  marginLeft: "auto",
                                  maxWidth: "400px",
                                }}
                                onClick={() =>
                                  setDetails((pre) => ({
                                    ...pre,
                                    provider: [
                                      ...details?.provider,
                                      {
                                        name: "",
                                        email: "",
                                        phone: "",
                                      },
                                    ],
                                  }))
                                }
                              >
                                <img
                                  src={addIcon}
                                  alt=""
                                  style={{
                                    width: "25px",
                                    marginRight: "10px",
                                  }}
                                />{" "}
                                Add Provider
                              </button>
                            </Box>
                          </div>
                          <ProviderCard
                            details={details}
                            setDetails={setDetails}
                            setDeletedProviderId={setDeletedProviderId}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">
                            <button
                              type="button"
                              className="btn_White ms-lg-auto"
                              onClick={() => {
                                handleCancel();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn_admina"
                              onClick={() => thirdNext()}
                              // onClick={() => }
                              // onClick={() => {
                              //     if (inputData?.provider_introduction.trim()) {
                              //
                              //
                              //         dispatch(addMyPageDetails({...inputData, deleteId: deleteId},));
                              //         handleSave()
                              //         setLinkError("")
                              //     } else {
                              //         toast.error("Please Add Provider Introductions.")
                              //     }
                              // }}
                            >
                              Next
                            </button>
                          </Box>
                        </Grid>
                      </>
                    ) : eventStaps?.forth ? (
                      <>
                        <Grid item xs={12}>
                          <div className="d-block d-md-flex align-items-center ">
                            <p className="t-30-bold">
                              {details?._id ? "Edit" : "Create"} Package
                            </p>
                            <Box
                              className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3"
                              style={{ flexGrow: "1", marginLeft: "auto" }}
                            >
                              <button
                                type="button"
                                className="btn_admina"
                                style={{
                                  marginLeft: "auto",
                                  maxWidth: "400px",
                                }}
                                onClick={() => setOpenService(true)}
                              >
                                <img
                                  src={addIcon}
                                  alt=""
                                  style={{
                                    width: "25px",
                                    marginRight: "10px",
                                  }}
                                />{" "}
                                Add Package
                              </button>
                            </Box>
                          </div>
                          <PackageCard
                            details={details}
                            setDetails={setDetails}
                            setPackageDetails={setPackageDetails}
                            setPackageEdit={setPackageEdit}
                            setPackageIndex={setPackageIndex}
                            setOpenService={setOpenService}
                            setDeletedPackageId={setDeletedPackageId}
                          />
                          <p className="t-13-500 text-danger">
                            {error?.package}
                          </p>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">
                            <div className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3 w-100">
                              <Button
                                className="ticketBox gap-2 d-flex align-items-center justify-content-center"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();

                                  if (validation()) {
                                    setOpenDicPopup(true);
                                  }
                                }}
                              >
                                <img src={ticket} alt="" />
                                <p className="m-0">Discount Code</p>
                              </Button>

                              {/*<div className="ms-lg-auto">*/}
                              {loaderPre ? (
                                <a
                                  href="#"
                                  style={{ lineHeight: "22px" }}
                                  onClick={(e) => e.preventDefault()}
                                  className={`btn_White ${
                                    id && is_duplicate !== true
                                      ? ""
                                      : "ms-lg-auto"
                                  }`}
                                >
                                  {/* save */}
                                  <div className="loader blackloader"></div>
                                </a>
                              ) : (
                                <button
                                  type="button"
                                  className={`btn_White ${
                                    id && is_duplicate !== true
                                      ? ""
                                      : "ms-lg-auto"
                                  }`}
                                  onClick={(e) => {
                                    setDetails((pre) => ({
                                      ...pre,
                                      publish: false,
                                      event_id:
                                        myEvent?.createdEvent?._id || null,
                                    }));
                                    if (id && is_duplicate !== true) {
                                      handleEditEvent(e, true);
                                    } else {
                                      handleCreateEvent(e, true);
                                    }
                                  }}
                                >
                                  Preview Event
                                </button>
                              )}
                              {/*</div>*/}
                              <button
                                type="button"
                                className={`btn_White ${
                                  id && is_duplicate !== true
                                    ? "ms-lg-auto"
                                    : ""
                                }`}
                                onClick={() => {
                                  handleCancel();
                                }}
                              >
                                Cancel
                              </button>
                              {loader ? (
                                <a
                                  href="#"
                                  style={{ lineHeight: "22px" }}
                                  onClick={(e) => e.preventDefault()}
                                  className="btn_admina"
                                >
                                  {/* save */}
                                  <div className="loader"></div>
                                </a>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn_admina"
                                  onClick={(e) => {
                                    // validation()
                                    setDetails((pre) => ({
                                      ...pre,
                                      publish: true,
                                      event_id:
                                        myEvent?.createdEvent?._id ||
                                        id ||
                                        null,
                                    }));
                                    if (id && is_duplicate !== true) {
                                      handleEditEvent(e, false);
                                    } else {
                                      handleCreateEvent(e, false);
                                    }
                                  }}
                                  // onClick={() => }
                                  // onClick={() => {
                                  //     if (inputData?.provider_introduction.trim()) {
                                  //
                                  //
                                  //         dispatch(addMyPageDetails({...inputData, deleteId: deleteId},));
                                  //         handleSave()
                                  //         setLinkError("")
                                  //     } else {
                                  //         toast.error("Please Add Provider Introductions.")
                                  //     }
                                  // }}
                                >
                                  {id && is_duplicate !== true
                                    ? "Edit Event"
                                    : "Publish Event"}
                                </button>
                              )}
                            </div>
                          </Box>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                  {/*<Box*/}
                  {/*    className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">*/}

                  {/*    <button*/}
                  {/*        type="button"*/}
                  {/*        className="btn_White ms-lg-auto"*/}
                  {/*        onClick={() => {*/}
                  {/*            handleCliearData()*/}
                  {/*            navigate(-1)*/}
                  {/*        }}*/}
                  {/*    >*/}
                  {/*        Cancel*/}
                  {/*    </button>*/}
                  {/*    {loader ? <a*/}
                  {/*            href="#"*/}
                  {/*            style={{lineHeight: "22px"}}*/}
                  {/*            onClick={(e) => e.preventDefault()}*/}
                  {/*            className="btn_admina"*/}
                  {/*        >*/}
                  {/*            /!* save *!/*/}
                  {/*            <div className="loader"></div>*/}
                  {/*        </a> :*/}
                  {/*        <button*/}
                  {/*            type="submit"*/}
                  {/*            className="btn_admina"*/}
                  {/*            onClick={() => validation()}*/}
                  {/*            // onClick={() => }*/}
                  {/*            // onClick={() => {*/}
                  {/*            //     if (inputData?.provider_introduction.trim()) {*/}
                  {/*            //*/}
                  {/*            //*/}
                  {/*            //         dispatch(addMyPageDetails({...inputData, deleteId: deleteId},));*/}
                  {/*            //         handleSave()*/}
                  {/*            //         setLinkError("")*/}
                  {/*            //     } else {*/}
                  {/*            //         toast.error("Please Add Provider Introductions.")*/}
                  {/*            //     }*/}
                  {/*            // }}*/}
                  {/*        >*/}
                  {/*            {id ? "Edit Event" : "Publish Event"}*/}
                  {/*        </button>}*/}
                  {/*</Box>*/}
                </div>
              </form>
            </div>
          </section>
        </div>
      ) : (
        <PreviewEvent
          setPreviewPopup={setPreviewPopup}
          eventId={myEvent?.createdEvent?._id || id}
        />
      )}

      <PackagePopup
        open={openService}
        setOpen={setOpenService}
        details={details}
        setDetails={setDetails}
        setPackageDetails={setPackageDetails}
        packageDetails={packageDetails}
        isPackageEdit={isPackageEdit}
        setPackageEdit={setPackageEdit}
        packageIndex={packageIndex}
      />
      <PlacePopUp
        open={isLoaction}
        setOpen={setIsLocation}
        setDetails={setLoactionDetails}
        showNote={false}
        setPlaceNote={() => {}}
      />
      {myEvent?.createdEvent?._id && (
        <EventLinkPopup
          setOpen={setIsLinkPopup}
          open={isLinkPopup}
          typeSharing={"event_type"}
          path={"view-events"}
          providerId={userAuth?._id}
          eventId={myEvent?.createdEvent?._id}
          setIsEventPopup={setIsEventPopup}
          isEventPopup={isEventPopup}
        />
      )}
      {myEvent?.createdEvent?._id ? (
        <EventSharePopup
          setOpen={setIsEventPopup}
          open={isEventPopup}
          typeSharing={"event_type"}
          path={"view-events"}
          providerId={userAuth?._id}
          eventId={myEvent?.createdEvent?._id}
        />
      ) : null}
      <BankAlartPopup
        setOpen={setIsBankAlart}
        open={isBankAlart}
        setOpenBankInfo={setOpenBankInfo}
        isEvent={true}
      />
      <BankView setOpenBankInfo={setOpenBankInfo} openBankInfo={openBankInfo} />
      <GenerateCodePopup
        dublicateEventMedia={dublicateEventMedia}
        setIsEdit={() => {}}
        setOpen={setOpenDicPopup}
        duplicateAddedMedia={duplicateAddedMedia}
        open={openDicPopup}
        mergeDateTime={mergeDateTime}
        validation={validation}
        userDetails={userDetails}
        setIsBankAlart={setIsBankAlart}
        setLoaderPre={setLoaderPre}
        EventMedia={EventMedia}
        getServiceJson={getServiceJson}
        getProviderJson={getProviderJson}
        getPackageJson={getPackageJson}
        meetingLink={meetingLink}
        loactionDetails={loactionDetails}
        handleCliearData={handleCliearData}
        setIsLinkPopup={setIsLinkPopup}
        setPreviewPopup={setPreviewPopup}
        setOpenDiscountSucsess={() => {}}
        eventDetails={details}
        seteventDetails={setDetails}
      />
    </>
  );
};

export default CreateEvent;
