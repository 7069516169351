import React from 'react';
import {Modal} from "react-bootstrap";
import DeleteImage from "../../assets/images/deletedot.png";
import {IconButton} from "@mui/material";
import shareIcon from "../../assets/images/share_icon.png";
import copyIcon from "../../assets/images/copy_icon.png";

const DiscountCongratulationsPopup = ({open, setOpen, data, setOpenShare, setSharedData, openShare}) => {


    function myFunction(code) {
        // Get the text field
        navigator.clipboard.writeText(code);
        // Alert the copied text
        // alert("Copied the text: " + copyText.value);
    }



    return(
        <>
            <Modal
                centered
                show={open}
                // backdrop="static"
                onHide={() => {
                    setOpen(false);
                }}
                style={{zIndex: '100001', background: '#00000096'}}
                dialogClassName="delete-modal discount-congo"
            >
                <div className="Congratulations_msg discount-congo">
                    <div className="group-parent m-0" style={{ borderRadius: "10px" }}>
                        <div className="group " style={{ borderRadius: "10px" }}>
                            <div className="padding-inner pt-2 pb-2" style={{ borderRadius: "10px" }}>
                                <div className="d-flex align-content justify-content-between p-2 " style={{border: "1px dotted #0DBCEC", borderRadius: '5px', background: 'linear-gradient(90deg, rgba(92, 203, 234, 0.2) -4.34%, rgba(239, 206, 150, 0.2) 103.9%)'}}>
                                    <p className="m-0 t-20-bold text-dark">{data?.discount_code}</p>
                                    <div>
                                        <div className="d-flex gap-2">
                                            <button className="btnn">
                                                <IconButton sx={{padding: '0'}} className="btnn" onClick={() => {
                                                    setOpenShare(true)
                                                    setSharedData(data)
                                                    setOpen(false);
                                                }}>
                                                    <img src={shareIcon} alt="" className="btnn"/>
                                                </IconButton>
                                            </button>
                                            <button className="btnn">
                                                <IconButton sx={{padding: '0'}} className="btnn" onClick={() => {
                                                    myFunction(data?.discount_code)
                                                }}>
                                                    <img src={copyIcon} alt="" className="btnn"/>
                                                </IconButton>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mb-3 mb-md-3 page-title text-center mt-3">
                                    Congratulations!
                                </h3>

                                <p className="text-center" style={{color: "#000"}}>
                                    Your discount code has been generated.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DiscountCongratulationsPopup;