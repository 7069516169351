import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconButton, Popover, Typography } from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import { app } from "../Configration/firebase";
import { ENVIROMENT } from "../Configration/configration";
// import {firebase} from 'firebase/app';
// import 'firebase/dynamic-links';
// import dynamicLinks from '@react-native-firebase/dynamic-links';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
} from "react-share";
import {
  CustomGreyLeftArrow,
  CustomGreyRightArrow,
  CustomLeftArrow,
  CustomRightArrow,
} from "./CarouselArrows";
import Carousel from "react-multi-carousel";

import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";
import { hireNcollaborateConstant } from "../action/constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const reviewResponsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 5, slidesToSlide: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 5, slidesToSlide: 3 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 5, slidesToSlide: 3 },
};

const EventLinkPopup = ({
  open,
  setOpen,
  typeSharing,
  data,
  path,
  providerId,
  eventId,
  serviceId,
  setIsEventPopup,
  isEventPopup,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isCopy, setIsCopy] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openCopy = Boolean(anchorEl);

  const [shortLink, setShortLink] = useState(null);
  const typeKey = "type"; // Replace this with your actual type key
  // const typeSharings = typeSharing; // Replace this with your actual type sharing
  const userIdKey = "user_id"; // Replace this with your actual user ID key
  const milesDistanceKey = "miles_distance"; // Replace this with your actual miles distance key
  const isSaveStatusKey = "is_save_status"; // Replace this with your actual save status key
  const serviceIdKey = "service_id"; // Replace this with your actual service ID key
  const eventIdKey = "event_id"; // Replace this with your actual event ID key
  const serviceTypeKey = "service_type";
  const bookingTypeKey = "booking_type";
  const links = `https://adanima.page.link?${typeKey}=${typeSharing}&${
    typeSharing === serviceTypeKey
      ? `${userIdKey}=${data?._id}&${milesDistanceKey}=${
          data?.miles_distance
        }&${isSaveStatusKey}=${""}&${serviceIdKey}=${serviceId}`
      : typeSharing === bookingTypeKey
      ? `${userIdKey}=${data?.user_id}&${serviceIdKey}=${data?._id}`
      : `${eventIdKey}=${eventId}`
  }`;

  const navigate = useNavigate();

  let shareWeblink;
  if (ENVIROMENT == "DEV") {
    shareWeblink =
      path == "provider_details"
        ? `https://dev.ad-anima.com/${path}/${providerId}`
        : path == "book-session"
        ? `https://dev.ad-anima.com/${path}/${providerId}/${data?._id}`
        : `https://dev.ad-anima.com/${path}/${eventId}/${providerId}`; // --- live ---
  } else if (ENVIROMENT == "STAGE") {
    shareWeblink =
      path == "provider_details"
        ? `https://stage.ad-anima.com/${path}/${providerId}`
        : path == "book-session"
        ? `https://stage.ad-anima.com/${path}/${providerId}/${data?._id}`
        : `https://stage.ad-anima.com/${path}/${eventId}/${providerId}`; // --- live ---
  } else if (ENVIROMENT == "LIVE") {
    shareWeblink =
      path == "provider_details"
        ? `https://app.ad-anima.com/${path}/${providerId}`
        : path == "book-session"
        ? `https://app.ad-anima.com/${path}/${providerId}/${data?._id}`
        : `https://app.ad-anima.com/${path}/${eventId}/${providerId}`; // --- live ---
  }

  // const shareWeblink = path == "provider_details" ? `https://stage.ad-anima.com/${path}/${providerId}` : path == "book-session" ? `https://stage.ad-anima.com/${path}/${providerId}/${data?._id}` : `https://stage.ad-anima.com/${path}/${eventId}/${providerId}` // --- live ---
  // const shareWeblink = path == "provider_details" ? `https://app.ad-anima.com/${path}/${providerId}` : path == "book-session" ? `https://app.ad-anima.com/${path}/${providerId}/${data?._id}` : `https://app.ad-anima.com/${path}/${eventId}/${providerId}` // --- live ---

  useEffect(() => {
    if (path) {
      // Firebase Dynamic Links API endpoint and API key
      const apiUrl =
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBJVp9yG_EYmhcDu9K0cCuDdX059plyAtI";

      // Request body containing the dynamic link parameters
      const requestBody = {
        dynamicLinkInfo: {
          domainUriPrefix: "https://adanima.page.link",
          link: links,
          androidInfo: {
            androidPackageName: "com.app.adanima",
            androidFallbackLink: shareWeblink,
          },
          iosInfo: {
            iosBundleId: "com.app.adanima",
            iosFallbackLink: shareWeblink,
          },
          desktopInfo: {
            desktopFallbackLink: shareWeblink,
          },
        },
      };

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response from the API
          // console.log(data.shortLink, "data.shortLink");
          setShortLink(data.shortLink);
        })
        .catch((error) => {
          // Handle errors if any
          console.error("Error creating Dynamic Link:", error);
        });
    }
  }, [path, providerId, data]);

  return (
    <>
      <Modal
        centered
        show={open}
        onHide={() => {
          setOpen(false);
          navigate(`/my_events`);
        }}
        dialogClassName="delete-modal"
      >
        <div
          className="Congratulations_msg"
          style={{ display: isEventPopup ? "none" : "block" }}
        >
          <div className="group" style={{ borderRadius: "10px" }}>
            <div className="py-2 px-2" style={{ borderRadius: "10px" }}>
              <div className="d-flex justify-content-between align-items-center">
                <p className="t-24-bold m-0">Event Link</p>
                {/*<IconButton onClick={() => setOpen(false)}>*/}
                {/*    <img src={closeIcon} alt=""/>*/}
                {/*</IconButton>*/}
              </div>

              {/*<div className="mt-4 mb-2 share">*/}
              {/*    <Carousel*/}
              {/*        responsive={reviewResponsive}*/}
              {/*        // autoPlay={true}*/}
              {/*        // autoPlaySpeed={3000}*/}
              {/*        // infinite={true}*/}
              {/*        customLeftArrow={<CustomGreyLeftArrow/>}*/}
              {/*        customRightArrow={<CustomGreyRightArrow/>}*/}
              {/*        removeArrowOnDeviceType={["tablet", "mobile"]}*/}
              {/*    >*/}
              {/*        <FacebookShareButton url={shortLink}>*/}
              {/*            <FacebookIcon size={50} round={true}/>*/}
              {/*        </FacebookShareButton>*/}
              {/*        <WhatsappShareButton url={shortLink}>*/}
              {/*            <WhatsappIcon size={50} round={true}/>*/}
              {/*        </WhatsappShareButton>*/}
              {/*        <EmailShareButton url={shortLink}>*/}
              {/*            <EmailIcon size={50} round={true}/>*/}
              {/*        </EmailShareButton>*/}
              {/*        <HatenaShareButton url={shortLink}>*/}
              {/*            <HatenaIcon size={50} round={true}/>*/}
              {/*        </HatenaShareButton>*/}
              {/*        <InstapaperShareButton url={shortLink}>*/}
              {/*            <InstapaperIcon size={50} round={true}/>*/}
              {/*        </InstapaperShareButton>*/}
              {/*        <LineShareButton url={shortLink}>*/}
              {/*            <LineIcon size={50} round={true}/>*/}
              {/*        </LineShareButton>*/}
              {/*        <LinkedinShareButton url={shortLink}>*/}
              {/*            <LinkedinIcon size={50} round={true}/>*/}
              {/*        </LinkedinShareButton>*/}
              {/*        <LivejournalShareButton url={shortLink}>*/}
              {/*            <LivejournalIcon size={50} round={true}/>*/}
              {/*        </LivejournalShareButton>*/}
              {/*        <MailruShareButton url={shortLink}>*/}
              {/*            <MailruIcon size={50} round={true}/>*/}
              {/*        </MailruShareButton>*/}
              {/*        <OKShareButton url={shortLink}>*/}
              {/*            <OKIcon size={50} round={true}/>*/}
              {/*        </OKShareButton>*/}
              {/*        <PinterestShareButton url={shortLink}>*/}
              {/*            <PinterestIcon size={50} round={true}/>*/}
              {/*        </PinterestShareButton>*/}
              {/*        <PocketShareButton url={shortLink}>*/}
              {/*            <PocketIcon size={50} round={true}/>*/}
              {/*        </PocketShareButton>*/}
              {/*        <RedditShareButton url={shortLink}>*/}
              {/*            <RedditIcon size={50} round={true}/>*/}
              {/*        </RedditShareButton>*/}
              {/*        <TelegramShareButton url={shortLink}>*/}
              {/*            <TelegramIcon size={50} round={true}/>*/}
              {/*        </TelegramShareButton>*/}
              {/*        <TumblrShareButton url={shortLink}>*/}
              {/*            <TumblrIcon size={50} round={true}/>*/}
              {/*        </TumblrShareButton>*/}
              {/*        <TwitterShareButton url={shortLink}>*/}
              {/*            <TwitterIcon size={50} round={true}/>*/}
              {/*        </TwitterShareButton>*/}
              {/*        <ViberShareButton url={shortLink}>*/}
              {/*            <ViberIcon size={50} round={true}/>*/}
              {/*        </ViberShareButton>*/}
              {/*        <VKShareButton url={shortLink}>*/}
              {/*            <VKIcon size={50} round={true}/>*/}
              {/*        </VKShareButton>*/}
              {/*        <WorkplaceShareButton url={shortLink}>*/}
              {/*            <WorkplaceIcon size={50} round={true}/>*/}
              {/*        </WorkplaceShareButton>*/}
              {/*    </Carousel>*/}
              {/*</div>*/}

              {/*<div className="black_border p-3 d-flex align-items-center mt-4">*/}
              {/*    <p className="t-15-bold m-0" style={{*/}
              {/*        whiteSpace: "nowrap",*/}
              {/*        width: "100%",*/}
              {/*        overflow: "hidden",*/}
              {/*        textOverflow: "ellipsis",*/}
              {/*    }}>{shortLink}</p>*/}
              {/*</div>*/}
              <div>
                <p>
                  Share your event link with your friends and other providers.
                </p>
              </div>
              {/*<div className="d-flex justify-content-center mt-3">*/}
              {/*    <button className="massageBtn mt-3"*/}
              {/*            onClick={(e) => {*/}
              {/*                navigator.clipboard.writeText(shortLink)*/}
              {/*                setIsCopy(true)*/}
              {/*                setTimeout(() => {*/}
              {/*                    setIsCopy(false)*/}
              {/*                }, 1000);*/}
              {/*            }}*/}
              {/*            onMouseEnter={handlePopoverOpen}*/}
              {/*            onMouseLeave={handlePopoverClose}*/}
              {/*    >*/}
              {/*        Copy Link*/}
              {/*    </button>*/}
              {/*</div>*/}
              <div className="w-100 d-flex">
                <div className="col-6">
                  <button
                    type="submit"
                    style={{ lineHeight: "22px" }}
                    className="btn_admina delete-confirm-button"
                    onClick={() => {
                      navigator.clipboard.writeText(shortLink);
                      setIsCopy(true);
                      setTimeout(() => {
                        setIsCopy(false);
                      }, 1000);

                      // onConfirm();
                    }}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    Copy Link
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="submit"
                    style={{ lineHeight: "22px" }}
                    className="btn_admina delete-button"
                    onClick={() => setIsEventPopup(true)}
                  >
                    Share Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openCopy}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{isCopy ? "Copied..." : "Copy"}</Typography>
      </Popover>
    </>
  );
};

export default EventLinkPopup;
