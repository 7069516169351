import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {version, walletConstant} from "./constant";
import {toast} from "react-toastify";
import {cardsLists} from "./walletConstant";


export const UpdateVarsion = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        // fdata.append("user_id", JSON.parse(localStorage.getItem("AdAnima_auth"))?._id);
        fdata.append("user_id", data?.user_id);
        fdata.append("app_version", data?.app_version);
        fdata.append("app_platform", data?.app_platform);
        fdata.append("device_token", data?.device_token);
        fdata.append("location", JSON.stringify(data?.center));
        fdata.append("address", data?.address);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.update_version}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            // dispatch(cardsLists())
            dispatch({
                type : version.UPDATE_VERSION,
                payload : response.data,
            });
            // toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
};
export const UpdatRadius = (response) => {
    return async (dispatch) => {
        // let response;
        // const fdata = new FormData();
        // // fdata.append("user_id", JSON.parse(localStorage.getItem("AdAnima_auth"))?._id);
        // fdata.append("user_id", data?.user_id);
        // fdata.append("app_version", data?.app_version);
        // fdata.append("app_platform", data?.app_platform);
        // fdata.append("device_token", data?.device_token);
        // fdata.append("location", JSON.stringify(data?.center));
        // fdata.append("address", data?.address);
        // await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.update_version}`, {
        //     method: "POST",
        //     headers: {
        //         Authorization: `Bearer ${
        //             JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        //         }`,
        //     },
        //     body: fdata,
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         response = data;
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });

        // if (response?.success) {
            dispatch({
                type : version.RADIUS,
                payload : response,
            });
            // toast.success(response?.message);
        // } else {
        //     toast.error(response?.message);
        // }
    };
};