import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../../Utils/DateFormateUtils";
import moment from "moment";
import logo from "../../assets/images/tempImage/templogo.png";
import tempaback from "../../assets/images/tempImage/storyback3.png";

const ServiceStoryTemp3 = ({ data }) => {
  console.log(data);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 1080 1920"
      fill="none"
    >
      <g clip-path="url(#clip0_9072_7461)">
        <rect width="1080" height="1920" fill="white" />
        <rect
          x="-903"
          width="4148"
          height="2808"
          fill="url(#pattern0_9072_7461)"
        />
        <rect width="1080" height="1920" fill="url(#paint0_linear_9072_7461)" />
        <rect
          x="50"
          y="50"
          width="225"
          height="203"
          fill="url(#pattern1_9072_7461)"
        />
        <g filter="url(#filter0_d_9072_7461)">
          <path
            d="M987.443 219H917.557C915.035 219 912.707 217.643 911.465 215.447L876.95 154.447C875.74 152.308 875.74 149.692 876.95 147.553L911.465 86.5529C912.707 84.3573 915.035 83 917.557 83H987.443C989.965 83 992.293 84.3573 993.535 86.5528L1028.05 147.553C1029.26 149.692 1029.26 152.308 1028.05 154.447L993.535 215.447C992.293 217.643 989.965 219 987.443 219Z"
            fill="url(#pattern2_9072_7461)"
            shape-rendering="crispEdges"
          />
          <path
            d="M987.443 217.5H917.557C915.575 217.5 913.747 216.434 912.77 214.708L878.256 153.708C877.305 152.028 877.305 149.972 878.256 148.292L876.95 147.553L878.256 148.292L912.77 87.2915C913.747 85.5665 915.575 84.5 917.557 84.5H987.443C989.425 84.5 991.253 85.5665 992.23 87.2915L1026.74 148.292C1027.69 149.972 1027.69 152.028 1026.74 153.708L992.23 214.708C991.253 216.434 989.425 217.5 987.443 217.5Z"
            stroke="url(#paint1_linear_9072_7461)"
            stroke-width="3"
            shape-rendering="crispEdges"
          />
        </g>
        <path
          d="M556.694 1747.09C567.016 1730.89 565.718 1732.91 566.016 1732.49C569.774 1727.19 571.76 1720.95 571.76 1714.45C571.76 1697.21 557.767 1683 540.5 1683C523.29 1683 509.24 1697.18 509.24 1714.45C509.24 1720.95 511.268 1727.35 515.149 1732.72L524.306 1747.09C514.516 1748.59 497.873 1753.08 497.873 1762.95C497.873 1766.55 500.222 1771.68 511.412 1775.67C519.225 1778.46 529.555 1780 540.5 1780C560.966 1780 583.127 1774.23 583.127 1762.95C583.127 1753.08 566.504 1748.6 556.694 1747.09ZM519.897 1729.6C519.866 1729.55 519.833 1729.5 519.799 1729.45C516.569 1725.01 514.924 1719.74 514.924 1714.45C514.924 1700.24 526.368 1688.68 540.5 1688.68C554.603 1688.68 566.076 1700.24 566.076 1714.45C566.076 1719.75 564.462 1724.84 561.407 1729.16C561.133 1729.53 562.561 1727.31 540.5 1761.92L519.897 1729.6ZM540.5 1774.32C518.146 1774.32 503.557 1767.75 503.557 1762.95C503.557 1759.73 511.053 1754.42 527.664 1752.36L538.103 1768.74C538.36 1769.14 538.714 1769.47 539.133 1769.7C539.552 1769.93 540.022 1770.05 540.5 1770.05C540.978 1770.05 541.448 1769.93 541.866 1769.7C542.285 1769.47 542.639 1769.14 542.896 1768.74L553.335 1752.36C569.947 1754.42 577.443 1759.73 577.443 1762.95C577.443 1767.7 562.986 1774.32 540.5 1774.32Z"
          fill="#CFF5FF"
        />
        <path
          d="M540.5 1700.24C532.665 1700.24 526.291 1706.61 526.291 1714.45C526.291 1722.28 532.665 1728.66 540.5 1728.66C548.335 1728.66 554.709 1722.28 554.709 1714.45C554.709 1706.61 548.335 1700.24 540.5 1700.24ZM540.5 1722.97C535.799 1722.97 531.975 1719.15 531.975 1714.45C531.975 1709.75 535.799 1705.92 540.5 1705.92C545.201 1705.92 549.025 1709.75 549.025 1714.45C549.025 1719.15 545.201 1722.97 540.5 1722.97Z"
          fill="#CFF5FF"
        />
        <foreignObject x="68" y="326" width="945" height="1300">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            className="w-100 h-100 d-flex flex-wrap align-items-center"
          >
            <div className="w-100">
              <div
                className="w-100 text-bigshot text-elips text-center"
                style={{
                  fontSize: "130px",
                  lineHeight: "128px",
                  color: "#ffffff",
                  fontWeight: "400",
                }}
              >
                {data?.service_name} by {data?.user_id?.name}
              </div>
              <div
                className="w-100 text-rubik text-elips one-line text-center mt-4"
                style={{
                  fontSize: "60px",
                  lineHeight: "71px",
                  color: "#F09B00",
                  fontWeight: "600",
                }}
              >
                ${data?.session_price}/Hour
              </div>
              <div
                className="w-100 text-source text-elips one-line text-center mt-3"
                style={{
                  fontSize: "50px",
                  lineHeight: "66px",
                  color: "#ffffff",
                  fontWeight: "600",
                }}
              >
                Availability: {data?.session_hour} hour {data?.session_minute}{" "}
                mins
              </div>
              <div
                className="w-100 text-istok text-elips text-center mt-3"
                style={{
                  fontSize: "31px",
                  lineHeight: "44px",
                  color: "#ffffff",
                  fontWeight: "400",
                }}
              >
                {data?.description}
              </div>
              <div className="w-100 mt-4 d-flex justify-content-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="333"
                  height="102"
                  viewBox="0 0 333 102"
                  fill="none"
                >
                  <g filter="url(#filter0_d_9072_7489)">
                    <path
                      d="M295.774 94H37.8227C36.1043 94 34.5063 93.1176 33.591 91.6632L9.67605 53.6632C8.65167 52.0355 8.65167 49.9645 9.67605 48.3368L33.591 10.3368C34.5063 8.88244 36.1043 8 37.8227 8H295.774C297.492 8 299.09 8.88245 300.005 10.3368L323.92 48.3368C324.945 49.9645 324.945 52.0355 323.92 53.6632L300.005 91.6632C299.09 93.1176 297.492 94 295.774 94Z"
                      fill="url(#paint0_linear_9072_7489)"
                    />
                    <path
                      d="M295.774 93H37.8227C36.448 93 35.1695 92.294 34.4373 91.1305L10.5224 53.1306C9.70289 51.8284 9.70289 50.1716 10.5224 48.8694L34.4373 10.8694C35.1695 9.70596 36.448 9 37.8227 9H295.774C297.148 9 298.427 9.70596 299.159 10.8694L323.074 48.8694C323.894 50.1716 323.894 51.8284 323.074 53.1306L299.159 91.1306C298.427 92.294 297.148 93 295.774 93Z"
                      stroke="url(#paint1_linear_9072_7489)"
                      stroke-width="2"
                    />
                  </g>
                  <path
                    d="M255.022 40.4233L248.232 62.3156H244.144L239.663 47.9974C239.592 47.7962 239.522 47.5749 239.451 47.3335C239.381 47.0921 239.316 46.8356 239.255 46.564C239.195 46.8356 239.13 47.0921 239.059 47.3335C238.989 47.5749 238.918 47.7962 238.848 47.9974L234.322 62.3156H230.218L223.443 40.4233H227.245C227.638 40.4233 227.965 40.5139 228.226 40.6949C228.498 40.876 228.674 41.1174 228.754 41.4191L232.149 53.7609C232.229 54.1029 232.31 54.475 232.39 54.8774C232.481 55.2697 232.566 55.6871 232.647 56.1297C232.737 55.677 232.833 55.2546 232.934 54.8623C233.034 54.47 233.145 54.1029 233.265 53.7609L237.218 41.4191C237.299 41.1677 237.47 40.9414 237.731 40.7402C238.003 40.529 238.33 40.4233 238.712 40.4233H240.04C240.432 40.4233 240.749 40.5189 240.99 40.71C241.242 40.8911 241.428 41.1274 241.549 41.4191L245.487 53.7609C245.607 54.0828 245.718 54.4348 245.818 54.817C245.919 55.1892 246.015 55.5865 246.105 56.009C246.186 55.5865 246.261 55.1892 246.331 54.817C246.412 54.4348 246.492 54.0828 246.573 53.7609L249.968 41.4191C250.038 41.1576 250.209 40.9263 250.481 40.7251C250.752 40.5239 251.079 40.4233 251.461 40.4233H255.022Z"
                    fill="black"
                  />
                  <path
                    d="M222.766 51.3619C222.766 52.9612 222.494 54.4448 221.951 55.8128C221.418 57.1807 220.658 58.3626 219.673 59.3584C218.697 60.3542 217.52 61.1387 216.142 61.7121C214.764 62.2753 213.235 62.557 211.555 62.557C209.876 62.557 208.347 62.2753 206.969 61.7121C205.591 61.1387 204.409 60.3542 203.423 59.3584C202.437 58.3626 201.673 57.1807 201.13 55.8128C200.597 54.4448 200.33 52.9612 200.33 51.3619C200.33 49.7626 200.597 48.284 201.13 46.9261C201.673 45.5581 202.437 44.3763 203.423 43.3805C204.409 42.3847 205.591 41.6052 206.969 41.0419C208.347 40.4686 209.876 40.1819 211.555 40.1819C213.235 40.1819 214.764 40.4686 216.142 41.0419C217.52 41.6052 218.697 42.3897 219.673 43.3956C220.658 44.3914 221.418 45.5732 221.951 46.9412C222.494 48.2991 222.766 49.7726 222.766 51.3619ZM218.134 51.3619C218.134 50.2152 217.983 49.1893 217.681 48.284C217.379 47.3687 216.942 46.5942 216.368 45.9605C215.805 45.3167 215.116 44.8289 214.301 44.497C213.497 44.155 212.581 43.984 211.555 43.984C210.529 43.984 209.609 44.155 208.794 44.497C207.98 44.8289 207.286 45.3167 206.712 45.9605C206.149 46.5942 205.716 47.3687 205.415 48.284C205.113 49.1893 204.962 50.2152 204.962 51.3619C204.962 52.5186 205.113 53.5546 205.415 54.47C205.716 55.3752 206.149 56.1447 206.712 56.7784C207.286 57.4121 207.98 57.8999 208.794 58.2419C209.609 58.5738 210.529 58.7398 211.555 58.7398C212.581 58.7398 213.497 58.5738 214.301 58.2419C215.116 57.8999 215.805 57.4121 216.368 56.7784C216.942 56.1447 217.379 55.3752 217.681 54.47C217.983 53.5546 218.134 52.5186 218.134 51.3619Z"
                    fill="black"
                  />
                  <path
                    d="M197.027 40.4233V62.3156H194.689C194.337 62.3156 194.04 62.2603 193.799 62.1497C193.557 62.029 193.321 61.8278 193.09 61.5461L182.181 47.6654C182.211 48.0175 182.231 48.3645 182.242 48.7065C182.262 49.0485 182.272 49.3653 182.272 49.657V62.3156H178.273V40.4233H180.657C180.848 40.4233 181.009 40.4334 181.14 40.4535C181.281 40.4636 181.407 40.4988 181.517 40.5591C181.628 40.6094 181.734 40.6798 181.834 40.7704C181.935 40.8609 182.045 40.9816 182.166 41.1325L193.15 55.0735C193.11 54.6913 193.08 54.3242 193.059 53.9721C193.039 53.61 193.029 53.268 193.029 52.9461V40.4233H197.027Z"
                    fill="black"
                  />
                  <path
                    d="M154.657 49.4609H155.547C156.292 49.4609 156.83 49.2295 157.162 48.7668L162.699 41.3588C162.96 41.0067 163.247 40.7653 163.559 40.6346C163.871 40.4937 164.253 40.4233 164.706 40.4233H168.628L161.658 49.4005C161.205 49.9839 160.738 50.4014 160.255 50.6528C160.607 50.7936 160.924 50.9797 161.205 51.2111C161.487 51.4323 161.758 51.7291 162.02 52.1012L169.172 62.3156H165.143C164.882 62.3156 164.655 62.2955 164.464 62.2553C164.283 62.215 164.122 62.1597 163.981 62.0893C163.841 62.0189 163.72 61.9334 163.619 61.8328C163.519 61.7222 163.423 61.5964 163.333 61.4556L157.705 53.5798C157.514 53.3083 157.287 53.1171 157.026 53.0065C156.764 52.8858 156.397 52.8254 155.925 52.8254H154.657V62.3156H150.131V40.4233H154.657V49.4609Z"
                    fill="black"
                  />
                  <path
                    d="M146.826 51.3619C146.826 52.9612 146.554 54.4448 146.011 55.8128C145.478 57.1807 144.719 58.3626 143.733 59.3584C142.757 60.3542 141.581 61.1387 140.203 61.7121C138.825 62.2753 137.296 62.557 135.616 62.557C133.936 62.557 132.407 62.2753 131.029 61.7121C129.651 61.1387 128.469 60.3542 127.484 59.3584C126.498 58.3626 125.733 57.1807 125.19 55.8128C124.657 54.4448 124.391 52.9612 124.391 51.3619C124.391 49.7626 124.657 48.284 125.19 46.9261C125.733 45.5581 126.498 44.3763 127.484 43.3805C128.469 42.3847 129.651 41.6052 131.029 41.0419C132.407 40.4686 133.936 40.1819 135.616 40.1819C137.296 40.1819 138.825 40.4686 140.203 41.0419C141.581 41.6052 142.757 42.3897 143.733 43.3956C144.719 44.3914 145.478 45.5732 146.011 46.9412C146.554 48.2991 146.826 49.7726 146.826 51.3619ZM142.194 51.3619C142.194 50.2152 142.043 49.1893 141.741 48.284C141.44 47.3687 141.002 46.5942 140.429 45.9605C139.866 45.3167 139.177 44.8289 138.362 44.497C137.557 44.155 136.642 43.984 135.616 43.984C134.59 43.984 133.67 44.155 132.855 44.497C132.04 44.8289 131.346 45.3167 130.773 45.9605C130.209 46.5942 129.777 47.3687 129.475 48.284C129.173 49.1893 129.023 50.2152 129.023 51.3619C129.023 52.5186 129.173 53.5546 129.475 54.47C129.777 55.3752 130.209 56.1447 130.773 56.7784C131.346 57.4121 132.04 57.8999 132.855 58.2419C133.67 58.5738 134.59 58.7398 135.616 58.7398C136.642 58.7398 137.557 58.5738 138.362 58.2419C139.177 57.8999 139.866 57.4121 140.429 56.7784C141.002 56.1447 141.44 55.3752 141.741 54.47C142.043 53.5546 142.194 52.5186 142.194 51.3619Z"
                    fill="black"
                  />
                  <path
                    d="M122.367 51.3619C122.367 52.9612 122.096 54.4448 121.552 55.8128C121.019 57.1807 120.26 58.3626 119.274 59.3584C118.298 60.3542 117.122 61.1387 115.744 61.7121C114.366 62.2753 112.837 62.557 111.157 62.557C109.477 62.557 107.948 62.2753 106.57 61.7121C105.192 61.1387 104.01 60.3542 103.025 59.3584C102.039 58.3626 101.274 57.1807 100.731 55.8128C100.198 54.4448 99.9316 52.9612 99.9316 51.3619C99.9316 49.7626 100.198 48.284 100.731 46.9261C101.274 45.5581 102.039 44.3763 103.025 43.3805C104.01 42.3847 105.192 41.6052 106.57 41.0419C107.948 40.4686 109.477 40.1819 111.157 40.1819C112.837 40.1819 114.366 40.4686 115.744 41.0419C117.122 41.6052 118.298 42.3897 119.274 43.3956C120.26 44.3914 121.019 45.5732 121.552 46.9412C122.096 48.2991 122.367 49.7726 122.367 51.3619ZM117.735 51.3619C117.735 50.2152 117.584 49.1893 117.283 48.284C116.981 47.3687 116.543 46.5942 115.97 45.9605C115.407 45.3167 114.718 44.8289 113.903 44.497C113.098 44.155 112.183 43.984 111.157 43.984C110.131 43.984 109.211 44.155 108.396 44.497C107.581 44.8289 106.887 45.3167 106.314 45.9605C105.75 46.5942 105.318 47.3687 105.016 48.284C104.714 49.1893 104.564 50.2152 104.564 51.3619C104.564 52.5186 104.714 53.5546 105.016 54.47C105.318 55.3752 105.75 56.1447 106.314 56.7784C106.887 57.4121 107.581 57.8999 108.396 58.2419C109.211 58.5738 110.131 58.7398 111.157 58.7398C112.183 58.7398 113.098 58.5738 113.903 58.2419C114.718 57.8999 115.407 57.4121 115.97 56.7784C116.543 56.1447 116.981 55.3752 117.283 54.47C117.584 53.5546 117.735 52.5186 117.735 51.3619Z"
                    fill="black"
                  />
                  <path
                    d="M89.6089 58.8454C90.3331 58.8454 90.9266 58.76 91.3893 58.589C91.862 58.4079 92.2392 58.1715 92.5209 57.8798C92.8025 57.5881 92.9986 57.2562 93.1093 56.884C93.2199 56.5018 93.2753 56.1095 93.2753 55.7072C93.2753 55.2646 93.2099 54.8673 93.0791 54.5153C92.9484 54.1632 92.7371 53.8665 92.4454 53.6251C92.1537 53.3736 91.7715 53.1825 91.2988 53.0518C90.8361 52.921 90.2627 52.8556 89.5788 52.8556H85.7314V58.8454H89.6089ZM85.7314 43.8633V49.7777H88.7037C89.3374 49.7777 89.9006 49.7274 90.3935 49.6269C90.8864 49.5162 91.2988 49.3452 91.6307 49.1139C91.9626 48.8825 92.2141 48.5808 92.3851 48.2086C92.5661 47.8264 92.6567 47.3687 92.6567 46.8356C92.6567 46.2925 92.5812 45.8298 92.4303 45.4476C92.2895 45.0653 92.0682 44.7585 91.7665 44.5272C91.4647 44.2959 91.0825 44.1299 90.6198 44.0293C90.1571 43.9187 89.6089 43.8633 88.9753 43.8633H85.7314ZM88.9753 40.4233C90.4438 40.4233 91.6961 40.5642 92.7321 40.8458C93.7782 41.1174 94.6281 41.5097 95.2819 42.0226C95.9458 42.5256 96.4286 43.1391 96.7303 43.8633C97.0321 44.5876 97.183 45.3973 97.183 46.2925C97.183 46.7954 97.1075 47.2782 96.9567 47.7409C96.8158 48.2036 96.5895 48.6411 96.2777 49.0535C95.976 49.4559 95.5837 49.823 95.1009 50.1549C94.6181 50.4868 94.0447 50.7685 93.3809 50.9998C94.8695 51.3519 95.9709 51.9252 96.6851 52.7198C97.3992 53.5145 97.7563 54.5454 97.7563 55.8128C97.7563 56.7382 97.5753 57.5982 97.2131 58.3928C96.8611 59.1874 96.3431 59.8764 95.6591 60.4598C94.9751 61.0432 94.1302 61.5009 93.1244 61.8328C92.1286 62.1547 90.9819 62.3156 89.6844 62.3156H81.2051V40.4233H88.9753Z"
                    fill="black"
                  />
                  <defs>
                    <filter
                      id="filter0_d_9072_7489"
                      x="0.908203"
                      y="0"
                      width="331.781"
                      height="102"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_9072_7489"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_9072_7489"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_9072_7489"
                      x1="-24.5"
                      y1="74.5"
                      x2="320"
                      y2="74.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0DBCEC" />
                      <stop offset="1" stop-color="#FFC056" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_9072_7489"
                      x1="119.153"
                      y1="8"
                      x2="119.153"
                      y2="94"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#5CCBEA" />
                      <stop offset="1" stop-color="#EFCE96" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </foreignObject>
        <foreignObject x="60" y="1800" width="961" height="90">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{ fontSize: "45px", color: "#FFFFFF", fontWeight: "600" }}
            className="text-source text-center text-elips one-line"
          >
            {data?.service_type == "virtual"
              ? "Virtual Service"
              : data?.address}
          </div>
        </foreignObject>
      </g>
      <defs>
        <pattern
          id="pattern0_9072_7461"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_9072_7461"
            transform="matrix(0.000248423 0 0 0.000366972 -0.00877036 0)"
          />
        </pattern>
        <pattern
          id="pattern1_9072_7461"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1_9072_7461"
            transform="matrix(0.001362 0 0 0.00149172 -0.0597826 -0.113095)"
          />
        </pattern>
        <filter
          id="filter0_d_9072_7461"
          x="831.043"
          y="38"
          width="242.914"
          height="226"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="22.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9072_7461"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9072_7461"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern2_9072_7461"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image2_9072_7461"
            transform="matrix(0.00178571 0 0 0.00203519 0 -0.0698529)"
          />
        </pattern>
        <filter
          id="filter1_d_9072_7461"
          x="373.908"
          y="1501"
          width="331.781"
          height="102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9072_7461"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9072_7461"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_9072_7461"
          x1="540"
          y1="0"
          x2="540"
          y2="1920"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#013341" stop-opacity="0.72" />
          <stop offset="1" stop-opacity="0.74" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9072_7461"
          x1="929.247"
          y1="83"
          x2="929.247"
          y2="219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CCBEA" />
          <stop offset="1" stop-color="#EFCE96" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9072_7461"
          x1="348.5"
          y1="1575.5"
          x2="693"
          y2="1575.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0DBCEC" />
          <stop offset="1" stop-color="#FFC056" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9072_7461"
          x1="492.153"
          y1="1509"
          x2="492.153"
          y2="1595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5CCBEA" />
          <stop offset="1" stop-color="#EFCE96" />
        </linearGradient>
        <clipPath id="clip0_9072_7461">
          <rect width="1080" height="1920" fill="white" />
        </clipPath>
        <image
          id="image0_9072_7461"
          width="4096"
          height="2725"
          xlinkHref={data?.user_id?.provider_banner_images[0]?.file_name}
          preserveAspectRatio="xMidYMid slice"
        />
        <image
          id="image1_9072_7461"
          width="822"
          height="822"
          xlinkHref={logo}
        />
        <image
          id="image2_9072_7461"
          width="560"
          height="560"
          xlinkHref={data?.user_id?.profile_picture}
          preserveAspectRatio="xMidYMid slice"
        />
      </defs>
    </svg>
  );
};

export default ServiceStoryTemp3;
