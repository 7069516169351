import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Header from "../../Component/Header";
import {Box, Grid} from "@mui/material";
import bannerImg from "../../assets/images/ref-banner.png"
import RefcodeBanner from "./RefcodeBanner";
import {refCodeGenerate, refCodeList} from "../../action/ReffDiscountConstant";
import RefCodeCards from "./RefCodeCards";
import DiscountBanner from "./DiscountBanner";
import DscountCode from "./DscountCode";
import {discountCodeList} from "../../action/discountCodeConstant";
import GenerateCodePopup from "./GenerateCodePopup";
import DiscountCongratulationsPopup from "./DiscountCongratulationsPopup";
import CodeShare from "./CodeShare";

const Code_Discount = () => {

    const [showBanner, setShowBanner] = useState(true);
    const dispatch = useDispatch();
    const [isTabSelect, setIsTabSelect] = useState("InviteFriends");
    const [showBtn, setShowBtn] = useState(0);
    const {refDescount, discountCode} = useSelector((state) => state);
    const [loader, setLoader] = useState(false);
    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    const [showDiscountBanner, setShowDiscountBanner] = useState(true);
    const [is_generate, setIs_generate] = useState(false);
    const [openDiscountSuccsess, setOpenDiscountSucsess] = useState(false)


    const getCodeList = () => {
        dispatch(refCodeList())
    }


    const getDiscountList = () => {
        dispatch(discountCodeList())
    }

    useEffect(() => {
        getCodeList()
        getDiscountList()
    }, []);

    useEffect(() => {
        if (refDescount?.refCodeList?.length > 0) {
            setShowBanner(false)
        }
    }, [refDescount?.refCodeList]);

    useEffect(() => {
        if (discountCode?.discountList?.length > 0) {
            setShowDiscountBanner(false)
        }
    }, [discountCode?.discountList]);

    const moreCodeCheck = () => {

        // let lastElement = refDescount?.refCodeList[0];
        // console.log(lastElement, "lastElement");

        refDescount?.refCodeList?.map((lastElement) => {
            if (lastElement?.is_admin_created == false || lastElement?.is_admin_created !== true) {
                if (new Date(lastElement?.expire_date).toLocaleDateString() <= new Date().toLocaleDateString()) {
                    // setShowBtn(0);
                } else {
                    setShowBtn(showBtn + 1);
                }
            }
        })

    }

    useEffect(() => {
        moreCodeCheck()
    }, [refDescount?.refCodeList]);

    const handleCreateCode = () => {
        setLoader(true)
        dispatch(refCodeGenerate((a) => {
            setShowBanner(a)
        }, () => {
            getCodeList()
        }, () => {
            setLoader(false)
        }))
    }

    return (
        <>
            <section className="main-page">
                <Header select={""}/>
                <div className="main-inner" style={{marginBottom: '10px'}}>
                    <div className="container">
                        {/*.........page section.........*/}

                        {/*<Box>*/}
                        {/*    <h3 style={{fontWeight: "600", marginBottom: "25px"}}>*/}
                        {/*        Transaction History*/}
                        {/*    </h3>*/}
                        {/*</Box>*/}

                        {/*     ===== TABS =====   */}

                        {userAuth?.user_type !== "user" ? <div className="walletTabsdiv">
                            <div
                                className={`wallet_tabs mt-1 ${isTabSelect == "InviteFriends" ? 'wallet_tabs_select' : ''}`}
                                onClick={() => setIsTabSelect("InviteFriends")}><p>Invite Friends</p></div>
                            <div className={`wallet_tabs mt-1 ${isTabSelect == "Discount" ? 'wallet_tabs_select' : ''}`}
                                 onClick={() => setIsTabSelect("Discount")}><p>Discount</p>
                            </div>
                        </div> : null}


                        {isTabSelect == "InviteFriends" ? (showBanner ?
                                <RefcodeBanner handleCreateCode={handleCreateCode} loader={loader}/> :
                                <RefCodeCards data={refDescount?.refCodeList} setShowBanner={setShowBanner}
                                              showBtn={showBtn}/>) : (showDiscountBanner ? <DiscountBanner handleCreateCode={handleCreateCode} setIs_generate={(status) => setIs_generate(status)} loader={loader}/> :
                            <DscountCode data={discountCode?.discountList} setShowBanner={setShowBanner}
                                         showBtn={showBtn} getDiscountList={getDiscountList} openDiscountSuccsess={openDiscountSuccsess} setOpenDiscountSucsess={setOpenDiscountSucsess}/>)}
                    </div>
                </div>
            </section>
            <GenerateCodePopup setOpen={setIs_generate} open={is_generate} is_edit={false} data={{}} setOpenDiscountSucsess={setOpenDiscountSucsess} />

        </>
    )
}

export default Code_Discount