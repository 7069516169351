import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {paymentConstant} from "./constant";
import {toast} from "react-toastify";

export const PayBooking = (data, getNotificationsList) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("booking_id", data?.booking_id);
        fdata.append("total_amount", data?.total_amount);
        fdata.append("total_paid_amount", data?.total_paid_amount);
        fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
        fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
        fdata.append("fees_percentage_from_provider", data?.fees_percentage_from_provider);
        fdata.append("fees_amount_from_provider", data?.fees_amount_from_provider);
        fdata.append("total_transfered_amount", data?.total_transfered_amount);
        fdata.append("stripe_charge_id", data?.stripe_charge_id);
        fdata.append("stripe_payment_intent_id", data?.stripe_payment_intent_id);
        fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
        fdata.append("sales_tax_amount_from_user", data?.sales_tax_amount_from_user);
        fdata.append("address", data?.address);
        fdata.append("state", data?.state);
        fdata.append("payment_date", data?.payment_date);
        fdata.append("current_timezone", data?.current_timezone);
        fdata.append("country", data?.country);
        fdata.append("location", JSON.stringify(data?.location));
        fdata.append("referral_code_info", JSON.stringify(data?.referral_code_info));
        fdata.append("discount_code_info", JSON.stringify(data?.discount_code_info));
        if(data?.discount_code_id){

            fdata.append("discount_code_id", data?.discount_code_id);
        }
        fdata.append("discount_amount", data?.discount_amount);
        fdata.append("discount_percentage", data?.discount_percentage);
        if (data?.referral_discount_percentage_user && data?.referral_discount_amount_user) {
            fdata.append("referral_discount_amount_user", data?.referral_discount_amount_user);
            fdata.append("referral_discount_percentage_user", data?.referral_discount_percentage_user);
            // fdata.append("referral_code_id", data?.referral_code_id);
        }
        if (data?.referer_referral_code_id){
            fdata.append("referer_referral_code_id", data?.referer_referral_code_id);

        }
        if (data?.referree_referral_code_id){
            fdata.append("referree_referral_code_id", data?.referree_referral_code_id);

        }
        if (data?.sales_tax_id) {
            fdata.append("sales_tax_id", data?.sales_tax_id);
        }
        fdata.append("notification_id", data?.notification_id);


        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.pay_booking}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: paymentConstant.PAY_BOOKING,
                payload: response.data,
            });
            toast.success(response?.message)
            getNotificationsList()
        } else {
            toast.error(response?.message);
        }
    };
};