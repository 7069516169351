import React from 'react';
import shape from "../../assets/images/Vector 10.png";
import img1 from "../../assets/images/layout1.jpg"
import aroow1 from "../../assets/images/arrow3.svg";
import aroow2 from "../../assets/images/arrow2.svg";
import arrow3 from "../../assets/images/arrow1.svg";
import arrow4 from "../../assets/images/arrow6.svg";
import arrow5 from "../../assets/images/arrow4.svg";
import arrow6 from "../../assets/images/arrow5.svg";
import arrow7 from "../../assets/images/arrow7.svg";
import arrow8 from "../../assets/images/arrow8.svg";


const ServiceLayout = ({image, activities, onTextClick}) => {
    return (


        <>
            {/*...............6...........*/}
            {activities.length == 6 && <div className="service-layout-scale service-layout-scale-6">

                <div className="d-flex align-items-center justify-content-center">
                    <div className="t-polygon-imgae me-5">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[0])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[0]}</p>
                            </div>
                        </div>
                    </div>
                    <div className="t-polygon-imgae ms-5">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[1])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[1]}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[2])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[2]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="left-arrow-Container">
                        <div>
                            <img src={aroow2} alt=""/>
                        </div>
                        <div>
                            <img src={aroow1} alt=""/>
                        </div>
                        <div>
                            <img src={arrow3} alt=""/>
                        </div>
                    </div>

                    <div className="polygon-imgae">
                        <div className="p_image_shape">
                            <img
                                src={image}
                            />
                        </div>
                    </div>


                    <div className="right-arrow-Container">
                        <div>
                            <img src={arrow5} alt=""/>
                        </div>
                        <div>
                            <img src={arrow4} alt=""/>
                        </div>
                        <div>
                            <img src={arrow6} alt=""/>
                        </div>
                    </div>

                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[3])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[3]}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="d-flex align-items-center justify-content-center">
                    <div className="t-polygon-imgae me-5">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[4])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[4]}</p>
                            </div>
                        </div>
                    </div>
                    <div className="t-polygon-imgae ms-5">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[5])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[5]}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>}


            {/*    ............5................*/}


            {activities.length == 5 && <div className="service-layout-scale">

                <div className="d-flex align-items-center justify-content-center">
                    <div className="t-polygon-imgae me-5">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[0])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[0]}</p>
                            </div>
                        </div>
                    </div>
                    <div className="t-polygon-imgae ms-5">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[1])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[1]}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-start">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[2])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[2]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="left-arrow-Container">
                        <div>
                            <img src={aroow2} alt=""/>
                        </div>
                        <div>
                            <img src={aroow1} alt=""/>
                        </div>
                        <div>
                            {/*<img src={arrow3} alt=""/>*/}
                        </div>
                    </div>

                    <div className="polygon-imgae">
                        <div className="p_image_shape">
                            <img
                                src={image}
                            />
                        </div>
                    </div>


                    <div className="right-arrow-Container">
                        <div>
                            <img src={arrow5} alt=""/>
                        </div>
                        <div>
                            <img src={arrow4} alt=""/>
                        </div>
                        <div>
                            {/*<img src={arrow6} alt=""/>*/}
                        </div>
                    </div>

                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[3])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[3]}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <img src={arrow8} alt=""/>
                </div>

                <div className="d-flex justify-content-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[4])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[4]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}


            {/* ......4.......*/}

            {activities.length == 4 && <div className="service-layout-scale service-layout-scale-4">
                <div className="d-flex justify-content-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[0])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[0]}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <img src={arrow7} alt=""/>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[1])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[1]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="left-arrow-Container">
                        <div>
                            <img src={aroow1} alt=""/>
                        </div>
                    </div>

                    <div className="polygon-imgae">
                        <div className="p_image_shape">
                            <img
                                src={image}
                            />
                        </div>
                    </div>
                    <div className="right-arrow-Container">
                        <div>
                            <img src={arrow4} alt=""/>
                        </div>
                    </div>

                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[2])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[2]}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <img src={arrow8} alt=""/>
                </div>

                <div className="d-flex justify-content-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[3])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[3]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}


            {/*    ..............3........*/}

            {activities.length == 3 && <div className="service-layout-scale">
                <div className="d-flex justify-content-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[0])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[0]}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <img src={arrow7} alt=""/>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[1])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[1]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="left-arrow-Container">
                        <div>
                            <img src={aroow1} alt=""/>
                        </div>
                    </div>

                    <div className="polygon-imgae">
                        <div className="p_image_shape">
                            <img
                                src={image}
                            />
                        </div>
                    </div>
                    <div className="right-arrow-Container">
                        <div>
                            <img src={arrow4} alt=""/>
                        </div>
                    </div>

                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[2])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[2]}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>}


            {/*......2.......*/}

            {activities.length == 2 && <div className="service-layout-scale">

                <div className="d-flex justify-content-center align-items-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[0])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[0]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="left-arrow-Container">
                        <div>
                            <img src={aroow1} alt=""/>
                        </div>
                    </div>

                    <div className="polygon-imgae">
                        <div className="p_image_shape">
                            <img
                                src={image}
                            />
                        </div>
                    </div>
                    <div className="right-arrow-Container">
                        <div>
                            <img src={arrow4} alt=""/>
                        </div>
                    </div>

                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[1])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[1]}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>}


            {/*    ..........1........  */}

            {activities.length == 1 && <div className="service-layout-scale service-layout-scale1">
                <div className="d-flex justify-content-center">
                    <div className="t-polygon-imgae">
                        <div className="t_image_shape">
                            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                <p className="t-polygon-text three-line" onClick={() => {
                                    onTextClick(activities[0])
                                }} style={{
                                    wordBreak: "break-word",
                                    clipPath: "none",
                                    height: 'auto',
                                    display: "-webkit-box",
                                    padding: '5px'
                                }}>{activities[0]}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <img src={arrow7} alt=""/>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <div className="polygon-imgae">
                        <div className="p_image_shape">
                            <img
                                src={image}
                            />
                        </div>
                    </div>

                </div>

            </div>}
        </>
    )
}

export default ServiceLayout
