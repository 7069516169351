import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";
import { myEventConstant } from "./constant";
import { toast } from "react-toastify";
import { IsoFormate } from "../Utils/DateFormateUtils";

export const getEvents = (data, setLoader) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append(
      "user_id",
      JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
    );
    fdata.append("page", data?.page);
    fdata.append("limit", data?.limit);
    fdata.append("search_data", data?.isTabSelect);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.event_listing}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.GET_MY_EVENT,
        payload: response.data,
      });
      setLoader(false);
    } else {
      toast.error(response?.message);
      setLoader(false);
    }
  };
};

export const getMoreEvents = (data, setHasMore) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append(
      "user_id",
      JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
    );
    fdata.append("page", data?.page);
    fdata.append("limit", data?.limit);
    fdata.append("search_data", data?.isTabSelect);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.event_listing}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.FATCH_MORE_MY_EVENT,
        payload: response.data,
      });
      if (response.data.length == 0) {
        setHasMore(false);
      }
    } else {
      toast.error(response?.message);
    }
  };
};

export const getEventById = (id) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("event_id", id);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.event_details}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.GET_EVENT_BY_ID,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
};

export const getEventProviders = (data, setHasMore) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("page", data?.page);
    fdata.append("limit", data?.limit);
    fdata.append("event_id", data?.id);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.event_provider_list}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.GET_EVENT_PROVIDERS,
        payload: response.data,
      });
      if (response.data.length == 0) {
        setHasMore(false);
      }
    } else {
      toast.error(response?.message);
    }
  };
};
export const getMoreEventProviders = (data) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("page", data?.page);
    fdata.append("limit", data?.limit);
    fdata.append("event_id", data?.id);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.event_provider_list}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.GET_MORE_EVENT_PROVIDERS,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
};

export const getEventParticipants = (data, setLoader) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("page", data?.page);
    fdata.append("limit", data?.limit);
    fdata.append("event_id", data?.id);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.event_participant_list}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.GET_EVENT_PARTICIPANTS,
        payload: response.data,
      });
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(response?.message);
    }
  };
};

export const getMoreEventParticipants = (data) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("page", data?.page);
    fdata.append("limit", data?.limit);
    fdata.append("event_id", data?.id);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.event_participant_list}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.GET_MORE_EVENT_PARTICIPANTS,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
};
export const deleteEvent = (id, navigate) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("event_id", id);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.cancel_event}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.DELETE_EVENT,
        payload: response.data,
      });
      navigate();
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
};
export const cancelRagister = (id) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("event_id", id);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.close_registration}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.CANCEL_RAGISTER,
        payload: response.data,
      });
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
};
export const uploadPackageImage = (
  payload,
  handleClose,
  setLoader,
  setDetails
) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    if (payload?.image_file?.length) {
      for (let i = 0; i < payload?.image_file?.length; i++) {
        fdata.append("image_file", payload?.image_file[i]);
      }
    }
    // payload?.image_file?.map((ele) => {
    //    return  fdata.append("image_file", JSON.stringify(ele));
    // })
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.upload_package_images}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      // dispatch({
      //     type: myEventConstant?.UPLOAD_PACKAGE_IMAGE,
      //     payload: response.data,
      // });

      setDetails(response.data);
      handleClose();
      setLoader(false);
      // toast.success(response?.message);
    } else {
      setLoader(false);
      toast.error(response?.message);
    }
  };
};
export const createEvent = (
  payload,
  navigate,
  setLoader,
  handleCliearData,
  setIsLinkPopup,
  preview,
  setPreviewPopup,
  setLoaderPre
) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();

    fdata.append("event_name", payload?.event_name);
    if (payload?.address) {
      fdata.append("address", payload?.address);
    }
    fdata.append("start_date", IsoFormate(payload?.start_date));
    fdata.append("end_date", IsoFormate(payload?.end_date));
    fdata.append("start_time", IsoFormate(payload?.start_time));
    fdata.append("end_time", IsoFormate(payload?.end_time));
    fdata.append("about", payload?.about);
    fdata.append("activities", JSON?.stringify(payload?.activities));
    if (payload?.website_link) {
      fdata.append("website_link", payload?.website_link);
    }
    fdata.append("event_type", payload?.event_type);
    fdata.append("need_provider", payload?.need_provider);
    fdata.append(
      "is_accomodations_provided",
      payload?.is_accomodations_provided
    );
    fdata.append("is_duplicate", payload?.is_duplicate);
    fdata.append("is_virtual_event", payload?.is_virtual_event);
    fdata.append("is_publish", payload?.is_publish);
    fdata.append("current_timezone", payload?.current_timezone);
    if (payload?.parent_event_id) {
      fdata.append("parent_event_id", payload?.parent_event_id);
    }
    if (payload?.duplicate_event_file) {
      fdata.append(
        "duplicate_event_file",
        JSON.stringify(payload?.duplicate_event_file)
      );
    }
    // if (payload?.event_id !== null) {
    //     fdata.append("event_id", payload?.event_id);
    // }
    if (payload?.location) {
      fdata.append("location", JSON?.stringify(payload?.location));
    }
    if (payload?.service_details?.length) {
      fdata.append(
        "service_details",
        JSON?.stringify(payload?.service_details)
      );
    }
    if (payload?.add_provider) {
      fdata.append("add_provider", JSON?.stringify(payload?.add_provider));
    }
    if (payload?.package_details?.length) {
      fdata.append(
        "package_details",
        JSON?.stringify(payload?.package_details)
      );
    }

    if (payload?.event_media?.length > 0) {
      for (let i = 0; i < payload?.event_media?.length; i++) {
        fdata.append("event_media", payload?.event_media[i]);
      }
    }
    // payload?.image_file?.map((ele) => {
    //    return  fdata.append("image_file", JSON.stringify(ele));
    // })
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.create_event}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.CREATE_EVENT,
        payload: response.data,
      });
      localStorage.removeItem("meetingLink");
      localStorage.removeItem("event_draft");
      localStorage.removeItem("event_location_draft");
      navigate(response.data);
      if (!preview) {
        setLoader(false);
        setIsLinkPopup(true);
        // handleCliearData()
        toast.success(response?.message);
      } else {
        setLoaderPre(false);
        setPreviewPopup(true);
      }
    } else {
      setLoaderPre(false);
      setLoader(false);
      toast.error(response?.message);
    }
  };
};

export const createDiscountEvent = (
  payload,
  navigate,
  setLoader,
  handleCliearData,
  setIsLinkPopup,
  preview,
  setPreviewPopup,
  setLoaderPre,
  handleGenerateCode
) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();

    fdata.append("event_name", payload?.event_name);
    if (payload?.address) {
      fdata.append("address", payload?.address);
    }
    fdata.append("start_date", IsoFormate(payload?.start_date));
    fdata.append("end_date", IsoFormate(payload?.end_date));
    fdata.append("start_time", IsoFormate(payload?.start_time));
    fdata.append("end_time", IsoFormate(payload?.end_time));
    fdata.append("about", payload?.about);
    fdata.append("activities", JSON?.stringify(payload?.activities));
    if (payload?.website_link) {
      fdata.append("website_link", payload?.website_link);
    }
    fdata.append("event_type", payload?.event_type);
    fdata.append("need_provider", payload?.need_provider);
    fdata.append(
      "is_accomodations_provided",
      payload?.is_accomodations_provided
    );
    fdata.append("is_duplicate", payload?.is_duplicate);
    fdata.append("is_virtual_event", payload?.is_virtual_event);
    fdata.append("is_publish", payload?.is_publish);
    fdata.append("current_timezone", payload?.current_timezone);
    if (payload?.parent_event_id) {
      fdata.append("parent_event_id", payload?.parent_event_id);
    }
    if (payload?.duplicate_event_file) {
      fdata.append(
        "duplicate_event_file",
        JSON.stringify(payload?.duplicate_event_file)
      );
    }
    // if (payload?.event_id !== null) {
    //     fdata.append("event_id", payload?.event_id);
    // }
    if (payload?.location) {
      fdata.append("location", JSON?.stringify(payload?.location));
    }
    if (payload?.service_details?.length) {
      fdata.append(
        "service_details",
        JSON?.stringify(payload?.service_details)
      );
    }
    if (payload?.add_provider) {
      fdata.append("add_provider", JSON?.stringify(payload?.add_provider));
    }
    if (payload?.package_details?.length) {
      fdata.append(
        "package_details",
        JSON?.stringify(payload?.package_details)
      );
    }

    if (payload?.event_media?.length > 0) {
      for (let i = 0; i < payload?.event_media?.length; i++) {
        fdata.append("event_media", payload?.event_media[i]);
      }
    }
    // payload?.image_file?.map((ele) => {
    //    return  fdata.append("image_file", JSON.stringify(ele));
    // })
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.create_event}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      handleGenerateCode(response.data);
      dispatch({
        type: myEventConstant?.CREATE_EVENT,
        payload: response.data,
      });
      localStorage.removeItem("event_draft");
      localStorage.removeItem("meetingLink");
      localStorage.removeItem("event_location_draft");
      // handleGenerateCode(response.data)
      // navigate()
      if (!preview) {
        setLoader(false);
        setIsLinkPopup(true);
        // handleCliearData()
        toast.success(response?.message);
      } else {
        setLoaderPre(false);
        setPreviewPopup(true);
      }
    } else {
      setLoaderPre(false);
      setLoader(false);
      toast.error(response?.message);
    }
  };
};

export const editEvent = (
  payload,
  navigate,
  setLoader,
  handleCliearData,
  preview,
  setPreviewPopup,
  setLoaderPre
) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();

    fdata.append("event_id", payload?.event_id);
    fdata.append("event_name", payload?.event_name);
    if (payload?.address) {
      fdata.append("address", payload?.address);
    }
    fdata.append("start_date", IsoFormate(new Date(payload?.start_date)));
    fdata.append("end_date", IsoFormate(new Date(payload?.end_date)));
    fdata.append("start_time", IsoFormate(new Date(payload?.start_time)));
    fdata.append("end_time", IsoFormate(new Date(payload?.end_time)));
    fdata.append("about", payload?.about);
    fdata.append("activities", JSON?.stringify(payload?.activities));
    if (payload?.website_link) {
      fdata.append("website_link", payload?.website_link);
    }
    fdata.append("event_type", payload?.event_type);
    fdata.append("need_provider", payload?.need_provider);
    fdata.append(
      "is_accomodations_provided",
      payload?.is_accomodations_provided
    );
    fdata.append("is_duplicate", payload?.is_duplicate);
    fdata.append("is_virtual_event", payload?.is_virtual_event);
    fdata.append("is_publish", payload?.is_publish);
    fdata.append("current_timezone", payload?.current_timezone);
    if (payload?.location) {
      fdata.append("location", JSON?.stringify(payload?.location));
    }
    if (payload?.service_details?.length) {
      fdata.append(
        "service_details",
        JSON?.stringify(payload?.service_details)
      );
    }
    if (payload?.add_provider?.length) {
      fdata.append("add_provider", JSON?.stringify(payload?.add_provider));
    }
    if (payload?.package_details?.length) {
      fdata.append(
        "package_details",
        JSON?.stringify(payload?.package_details)
      );
    }
    if (payload?.delete_event_image_ids?.length) {
      fdata.append(
        "delete_event_image_ids",
        JSON?.stringify(payload?.delete_event_image_ids)
      );
    }

    if (payload?.delete_package_image_ids?.length) {
      fdata.append(
        "delete_package_image_ids",
        JSON?.stringify(payload?.delete_package_image_ids)
      );
    }

    if (payload?.event_join_link) {
      fdata.append("event_join_link", payload?.event_join_link);
    }

    if (payload?.event_media?.length > 0) {
      for (let i = 0; i < payload?.event_media?.length; i++) {
        fdata.append("event_media", payload?.event_media[i]);
      }
    }
    // payload?.image_file?.map((ele) => {
    //    return  fdata.append("image_file", JSON.stringify(ele));
    // })
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.edit_event}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.EDIT_EVENT,
        payload: response.data,
      });
      localStorage.removeItem("event_draft");
      localStorage.removeItem("meetingLink");
      localStorage.removeItem("event_location_draft");
      navigate(response.data);
      // setIsLinkPopup(true)
      if (!preview) {
        setLoader(false);
        handleCliearData();
        toast.success(response?.message);
      } else {
        setLoaderPre(false);
        setPreviewPopup(true);
      }
    } else {
      setLoaderPre(false);
      setLoader(false);
      toast.error(response?.message);
    }
  };
};

export const editDiscountEvent = (
  payload,
  navigate,
  setLoader,
  handleCliearData,
  preview,
  setPreviewPopup,
  setLoaderPre,
  handleGenerateCode
) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();

    fdata.append("event_id", payload?.event_id);
    fdata.append("event_name", payload?.event_name);
    if (payload?.address) {
      fdata.append("address", payload?.address);
    }
    fdata.append("start_date", IsoFormate(new Date(payload?.start_date)));
    fdata.append("end_date", IsoFormate(new Date(payload?.end_date)));
    fdata.append("start_time", IsoFormate(new Date(payload?.start_time)));
    fdata.append("end_time", IsoFormate(new Date(payload?.end_time)));
    fdata.append("about", payload?.about);
    fdata.append("activities", JSON?.stringify(payload?.activities));
    if (payload?.website_link) {
      fdata.append("website_link", payload?.website_link);
    }
    fdata.append("event_type", payload?.event_type);
    fdata.append("need_provider", payload?.need_provider);
    fdata.append(
      "is_accomodations_provided",
      payload?.is_accomodations_provided
    );
    fdata.append("is_duplicate", payload?.is_duplicate);
    fdata.append("is_virtual_event", payload?.is_virtual_event);
    fdata.append("is_publish", payload?.is_publish);
    fdata.append("current_timezone", payload?.current_timezone);
    if (payload?.location) {
      fdata.append("location", JSON?.stringify(payload?.location));
    }
    if (payload?.service_details?.length) {
      fdata.append(
        "service_details",
        JSON?.stringify(payload?.service_details)
      );
    }
    if (payload?.add_provider?.length) {
      fdata.append("add_provider", JSON?.stringify(payload?.add_provider));
    }
    if (payload?.package_details?.length) {
      fdata.append(
        "package_details",
        JSON?.stringify(payload?.package_details)
      );
    }
    if (payload?.delete_event_image_ids?.length) {
      fdata.append(
        "delete_event_image_ids",
        JSON?.stringify(payload?.delete_event_image_ids)
      );
    }

    if (payload?.event_join_link) {
      fdata.append("event_join_link", payload?.event_join_link);
    }

    if (payload?.event_media?.length > 0) {
      for (let i = 0; i < payload?.event_media?.length; i++) {
        fdata.append("event_media", payload?.event_media[i]);
      }
    }
    // payload?.image_file?.map((ele) => {
    //    return  fdata.append("image_file", JSON.stringify(ele));
    // })
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.edit_event}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: myEventConstant?.EDIT_EVENT,
        payload: response.data,
      });
      navigate();
      handleGenerateCode(response.data);
      localStorage.removeItem("event_draft");
      localStorage.removeItem("meetingLink");
      localStorage.removeItem("event_location_draft");

      // setIsLinkPopup(true)
      if (!preview) {
        setLoader(false);
        handleCliearData();
        toast.success(response?.message);
      } else {
        setLoaderPre(false);
        setPreviewPopup(true);
      }
    } else {
      setLoaderPre(false);
      setLoader(false);
      toast.error(response?.message);
    }
  };
};
