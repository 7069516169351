import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import profileImg from "../../assets/images/layout1.jpg";
import moment from "moment/moment";
import HireCollaborateModal from "./InfoPopup/HireCollaborateModal";
import { useDispatch, useSelector } from "react-redux";
import { getOtherProvider } from "../../action/otherProviderConstant";
import whiteBtn from "../../assets/images/cenacleBtn.svg";
import {
  acceptHireCollaNoti,
  acceptHireCollaNotiModify,
  calcleHireCollaNoti,
  calcleHireCollaNotiModify,
} from "../../action/hireNcollaborateConstant";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import deleteIcon from "../../assets/images/deleteAccIcon.svg";
import userIcon from "../../assets/images/userIconWithBg.png";

const HireCollabrote = ({
  data,
  formatBadge,
  getNotificationsList,
  getReviewTime,
  seenNotification,
  handleDeleteNotification,
  handleClose,
}) => {
  const [openModal, setOpenModal] = useState({ event: false });
  const userDetails = useSelector((state) => state?.otherProviderDetails?.data);
  const [foundMassage, setFoundMassage] = useState("");
  const dispatch = useDispatch();
  const [removeModal, setRemoveModal] = useState(false);
  const getOtherProviders = () => {
    dispatch(
      getOtherProvider(data?.sender_user?._id, null, (a) => {
        setFoundMassage(a);
      })
    ).then(() => {
      foundModal();
    });
    foundModal();
  };

  const foundModal = () => {
    if (foundMassage == "Couldn't found user") {
      setOpenModal((pre) => ({ ...pre, event: false }));
      setRemoveModal(true);
    }
  };

  useEffect(() => {
    foundModal();
  }, [foundMassage]);

  const handleNavigate = (event) => {
    const clickedElement = event.target;
    if (
      event?.target?.classList?.contains("btnn") ||
      clickedElement.classList.contains("btnn")
    ) {
      return;
    } else {
      setOpenModal((pre) => ({ ...pre, event: true }));
      getOtherProviders();
      seenNotification();
    }
  };

  const handleAccept = () => {
    const payload = {
      notification_id: data?._id,
    };
    dispatch(
      acceptHireCollaNoti(
        payload,
        () => {},
        () => getNotificationsList()
      )
    );
  };

  const handleAcceptModiRequest = () => {
    const payload = {
      notification_id: data?._id,
      hire_and_collaborate_id: data?.hire_and_collaborate_id?._id,
    };
    dispatch(
      acceptHireCollaNotiModify(
        payload,
        () => {},
        () => {
          getNotificationsList();
        }
      )
    );
  };

  const handleCancelParticipents = () => {
    const payload = {
      notification_id: data?._id,
    };
    dispatch(
      calcleHireCollaNoti(
        payload,
        () => {},
        () => {
          getNotificationsList();
        },
        () => {}
      )
    );
  };

  const handleCancleRequest = () => {
    const payload = {
      notification_id: data?._id,
    };
    dispatch(
      calcleHireCollaNoti(
        payload,
        () => {},
        () => {
          getNotificationsList();
        },
        () => {}
      )
    );
  };

  return (
    <>
      <div
        style={{
          background: data?.is_seen ? "white" : "#E6F7FF",
          cursor: "pointer",
        }}
        onClick={(event) => {
          handleNavigate(event);
        }}
      >
        <div className=" notification-main-div position-relative d-flex align-items-start justify-content-between p-2">
          <div className="d-flex align-items-start w-100">
            <div className="d-flex align-items-center">
              <FiberManualRecordIcon
                style={{
                  width: "13px",
                  color: data?.is_seen ? "transparent" : "#60CE74",
                }}
              />
              <div
                className="p_image_shape me-2 me-sm-0"
                style={{ transform: "scale(0.6)", marginLeft: "-15px" }}
              >
                <img
                  src={data?.sender_user?.profile_picture || userIcon}
                  onError={(e) => (e.target.src = userIcon)}
                />
              </div>
            </div>
            <div className="w-100">
              <p
                className="mb-0 t-13-800 mt-0 twoLineElips"
                style={{ wordBreak: "break-word" }}
              >
                {data?.noti_msg
                  .replace("user_name", data?.sender_user?.name)
                  ?.replace("login_", "")
                  .replace("service_name", data?.service_name ?? "")
                  ?.replace("event_name", data?.event_name ?? "")
                  .replace("provider_name", data?.provider_name ?? "")}
              </p>

              <div className="w-100 d-flex justify-content-between">
                <div>
                  <p className="mb-0 t-12-bold mt-0" style={{ width: "80%" }}>
                    {data?.sender_user?.name}
                  </p>
                  <div className="d-flex align-items-center g-2">
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("MMMM D YYYY")}
                    </p>
                    <FiberManualRecordIcon
                      style={{ transform: "scale(0.4)", color: "#555555" }}
                    />
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("LT")}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    maxWidth:
                      formatBadge(
                        data?.noti_for,
                        data?.booking_type == "service" ? false : true
                      ) === "Hiring"
                        ? "87px"
                        : "none",
                    width:
                      formatBadge(
                        data?.noti_for,
                        data?.booking_type == "service" ? false : true
                      ) === "Hiring"
                        ? "100%"
                        : "auto",
                  }}
                >
                  <p
                    className="t-10-bold m-0"
                    style={{
                      color: "#2F8A40",
                      textAlign: "end",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {getReviewTime(data?.createdAt)}
                  </p>
                  <p
                    className="t-11-bold mt-1 d-flex align-items-center justify-content-center m-0"
                    style={{
                      borderRadius: "3px",
                      background: "#00ADFF",
                      padding: "2px 4px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formatBadge(
                      data?.noti_for,
                      data?.booking_type == "service" ? false : true
                    )}
                  </p>
                </div>
              </div>

              {
                // (formatBadge(data?.noti_for, false) == "Request Declined" && (data?.isAccepted != null) && formatBadge(data?.noti_for, false) == "Request Accepted" && formatBadge(data?.noti_for, false) == "Accept Request" && formatBadge(data?.noti_for, false) == "Decline Request") &&
                data?.is_accepted == null &&
                  formatBadge(data?.noti_for, false) !== "Request Declined" &&
                  formatBadge(data?.noti_for, false) !== "Request Accepted" &&
                  formatBadge(data?.noti_for, false) !== "Accept Request" &&
                  formatBadge(data?.noti_for, false) !== "Decline Request" &&
                  (formatBadge(data?.noti_for, false) == "Collaborate" ? (
                    <div className="col-6 ">
                      <button
                        type="submit"
                        className="btn_admina delete-button"
                        onClick={(event) => {
                          getOtherProviders();
                          handleNavigate(event);
                        }}
                        style={{
                          transform: "scale(0.7)",
                          marginLeft: "-25px",
                          width: "144px",
                          backgroundSize: "142px 65px",
                        }}
                      >
                        Review
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-start row g-1">
                      <div className="col-6 " style={{width : '100px'}}>
                        <button
                          type="submit"
                          className="btn_admina delete-button btnn"
                          onClick={() => {
                            if (data?.noti_for == "modify_amount_request") {
                              handleAcceptModiRequest();
                            } else {
                              handleAccept();
                            }
                          }}
                          style={{
                            transform: "scale(0.7)",
                            marginLeft: "-25px",
                            width: "160px",
                            backgroundSize: "142px 65px",
                          }}
                        >
                          Accept
                        </button>
                      </div>
                      <div className="col-6 " style={{width : '100px'}}>
                        <button
                          type="submit"
                          className="btn_admina delete-confirm-button btnn"
                          onClick={() => {
                            if (data?.noti_for == "modify_amount_request") {
                              handleCancelParticipents();
                            } else {
                              handleCancleRequest();
                            }
                          }}
                          style={{
                            transform: "scale(0.7)",
                            marginLeft: "-25px",
                            width: "160px",
                            backgroundSize: "142px 65px",
                            // backgroundImage: `${whiteBtn}`
                          }}
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                  ))
              }
              {/*<div className="d-flex justify-content-start">*/}
              {/*        <div className="col-12">*/}
              {/*    */}
              {/*    </div>*/}
              {/*</div>*/}
            </div>
          </div>

          <div
            className="position-absolute delete_notification"
            style={{ left: "0", top: "0" }}
          >
            <button className="btnn">
              <Tooltip placement="top" title={"Clear"}>
                <IconButton
                  style={{ padding: "3px 5px" }}
                  className="btnn"
                  onClick={() => {
                    handleDeleteNotification();
                  }}
                >
                  {/*<CloseIcon style={{width: '15px'}}/>*/}
                  <img
                    src={deleteIcon}
                    alt=""
                    className="btnn"
                    style={{ width: "24px" }}
                  />
                </IconButton>
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
      {openModal ? (
        <HireCollaborateModal
          getNotificationsList={getNotificationsList}
          handleClose={handleClose}
          open={openModal}
          setOpen={setOpenModal}
          userDetails={userDetails}
          data={data}
          formatBadge={formatBadge}
        />
      ) : null}
    </>
  );
};

export default HireCollabrote;
