import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {paymentHistoryConstant} from "./constant";
import {toast} from "react-toastify";

export const getPaymentHistory = (data, setDataLoader) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("list_type", data?.list_type);
        fdata.append("page", data?.page);
        fdata.append("limit", data?.limit);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.payment_history}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: paymentHistoryConstant.PAYMENT_HISTORY,
                payload: response.data,
            });
            setDataLoader(false)
        } else {
            setDataLoader(false)
            toast.error(response?.message);
        }
    };
};
export const fatchMoreHistoryData = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("list_type", data?.list_type);
        fdata.append("page", data?.page);
        fdata.append("limit", data?.limit);
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.payment_history}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: paymentHistoryConstant.FETCH_MORE_HISTORY,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};