import {
  APP_VERSION,
  BASE_URL,
  URL_PATH,
  GOOGLE_CLIENT_ID,
  ENVIROMENT,
} from "../Configration/configration";
import { myPageConstant, scheduleConstant } from "./constant";
import { toast } from "react-toastify";
import { IsoFormate } from "../Utils/DateFormateUtils";
import { gapi } from "gapi-script";
// import { apiCalendar } from "../App.js";

export const getScheduleList = (data) => {
  return async (dispatch) => {
    let response;
    const fdata = new FormData();
    fdata.append("schedule_date", data?.schedule_date);
    fdata.append("user_id", data?.user_id);
    if (data?.schedule_end_date) {
      fdata.append("schedule_end_date", data?.schedule_end_date);
    }
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.schedule_list}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: scheduleConstant.SCHEDULE_LIST,
        payload: response.data,
      });
    } else {
      toast.error(response?.message);
    }
  };
};
export const deleteSchedule = (schedule, want_action_for_all) => {
  return async (dispatch) => {
    const auth2 = gapi?.auth2?.getAuthInstance();
    let response;
    const fdata = new FormData();
    fdata.append("schedule_id", schedule?._id);
    fdata.append("want_to_apply_all_recurrent_schedules", want_action_for_all);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.delete_schedule}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      dispatch({
        type: scheduleConstant.DLELETE_SCHEDULE,
        payload: { data: response.data, status: response?.success },
      });
      toast.success(response?.message);
      response?.data?.map((apiResData) => {
        if (
          apiResData?.google_calender_id &&
          apiResData?.google_calender_email &&
          apiResData?.google_calender_email ==
            auth2?.currentUser?.get()?.getBasicProfile()?.getEmail()
        ) {
          const request = gapi.client.calendar.events.delete({
            calendarId: "primary",
            eventId: apiResData?.google_calender_id,
          });

          request.execute(function (eventData) {});
        }
      });
    } else {
      toast.error(response?.message);
    }
  };
};
export const addSchedule = (
  data,
  setOpen,
  getMyScedule,
  getAllScheduled,
  handleCancel
) => {
  return async (dispatch) => {
    const auth2 = gapi?.auth2?.getAuthInstance();
    let response;
    const fdata = new FormData();
    fdata.append("schedule_start_date", IsoFormate(data?.schedule_start_date));
    fdata.append("schedule_end_date", IsoFormate(data?.schedule_end_date));
    fdata.append("service_id", JSON.stringify(data?.service_id));
    fdata.append("location", JSON.stringify(data?.location));
    fdata.append("address", data?.address);
    fdata.append("current_timezone", data?.current_timezone);
    if (data?.is_recurrent_schedule) {
      fdata.append("is_recurrent_schedule", data?.is_recurrent_schedule);
      fdata.append("recurrent_schedule_type", data?.recurrent_schedule_type);
      fdata.append(
        "recurrent_schedule_end_date",
        IsoFormate(data?.recurrent_schedule_end_date)
      );
    }
    // fdata.append("schedule_id", data?.schedule_id);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.add_my_schedule}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      setOpen(false);
      dispatch({
        type: scheduleConstant.Add_SCHEDULE,
        payload: { data: response.data, status: response?.success },
      });

      if (auth2?.isSignedIn?.get()) {
        response?.data?.map(async (Sdata) => {
          const event = {
            summary: data?.services
              ?.filter((service) => data?.service_id?.includes(service._id))
              .map((service) => service.service_name)
              .join(", "),
            start: {
              dateTime: Sdata?.schedule_start_date,
              timeZone: data?.current_timezone,
            },
            end: {
              dateTime: Sdata?.schedule_end_date,
              timeZone: data?.current_timezone,
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event,
          });

          await request.execute(function (eventData) {
            const payload = {
              service_id: Sdata?.service_id,
              schedule_start_date: Sdata?.schedule_start_date,
              schedule_end_date: Sdata?.schedule_end_date,
              location: Sdata?.location,
              address: Sdata?.address,
              schedule_id: Sdata?._id,
              current_timezone: Sdata?.date_timezone,
              services: data?.services,
              google_calender_id: eventData?.id,
              google_calender_email: auth2?.currentUser
                ?.get()
                ?.getBasicProfile()
                ?.getEmail(),
              want_action_for_all: false,
            };

            dispatch(
              editSchedule(
                payload,
                setOpen,
                getMyScedule,
                getAllScheduled,
                handleCancel,
                true
              )
            );
          });
        });
      } else {
        await auth2?.signIn()?.then(function () {});

        if (auth2?.isSignedIn?.get()) {
          response?.data?.map(async (Sdata) => {
            const event = {
              summary: data?.services
                ?.filter((service) => data?.service_id?.includes(service._id))
                .map((service) => service.service_name)
                .join(", "),
              start: {
                dateTime: Sdata?.schedule_start_date,
                timeZone: data?.current_timezone,
              },
              end: {
                dateTime: Sdata?.schedule_end_date,
                timeZone: data?.current_timezone,
              },
            };

            const request = gapi.client.calendar.events.insert({
              calendarId: "primary",
              resource: event,
            });

            await request.execute(function (eventData) {
              const payload = {
                service_id: Sdata?.service_id,
                schedule_start_date: Sdata?.schedule_start_date,
                schedule_end_date: Sdata?.schedule_end_date,
                location: Sdata?.location,
                address: Sdata?.address,
                schedule_id: Sdata?._id,
                current_timezone: Sdata?.date_timezone,
                services: data?.services,
                google_calender_id: eventData?.id,
                google_calender_email: auth2?.currentUser
                  ?.get()
                  ?.getBasicProfile()
                  ?.getEmail(),
                want_action_for_all: false,
              };

              dispatch(
                editSchedule(
                  payload,
                  setOpen,
                  getMyScedule,
                  getAllScheduled,
                  handleCancel,
                  true
                )
              );
            });
          });
        }
      }

      getMyScedule();
      getAllScheduled();
      handleCancel();
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
};

export const editSchedule = (
  data,
  setOpen,
  getMyScedule,
  getAllScheduled,
  handleCancel,
  isAddTime
) => {
  return async (dispatch) => {
    const auth2 = gapi?.auth2?.getAuthInstance();
    let response;
    const fdata = new FormData();
    fdata.append("schedule_start_date", IsoFormate(data?.schedule_start_date));
    fdata.append("schedule_end_date", IsoFormate(data?.schedule_end_date));
    fdata.append("service_id", JSON.stringify(data?.service_id));
    fdata.append("location", JSON.stringify(data?.location));
    fdata.append("address", data?.address);
    fdata.append("schedule_id", data?.schedule_id);
    fdata.append(
      "want_to_apply_all_recurrent_schedules",
      data?.want_action_for_all
    );

    if (auth2?.isSignedIn?.get()) {
      fdata.append("google_calender_id", data?.google_calender_id);
      fdata.append(
        "google_calender_email",
        auth2?.currentUser?.get()?.getBasicProfile()?.getEmail()
      );
    }

    fdata.append("current_timezone", data?.current_timezone);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.edit_schedule}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      if (!isAddTime) {
        response?.data?.map(async (apiResdata) => {
          const event = {
            summary: data?.services
              ?.filter((service) => data?.service_id?.includes(service._id))
              .map((service) => service.service_name)
              .join(", "),
            start: {
              dateTime: new Date(apiResdata?.schedule_start_date),
              timeZone: data?.current_timezone,
            },
            end: {
              dateTime: new Date(apiResdata?.schedule_end_date),
              timeZone: data?.current_timezone,
            },
          };

          if (auth2?.isSignedIn?.get()) {
            if (
              apiResdata?.google_calender_id &&
              apiResdata?.google_calender_email &&
              apiResdata?.google_calender_email ==
                auth2?.currentUser?.get()?.getBasicProfile()?.getEmail()
            ) {
              const request = gapi.client.calendar.events.update({
                calendarId: "primary",
                eventId: apiResdata?.google_calender_id,
                resource: event,
              });

              request.execute(function (eventData) {});
            } else {
              const request = gapi.client.calendar.events.insert({
                calendarId: "primary",
                resource: event,
              });

              await request.execute(function (eventData) {
                dispatch(
                  editSchedule(
                    {
                      ...data,
                      schedule_start_date: apiResdata?.schedule_start_date,
                      schedule_end_date: apiResdata?.schedule_end_date,
                      google_calender_id: eventData?.id,
                      schedule_id: apiResdata?._id,
                    },
                    setOpen,
                    getMyScedule,
                    getAllScheduled,
                    handleCancel,
                    true
                  )
                );
              });
            }
          } else {
            await auth2?.signIn()?.then(function () {});

            if (auth2?.isSignedIn?.get()) {
              if (
                apiResdata?.google_calender_id &&
                apiResdata?.google_calender_email &&
                apiResdata?.google_calender_email ==
                  auth2?.currentUser?.get()?.getBasicProfile()?.getEmail()
              ) {
                const request = gapi.client.calendar.events.update({
                  calendarId: "primary",
                  eventId: apiResdata?.google_calender_id,
                  resource: event,
                });

                request.execute(function (eventData) {});
              } else {
                const request = gapi.client.calendar.events.insert({
                  calendarId: "primary",
                  resource: event,
                });

                request.execute(function (eventData) {
                  dispatch(
                    editSchedule(
                      {
                        ...data,
                        schedule_start_date: apiResdata?.schedule_start_date,
                        schedule_end_date: apiResdata?.schedule_end_date,
                        google_calender_id: eventData?.id,
                        schedule_id: apiResdata?._id,
                      },
                      setOpen,
                      getMyScedule,
                      getAllScheduled,
                      handleCancel,
                      true
                    )
                  );
                });
              }
            }
          }
        });
      }

      setOpen(false);
      dispatch({
        type: scheduleConstant.EDIT_SCHEDULE,
        payload: { data: response.data, status: response?.success },
      });
      getMyScedule();
      getAllScheduled();
      if (!isAddTime) {
        handleCancel();
        toast.success(response?.message);
      }
    } else {
      toast.error(response?.message);
    }
  };
};
