import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import img1 from "../assets/images/1.jpg";
import { Box, Grid, IconButton } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../assets/images/Discovery.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import varifyIcon from "../assets/images/varificationIcon.svg";
import layout1 from "../assets/images/layout1.jpg";
import layout2 from "../assets/images/layout2.jpg";
import layout3 from "../assets/images/layout3.jpg";
import layout4 from "../assets/images/layout4.jpg";
import layout5 from "../assets/images/layout5.jpg";
import layout6 from "../assets/images/layout6.jpg";
import ImageLayouts from "../Component/ImageLayouts/ImageLayouts";
import ServiceLayout from "../Component/ServiceLayout/ServiceLayout";
import Carousel from "react-multi-carousel";
import PackagePopup from "../Component/PackagePopup";
import { addMyPageDetails } from "../action/myPageConstant";
import { useNavigate, useParams } from "react-router-dom";
import checkIcon from "../assets/images/blueCheck.png";
import checkedIcon from "../assets/images/blueChecked.png";
import GuestHeader from "../Component/GuestHeader";
import { Select } from "antd";
import {
  checkModifyPayment,
  eventNotify,
  getEvent,
  getGuestEvent,
  bookEventRequest,
  guestEventBookingRequest,
} from "../action/eventConstant";
import { useDispatch, useSelector } from "react-redux";
import unuserIcon from "../assets/images/unFoundUser.png";
import upNdownArrow from "../assets/images/upNdownArrow.svg";
import UpArrow from "../assets/images/upArrow.png";
import downArrow from "../assets/images/downArrow.png";
import { toast } from "react-toastify";
import ReviewPopUp from "../Component/ReviewPopUp";
import LoginPopup from "../Component/LoginPopup";
import EventSharePopup from "../Component/EventSharePopup";
import moment from "moment";
import shareIocn from "../assets/images/shareIcon.svg";
import { saveService } from "../action/userServiceConstant";
import saveIcon from "../assets/images/save.svg";
import unsave from "../assets/images/saveIcon.svg";
import { saveUnSaveEvent } from "../action/upcomingEventConstant";
import ImageModle from "../Component/ImageModle";
import Loader from "../Component/Loader";
import RemoveEventPopup from "../Component/RemoveEventPopup";
import { getBookingDetails } from "../action/myBokingConstant";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../Utils/DateFormateUtils";
import placeImg from "../assets/images/userIconWithBg.png";
import ViewReviewPopup from "../Component/ViewReviewPopup";
import { GoogleMapRedirection } from "../Utils/GoogleMapRedirection";
import verifyImage from "../assets/images/check.svg";
import { googleMapApiKey } from "../Utils/GoogleMeet";
import GoogleMapIframe from "../Component/GoogleMapIframe";
import { Link } from "react-router-dom";
import userIcon from "../assets/images/userIconWithBg.png";
import Claim_book_request from "../Component/Claim_book_request";
import { ENVIROMENT } from "../Configration/configration";
import { Modal } from "react-bootstrap";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1220 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1220, min: 800 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};

// const responsive = {
//     desktop: {breakpoint: {max: 3000, min: 1024}, items: 4, slidesToSlide: 3},
//     tablet: {breakpoint: {max: 1024, min: 464}, items: 3, slidesToSlide: 3},
//     mobile: {breakpoint: {max: 464, min: 0}, items: 2, slidesToSlide: 3},
// };

const ViewEvent = (props) => {
  const [open, setOpen] = useState(false);

  // const mapUrl = `https://www.openstreetmap.org/export/embed.html?bbox=${longitude-0.01}%2C${latitude-0.01}%2C${longitude+0.01}%2C${latitude+0.01}&layer=mapnik`;
  const navigate = useNavigate();
  const [checkedPackage, setCheckedPackage] = useState(null);
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const { id, providerId, bookingId, reviewOpen, userId } = useParams();
  const dispatch = useDispatch();
  const { event, myBookingDetails } = useSelector((state) => state);
  const [packageDetails, setPackageDetails] = useState({});
  const [isShare, setShare] = useState(false);
  const [isSelectedParticipats, setIsSelectedParticipats] = useState(0);
  const [foundEvent, setFoundEvent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedReview, setSelectedReview] = useState("");
  const [modifyCharges, setModifyCharges] = useState({
    modifyPercentage: "",
    systemPercentage: "",
    modifyAmount: "",
    systemAmount: "",
    total: "",
    amount: "",
  });

  const [bannerTextModal, setbannerTextModal] = useState(false);
  const [bannerText, setbannerText] = useState("");

  const [isClaimPopup, setIsClaimPopup] = useState({
    claimAccount: "",
    requestTobook: "",
  });

  const [partisipent, setPartisipent] = useState(1);
  const [openReview, setOpenReview] = useState(false);
  const [isPopUp, setPopUp] = useState({
    review: reviewOpen ? true : false,
  });
  const [openimg, setOpenimg] = React.useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const handleOpenimg = (url) => {
    setImageUrl(url);
    setOpenimg(true);
  };
  const handleCloseimg = () => setOpenimg(false);

  const geteventInfo = () => {
    if (!userAuth?.token) {
      setLoader(true);
      dispatch(
        getGuestEvent(id, (a) => {
          setLoader(a);
        })
      );
    } else {
      setLoader(true);
      dispatch(
        getEvent(
          id,
          (a) => {
            setFoundEvent(a);
          },
          (a) => {
            setLoader(a);
          }
        )
      );
    }
  };

  useEffect(() => {
    geteventInfo();
  }, [providerId, id]);

  const handleNotify = () => {
    const payload = {
      event_id: id,
      is_notify: true,
    };
    dispatch(eventNotify(payload));
    setTimeout(() => {
      geteventInfo();
    }, 1000);
  };

  const handleDecriment = () => {
    if (partisipent > 1) {
      setPartisipent((pre) => pre - 1);
    }
  };

  const handleIncriment = () => {
    if (partisipent <= 99) {
      setPartisipent((pre) => pre + 1);
    }
  };

  const saveProvider = (id, status) => {
    dispatch(saveUnSaveEvent({ id: id, status: status, event: true }));
    setTimeout(() => {
      geteventInfo();
    }, 1000);
  };

  const [center, setCenter] = useState();

  useEffect(() => {
    document.body.style.overflow = "auto";
    if ("geolocation" in navigator) {
      //check geolocation available
      //try to get user current location using getCurrentPosition() method
      navigator?.geolocation?.getCurrentPosition(async (position) => {
        // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
        await setCenter({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    } else {
      console.log("Browser doesn't support geolocation!");
    }
  }, []);

  const gateData = () => {
    const payload = {
      id: bookingId,
      latLong: center,
    };
    dispatch(getBookingDetails(payload));
  };

  useEffect(() => {
    if (bookingId && bookingId !== "null") {
      gateData();
    }
  }, [bookingId]);

  useEffect(() => {
    if (bookingId) {
      setCheckedPackage(myBookingDetails?.bookingDetails?.event_package_id);
      setIsSelectedParticipats(
        myBookingDetails?.bookingDetails?.event_package_id
      );
      setPartisipent(myBookingDetails?.bookingDetails?.no_of_participants);
    }
  }, [myBookingDetails?.bookingDetails]);

  const getSelectedPackage = () => {
    const selectedPackage = event?.event?.package_details?.find((ele) => {
      if (ele._id == checkedPackage) {
        return ele;
      }
    });
    return selectedPackage;
  };
  const modifyCount = () => {
    if (myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount) {
      const AMAOUNT =
        Number(getSelectedPackage()?.value_per_person * partisipent) -
        Number(
          myBookingDetails?.bookingDetails?.booking_payment_info
            ?.total_paid_amount
        );

      if ((myBookingDetails?.bookingDetails?.modified_count ?? 0) > 2) {
        // console.log(1, "amount")
        if (userAuth?.is_ambassador === true) {
          // console.log(2, "amount")
          const modifyPercentage = 8; //team fees
          const systemPercentage = 0; //tax
          const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
          const systemAmount = (AMAOUNT * systemPercentage) / 100;
          const totalAmount = AMAOUNT + modifyAmount + systemAmount;
          setModifyCharges((pre) => ({
            ...pre,
            modifyPercentage: modifyPercentage,
            systemPercentage: systemPercentage,
            modifyAmount: modifyAmount,
            systemAmount: systemAmount,
            total: totalAmount,
            amount: AMAOUNT,
          }));

          // modify charge 8%
          // system charge 0
        } else {
          // console.log(3, "amount")
          const modifyPercentage = 8; //team fees
          const systemPercentage = 2; //tax
          const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
          const systemAmount = (AMAOUNT * systemPercentage) / 100;
          const totalAmount = AMAOUNT + modifyAmount + systemAmount;
          setModifyCharges((pre) => ({
            ...pre,
            modifyPercentage: modifyPercentage,
            systemPercentage: systemPercentage,
            modifyAmount: modifyAmount,
            systemAmount: systemAmount,
            total: totalAmount,
            amount: AMAOUNT,
          }));

          // modify charge 8%
          // system charge 2%
        }
      } else if (myBookingDetails?.bookingDetails?.event_id?.start_time) {
        // console.log(4, "amount")
        const currentTime = new Date();
        const oneHourLater = new Date(currentTime.getTime() + 60 * 60 * 1000); // Calculate 1 hour later

        if (
          currentTime <
            myBookingDetails?.bookingDetails?.event_id?.start_time &&
          myBookingDetails?.bookingDetails?.event_id?.start_time < oneHourLater
        ) {
          if (userAuth?.is_ambassador === true) {
            // console.log(6, "amount")
            const modifyPercentage = 5;
            const systemPercentage = 0;
            const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
            const systemAmount = (AMAOUNT * systemPercentage) / 100;
            const totalAmount = AMAOUNT + modifyAmount + systemAmount;
            setModifyCharges((pre) => ({
              ...pre,
              modifyPercentage: modifyPercentage,
              systemPercentage: systemPercentage,
              modifyAmount: modifyAmount,
              systemAmount: systemAmount,
              total: totalAmount,
              amount: AMAOUNT,
            }));

            // modify charge 5%
            // system charge 0
          } else {
            // console.log(5, "amount")
            const modifyPercentage = 5;
            const systemPercentage = 2;
            const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
            const systemAmount = (AMAOUNT * systemPercentage) / 100;
            const totalAmount = AMAOUNT + modifyAmount + systemAmount;
            setModifyCharges((pre) => ({
              ...pre,
              modifyPercentage: modifyPercentage,
              systemPercentage: systemPercentage,
              modifyAmount: modifyAmount,
              systemAmount: systemAmount,
              total: totalAmount,
              amount: AMAOUNT,
            }));

            // modify charge 5%
            // system charge 2%
          }
        } else {
          const modifyPercentage = 0;
          const systemPercentage = 0;
          const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
          const systemAmount = (AMAOUNT * systemPercentage) / 100;
          const totalAmount = AMAOUNT + modifyAmount + systemAmount;

          setModifyCharges((pre) => ({
            ...pre,
            modifyPercentage: modifyPercentage,
            systemPercentage: systemPercentage,
            modifyAmount: modifyAmount,
            systemAmount: systemAmount,
            total: totalAmount,
            amount: AMAOUNT,
          }));
        }
      }
    }
  };

  useEffect(() => {
    if (bookingId) {
      modifyCount();
    }
  }, [
    myBookingDetails?.bookingDetails,
    event?.event?.package_details,
    partisipent,
  ]);
  const handleCheckModifyPayment = () => {
    const payload = {
      modified_booking_id: bookingId,
      total_paid_amount: modifyCharges.total,
    };
    dispatch(
      checkModifyPayment(
        payload,
        () => {
          navigate(
            `/event-modify/${id}/${checkedPackage}/${partisipent}/${providerId}/${bookingId}`
          );
        },
        () => {}
      )
    );
  };

  let authToken;
  if (ENVIROMENT == "LIVE") {
    authToken =
      "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";
  } else {
    authToken =
      "sk_test_51Ng8S1CutznZ678nVvKamQkm2jhrPyeRgwZGDY2Uz0vlREZstr3OhzY5mRm0gajjLzRlWtnKnAsaOcSfG0jIvnzd009dUu3LNC";
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = {
      payment_intent: urlParams.get("payment_intent"),
      payment_intent_client_secret: urlParams.get(
        "payment_intent_client_secret"
      ),
    };

    const EventBookingData = JSON.parse(
      localStorage.getItem("EventBookingData")
    );
    const EventModifyData = JSON.parse(localStorage.getItem("EventModifyData"));

    if (
      (EventBookingData || EventModifyData) &&
      myParam?.payment_intent &&
      myParam?.payment_intent_client_secret
    ) {
      fetch(
        `https://api.stripe.com/v1/payment_intents/${myParam?.payment_intent}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((res) => res.json())
        .then((intentData) => {
          if (EventBookingData) {
            let payload = {
              ...EventBookingData,
              stripe_charge_id: intentData?.latest_charge,
              stripe_payment_intent_id: intentData?.id,
            };

            if (userAuth?.token) {
              dispatch(
                bookEventRequest(payload, (a) => {
                  localStorage.removeItem("EventBookingData");
                })
              );
            } else {
              dispatch(
                guestEventBookingRequest(payload, (a) => {
                  localStorage.removeItem("EventBookingData");
                })
              );
            }
          } else {
            let payload = {
              ...EventModifyData,
              stripe_charge_id: intentData?.latest_charge,
              stripe_payment_intent_id: intentData?.id,
            };
            dispatch(
              bookEventRequest(payload, (a) => {
                localStorage.removeItem("EventModifyData");
              })
            );
          }
        });
    }
  }, []);

  return (
    <>
      {event?.event == {} && (
        <>
          <Loader />
        </>
      )}
      <div>
        <section className="main-page">
          {!userAuth?.token ? <GuestHeader /> : <Header select={"Home"} />}
          <div className="main-inner">
            <div className="container">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="border-gred">
                        <div
                          style={{ background: "white", borderRadius: "8px" }}
                        >
                          <div
                            className="inner-gred-yellow"
                            style={{
                              background: event?.event?.is_pre_populate_event
                                ? "linear-gradient(270deg, rgba(218, 225, 174, 0.5) 0%, rgba(171, 213, 180, 0.5) 99.79%)"
                                : "rgba(255,165,0,.1)",
                            }}
                          >
                            <div className="d-flex ">
                              <div
                                className="p_image_shape me-2 me-sm-3 mt-2"
                                onClick={() =>
                                  handleOpenimg(
                                    event?.event?.event_media[0]?.file_name
                                  )
                                }
                              >
                                <img
                                  src={
                                    event?.event?.event_media
                                      ? event?.event?.event_media[0]?.file_name
                                      : ""
                                  }
                                />
                              </div>
                              <div style={{ width: "100%" }}>
                                <div
                                  className="d-flex justify-content-between align-items-sm-center align-items-baseline"
                                  style={{ width: "100%" }}
                                >
                                  <p className="m-0 t-18-bold mt-1">
                                    {event?.event?.event_name}
                                  </p>
                                  <div className="d-flex align-items-center gap-1">
                                    <IconButton onClick={() => setShare(true)}>
                                      <img
                                        src={shareIocn}
                                        alt=""
                                        style={{ transform: "scale(2)" }}
                                      />
                                    </IconButton>

                                    {userAuth?.token && (
                                      <IconButton
                                        onClick={() => {
                                          if (event?.event?.is_save) {
                                            saveProvider(
                                              event?.event?._id,
                                              false
                                            );
                                          } else {
                                            saveProvider(
                                              event?.event?._id,
                                              true
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          src={
                                            event?.event?.is_save
                                              ? saveIcon
                                              : unsave
                                          }
                                          alt=""
                                          width="28px"
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex flex-sm-row flex-column flex-md-wrap align-items-start align-items-sm-center mt-1">
                                  {/*<p className="m-0 t-15-500">{(moment(event?.event?.start_date).format('MMM Do') == moment(event?.event?.end_date).format('MMM Do')) ? (`${moment(event?.event?.start_date).format('MMM Do')} - ${moment(event?.event?.end_date).format('MMM Do, YYYY')}`) : {moment(event?.event?.start_date).format('MMMM Do YYYY')}}</p>*/}
                                  {/*<p className="m-0 t-15-500">{moment(LocalFormate(event?.event?.start_date)).format('MMM Do') !== moment(LocalFormate(event?.event?.end_date)).format('MMM Do') ? (`${moment(LocalFormate(event?.event?.start_date)).format('MMM Do')} - ${moment(LocalFormate(event?.event?.end_date)).format('MMM Do, YYYY')}`) : (`${moment(LocalFormate(event?.event?.start_date)).format('MMMM Do YYYY')}`)}</p>*/}
                                  <p className="m-0 t-15-500">
                                    {DateFormate(
                                      LocalFormate(
                                        event?.event?.start_date,
                                        event?.event?.date_timezone
                                      ),
                                      LocalFormate(
                                        event?.event?.end_date,
                                        event?.event?.date_timezone
                                      )
                                    )}
                                  </p>
                                  <FiberManualRecordIcon
                                    className="d-none d-sm-block"
                                    style={{ transform: "scale(0.4)" }}
                                  />
                                  <p className="m-0 t-15-500">
                                    {moment(
                                      LocalFormate(
                                        event?.event?.start_time,
                                        event?.event?.date_timezone
                                      )
                                    ).format("LT")}{" "}
                                    to{" "}
                                    {moment(
                                      LocalFormate(
                                        event?.event?.end_time,
                                        event?.event?.date_timezone
                                      )
                                    ).format("LT")}{" "}
                                    (
                                    {timeZoneFormate(
                                      event?.event?.start_time,
                                      event?.event?.date_timezone,
                                      event?.event?.is_virtual_event
                                    )}
                                    )
                                  </p>
                                </div>
                                {event?.event?.is_virtual_event == true ? (
                                  <p className="t-14-bold">Type: Virtual</p>
                                ) : (
                                  <div
                                    className="d-none d-sm-flex align-items-start gap-2 mt-1 location-hover"
                                    onClick={() =>
                                      GoogleMapRedirection(
                                        event?.event?.location?.coordinates[1],
                                        event?.event?.location?.coordinates[0]
                                      )
                                    }
                                  >
                                    <img
                                      src={locationIcon}
                                      alt="locationIcon"
                                      className="mt-2"
                                    />
                                    <p className="mb-0 t-15-bold">
                                      {event?.event?.address}
                                    </p>
                                  </div>
                                )}
                                <div
                                  className="d-flex align-items-baseline mt-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/provider_details/${event?.event?.user_id?._id}`
                                    )
                                  }
                                >
                                  <div
                                    className="p_image_shape me-2 me-sm-0"
                                    style={{
                                      transform: "scale(0.4)",
                                      margin: "-50px",
                                      marginLeft: "-30px",
                                    }}
                                  >
                                    <img
                                      src={
                                        event?.event?.user_id
                                          ?.profile_picture ?? placeImg
                                      }
                                    />
                                    {event?.event?.user_id?.is_verified ? (
                                      <img
                                        src={verifyImage}
                                        className="blue_check"
                                      />
                                    ) : null}
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginLeft: "-25px",
                                    }}
                                  >
                                    <div
                                      className="d-flex justify-content-between w-100 align-items-center"
                                      style={{ width: "100%" }}
                                    >
                                      <p className="m-0 t-18-bold mt-1">
                                        {event?.event?.user_id?.name}
                                      </p>
                                      {event?.event?.user_id?.is_ambassador ? (
                                        <img
                                          src="/images/ambassador.svg"
                                          className="mb-1"
                                          style={{
                                            height: "16px",
                                            marginTop: "8px",
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*{event?.event?.is_virtual_event ? <p className="t-14-bold">Type: Virtual</p> : <div*/}
                            {/*    className="d-flex d-sm-none align-items-center gap-2 mt-1">*/}
                            {/*    <img src={locationIcon} alt="locationIcon"*/}
                            {/*         className="mt-1"/>*/}
                            {/*    <p className="mb-0 t-15-bold">{event?.event?.address}</p>*/}
                            {/*</div>}*/}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} className="pt-0">
                      <div
                        style={{ background: "white", borderRadius: "8px" }}
                        onClick={() =>
                          navigate(
                            `/provider_details/${event?.event?.user_id?._id}`
                          )
                        }
                        className="box_shadow"
                      >
                        <div className="inner-gred p-0 pe-3 c-pointer">
                          <div className="d-flex align-items-baseline ">
                            <div
                              className="p_image_shape me-2 me-sm-0"
                              style={{ transform: "scale(0.6)" }}
                            >
                              <img
                                src={
                                  event?.event?.user_id?.profile_picture ??
                                  placeImg
                                }
                              />
                              {event?.event?.user_id?.is_verified ? (
                                <img src={verifyImage} className="blue_check" />
                              ) : null}
                            </div>
                            <div style={{ width: "100%" }}>
                              <div
                                className="d-flex justify-content-between w-100 align-items-center"
                                style={{ width: "100%" }}
                              >
                                <p className="m-0 t-18-bold mt-1">
                                  {event?.event?.user_id?.name}
                                </p>
                                {event?.event?.user_id?.is_ambassador ? (
                                  <img
                                    src="/images/ambassador.svg"
                                    className="mb-1"
                                    style={{ height: "16px", marginTop: "8px" }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid> */}
                    <Grid item xs={12}>
                      <div className="border-gred">
                        <div className="inner-gred">
                          <p className="t-18-bold">About</p>
                          {/*<p className="t-14-bold">{event?.event?.about}</p>*/}
                          <p
                            className="mt-3 selected-item review-text m-0 t-14-bold"
                            style={{ wordBreak: "break-word" }}
                          >
                            {event?.event?.about}
                          </p>
                          {event?.event?.about?.length > 390 && (
                            <p
                              onClick={() => {
                                setSelectedReview(event?.event?.about);
                                setOpenReview(true);
                              }}
                              style={{
                                textAlign: "end",
                                margin: "0",
                                color: "#9494c9",
                                fontSize: "13px",
                                cursor: "pointer",
                              }}
                            >
                              Read More
                            </p>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <div className="d-flex align-items-center justify-content-center ">
                    <ImageLayouts userDetails={event?.event?.event_media} />
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={
                    event?.event?.hire_collab_provider_details?.length ? 6 : 12
                  }
                  lg={
                    event?.event?.hire_collab_provider_details?.length ? 6 : 12
                  }
                  order={{ xs: 2, md: 1 }}
                >
                  {event?.event?.event_media?.length && (
                    <ServiceLayout
                      image={event?.event?.event_media[0]?.file_name}
                      activities={event?.event?.activities}
                      onTextClick={(text) => {
                        setbannerText(text);
                        setbannerTextModal(true);
                      }}
                    />
                  )}
                </Grid>

                {event?.event?.hire_collab_provider_details?.length ? (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    order={{ xs: 1, md: 2 }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <div
                          className="border-gred mt-3 mt-sm-2"
                          style={{ width: "100%" }}
                        >
                          <div className="inner-gred">
                            <p className="t-18-bold">Team</p>
                            <>
                              {event?.event?.hire_collab_provider_details?.map(
                                (ele, i) => (
                                  <>
                                    {ele.is_registered_user ||
                                    ele.is_request_accepted ? (
                                      <>
                                        <Link
                                          to={`/provider_details/${ele?.provider_id?._id}`}
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <div
                                                className="p_image_shape me-2 me-sm-0"
                                                style={{
                                                  transform: "scale(0.7)",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    ele?.provider_id
                                                      ?.profile_picture ||
                                                    userIcon
                                                  }
                                                  onError={(e) =>
                                                    (e.target.src = userIcon)
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <p className="m-0 t-20-bold">
                                                  {ele?.provider_id?.name}
                                                </p>
                                                <p
                                                  className="m-0 t-13-500"
                                                  style={{ color: "#7A7A7A" }}
                                                >
                                                  {
                                                    ele?.provider_id
                                                      ?.provider_services[0]
                                                      ?.description_name
                                                  }
                                                </p>
                                                <div className="d-flex gap-1">
                                                  <div
                                                    style={{ color: "#FFC107" }}
                                                  >
                                                    {ele?.provider_id
                                                      .average_rating >= 0.5 ? (
                                                      <StarIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <StarBorderIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    )}
                                                    {ele?.provider_id
                                                      .average_rating >= 1.5 ? (
                                                      <StarIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <StarBorderIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    )}
                                                    {ele?.provider_id
                                                      .average_rating >= 2.5 ? (
                                                      <StarIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <StarBorderIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    )}
                                                    {ele?.provider_id
                                                      .average_rating >= 3.5 ? (
                                                      <StarIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <StarBorderIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    )}
                                                    {ele?.provider_id
                                                      .average_rating >= 4.5 ? (
                                                      <StarIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <StarBorderIcon
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                  <p
                                                    className="m-0 t-13-500"
                                                    style={{ lineHeight: "2" }}
                                                  >
                                                    (
                                                    {Math.round(
                                                      ele?.provider_id
                                                        .average_rating
                                                    )}
                                                    /5)
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            {event?.event?.is_verified && (
                                              <div className="d-flex align-items-center gap-1">
                                                <img src={varifyIcon} alt="" />
                                                <p className="m-0">Verify</p>
                                              </div>
                                            )}
                                          </div>
                                        </Link>
                                        {i + 1 ===
                                        event?.event
                                          ?.hire_collab_provider_details
                                          ?.length ? (
                                          <></>
                                        ) : i !==
                                          event?.event
                                            ?.hire_collab_provider_details[
                                            event?.event
                                              ?.hire_collab_provider_details
                                              .length - 1
                                          ] ? (
                                          <hr style={{ margin: "0" }} />
                                        ) : null}
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">
                                            {/*<div*/}
                                            {/*    className="p_image_shape me-2 me-sm-0"*/}
                                            {/*    style={{transform: "scale(0.7)"}}>*/}
                                            <img
                                              src={unuserIcon}
                                              style={{ width: "97px" }}
                                              className="usericoninevent"
                                            />
                                            {/*</div>*/}
                                            <div>
                                              <p
                                                className="t-20-bold"
                                                style={{
                                                  marginBottom:
                                                    "22px !important",
                                                }}
                                              >
                                                {ele?.provider_name}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        {i + 1 ===
                                        event?.event
                                          ?.hire_collab_provider_details
                                          ?.length ? (
                                          <></>
                                        ) : (
                                          <hr style={{ margin: "0" }} />
                                        )}
                                      </>
                                    )}
                                  </>
                                )
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={12} order={{ xs: 3, md: 3 }}>
                  <p className="mt-5 t-30-bold text-center carousel-margin">
                    Select Your Package
                  </p>
                  {event?.event?.package_details?.length && (
                    <Carousel
                      responsive={responsive}
                      // swipeable={false}
                      draggable={true}
                      showDots={
                        event?.event?.package_details?.length > 0 ? true : false
                      }
                      // ssr={true} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={props.deviceType !== "mobile" ? true : false}
                      autoPlaySpeed={2000}
                      keyBoardControl={true}
                      // customTransition="all .5"
                      // transitionDuration={500}
                      arrows={false}
                      // renderArrowsWhenDisabled={false}
                      // containerClass="carousel-container"
                      // removeArrowOnDeviceType={["tablet", "mobile", "laptop"]}
                      deviceType={props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                      className={`${
                        event?.event?.package_details?.length > 3
                          ? "justify-content-start"
                          : "justify-content-lg-center"
                      }`}
                    >
                      {/*{*/}
                      {/*    PACKAGE?.map((ele, i) => {*/}
                      {event?.event?.package_details?.length &&
                        event?.event?.package_details?.map((ele) => {
                          var capacity = [];
                          for (let index = 1; index <= ele?.capacity; index++) {
                            capacity.push(index);
                          }
                          return (
                            <div className="border-gred m-2">
                              <div
                                className="inner-gred"
                                style={{ padding: "16px" }}
                              >
                                <div className="position-relative">
                                  <div className="d-flex">
                                    <div
                                      className="p_image_shape me-2 me-sm-0"
                                      style={{ transform: "scale(0.7)" }}
                                    >
                                      <img
                                        src={
                                          ele?.package_media[0]?.file_name ??
                                          placeImg
                                        }
                                      />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                      <div style={{ width: "100%" }}>
                                        <p
                                          className="t-18-bold m-0 newelips"
                                          style={{ width: "60%" }}
                                          //    style={{
                                          //     whiteSpace: "nowrap",
                                          //     overflow: "hidden",
                                          //     textOverflow: "ellipsis",
                                          // }}
                                        >
                                          {ele?.package_name}
                                        </p>
                                      </div>
                                      {/* <p className="t-10-bold m-0">
                                        Up to - {ele?.capacity} ppl
                                      </p> */}
                                      <p
                                        className="t-12-bold pe-4"
                                        style={{
                                          color: "#000",
                                          margin: "5px 0 0 0",
                                        }}
                                      >
                                        Value ${ele?.value_per_person} / Person{" "}
                                        <span
                                          className="ms-2 t-10-bold"
                                          style={{ color: "#636363" }}
                                        >
                                          {" "}
                                          <div
                                            className="d-inline-block me-2"
                                            style={{
                                              width: "5px",
                                              height: "5px",
                                              borderRadius: "50%",
                                              backgroundColor: "#636363",
                                            }}
                                          ></div>{" "}
                                          Up to - {ele?.capacity} ppl
                                        </span>
                                      </p>
                                      <div
                                        className="d-flex justify-content-between"
                                        style={{ marginTop: "14px" }}
                                      >
                                        <div className="">
                                          <Select
                                            style={{ width: "100px" }}
                                            value={
                                              isSelectedParticipats == ele?._id
                                                ? partisipent
                                                : 1
                                            }
                                            disabled={
                                              !(
                                                isSelectedParticipats ==
                                                ele?._id
                                              )
                                            }
                                            onChange={(val) => {
                                              setPartisipent(val);
                                            }}
                                            options={capacity?.map((co) => {
                                              return {
                                                value: co,
                                                label: co,
                                              };
                                            })}
                                          ></Select>
                                        </div>
                                        <div className="">
                                          <button
                                            type="submit"
                                            className="btn "
                                            onClick={() => {
                                              setPackageDetails(ele);
                                              setOpen(true);
                                            }}
                                            style={{
                                              fontSize: "11px",
                                              fontWeight: "600",
                                              color: "#FFA500",
                                            }}
                                          >
                                            More Details
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="position-absolute"
                                    style={{
                                      right: "5px",
                                      top: "5px",
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      {/* <div className="d-flex align-items-center gap-2">
                                        <p className="m-0">
                                          {isSelectedParticipats == ele?._id
                                            ? partisipent
                                            : 1}
                                        </p>
                                        <div className="d-flex flex-column">
                                          <IconButton
                                            onClick={() => {
                                              setIsSelectedParticipats(
                                                ele?._id
                                              );
                                              handleIncriment();
                                            }}
                                          >
                                            <img src={UpArrow} alt="" />
                                          </IconButton>
                                          <IconButton
                                            onClick={() => {
                                              setIsSelectedParticipats(
                                                ele?._id
                                              );
                                              handleDecriment();
                                            }}
                                          >
                                            <img src={downArrow} alt="" />
                                          </IconButton>
                                        </div>
                                      </div> */}
                                      <IconButton
                                        className="p-0"
                                        onClick={() => {
                                          // setPartisipent(1)
                                          setCheckedPackage(ele._id);
                                          setIsSelectedParticipats(ele?._id);
                                        }}
                                      >
                                        <img
                                          src={
                                            checkedPackage == ele._id
                                              ? checkedIcon
                                              : checkIcon
                                          }
                                          alt=""
                                        />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {/* })*/}
                      {/*}*/}
                    </Carousel>
                  )}
                </Grid>
              </Grid>

              {providerId !==
                JSON.parse(localStorage.getItem("AdAnima_auth"))?._id && (
                <div>
                  <Box className="d-flex mt-5 flex-wrap justify-content-center align-items-center mt-3">
                    {event?.event?.user_id?.is_admin_created &&
                    event?.event?.is_pre_populate_event ? (
                      <button
                        className="btn_admina"
                        // disabled={(bookingId && myBookingDetails?.bookingDetails?.no_of_participants == partisipent) ? true : false}
                        // style={{opacity: (bookingId && myBookingDetails?.bookingDetails?.no_of_participants == partisipent) ? "0.5" : '1'}}
                        onClick={() =>
                          setIsClaimPopup((pre) => ({
                            ...pre,
                            requestTobook: true,
                          }))
                        }
                      >
                        Request to Book
                      </button>
                    ) : (
                      <>
                        <button
                          className="btn_admina"
                          onClick={() =>
                            setPopUp((pre) => ({ ...pre, review: true }))
                          }
                        >
                          Add Review
                        </button>
                        <button
                          className="btn_admina"
                          // disabled={(bookingId && myBookingDetails?.bookingDetails?.no_of_participants == partisipent) ? true : false}
                          // style={{opacity: (bookingId && myBookingDetails?.bookingDetails?.no_of_participants == partisipent) ? "0.5" : '1'}}
                          onClick={() => {
                            // if (bookingId) {
                            // handleCheckModifyPayment()
                            // }
                            if (checkedPackage == null) {
                              toast.error("Please select Package");
                            } else {
                              bookingId
                                ? navigate(
                                    `/event-modify/${id}/${checkedPackage}/${partisipent}/${providerId}/${bookingId}`
                                  )
                                : navigate(
                                    `/event-booking/${id}/${checkedPackage}/${partisipent}/${providerId}`
                                  );
                            }
                          }}
                        >
                          {bookingId ? "Modify Booking" : "Book Now"}
                        </button>
                      </>
                    )}

                    {/* ) : userAuth?.token &&
                      !event?.event?.is_requested_notify ? (
                      <button
                        className="btn_admina"
                        onClick={() => handleNotify()}
                      >
                        Notify Me
                      </button>
                    ) : (
                      <></>
                    )} */}
                  </Box>
                  {/* {!event?.event?.is_booking_available && userAuth?.token && (
                    <p
                      className="d-flex justify-content-center"
                      style={{ fontSize: "15px" }}
                    >
                      You will receive a notification when event booking become
                      available.
                    </p>
                  )} */}
                </div>
              )}

              {/*     ===================== map ======================*/}
              {!event?.event?.is_virtual_event && (
                <GoogleMapIframe
                  address={event?.event?.address}
                  lat={event?.event?.location?.coordinates[1]}
                  long={event?.event?.location?.coordinates[0]}
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <PackagePopup
        setOpen={setOpen}
        open={open}
        packageDetails={packageDetails}
      />
      {userAuth?.token || userId ? (
        <ReviewPopUp
          open={isPopUp}
          setOpen={setPopUp}
          providerId={event?.event?._id}
          isEvent={true}
          event={event}
          userId={userId}
        />
      ) : (
        <LoginPopup open={isPopUp} setOpen={setPopUp} />
      )}

      <EventSharePopup
        setOpen={setShare}
        open={isShare}
        typeSharing={"event_type"}
        path={"view-events"}
        providerId={providerId}
        eventId={id}
      />
      <ImageModle
        open={openimg}
        handleClose={handleCloseimg}
        imageUrl={imageUrl}
      />
      <RemoveEventPopup open={foundEvent} setOpen={setFoundEvent} />
      <ViewReviewPopup
        open={openReview}
        setOpen={setOpenReview}
        selectedReview={selectedReview}
        setSelectedReview={setSelectedReview}
        title={"About"}
      />
      <Claim_book_request
        setOpen={setIsClaimPopup}
        open={isClaimPopup}
        userDetails={event?.event}
        type="event"
      />
      {loader && <Loader />}
      <Modal
        centered
        show={bannerTextModal}
        onHide={() => {
          setbannerTextModal(false);
        }}
        dialogClassName="delete-modal"
      >
        <div className="Congratulations_msg">
          <div className=" m-0" style={{ borderRadius: "10px" }}>
            <div className="group " style={{ borderRadius: "10px" }}>
              <div
                className="padding-inner position-relative pt-2"
                style={{ borderRadius: "10px", textAlign: "center" }}
              >
                <div className="mt-4">
                  <p className="text-start m-0 t-16-bold">{bannerText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/*    .........MAP .................*/}
    </>
  );
};

export default ViewEvent;
