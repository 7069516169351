import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {Grid, IconButton} from "@mui/material";
import profileimg from "../assets/images/1.jpg";
import locationcon from "../assets/images/Discovery.svg"
import unsave from "../assets/images/saveIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../assets/images/Discovery.svg";
import {Empty, Select} from "antd";
import {getGuestUpcominEvents, getUpcominEvents, getUpcominEventsForHire} from "../action/upcomingEventConstant";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {getEvent} from "../action/eventConstant";
import {add_hire_and_collaborate, claim_add_hire_and_collaborate} from "../action/hireNcollaborateConstant";
import logo from "../assets/images/ad-anima_logo.png"
import {DateFormate, LocalFormate, timeZoneFormate} from "../Utils/DateFormateUtils";
import placeImg from "../assets/images/userIconWithBg.png";

const AddEventPopup = ({open, setOpen, userDetails, isClaim = false}) => {
    let {providerId} = useParams();
    const dispatch = useDispatch();
    const [selectedEvent, setSelectedEvent] = useState({})
    const {upComingEvents, event} = useSelector((state) => state);
    const [LangOpen, setLangOpen] = useState(false);
    const navigate = useNavigate();
    const [eventDetails, setEventDetails] = useState({
        massage: "", service: "", amount: "", activity: "",
        ckeckBtn: "hire"
    })
    const [error, setError] = useState({
        massage: "", amount: ""
    })
    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));

    // console.log(userDetails, "userDetails")
    const getEventDitails = () => {
        // if (isClaim) {
        dispatch(getUpcominEventsForHire(userAuth?._id))
        // } else if (userDetails?.details?._id) {
        //     dispatch(getUpcominEvents(userDetails?.details?._id))
        // }
    }


    useEffect(() => {
        getEventDitails()
    }, [userDetails?.details?._id]);

    const getEventDetails = () => {
        dispatch(getEvent(selectedEvent?._id, () => {
        }, () => {
        }))
    }

    useEffect(() => {
        if (selectedEvent?._id) {
            getEventDetails()
        }
    }, [selectedEvent]);
    const calculateHourDifference = (startTime, endTime) => {
        const start = new Date(`01/01/2000 ${startTime}`);
        const end = new Date(`01/01/2000 ${endTime}`);

        const diffInMilliseconds = Math.abs(end - start);
        const hours = Math.floor(diffInMilliseconds / 1000 / 60 / 60);

        return hours;
    };


    useEffect(() => {
        setEventDetails({
            massage: "",
            service: "",
            amount: "",
            activity: "",
            ckeckBtn: "hire"
        })
        if (event?.event?.service_details?.length) {
            setEventDetails((pre) => ({...pre, service: event?.event?.service_details[0]}))
        } else {
            setEventDetails((pre) => ({
                ...pre,
                activity: event?.event?.activities?.length > 0 ? event?.event?.activities[0] : ""
            }))
        }
    }, [event, selectedEvent])

    useEffect(() => {
        if (upComingEvents?.upComingEventsForHire?.length) {
            setSelectedEvent(upComingEvents?.upComingEventsForHire[0])
        }
    }, [upComingEvents]);

    const handleAddHireCollaborate = () => {
        if (!eventDetails?.amount) {
            setError((pre) => ({...pre, amount: "Please Enter Amount", massage: ""}))
        } else {
            setError({
                massage: "", amount: ""
            })
            const payload = {
                event_id: event?.event?._id,
                provider_id: userDetails?.details?._id,
                description_id: eventDetails?.service?.description_id?._id,
                message: eventDetails?.massage,
                amount: eventDetails?.amount,
                event_request_type: eventDetails?.ckeckBtn,
                service_time_detail: eventDetails?.service?.service_time_detail,
                activities: eventDetails?.activity
            }
            dispatch(add_hire_and_collaborate(payload, (a) => {
                setOpen((pre) => ({...pre, event: a}))
            }))
        }

    }
    const handleClaimAddHireCollaborate = () => {
        if (!eventDetails?.amount) {
            setError((pre) => ({...pre, amount: "Please Enter Amount", massage: ""}))
        } else {
            setError({
                massage: "", amount: ""
            })
            const payload = {
                event_id: event?.event?._id,
                provider_id: userDetails?.details?._id,
                description_id: eventDetails?.service?.description_id?._id,
                message: eventDetails?.massage,
                amount: eventDetails?.amount,
                event_request_type: eventDetails?.ckeckBtn,
                service_time_detail: eventDetails?.service?.service_time_detail,
                activities: eventDetails?.activity
            }
            dispatch(claim_add_hire_and_collaborate(payload, (a) => {
                setOpen((pre) => ({...pre, event: a}))
            }))
        }

    }


    return (<div className="eventModal">
            <Modal
                centered
                size="lg"
                backdrop="static"
                show={open.event}
                style={{zIndex: '100001', background: '#00000096'}}
                onHide={() => {
                    setOpen((pre) => ({...pre, event: false}));
                }}
                dialogClassName="collabrate-modale"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <div className={"Congratulations_msg"} style={{width: "100%", maxWidth: "865px"}}>
                    <div className="inner-gred">
                        <p className="t-24-bold">Add to My Event</p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>

                                {/*..............left side...........*/}

                                <div className="border-gred w-100 c-pointer" onClick={() => {
                                    setOpen((pre) => ({...pre, event: false}));
                                    navigate(`/provider_details/${userDetails?.details?._id}`)
                                }}>
                                    <div className="inner-gred w-100">
                                        <div className="d-flex align-items-center">
                                            <div className="p_image_shape me-2 me-sm-3">
                                                <img
                                                    src={userDetails?.details?.profile_picture ? userDetails?.details?.profile_picture : logo}/>
                                            </div>
                                            <div>
                                                <p className="t-20-bold m-0 mb-2"> {userDetails?.details?.name}</p>
                                                <div className="d-flex align-items-center gap-2 ad-my-event">
                                                    <img src={locationcon} alt=""/>
                                                    <p className="m-0 yelloe-t-bold">{userDetails?.details?.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="inner-gred box_shadow collabrate-event-list"
                                     style={{padding: "20px 9px 20px 20px"}}>
                                    {/*...............1.............*/}

                                    {
                                        !upComingEvents?.upComingEventsForHire?.length ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={<span>No Event Found</span>}
                                            /> :
                                            upComingEvents?.upComingEventsForHire?.map((ele, i) => (<div
                                                className={`evant-card-bg box_shadow inner-gred w-100 mt-2 ${selectedEvent?._id == ele?._id ? "yellow_border" : ""}`}
                                                onClick={() => {
                                                    setSelectedEvent(ele)
                                                }}>
                                                <div className="d-flex align-items-center">
                                                    <div className="p_image_shape me-2 me-sm-3">
                                                        <img src={ele?.event_media[0] ?? placeImg}/>
                                                    </div>
                                                    <div>
                                                        <div className="">
                                                            <p className="t-16-bold m-0 mb-1">{ele?.event_name}</p>
                                                        </div>
                                                        {/*<div className="d-flex align-items-center mb-1">*/}
                                                        {/*    <p className="pro_carousel_time m-0 text-nowrap ">August 23*/}
                                                        {/*        2023</p>*/}
                                                        {/*    <FiberManualRecordIcon style={{transform: "scale(0.3)"}}/>*/}
                                                        {/*    <p className="pro_carousel_time m-0 text-nowrap ">3PM to*/}
                                                        {/*        4PM</p>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="d-flex flex-sm-column flex-column align-items-start align-items-sm-start mb-1">
                                                            {/*<p className="pro_carousel_time m-0">{moment(ele?.start_date).format('MMM Do') !== moment(ele?.end_date).format('MMM Do') ? (`${moment(ele?.start_date).format('MMM Do')} - ${moment(ele?.end_date).format('MMM Do, YYYY')}`) : (`${moment(ele?.start_date).format('MMMM Do YYYY')}`)}</p>*/}
                                                            {/*<FiberManualRecordIcon className="d-none d-sm-block"*/}
                                                            <p className="pro_carousel_time m-0">{DateFormate(LocalFormate(ele?.start_date, ele?.date_timezone), LocalFormate(ele?.end_date, ele?.date_timezone))}</p>
                                                            {/*<FiberManualRecordIcon className="d-none d-sm-block"*/}
                                                            {/*                       style={{transform: "scale(0.3)"}}/>*/}
                                                            <p className="pro_carousel_time m-0">{moment(LocalFormate(ele?.start_time, ele?.date_timezone)).format('LT')} to {moment(LocalFormate(ele?.end_time, ele?.date_timezone)).format('LT')} ({timeZoneFormate(ele?.start_time, ele?.date_timezone)})</p>
                                                        </div>
                                                        {ele?.address ?
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img src={locationIcon} alt=""/>
                                                                <p className="yelloe-t-bold m-0">{ele?.address}</p>
                                                            </div> : null}
                                                    </div>
                                                </div>
                                            </div>))
                                    }


                                    <div>
                                        {/*<div className="evant-card-bg box_shadow inner-gred w-100">*/}
                                        {/*    <div className="d-flex align-items-center">*/}
                                        {/*        <div className="p_image_shape me-2 me-sm-3">*/}
                                        {/*            <img src={profileimg}/>*/}
                                        {/*        </div>*/}
                                        {/*        <div>*/}
                                        {/*            <div className="">*/}
                                        {/*                <p className="t-16-bold m-0 mb-1">Serenity Retreat: Discovering*/}
                                        {/*                    Peace...</p>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="d-flex align-items-center mb-1">*/}
                                        {/*                <p className="pro_carousel_time m-0 text-nowrap ">August 23 2023</p>*/}
                                        {/*                <FiberManualRecordIcon style={{transform: "scale(0.3)"}}/>*/}
                                        {/*                <p className="pro_carousel_time m-0 text-nowrap ">3PM to 4PM</p>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="d-flex align-items-center gap-1">*/}
                                        {/*                <img src={locationIcon} alt=""/>*/}
                                        {/*                <p className="yelloe-t-bold m-0">RIO DE JANERIO, BRAZIL</p>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*...............2.............*/}
                                        {/*<div className="evant-card-bg box_shadow inner-gred w-100 mt-3">*/}
                                        {/*    <div className="d-flex align-items-center">*/}
                                        {/*        <div className="p_image_shape me-2 me-sm-3">*/}
                                        {/*            <img src={profileimg}/>*/}
                                        {/*        </div>*/}
                                        {/*        <div>*/}
                                        {/*            <div className="">*/}
                                        {/*                <p className="t-16-bold m-0 mb-1">Serenity Retreat: Discovering*/}
                                        {/*                    Peace...</p>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="d-flex align-items-center mb-1">*/}
                                        {/*                <p className="pro_carousel_time m-0 text-nowrap ">August 23 2023</p>*/}
                                        {/*                <FiberManualRecordIcon style={{transform: "scale(0.3)"}}/>*/}
                                        {/*                <p className="pro_carousel_time m-0 text-nowrap ">3PM to 4PM</p>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="d-flex align-items-center gap-1">*/}
                                        {/*                <img src={locationIcon} alt=""/>*/}
                                        {/*                <p className="yelloe-t-bold m-0">RIO DE JANERIO, BRAZIL</p>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*...............3.............*/}
                                        {/*<div className="evant-card-bg box_shadow inner-gred w-100 mt-3">*/}
                                        {/*    <div className="d-flex align-items-center">*/}
                                        {/*        <div className="p_image_shape me-2 me-sm-3">*/}
                                        {/*            <img src={profileimg}/>*/}
                                        {/*        </div>*/}
                                        {/*        <div>*/}
                                        {/*            <div className="">*/}
                                        {/*                <p className="t-16-bold m-0 mb-1">Serenity Retreat: Discovering*/}
                                        {/*                    Peace...</p>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="d-flex align-items-center mb-1">*/}
                                        {/*                <p className="pro_carousel_time m-0 text-nowrap ">August 23 2023</p>*/}
                                        {/*                <FiberManualRecordIcon style={{transform: "scale(0.3)"}}/>*/}
                                        {/*                <p className="pro_carousel_time m-0 text-nowrap ">3PM to 4PM</p>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="d-flex align-items-center gap-1">*/}
                                        {/*                <img src={locationIcon} alt=""/>*/}
                                        {/*                <p className="yelloe-t-bold m-0">RIO DE JANERIO, BRAZIL</p>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </Grid>


                            {/*..............right side...........*/}
                            <Grid item xs={12} md={6}>
                                <>
                                    <div className="box_shadow inner-gred">
                                        <div className="tablist">
                                <span
                                    className={`w-100 ${eventDetails.ckeckBtn == "hire" ? "t_active" : ""}`}
                                    onClick={() => {
                                        setEventDetails((pre) => ({...pre, ckeckBtn: "hire"}));
                                    }}
                                >
                                    Hire
                                </span>
                                            <span
                                                className={`w-100 ${eventDetails.ckeckBtn == "collaborate" ? "t_active" : ""}`}
                                                onClick={() => {
                                                    setEventDetails((pre) => ({...pre, ckeckBtn: "collaborate"}));
                                                }}
                                            >Collaborate
                                </span>
                                        </div>
                                        <hr/>
                                        <div>
                                            <p className="t-14-bold m-0 mt-3">Type Your Message Here
                                                For {eventDetails.ckeckBtn == "collaborate" ? " Collaborate " : " Hire "}
                                                Provider</p>
                                            <textarea className="eventtextarea mt-1" value={eventDetails?.massage}
                                                      onChange={(e) => setEventDetails((pre) => ({
                                                          ...pre,
                                                          massage: e.target.value
                                                      }))}/>
                                            {/*<p className="t-12-bold text-danger">{error?.massage}</p>*/}
                                        </div>

                                        <div className="mt-2">
                                            <div className="position-relative">
                                                <div className="group-input mt-0">
                                                    <input
                                                        type="text"
                                                        name=""
                                                        value={event?.event?.service_details?.length ? eventDetails?.service?.description_id?.description_name : eventDetails?.activity}
                                                        className="select-servcesss"
                                                        onClick={(e) => {
                                                            setLangOpen(!LangOpen);
                                                        }}
                                                        readOnly
                                                    />
                                                </div>
                                                <div>
                                                    <div
                                                        className={
                                                            LangOpen
                                                                ? "list-open open pt-2 pb-2"
                                                                : "list-open"
                                                        }
                                                        style={{scrollBehavior: "smooth", padding: '0'}}
                                                    >
                                                        {event?.event?.service_details?.length ? event?.event?.service_details?.map((data, i) => (
                                                            <React.Fragment key={i}>
                                                                <p className="colabrote_service_dropdown m-0"
                                                                   onClick={() => {
                                                                       setLangOpen(false);
                                                                       setEventDetails((pre) => ({
                                                                           ...pre,
                                                                           service: data
                                                                       }))
                                                                   }}>{data?.description_id?.description_name}</p>
                                                            </React.Fragment>
                                                        )) : event?.event?.activities?.map((data, i) => {
                                                            return (
                                                                <React.Fragment key={i}>
                                                                    <p className="colabrote_service_dropdown m-0"
                                                                       onClick={() => {
                                                                           setLangOpen(false);
                                                                           setEventDetails((pre) => ({
                                                                               ...pre,
                                                                               activity: data
                                                                           }))
                                                                       }}>{data}</p>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {eventDetails.ckeckBtn == "hire" ?
                                            <div className="group-input mt-3 p-15 clearfix">
                                                <input
                                                    type="text"
                                                    name=""
                                                    placeholder="Enter your hiring amount"
                                                    value={eventDetails?.amount?.replace(/\D/g, '')}
                                                    onChange={(e) => {
                                                        if (e.target.value.length < 8) {
                                                            setEventDetails((pre) => ({
                                                                ...pre,
                                                                amount: e.target.value
                                                            }))
                                                        }

                                                    }}
                                                />
                                            </div> : <div className="group-input mt-3 p-15 clearfix">
                                                <input
                                                    type="text"
                                                    name=""
                                                    placeholder="% of revenue for this provider"
                                                    value={eventDetails?.amount?.replace(/\D/g, '')}
                                                    onChange={(e) => {
                                                        if (e.target.value.length < 3) {
                                                            setEventDetails((pre) => ({
                                                                ...pre,
                                                                amount: e.target.value
                                                            }))
                                                        }

                                                    }}
                                                />
                                            </div>}
                                        <p className="t-12-bold text-danger">{error?.amount}</p>
                                    </div>
                                    {eventDetails?.service?.service_time_detail?.length ?
                                        <div className="box_shadow inner-gred mt-2">
                                            <p className="t-14-bold mb-2">Hours</p>
                                            <div className="border-gred">
                                                <div className="inner-gred">
                                                    {/*<p className="t-12-bold">06/15/2023 - 10 am to 12am - 2 Hours</p>*/}
                                                    {/*<p className="t-12-bold">06/15/2023 - 10 am to 12am - 2 Hours</p>*/}
                                                    {/*<p className="t-12-bold">06/15/2023 - 10 am to 12am - 2 Hours</p>*/}
                                                    {eventDetails?.service?.service_time_detail?.map((ele) => (
                                                        <p className="t-12-bold">{`${moment(ele?.provider_service_date).format('L')} - ${moment(ele?.provider_start_time).local().format('LT')} to ${moment(ele?.provider_end_time).local().format('LT')} - ${calculateHourDifference(moment(ele?.provider_start_time).local().format('LT'), moment(ele?.provider_end_time).local().format('LT'))} Hours`}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div> : null}
                                </>
                            </Grid>
                        </Grid>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <div className="col-6"
                                 style={{width: "100%", maxWidth: "170px"}}>
                                <button
                                    type="submit"
                                    className="btn_admina delete-button"
                                    style={{opacity: !upComingEvents?.upComingEventsForHire?.length ? '0.5' : '1'}}
                                    disabled={!upComingEvents?.upComingEventsForHire?.length}
                                    onClick={() => {
                                        if (isClaim) {
                                            handleClaimAddHireCollaborate()
                                        } else {
                                            handleAddHireCollaborate()
                                        }

                                    }}
                                >
                                    {eventDetails.ckeckBtn == "hire" ? "Hire" : "Collaborate"}
                                </button>
                            </div>
                            <div className="col-6"
                                 style={{width: "100%", maxWidth: "170px"}}>
                                <button
                                    type="submit"
                                    className="btn_admina delete-confirm-button"
                                    onClick={() => {
                                        setOpen((pre) => ({...pre, event: false}))
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AddEventPopup;