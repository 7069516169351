import {toast} from "react-toastify";
import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
import {myBookingConstant, sessionBookingConstant} from "./constant";
import {IsoFormate} from "../Utils/DateFormateUtils";

export const getMyBookingUpcomingDetails = (props, setDataLoader) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", props?.page);
        fdata.append("limit", props?.limit);
        if (props?.latLong) {
            fdata.append("lat", props?.latLong?.lat);
            fdata.append("long", props?.latLong?.lng);
        }
        fdata.append(
            "user_id",
            JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        );
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_my_booking_upcoming_details}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setDataLoader(false)
            dispatch({
                type: myBookingConstant.GET_MY_BOOKING_DETAILS,
                payload: response.data,
            });
        } else {
            setDataLoader(false)
            toast.error(response?.message);
        }
    };
};
export const getMyBookingPastDetails = (props, setDataLoader) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", props?.page);
        fdata.append("limit", props?.limit);
        if (props?.latLong) {
            fdata.append("lat", props?.latLong?.lat);
            fdata.append("long", props?.latLong?.lng);
        }
        fdata.append(
            "user_id",
            JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        );
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_my_booking_past_details}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setDataLoader(false)
            dispatch({
                type: myBookingConstant.GET_MY_BOOKING_DETAILS,
                payload: response.data,
            });
        } else {
            setDataLoader(false)
            toast.error(response?.message);
        }
    };
};
export const getMyBookingCancelDetails = (props, setDataLoader) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", props?.page);
        fdata.append("limit", props?.limit);
        if (props?.latLong) {
            fdata.append("lat", props?.latLong?.lat);
            fdata.append("long", props?.latLong?.lng);
        }
        fdata.append(
            "user_id",
            JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        );
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_my_booking_cancel_details}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setDataLoader(false)
            dispatch({
                type: myBookingConstant.GET_MY_BOOKING_DETAILS,
                payload: response.data,
            });
        } else {
            setDataLoader(false)
            toast.error(response?.message);
        }
    };
};


export const getMoreMyBookingUpcomingDetails = (props, setDataLoader, setHasMore) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", props?.page);
        fdata.append("limit", props?.limit);
        if (props?.latLong) {
            fdata.append("lat", props?.latLong?.lat);
            fdata.append("long", props?.latLong?.lng);
        }
        fdata.append(
            "user_id",
            JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        );
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_my_booking_upcoming_details}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setDataLoader(false)
            dispatch({
                type: myBookingConstant.GET_MORE_MY_BOOKING_DETAILS,
                payload: response.data,
            });
            if (response.data?.length == 0) {
                setHasMore(false)
            }
        } else {
            setDataLoader(false)
            toast.error(response?.message);
        }
    };
};
export const getMoreMyBookingPastDetails = (props, setDataLoader, setHasMore) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", props?.page);
        fdata.append("limit", props?.limit);
        if (props?.latLong) {
            fdata.append("lat", props?.latLong?.lat);
            fdata.append("long", props?.latLong?.lng);
        }
        fdata.append(
            "user_id",
            JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        );
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_my_booking_past_details}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setDataLoader(false)
            dispatch({
                type: myBookingConstant.GET_MORE_MY_BOOKING_DETAILS,
                payload: response.data,
            });
            if (response.data?.length == 0) {
                setHasMore(false)
            }
        } else {
            setDataLoader(false)
            toast.error(response?.message);
        }
    };
};
export const getMoreMyBookingCancelDetails = (props, setDataLoader, setHasMore) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("page", props?.page);
        fdata.append("limit", props?.limit);
        if (props?.latLong) {
            fdata.append("lat", props?.latLong?.lat);
            fdata.append("long", props?.latLong?.lng);
        }
        fdata.append(
            "user_id",
            JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        );
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_my_booking_cancel_details}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setDataLoader(false)
            dispatch({
                type: myBookingConstant.GET_MORE_MY_BOOKING_DETAILS,
                payload: response.data,
            });
            if (response.data?.length == 0) {
                setHasMore(false)
            }
        } else {
            setDataLoader(false)
            toast.error(response?.message);
        }
    };
};


// ============ BOOKING API NEW KEYS ===============


//     booking_type,
//     description,
//     event_id, --
//     service_id,
//     provider_id,
//     schedule_id,
//     event_package_id, --
//     booking_date,
//     start_date_time,
//     end_date_time,
//     location,
//     address,
//     no_of_participants,
//     service_type,
//     location_type,
//     total_paid_amount,
//     total_amount,
//     fees_percentage_from_user,
//     fees_amount_from_user,
//     //----
//     fees_percentage_from_provider, *****
//     fees_amount_from_provider,
//     total_transfered_amount,
//     stripe_charge_id,
//     stripe_payment_intent_id,
//     //---
//     sales_tax_percentage,
//     sales_tax_amount_from_user,
//     sales_tax_id,
//     card_id,


export const sessionBooking = (data, setBtnLoader) => {
    return async (dispatch) => {
        let response;
        let success = {status: false};
        const fdata = new FormData();
        // fdata.append(
        //     "user_id",
        //     JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        // );


        // console.log(data, 'data?.booking_date')
        fdata.append("booking_type", data?.booking_type);
        fdata.append("service_id", data?.service_id);
        fdata.append("provider_id", data?.provider_id);
        fdata.append("schedule_id", data?.schedule_id);
        // if (data?.booking_date) {
        fdata.append("booking_date", IsoFormate(new Date(data?.booking_date)));
        // }
        fdata.append("start_date_time", IsoFormate(new Date(data?.start_date_time)));
        fdata.append("end_date_time", IsoFormate(new Date(data?.end_date_time)));
        fdata.append("location", JSON.stringify(data?.location));
        fdata.append("address", data?.address);
        fdata.append("no_of_participants", data?.no_of_participants);
        fdata.append("service_type", data?.service_type);
        fdata.append("location_type", data?.location_type);
        fdata.append("total_paid_amount", data?.total_paid_amount);
        fdata.append("card_id", data?.card_id);
        fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
        fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
        fdata.append("total_amount", data?.total_amount);
        fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
        fdata.append("sales_tax_amount_from_user", data?.sales_tax_amount_from_user);
        fdata.append("total_transfered_amount", data?.total_paid_amount); // ===== TEMP =======
        if (data?.description && data?.location_type == "my_place") {
            fdata.append("description", data?.description);
        }
        if (data?.sales_tax_id !== 0) {
            fdata.append("sales_tax_id", data?.sales_tax_id);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.sessionBooking}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                success = {status: data?.success};
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setBtnLoader(false)
            toast.success(response?.message);
            dispatch({
                type: sessionBookingConstant.SESSION_BOOKING,
                payload: {data: response.data, success: success},
            });
        } else {
            setBtnLoader(false)
            toast.error(response?.message);
        }
    };
}

export const sessionBookingRequest = (data, setBtnLoader, clianData) => {
    return async (dispatch) => {
        let response;
        let success = {status: false};
        const fdata = new FormData();
        // fdata.append(
        //     "user_id",
        //     JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        // );


        fdata.append("booking_type", data?.booking_type);
        fdata.append("service_id", data?.service_id);
        fdata.append("provider_id", data?.provider_id);
        fdata.append("schedule_id", data?.schedule_id);
        fdata.append("booking_date", IsoFormate(data?.booking_date));
        fdata.append("start_date_time", IsoFormate(data?.start_date_time));
        fdata.append("end_date_time", IsoFormate(data?.end_date_time));
        fdata.append("location", JSON.stringify(data?.location));
        fdata.append("address", data?.address);
        fdata.append("no_of_participants", data?.no_of_participants);
        fdata.append("service_type", data?.service_type);
        fdata.append("location_type", data?.location_type);
        fdata.append("total_paid_amount", data?.total_paid_amount);
        fdata.append("current_timezone", data?.current_timezone);
        fdata.append("card_id", data?.card_id);
        fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
        fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
        fdata.append("total_amount", data?.total_amount);
        fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
        fdata.append("sales_tax_amount_from_user", data?.sales_tax_amount_from_user);
        fdata.append("fees_percentage_from_provider", data?.fees_percentage_from_provider);
        fdata.append("fees_amount_from_provider", data?.fees_amount_from_provider);
        fdata.append("total_transfered_amount", data?.total_paid_amount); // ===== TEMP =======
        fdata.append("modify_booking_payment_info", JSON.stringify(data?.modify_booking_payment_info));
        if (data?.discount_code_id){
            fdata.append("discount_code_id", data?.discount_code_id); // ===== TEMP =======
        }
        if (data?.referral_code_id) {
            fdata.append("referral_code_id", data?.referral_code_id); // ===== TEMP =======
        }
        if (data?.description && data?.location_type == "my_place") {
            fdata.append("description", data?.description);
        }
        if (data?.sales_tax_id !== 0) {
            fdata.append("sales_tax_id", data?.sales_tax_id);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.add_booking_request_session}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                success = {status: data?.success};
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setBtnLoader(false)
            clianData()
            toast.success(response?.message);
            dispatch({
                type: sessionBookingConstant.SESSION_BOOKING,
                payload: {data: response.data, success: success},
            });
        } else {
            setBtnLoader(false)
            toast.error(response?.message);
        }
    };
}
export const sessionBookingModify = (data, setBtnLoader) => {
    return async (dispatch) => {
        let response;
        let success = {status: false};
        const fdata = new FormData();
        // fdata.append(
        //     "user_id",
        //     JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        // );


        fdata.append("booking_type", data?.booking_type);
        fdata.append("service_id", data?.service_id);
        fdata.append("current_timezone", data?.current_timezone);
        fdata.append("provider_id", data?.provider_id);
        fdata.append("schedule_id", data?.schedule_id);
        fdata.append("booking_date", IsoFormate(data?.booking_date));
        fdata.append("start_date_time", IsoFormate(data?.start_date_time));
        fdata.append("end_date_time", IsoFormate(data?.end_date_time));
        fdata.append("location", JSON.stringify(data?.location));
        fdata.append("address", data?.address);
        fdata.append("no_of_participants", data?.no_of_participants);
        fdata.append("service_type", data?.service_type);
        fdata.append("location_type", data?.location_type);
        fdata.append("total_paid_amount", data?.total_paid_amount);
        if (data?.card_id) {
            fdata.append("card_id", data?.card_id);
        }
        fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
        fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
        fdata.append("total_amount", data?.total_amount);
        fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
        fdata.append("sales_tax_amount_from_user", data?.sales_tax_amount_from_user);
        fdata.append("total_transfered_amount", data?.total_paid_amount); // ===== TEMP =======
        fdata.append("booking_id", data?.booking_id);
        if (data?.description && data?.location_type == "my_place") {
            fdata.append("description", data?.description);
        }
        if (data?.sales_tax_id !== 0) {
            fdata.append("sales_tax_id", data?.sales_tax_id);
        }
        if (data?.modify_booking_payment_info) {
            fdata.append("modify_booking_payment_info", JSON.stringify(data?.modify_booking_payment_info));

        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.modified_service_booking}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                success = {status: data?.success};
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setBtnLoader(false)
            toast.success(response?.message);
            dispatch({
                type: sessionBookingConstant.MODIFY_SESSION_BOOKING,
                payload: {data: response.data, success: success},
            });
        } else {
            setBtnLoader(false)
            toast.error(response?.message);
        }
    };
}
export const guestsessionBooking = (data, setBtnLoader) => {
    return async (dispatch) => {
        let response;
        let success = {status: false};
        const fdata = new FormData();
        // fdata.append(
        //     "user_id",
        //     JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        // );


        fdata.append("booking_type", data?.booking_type);
        fdata.append("service_id", data?.service_id);
        fdata.append("provider_id", data?.provider_id);
        fdata.append("schedule_id", data?.schedule_id);
        fdata.append("booking_date", IsoFormate(new Date(data?.booking_date)));
        fdata.append("start_date_time", IsoFormate(new Date(data?.start_date_time)));
        fdata.append("end_date_time", IsoFormate(new Date(data?.end_date_time)));
        fdata.append("location", JSON.stringify(data?.location));
        fdata.append("address", data?.address);
        fdata.append("no_of_participants", data?.no_of_participants);
        fdata.append("service_type", data?.service_type);
        fdata.append("location_type", data?.location_type);
        fdata.append("total_paid_amount", data?.total_paid_amount);
        fdata.append("is_guest_user", data?.is_guest_user);
        fdata.append("name", data?.name);
        fdata.append("email_address", data?.email_address);
        fdata.append("phone_number", data?.phone_number);
        fdata.append("country_code", data?.country_code);
        fdata.append("card_holder_name", data?.card_holder_name);
        fdata.append("card_number", data?.card_number);
        fdata.append("expire_year", data?.expire_year);
        fdata.append("expire_month", data?.expire_month);
        fdata.append("cvv", data?.cvv);
        fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
        fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
        fdata.append("total_amount", data?.total_amount);
        fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
        fdata.append("sales_tax_amount_from_user", data?.sales_tax_amount_from_user);
        // fdata.append("total_transfered_amount", data?.total_paid_amount);
        if (data?.sales_tax_id !== 0) {
            fdata.append("sales_tax_id", data?.sales_tax_id);
        }
        if (data?.description && data?.location_type == "my_place") {
            fdata.append("description", data?.description);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_sessionBooking}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                success = {status: data?.success};
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setBtnLoader(false)
            toast.success(response?.message);
            dispatch({
                type: sessionBookingConstant.SESSION_BOOKING,
                payload: {data: response.data, success: success},
            });
        } else {
            setBtnLoader(false)
            toast.error(response?.message);
        }
    };
}

export const guestsessionBookingRequest = (data, setBtnLoader, clianData) => {
    return async (dispatch) => {
        let response;
        let success = {status: false};
        const fdata = new FormData();
        // fdata.append(
        //     "user_id",
        //     JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
        // );


        fdata.append("booking_type", data?.booking_type);
        fdata.append("service_id", data?.service_id);
        fdata.append("provider_id", data?.provider_id);
        fdata.append("schedule_id", data?.schedule_id);
        fdata.append("booking_date", IsoFormate(new Date(data?.booking_date)));
        fdata.append("start_date_time", IsoFormate(new Date(data?.start_date_time)));
        fdata.append("end_date_time", IsoFormate(new Date(data?.end_date_time)));
        fdata.append("location", JSON.stringify(data?.location));
        fdata.append("address", data?.address);
        fdata.append("no_of_participants", data?.no_of_participants);
        fdata.append("service_type", data?.service_type);
        fdata.append("location_type", data?.location_type);
        fdata.append("total_paid_amount", data?.total_paid_amount);
        fdata.append("is_guest_user", data?.is_guest_user);
        fdata.append("name", data?.name);
        fdata.append("email_address", data?.email_address);
        fdata.append("current_timezone", data?.current_timezone);
        fdata.append("phone_number", data?.phone_number);
        fdata.append("country_code", data?.country_code);
        // fdata.append("card_holder_name", data?.card_holder_name);
        // fdata.append("card_number", data?.card_number);
        // fdata.append("expire_year", data?.expire_year);
        // fdata.append("expire_month", data?.expire_month);
        // fdata.append("cvv", data?.cvv);
        fdata.append("fees_amount_from_user", data?.fees_amount_from_user);
        fdata.append("fees_percentage_from_user", data?.fees_percentage_from_user);
        fdata.append("total_amount", data?.total_amount);
        fdata.append("sales_tax_percentage", data?.sales_tax_percentage);
        fdata.append("sales_tax_amount_from_user", data?.sales_tax_amount_from_user);
        fdata.append("fees_percentage_from_provider", data?.fees_percentage_from_provider);
        fdata.append("fees_amount_from_provider", data?.fees_amount_from_provider);
        fdata.append("modify_booking_payment_info", JSON.stringify(data?.modify_booking_payment_info));
        if (data?.discount_code_id){
            fdata.append("discount_code_id", data?.discount_code_id);
        }
        if (data?.referral_code_id) {
            fdata.append("referral_code_id", data?.referral_code_id);
        }
        // fdata.append("total_transfered_amount", data?.total_paid_amount);
        if (data?.sales_tax_id !== 0) {
            fdata.append("sales_tax_id", data?.sales_tax_id);
        }
        if (data?.description && data?.location_type == "my_place") { 
            fdata.append("description", data?.description);
        }

        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.add_guest_booking_request}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                success = {status: data?.success};
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            clianData()
            setBtnLoader(false)
            toast.success(response?.message);
            dispatch({
                type: sessionBookingConstant.SESSION_BOOKING,
                payload: {data: response.data, success: success},
            });
        } else {
            setBtnLoader(false)
            toast.error(response?.message);
        }
    };
}

export const getBookingDetails = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("booking_id", data?.id);
        if (data?.latLong) {
            fdata.append("lat", data?.latLong?.lat);
            fdata.append("long", data?.latLong?.lng);
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_booking_details}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: myBookingConstant.GET_BOOKING_DETAILS,
                payload: response.data,
            });
        } else {
            toast.error(response?.message);
        }
    };
};

export const deleteBookingEvent = (props, setIsOpen, getEvent) => {
    return async (dispatch) => {
        let response;
        let success = {status: false};
        const fdata = new FormData();
        fdata.append(
            props.type,
            props.id
        );
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.delete_Booking_event}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                {
                    success = {status: data?.success};
                    response = data;
                }
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            setIsOpen(false)
            dispatch({
                type: myBookingConstant.DELETE_BOOKING_EVENT,
                payload: {data: response.data, success: success},
            });
            toast.success(response?.message);
            getEvent()
        } else {
            setIsOpen(false)
            toast.error(response?.message);
        }
    };
};

export const setBookingStatus = (data) => {
    return async (dispatch) => {
        let response;
        const fdata = new FormData();
        fdata.append("booking_id", data.id);
        fdata.append("booking_status", data.status);
        if (data?.refund_booking_payment_info) {
            fdata.append("refund_booking_payment_info", JSON.stringify(data.refund_booking_payment_info));
        }
        if (data?.is_modified == true) {
            fdata.append("is_modified", data.is_modified);
        }
        if (data?.stripe_transfer_id) {
            fdata.append("stripe_transfer_id", data.stripe_transfer_id);
        }
        if (data?.is_payment_changes == false) {
            fdata.append("is_payment_changes", data.is_payment_changes);
        }
        await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.booking_status}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
                }`,
            },
            body: fdata,
        })
            .then((response) => response.json())
            .then((data) => {
                response = data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (response?.success) {
            dispatch({
                type: myBookingConstant.BOOKING_STATUS,
                payload: {data: response?.data, status: response?.success},
            });
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
    };
}