import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconButton, Popover, Typography } from "@mui/material";
import closeIcon from "../../assets/images/close-circle.svg";
import { ENVIROMENT } from "../../Configration/configration.js";
// import {firebase} from 'firebase/app';
// import 'firebase/dynamic-links';
// import dynamicLinks from '@react-native-firebase/dynamic-links';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
} from "react-share";
import {
  CustomGreyLeftArrow,
  CustomGreyRightArrow,
  CustomLeftArrow,
  CustomRightArrow,
} from "../../Component/CarouselArrows";
import Carousel from "react-multi-carousel";

// import {APP_VERSION, BASE_URL, URL_PATH} from "../Configration/configration";
// import {hireNcollaborateConstant} from "../action/constant";
// import {toast} from "react-toastify";

const reviewResponsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 5, slidesToSlide: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 5, slidesToSlide: 3 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 5, slidesToSlide: 3 },
};

const CodeShare = ({
  open,
  setOpen,
  typeSharing,
  data,
  path,
  providerId,
  eventId,
  serviceId,
  is_discount = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isCopy, setIsCopy] = useState(false);

  // console.log(data, "shareData")

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openCopy = Boolean(anchorEl);

  const [shortLink, setShortLink] = useState(null);
  const links = "https://adanima.page.link";

  let shareWeblink = "";

  if (ENVIROMENT == "DEV") {
    shareWeblink = "https://dev.ad-anima.com/login";
  } else if (ENVIROMENT == "STAGE") {
    shareWeblink = "https://stage.ad-anima.com/login";
  } else if (ENVIROMENT == "LIVE") {
    shareWeblink = "https://app.ad-anima.com/login";
  }

  if(is_discount){
    
    shareWeblink = shareWeblink.replace( 'login', `provider_details/${JSON.parse(localStorage.getItem('AdAnima_auth'))._id}`)
  }

  useEffect(() => {
    // if (path) {
    // Firebase Dynamic Links API endpoint and API key
    const apiUrl =
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBJVp9yG_EYmhcDu9K0cCuDdX059plyAtI";

    // Request body containing the dynamic link parameters
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: "https://adanima.page.link",
        link: links,
        androidInfo: {
          androidPackageName: "com.app.adanima",
          androidFallbackLink: shareWeblink,
        },
        iosInfo: {
          iosBundleId: "com.app.adanima",
          iosFallbackLink: shareWeblink,
        },
        desktopInfo: {
          desktopFallbackLink: shareWeblink,
        },
      },
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the API
        setShortLink(data.shortLink);
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error creating Dynamic Link:", error);
      });
    // }
  }, [data]);

  const copyText = () => {
    var SYNTeX = "";
    if (!is_discount) {
      SYNTeX = `Exciting news! I'm using this amazing platform for bookings, and I thought you'd love it too. Sign up using my referral code ${data?.referral_code} to get 5% off all bookings for a month! ${shortLink}`;
    } else {
      SYNTeX = `Enjoy Discount off at AD AD ANIMA! Use code: ${data?.discount_code} at booking. book now at ${shortLink}`;
    }
    return SYNTeX;
  };

  return (
    <>
      <Modal
        centered
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        dialogClassName="delete-modal"
      >
        <div className="Congratulations_msg">
          <div className="group" style={{ borderRadius: "10px" }}>
            <div className="py-2 px-2" style={{ borderRadius: "10px" }}>
              <div className="d-flex justify-content-between align-items-center">
                <p className="t-24-bold m-0">Share Code</p>
                <IconButton onClick={() => setOpen(false)}>
                  <img src={closeIcon} alt="" />
                </IconButton>
              </div>

              <div className="mt-4 mb-2 share">
                <Carousel
                  responsive={reviewResponsive}
                  // autoPlay={true}
                  // autoPlaySpeed={3000}
                  // infinite={true}
                  customLeftArrow={<CustomGreyLeftArrow />}
                  customRightArrow={<CustomGreyRightArrow />}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                  <FacebookShareButton url={shortLink}>
                    <FacebookIcon size={50} round={true} />
                  </FacebookShareButton>
                  <WhatsappShareButton url={shortLink}>
                    <WhatsappIcon size={50} round={true} />
                  </WhatsappShareButton>
                  <EmailShareButton url={shortLink}>
                    <EmailIcon size={50} round={true} />
                  </EmailShareButton>
                  <HatenaShareButton url={shortLink}>
                    <HatenaIcon size={50} round={true} />
                  </HatenaShareButton>
                  <InstapaperShareButton url={shortLink}>
                    <InstapaperIcon size={50} round={true} />
                  </InstapaperShareButton>
                  <LineShareButton url={shortLink}>
                    <LineIcon size={50} round={true} />
                  </LineShareButton>
                  <LinkedinShareButton url={shortLink}>
                    <LinkedinIcon size={50} round={true} />
                  </LinkedinShareButton>
                  <LivejournalShareButton url={shortLink}>
                    <LivejournalIcon size={50} round={true} />
                  </LivejournalShareButton>
                  <MailruShareButton url={shortLink}>
                    <MailruIcon size={50} round={true} />
                  </MailruShareButton>
                  <OKShareButton url={shortLink}>
                    <OKIcon size={50} round={true} />
                  </OKShareButton>
                  <PinterestShareButton url={shortLink}>
                    <PinterestIcon size={50} round={true} />
                  </PinterestShareButton>
                  <PocketShareButton url={shortLink}>
                    <PocketIcon size={50} round={true} />
                  </PocketShareButton>
                  <RedditShareButton url={shortLink}>
                    <RedditIcon size={50} round={true} />
                  </RedditShareButton>
                  <TelegramShareButton url={shortLink}>
                    <TelegramIcon size={50} round={true} />
                  </TelegramShareButton>
                  <TumblrShareButton url={shortLink}>
                    <TumblrIcon size={50} round={true} />
                  </TumblrShareButton>
                  <TwitterShareButton url={shortLink}>
                    <TwitterIcon size={50} round={true} />
                  </TwitterShareButton>
                  <ViberShareButton url={shortLink}>
                    <ViberIcon size={50} round={true} />
                  </ViberShareButton>
                  <VKShareButton url={shortLink}>
                    <VKIcon size={50} round={true} />
                  </VKShareButton>
                  <WorkplaceShareButton url={shortLink}>
                    <WorkplaceIcon size={50} round={true} />
                  </WorkplaceShareButton>
                </Carousel>
              </div>

              <div className="black_border p-3 d-flex align-items-center mt-4">
                {!is_discount ? (
                  <p
                    className="t-15-bold m-0"
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    Exciting news! I'm using this amazing platform for bookings,
                    and I thought you'd love it too. Sign up using my referral
                    code {data?.referral_code} to get 5% off all bookings for a
                    month!{" "}
                    <span
                      style={{
                        color: "#000097",
                        textDecoration: "underline",
                      }}
                    >
                      {shortLink}
                    </span>
                  </p>
                ) : (
                  <p
                    className="t-15-bold m-0"
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    Enjoy Discount off at AD AD ANIMA! Use code:{" "}
                    {data?.discount_code} at booking. book now at{" "}
                    <span
                      style={{
                        color: "#000097",
                        textDecoration: "underline",
                      }}
                    >
                      {shortLink}
                    </span>
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="massageBtn mt-3"
                  onClick={(e) => {
                    navigator.clipboard.writeText(copyText());
                    setIsCopy(true);
                    setTimeout(() => {
                      setIsCopy(false);
                    }, 1000);
                  }}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  Copy Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openCopy}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{isCopy ? "Copied..." : "Copy"}</Typography>
      </Popover>
    </>
  );
};

export default CodeShare;
