import React, {useState} from 'react';
import logo from "../../assets/images/ad-anima_logo.png";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import locationIcon from "../../assets/images/Discovery.svg";
import {Grid, IconButton} from "@mui/material";
import saveIcon from "../../assets/images/save.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {RefundPayment, RefundRequest} from "../../action/walletConstant";
import {capital} from "../../Utils/capitalUtils";
import {DateFormate, LocalFormate, timeZoneFormate} from "../../Utils/DateFormateUtils";
import RemoveEventPopup from "../../Component/RefundPopup";
import RefundPopup from "../../Component/RefundPopup";
import userIcon from "../../assets/images/userIconWithBg.png"
import {deleteBookingEvent} from "../../action/myBokingConstant";

const TransactionDetailsCard = ({data, getAllHistory}) => {
    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    const [openRefundPopup, setRefundPopup] = useState(false)
    const dispatch = useDispatch();
    const handleRefundRequest = () => {
        dispatch(RefundRequest(data._id, () => {
            getAllHistory()
        }))
    }

    const handleRefund = () => {
        dispatch(RefundPayment(data?._id, () => {
            getAllHistory()
        }))
    }

    const deleteEvent = () => {
        const isId = data?.booking_id?._id
        const payload = {
            id: isId, type: "booking_id"
        }
        dispatch(deleteBookingEvent(payload, (a) => {
            setRefundPopup(false)
        }, () => {
            getAllHistory()
        }))
    }


    const checkRefund = (data) => {
        if (data?.payment_type?.includes("refund")) {
            return false
        } else {
            return true
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div className="border-gred">
                        <div
                            className={`d-flex inner-gred box_shadow position-relative cursor-pointer`}
                            style={{cursor: 'pointer', background: "rgb(255 242 219)", padding: '15px'}}>
                            <div
                                className={`d-flex w-100 align-items-center`}
                                // onClick={(e) => handleNavigate(e, ele?.user_id?._id)}
                            >
                                <div className="position-relative">
                                    <div className="p_image_shape me-2 me-sm-3">
                                        <img
                                            src={data?.payment_type?.includes("event") ? (data?.banner_image || data?.payment_to_user?.profile_picture) : (data?.display_image || data?.payment_to_user?.profile_picture)}
                                            onError={(e) => (e.target.src = userIcon)}/>
                                    </div>
                                </div>
                                <div style={{flexGrow: "1"}}>
                                    <div
                                        className="d-flex justify-content-between align-items-center">
                                        <p className="t-14-bold m-0 mb-1">{data?.booking_id?.service_id?.service_name || data?.booking_id?.event_id?.event_name || data?.payment_to_user?.name}</p>
                                    </div>
                                    {/*<div className="d-flex flex-sm-row flex-column align-items-start align-items-sm-center mb-1">*/}
                                    {/*    <p className="pro_carousel_time m-0">{moment(ele?.start_date).format('MMM Do') !== moment(ele?.end_date).format('MMM Do') ? (`${moment(ele?.start_date).format('MMM Do')} - ${moment(ele?.end_date).format('MMM Do, YYYY')}`) : (`${moment(ele?.start_date).format('MMMM Do YYYY')}`)}</p>*/}
                                    {/*    <FiberManualRecordIcon className="d-none d-sm-block"*/}
                                    {/*                           style={{transform: "scale(0.3)"}}/>*/}
                                    {/*    <p className="pro_carousel_time m-0">{moment(ele?.start_time).format('LT')} to {moment(ele?.end_time).format('LT')}</p>*/}
                                    {/*</div>*/}
                                    <div
                                        className="d-flex flex-sm-row flex-column align-items-start align-items-sm-center mb-1">
                                        <p className="t-12-500 pro_carousel_time m-0">
                                            {moment(LocalFormate(data?.booking_id?.start_date_time, data?.booking_id?.service_id?.date_timezone || data?.booking_id?.event_id?.date_timezone)).format("MMM DD YYYY")}
                                        </p>
                                        <FiberManualRecordIcon className="d-none d-sm-block"
                                                               style={{transform: "scale(0.3)"}}/>
                                        <p className="t-12-500 pro_carousel_time m-0">{moment(LocalFormate(data?.booking_id?.start_date_time, data?.booking_id?.service_id?.date_timezone || data?.booking_id?.event_id?.date_timezone)).format("LT")} to {moment(LocalFormate(data?.booking_id?.end_date_time, data?.booking_id?.service_id?.date_timezone || data?.booking_id?.event_id?.date_timezone)).format("LT")} ({timeZoneFormate(data?.booking_id?.start_date_time, data?.booking_id?.service_id?.date_timezone || data?.booking_id?.event_id?.date_timezone)})</p>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2 flex-wrap save-page-content"
                                         style={{maxWidth: '100%'}}>
                                        {/*{ele?.user_id?.name && <p className="t-12-bold m-0">{ele?.user_id?.name}</p>}*/}
                                        <div className="d-flex align-items-center gap-1">
                                            <img src={locationIcon} alt=""/>
                                            <p className="yelloe-t-bold t-10-bold m-0 w-100">{data?.booking_id?.service_id?.address || data?.booking_id?.event_id?.address || data?.payment_to_user?.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>

                {/* ========== Transaction  Details ==========*/}

                <Grid item xs={12} md={4}>
                    <div className="border-gred">
                        <div className="inner-gred">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Payment From</p>
                                <p className="t-12-bold m-1">{data?.payment_from_user?.name}</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Transaction ID</p>
                                <p className="t-12-bold m-1">{data?.transaction_id}</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Payment Time</p>
                                <p className="t-12-bold m-1">{DateFormate(LocalFormate(data?.createdAt, (data?.date_timezone || data?.payment_from_user?.date_timezone)), null, true)} ({timeZoneFormate(data?.createdAt, data?.date_timezone)})</p>

                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Status</p>
                                <p className="t-12-bold m-1"
                                   style={{color: data?.payment_status == "success" ? 'green' : 'red'}}>{capital(data?.payment_status)}</p>
                            </div>
                        </div>
                    </div>
                </Grid>

                {/* ========== Payment  Details ==========*/}
                <Grid item xs={12} md={4}>
                    <div className="border-gred">
                        <div className="inner-gred">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Participants</p>
                                <p className="t-12-bold m-1">{data?.no_of_participants ?? 0}</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Package amount</p>
                                <p className="t-12-bold m-1">$ {Number(data?.total_amount)?.toFixed(2)}</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Network fees
                                    ( {data?.fees_percentage_from_user}% )</p>
                                <p className="t-12-bold m-1">$ {Number(data?.fees_amount_from_user).toFixed(2)}</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Tax
                                    ( {data?.sales_tax_percentage} %)</p>
                                <p className="t-12-bold m-1">$ {Number(data?.sales_tax_amount_from_user).toFixed(2)}</p>
                            </div>
                            <hr style={{border: "1px dashed black"}}/>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="t-12-bold m-1">Total Amount</p>
                                <p className="t-12-bold m-1">$ {Number(data?.total_paid_amount).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-end">

                        {checkRefund(data) && data?.is_refunded !== true && <button
                            style={{
                                maxWidth: "350px", height: "45px"
                            }}
                            className="massageBtn mt-0"
                            onClick={() => {
                                data?.transaction_type == "credited" ?
                                    // handleRefund()
                                    setRefundPopup(true)
                                    :
                                    handleRefundRequest()
                            }}
                        >
                            {data?.transaction_type == "credited" ? 'Refund' : 'Send Refund Request'}
                        </button>}
                    </div>
                </Grid>
            </Grid>
            <RefundPopup setOpen={setRefundPopup} open={openRefundPopup} handleRefund={handleRefund} deleteEvent={deleteEvent}/>
        </>
    )
}

export default TransactionDetailsCard;