import React from "react";

export const Rules = {
  Rule_0: "Rule_0",
  Rule_1: "Rule_1", ///This will calculate the 1 hour ago payment case.
  Rule_2: "Rule_2", ///This will  calculate the 2 time modified payment case.
  Rule_3: "Rule_3", ///This will  calculate the 24 hour payment case.
  Rule_4: "Rule_4",
};

export const old_modifyFees = {
  ///Rules Calculation Params
  modify1HourSysFees: 7,
  modify1HourNetFees: 2,
  modify2TimeSysFees: 10,
  modify2TimeNetFees: 2,
};

export const modifyFees = (refCodeData, discountDetails) => {
  var modify1HourSysFees = 7;
  var modify1HourNetFees = 2;
  var modify2TimeSysFees = 10;
  var modify2TimeNetFees = 2;

  var fees = {
    modify1HourSysFees: modify1HourSysFees,
    modify1HourNetFees: modify1HourNetFees,
    modify2TimeSysFees: modify2TimeSysFees,
    modify2TimeNetFees: modify2TimeNetFees,
  };

  if (
    refCodeData?.referee?.is_expire == false &&
    discountDetails?.is_discount == true &&
    discountDetails?.is_admin_created == true
  ) {
    let a =
      old_modifyFees?.modify1HourNetFees -
      Number(
        Number(refCodeData?.referee?.percentage ?? 0) +
          Number(discountDetails?.discount_percentage ?? 0)
      );
    let b =
      old_modifyFees?.modify2TimeNetFees -
      Number(
        Number(refCodeData?.referee?.percentage ?? 0) +
          Number(discountDetails?.discount_percentage ?? 0)
      );

    if (Math.sign(a) > 0) {
      fees.modify1HourNetFees = a;
    } else {
      fees.modify1HourNetFees = 0;
    }
    if (Math.sign(b) > 0) {
      fees.modify2TimeNetFees = b;
    } else {
      fees.modify2TimeNetFees = 0;
    }
  } else if (
    refCodeData?.referee?.is_expire == true &&
    discountDetails?.is_discount == true &&
    discountDetails?.is_admin_created == true
  ) {
    let a =
      old_modifyFees?.modify1HourNetFees -
      Number(discountDetails?.discount_percentage ?? 0);
    let b =
      old_modifyFees?.modify2TimeNetFees -
      Number(discountDetails?.discount_percentage ?? 0);

    if (Math.sign(a) > 0) {
      fees.modify1HourNetFees = a;
    } else {
      fees.modify1HourNetFees = 0;
    }
    if (Math.sign(b) > 0) {
      fees.modify2TimeNetFees = b;
    } else {
      fees.modify2TimeNetFees = 0;
    }
  } else if (
    refCodeData?.referee?.is_expire == false &&
    discountDetails?.is_discount == false
  ) {
    let a =
      old_modifyFees?.modify1HourNetFees -
      Number(refCodeData?.referee?.percentage ?? 0);
    let b =
      old_modifyFees?.modify2TimeNetFees -
      Number(refCodeData?.referee?.percentage ?? 0);

    if (Math.sign(a) > 0) {
      fees.modify1HourNetFees = a;
    } else {
      fees.modify1HourNetFees = 0;
    }
    if (Math.sign(b) > 0) {
      fees.modify2TimeNetFees = b;
    } else {
      fees.modify2TimeNetFees = 0;
    }
  }

  if (refCodeData?.referrer?.is_expire == false) {
    var a =
      old_modifyFees?.modify1HourSysFees -
      Number(refCodeData?.referrer?.percentage);
    var b =
      old_modifyFees?.modify2TimeSysFees -
      Number(refCodeData?.referrer?.percentage);

    if (Math.sign(a) > 0) {
      fees.modify1HourSysFees = a;
    } else {
      fees.modify1HourSysFees = 0;
    }
    if (Math.sign(b) > 0) {
      fees.modify2TimeSysFees = b;
    } else {
      fees.modify2TimeSysFees = 0;
    }
  }

  return fees;
};

const isRefundCase = (data) => {
  const currentPrice =
    (data?.totalParticipants ?? 0) * (data?.packagePrice ?? 0);

  if (currentPrice - (data?.previousPrice ?? 0) < 0) {
    return true; // The value is negative
  }
  return false; // The value is non-negative (zero or positive)
};

export const priceCalculations = (
  data,
  setModifyCharges,
  setModifyBookingInfo,
  refCodeData,
  discountDetails
) => {
  var packagePrice = 0;
  var price = 0;
  var totalPrice = 0;
  var salesTaxAmt = 0;
  var netFeesAmt = 0;
  var salesTaxPercentage = 0;
  var netFeesPercentage = 0;
  var displayNetFeesAmt = 0;
  var displayNetFeesPercentage = 0;
  var providerFeesPercentage = 0;
  var providerFeesAmt = 0;
  var totalTransferedAmount = 0;
  var applicationFees = 0;
  var salesTaxId = null;
  var discountAmount = 0;
  var discountPercentage = 0;

  // console.log(discountDetails, "discountDetails123")

  // ====================== Price calculation ========================

  // price = (packagePrice ?? 0) * (newParticipate ?? 0);
  // if service base modified change needed

  // packagePrice = ((data?.packagePrice ?? 0) * (data?.totalParticipants ?? 0)) - (data?.previousPrice ?? 0)
  // var productPrice = ((data?.packagePrice ?? 0) * (data?.totalParticipants ?? 0)) - (data?.previousPrice ?? 0)
  // discountPercentage = discountDetails?.discount_percentage
  // if (discountDetails?.is_discount == true && discountDetails?.is_admin_created == false) {
  //     discountAmount = (productPrice * (discountDetails?.discount_percentage) / 100)
  //     packagePrice = productPrice - discountAmount
  // }
  //
  // price = ((packagePrice ?? 0) * (data?.totalParticipants ?? 0)) - (data?.previousPrice ?? 0);///Sales Tax

  packagePrice = data?.packagePrice;
  var productPrice = data?.packagePrice;
  discountPercentage = discountDetails?.discount_percentage;
  if (
    discountDetails?.is_discount == true &&
    discountDetails?.is_admin_created == false
  ) {
    discountAmount =
      (productPrice * discountDetails?.discount_percentage) / 100;
    packagePrice = productPrice - discountAmount;
  }

  const refundPayload = {
    totalParticipants: data?.totalParticipants,
    packagePrice: data?.packagePrice,
    previousPrice: data?.previousPrice,
    is_package_change: data?.is_package_change,
  };

  if (isRefundCase(refundPayload) == true) {
    if (data?.is_package_change) {
      price =
        (packagePrice ?? 0) * (data?.totalParticipants ?? 0) -
        (data?.previousPrice ?? 0);
    } else {
      price =
        ((data?.previousPrice ?? 0) / (data?.previous_participants ?? 0)) *
        ((data?.previous_participants ?? 0) - (data?.totalParticipants ?? 0)); ///Sales Tax
    }
  } else {
    if (data?.is_package_change) {
      price =
        (packagePrice ?? 0) * (data?.totalParticipants ?? 0) -
        (data?.previousPrice ?? 0);
    } else {
      price =
        (packagePrice ?? 0) *
        ((data?.totalParticipants ?? 0) - (data?.previous_participants ?? 0)); ///Sales Tax
    }
  }

  let amount_without_discount = 0;

  if (data?.is_package_change) {
    amount_without_discount =
      (packagePrice ?? 0) * (data?.totalParticipants ?? 0) -
      (data?.previousPrice ?? 0);
  } else {
    amount_without_discount =
      (data?.packagePrice ?? 0) *
      ((data?.totalParticipants ?? 0) - (data?.previous_participants ?? 0));
  }

  // console.log(data, "participant")

  /// =========================== Sales Tax =======================

  if (isRefundCase(refundPayload) == true) {
    salesTaxId = null;
    salesTaxPercentage = 0;
    salesTaxAmt = (amount_without_discount * salesTaxPercentage) / 100;
  } else {
    // if (salesTax.value != null) {
    salesTaxPercentage = data?.tax_rate ?? 0;
    salesTaxAmt = (amount_without_discount * salesTaxPercentage) / 100;
    salesTaxId = data?.tax_id;
    // }
  }

  /// ================ Provider fees Calculation =============
  // if (isRefundCase(refundPayload) == true) {
  //     salesTaxId = null;
  //     salesTaxPercentage = 0;
  //     salesTaxAmt = (price * (salesTaxPercentage) / 100);
  // } else {
  //     // providerFeesPercentage = num.parse(
  //     //     systemChargeModel.value?.data?.fromProviderPercentage.toString() ??
  //     //     0.toString());
  //     // providerFeesAmt = (price *
  //     //     (num.parse(systemChargeModel.value?.data?.fromProviderPercentage
  //     //             .toString() ??
  //     //         0.toString())) /
  //     // 100);
  // }

  // ============================= RULS ==========================

  if (Rules?.Rule_1 == data?.ruls) {
    // console.log(Rules?.Rule_1, "Rule")
    ///This will calculate the 1 hour ago payment case.
    // Rule_1,
    if (data?.is_ambassador == true) {
      netFeesPercentage =
        modifyFees(refCodeData, discountDetails)?.modify1HourSysFees -
        modifyFees(refCodeData, discountDetails)?.modify1HourNetFees;
      netFeesAmt = (price * netFeesPercentage) / 100;
      displayNetFeesPercentage =
        old_modifyFees?.modify1HourSysFees - old_modifyFees.modify1HourNetFees;
      displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
    } else {
      netFeesPercentage = modifyFees(
        refCodeData,
        discountDetails
      )?.modify1HourNetFees;
      netFeesAmt = (price * netFeesPercentage) / 100;
      displayNetFeesPercentage = old_modifyFees?.modify1HourNetFees;
      displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
    }
  } else if (Rules?.Rule_2 == data?.ruls) {
    // console.log(Rules?.Rule_2, "Rule")
    ///This will  calculate the 2 time modified payment case.
    // Rule_2,

    // console.log(displayNetFeesPercentage, "participant")

    if (data?.is_ambassador == true) {
      // netFeesPercentage = modifyFees(refCodeData, discountDetails)?.modify2TimeSysFees - modifyFees(refCodeData, discountDetails)?.modify2TimeNetFees;
      netFeesPercentage =
        15 - modifyFees(refCodeData, discountDetails)?.modify2TimeNetFees;

      netFeesAmt = (price * netFeesPercentage) / 100;
      displayNetFeesPercentage =
        old_modifyFees?.modify2TimeSysFees - old_modifyFees.modify2TimeNetFees;
      displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
    } else {
      netFeesPercentage = modifyFees(
        refCodeData,
        discountDetails
      )?.modify2TimeNetFees;
      // console.log(netFeesPercentage, "netFeesPercentage")
      netFeesAmt = (price * netFeesPercentage) / 100;
      displayNetFeesPercentage = old_modifyFees?.modify2TimeNetFees;
      displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
    }
  } else if (Rules?.Rule_3 == data?.ruls) {
    // console.log(Rules?.Rule_3, "Rule")
    ///24 hour case.

    netFeesPercentage = 0;
    netFeesAmt = (price * netFeesPercentage) / 100;
    displayNetFeesPercentage = 0;
    displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
  } else if (Rules?.Rule_4 == data?.ruls) {
    // console.log(Rules?.Rule_4, "Rule")

    ///refund payment case.
    netFeesPercentage = 0;
    netFeesAmt = (price * netFeesPercentage) / 100;
    displayNetFeesPercentage = 0;
    displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
    salesTaxId = null;
    salesTaxPercentage = 0;
    salesTaxAmt = (amount_without_discount * salesTaxPercentage) / 100;
    providerFeesPercentage = 0;
    providerFeesAmt = (price * providerFeesPercentage) / 100;
  } else {
    ///simple payment.

    if (data?.is_ambassador == true) {
      netFeesPercentage = 0;
      netFeesAmt = (price * netFeesPercentage) / 100;
      displayNetFeesPercentage = 0;
      displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
    } else {
      netFeesPercentage = data?.systemCharge?.from_user_percentage ?? 0;
      netFeesAmt = (price * netFeesPercentage) / 100;
      displayNetFeesPercentage = data?.systemCharge?.from_user_percentage ?? 0;
      displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
    }
  }

  // =================================================================

  /// =================== Application Fees calculation =================

  if (isRefundCase(refundPayload) == true) {
    applicationFees =
      Math.abs(salesTaxAmt) + Math.abs(netFeesAmt) + Math.abs(providerFeesAmt);
  } else {
    applicationFees = salesTaxAmt + netFeesAmt + providerFeesAmt;
  }

  // =================================================================

  /// ================== TotalPrice calculation =================

  if (isRefundCase(refundPayload) == true) {
    totalPrice = Math.abs(price) - Math.abs(salesTaxAmt) - Math.abs(netFeesAmt);
  } else {
    totalPrice = price + salesTaxAmt + netFeesAmt;
  }

  // ===================================================================

  /// =================== Calculate the Transfer Amount ==================

  if (isRefundCase(refundPayload) == true) {
    totalTransferedAmount = Math.abs(price) - Math.abs(applicationFees);
  } else {
    totalTransferedAmount = totalPrice - applicationFees;
  }

  // ====================================================================

  // =========================== set state ===========================

  const modifyCharges = {
    participate: data?.newParticipants,
    serviceAmount: data?.packagePrice,
    totalAmount: price,
    totalPaidAmount: totalPrice,
    feesPercentageFromUser: netFeesPercentage,
    feesAmountFromUser: netFeesAmt,
    displayFeesPercentageFromUser: displayNetFeesPercentage,
    displayFeesAmountFromUser: displayNetFeesAmt,
    salesTaxId: salesTaxId,
    salesTaxPercentage: salesTaxPercentage,
    salesTaxAmountFromUser: salesTaxAmt,
    feesPercentageFromProvider: providerFeesPercentage,
    feesAmountFromProvider: providerFeesAmt,
    totalTransferedAmount: totalTransferedAmount,
    applicationFees: applicationFees,
    is_referee: refCodeData?.referee?.is_referee,
    referee_fees_percentage: refCodeData?.referee?.percentage,
    referree_referral_code_id: refCodeData?.referee?.id,
    referer_referral_code_id: refCodeData?.referrer?.id,
    // amount_without_discount: productPrice,
    amount_without_discount: amount_without_discount,
    discount_amount: discountAmount,
    discount_percentage: discountPercentage,
    package_price: packagePrice,
  };

  console.log(modifyCharges, "participant");

  setModifyCharges(modifyCharges);

  ModifyPaymentInfoUtils(
    modifyCharges,
    data,
    setModifyBookingInfo,
    discountDetails
  );
};

export const modifyEventPriceCalculations = (
  data,
  setModifyCharges,
  setModifyBookingInfo,
  refCodeData,
  discountDetails
) => {
  const refundPayload = {
    totalParticipants: data?.totalParticipants,
    packagePrice: data?.packagePrice,
    previousPrice: data?.previousPrice,
  };

  var packagePrice = 0;
  var price = 0;
  var totalPrice = 0;
  var salesTaxAmt = 0;
  var netFeesAmt = 0;
  var salesTaxPercentage = 0;
  var netFeesPercentage = 0;
  var displayNetFeesAmt = 0;
  var displayNetFeesPercentage = 0;
  var providerFeesPercentage = 0;
  var providerFeesAmt = 0;
  var totalTransferedAmount = 0;
  var applicationFees = 0;
  var salesTaxId = null;
  var discountAmount = 0;
  var discountPercentage = 0;

  packagePrice = data?.packagePrice;

  var productPrice = data?.packagePrice;
  discountPercentage = discountDetails?.discount_percentage;

  // ============================= RULS ==========================

  if (Rules?.Rule_1 == data?.ruls) {
    if (data?.is_ambassador == true) {
      netFeesPercentage = 10;
      displayNetFeesPercentage = 10;
      // netFeesAmt = (price * (netFeesPercentage) / 100)
      // displayNetFeesAmt = (price * (displayNetFeesPercentage) / 100)
    } else {
      netFeesPercentage = 15;
      displayNetFeesPercentage = 15;
      // netFeesAmt = (price * (netFeesPercentage) / 100);
      // displayNetFeesAmt = (price * (displayNetFeesPercentage) / 100)
    }
  } else {
    netFeesPercentage = 0;
    displayNetFeesPercentage = 0;
    // netFeesAmt = (price * (netFeesPercentage) / 100);
    // displayNetFeesAmt = (price * (displayNetFeesPercentage) / 100)
  }

  if (
    discountDetails?.is_discount == true &&
    discountDetails?.is_admin_created == false
  ) {
    // discountAmount =
    //   (productPrice * discountDetails?.discount_percentage) / 100;
    // packagePrice = productPrice - discountAmount;

    discountPercentage =
      discountDetails?.discount_percentage || 0 > 0
        ? discountDetails?.discount_percentage
        : 0;
  } else if (
    discountDetails?.is_discount == true &&
    discountDetails?.is_admin_created == true
  ) {
    displayNetFeesPercentage =
      displayNetFeesPercentage - discountDetails?.discount_percentage || 0 > 0
        ? displayNetFeesPercentage - discountDetails?.discount_percentage
        : 0;
  }

  salesTaxPercentage = data?.tax_rate ?? 0;
  price = data?.totalParticipants * data?.packagePrice;
  salesTaxAmt = (price * salesTaxPercentage) / 100;
  netFeesAmt = (price * netFeesPercentage) / 100;
  displayNetFeesAmt = (price * displayNetFeesPercentage) / 100;
  totalPrice = price + salesTaxAmt + netFeesAmt;

  applicationFees = salesTaxAmt + netFeesAmt + providerFeesAmt;
  totalTransferedAmount = totalPrice - applicationFees;

  const amount_without_discount =
    (data?.packagePrice ?? 0) *
    ((data?.totalParticipants ?? 0) - (data?.previous_participants ?? 0));

  //   if (isRefundCase(refundPayload) == true) {
  //     price =
  //       ((data?.previousPrice ?? 0) / (data?.previous_participants ?? 0)) *
  //       ((data?.previous_participants ?? 0) - (data?.totalParticipants ?? 0)); ///Sales Tax
  //   } else {
  //     price =
  //       (packagePrice ?? 0) *
  //       ((data?.totalParticipants ?? 0) - (data?.previous_participants ?? 0)); ///Sales Tax
  //   }

  // console.log(data, "participant")

  /// =========================== Sales Tax =======================

  //   if (isRefundCase(refundPayload) == true) {
  //     salesTaxId = null;
  //     salesTaxPercentage = 0;
  //     salesTaxAmt = (amount_without_discount * salesTaxPercentage) / 100;
  //   } else {
  //     // if (salesTax.value != null) {
  //     salesTaxPercentage = data?.tax_rate ?? 0;
  //     salesTaxAmt = (amount_without_discount * salesTaxPercentage) / 100;
  //     salesTaxId = data?.tax_id;
  //     // }
  //   }

  /// =================== Application Fees calculation =================

  //   if (isRefundCase(refundPayload) == true) {
  //     applicationFees =
  //       Math.abs(salesTaxAmt) + Math.abs(netFeesAmt) + Math.abs(providerFeesAmt);
  //   } else {
  //     applicationFees = salesTaxAmt + netFeesAmt + providerFeesAmt;
  //   }

  // =================================================================

  /// ================== TotalPrice calculation =================

  //   if (isRefundCase(refundPayload) == true) {
  //     totalPrice = Math.abs(price) - Math.abs(salesTaxAmt) - Math.abs(netFeesAmt);
  //   } else {
  //     totalPrice = price + salesTaxAmt + netFeesAmt;
  //   }

  // ===================================================================

  /// =================== Calculate the Transfer Amount ==================

  //   if (isRefundCase(refundPayload) == true) {
  //     totalTransferedAmount = Math.abs(price) - Math.abs(applicationFees);
  //   } else {
  //     totalTransferedAmount = totalPrice - applicationFees;
  //   }

  // ====================================================================

  // =========================== set state ===========================

  const modifyCharges = {
    participate: data?.newParticipants,
    serviceAmount: data?.packagePrice,
    totalAmount: price,
    totalPaidAmount: totalPrice,
    feesPercentageFromUser: netFeesPercentage,
    feesAmountFromUser: netFeesAmt,
    displayFeesPercentageFromUser: displayNetFeesPercentage,
    displayFeesAmountFromUser: displayNetFeesAmt,
    salesTaxId: salesTaxId,
    salesTaxPercentage: salesTaxPercentage,
    salesTaxAmountFromUser: salesTaxAmt,
    feesPercentageFromProvider: providerFeesPercentage,
    feesAmountFromProvider: providerFeesAmt,
    totalTransferedAmount: totalTransferedAmount,
    applicationFees: applicationFees,
    is_referee: refCodeData?.referee?.is_referee,
    referee_fees_percentage: refCodeData?.referee?.percentage,
    referree_referral_code_id: refCodeData?.referee?.id,
    referer_referral_code_id: refCodeData?.referrer?.id,
    // amount_without_discount: productPrice,
    amount_without_discount: amount_without_discount,
    discount_amount: discountAmount,
    discount_percentage: discountPercentage,
    package_price: packagePrice,
  };

  console.log(modifyCharges, "modifyCharges");

  //   setModifyCharges(modifyCharges);

  //   ModifyPaymentInfoUtils(
  //     modifyCharges,
  //     data,
  //     setModifyBookingInfo,
  //     discountDetails
  //   );
};

const ModifyPaymentInfoUtils = (
  modifyCharges,
  data,
  setModifyBookingInfo,
  discountDetails
) => {
  const refundPayload = {
    totalParticipants: data?.totalParticipants,
    packagePrice: data?.packagePrice,
    previousPrice: data?.previousPrice,
  };

  const PAYLOAD = {
    old_participate: data?.previous_participants ?? 0,
    new_participate: modifyCharges?.participate ?? 0,
    old_amount: Number(data?.previousPrice ?? 0),
    new_amount: Math.abs(modifyCharges?.totalAmount ?? 0),
    old_fees_percentage_from_user: Number(
      data?.old_fees_percentage_from_user ?? 0
    ),
    old_fees_amount_from_user: Number(data?.old_fees_amount_from_user ?? 0),
    old_sales_tax_percentage: Number(data?.old_sales_tax_percentage ?? 0),
    old_sales_tax_amount_from_user: Number(
      data?.old_sales_tax_amount_from_user ?? 0
    ),
    fees_percentage_from_user: modifyCharges?.feesPercentageFromUser ?? 0,
    fees_amount_from_user: Math.abs(modifyCharges?.feesAmountFromUser ?? 0),
    sales_tax_percentage: modifyCharges?.salesTaxPercentage ?? 0,
    sales_tax_amount_from_user: Math.abs(
      modifyCharges?.salesTaxAmountFromUser ?? 0
    ),
    old_total_paid_amount: Number(data?.totalPaidAmount ?? 0),
    new_total_paid_amount: Math.abs(modifyCharges?.totalPaidAmount ?? 0),
    is_refund: isRefundCase(refundPayload),
    total_transfered_amount: Math.abs(
      modifyCharges?.totalTransferedAmount ?? 0
    ),
    application_fees: Math.abs(modifyCharges?.applicationFees ?? 0),
    sales_tax_id: modifyCharges?.salesTaxId,
    fees_percentage_from_provider:
      modifyCharges?.feesPercentageFromProvider ?? 0,
    fees_amount_from_provider: Math.abs(
      modifyCharges?.feesAmountFromProvider ?? 0
    ),
    referee_fees_amount_from_user: modifyCharges?.displayFeesAmountFromUser,
    is_referee: modifyCharges?.is_referee,
    referee_fees_percentage: modifyCharges?.referee_fees_percentage,
    referer_referral_code_id: modifyCharges?.referer_referral_code_id,
    referree_referral_code_id: modifyCharges?.referree_referral_code_id,
    is_discount: discountDetails?.is_discount,
    amount_without_discount: modifyCharges?.amount_without_discount,
    discount_amount: Number(modifyCharges?.discount_amount ?? 0),
    discount_percentage: Number(modifyCharges?.discount_percentage ?? 0),
    is_admin_discount: discountDetails?.is_admin_created,
  };
  setModifyBookingInfo(PAYLOAD);
};
