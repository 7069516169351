import React, {useState} from 'react';
import {Grid, IconButton} from "@mui/material";
import shareIcon from "../../assets/images/share_icon.png"
import copyIcon from "../../assets/images/copy_icon.png"
import moment from "moment";
import CodeShare from "./CodeShare";
import {refCodeGenerate} from "../../action/ReffDiscountConstant";
import {useDispatch, useSelector} from "react-redux";
import DiscountPopup from "./DiscountPopup";
import GenerateCodePopup from "./GenerateCodePopup";
import {deleteDiscount} from "../../action/discountCodeConstant";
import DiscountCongratulationsPopup from "./DiscountCongratulationsPopup";


const checkCodeExpire = (date, ele) => {
    // data?.map((ele) => {
    const EXDATE = new Date(date).toLocaleDateString()
    const TODAY = new Date().toLocaleDateString()
    if (date != null) {
        if (TODAY <= EXDATE) {
            return <div className="d-flex align-items-center justify-content-between">
                <div>
                    <p className="t-15-500 m-0" style={{color: '#8E93AE'}}>Expiration
                        Date: {moment(date).format("D MMMM YYYY")}</p>
                    <div>
                        {ele?.is_admin_created ?
                            <p className="t-15-bold m-0"
                               style={{marginLeft: 'auto !important'}}>Created by Admin</p>
                            : null}
                    </div>
                </div>
                <p className="t-15-bold m-0"
                   style={{color: '#0D982B', background: '#DDFFE4', padding: '5px', borderRadius: '5px'}}>ACTIVE</p>
            </div>
        } else {
            return <div className="d-flex align-items-center justify-content-between">
                <div>
                    <p className="t-15-500 m-0" style={{color: '#8E93AE'}}>Expiration
                        Date: {moment(date).format("D MMMM YYYY")}</p>
                    <div>
                        {ele?.is_admin_created ?
                            <p className="t-15-bold m-0"
                               style={{marginLeft: 'auto !important'}}>Created by Admin</p> : null}
                    </div>
                </div>
                <p className="t-15-bold m-0"
                   style={{
                       color: '#F46F6F',
                       background: '#FFE1E1',
                       padding: '5px',
                       borderRadius: '5px'
                   }}>EXPIRED</p>
            </div>
        }
    } else {
        return <div className="d-flex align-items-center justify-content-between">
            <p className="t-15-500 m-0" style={{color: '#8E93AE'}}></p>
            <p className="t-15-bold m-0"
               style={{color: '#0D982B', background: '#DDFFE4', padding: '5px', borderRadius: '5px'}}>ACTIVE</p>
        </div>
    }

}

const DscountCode = ({data, showBtn, setShowBanner, getDiscountList, setOpenDiscountSucsess, openDiscountSuccsess}) => {
    const [openShare, setOpenShare] = useState(false);
    const [sharedData, setSharedData] = useState({});
    const dispatch = useDispatch()
    const [openDetails, setOpenDetails] = useState(false)
    const [codeData, setCodeData] = useState({})
    const [is_generate, setIs_generate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const {discountCode} = useSelector((state) => state)

    function myFunction(code) {
        // Get the text field
        navigator.clipboard.writeText(code);
        // Alert the copied text
        // alert("Copied the text: " + copyText.value);
    }

    const handleOpenModle = (event) => {
        if (event?.target?.classList?.contains('btnn')) {
            // console.log(true, "className")
            return;
        } else {
            setOpenDetails(true);
        }
    }

    const handleDelete = (id) => {
        dispatch(deleteDiscount(id, () => {getDiscountList()}, () => {setOpenDetails(false)}))
    }


    return (
        <>
            <div>
                {/*<h1 className="d-inline-block mt-4">REFER YOUR FRIENDS</h1>*/}
            </div>
            <div className="position-relative" style={{height: 'calc(100vh - 365px)'}}>
                <div className="position-relative" style={{height: '80%'}}>
                    <p className="t-17-600 mt-3">Discounts you create only apply to your earnings, not platform fees.
                        Even with a 100% discount, participants still pay fees on the original price. For any queries,
                        contact support.</p>
                    <div className="scheduleScroll" style={{
                        height: '100%',
                        overflow: 'auto'
                    }}>
                        <Grid container spacing={2}>
                            {data?.map((ele) => (
                                <Grid item xs={12} md={6}>
                                    <div className="ref-code-box c-pointer" onClick={(e) => {
                                        handleOpenModle(e)
                                        setCodeData(ele)
                                    }}>
                                        <p className="m-0 t-16-bold" style={{color: '#8E93AE'}}>YOUR CODE</p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div style={{display: 'contents'}}>
                                                <p className="t-20-bold m-0 mt-1"
                                                   style={{color: '#363853'}}>{ele?.discount_code}</p>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <button className="btnn">
                                                    <IconButton sx={{padding: '0'}} className="btnn" onClick={() => {
                                                        setOpenShare(true)
                                                        setSharedData(ele)
                                                    }}>
                                                        <img src={shareIcon} alt=""  className="btnn"/>
                                                    </IconButton>
                                                </button>
                                                <button className="btnn">
                                                    <IconButton sx={{padding: '0'}} className="btnn" onClick={() => {
                                                        myFunction(ele?.discount_code)
                                                    }}>
                                                        <img src={copyIcon} alt=""  className="btnn"/>
                                                    </IconButton>
                                                </button>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="w-100">
                                                {checkCodeExpire(ele?.expire_date, ele)}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>

                </div>


                {
                    <div
                        className="d-flex flex-wrap align-items-center justify-content-end mt-4 position-absolute g-code-btn"
                        style={{
                            position: 'absolute',
                            width: '100%',
                            bottom: '0',
                            right: '0'
                        }}>
                        <button className="massageBtn mt-3"
                                onClick={() => {
                                    // setShowBanner(true)
                                    setIs_generate(true)
                                }}
                                style={{
                                    maxWidth: "350px",
                                    height: "45px",
                                    fontWeight: '600'
                                }}>Generate Code
                        </button>
                    </div>
                }
            </div>
            <CodeShare open={openShare} setOpen={setOpenShare} data={sharedData} is_discount={true}/>
            <DiscountPopup setOpen={setOpenDetails} open={openDetails} data={codeData} setOpenShare={setOpenShare} handleDelete={handleDelete} setSharedData={setSharedData} openShare={openShare} setIsEdit={setIsEdit} editModle={setIs_generate} is_generate={is_generate}/>
            <GenerateCodePopup setOpen={setIs_generate} open={is_generate} is_edit={isEdit} data={codeData} setIsEdit={setIsEdit}  setOpenDiscountSucsess={setOpenDiscountSucsess} setOpenShare={setOpenDetails}/> 
            <DiscountCongratulationsPopup  open={openDiscountSuccsess} setOpen={setOpenDiscountSucsess} data={discountCode?.addedDiscount} setOpenShare={setOpenShare} setSharedData={setSharedData}/>
        </>
    )
}

export default DscountCode;