import React from "react";
import { Modal } from "react-bootstrap";
import closeRedIcon from "../../assets/images/close-colorfull-icon.png";
import image from "../../assets/images/layout1.jpg";
import locationIcon from "../../assets/images/Discovery.svg";
import { IconButton } from "@mui/material";
import userIcon from "../../assets/images/userIconWithBg.png";
import { capital } from "../../Utils/capitalUtils";

const UserProfile = ({ open, setOpen, data }) => {
  return (
    <>
      <Modal
        centered
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        dialogClassName="delete-modal"
      >
        <div
          className="Congratulations_msg p-3"
          style={{ textAlign: "center" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <p className="t-24-bold m-0">Profile</p>
            <div>
              <IconButton onClick={() => setOpen(false)}>
                <img src={closeRedIcon} alt="" />
              </IconButton>
            </div>
          </div>
          <div>
            <div
              className="polygon-imgae"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                transform: "scale(0.7)",
              }}
            >
              <div className="p_image_shape">
                <img src={data?.user_id?.profile_picture ?? userIcon} />
              </div>
            </div>
            <p className="t-20-bold m-0">{data?.user_id?.name}</p>
            <p className="t-14-bold m-0">
              {capital(data?.user_id?.user_type ?? "")}
            </p>
            <div className="d-flex align-items-start justify-content-center gap-2">
              <img src={locationIcon} alt="" />
              <p className="t-14-500 m-0" style={{ width: "73%" }}>
                {data?.user_id?.address}
              </p>
            </div>
            <hr />
            <div>
              <p className="t-17-bold text-start m-0">Package Details</p>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p className="t-15-bold m-0 text-start text-black">
                  {data?.event_package_id?.package_name}
                </p>
                <p className="t-12-bold m-0 text-start">
                  Value $
                  {Number(
                    data?.event_package_id?.value_per_person ?? 0
                  ).toFixed(2)}{" "}
                  / Person
                </p>
              </div>
              <p className="t-12-bold m-0 text-start">
                {data?.no_of_participants} Participants
              </p>
            </div>
            {data?.payment_history_id?.discount_code_id?.discount_code && (
              <>
                <hr />
                <div className="mt-3 d-flex align-items-center justify-content-between ">
                  <p className="t-17-bold text-start m-0">Discount Code</p>
                  <div className="mt-0">
                    <p className="t-15-bold m-0 text-start text-black">
                      {
                        data?.payment_history_id?.discount_code_id
                          ?.discount_code
                      }
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <button
              className="massageBtn massageBtn3 mt-3 btnn"
              style={{
                marginTop: "41px !important",
                height: "60px",
                maxWidth: "360px",
              }}
            >
              Send Message
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserProfile;
