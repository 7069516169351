import { react, useEffect } from "react";
import Loader from "../Component/Loader";
import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";
import { toast } from "react-toastify";

const BankVerification = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("bank_id");

  useEffect(() => {
    const apidata = new FormData();
    apidata.append("bank_account_id", myParam);
    fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_verification_list}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: apidata,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.success) {
          window.location.href = data?.data;
        } else {
          toast.error(data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(myParam);
  return (
    <>
      <Loader />
    </>
  );
};

export default BankVerification;
