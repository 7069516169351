import React, {useEffect, useState} from 'react';
import PlusIcon from "../../assets/images/plus.png";

const AddMedia = ({
                      isSmall = false,
                      setDetails,
                      setPackageDetails,
                      packageDetails,
                      isPackageEdit,
                      isEventEdit = false,
                      media,
                      packageDelateId,
                      setDeletedId
                  }) => {
    const [registration, setregistration] = useState({
        provider_banner_images: [],
    });

    const [selectServiceImage, setselectServiceImage] = useState([]);
    const [serviceImage, setserviceImage] = useState([]);

    useEffect(() => {
        if (isSmall) {
            setPackageDetails((pre) => ({...pre, media: registration?.provider_banner_images}))
        } else {
            // setDetails((pre) => ({...pre, media: registration?.provider_banner_images}))
        }
    }, [registration]);


    useEffect(() => {
        if (isPackageEdit) {
            setregistration((pre) => ({...pre, provider_banner_images: packageDetails?.media ?? []}))
            setselectServiceImage(packageDetails?.media ?? [])
        }
        if (isEventEdit) {
            setregistration((pre) => ({...pre, provider_banner_images: media ?? []}))
            setselectServiceImage(media ?? [])
        }
    }, [isPackageEdit, isEventEdit, media]);

    // console.log(selectServiceImage, "selectServiceImage")

    return (
        <>
            <div className="inner-gred box_shadow">
                {isSmall ? null : <p className="t-16-bold">Add Media</p>}
                <div className={`multi_imgs ${isSmall ? 'mt-0' : 'mt-3'}`}>
                    {serviceImage?.map((value, i) => {
                        return (<>
                            <div className="hexagone"
                                 style={{
                                     width: isSmall ? '61px' : "70px",
                                     height: isSmall ? '51px' : '62px',
                                     marginRight: isSmall ? '7px' : '15px'
                                 }}>
                                <span
                                    className="remove"
                                    onClick={() => {
                                        const newImage = serviceImage?.filter((data, j) => {
                                            return i !== j;
                                        });
                                        setserviceImage(newImage);
                                        setDeletedId([...packageDelateId, value._id])
                                        setregistration({
                                            ...registration,
                                            delete_banner_image_ids: [...registration.delete_banner_image_ids, value._id,],
                                        });
                                    }}
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g id="Icons">
                                            <path
                                                id="Vector"
                                                d="M12.3865 12.8635L7.61351 8.09054M7.61351 12.8635L12.3865 8.09054"
                                                stroke="#FF0000"
                                                strokeWidth="1.39212"
                                                stroke-linecap="round"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <img src={value.file_name || window.URL.createObjectURL(value)}/>
                            </div>
                        </>);
                    })}
                    {selectServiceImage?.map((value, i) => {
                        // if (i <= 6) {
                        return (<>
                            <div className="hexagone"
                                 style={{
                                     width: isSmall ? '61px' : "70px",
                                     height: isSmall ? '51px' : '62px',
                                     marginRight: isSmall ? '7px' : '15px'
                                 }}>
                                <span
                                    className="remove"
                                    onClick={() => {
                                        const newImage = selectServiceImage?.filter((data, j) => {
                                            return i !== j;
                                        });
                                        const newFormData = registration.provider_banner_images?.filter((data, j) => {
                                            return i !== j;
                                        });
                                        if (value?._id) {
                                            setDeletedId((pre) => ([...pre, value?._id]))
                                        }

                                        setselectServiceImage(newImage);
                                        setregistration({
                                            ...registration, provider_banner_images: newFormData,
                                        });
                                    }}
                                >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g id="Icons">
                                                    <path
                                                        id="Vector"
                                                        d="M12.3865 12.8635L7.61351 8.09054M7.61351 12.8635L12.3865 8.09054"
                                                        stroke="#FF0000"
                                                        strokeWidth="1.39212"
                                                        stroke-linecap="round"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                {/*{value.file_name ?*/}
                                <img src={value?.file_url || value.file_name || window.URL.createObjectURL(value)}/>
                                {/*: null}*/}
                            </div>
                        </>);
                        // }
                    })}
                    {serviceImage?.length + selectServiceImage?.length < 7 && (<>
                        <div className="d-inline-block">
                            <div className="custom-upload-2" style={{transform: isSmall ? 'scale(0.9)' : 'scale(1)'}}>
                                <input
                                    type="file"
                                    name=""
                                    multiple={true}
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => {
                                        const ImageArray = Array.from(e.target.files);
                                        // console.log(ImageArray, "ImageArray")
                                        if (ImageArray?.length > 0) {
                                            ImageArray?.map((ele) => {
                                                // console.log(ele, "ele")
                                                setselectServiceImage((pre) => ([...pre, {file_name: window.URL.createObjectURL(ele)}]))
                                                // setselectServiceImage([...selectServiceImage, {
                                                //     file_name: window.URL.createObjectURL(ele),
                                                // },]);
                                            })
                                            setregistration({
                                                ...registration,
                                                provider_banner_images: [...registration.provider_banner_images, ...ImageArray,],
                                            })
                                        }
                                        e.target.value = "";
                                    }}
                                />
                                <img src={PlusIcon}/>
                            </div>
                        </div>
                    </>)}
                </div>
            </div>
        </>
    )
}

export default AddMedia;