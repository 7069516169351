import React, {useState} from "react";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import Modal from "@mui/material/Modal";
import Carousel from "react-multi-carousel";
import {CustomLeftArrow, CustomRightArrow} from "./CarouselArrows";
import {Swiper, SwiperSlide} from 'swiper/react';
import {A11y, Navigation} from 'swiper/modules';
import {Modal} from "react-bootstrap";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    height: '100%',
    maxHeight: '400px',
    // background : 'white',
    // bgcolor: 'white',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 1
    },
    desktop: {
        breakpoint: {max: 3000, min: 1220},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1220, min: 800},
        items: 1
    },
    mobile: {
        breakpoint: {max: 800, min: 0},
        items: 1
    }
};

const SliderPopup = (props) => {
    const {open, handleClose, imageUrl, userDetails, sliderIndex} = props
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const handleImageLoaded = () => {
        setLoaded(true);
    };

    const handleImageError = () => {
        setError(true);
    };

    // console.log(userDetails, "userDetails")

    return (
        <div className="slide-modle">
            <Modal
                className="modal-sticky absolute_center modal-sticky-lg modal-sticky-bottom-right document-modal slide-modle"
                id="kt_inbox_compose1"
                role="dialog"
                centered
                data-backdrop="false"
                aria-hidden="true"
                tabIndex="-1"
                show={open}
                onHide={() => {
                    handleClose();
                }}
                animation={true}
                size="lg"
            >
                <div>
                    <div style={{ height: 'calc(100vh - 300px)', textAlign: 'center' }}>
                        <div className="position-relative h-100">

                            <div className="h-100">
                                <Swiper modules={[Navigation, A11y]}
                                        initialSlide={sliderIndex ?? 0}
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        navigation
                                        pagination={{clickable: true}}
                                        onSwiper={(swiper) => {
                                        }}
                                        style={{height: '100%'}}
                                        onSlideChange={() => {
                                        }}
                                        autoplay={{delay: 5000}}
                                        className="w-100"
                                >
                                    {userDetails?.map((ele) => (
                                        <SwiperSlide>
                                            <img src={ele?.file_name ? ele?.file_name : ele} alt=""
                                                 style={{
                                                     width: 'auto',
                                                     height: '100%',
                                                     maxWidth: '100%',
                                                     objectFit: 'contain'
                                                 }}/>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
                {/*<div style={style}>*/}

                {/*</div>*/}
          </div>
    )
}

export default SliderPopup;