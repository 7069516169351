import React from 'react';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "react-bootstrap";
import locationIcon from "../assets/images/Discovery.svg";
import calenderIcon from "../assets/images/calender.png";
import clockIcon from "../assets/images/clock.svg"
import moment from "moment/moment";
import {DateFormate, LocalFormate, timeZoneFormate} from "../Utils/DateFormateUtils";

const SchedulePopup = ({open, setOpen, data}) => {

    // console.log(data, "viewScheduleData")
    return (
        <>
            <Modal
                centered
                show={open}
                backdrop="static"
                onHide={() => {
                    setOpen(false);
                }}
                dialogClassName="delete-modal"
            >
                <>
                    <div className="Congratulations_msg">
                        <div className="group" style={{borderRadius: "10px"}}>
                            <div className="py-2 px-2" style={{borderRadius: "10px"}}>
                                <div className="d-flex justify-content-between">
                                    <p className="t-20-bold">Schedule Details</p>
                                    <IconButton
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        sx={{
                                            background: "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)",
                                            height: "40px"
                                        }}>
                                        <CloseIcon/>
                                    </IconButton>
                                </div>
                                <div>
                                    <div className="d-flex gap-1 align-items-center">
                                        <img src={locationIcon} alt=""/>
                                        <p className="m-0 t-14-500 text-black">{data?.address}</p>
                                    </div>
                                    <div className="d-flex gap-1 align-items-center mt-2">
                                        <img src={calenderIcon} alt=""/>
                                        {/*<p className="m-0 t-14-500 text-black">{moment(data?.schedule_start_date).format('MMM Do YYYY') !== moment(data?.schedule_end_date).format('MMM Do YYYY') ? (`${moment(data?.schedule_start_date).format('Do MMM')} - ${moment(data?.schedule_end_date).format('Do MMM, YYYY')}`) : (`${moment(data?.schedule_start_date).format('MMMM Do YYYY')}`)}</p>*/}
                                        <p className="m-0 t-14-500 text-black">{DateFormate(LocalFormate(data?.schedule_start_date, data?.date_timezone), LocalFormate(data?.schedule_end_date, data?.date_timezone))}</p>
                                    </div>
                                    <div className="d-flex gap-1 align-items-center mt-2">
                                        <img src={clockIcon} alt=""/>
                                        <p className="m-0 t-14-500 text-black">{moment(LocalFormate(data?.schedule_start_date, data?.date_timezone)).format('LT')} to {moment(LocalFormate(data?.schedule_end_date, data?.date_timezone)).format('LT')} ({timeZoneFormate(data?.schedule_start_date, data?.date_timezone)})</p>
                                    </div>
                                </div>
                                <div className="mt-3 mb-3">
                                    <p className="t-16-bold m-0">Services</p>
                                    <div className="selected_services">
                                        {
                                            data?.service_id?.map((ele) => (
                                                <span>{ele?.service_name}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </>
    )
}

export default SchedulePopup;