import React, { useEffect, useState } from "react";
import { IconButton, Popover, Typography } from "@mui/material";
import filterIcon from "../../assets/images/Sort.png";
import HireCard from "./MultiCard";
import EventCard from "./EventCard";
import Important from "./Important";
import NewMessage from "./NewMessage";
import Collaborate from "./Collaborate";
import {
  deleteNotification,
  fatcheMoreNotificationList,
  notificationList,
  seen_unseen_notification,
  updateNotification,
} from "../../action/NotificationConstant";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  fetchMoreGuestService,
  fetchMoreService,
} from "../../action/userServiceConstant";
import Reminder from "./Reminder";
import BookingReminder from "./BookingReminder";
import NewReview from "./NewReview";
import BookingCard from "./BookingCard";
import Payment from "./Payment";
import HireCollabrote from "./HireCollabrote";
import { Empty } from "antd";
import ModifyBooking from "./ModifyBooking";
import moment from "moment/moment";
import Event_Invitation from "./Event_Invitation";
import badgeIcon from "../../assets/images/red_notification_icon.png";
import MultiCard from "./MultiCard";
import  BookingReview from "./BookingReview";
import { LocalFormate } from "../../Utils/DateFormateUtils";

const Notification = ({ id, open, anchorEl, handleClose, setOpen }) => {
  const dispatch = useDispatch();
  const [center, setCenter] = useState({});
  const { notifications } = useSelector((state) => state);
  const [pagePagination, setPagePagination] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [notifilter, setNotifilter] = useState("");
  const [seenedId, setSeenedId] = useState("");

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const openfilter = Boolean(anchorElFilter);

  const filterId = openfilter ? "simple-popover" : undefined;

  const getNotificationsList = () => {
    const payload = {
      page: 1,
      limit: 10,
      lat: center?.lat,
      long: center?.lng,
    };
    dispatch(notificationList(payload));
  };

  const formatBadge = (notiType, isEvent) => {
    if (notiType?.toLowerCase() == "create_service") {
      return "New Service"; // else
    } else if (notiType?.toLowerCase() == "create_event") {
      return "New Event";
    } else if (notiType?.toLowerCase() == "accept_event_invitation") {
      return "Accept Invitation"; // else
    } else if (notiType?.toLowerCase() == "decline_event_invitation") {
      return "Decline Invitation"; // else
    } else if (notiType?.toLowerCase() == "provider_registration_close") {
      return "Registration Close"; // else * --
    } else if (notiType?.toLowerCase() == "cancel_event") {
      return "Cancel Event"; // else * --
    } else if (notiType?.toLowerCase() == "event_invitation") {
      return "Event Invitation";
    } else if (notiType?.toLowerCase() == "edit_event") {
      return "Edit Event"; // else * --
    } else if (notiType?.toLowerCase() == "hire_request") {
      return "Hiring";
    } else if (notiType?.toLowerCase() == "collaborate_request") {
      return "Collaborate";
    } else if (notiType?.toLowerCase() == "accept_hire_and_collaborate_req") {
      return "Request Accepted";
    } else if (notiType?.toLowerCase() == "cancel_hire_and_collaborate_req") {
      return "Request Declined";
    } else if (notiType?.toLowerCase() == "add_booking") {
      return isEvent == true ? "Event Booking" : "Service Booking";
    } else if (notiType?.toLowerCase() == "booking_confirm") {
      return "Confirm Booking";
    } else if (notiType?.toLowerCase() == "booking_cancel") {
      return "Cancel Booking";
    } else if (
      notiType?.toLowerCase() == "create_nearby_event" ||
      notiType?.toLowerCase() == "near by new event"
    ) {
      return "Important";
    } else if (notiType?.toLowerCase() == "match_service_event") {
      return "New Event";
    } else if (notiType?.toLowerCase() == "notify_event") {
      return "Booking On"; // else * --
    } else if (notiType?.toLowerCase() == "payment_refund") {
      return "Payment Refund"; // else
    } else if (notiType?.toLowerCase() == "modify_amount_request") {
      return "Modify Request";
    } else if (notiType?.toLowerCase() == "accept_modify_amount_request") {
      return "Accept Request";
    } else if (notiType?.toLowerCase() == "cancel_modify_amount_request") {
      return "Decline Request";
    } else if (notiType?.toLowerCase() == "meeting_appointment_reminder") {
      return "Reminder";
    } else if (notiType?.toLowerCase() == "meeting_link_generated") {
      return "Join Link"; //else *
    } else if (notiType?.toLowerCase() == "modified_booking") {
      return "Modify Booking";
    } else if (notiType?.toLowerCase() == "question_chat_message") {
      return "Question?"; //else
    } else if (notiType?.toLowerCase() == "add_review") {
      return "New Review";
    } else if (notiType?.toLowerCase() == "booking_confirm_refund") {
      return "Refund"; //else
    } else if (notiType?.toLowerCase() == "booking_reminder") {
      return "Booking Reminder"; //else
    } else if (notiType?.toLowerCase() == "booking_end_review") {
      return "Booking Review"; //else
    } else {
      return "Rest";
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      //check geolocation available
      //try to get user current location using getCurrentPosition() method
      navigator?.geolocation?.getCurrentPosition(async (position) => {
        // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
        await setCenter({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    } else {
      console.log("Browser doesn't support geolocation!");
    }
  }, []);

  // useEffect(() => {
  //
  //     getNotificationsList()
  // }, [center]);

  const fetchmoreData = () => {
    // console.log('slksjs')
    const payload = {
      page: pagePagination,
      limit: 10,
      lat: center?.lat,
      long: center?.lng,
    };
    dispatch(
      fatcheMoreNotificationList(payload, (a) => {
        setHasMore(a);
      })
    );
    setPagePagination((pre) => pre + 1);
  };

  const handleFilter = (type) => {
    const payload = {
      filter_type: type,
      page: 1,
      limit: 10,
      lat: center?.lat,
      long: center?.lng,
    };
    dispatch(notificationList(payload, (a) => {}));
  };

  // const getReviewTime = (time) => {
  //     const getTime = moment(time).local().startOf("seconds").fromNow()
  //
  //     if (getTime.includes("a few seconds ago")) {
  //         return "Just Now"
  //     } else {
  //         return getTime
  //     }
  // }

  const getReviewTime = (date) => {
    const LocalDate = LocalFormate(date);
    const now = new Date();
    const DATE = new Date(LocalDate);
    const difference = now.getTime() - DATE.getTime();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

    if (Math.round(difference / oneDay) === 1) {
      // console.log(true, 'time')
      return "Yesterday";
    } else if (Math.round(difference / oneDay) === -1) {
      // console.log(true, 'time')
      return "Tomorrow";
    } else if (
      difference / (60 * 1000) == 0 ||
      Math.round(difference / (60 * 1000)) == 0 ||
      difference / (60 * 1000) == -1 ||
      Math.round(difference / (60 * 1000)) == -1
    ) {
      return "Just Now";
    } else if (difference / (60 * 1000) < 60) {
      return `${Math.round(difference / (60 * 1000))} mins ago`;
    } else if (difference / (60 * 60 * 1000) < 24) {
      // console.log(true, 'time')
      return `${Math.round(difference / (60 * 60 * 1000))} hours ago`;
    } else {
      // console.log(true, 'time')
      return `${DATE.getDate()}/${DATE.getMonth() + 1}/${DATE.getFullYear()}`;
    }
  };

  const seenNotification = (id) => {
    dispatch(updateNotification(id));
    dispatch(seen_unseen_notification(id));
  };

  const handleDeleteNotification = (id) => {
    dispatch(deleteNotification(id));
  };
  const style = {
    width: "100%",
    maxWidth: "400px",
    borderRadius: "15px",
    // Adding media query..
    "@media (max-width:767px)": {
      maxWidth: "356px",
    },
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className="custom-popover"
        PaperProps={{ style: style }}
      >
        <div className="">
          <div className="d-flex justify-content-between align-items-center p-3">
            <p className="mb-0 t-20-bold">Notification</p>
            <IconButton
              sx={{ background: "#F3F3F3" }}
              onClick={(e) => handleClickFilter(e)}
              className="position-relative"
            >
              {notifilter !== "" ? (
                <img
                  src={badgeIcon}
                  alt=""
                  className="position-absolute"
                  style={{
                    top: "0",
                    right: "0",
                    width: "10px",
                  }}
                />
              ) : null}
              <img src={filterIcon} alt="" />
            </IconButton>
          </div>

          <div
            style={{
              height: "calc(100vh - 200px)",
              overflow: "auto",
            }}
            id="scrollableDiv1"
            className="notificationScroll"
          >
            <InfiniteScroll
              dataLength={notifications?.notificationList}
              next={fetchmoreData}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv1"
              className="infinity-list bg-white "
            >
              {!notifications?.notificationList?.length ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>Notification not Found</span>}
                />
              ) : (
                notifications?.notificationList?.map((ele) =>
                  formatBadge(ele?.noti_for, false) === "Important" ? (
                    <div>
                      <Important
                        data={ele}
                        formatBadge={formatBadge}
                        handleClose={handleClose}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : formatBadge(ele?.noti_for, false) === "Reminder" ? (
                    <div>
                      <Reminder
                        data={ele}
                        formatBadge={formatBadge}
                        setOpen={setOpen}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : formatBadge(ele?.noti_for, false) === "New Review" ? (
                    <div>
                      <NewReview
                        data={ele}
                        formatBadge={formatBadge}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : ele?.noti_for === "add_booking" ? (
                    <div>
                      <BookingCard
                        data={ele}
                        formatBadge={formatBadge}
                        setOpen={setOpen}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : formatBadge(ele?.noti_for, false) === "New Event" ? (
                    <div>
                      <EventCard
                        data={ele}
                        formatBadge={formatBadge}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : //     : formatBadge(ele?.noti_for, false) === "Collaborate" ? (
                  //     <Collaborate data={ele} formatBadge={formatBadge} />
                  // )
                  formatBadge(ele?.noti_for, false) === "Confirm Booking" ||
                    formatBadge(ele?.noti_for, false) === "Cancel Booking" ? (
                    <div>
                      <Payment
                        data={ele}
                        formatBadge={formatBadge}
                        getReviewTime={getReviewTime}
                        getNotificationsList={getNotificationsList}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : formatBadge(ele?.noti_for, false) === "Accept Request" ||
                    formatBadge(ele?.noti_for, false) === "Decline Request" ||
                    formatBadge(ele?.noti_for, false) === "Modify Request" ||
                    formatBadge(ele?.noti_for, false) === "Hiring" ||
                    formatBadge(ele?.noti_for, false) === "Collaborate" ||
                    formatBadge(ele?.noti_for, false) === "Request Accepted" ||
                    formatBadge(ele?.noti_for, false) === "Request Declined" ? (
                    <div>
                      <HireCollabrote
                        data={ele}
                        handleClose={handleClose}
                        formatBadge={formatBadge}
                        getNotificationsList={getNotificationsList}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : formatBadge(ele?.noti_for, false) === "Modify Booking" ? (
                    <div>
                      <ModifyBooking
                        data={ele}
                        formatBadge={formatBadge}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : formatBadge(ele?.noti_for, false) ===
                      "Event Invitation" ||
                    formatBadge(ele?.noti_for, false) === "Edit Event" ||
                    formatBadge(ele?.noti_for, false) === "Booking On" ||
                    formatBadge(ele?.noti_for, false) === "Cancel Event" ||
                    formatBadge(ele?.noti_for, false) ===
                      "Registration Close" ? (
                    <div>
                      <Event_Invitation
                        data={ele}
                        formatBadge={formatBadge}
                        getNotificationsList={getNotificationsList}
                        handleClose={handleClose}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : formatBadge(ele?.noti_for, false) ===
                    "Booking Reminder" ? (
                    <div>
                      <BookingReminder
                        data={ele}
                        formatBadge={formatBadge}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : formatBadge(ele?.noti_for, false) === "Booking Review" ? (
                    <div>
                      <BookingReview
                        data={ele}
                        formatBadge={formatBadge}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <MultiCard
                        data={ele}
                        formatBadge={formatBadge}
                        getReviewTime={getReviewTime}
                        seenNotification={() => seenNotification(ele?._id)}
                        handleDeleteNotification={() =>
                          handleDeleteNotification(ele?._id)
                        }
                      />
                    </div>
                  )
                )
              )}
            </InfiniteScroll>

            {/*<HireCard/>*/}
            {/*<EventCard/>*/}
            {/*<Important/>*/}
            {/*<NewMessage/>*/}
            {/*<Collaborate/>*/}
          </div>
        </div>
      </Popover>

      <Popover
        id={filterId}
        open={openfilter}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="pt-3 pb-3 noti-mainDiv">
          <Typography
            className="t-14-bold noti-filter"
            style={{ color: notifilter == "" ? "#FFA500" : "#636363" }}
            sx={{ p: 1 }}
            onClick={() => {
              setNotifilter("");
              getNotificationsList();
              handleCloseFilter();
            }}
          >
            All
          </Typography>
          <Typography
            className="t-14-bold noti-filter"
            style={{
              color: notifilter == "hire_request" ? "#FFA500" : "#636363",
            }}
            sx={{ p: 1 }}
            onClick={() => {
              setNotifilter("hire_request");
              handleFilter("hire_request");
              handleCloseFilter();
            }}
          >
            Hiring
          </Typography>
          <Typography
            className="t-14-bold noti-filter"
            style={{
              color: notifilter == "create_event" ? "#FFA500" : "#636363",
            }}
            sx={{ p: 1 }}
            onClick={() => {
              setNotifilter("create_event");
              handleFilter("create_event");
              handleCloseFilter();
            }}
          >
            New Event
          </Typography>
          <Typography
            className="t-14-bold noti-filter"
            style={{
              color:
                notifilter == "create_nearby_event" ? "#FFA500" : "#636363",
            }}
            sx={{ p: 1 }}
            onClick={() => {
              handleFilter("create_nearby_event");
              setNotifilter("create_nearby_event");
              handleCloseFilter();
            }}
          >
            Important
          </Typography>
          <Typography
            className="t-14-bold noti-filter"
            style={{
              color:
                notifilter == "collaborate_request" ? "#FFA500" : "#636363",
            }}
            sx={{ p: 1 }}
            onClick={() => {
              handleFilter("collaborate_request");
              setNotifilter("collaborate_request");
              handleCloseFilter();
            }}
          >
            Collaborate
          </Typography>
          <Typography
            className="t-14-bold noti-filter"
            style={{ color: notifilter == "massage" ? "#FFA500" : "#636363" }}
            sx={{ p: 1 }}
            onClick={() => {
              handleFilter("massage");
              setNotifilter("massage");
              handleCloseFilter();
            }}
          >
            Message
          </Typography>
          <Typography
            className="t-14-bold noti-filter"
            style={{
              color: notifilter == "new_questions" ? "#FFA500" : "#636363",
            }}
            sx={{ p: 1 }}
            onClick={() => {
              handleFilter("new_questions");
              setNotifilter("new_questions");
              handleCloseFilter();
            }}
          >
            New Questions
          </Typography>
        </div>
      </Popover>
    </>
  );
};

export default Notification;
