import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {IconButton} from "@mui/material";
import closeIcon from "../assets/images/close-circle.svg";
import {claimProviderContact} from "../action/claimConstant";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Claim_ContactUs_modle from "./Claim_ContactUs_modle";

const Claim_ContactUs = ({open, setOpen, providerName, id}) => {
    const [isStatus, setIsStatus] = useState(false);
    const dispatch = useDispatch();
    const [massage, setMassage] = useState("");
    const [error, setError] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const {providerId} = useParams();
    const handleContactUs = () => {
        setIsStatus(true)
        if (massage == "") {
            setIsStatus(false)
            setError("Please Add Message");
        } else {
            setError("");
            const payload = {
                contact_user_id: providerId || id,
                message: massage
            }
            dispatch(claimProviderContact(payload, (a) => {setIsStatus(a)}, (a) => {setOpen(a)}, (a) => {setIsSuccess(a)} ))
        }
    }

    // console.log(providerId, 'providerId')

    useEffect(() => {
        return () => {
            setError("")
            setMassage("")
        }
    }, [open]);

    return (
        <>
            <Modal
                centered
                show={open}
                backdrop="static"
                onHide={() => {
                    setError("")
                    setMassage("")
                    setOpen(false);
                }}
                dialogClassName="claim_account_modal_contact"
            >
                <div className="Congratulations_msg p-4" style={{maxWidth: '100%'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="m-0">Contact Us</h3>
                        <IconButton
                            sx={{background: '#EEEEEE'}}
                            onClick={() => {
                                setOpen(false)
                                setError("")
                                setMassage("")
                            }}
                        >
                            <img src={closeIcon} alt=""/>
                        </IconButton>
                    </div>
                    <div className="mt-4">
                        <div>
                            <p className="t-19-bold mb-0 text-black">Subject:</p>
                            <p className="t-13-bold" style={{color: 'rgba(0, 0, 0, 0.59)'}}>I am having difficulties
                                CLAIMING this account {providerName}.</p>
                        </div>
                        <div className="mt-4">
                            <p className="t-19-bold mb-0 text-black">Message:</p>
                            <p className="t-13-bold" style={{color: 'rgba(0, 0, 0, 0.59)'}}>In your message please
                                include your name, your relationship to this business And some context.</p>
                            <textarea placeholder="Tell us how we can help" onChange={(e) => setMassage(e.target.value)}
                                      value={massage} className="ContactTextArea mt-1" name=""
                                      id="" cols="50"/>
                            <p className="t-14-bold mt-0 text-danger">{error}</p>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">


                            {isStatus ? (<a
                                href="#"
                                onClick={(e) => e.preventDefault()}
                                className="btn_admina"
                            >
                                <div className="loader"></div>
                            </a>) : (<button

                                className="btn_admina"
                                onClick={() => {
                                    handleContactUs()
                                }}

                            >
                                Submit
                            </button>)}
                        </div>
                    </div>
                </div>
            </Modal>
            <Claim_ContactUs_modle setOpen={setIsSuccess} open={isSuccess} />
        </>
    )
}

export default Claim_ContactUs;