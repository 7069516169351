import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import profileImg from "../../assets/images/layout1.jpg";
import moment from "moment/moment";
import HireCollaborateModal from "./InfoPopup/HireCollaborateModal";
import { useDispatch, useSelector } from "react-redux";
import { getOtherProvider } from "../../action/otherProviderConstant";
import whiteBtn from "../../assets/images/cenacleBtn.svg";
import {
  acceptHireCollaNoti,
  calcleHireCollaNoti,
} from "../../action/hireNcollaborateConstant";
import {
  acceptEventNotification,
  cancelEventNotification,
} from "../../action/eventConstant";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import deleteIcon from "../../assets/images/deleteAccIcon.svg";
import userIcon from "../../assets/images/userIconWithBg.png";

const Event_Invitation = ({
  data,
  formatBadge,
  getNotificationsList,
  getReviewTime,
  seenNotification,
  handleDeleteNotification,
  handleClose,
}) => {
  const [openModal, setOpenModal] = useState({ event: false });
  const userDetails = useSelector((state) => state?.otherProviderDetails?.data);
  const [foundMassage, setFoundMassage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [removeModal, setRemoveModal] = useState(false);
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  // console.log(data, "data")
  const getOtherProviders = () => {
    dispatch(
      getOtherProvider(data?.sender_user?._id, null, (a) => {
        setFoundMassage(a);
      })
    ).then(() => {
      foundModal();
    });
    foundModal();
  };

  const foundModal = () => {
    if (foundMassage == "Couldn't found user") {
      setOpenModal((pre) => ({ ...pre, event: false }));
      setRemoveModal(true);
    }
  };

  useEffect(() => {
    foundModal();
  }, [foundMassage]);

  const handleNavigate = (event) => {
    if (event?.target?.classList?.contains("btnn")) {
      return;
    } else {
      handleClose(false);
      // setOpenModal((pre) => ({...pre, event: true}))
      navigate(`/view-events/${data?.event_id}/${data?.sender_user?._id}`);
    }
  };

  const handleAccept = () => {
    const payload = {
      notification_id: data?._id,
      event_id: data?.event_id,
      provider_id: userAuth?._id,
    };
    dispatch(
      acceptEventNotification(
        payload,
        () => {
          getNotificationsList();
        },
        () => getNotificationsList()
      )
    );
  };

  const handleCancleRequest = () => {
    const payload = {
      notification_id: data?._id,
      event_id: data?.event_id,
      provider_id: userAuth?._id,
    };
    dispatch(
      cancelEventNotification(
        payload,
        () => {
          getNotificationsList();
        },
        () => {
          getNotificationsList();
        }
      )
    );
  };

  // const handleNavigate = (event, status) => {
  //     const clickedElement = event.target;
  //     if (clickedElement.classList.contains('btnn')) {
  //         // Button with the class "btnn" was clicked, handle API call logic
  //         return;
  //     } else {
  //         navigate(`/view-events/${data?.event_id}/${data?.sender_user?._id}`);
  //     }
  // }

  return (
    <>
      <div
        style={{
          background: data?.is_seen ? "white" : "#E6F7FF",
          cursor: "pointer",
        }}
        onClick={(event) => {
          handleNavigate(event);
          getOtherProviders();
          seenNotification();
        }}
      >
        <div className="notification-main-div d-flex align-items-start position-relative justify-content-between p-2">
          <div className="d-flex align-items-start position-relative w-100">
            <div className="d-flex align-items-center">
              <FiberManualRecordIcon
                style={{
                  width: "13px",
                  color: data?.is_seen ? "transparent" : "#60CE74",
                }}
              />
              <div
                className="p_image_shape me-2 me-sm-0"
                style={{ transform: "scale(0.6)", marginLeft: "-15px" }}
              >
                <img
                  src={data?.sender_user?.profile_picture || userIcon}
                  onError={(e) => (e.target.src = userIcon)}
                />
              </div>
            </div>
            <div className="w-100">
              <p
                className="mb-0 t-13-800 mt-0 twoLineElips"
                style={{ wordBreak: "break-word" }}
              >
                {data?.noti_msg
                  .replace("user_name", data?.sender_user?.name)
                  ?.replace("login_", "")
                  .replace("service_name", data?.service_name ?? "")
                  ?.replace("event_name", data?.event_name ?? "")
                  .replace("provider_name", data?.provider_name ?? "")}
              </p>

              <div className="w-100 d-flex justify-content-between">
                <div>
                  <p className="mb-0 t-12-bold mt-0" style={{ width: "80%" }}>
                    {data?.sender_user?.name}
                  </p>
                  <div className="d-flex align-items-center g-2">
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("MMMM D YYYY")}
                    </p>
                    <FiberManualRecordIcon
                      style={{ transform: "scale(0.4)", color: "#555555" }}
                    />
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("LT")}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="t-10-bold m-0"
                    style={{
                      color: "#2F8A40",
                      textAlign: "end",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {getReviewTime(data?.createdAt)}
                  </p>
                  <p
                    className="t-11-bold mt-1 d-flex align-items-center justify-content-center m-0"
                    style={{
                      borderRadius: "3px",
                      background: "#00ADFF",
                      padding: "2px 4px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formatBadge(
                      data?.noti_for,
                      data?.booking_type == "service" ? false : true
                    )}
                  </p>
                </div>
              </div>

              {data?.is_accepted == null &&
              formatBadge(data?.noti_for, false) !== "Edit Event" &&
              formatBadge(data?.noti_for, false) !== "Booking On" ? (
                <div className="d-flex justify-content-start row">
                  <div className="col-6 " style={{width : '100px'}}>
                    <button
                      type="submit"
                      className="btn_admina delete-button btnn"
                      onClick={() => {
                        handleAccept();
                      }}
                      style={{
                        transform: "scale(0.7)",
                        marginLeft: "-25px",
                        width: "160px",
                        backgroundSize: "142px 65px",
                      }}
                    >
                      Accept
                    </button>
                  </div>
                  <div className="col-6 " style={{width : '100px'}}>
                    <button
                      type="submit"
                      className="btn_admina delete-confirm-button btnn"
                      onClick={() => {
                        handleCancleRequest();
                      }}
                      style={{
                        transform: "scale(0.7)",
                        marginLeft: "-25px",
                        width: "160px",
                        backgroundSize: "142px 65px",
                        // backgroundImage: `${whiteBtn}`
                      }}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              ) : null}
              {/*<div className="d-flex justify-content-start">*/}
              {/*        <div className="col-12">*/}
              {/*    */}
              {/*    </div>*/}
              {/*</div>*/}
            </div>
          </div>

          <div
            className="position-absolute delete_notification"
            style={{ left: "0", top: "0" }}
          >
            <button className="btnn">
              <Tooltip placement="top" title={"Clear"}>
                <IconButton
                  style={{ padding: "3px 5px" }}
                  className="btnn"
                  onClick={() => {
                    handleDeleteNotification();
                  }}
                >
                  {/*<CloseIcon style={{width: '15px'}}/>*/}
                  <img
                    src={deleteIcon}
                    alt=""
                    className="btnn"
                    style={{ width: "24px" }}
                  />
                </IconButton>
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
      {/*<HireCollaborateModal getNotificationsList={getNotificationsList} open={openModal} setOpen={setOpenModal}*/}
      {/*                      userDetails={userDetails} data={data}/>*/}
    </>
  );
};

export default Event_Invitation;
