import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {Grid, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileTimePicker} from "@mui/x-date-pickers";
import AddMedia from "./AddMedia";
import {useDispatch} from "react-redux";
import {uploadPackageImage} from "../../action/myEventConstant";

const PackagePopup = ({
                          open,
                          setOpen,
                          details,
                          setDetails,
                          setPackageDetails,
                          packageDetails,
                          setPackageEdit,
                          isPackageEdit,
                          packageIndex
                      }) => {

    const dispatch = useDispatch();
    const [error, setError] = useState("")
    const [loader, setLoader] = useState(false)
    const [files, setfiles] = useState([])

    const handleClose = () => {
        setOpen(false);
        setLoader(false)
        setPackageEdit();
        setPackageDetails({
            name: "",
            capacity: "",
            description: "",
            dackageDetails: "",
            value: "",
            media: [],
            id: null
        })
    }

    const [packageDelateId, setpackageDelateId] = useState([])

    const handleSubmit = (e) => {
        setLoader(true)
        e.preventDefault()
        // if (!packageDetails?.media?.length) {
        //     setLoader(false)
        //     setError("Please add package image")
        // } else
        if (packageDetails?.media?.length > 7) {
            setLoader(false)
            setError("Please add maximum 7 package image")
        } else {
            setError("")
            // setDetails((pre) => ({...pre, package: [...pre.package, packageDetails]}))
            const payload = {
                image_file: packageDetails?.media
            }
            if (packageDetails?.media?.length) {
                dispatch(uploadPackageImage(payload, () => {
                    handleClose()
                }, (a) => {
                    setLoader(false)
                }, (a) => {
                    setDetails((pre) => ({...pre, package: [...pre.package, {...packageDetails, media: a}]}))
                }))
            } else {
                setDetails((pre) => ({...pre, package: [...pre.package, {...packageDetails}]}))
                setLoader(false)
                handleClose()
            }

        }
    }


    const EventMedia = () => {
        // Check if the data is in file format
        const isFileFormat = (data) => data.hasOwnProperty('file_name') || data.hasOwnProperty('_id');

        const processedArray = packageDetails?.media?.filter((element) => {
            if (!isFileFormat(element)) {
                return element;
            }
        });

        return processedArray

    }


    const handleEdit = (e) => {
        setLoader(true)
        e.preventDefault()
        // if (!packageDetails?.media?.length) {
        //     setLoader(false)
        //     setError("Please add package image")
        // }
        if (packageDetails?.media?.length > 7) {
            setLoader(false)
            setError("Please add maximum 7 package image")
        } else {
            setError("")
            // setDetails((pre) => ({
            //     ...pre, package: pre?.package?.map((ele, i) => {
            //         if (i == packageIndex) {
            //             return packageDetails
            //         } else {
            //             return ele
            //         }
            //     })
            // }))
            const payload = {
                image_file: EventMedia()
            }

            // console.log(EventMedia(), 'processedArray')
            if (EventMedia()?.length) {
                dispatch(uploadPackageImage(payload, () => {
                    handleClose()
                }, (a) => {
                    setLoader(a)
                }, (a) => {
                    setDetails((pre) => ({
                        ...pre,
                        delete_package_image_ids : packageDelateId,
                        package: pre?.package?.map((ele, i) => {
                            if (i == packageIndex) {
                                return {...packageDetails, media: [...ele.media, ...a]}
                            } else {
                                return ele
                            }
                        })
                    }))
                }))
            } else {
                setDetails((pre) => ({
                    ...pre,
                    delete_package_image_ids : packageDelateId,
                    package: pre?.package?.map((ele, i) => {
                        if (i == packageIndex) {
                            return {...packageDetails, media: [...ele.media]}
                        } else {
                            return ele
                        }
                    })
                }))
                setLoader(false)
                handleClose()
            }

        }
    }


    return (
        <>
            <>
                <Modal
                    centered
                    show={open}
                    backdrop="static"
                    onHide={() => {
                        handleClose()
                        // setIsUpdate(false)
                    }}
                    dialogClassName="delete-modal"
                >
                    <>
                        <div className="Congratulations_msg">
                            <div className="group" style={{borderRadius: "10px"}}>
                                <div className="py-2 px-2" style={{borderRadius: "10px"}}>
                                    <div>
                                        <p className="t-18-bold">Add Package</p>
                                        <hr/>
                                    </div>
                                    <div className="p-3" style={{
                                        background: '#EFF3F7',
                                        borderRadius: '5px',
                                    }}>
                                        <form onSubmit={(e) => {
                                            if (isPackageEdit) {
                                                handleEdit(e)
                                            } else {
                                                handleSubmit(e)
                                            }
                                        }}>
                                            <Grid container spacing={2}>

                                                <Grid item xs={12}>
                                                    <div>
                                                        <p className="t-14-500 text-black m-0">Package Name</p>
                                                        <div className="group-input mt-2 p-15 clearfix">
                                                            <input
                                                                required
                                                                type="text"
                                                                style={{
                                                                    color: "#000000",
                                                                }}
                                                                onChange={(e) => {
                                                                    if (e.target.value?.length <= 30) {
                                                                        setPackageDetails((pre) => ({
                                                                            ...pre,
                                                                            name: e.target.value
                                                                        }))
                                                                    }
                                                                }}
                                                                value={packageDetails?.name}
                                                                placeholder="Package #1"
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <p className="t-14-500 text-black m-0">Capacity</p>
                                                        <div className="group-input mt-2 p-15 clearfix">
                                                            <input
                                                                required
                                                                type="number"
                                                                style={{
                                                                    color: "#000000",
                                                                }}
                                                                placeholder="Enter Capacity"
                                                                onChange={(e) => {
                                                                    if (e.target.value.length <= 4) {
                                                                        setPackageDetails((pre) => ({
                                                                            ...pre,
                                                                            capacity: e.target.value
                                                                        }))
                                                                    }
                                                                }}
                                                                value={packageDetails?.capacity}

                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <p className="t-14-500 text-black m-0">Description</p>
                                                        <div className="group-input mt-2 p-15 clearfix">
                                                        <textarea
                                                            required
                                                            style={{
                                                                color: "#000000",
                                                            }}
                                                            placeholder="Describe accommodation package and what is included"
                                                            onChange={(e) => {
                                                                if (e.target.value?.length <= 250) {
                                                                    setPackageDetails((pre) => ({
                                                                        ...pre,
                                                                        description: e.target.value
                                                                    }))
                                                                }
                                                            }}
                                                            value={packageDetails?.description}

                                                        />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <p className="t-14-500 text-black m-0">Package details</p>
                                                        <div className="group-input mt-2 p-15 clearfix">
                                                        <textarea
                                                            required
                                                            style={{
                                                                color: "#000000",
                                                            }}
                                                            placeholder="Enter details"
                                                            onChange={(e) => {
                                                                if (e.target.value?.length <= 250) {
                                                                    setPackageDetails((pre) => ({
                                                                        ...pre,
                                                                        dackageDetails: e.target.value
                                                                    }))
                                                                }
                                                            }}
                                                            value={packageDetails?.dackageDetails}

                                                        />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <p className="t-14-500 text-black m-0">Value / Person</p>
                                                        <div className="group-input mt-2 p-15 clearfix">
                                                            <input
                                                                required
                                                                style={{
                                                                    color: "#000000",
                                                                }}
                                                                type="number"
                                                                onChange={(e) => {
                                                                    if (e.target.value.length <= 10) {
                                                                        setPackageDetails((pre) => ({
                                                                            ...pre,
                                                                            value: e.target.value
                                                                        }))
                                                                    }
                                                                }}
                                                                placeholder="Enter Value"
                                                                value={packageDetails?.value}

                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AddMedia details={packageDetails}
                                                              isSmall={true} setDetails={setDetails}
                                                              setPackageDetails={setPackageDetails}
                                                              isPackageEdit={isPackageEdit}
                                                              packageDetails={packageDetails}
                                                              packageDelateId={packageDelateId}
                                                              setDeletedId={(Ids) => {
                                                                setpackageDelateId(Ids)
                                                              }}
                                                    />
                                                    <p className="t-13-500 text-danger">{error}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="w-100 d-flex mt-4">
                                                <div className="col-6">
                                                    <button
                                                        // type="submit"
                                                        className="btn_admina delete-confirm-button"
                                                        style={{lineHeight: "22px"}}
                                                        onClick={() => handleClose()}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    {loader ? <a
                                                            href="#"
                                                            style={{lineHeight: "22px"}}
                                                            onClick={(e) => e.preventDefault()}
                                                            className="btn_admina delete-button"
                                                        >
                                                            {/* save */}
                                                            <div className="loader"></div>
                                                        </a> :
                                                        <button type="submit" style={{lineHeight: "22px"}}
                                                                className="btn_admina delete-button"
                                                                onClick={() => {
                                                                    // if (isEdit) {
                                                                    //     handleEditSubmit()
                                                                    // } else {
                                                                    // handleSubmit()
                                                                    // }
                                                                    // setOpen(false);
                                                                }}
                                                        >
                                                            Save
                                                        </button>
                                                    }
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            </>
        </>
    )
}

export default PackagePopup;