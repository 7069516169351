import React from 'react';
import {Modal} from "react-bootstrap";
import image from "../assets/images/event.png";
import img1 from "../assets/images/1.jpg";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {Grid, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Carousel from "react-multi-carousel";



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 1
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};



const PackagePopup = (props) => {
    const {open, setOpen, packageDetails} = props;


    return (
        <>
            <Modal
                centered
                show={open}
                onHide={() => {
                    setOpen(false);
                }}
                dialogClassName="delete-modal"
            >
                <div className="Congratulations_msg">
                    <div className=" m-0" style={{borderRadius: "10px"}}>
                        <div className="group " style={{borderRadius: "10px"}}>
                            <div className="padding-inner position-relative pt-2" style={{borderRadius: "10px", textAlign: "center"}}>

                                <Carousel
                                    responsive={responsive}
                                    swipeable={false}
                                    draggable={false}
                                    showDots={true}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={props.deviceType !== "mobile" ? true : false}
                                    autoPlaySpeed={2000}
                                    keyBoardControl={true}
                                    // customTransition="all .5"
                                    transitionDuration={500}
                                    arrows={false}
                                    // renderArrowsWhenDisabled={false}
                                    // containerClass="carousel-container"
                                    // removeArrowOnDeviceType={["tablet", "mobile", "laptop"]}
                                    deviceType={props.deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >


                                    {
                                        packageDetails?.package_media?.map((e) => (
                                            <div className="polygon-imgae d-flex justify-content-center mt-3" key={e._id}>
                                                <div className="p_image_shape" style={{
                                                    marginBottom: "30px"
                                                }}>
                                                    <img src={e?.file_name}/>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Carousel>

                                <div>
                                    <p className="t-25-bold m-0" style={{wordBreak: "break-word"}}>{packageDetails?.package_name}</p>
                                    <p className="t-18-500 m-0">up to - {packageDetails?.capacity} ppl</p>
                                    <p className="t-18-bold m-0 text-black">Value ${packageDetails?.value_per_person} / Person</p>
                                    <div className="mt-4">
                                        <Grid container spacing={0}>
                                            {packageDetails?.package_detail?.split(",").filter(str => str.trim() !== '').map((ele) => (
                                                <Grid item sx={{paddingTop: "0"}}>
                                                    <div className="d-flex align-items-start">
                                                        <FiberManualRecordIcon style={{
                                                            marginTop: '3px',
                                                            transform: "scale(0.4)",
                                                            color: "transparent",
                                                            borderRadius: "50%",
                                                            background: "linear-gradient(90deg, #5CCBEA -4.34%, #EFCE96 103.9%)"
                                                        }}/>
                                                        <p className="m-0 t-18-500 text-black text-start">{ele}</p>
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-start m-0 t-16-bold">Details:</p>
                                        <p className="t-14-500 text-start text-gray">{packageDetails?.description}</p>
                                    </div>

                                    <div className="position-absolute" style={{
                                        top: "5px",
                                        right: "5px"
                                    }}>
                                        <IconButton sx={{background: "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)"}} onClick={() => setOpen(false)}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PackagePopup;