import React from 'react';
import {Button, Popover} from "@mui/material";

const WalletCardPopup = ({id, open, anchorEl, handleClose, handleDeleteCard, setwalletMenu}) => {
    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div className="d-flex flex-column">
                    {/*<Button className="walletpopText">Edit</Button>*/}
                    <Button className="walletpopTextRed" onClick={() => {
                        handleDeleteCard()
                        setwalletMenu(false)
                    }}>Delete</Button>
                </div>
            </Popover>
        </>
    )
}

export default WalletCardPopup;