import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment/moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../../assets/images/Discovery.svg";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { capital } from "../../Utils/capitalUtils";
import { Modal } from "react-bootstrap";
import userIcon from "../../assets/images/userIconWithBg.png";
import { calcleHireCollaNotiInEvent } from "../../action/hireNcollaborateConstant";
import { useDispatch } from "react-redux";
import ModifyScreen from "./ModifyScreen";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../../Utils/DateFormateUtils";

const ProviderDetailsScreen = ({ open, setOpen, details, event }) => {
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const dispatch = useDispatch();
  const [cancelLoader, setCancelLoader] = useState(false);
  const [openModify, setOpenModify] = useState(false);
  // console.log(details, "details")
  const handleCancelNoti = () => {
    setCancelLoader(true);
    const payload = {
      notification_id: details?._id,
    };
    dispatch(
      calcleHireCollaNotiInEvent(
        payload,
        (a) => {
          setOpen(a);
        },
        () => {},
        (a) => {
          setCancelLoader(a);
        }
      )
    );
  };

  console.log(details, "details");
  return (
    // <></>
    <>
      <Modal
        centered
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        dialogClassName="delete-modal payment-details-modal"
      >
        <div
          className="Congratulations_msg payment-details-modal-mx"
          style={{ display: openModify ? "none" : "block" }}
        >
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0 t-22-bold">
                {details?.event_request_type == "collaborate"
                  ? "Collaborate Details"
                  : "Hire Details"}
              </p>
              <IconButton
                sx={{
                  background:
                    "linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%)",
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              className="inner-gred box_shadow mt-4"
              style={{
                background:
                  details?.booking_type == "event" && "rgba(255, 165, 0, 0.10)",
              }}
            >
              <div className="d-flex">
                <div className="p_image_shape me-2 me-sm-3">
                  <img
                    src={
                      event?.event_media?.length
                        ? event?.event_media[0]?.file_name
                        : userIcon
                    }
                  />
                </div>
                <div>
                  <p className="t-15-bold mb-1" style={{ color: "black" }}>
                    {event?.event_name}
                  </p>

                  <div className="d-flex align-items-center">
                    <p
                      className="m-0 t-12-bold"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {DateFormate(
                        LocalFormate(event?.start_date, event?.date_timezone)
                      )}
                    </p>
                    <FiberManualRecordIcon
                      style={{ transform: "scale(0.3)" }}
                    />
                    <p
                      className="m-0 t-12-bold"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {moment(
                        LocalFormate(event?.start_time, event?.date_timezone)
                      ).format("LT")}{" "}
                      to{" "}
                      {moment(
                        LocalFormate(event?.end_time, event?.date_timezone)
                      ).format("LT")}{" "}
                      (
                      {timeZoneFormate(event?.start_time, event?.date_timezone)}
                      )
                    </p>
                  </div>
                  {event?.is_virtual_event ? (
                    <p className="t-14-bold">Type: Virtual</p>
                  ) : (
                    <div className="d-flex gap-1">
                      <img src={locationIcon} alt="" />
                      <p className="yelloe-t-bold m-0">{event?.address}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {details?.service_time_detail?.length ? (
              <>
                <p className="t-18-bold m-0 mt-3">Time Slots</p>
                <div
                  className="inner-gred box_shadow mt-2"
                  style={{ padding: "12px" }}
                >
                  <div
                    className="hideScroll"
                    style={{
                      maxHeight: "580px",
                      height: "100%",
                      overflow: "auto",
                    }}
                  >
                    <>
                      {/*<div className="border-gred">*/}
                      {/*    <div className="inner-gred">*/}
                      <div>
                        {details?.service_time_detail?.map((ele) => (
                          <p className="m-0 t-15-bold">
                            {moment(
                              LocalFormate(
                                ele?.service_date || ele?.provider_service_date
                              )
                            ).format("D/MM/YYYY")}{" "}
                            -{" "}
                            {moment(
                              LocalFormate(
                                ele?.start_time || ele?.provider_start_time
                              )
                            ).format("LT")}{" "}
                            to{" "}
                            {moment(
                              LocalFormate(
                                ele?.end_time || ele?.provider_end_time
                              )
                            ).format("LT")}
                          </p>
                        ))}
                      </div>
                      {/*</div>*/}
                      {/*</div>*/}
                    </>
                  </div>
                </div>
              </>
            ) : null}

            <p className="t-18-bold m-0 mt-3">Payment Details</p>
            <div
              className="inner-gred box_shadow mt-1"
              style={{ padding: "12px" }}
            >
              <div
                className="hideScroll"
                style={{
                  maxHeight: "580px",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <>
                  {/*<div className="border-gred">*/}
                  {/*    <div className="inner-gred">*/}
                  {details?.event_request_type == "collaborate" ? (
                    <>
                      <div
                        className="d-flex align-items-center justify-content-between pb-2"
                        style={{ borderBottom: "1px solid #D0DEE2" }}
                      >
                        <p
                          className="m-0"
                          style={{
                            fontSize: "14px",
                            color: "#585858",
                            fontWeight: "600",
                          }}
                        >
                          Collaborate Percentage
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontSize: "14px",
                            color: "#585858",
                            fontWeight: "600",
                          }}
                        >
                          {details?.amount || details?.request_amount}%
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <p
                          className="m-0"
                          style={{
                            fontSize: "14px",
                            color: "#585858",
                            fontWeight: "600",
                          }}
                        >
                          Total Bookings
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontSize: "14px",
                            color: "#585858",
                            fontWeight: "600",
                          }}
                        >
                          {details?.total_bookings || 0}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <p
                          className="m-0"
                          style={{
                            fontSize: "14px",
                            color: "#585858",
                            fontWeight: "600",
                          }}
                        >
                          Total Amount Receive
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontSize: "14px",
                            color: "#585858",
                            fontWeight: "600",
                          }}
                        >
                          {details?.total_amount_receive || 0}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <p
                          className="m-0"
                          style={{
                            fontSize: "14px",
                            color: "#008000",
                            fontWeight: "900",
                          }}
                        >
                          Revenue Share
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontSize: "14px",
                            color: "#008000",
                            fontWeight: "900",
                          }}
                        >
                          {details?.revenue_share || 0}
                        </p>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex align-items-center justify-content-between">
                      <p
                        className="m-0"
                        style={{
                          fontSize: "14px",
                          color: "#585858",
                          fontWeight: "600",
                        }}
                      >
                        Hire Amount
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontSize: "14px",
                          color: "#585858",
                          fontWeight: "600",
                        }}
                      >
                        $
                        {Number(
                          (details?.amount || details?.request_amount) ?? 0
                        )?.toFixed(2)}
                      </p>
                    </div>
                  )}

                  {/*</div>*/}
                  {/*</div>*/}
                </>
              </div>
            </div>
            {details?.event_request_type == "collaborate" && (
              <>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <a
                    href="/partnership_ruls.html"
                    style={{
                      borderBottom: "2px solid #373C74",
                      color: "#373C74",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Partnership Rule
                  </a>
                </div>
              </>
            )}
            <div style={{ marginTop: "50%" }}>
              {
                // details?._id == userAuth?._id ?
                details?.is_request_accepted == true ||
                details?.provider_status == "accept" ? (
                  <>
                    {event?.user_id?._id == userAuth?._id ? (
                      <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center  mt-2">
                        <button
                          className="btn_admina  ms-lg-auto "
                          style={{
                            backgroundSize: "contain",
                            lineHeight: "16px",
                          }}
                          onClick={() => setOpenModify(true)}
                        >
                          Modify
                        </button>
                      </Box>
                    ) : null}
                    <div className="w-100 d-flex justify-content-center">
                      <div
                        className="col-6"
                        style={{ width: "100%", maxWidth: "170px" }}
                      >
                        {cancelLoader ? (
                          <a
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            className="btn_admina delete-confirm-button"
                            style={{ color: "#b3b3b3" }}
                          >
                            {/* save */}
                            <div className="loader"></div>
                          </a>
                        ) : (
                          <button
                            type="submit"
                            // disabled={disable}
                            className="btn_admina delete-confirm-button"
                            onClick={() => {
                              handleCancelNoti();
                            }}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                      <div
                        className="col-6"
                        style={{ width: "100%", maxWidth: "170px" }}
                      >
                        <button
                          type="submit"
                          // disabled={disable}
                          className="btn_admina delete-button"
                          // onClick={() => handleAddHireCollaborate()}
                        >
                          Report Issue
                        </button>
                      </div>
                    </div>
                  </>
                ) : null
                // : null
              }
            </div>
          </div>
        </div>
      </Modal>
      <ModifyScreen
        open={openModify}
        setOpen={setOpenModify}
        details={details}
        event={event}
      />
    </>
  );
};

export default ProviderDetailsScreen;
