import React, {useEffect} from 'react';
import {Box, Grid, IconButton} from "@mui/material";
import moment from "moment/moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import locationIcon from "../../assets/images/Discovery.svg";
import saveIcon from "../../assets/images/save.svg";
import unsave from "../../assets/images/saveIcon.svg";
import noImg from "../../assets/images/noImg.jpg";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {saveService} from "../../action/userServiceConstant";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import logo from "../../assets/images/ad-anima_logo.png"

const SaveClaimCard = ({ele, gatSavedList}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selector = useSelector((state) => state)
    const savedUnsaved = (id, status) => {
        dispatch(saveService({id: id, status: status}))
        // if (save?.unsavedData?.status) {
        gatSavedList()
        // }
    }

    const handleNavigate = (event, id) => {
        if (event?.target?.classList?.contains('btnn')) {
            return;
        } else {
            navigate(`/provider_details/${id}`)
        }

    }


    return (
        <Box>
            <div
                className={`d-flex inner-gred box_shadow position-relative cursor-pointer`}
                style={{cursor: 'pointer', background: "rgb(234, 234, 234)"}}>
                <div
                    className={`d-flex w-100`}
                    onClick={(e) => handleNavigate(e, ele?.user_id?._id)}
                >
                    <div className="position-relative">
                        <div className="p_image_shape me-2 me-sm-3">
                            <img src={logo}/>
                        </div>
                        {/*<img*/}
                        {/*    src={ele?.user_profile_picture}*/}
                        {/*    style={{*/}
                        {/*        right: '17px',*/}
                        {/*        bottom: '-3px',*/}
                        {/*        height: '37px',*/}
                        {/*        width: '37px',*/}
                        {/*        borderRadius: '50%',*/}
                        {/*        zIndex: '11',*/}
                        {/*        border: '3px solid white',*/}
                        {/*        background: "white"*/}
                        {/*    }}*/}
                        {/*    className="position-absolute"*/}
                        {/*    onError={(e) => {*/}
                        {/*        e.target.src = noImg;*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </div>
                    <div style={{flexGrow: "1"}}>
                        <div
                            className="d-flex justify-content-between align-items-center">
                            <p className="t-16-bold m-0 mb-1">{ele?.service_name}</p>
                        </div>
                        {/*<div className="d-flex flex-sm-row flex-column align-items-start align-items-sm-center mb-1">*/}
                        {/*    <p className="pro_carousel_time m-0">{moment(ele?.start_date).format('MMM Do') !== moment(ele?.end_date).format('MMM Do') ? (`${moment(ele?.start_date).format('MMM Do')} - ${moment(ele?.end_date).format('MMM Do, YYYY')}`) : (`${moment(ele?.start_date).format('MMMM Do YYYY')}`)}</p>*/}
                        {/*    <FiberManualRecordIcon className="d-none d-sm-block"*/}
                        {/*                           style={{transform: "scale(0.3)"}}/>*/}
                        {/*    <p className="pro_carousel_time m-0">{moment(ele?.start_time).format('LT')} to {moment(ele?.end_time).format('LT')}</p>*/}
                        {/*</div>*/}
                        <div className="d-flex align-items-center gap-1 mb-1">
                            <div style={{color: "#FFC107"}}
                                 className="d-flex align-items-center justify-content-center">
                                {ele?.user_id?.average_rating >= 0.5 ? (
                                    <StarIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                ) : (
                                    <StarBorderIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                )}
                                {ele?.user_id?.average_rating >= 1.5 ? (
                                    <StarIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                ) : (
                                    <StarBorderIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                )}
                                {ele?.user_id?.average_rating >= 2.5 ? (
                                    <StarIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                ) : (
                                    <StarBorderIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                )}
                                {ele?.user_id?.average_rating >= 3.5 ? (
                                    <StarIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                ) : (
                                    <StarBorderIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                )}
                                {ele?.user_id?.average_rating >= 4.5 ? (
                                    <StarIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                ) : (
                                    <StarBorderIcon
                                        style={{
                                            height: "14px",
                                            width: "14px",
                                        }}
                                    />
                                )}
                            </div>
                            <p className="m-0 t-12-bold">({Math.round(ele?.user_id?.average_rating)}/5)</p>
                        </div>
                        <div className="d-flex justify-content-between mt-2 flex-wrap save-page-content">
                            {ele?.user_id?.name && <p className="t-12-bold m-0">{ele?.user_id?.name}</p>}
                            <div className="d-flex align-items-center gap-1">
                                <img src={locationIcon} alt=""/>
                                <p className="yelloe-t-bold m-0">{ele?.miles_distance} Mile Away</p>
                            </div>
                            {/*<div className="save-page-btn d-flex mt-3">*/}
                            {/*    <button*/}
                            {/*        type="submit"*/}
                            {/*        className="btnn btn_admina delete-confirm-button white-border-btn"*/}
                            {/*        onClick={() => {*/}
                            {/*            handleNavigate("", "")*/}
                            {/*            navigate(`/provider_details/${ele?.user_id?._id}`)*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        Claim Now*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="position-absolute" style={{
                    right: "10px",
                    top: "10px",
                }}>
                    <IconButton style={{padding: "3px"}}
                                onClick={() => {
                                    savedUnsaved(ele?._id, false)
                                }}
                    >
                        <img src={saveIcon} alt=""
                             width="28px"/>
                    </IconButton>
                </div>
            </div>
        </Box>
    )
}

export default SaveClaimCard;