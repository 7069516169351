import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import { Box, Button, Grid, IconButton } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import RemoveLinkPopUp from "../Component/RemoveLinkPopUp";
import clockIcon from "../assets/images/clock.svg";
import locationIcon from "../assets/images/Discovery.svg";
import RemoveServicePopUp from "../Component/RemoveServicePopUp";
import { useDispatch, useSelector } from "react-redux";
import { addMyPageDetails, getMyPageDetails } from "../action/myPageConstant";
import { Form, Modal } from "react-bootstrap";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import Loader from "../Component/Loader";
import plusIcon from "../assets/images/whiteplus.png";
import { toast } from "react-toastify";
import BankAlartPopup from "../Component/BankAlartPopup";
import BankView from "./Bank/BankView";
import InstaTempModal from "../Component/InstaTempModal";

const minit = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

const MyPages = () => {
  const [deleteModal, setDeleteModal] = useState({
    link: false,
    service: false,
  });
  const minitObjects = minit.map((item) => ({
    label: item.padStart(2, "0"),
    value: item,
  }));
  const [isBankAlart, setIsBankAlart] = useState(false);
  const [openBankInfo, setOpenBankInfo] = useState(false);
  const [instaTempModal, setinstaTempModal] = useState(false);

  const [modelShow, setmodelShow] = useState(false);
  const userDetails = useSelector((state) => state.user);
  const [inputData, setInputData] = useState({
    website_link: [""],
  });

  const dispatch = useDispatch();
  const [introduced, setIntroduced] = useState(false);
  const [linkRemoveNo, setlinkRemoveNo] = useState("");

  const [linkError, setLinkError] = useState("");
  const [sessionTime, setSessionTime] = useState("");

  useEffect(() => {
    dispatch(getMyPageDetails());
  }, []);

  const myDetails = useSelector((state) => state.myPageDetails);

  useEffect(() => {
    setInputData(myDetails);
  }, [myDetails]);

  const [editMode, seteditMode] = useState(false);
  const [editId, setEditId] = useState("");

  const [selectedService, setselectedService] = useState(null);

  const [serviceForm, setserviceForm] = useState({
    service: "",
    details: "in_person",
    details_link: "",
    location: "i_travel",
    locationCordinates: "",
    adress: "",
    time: "",
    session_price: "",
    member: "",
    meximum_member: "",
    addition_price: "",
    discription: "",
    session_hours: "1",
    session_minutes: "0",
  });

  function isValidURL(str) {
    const pattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    if (pattern.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  const [deleteId, setDeleteId] = useState([]);

  const handleSave = () => {
    setserviceForm({
      service: "",
      details: "in_person",
      details_link: "",
      location: "i_travel",
      locationCordinates: "",
      adress: "",
      time: "",
      session_price: "",
      member: "",
      meximum_member: "",
      addition_price: "",
      discription: "",
      session_hours: "1",
      session_minutes: "0",
    });
    setInputData({
      website_link: [""],
    });
  };

  return (
    <>
      <section className="main-page">
        <Header select={"My Page"} />
        <div className="main-inner">
          <div className="container">
            {/*.........page section.........*/}

            <Box>
              <h3 style={{ fontWeight: "600", marginBottom: "25px" }}>
                My Pages
              </h3>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box
                      className="inner-gred page-Container-height"
                      style={{ height: "auto" }}
                    >
                      <p>How Would you like to be Introduced? </p>
                      <Box className="border-gred m-0">
                        <TextArea
                          value={inputData?.provider_introduction}
                          onChange={(e) => {
                            if (e.target.value?.length <= 350) {
                              setInputData({
                                ...inputData,
                                provider_introduction: e.target.value,
                              });
                            }
                          }}
                          rows={8}
                          cols={6}
                          className="inner-gred"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className="inner-gred page-Container-height">
                      <Box className="d-flex justify-content-between align-items-center">
                        <p>Do you have a website? Or more links? (Optional)</p>
                        <span
                          className="plus-icon float-end"
                          onClick={() => {
                            if (inputData?.website_link) {
                              if (
                                isValidURL(
                                  inputData?.website_link[
                                    inputData?.website_link?.length - 1
                                  ]
                                )
                              ) {
                                setLinkError("");
                                setInputData({
                                  ...inputData,
                                  website_link: [
                                    ...inputData?.website_link,
                                    "",
                                  ],
                                });
                              } else if (inputData?.website_link?.length == 0) {
                                setInputData({
                                  ...inputData,
                                  website_link: [
                                    ...inputData?.website_link,
                                    "",
                                  ],
                                });
                              } else {
                                setLinkError("Please Enter Valid Link");
                              }
                            } else {
                              setInputData({
                                ...inputData,
                                website_link: [
                                  ...(inputData?.website_link || ""),
                                  "",
                                ],
                              });
                            }
                          }}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="add">
                              <g id="vuesax/outline/add">
                                <g id="add_2">
                                  <path
                                    id="Vector"
                                    d="M22.5 15.9375H7.5C6.9875 15.9375 6.5625 15.5125 6.5625 15C6.5625 14.4875 6.9875 14.0625 7.5 14.0625H22.5C23.0125 14.0625 23.4375 14.4875 23.4375 15C23.4375 15.5125 23.0125 15.9375 22.5 15.9375Z"
                                    fill="#292D32"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M15 23.4375C14.4875 23.4375 14.0625 23.0125 14.0625 22.5V7.5C14.0625 6.9875 14.4875 6.5625 15 6.5625C15.5125 6.5625 15.9375 6.9875 15.9375 7.5V22.5C15.9375 23.0125 15.5125 23.4375 15 23.4375Z"
                                    fill="#292D32"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </Box>
                      <Box>
                        <Box style={{ height: "225px", overflow: "auto" }}>
                          {inputData?.website_link?.map((data, i) => {
                            return (
                              <>
                                <div className="group-input mt-2 p-15 clearfix">
                                  <input
                                    type="url"
                                    name=""
                                    value={inputData?.website_link[i]}
                                    onChange={(e) => {
                                      const newValue =
                                        inputData?.website_link?.map(
                                          (value, j) => {
                                            if (i == j) {
                                              return e.target.value;
                                            } else {
                                              return value;
                                            }
                                          }
                                        );
                                      setInputData({
                                        ...inputData,
                                        website_link: newValue,
                                      });
                                    }}
                                    placeholder="Your Link here"
                                  />
                                  {inputData?.website_link?.length > 1 && (
                                    <>
                                      <span
                                        className="remove new-remove"
                                        style={{ top: "16px", right: "16px" }}
                                        onClick={() => {
                                          setDeleteModal((pre) => ({
                                            ...pre,
                                            link: true,
                                          }));
                                          setlinkRemoveNo(i);
                                          setLinkError("");
                                        }}
                                        // onClick={() => {
                                        //     setWebLinks({
                                        //         ...webLinks,
                                        //         webLinks: webLinks?.filter((datanew, j) => {
                                        //             return i !== j;
                                        //         }),
                                        //     });
                                        // }}
                                      >
                                        <svg
                                          style={{ position: "initial" }}
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g id="Icons">
                                            <path
                                              id="Vector"
                                              d="M12.3865 12.8635L7.61351 8.09054M7.61351 12.8635L12.3865 8.09054"
                                              stroke="#FF0000"
                                              strokeWidth="1.39212"
                                              stroke-linecap="round"
                                            ></path>
                                          </g>
                                        </svg>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          })}
                          <p className="t-14-bold" style={{ color: "red" }}>
                            {linkError}
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/*.........Services section.......... */}

            {!inputData?.my_page_service_detail ? (
              <Loader />
            ) : (
              <Box className="mt-5">
                <h3 style={{ fontWeight: "600", marginBottom: "25px" }}>
                  My Services
                </h3>
                <Box>
                  <Grid container spacing={2}>
                    {inputData?.my_page_service_detail?.map((service, i) => (
                      <Grid item xs={12} sm={6} lg={4}>
                        <Box className="border-gred">
                          <Box className="inner-gred">
                            <Box className="d-flex align-items-center justify-content-between">
                              <h4
                                className="input-label"
                                style={{
                                  wordWrap: "normal",
                                  textWrap: "wrap",
                                  wordBreak: "break-word",
                                }}
                              >
                                {service?.service_name}
                              </h4>
                              <Box style={{minWidth : '75px'}}>
                                <IconButton
                                  sx={{ padding: "0", marginRight: "10px" }}
                                  onClick={() => {
                                    setserviceForm({
                                      service: service?.service_name,
                                      details: service?.service_type,
                                      details_link: service?.link,
                                      location: service?.location_type,
                                      adress: service?.address,
                                      time: "",
                                      session_price: service?.session_price,
                                      member: service?.participants,
                                      meximum_member:
                                        service?.maximum_participants,
                                      addition_price: service?.additional_price,
                                      discription: service?.description,
                                      locationCordinates: service?.location,
                                      session_hours: service?.session_hour,
                                      session_minutes: service?.session_minute,
                                    });
                                    setmodelShow(true);
                                    seteditMode(true);
                                    setEditId(i);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M15.3334 5.16644C14.0835 5.58308 12.4169 3.91654 12.8336 2.66663M12.7325 2.76773L10.0396 5.46054C8.86897 6.63122 8.03847 8.09805 7.63693 9.7042L7.50681 10.2247C7.46627 10.3868 7.61316 10.5337 7.77533 10.4932L8.2958 10.3631C9.90195 9.96153 11.3688 9.13103 12.5395 7.96035L15.2323 5.26754C15.5638 4.93604 15.75 4.48644 15.75 4.01763C15.75 3.0414 14.9586 2.25 13.9824 2.25C13.5136 2.25 13.064 2.43623 12.7325 2.76773Z"
                                      stroke="#363853"
                                    />
                                    <path
                                      d="M9 2.25C8.2325 2.25 7.46501 2.33822 6.71282 2.51466C4.62976 3.00328 3.00328 4.62975 2.51466 6.71282C2.16178 8.21719 2.16178 9.78281 2.51466 11.2872C3.00328 13.3702 4.62976 14.9967 6.71283 15.4853C8.21719 15.8382 9.78281 15.8382 11.2872 15.4853C13.3702 14.9967 14.9967 13.3702 15.4853 11.2872C15.6618 10.535 15.75 9.76748 15.75 8.99998"
                                      stroke="#363853"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </IconButton>
                                {service?._id && (
                                  <IconButton
                                    sx={{ padding: "0", marginRight: "10px" }}
                                    onClick={() => {
                                      setselectedService(service);
                                      setinstaTempModal(true);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M12 10V8C12 7.86739 11.9473 7.74021 11.8536 7.64645C11.7598 7.55268 11.6326 7.5 11.5 7.5C11.3674 7.5 11.2402 7.55268 11.1464 7.64645C11.0527 7.74021 11 7.86739 11 8V10C11 10.1326 10.9473 10.2598 10.8536 10.3536C10.7598 10.4473 10.6326 10.5 10.5 10.5H1.5C1.36739 10.5 1.24021 10.4473 1.14645 10.3536C1.05268 10.2598 1 10.1326 1 10V8C1 7.86739 0.947321 7.74021 0.853553 7.64645C0.759785 7.55268 0.632608 7.5 0.5 7.5C0.367392 7.5 0.240215 7.55268 0.146447 7.64645C0.0526785 7.74021 0 7.86739 0 8V10C0 10.3978 0.158035 10.7794 0.43934 11.0607C0.720644 11.342 1.10218 11.5 1.5 11.5H10.5C10.8978 11.5 11.2794 11.342 11.5607 11.0607C11.842 10.7794 12 10.3978 12 10ZM8.81 7.39L6.31 9.39C6.22173 9.45975 6.11251 9.4977 6 9.4977C5.88749 9.4977 5.77827 9.45975 5.69 9.39L3.19 7.39C3.09891 7.30402 3.04341 7.18697 3.03448 7.06203C3.02556 6.93708 3.06386 6.81334 3.1418 6.71528C3.21975 6.61722 3.33166 6.55199 3.4554 6.53249C3.57913 6.513 3.70568 6.54066 3.81 6.61L5.5 7.96V1C5.5 0.867392 5.55268 0.740215 5.64645 0.646447C5.74021 0.552679 5.86739 0.5 6 0.5C6.13261 0.5 6.25979 0.552679 6.35355 0.646447C6.44732 0.740215 6.5 0.867392 6.5 1V7.96L8.19 6.61C8.24035 6.56247 8.30002 6.52592 8.36523 6.50266C8.43044 6.4794 8.49978 6.46994 8.56884 6.47487C8.6379 6.4798 8.70518 6.49903 8.76643 6.53132C8.82767 6.56362 8.88154 6.60828 8.92462 6.66248C8.96771 6.71668 8.99906 6.77924 9.01671 6.84619C9.03435 6.91314 9.0379 6.98302 9.02712 7.05142C9.01634 7.11981 8.99148 7.18522 8.95411 7.2435C8.91673 7.30179 8.86766 7.35167 8.81 7.39Z"
                                        fill="#363853"
                                      />
                                    </svg>
                                  </IconButton>
                                )}

                                <IconButton
                                  sx={{ padding: "0" }}
                                  onClick={() => {
                                    setDeleteModal((pre) => ({
                                      ...pre,
                                      service: true,
                                    }));
                                    setEditId(i);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                      fill="#FFD8D8"
                                    />
                                    <path
                                      d="M9.17 14.8299L14.83 9.16992"
                                      stroke="#FF0000"
                                      strokeWidth="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M14.83 14.8299L9.17 9.16992"
                                      stroke="#FF0000"
                                      strokeWidth="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </IconButton>
                              </Box>
                            </Box>
                            <span>{service?.service_type}</span>
                            <hr />
                            <Box>
                              {service?.address && (
                                <Box className="d-flex gap-2 align-items-center">
                                  <img src={locationIcon} alt="clock_Icon" />
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#000000",
                                    }}
                                  >
                                    {service?.address}
                                  </span>
                                </Box>
                              )}
                              {service?.details_link && <>link</>}
                              {(service?.session_hour ||
                                service?.session_minute) && (
                                <Box className="d-flex gap-2 align-items-center">
                                  <img src={clockIcon} alt="clock_Icon" />
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#000000",
                                    }}
                                  >
                                    {`${service?.session_hour}:${service?.session_minute} Hours`}
                                  </span>
                                </Box>
                              )}
                            </Box>
                            <Box className="d-flex gap-5 mt-3">
                              <Box>
                                <Box>
                                  <span className="grey-text">
                                    Session Price
                                  </span>
                                  <p style={{ margin: "0", color: "#000000" }}>
                                    ${service?.session_price || 0}
                                  </p>
                                </Box>
                                <Box className="mt-2">
                                  <span className="grey-text">
                                    Additional Price
                                  </span>
                                  <p style={{ margin: "0", color: "#000000" }}>
                                    ${service?.additional_price || 0}
                                  </p>
                                </Box>
                              </Box>
                              <Box>
                                <Box>
                                  <span className="grey-text">
                                    Participants
                                  </span>
                                  <p style={{ margin: "0", color: "#000000" }}>
                                    {service?.participants || 0}
                                  </p>
                                </Box>
                                <Box className="mt-2">
                                  <span className="grey-text">
                                    Maximum Participants
                                  </span>
                                  <p style={{ margin: "0", color: "#000000" }}>
                                    {service?.maximum_participants || 0}
                                  </p>
                                </Box>
                              </Box>
                            </Box>
                            <hr />
                            <span
                              className="grey-text"
                              style={{ wordWrap: "break-word" }}
                            >
                              {service?.description}
                            </span>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            )}

            <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">
              <button
                className="btn_admina"
                style={{ maxWidth: "400px" }}
                onClick={() => {
                  // if (!userDetails?.bank_account_id) {
                  //     setIsBankAlart(true)
                  // } else {
                  setmodelShow(true);
                  // }
                }}
              >
                <img src={plusIcon} alt="" style={{ marginRight: "10px" }} />
                Add Service
              </button>
              <button
                className="btn_White ms-lg-auto"
                onClick={() => {
                  setInputData(myDetails);
                }}
              >
                Cancel
              </button>
              <button
                className="btn_admina"
                onClick={() => {
                  if (inputData?.provider_introduction.trim()) {
                    dispatch(
                      addMyPageDetails({ ...inputData, deleteId: deleteId })
                    );
                    handleSave();
                    setLinkError("");
                  } else {
                    toast.error("Please Add Provider Introductions.");
                  }
                }}
              >
                Save
              </button>
            </Box>
          </div>
        </div>

        {/*.............pop-up's............*/}
        <RemoveLinkPopUp
          open={deleteModal}
          setOpen={setDeleteModal}
          onConfirm={() => {
            const newValue = inputData?.website_link?.filter((value, j) => {
              return j !== linkRemoveNo;
            });
            setInputData({
              ...inputData,
              website_link: newValue,
            });
          }}
        />
        <RemoveServicePopUp
          open={deleteModal}
          setOpen={setDeleteModal}
          onConfirm={() => {
            const newValue = inputData?.my_page_service_detail?.filter(
              (value, j) => {
                return j !== editId;
              }
            );
            setInputData({
              ...inputData,
              my_page_service_detail: newValue,
            });

            if (inputData?.my_page_service_detail[editId]?._id) {
              setDeleteId([
                ...deleteId,
                inputData?.my_page_service_detail[editId]?._id,
              ]);
            }
          }}
        />
      </section>

      <Modal
        centered
        className="largemodal add_service_modal"
        show={modelShow}
        onHide={() => {
          setmodelShow(false);
          setserviceForm({
            service: "",
            details: "in_person",
            details_link: "",
            location: "i_travel",
            adress: "",
            time: "",
            session_price: "",
            member: "",
            meximum_member: "",
            addition_price: "",
            discription: "",
            locationCordinates: "",
            session_hours: "",
            session_minutes: "",
          });
          seteditMode(false);
        }}
      >
        <button
          type="button"
          className="close-modal"
          onClick={() => {
            setmodelShow(false);
            setserviceForm({
              service: "",
              details: "in_person",
              details_link: "",
              location: "i_travel",
              adress: "",
              time: "",
              session_price: "",
              member: "",
              meximum_member: "",
              addition_price: "",
              discription: "",
              locationCordinates: "",
              session_hours: "",
              session_minutes: "",
            });
            seteditMode(false);
          }}
          style={{ position: "absolute", right: "20px", top: "20px" }}
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25" cy="25" r="25" fill="#EEEEEE" />
            <path
              d="M25 38.4375C17.5875 38.4375 11.5625 32.4125 11.5625 25C11.5625 17.5875 17.5875 11.5625 25 11.5625C32.4125 11.5625 38.4375 17.5875 38.4375 25C38.4375 32.4125 32.4125 38.4375 25 38.4375ZM25 13.4375C18.625 13.4375 13.4375 18.625 13.4375 25C13.4375 31.375 18.625 36.5625 25 36.5625C31.375 36.5625 36.5625 31.375 36.5625 25C36.5625 18.625 31.375 13.4375 25 13.4375Z"
              fill="#363853"
            />
            <path
              d="M21.4627 29.4752C21.2252 29.4752 20.9877 29.3877 20.8002 29.2002C20.4377 28.8377 20.4377 28.2377 20.8002 27.8752L27.8752 20.8002C28.2377 20.4377 28.8377 20.4377 29.2002 20.8002C29.5627 21.1627 29.5627 21.7627 29.2002 22.1252L22.1252 29.2002C21.9502 29.3877 21.7002 29.4752 21.4627 29.4752Z"
              fill="#363853"
            />
            <path
              d="M28.5377 29.4752C28.3002 29.4752 28.0627 29.3877 27.8752 29.2002L20.8002 22.1252C20.4377 21.7627 20.4377 21.1627 20.8002 20.8002C21.1627 20.4377 21.7627 20.4377 22.1252 20.8002L29.2002 27.8752C29.5627 28.2377 29.5627 28.8377 29.2002 29.2002C29.0127 29.3877 28.7752 29.4752 28.5377 29.4752Z"
              fill="#363853"
            />
          </svg>
        </button>
        <form
          className="login_margin mt-2"
          onSubmit={(e) => {
            e.preventDefault();
            // if (serviceForm.session_hours <= 0) {
            if (!userDetails?.bank_account_id) {
              setIsBankAlart(true);
            } else if (
              Number(serviceForm?.session_hours) <= 0 &&
              Number(serviceForm?.session_minutes) <= 14
            ) {
              setSessionTime("Please Schedule minimum 15 min session");
            } else {
              setSessionTime("");
              if (editMode) {
                setInputData({
                  ...inputData,
                  my_page_service_detail:
                    inputData?.my_page_service_detail?.map((value, i) => {
                      if (i == editId) {
                        return {
                          ...value, // service_id : value?._id,
                          service_name: serviceForm?.service,
                          service_type: serviceForm?.details,
                          location_type: serviceForm?.location,
                          link: serviceForm?.details_link,
                          location: serviceForm?.locationCordinates,
                          address: serviceForm?.adress,
                          session_hour: serviceForm?.session_hours || 1,
                          session_minute: serviceForm?.session_minutes || 0,
                          session_price: serviceForm?.session_price,
                          participants: serviceForm?.member,
                          additional_price: serviceForm?.addition_price,
                          maximum_participants: serviceForm?.meximum_member,
                          description: serviceForm?.discription,
                        };
                      } else {
                        return value;
                      }
                    }),
                });
              } else {
                setInputData({
                  ...inputData,
                  my_page_service_detail: [
                    ...inputData?.my_page_service_detail,
                    {
                      service_name: serviceForm?.service,
                      service_type: serviceForm?.details,
                      location_type: serviceForm?.location,
                      link: serviceForm?.details_link,
                      location: serviceForm?.locationCordinates,
                      address: serviceForm?.adress,
                      session_hour: serviceForm?.session_hours || 1,
                      session_minute: serviceForm?.session_minutes || 0,
                      session_price: serviceForm?.session_price,
                      participants: serviceForm?.member,
                      additional_price: serviceForm?.addition_price,
                      maximum_participants: serviceForm?.meximum_member,
                      description: serviceForm?.discription,
                    },
                  ],
                });
                // }
                setserviceForm({
                  service: "",
                  details: "in_person",
                  details_link: "",
                  location: "i_travel",
                  adress: "",
                  time: "",
                  session_price: "",
                  member: "",
                  meximum_member: "",
                  addition_price: "",
                  discription: "",
                  locationCordinates: "",
                  session_hours: "1",
                  session_minutes: "0",
                });
              }
              seteditMode(false);
              setmodelShow(false);
            }
          }}
        >
          <h3 className="mb-3 mb-md-3 page-title">Add Service</h3>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="group-input mt-2 p-15 clearfix">
                <input
                  type="text"
                  name=""
                  required
                  value={serviceForm.service}
                  onChange={(e) => {
                    if (e.target.value?.length <= 35) {
                      setserviceForm({
                        ...serviceForm,
                        service: e.target.value,
                      });
                    }
                  }}
                  placeholder="Enter service"
                />
              </div>
              <div className="detail_location">
                <h4 className="text-center">Details</h4>
                <div className="tablist">
                  <span
                    className={
                      serviceForm.details == "in_person" ? "t_active" : ""
                    }
                    onClick={() => {
                      setserviceForm({ ...serviceForm, details: "in_person" });
                    }}
                  >
                    In Person
                  </span>
                  <span
                    className={
                      serviceForm.details == "virtual" ? "t_active" : ""
                    }
                    onClick={() => {
                      setserviceForm({ ...serviceForm, details: "virtual" });
                    }}
                  >
                    Virtual
                  </span>
                  <span
                    className={serviceForm.details == "both" ? "t_active" : ""}
                    onClick={() => {
                      setserviceForm({ ...serviceForm, details: "both" });
                    }}
                  >
                    Both
                  </span>
                </div>
                {serviceForm.details !== "in_person" &&
                  serviceForm.details !== "Details" && (
                    <>
                      <div className="group-input mt-3 p-15 clearfix">
                        <input
                          type="url"
                          name=""
                          value={serviceForm.details_link}
                          onChange={(e) => {
                            setserviceForm({
                              ...serviceForm,
                              details_link: e.target.value,
                            });
                          }}
                          placeholder="Enter link"
                        />
                      </div>
                    </>
                  )}

                {serviceForm.details !== "virtual" && (
                  <>
                    <div className="text-center location_title">
                      <h4 className="text-center d-inline-block">
                        <img
                          src="images/loca.svg"
                          className="d-inline-block me-1"
                          style={{ verticalAlign: "top" }}
                        />
                        Location
                      </h4>
                    </div>
                    <div className="tablist">
                      <span
                        className={
                          serviceForm.location == "i_travel" ? "t_active" : ""
                        }
                        onClick={() => {
                          setserviceForm({
                            ...serviceForm,
                            location: "i_travel",
                          });
                        }}
                      >
                        I Travel
                      </span>
                      <span
                        className={
                          serviceForm.location == "i_host" ? "t_active" : ""
                        }
                        onClick={() => {
                          setserviceForm({
                            ...serviceForm,
                            location: "i_host",
                          });
                        }}
                      >
                        I Host
                      </span>
                      <span
                        className={
                          serviceForm.location == "both" ? "t_active" : ""
                        }
                        onClick={() => {
                          setserviceForm({ ...serviceForm, location: "both" });
                        }}
                      >
                        Both
                      </span>
                    </div>
                    {serviceForm.location !== "i_travel" && (
                      <>
                        <div className="group-input mt-3 p-15 clearfix">
                          <PlacesAutocomplete
                            value={serviceForm.adress}
                            onChange={(e) => {
                              setserviceForm({
                                ...serviceForm,
                                adress: e,
                              });
                            }}
                            onSelect={(address) => {
                              geocodeByAddress(address)
                                .then((results) => getLatLng(results[0]))
                                .then((latLng) => {
                                  setserviceForm({
                                    ...serviceForm,
                                    adress: address,
                                    locationCordinates: {
                                      type: "Point",
                                      coordinates: [latLng.lng, latLng.lat],
                                    },
                                  });
                                })
                                .catch((error) =>
                                  console.error("Error", error)
                                );
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  required
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions?.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <div className="detail_location mt-0">
                <h4 className="text-center">Time Frame</h4>

                <div className="row">
                  <div className="col-12 col-sm-6 mt-3">
                    <label htmlFor="">Session Hours</label>
                    {/*<div className="time-picker  mt-2">*/}
                    {/*    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{*/}
                    {/*        backgroundColor: "white",*/}
                    {/*        paddingTop: "0 !important"*/}
                    {/*    }}>*/}
                    {/*        <DemoContainer*/}
                    {/*            components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}*/}
                    {/*        >*/}
                    {/*            <DemoItem sx={{backgroundColor: "white !important"}}>*/}
                    {/*                <TimePicker ampm={false} views={['hours']}*/}
                    {/*                            required*/}
                    {/*                            value={dayjs(`2022-04-17T${serviceForm.session_hours}:30`)}*/}
                    {/*                            sx={{*/}
                    {/*                                backgroundColor: "white",*/}
                    {/*                                borderRadius: "5px",*/}

                    {/*                                outline: "none"*/}
                    {/*                            }} format="h" onChange={(newValue) => {*/}
                    {/*                    setserviceForm({*/}
                    {/*                        ...serviceForm, session_hours: newValue?.$H,*/}
                    {/*                    });*/}
                    {/*                }}/>*/}
                    {/*            </DemoItem>*/}
                    {/*        </DemoContainer>*/}
                    {/*    </LocalizationProvider>*/}
                    <div className="group-input mt-2 p-15 clearfix">
                      <select
                        name="cars"
                        style={{ fontSize: "15px" }}
                        id="cars"
                        required
                        value={serviceForm.session_hours}
                        onChange={(e) => {
                          setserviceForm({
                            ...serviceForm,
                            session_hours: e.target.value,
                          });
                        }}
                      >
                        <option value="0">00</option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                      </select>
                    </div>
                    {/*</div>*/}
                  </div>
                  <div className="col-12 col-sm-6 mt-3">
                    <label htmlFor=""> Session Minutes</label>
                    {/*<div className="time-picker  mt-2">*/}
                    {/*    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{*/}
                    {/*        backgroundColor: "white",*/}
                    {/*        paddingTop: "0 !important"*/}
                    {/*    }}>*/}
                    {/*        <DemoContainer*/}
                    {/*            components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}*/}
                    {/*        >*/}
                    {/*            <DemoItem sx={{backgroundColor: "white !important"}}>*/}
                    {/*                <TimePicker required views={['minutes']}*/}
                    {/*                            value={dayjs(`2022-04-17T15:${serviceForm.session_minutes}`)}*/}

                    {/*                            sx={{*/}
                    {/*                                backgroundColor: "white",*/}
                    {/*                                borderRadius: "5px",*/}
                    {/*                                outline: "none"*/}
                    {/*                            }} format="mm" onChange={(newValue) => {*/}
                    {/*                    setserviceForm({*/}
                    {/*                        ...serviceForm, session_minutes: newValue?.$m,*/}
                    {/*                    });*/}
                    {/*                }}/>*/}
                    {/*            </DemoItem>*/}
                    {/*        </DemoContainer>*/}
                    {/*    </LocalizationProvider>*/}
                    {/*</div>*/}
                    <div className="group-input mt-2 p-15 clearfix">
                      <select
                        name="minit"
                        id="minit"
                        style={{ fontSize: "15px" }}
                        required
                        value={serviceForm.session_minutes}
                        onChange={(e) => {
                          setserviceForm({
                            ...serviceForm,
                            session_minutes: e.target.value,
                          });
                        }}
                      >
                        {minitObjects?.map((ele) => (
                          <option
                            style={{ marginTop: "5px" }}
                            value={ele.value}
                          >
                            {ele.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {sessionTime && (
                    <div
                      className="col-12 col-sm-12 mt-3"
                      style={{ marginTop: "0 !important" }}
                    >
                      <p className="text-danger t-15-500 m-0">{sessionTime}</p>
                    </div>
                  )}

                  <div className="col-12 col-sm-6 mt-3">
                    <label htmlFor="">Session Price</label>
                    <div className="group-input mt-2 p-15 clearfix">
                      <input
                        type="number"
                        name=""
                        required
                        max={100000}
                        min="0"
                        value={serviceForm.session_price}
                        onChange={(e) => {
                          if (e.target.value?.length <= 6) {
                            setserviceForm({
                              ...serviceForm,
                              session_price: e.target.value,
                            });
                          }
                        }}
                        placeholder="Enter Amount"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 mt-3">
                    <label htmlFor="">Participants</label>
                    <div className="group-input mt-2 p-15 clearfix d-flex">
                      <input
                        type="text"
                        name=""
                        value="1 -"
                        readOnly
                        className="showArrow"
                        style={{
                          width: "40px",
                          padding: "15px 0 15px 20px",
                          borderRadius: "3px 0 0 3px",
                        }}
                      />
                      <input
                        type="number"
                        name=""
                        required
                        max={50}
                        min="1"
                        className="showArrow"
                        value={serviceForm.member}
                        oninput="validity.valid||(value='');"
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            if (e.target.value?.length <= 3) {
                              setserviceForm({
                                ...serviceForm,
                                member: e.target.value,
                              });
                            }
                          }
                        }}
                        placeholder="2"
                        style={{
                          borderRadius: "0 3px 3px 0",
                          paddingLeft: "0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 mt-3">
                    <label htmlFor="">Price / Additional Participants</label>
                    <div className="group-input mt-2 p-15 clearfix">
                      <input
                        type="number"
                        name=""
                        required
                        max={100000}
                        min={0}
                        value={serviceForm.addition_price}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            if (e.target.value?.length <= 6) {
                              setserviceForm({
                                ...serviceForm,
                                addition_price: e.target.value,
                              });
                            }
                          }
                        }}
                        placeholder="Enter Participate"
                      />
                    </div>
                  </div>
                  <div
                    className="col-12 col-sm-6 mt-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <label htmlFor="">Maximum Participants</label>
                    <div className="group-input mt-2 p-15 clearfix">
                      <input
                        type="number"
                        name=""
                        required
                        min={1}
                        max={10000}
                        className="showArrow"
                        value={serviceForm.meximum_member}
                        onChange={(e) => {
                          if (e.target.value > 0) {
                            if (e.target.value?.length <= 3) {
                              setserviceForm({
                                ...serviceForm,
                                meximum_member: e.target.value,
                              });
                            }
                          }
                        }}
                        placeholder="15"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input mt-3 p-15 clearfix ">
                <textarea
                  name=""
                  value={serviceForm.discription}
                  onChange={(e) => {
                    if (e.target.value?.length <= 500) {
                      setserviceForm({
                        ...serviceForm,
                        discription: e.target.value,
                      });
                    }
                  }}
                  style={{ height: "130px" }}
                  placeholder="Describe your services (optional)"
                  rows={3}
                />
              </div>
            </div>
          </div>
          <div className="btn_gred mt-4">
            <button type="submit" className="btn_admina">
              Save
            </button>
          </div>
        </form>
      </Modal>
      <BankAlartPopup
        setOpen={setIsBankAlart}
        open={isBankAlart}
        setOpenBankInfo={setOpenBankInfo}
      />
      <BankView setOpenBankInfo={setOpenBankInfo} openBankInfo={openBankInfo} />

      <InstaTempModal
        open={instaTempModal}
        setOpen={setinstaTempModal}
        details={selectedService}
        type="Service"
      />
    </>
  );
};

export default MyPages;
