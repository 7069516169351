import React from 'react';
import DeleteImage from "../assets/images/deletedot.png";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const RefundPopup = ({open, setOpen, handleRefund, deleteEvent}) => {
    const navigate = useNavigate();
   
    return(
        <>
            <Modal
                centered
                show={open}
                onHide={() => {
                    setOpen(false);
                }}
                style={{zIndex: '100001', background: '#00000096'}}
                dialogClassName="delete-modal"
            >
                <div className="Congratulations_msg">
                    <div className="group-parent m-0" style={{ borderRadius: "10px" }}>
                        <div className="group " style={{ borderRadius: "10px" }}>
                            <div className="padding-inner pt-2" style={{ borderRadius: "10px" }}>
                                <div className="text-center">
                                    <img
                                        className="img-fluid logo"
                                        alt=""
                                        src={DeleteImage}
                                    />
                                </div>
                                <h3 className="mb-3 mb-md-3 page-title text-center">
                                    Refund Payment!
                                </h3>

                                <p className="text-center mb-3 mb-md-5" style={{ color: "#000" }}>
                                    Are you sure you want to give a refund?
                                </p>
                                <div className="w-100 d-flex">
                                    <div className="col-6">
                                        <button type="submit" className="btn_admina delete-button" onClick={() => {
                                            setOpen(false);
                                            handleRefund();
                                        }} style={{lineHeight: "15px"}}>
                                            Keep Booking
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            type="submit"
                                            className="btn_admina delete-confirm-button"
                                            onClick={() => {
                                                deleteEvent()
                                                setOpen(false);
                                            }}
                                        >
                                            Cancel Booking
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default RefundPopup;