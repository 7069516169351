import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Grid, IconButton} from "@mui/material";
import Header from "./Header";
import profileImg from "../assets/images/2.png";
import loactionIcon from "../assets/images/Discovery.svg";
import {Select} from 'antd';
import cardImg from "../assets/images/Cardimg.png";
import cardIcon from "../assets/images/cardIcon.svg";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import userIcon from "../assets/images/userIconWithBg.png"
import dayjs from "dayjs";
import BookingPopup from "./BookingPopup";
import {addMyPageDetails} from "../action/myPageConstant";
import {useDispatch, useSelector} from "react-redux";
import {getGuestOtherProvider, getOtherProvider} from "../action/otherProviderConstant";
import {useNavigate, useParams} from "react-router-dom";
import {
    bookScheduleDate,
    guestBookScheduleDate,
    guestMyScheduleDate,
    guestScheduleLink,
    myScheduleDate,
    scheduleLink,
    systemCharge
} from "../action/calenderConstant";
// import {Calendar} from 'react-modern-calendar-datepicker';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {cardsLists, deleteCards, saveCards} from "../action/walletConstant";
import WalletCardPopup from "./WalletCardPopup";
import {getValueType} from "../Utils/generalUtils";
import {TYPE_RANGE, TYPE_SINGLE_DATE} from "../Utils/constants";
import {Calendars} from "./Calenders";
import {getUpcominEvents} from "../action/hireNcollaborateConstant";
import {GetProviderService, guestGetProviderService} from "../action/serviceConstant";
import moment from "moment";
import {
    getBookingDetails,
    guestsessionBooking,
    guestsessionBookingRequest,
    sessionBooking,
    sessionBookingModify,
    sessionBookingRequest
} from "../action/myBokingConstant";
import {fromAddress} from "react-geocode";
import PlacePopUp from "./PlacePopUp";
import {Calendar, utils} from "react-modern-calendar-datepicker";
import {toast} from "react-toastify";
import {calenderConstant, discountConstant, otherProviderConstant} from "../action/constant";
import PhoneInput from "react-phone-input-2";
import GuestHeader from "./GuestHeader";
import axios from "axios";
import {checkModifyPayment} from "../action/eventConstant";
import {capital} from "../Utils/capitalUtils";
import {priceCalculations, Rules} from "../Utils/priceCalculations";
import {LocalFormate} from "../Utils/DateFormateUtils";
import {getUserRefCode} from "../action/ReffDiscountConstant";
import {get_referral_code_percentage} from "../Utils/reffercode";
import {checkDiscountCode} from "../action/discountCodeConstant";

//  ========= IMPORT ==========


const previousNetworkFees = (myBookingDetails) => {
    if (myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.is_refree == true && myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_discount == false) {
        // console.log(true, "myBookingDetails if")
        return (<div>
            <div
                className="d-flex justify-content-between mt-1">
                    <span
                        className="review-Name">Network fees ( {myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user}% )
                    </span>
                <div className="d-flex gap-1">
                        <span className="review-Name" style={{
                            color: '#9E9E9E', textDecoration: 'line-through'
                        }}>$ {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_amount}</span>
                    <span
                        className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user).toFixed(2)}</span>
                </div>
            </div>
            <p className="yelloe-t-bold t-14-500 m-0">You have
                received
                a {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_percentage}%
                discount for using the referral code.</p>
        </div>)
    } else if (myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.is_refree == true && (myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_discount == true && myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_admin_discount == false)) {
        return (<div>
            <div
                className="d-flex justify-content-between mt-1">
                    <span
                        className="review-Name">Network fees ( {myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user}% )
                    </span>
                <div className="d-flex gap-1">
                        <span className="review-Name" style={{
                            color: '#9E9E9E', textDecoration: 'line-through'
                        }}>$ {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_amount}</span>
                    <span
                        className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user).toFixed(2)}</span>
                </div>
            </div>
            <p className="yelloe-t-bold t-14-500 m-0">You have
                received
                a {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_percentage}%
                discount for using the referral code.</p>
        </div>)
    } else if (myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.is_refree == true && (myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_discount == true && myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_admin_discount == true)) {
        // console.log(true, "myBookingDetails if")
        return (<div>
            <div
                className="d-flex justify-content-between mt-1">
                <span
                    className="review-Name">Network fees ({myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user}% )
                </span>
                <div className="d-flex gap-1">
                    <span className="review-Name" style={{
                        color: '#9E9E9E', textDecoration: 'line-through'
                    }}>$ {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_amount}</span>
                    <span
                        className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user).toFixed(2)}</span>
                </div>
            </div>
            <p className="yelloe-t-bold t-14-500 m-0">You have
                received
                a {Number(myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_percentage ?? 0) + Number(myBookingDetails?.bookingDetails?.payment_history_id?.discount_percentage ?? 0)}%
                discount for using the referral and discount code.</p>
        </div>)
    } else if (myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.is_refree == false && (myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_discount == true && myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_admin_discount == true)) {
        // console.log(true, "myBookingDetails if")
        return (<div>
            <div
                className="d-flex justify-content-between mt-1">
                <span
                    className="review-Name">Network fees ({myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user}% )
                </span>
                <div className="d-flex gap-1">
                    <span className="review-Name" style={{
                        color: '#9E9E9E', textDecoration: 'line-through'
                    }}>$ {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_amount}</span>
                    <span
                        className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user).toFixed(2)}</span>
                </div>
            </div>
            <p className="yelloe-t-bold t-14-500 m-0">You have
                received
                a {myBookingDetails?.bookingDetails?.payment_history_id?.discount_percentage}%
                discount for using the discount code.</p>
        </div>)
    } else {
        // console.log(true, "myBookingDetails if")
        return (<div className="d-flex justify-content-between mt-1">
                <span
                    className="review-Name">Network fees ( {myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user}% )</span>
            <span
                className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user).toFixed(2)}</span>
        </div>)
    }

}

const modifyNetworkFees = (loginUserDetails, refCodeData, modifyBookingInfo) => {
    // console.log(modifyBookingInfo, "modifyBookingInfo")

    if (refCodeData?.referee?.is_expire == false && (modifyBookingInfo?.is_discount == true && modifyBookingInfo?.is_admin_discount == true)) {
        // console.log("modifyBookingInfo", 1)
        return (<div
            className="d-flex justify-content-between align-items-center mt-1">
            <div>
                <span
                    className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}</span>
                {refCodeData?.referee?.is_expire ? null : <p className="yelloe-t-bold t-14-500 m-0">You
                    have received
                    a {Number(refCodeData?.referee?.percentage ?? 0) + Number(modifyBookingInfo?.discount_percentage ?? 0)}%
                    discount for using the referral and dicount
                    code.</p>}
            </div>
            <div className="d-flex gap-2">
                     <span
                         className="review-Name" style={{
                         color: '#9E9E9E', textDecoration: 'line-through'
                     }}>$ {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}</span>
                <span
                    className="review-Name">$ {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(2)}</span>
            </div>
        </div>)
    } else if (refCodeData?.referee?.is_expire == true && (modifyBookingInfo?.is_discount == true && modifyBookingInfo?.is_admin_discount == true)) {
        // console.log("modifyBookingInfo", 2)
        return (<div
            className="d-flex justify-content-between align-items-center mt-1">
            <div>
                <span
                    className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}</span>
                {refCodeData?.referee?.is_expire ? null : <p className="yelloe-t-bold t-14-500 m-0">You
                    have received
                    a {modifyBookingInfo?.discount_percentage}%
                    discount for using the discount
                    code.</p>}
            </div>
            <div className="d-flex gap-2">
                     <span
                         className="review-Name" style={{
                         color: '#9E9E9E', textDecoration: 'line-through'
                     }}>$ {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}</span>
                <span
                    className="review-Name">$ {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(2)}</span>
            </div>
        </div>)
    } else if (refCodeData?.referee?.is_expire == false && modifyBookingInfo?.is_discount == false) {
        // console.log("modifyBookingInfo", 3)
        return (<div
            className="d-flex justify-content-between align-items-center mt-1">
            <div>
                <span
                    className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}</span>
                {refCodeData?.referee?.is_expire ? null : <p className="yelloe-t-bold t-14-500 m-0">You
                    have received
                    a {refCodeData?.referee?.percentage}%
                    discount for using the referral
                    code.</p>}
            </div>
            <div className="d-flex gap-2">
                     <span
                         className="review-Name" style={{
                         color: '#9E9E9E', textDecoration: 'line-through'
                     }}>$ {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}</span>
                <span
                    className="review-Name">$ {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(2)}</span>
            </div>
        </div>)
    } else {
        // console.log("modifyBookingInfo", 4)
        return (
            <div
                className="d-flex justify-content-between align-items-center mt-1">
                <div>
                   <span
                       className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${modifyBookingInfo?.fees_percentage_from_provider}% )`}</span>
                    {refCodeData?.referee?.is_expire ? null :
                        <p className="yelloe-t-bold t-14-500 m-0">You
                            have received
                            a {refCodeData?.referee?.percentage}%
                            discount for using the referral
                            code.</p>}
                </div>
                <div className="d-flex gap-2">
                    {refCodeData?.referee?.is_expire ? null : <span
                        className="review-Name" style={{
                        color: '#9E9E9E', textDecoration: 'line-through'
                    }}>$ {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}</span>}
                    <span
                        className="review-Name">$ {Number(modifyBookingInfo?.fees_amount_from_provider ?? 0).toFixed(2)}</span>
                </div>
            </div>
        )
    }
}


const ModifySessionBooking = ({
                                  value,
                                  onChange,
                                  formatInputText,
                                  inputPlaceholder,
                                  inputClassName,
                                  inputName,
                                  renderInput,
                                  wrapperClassName,
                                  calendarClassName,
                                  calendarTodayClassName,
                                  calendarSelectedDayClassName,
                                  calendarRangeStartClassName,
                                  calendarRangeBetweenClassName,
                                  calendarRangeEndClassName,
                                  calendarPopperPosition,
                                  disabledDays,
                                  onDisabledDayError,
                                  colorPrimary,
                                  colorPrimaryLight,
                                  slideAnimationDuration,
                                  minimumDate,
                                  maximumDate,
                                  selectorStartingYear,
                                  selectorEndingYear,
                                  locale,
                                  shouldHighlightWeekends,
                                  renderFooter,
                                  customDaysClassName,
                              }) => {

    //  ====== STATS MANAGE ========

    const {providerId, serviceId, bookingId} = useParams();
    const navigate = useNavigate();
    const [mainState, setMainState] = useState({
        activeDate: null, monthChangeDirection: '', isMonthSelectorOpen: false, isYearSelectorOpen: false,
    });

    // console.log(mainState, "mainState")

    const [selectedSlot, setSelectedSlot] = useState(null);
    const [details, setDetails] = useState({
        service: "", participants: 1, service_type: "In person", location_type: "Their Place", address: "", latLng: {}
    })
    const [addCard, setAddCard] = useState({
        name: "", number: "", day: "", cvv: ""
    })
    const [isChecking, setIsCheching] = useState(false);
    const [guestUser, setGuestUser] = useState({
        name: "", email: "", mobile_number: "", country_code: ""
    })
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state?.otherProviderDetails?.data);
    const {wallet, calendar, service, myBookingDetails, event, refDescount, discountCode} = useSelector((state) => state);
    const [walletMenu, setwalletMenu] = React.useState(null);
    const Walletopen = Boolean(walletMenu);
    const walletId = Walletopen ? 'simple-popover' : undefined;
    const [CardSelectId, setCardSelectId] = useState("");
    const [matchDate, setMatchDate] = useState({});
    const [isSloting, setIsSloting] = useState([]);
    const [selectedTime, setSelectedTime] = useState("");
    const [selectedWalletId, setSelectedWalletId] = useState(null);
    const [isOpenPlace, setIsOpenPlace] = useState(false);
    const [matchDates, setMatchDates] = useState([])
    let allTimes = [];
    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    const [btnLoader, setBtnLoader] = useState(false);
    const [isCalendarOpen, setCalendarVisiblity] = useState(false);
    const [stateName, setStateName] = useState('');
    const [placeNote, setPlaceNote] = useState("");
    const [modifyBookingInfo, setModifyBookingInfo] = useState({});
    const [selectedAddress, setSelectedAddress] = useState("");
    const [selectedLatlong, setSelectedLatlong] = useState("");
    const [fixTax, setFixTax] = useState({
        tax: 0, tax_id: 0
    })
    const momentZone = moment.tz.guess()
    const loginUserDetails = useSelector((state) => state.user);
    const [refCodeData, setRefCodeData] = useState({
        referee: {
            id: null, is_referee: false, reward_type: null, percentage: null, is_expire: true,
        }, referrer: {
            id: null, is_referee: false, reward_type: null, percentage: null, is_expire: true,
        }
    })
    const [discountDetails, setDiscountDetails] = useState({
        is_admin_created: null,
        is_expire: true,
        discount_percentage: 0,
        discount_code: null,
        _id: null,
        is_discount: false
    })
    // console.log(discountDetails, "discountDetails")
    const [isDiscountCode, setIsDiscountCode] = useState("")
    // const [modifyCharges, setModifyCharges] = useState({
    //     modifyPercentage: '',
    //     systemPercentage: '',
    //     modifyAmount: '',
    //     systemAmount: '',
    //     total: '',
    //     amount: '',
    //     totalParcentage: ''
    // })
    const [modifyCharges, setModifyCharges] = useState();


    const getProviderDetails = () => {
        if (!userAuth?.token) {
            dispatch(getGuestOtherProvider(providerId))
        } else {
            dispatch(getOtherProvider(providerId, (a) => {
            }))
        }
    }


    const providerService = () => {
        const SERVICE = []
        service?.userService?.map((e) => {
            const service = e?.service_name
            SERVICE.push({value: e?.service_name, label: service.charAt(0).toUpperCase() + service.slice(1), id: e._id})
        })
        return SERVICE || []
    }

    // useEffect(() => {
    //     if (providerService().length) {
    //         providerService().map((ele) => {
    //
    //             if (serviceId) {
    //                 if (ele.id == serviceId) {
    //                     setDetails((pre) => ({...pre, service: ele?.value}))
    //                 }
    //             } else {
    //                 setDetails((pre) => ({...pre, service: providerService()[0]?.value}))
    //             }
    //         })
    //     }
    // }, [(service?.userService), serviceId])

    useEffect(() => {
        // const slotBookedStartTime = moment(myBookingDetails?.bookingDetails?.start_date_time).format('LT');
        // const slotBookedEndTime = moment(myBookingDetails?.bookingDetails?.end_date_time).format('LT');
        const slotBookedStartTime = moment(LocalFormate(myBookingDetails.bookingDetails.start_date_time, myBookingDetails?.bookingDetails?.date_timezone)).format('hh:mm A');
        const slotBookedEndTime = moment(LocalFormate(myBookingDetails.bookingDetails.end_date_time, myBookingDetails?.bookingDetails?.date_timezone)).format('hh:mm A');

        // const formattedStartTime = moment(slotBookedStartTime, 'hh:mm A').format('h:mm A');
        // const formattedEndTime = moment(slotBookedEndTime, 'hh:mm A').format('h:mm A');
        //
        // const [endHour, endMinute, endPeriod] = formattedEndTime.split(' ');
        // const formattedEndHour = endHour.padStart(2, '0');
        //
        // const selectedSlot = `${formattedStartTime} to ${formattedEndHour}:${endMinute} ${endPeriod}`;
        // const formattedEndMinutes = endMinute.padStart(2, '0');
        // const selectedTime = `${formattedStartTime} to ${formattedEndHour}:${formattedEndMinutes} ${endPeriod}`;

        if (providerService().length) {
            providerService().map((ele) => {
                if (myBookingDetails?.bookingDetails?.service_id?._id) {
                    if (ele.id == myBookingDetails?.bookingDetails?.service_id?._id) {
                        setDetails((pre) => ({...pre, service: ele?.value}))
                    }
                } else {
                    setDetails((pre) => ({...pre, service: providerService()[0]?.value}))
                }
            })
            setDetails((pre) => ({
                ...pre,
                participants: myBookingDetails?.bookingDetails?.no_of_participants,
                service_type: myBookingDetails?.bookingDetails?.service_type == "in_person" ? "In person" : "virtual",
                location_type: myBookingDetails?.bookingDetails?.location_type == "their_place" ? "Their Place" : "My Place",
                address: myBookingDetails?.bookingDetails?.service_id?.address,
                latLng: myBookingDetails?.bookingDetails?.service_id?.location
            }))
            setMainState((pre) => ({
                ...pre, activeDate: {
                    day: Number(moment(LocalFormate(myBookingDetails?.bookingDetails?.booking_date, myBookingDetails?.bookingDetails?.date_timezone)).format("D") ?? 1),
                    month: Number(moment(LocalFormate(myBookingDetails?.bookingDetails?.booking_date, myBookingDetails?.bookingDetails?.date_timezone)).format("MM") ?? new Date().getMonth()),
                    year: Number(moment(LocalFormate(myBookingDetails?.bookingDetails?.booking_date, myBookingDetails?.bookingDetails?.date_timezone)).format("YYYY") ?? new Date().getFullYear()),
                }
            }))


            setSelectedSlot(`${slotBookedStartTime} : ${slotBookedEndTime}`)
            setSelectedTime(`${slotBookedStartTime} : ${slotBookedEndTime}`)
            
                    console.log(myBookingDetails?.bookingDetails?.service_id?.location, myBookingDetails?.bookingDetails)
        }
    }, [myBookingDetails?.bookingDetails, service?.userService])


    const getOneService = () => {
        const oneService = service?.userService && service?.userService?.filter((e) => {
            if (e?.service_name == details?.service) {
                return e
            }
        })
        return oneService[0]
    }

    const matchServiceID = () => {
        const ARRAY = []
        calendar?.scheduleList?.map((ele) => {
            ele?.service_id?.map((req) => {

                if (req._id == getOneService()?._id) {
                    ARRAY.push(ele)
                    getSlotTime(ele);
                }
            })
        })
        setMatchDates(ARRAY)
    }

    useEffect(() => {
        if (calendar?.scheduleList?.length && mainState.activeDate !== null) {
            matchServiceID()
        }
    }, [calendar?.scheduleList, mainState.activeDate])


    const handleAddCard = () => {
        const date = moment(addCard?.day?.split("/")[1], "YY")
        const year = date.format("YYYY")
        const payload = {
            name: addCard.name,
            number: addCard.number,
            year: year,
            month: moment(addCard.day, 'MM/YY').format('MM'),
            cvv: addCard.cvv
        }
        dispatch(saveCards(payload))
        setAddCard({
            name: "", number: "", day: "", cvv: ""
        })
        // setTimeout(() => {
        //     getAllCards()
        // }, 500);

    }

    const getAllCards = () => {
        if (userAuth?.token) {
            dispatch(cardsLists())
        }
    }

    const handleWalletMenuClick = (event) => {
        setwalletMenu(event.currentTarget);
    };

    const handleWalletMenuClose = () => {
        setwalletMenu(null);
    };

    const handleDeleteCard = () => {
        dispatch(deleteCards(CardSelectId))
        setTimeout(() => {
            getAllCards()
        }, 500);
    }

    const handleCalendarChange = newValue => {
        const valueType = getValueType(value);
        setMainState((pre) => ({...pre, activeDate: newValue}))


        if (valueType === TYPE_SINGLE_DATE) setCalendarVisiblity(false); else if (valueType === TYPE_RANGE && newValue.from && newValue.to) setCalendarVisiblity(false);
    };

    const getBookedScedule = () => {
        const payload = {
            userId: providerId,
            schedule_month: mainState?.activeDate?.month || moment(new Date()).format('MM'),
            schedule_year: mainState?.activeDate?.year || moment(new Date()).format('YYYY'),
            service_id: getOneService()?._id
        }
        if (!userAuth?.token) {
            dispatch(guestBookScheduleDate(payload))
        } else {
            dispatch(bookScheduleDate(payload))
        }

    }

    const getMyScedule = () => {
        const payload = {
            userId: providerId,
            schedule_month: mainState?.activeDate?.month || moment(new Date()).format('MM'),
            schedule_year: mainState?.activeDate?.year || moment(new Date()).format('YYYY'),
            service_id: getOneService()?._id
        }
        if (!userAuth?.token) {
            dispatch(guestMyScheduleDate(payload))
        } else {
            dispatch(myScheduleDate(payload))
        }
    }


    function getDates(startDate, stopDate) {
        var dateArray = [];
        var ObjectDate = []
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = moment(currentDate).add(1, 'days');
        }


        calendar?.bookedDate.map((e) => {
            ObjectDate.push({
                year: Number(moment(e).format('YYYY')),
                month: Number(moment(e).format('MM')),
                day: Number(moment(e).format('DD')),
                className: "yellowDay",
            })
        })

        dateArray?.map((e) => {
            ObjectDate.push({
                year: Number(moment(e).format('YYYY')),
                month: Number(moment(e).format('MM')),
                day: Number(moment(e).format('DD')),
                className: "purpleDay",
            })
        })


        return ObjectDate;
    }

    useEffect(() => {
        getDates(new Date(), new Date())
    }, [])

    const getproviderService = () => {
        if (!userAuth?.token) {
            dispatch(guestGetProviderService(providerId))
        } else {
            dispatch(GetProviderService(providerId))
        }
        if (details.location_type == "Their Place") {
            setDetails((pre) => ({...pre, address: getOneService()?.address}))
        }
    }

    useEffect(() => {
        setIsCheching(false)
        getAllCards()
        return () => {
            setMainState((pre) => ({...pre, activeDate: null}))
            setIsCheching(false)
            setBtnLoader(false)
            setMatchDate({})
            setIsSloting([])
        }
    }, []);

    useEffect(() => {
        getProviderDetails()
        getproviderService()
    }, [providerId]);

    useEffect(() => {
        if (details.service) {
            getMyScedule()
            getBookedScedule()
        }

        return () => {
            dispatch({
                type: calenderConstant.REMOVE_MY_SCHEDULE_DATE, payload: null,
            });
        };
    }, [mainState.activeDate, details.service, myBookingDetails, bookingId, providerId]);

    const getSlectTime = () => {
        calendar?.myBookedDate?.map((ele) => {
            // if ((moment(ele?.schedule_start_date).format('D') < mainState?.activeDate?.day)
            //     && (moment(ele?.schedule_end_date).format('D') > mainState?.activeDate?.day)
            // ) {
            //     setMatchDate(ele)
            // }else {
            //     console.log(false)
            // }

            if (moment(ele?.schedule_start_date).format('D') <= mainState?.activeDate?.day <= moment(ele?.schedule_end_date).format('D')) {
                setMatchDate(ele)
            } else {
                console.log(false)
            }

            if (mainState?.activeDate?.day >= moment(ele?.schedule_start_date).format('D') && mainState?.activeDate?.day <= moment(ele?.schedule_end_date).format('D')) {
                setMatchDate(ele)
                if (matchDates && mainState?.activeDate !== null) {
                    matchDates?.map((req) => {
                        getSlotTime(req?.schedule_start_date, req?.schedule_end_date)
                    })
                }
            } else {
                setIsSloting([])
            }
        })
    }


    useEffect(() => {
        if (mainState.activeDate && userDetails?.details?._id) {
            getScheduleList()
            getSlectTime()
        }
    }, [mainState.activeDate, userDetails?.details]);


    const matchBookedDate = () => {
        const codition = calendar?.bookedDate?.map((e) => {
            if (moment(e).format('DD') == mainState?.activeDate?.day) {
                return true
            } else {
                return false
            }
        })
        return codition
    }

    // ...sloting....

    const getSlotTime = (ele) => {
        // const start = LocalFormate(ele?.schedule_start_date, ele?.date_timezone);
        // const end = LocalFormate(ele?.schedule_end_date, ele?.date_timezone);
        const start = moment(LocalFormate(ele?.schedule_start_date, ele?.date_timezone)).format('YYYY-MM-DDTHH:mm:ss')
        const end = moment(LocalFormate(ele?.schedule_end_date, ele?.date_timezone)).format('YYYY-MM-DDTHH:mm:ss')

        let currentTime = new Date(`January 1, 2023 ${`${start?.split("T")[1].split(":")[0]}:${start?.split("T")[1]?.split(":")[1]}`}`);
        const endT = new Date(`January 1, 2023 ${`${end?.split("T")[1]?.split(":")[0]}:${end?.split("T")[1]?.split(":")[1]}`}`);
        while (currentTime <= endT) {
            const formattedTime = currentTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
            const slot = `${formattedTime} : `;

            currentTime.setHours(currentTime.getHours() + getOneService()?.session_hour);
            currentTime.setMinutes(currentTime.getMinutes() + getOneService()?.session_minute);
            const endTimeFormatted = currentTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
            if (currentTime > endT) {
                break
            }
            const SCHEDULEOBJECT = {
                time: `${slot}${endTimeFormatted}`,
                address: ele?.address,
                miles: ele?.miles_distance,
                location: ele?.location,
                timeZone: ele?.date_timezone,
                startTime: ele?.schedule_start_date
            }

            // console.log(formattedTime, slot, endTimeFormatted, "SCHEDULEOBJECT")

            allTimes.push(SCHEDULEOBJECT);
        }

        // allTimes.pop()
        if (matchBookedDate().includes(true)) {
            setIsSloting([])
        } else {
            setIsSloting(allTimes);
        }
    }

    useEffect(() => {
        if (details.location_type == "Their Place") {
            setDetails((pre) => ({...pre, address: getOneService()?.address}))
        }
    }, [getOneService()])

    // useEffect(() => {
    //     if (matchDates && mainState?.activeDate !== null) {
    //         matchDates?.map((ele) => {
    //             getSlotTime(ele?.schedule_start_date, ele?.schedule_end_date)
    //         })
    //         // getSlotTime(matchDate?.schedule_start_date, matchDate?.schedule_end_date)
    //
    //     }
    // }, [matchDates]);
    function validateEmail(email) {
        // Regular expression for validating an Email
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    // useEffect(() => {
    //     const start_date_time = moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD')
    //         .add(parseInt(selectedTime.split(" : ")[0]?.split(":")[0]), "hours")
    //         .add(parseInt(selectedTime.split(" : ")[0]?.split(":")[1]), "minutes")
    //         .subtract(1, 'day')  // Subtract 1 day from the date
    //         .format("YYYY-MM-DDTHH:mm:ss[Z]");
    //
    //     const end_date_time = moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD')
    //         .add(parseInt(selectedTime.split(" : ")[1]?.split(":")[0]), "hours")
    //         .add(parseInt(selectedTime.split(" : ")[1]?.split(":")[1]), "minutes")
    //         .subtract(1, 'day')  // Subtract 1 day from the date
    //         .format("YYYY-MM-DDTHH:mm:ss[Z]");
    //
    //     console.log(start_date_time, end_date_time, "end_date_time")
    // }, [selectedTime]);

    const margeDateTime = (mainState, selectedTime, start) => {
        const formatedDate = moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').format("ddd MMM DD YYYY");
        let timeFormat = new Date();
        const [startDate, endDate] = selectedTime?.split(" : ");
        if (start) {
            if (startDate) {
                const [hour, minute] = startDate?.split(":");
                timeFormat.setHours(parseInt(hour, 10));
                timeFormat.setMinutes(parseInt(minute, 10));
                timeFormat.setSeconds(parseInt(0, 10));
                const splitTime = timeFormat.toString().split(" ")
                const sliceJoin = splitTime.slice(4, splitTime.length).join(" ")
                const margeDateTime = formatedDate + " " + sliceJoin
                return margeDateTime
            }

        } else {
            if (endDate) {
                const [hour, minute] = endDate?.split(":");
                timeFormat.setHours(parseInt(hour, 10));
                timeFormat.setMinutes(parseInt(minute, 10));
                timeFormat.setSeconds(parseInt(0, 10));
                const splitTime = timeFormat.toString().split(" ")
                const sliceJoin = splitTime.slice(4, splitTime.length).join(" ")
                const margeDateTime = formatedDate + " " + sliceJoin
                return margeDateTime
            }

        }


    }
    const BookSession = () => {
        const start_date_time = moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD')
            .add(parseInt(selectedTime.split(" : ")[0]?.split(":")[0]), "hours")
            .add(parseInt(selectedTime.split(" : ")[0]?.split(":")[1]), "minutes")
            .subtract(1, 'day')  // Subtract 1 day from the date
            .format("YYYY-MM-DDTHH:mm:ss[Z]");

        const end_date_time = moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD')
            .add(parseInt(selectedTime.split(" : ")[1]?.split(":")[0]), "hours")
            .add(parseInt(selectedTime.split(" : ")[1]?.split(":")[1]), "minutes")
            .subtract(1, 'day')  // Subtract 1 day from the date
            .format("YYYY-MM-DDTHH:mm:ss[Z]");
        if (!stateName.length) {
            openSiteInfo()
        } else {
            setBtnLoader(true)
            if (details.address == "") {
                setBtnLoader(false)
                toast.error("Please Enter Your Address")
            } else if (!userAuth?.token && (guestUser?.email?.length && !validateEmail(guestUser.email))) {
                setBtnLoader(false)
                toast.error("Please Enter valid email")
            } else {
                // if (!userAuth?.token) {
                //     const date = moment(addCard?.day?.split("/")[1], "YY")
                //     const year = date.format("YYYY")
                //     const payload = {
                //         booking_type: "service",
                //         service_id: getOneService()?._id,
                //         provider_id: userDetails?.details?._id,
                //         schedule_id: matchDate?._id,
                //         booking_date: moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').format("YYYY-MM-DDTHH:mm:ss[Z]"),
                //         start_date_time: moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').add(selectedTime.split(" : ")[0]?.split(":")[0], "hours").add(selectedTime.split(" : ")[0]?.split(":")[1], "minutes").format("YYYY-MM-DDTHH:mm:ss[Z]"),
                //         end_date_time: moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').add(selectedTime.split(" : ")[1]?.split(":")[0], "hours").add(selectedTime.split(" : ")[1]?.split(":")[1], "minutes").format("YYYY-MM-DDTHH:mm:ss[Z]"),
                //         location: details?.location_type == "My Place" ? (details?.latLng || "") : getOneService()?.location,
                //         address: details?.location_type !== "My Place" ? getOneService()?.address : details?.address,
                //         no_of_participants: details?.participants,
                //         service_type: details?.service_type == "In person" ? "in_person" : "virtual",
                //         location_type: details?.location_type == "My Place" ? "my_place" : "their_place",
                //         total_paid_amount: texCount((Number(getOneService()?.session_price * details?.participants)), Number(calendar?.systemCharge?.tem_fees), Number(fixTax?.tax), "amount"),
                //         is_guest_user: true,
                //         name: guestUser?.name,
                //         email_address: guestUser?.email,
                //         phone_number: guestUser?.mobile_number,
                //         country_code: guestUser?.country_code,
                //         card_holder_name: addCard?.name,
                //         card_number: addCard?.number,
                //         expire_year: year,
                //         expire_month: moment(addCard.day, 'MM/YY').format('MM'),
                //         cvv: addCard.cvv,
                //         total_amount: Number(getOneService()?.session_price * details?.participants),
                //         fees_percentage_from_user: userAuth?.is_ambassador ? Number(0) : Number(calendar?.systemCharge?.tem_fees),
                //         fees_amount_from_user: texCount((Number(getOneService()?.session_price * details?.participants)), Number(calendar?.systemCharge?.tem_fees), Number(calendar?.systemCharge?.tem_tax), "tax"),
                //         sales_tax_percentage: Number(fixTax?.tax),
                //         sales_tax_amount_from_user: texCount((Number(getOneService()?.session_price * details?.participants)), Number(calendar?.systemCharge?.tem_fees), Number(fixTax?.tax), "oneTax"),
                //         sales_tax_id: fixTax?.tax_id,
                //         description: placeNote,
                //     }
                //     // dispatch(guestsessionBooking(payload, (a) => {
                //     //     setBtnLoader(a)
                //     // }))
                //     dispatch(guestsessionBookingRequest(payload, (a) => {
                //         setBtnLoader(a)
                //     }))
                // } else {
                const payload = {
                    booking_type: "service",
                    service_id: getOneService()?._id,
                    provider_id: userDetails?.details?._id,
                    schedule_id: matchDate?._id,
                    booking_date: margeDateTime(mainState, selectedTime, true), // start_date_time: moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').add(selectedTime.split(" : ")[0]?.split(":")[0], "hours").add(selectedTime.split(" : ")[0]?.split(":")[1], "minutes").format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    // end_date_time: moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').add(selectedTime.split(" : ")[1]?.split(":")[0], "hours").add(selectedTime.split(" : ")[1]?.split(":")[1], "minutes").format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    start_date_time: margeDateTime(mainState, selectedTime, true),
                    end_date_time: margeDateTime(mainState, selectedTime, false),
                    location: selectedLatlong == "" ? myBookingDetails?.bookingDetails?.service_id?.location : selectedLatlong,
                    address: selectedAddress,
                    current_timezone: momentZone,
                    no_of_participants: details?.participants,

                    service_type: details?.service_type == "In person" ? "in_person" : "virtual",
                    location_type: details?.location_type == "My Place" ? "my_place" : "their_place",

                    total_paid_amount: Math.abs(Number(modifyCharges?.totalPaidAmount ?? 0)),

                    card_id: selectedWalletId,

                    total_amount: Math.abs(Number(modifyCharges?.totalAmount ?? 0)),
                    fees_percentage_from_user: Math.abs(Number(modifyCharges?.feesPercentageFromUser ?? 0)),
                    fees_amount_from_user: Math.abs(Number(modifyCharges?.feesAmountFromUser ?? 0)),
                    sales_tax_percentage: Number(modifyCharges?.salesTaxPercentage ?? 0),
                    sales_tax_amount_from_user: Math.abs(Number(modifyCharges?.salesTaxAmountFromUser ?? 0)),
                    sales_tax_id: modifyCharges?.salesTaxId,

                    description: placeNote,
                    booking_id: bookingId,
                    modify_booking_payment_info: modifyBookingInfo, // modify_booking_payment_info: {
                    //     old_participate: myBookingDetails?.bookingDetails?.no_of_participants ?? 0,
                    //     new_participate: details?.participants ?? 0,
                    //     old_amount: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount ?? 0)?.toFixed(2),
                    //     new_amount: Number(modifyCharges?.amount ?? 0)?.toFixed(2),
                    //     old_fees_percentage_from_user: myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user ?? 0,
                    //     old_fees_amount_from_user: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user ?? 0)?.toFixed(2),
                    //     old_sales_tax_percentage: myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_percentage ?? 0,
                    //     old_sales_tax_amount_from_user: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_amount_from_user ?? 0)?.toFixed(2),
                    //     fees_percentage_from_user: modifyCharges?.modifyPercentage ?? 0,
                    //     fees_amount_from_user: Number(modifyCharges?.modifyAmount ?? 0)?.toFixed(2),
                    //     sales_tax_percentage: modifyCharges?.systemPercentage ?? 0,
                    //     sales_tax_amount_from_user: Number(modifyCharges?.systemAmount ?? 0)?.toFixed(2),
                    //     old_total_paid_amount: Number(myBookingDetails?.bookingDetails?.booking_payment_info?.total_paid_amount ?? 0)?.toFixed(2),
                    //     new_total_paid_amount: Number(modifyCharges?.total ?? 0)?.toFixed(2)
                    // }
                }
                // dispatch(sessionBooking(payload, (a) => {
                //     setBtnLoader(a)
                // }))
                dispatch(sessionBookingModify(payload, (a) => {
                    setBtnLoader(a)
                }))
                // }
            }
        }
    }


    useEffect(() => {
        if (myBookingDetails?.sessionBooked?.success?.status == true || myBookingDetails?.modifyedBooking?.success?.status == true || event?.checkModifyPayment?.success == true) {
            setBtnLoader(false)
            setIsCheching(true)
        } else {
            setBtnLoader(false)
            setIsCheching(false)
        }
    }, [myBookingDetails, event?.checkModifyPayment])


    useEffect(() => {
        setDetails((pre) => ({
            ...pre, address: details.location_type == "Their Place" ? getOneService()?.address : ""
        }))
    }, [details.location_type, getOneService()]);


    const handleSubmit = async () => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser');
            return;
        }
        try {
            const geocoder = new window.google.maps.Geocoder();
            const response = await geocoder.geocode({address: details.address});
            const lat = response.results[0].geometry.location.lat();
            const lng = response.results[0].geometry.location.lng();
            setDetails((pre) => ({
                ...pre, latLng: {
                    type: "Point", coordinates: [lng, lat]
                }
            }));
        } catch (error) {
            console.error("error");
        }
    };

    useEffect(() => {
        handleSubmit()
    }, [details.address]);


    const handleCancelCard = () => {
        setAddCard({
            name: "", number: "", day: "", cvv: ""
        })
    }

    const getScheduleList = () => {
        const paylod = {
            userId: userDetails?.details?._id,
            scheduleDate: moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').format("YYYY-MM-DDTHH:mm:ss[Z]")
        }

        if (!userAuth?.token) {
            dispatch(guestScheduleLink(paylod))
        } else {
            dispatch(scheduleLink(paylod))
        }
        matchServiceID()
    }

    const handleSelectService = () => {
        setMainState((pre) => ({...pre, activeDate: null}))
        getSlotTime(null)
        setSelectedTime("")
        setSelectedWalletId("")
    }


    const getCurrentTime = () => {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        return `${hours}:${minutes}`;
    };

    const currentTime = getCurrentTime();

    const timeslotAfterTime = () => {
        // const today = new Date()
        // var hours = today.getHours();
        // var minutes = today.getMinutes();
        // var ampm = hours >= 12 ? 'PM' : 'AM';
        // hours = hours % 12;
        // hours = hours ? hours : 12; // the hour '0' should be '12'
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        // var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime, 'isSloting')
        // isSloting.map((ele) => {
        //     if (ele?.split(" : ")[0] > strTime) {
        //         console.log(ele?.split(" : ")[0], strTime)
        //     } else {
        //         console.log(false, "isSloting")
        //         console.log(ele?.split(" : ")[0], strTime)
        //     }
        // })
        // console.log(isSloting, 'isSloting')
        const currenAsMinute = moment.duration({hours: currentTime?.split(":")[0], minutes: currentTime.split(":")[1]})

        const filteredTimeSlots = isSloting.filter(slot => {
            const slotStartTime = slot?.time.split(" : ")[0];
            const fullhours = moment(slotStartTime, ["h:mm A"]).format("HH:mm");
            const asMinute = moment.duration({hours: fullhours?.split(":")[0], minutes: fullhours.split(":")[1]})
            return asMinute.asMinutes() > currenAsMinute.asMinutes();
        });

        let indexToKeep = isSloting.indexOf(...filteredTimeSlots);

        if (indexToKeep !== -1) {
            isSloting.splice(0, indexToKeep);
        }
    }

    const cardNumberReplace = (number) => {
        const numberStr = number.replace(/.(?=.{4})/g, 'X');
        return numberStr
    }


    // remove previous time

    useEffect(() => {
        const toayDate = new Date()
        if (moment(toayDate).format("YYYY-MM-DD") == moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').format("YYYY-MM-DD")) {
            timeslotAfterTime()
        }

    }, [isSloting]);


    const getSystemCharge = () => { 
        dispatch(systemCharge())
    }

    useEffect(() => {
        getSystemCharge()
    }, []);

    const texCount = (totalAmount, network, tax, key) => {

        const taxPercentage = tax;

        const normaltex = totalAmount * (taxPercentage / 100);

        const nexText = totalAmount * (network / 100)

        const plusText = loginUserDetails?.is_ambassador ? taxPercentage : taxPercentage + network

// Convert tax percentage to decimal
        const taxDecimal = plusText / 100;

// Calculate the tax amount
        const taxAmount = totalAmount * taxDecimal;

// Calculate the updated total amount with tax included
        const totalAmountWithTax = totalAmount + taxAmount;


        if (key == "tax") {
            return taxAmount?.toFixed(2)
        } else if (key == "amount") {
            return totalAmountWithTax?.toFixed(2)
        } else if (key == "network") {
            return nexText.toFixed(2)
        } else if (key == "oneTax") {
            return normaltex.toFixed(2)
        } else {
            return "00"
        }
    }


    const getMyStateTax = () => {

        calendar?.systemCharge?.sales_tax?.map((ele) => {
            if (ele?.unique_code == stateName) {
                setFixTax({
                    tax: ele?.tax_rate, tax_id: ele?._id
                })
            }
        })
    }


    useEffect(() => {
        getMyStateTax()
    }, [calendar?.systemCharge, stateName]);

    //   ============== FOR STATE LOCATION =================
    useEffect(() => {
        const fetchData = async () => {
            try {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(async (position) => {
                        const {latitude, longitude} = position.coords;


                        const googleMapApiKey = 'AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs';

                        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapApiKey}`;
                        const response = await fetch(apiUrl);

                        if (response.ok) {
                            const data = await response.json();

                            // Check if the response contains results
                            if (data.status === 'OK') {
                                // Extract the state from the address components
                                const results = data.results || [];
                                for (const result of results) {
                                    const addressComponents = result.address_components || [];
                                    for (const component of addressComponents) {
                                        const types = component.types || [];
                                        if (types.includes('administrative_area_level_1')) {
                                            setStateName(component.short_name);
                                            return;
                                        }
                                    }
                                }
                            }
                        } else {
                            console.error(`Failed to fetch data Status: ${response.status}`);
                        }
                    }, (error) => {
                        console.error("Error getting geolocation:", error);
                    });
                } else {
                    console.log("Geolocation is not supported");
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs once when the component mounts


    const openSiteInfo = () => {
        alert("Please provide location permission.\nTo manage location permissions, click on the lock icon next to the website's URL in the browser's address bar.");
    };

    const [center, setCenter] = useState();

    useEffect(() => {
        if ("geolocation" in navigator) {
            //check geolocation available
            //try to get user current location using getCurrentPosition() method
            navigator?.geolocation?.getCurrentPosition(async (position) => {
                // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
                await setCenter({
                    lat: position?.coords?.latitude, lng: position?.coords?.longitude,
                });
            });
        } else {
            console.log("Browser doesn't support geolocation!");
        }
    }, []);

    const gateData = () => {
        const payload = {
            id: bookingId, latLong: center
        }
        dispatch(getBookingDetails(payload))
    }

    useEffect(() => {
        if (bookingId) {
            gateData()
        }
    }, [bookingId])


    const modifyCount = () => {
        // if (myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount) {
        //     const AMAOUNT = Number(getOneService()?.session_price * details?.participants) - Number(myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount)
        //
        //
        //
        //     if ((myBookingDetails?.bookingDetails?.modified_count ?? 0) > 2) {
        //         console.log(1, "amount")
        //         if (userAuth?.is_ambassador === true) {
        //             console.log(2, "amount")
        //             const modifyPercentage = 8; //team fees
        //             const systemPercentage = 0; //tax
        //             const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
        //             const systemAmount = (AMAOUNT * systemPercentage) / 100;
        //             const totalAmount = AMAOUNT + modifyAmount + systemAmount;
        //             const totalParcentage = modifyPercentage + systemPercentage
        //             setModifyCharges((pre) => ({
        //                 ...pre,
        //                 modifyPercentage: modifyPercentage,
        //                 systemPercentage: systemPercentage,
        //                 modifyAmount: modifyAmount?.toFixed(2),
        //                 systemAmount: systemAmount?.toFixed(2),
        //                 total: totalAmount?.toFixed(2),
        //                 amount: AMAOUNT?.toFixed(2),
        //                 totalParcentage: totalParcentage?.toFixed(2)
        //             }))
        //
        //             // modify charge 8%
        //             // system charge 0
        //         } else {
        //             console.log(3, "amount")
        //             const modifyPercentage = 8; //team fees
        //             const systemPercentage = 2; //tax
        //             const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
        //             const systemAmount = (AMAOUNT * systemPercentage) / 100;
        //             const totalAmount = AMAOUNT + modifyAmount + systemAmount;
        //             const totalParcentage = modifyPercentage + systemPercentage
        //             setModifyCharges((pre) => ({
        //                 ...pre,
        //                 modifyPercentage: modifyPercentage,
        //                 systemPercentage: systemPercentage,
        //                 modifyAmount: modifyAmount?.toFixed(2),
        //                 systemAmount: systemAmount?.toFixed(2),
        //                 total: totalAmount?.toFixed(2),
        //                 amount: AMAOUNT?.toFixed(2),
        //                 totalParcentage: totalParcentage?.toFixed(2)
        //             }))
        //
        //             // modify charge 8%
        //             // system charge 2%
        //         }
        //     } else if (myBookingDetails?.bookingDetails?.start_date_time) {
        //         console.log(4, "amount")
        //         const currentTime = new Date();
        //         const oneHourLater = new Date(currentTime.getTime() + (60 * 60 * 1000)); // Calculate 1 hour later
        //
        //         if (currentTime < myBookingDetails?.bookingDetails?.start_date_time && myBookingDetails?.bookingDetails?.start_date_time < oneHourLater) {
        //             if (userAuth?.is_ambassador === true) {
        //                 console.log(6, "amount")
        //                 const modifyPercentage = 5;
        //                 const systemPercentage = 0;
        //                 const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
        //                 const systemAmount = (AMAOUNT * systemPercentage) / 100;
        //                 const totalAmount = AMAOUNT + modifyAmount + systemAmount;
        //                 const totalParcentage = modifyPercentage + systemPercentage
        //                 setModifyCharges((pre) => ({
        //                     ...pre,
        //                     modifyPercentage: modifyPercentage,
        //                     systemPercentage: systemPercentage,
        //                     modifyAmount: modifyAmount?.toFixed(2),
        //                     systemAmount: systemAmount?.toFixed(2),
        //                     total: totalAmount?.toFixed(2),
        //                     amount: AMAOUNT?.toFixed(2),
        //                     totalParcentage: totalParcentage?.toFixed(2)
        //                 }))
        //
        //                 // modify charge 5%
        //                 // system charge 0
        //             } else {
        //                 console.log(5, "amount")
        //                 const modifyPercentage = 5;
        //                 const systemPercentage = 2;
        //                 const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
        //                 const systemAmount = (AMAOUNT * systemPercentage) / 100;
        //                 const totalAmount = AMAOUNT + modifyAmount + systemAmount;
        //                 const totalParcentage = modifyPercentage + systemPercentage
        //                 setModifyCharges((pre) => ({
        //                     ...pre,
        //                     modifyPercentage: modifyPercentage,
        //                     systemPercentage: systemPercentage,
        //                     modifyAmount: modifyAmount?.toFixed(2),
        //                     systemAmount: systemAmount?.toFixed(2),
        //                     total: totalAmount?.toFixed(2),
        //                     amount: AMAOUNT?.toFixed(2),
        //                     totalParcentage: totalParcentage?.toFixed(2)
        //                 }))
        //
        //                 // modify charge 5%
        //                 // system charge 2%
        //             }
        //         } else {
        //             const modifyPercentage = 0;
        //             const systemPercentage = 0;
        //             const modifyAmount = (AMAOUNT * modifyPercentage) / 100;
        //             const systemAmount = (AMAOUNT * systemPercentage) / 100;
        //             const totalAmount = AMAOUNT + modifyAmount + systemAmount;
        //             const totalParcentage = modifyPercentage + systemPercentage
        //
        //             setModifyCharges((pre) => ({
        //                 ...pre,
        //                 modifyPercentage: modifyPercentage,
        //                 systemPercentage: systemPercentage,
        //                 modifyAmount: modifyAmount?.toFixed(2),
        //                 systemAmount: systemAmount?.toFixed(2),
        //                 total: totalAmount?.toFixed(2),
        //                 amount: AMAOUNT?.toFixed(2),
        //                 totalParcentage: totalParcentage?.toFixed(2)
        //             }))
        //         }
        //     }
        // }
    };

    // const calculate

    const checkRuls = () => {

        

        if ((myBookingDetails?.bookingDetails?.modified_count ?? 0) >= 2) {
            ///This will  calculate the 2 time modified payment case.
            // Rule_2,
            const payload = {
                ruls: Rules?.Rule_2,
                packagePrice: Number(getOneService()?.session_price),
                previousPrice: Number((myBookingDetails?.bookingDetails?.payment_history_id?.total_amount || myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount) ?? 0),
                totalParticipants: details?.participants,
                previous_participants: myBookingDetails?.bookingDetails?.no_of_participants,
                is_ambassador: loginUserDetails?.is_ambassador,
                systemCharge: calendar?.systemCharge,
                newParticipants: details?.participants - (myBookingDetails?.bookingDetails?.no_of_participants ?? 0),
                tax_rate: fixTax?.tax,
                tax_id: fixTax?.tax_id,
                old_fees_percentage_from_user: (myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user),
                old_fees_amount_from_user: Number((myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user) ?? 0)?.toFixed(2),
                old_sales_tax_percentage: (myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_percentage || myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_percentage) ?? 0,
                old_sales_tax_amount_from_user: Number((myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_amount_from_user) ?? 0)?.toFixed(2),
                totalPaidAmount: Number((myBookingDetails?.bookingDetails?.payment_history_id?.total_paid_amount || myBookingDetails?.bookingDetails?.booking_payment_info?.total_paid_amount) ?? 0)?.toFixed(2)
            }
            priceCalculations(payload, setModifyCharges, setModifyBookingInfo, refCodeData, discountDetails);
        } else {
            const currentTime = new Date();
            const oneHourLater = new Date(currentTime.getTime() + (60 * 60 * 1000)); // Calculate 1 hour later
            if (currentTime < myBookingDetails?.bookingDetails?.start_date_time && myBookingDetails?.bookingDetails?.start_date_time < oneHourLater) {
                ///This will calculate the 1 hour ago payment case.
                // Rule_1,
                const payload = {
                    ruls: Rules?.Rule_1,
                    packagePrice: Number(getOneService()?.session_price),
                    previousPrice: Number((myBookingDetails?.bookingDetails?.payment_history_id?.total_amount || myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount) ?? 0),
                    totalParticipants: details?.participants,
                    previous_participants: myBookingDetails?.bookingDetails?.no_of_participants,
                    is_ambassador: loginUserDetails?.is_ambassador,
                    systemCharge: calendar?.systemCharge,
                    newParticipants: details?.participants - (myBookingDetails?.bookingDetails?.no_of_participants ?? 0),
                    tax_rate: fixTax?.tax,
                    tax_id: fixTax?.tax_id,
                    old_fees_percentage_from_user: (myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user),
                    old_fees_amount_from_user: Number((myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user) ?? 0)?.toFixed(2),
                    old_sales_tax_percentage: (myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_percentage || myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_percentage) ?? 0,
                    old_sales_tax_amount_from_user: Number((myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_amount_from_user) ?? 0)?.toFixed(2),
                    totalPaidAmount: Number((myBookingDetails?.bookingDetails?.payment_history_id?.total_paid_amount || myBookingDetails?.bookingDetails?.booking_payment_info?.total_paid_amount) ?? 0)?.toFixed(2)
                }
                priceCalculations(payload, setModifyCharges, setModifyBookingInfo, refCodeData, discountDetails);
            } else {
                ///This will  calculate the 24 hour payment case.
                // Rule_3,
                const payload = {
                    ruls: Rules?.Rule_3,
                    packagePrice: Number(getOneService()?.session_price),
                    previousPrice: Number((myBookingDetails?.bookingDetails?.payment_history_id?.total_amount || myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount) ?? 0),
                    totalParticipants: details?.participants,
                    previous_participants: myBookingDetails?.bookingDetails?.no_of_participants,
                    is_ambassador: loginUserDetails?.is_ambassador,
                    systemCharge: calendar?.systemCharge,
                    newParticipants: details?.participants - (myBookingDetails?.bookingDetails?.no_of_participants ?? 0),
                    tax_rate: fixTax?.tax,
                    tax_id: fixTax?.tax_id,
                    old_fees_percentage_from_user: (myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user),
                    old_fees_amount_from_user: Number((myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user) ?? 0)?.toFixed(2),
                    old_sales_tax_percentage: (myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_percentage || myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_percentage) ?? 0,
                    old_sales_tax_amount_from_user: Number((myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_amount_from_user) ?? 0)?.toFixed(2),
                    totalPaidAmount: Number((myBookingDetails?.bookingDetails?.payment_history_id?.total_paid_amount || myBookingDetails?.bookingDetails?.booking_payment_info?.total_paid_amount) ?? 0)?.toFixed(2)
                }
                priceCalculations(payload, setModifyCharges, setModifyBookingInfo, refCodeData, discountDetails);
            }
        }
    }


    useEffect(() => {
        if (bookingId) {
            modifyCount()
            checkRuls()
        }
    }, [myBookingDetails?.bookingDetails, getOneService(), details, discountDetails]);

    const handleCheckModifyPayment = () => {
        setBtnLoader(true)
        // const payload = {
        //     modified_booking_id: bookingId,
        //     total_paid_amount: modifyCharges.total
        // }
        // dispatch(checkModifyPayment(payload, () => {
        //     BookSession()
        // }, () => {
        //     setBtnLoader(false)
        // }))

        BookSession()
    }

    const checkRefCode = () => {
        dispatch(getUserRefCode(providerId))
    }

    useEffect(() => {
        checkRefCode()
    }, [providerId]);

    useEffect(() => {
        if (refDescount?.userRefCodeData) {
            get_referral_code_percentage(refDescount?.userRefCodeData, setRefCodeData)
        }
    }, [refDescount?.userRefCodeData]);


    //  ======= Logic End =========


    // .......

    const handleCheckdiscountCode = () => {
        // setLoader(true)
        const payload = {
            discount_code_string: isDiscountCode, // provider_id: userDetails?.details?._id,
            event_id: event?.event?._id, provider_id: event?.event?.user_id?._id, is_event: true
        }
        dispatch(checkDiscountCode(payload, () => {}))
    }

    const handleSetDiscount = () => {
        if (discountCode?.checkCodeDetails?.discount_code) {
            setDiscountDetails({
                is_admin_created: discountCode?.checkCodeDetails?.is_admin_created,
                is_expire: false,
                discount_percentage: discountCode?.checkCodeDetails?.discount_percentage,
                discount_code: discountCode?.checkCodeDetails?.discount_code,
                _id: discountCode?.checkCodeDetails?._id,
                is_discount: true
            })
        } else {
            setDiscountDetails({
                is_admin_created: false,
                is_expire: true,
                discount_percentage: 0,
                discount_code: null,
                _id: null,
                is_discount: false
            })
        }

    }

    const clianData = () => {
        setDiscountDetails({
            is_admin_created: false, is_expire: true, discount_percentage: 0, discount_code: null, _id: null,
        })
    }

    useEffect(() => {
        return () => {
            clianData()
            dispatch({
                type: discountConstant.CLEAN_DISCOUNT_CODE, payload: null,
            });
        }
    }, []);

    useEffect(() => {
        handleSetDiscount()
    }, [discountCode?.checkCodeDetails]);

    return (<Box>
        <section className="main-page">
            {!userAuth?.token ? <GuestHeader/> : <Header select={"Home"}/>}
            <div className="main-inner">
                <div className="container">
                    <h3 className="mb-4 t-30-bold">Modify Private Session</h3>
                    {/*..................*/}
                    {!userAuth?.token && <div className="inner-gred  box_shadow mb-5">
                        <p className="m-0 t-24-bold">Basic Account Info </p>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <div className="group-input mt-3">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.25 6C15.25 7.79493 13.7949 9.25 12 9.25V10.75C14.6234 10.75 16.75 8.62335 16.75 6H15.25ZM12 9.25C10.2051 9.25 8.75 7.79493 8.75 6H7.25C7.25 8.62335 9.37665 10.75 12 10.75V9.25ZM8.75 6C8.75 4.20507 10.2051 2.75 12 2.75V1.25C9.37665 1.25 7.25 3.37665 7.25 6H8.75ZM12 2.75C13.7949 2.75 15.25 4.20507 15.25 6H16.75C16.75 3.37665 14.6234 1.25 12 1.25V2.75ZM9 13.75H15V12.25H9V13.75ZM15 20.25H9V21.75H15V20.25ZM9 20.25C7.20507 20.25 5.75 18.7949 5.75 17H4.25C4.25 19.6234 6.37665 21.75 9 21.75V20.25ZM18.25 17C18.25 18.7949 16.7949 20.25 15 20.25V21.75C17.6234 21.75 19.75 19.6234 19.75 17H18.25ZM15 13.75C16.7949 13.75 18.25 15.2051 18.25 17H19.75C19.75 14.3766 17.6234 12.25 15 12.25V13.75ZM9 12.25C6.37665 12.25 4.25 14.3766 4.25 17H5.75C5.75 15.2051 7.20507 13.75 9 13.75V12.25Z"
                                                fill="#363853"
                                            />
                                        </svg>
                                        <input
                                            type="text"
                                            required
                                            placeholder="Full Name"
                                            value={guestUser.name.replace(/\d/g, "")}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 25) {
                                                    setGuestUser((pre) => ({...pre, name: e.target.value}))
                                                }
                                            }}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="group-input mt-3">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="Icons">
                                                <path
                                                    id="Vector"
                                                    d="M2.88539 8.84875C3.55805 6.13983 5.70602 4.04534 8.43056 3.44162L8.88443 3.34105C10.9366 2.88632 13.0634 2.88632 15.1156 3.34105L15.5694 3.44162C18.294 4.04534 20.442 6.13984 21.1146 8.84875C21.6285 10.9182 21.6285 13.0819 21.1146 15.1512C20.442 17.8602 18.294 19.9547 15.5694 20.5584L15.1156 20.659C13.0634 21.1137 10.9366 21.1137 8.88443 20.659L8.43056 20.5584C5.70601 19.9547 3.55805 17.8602 2.88539 15.1513C2.37154 13.0819 2.37154 10.9181 2.88539 8.84875Z"
                                                    stroke="#363853"
                                                    strokeWidth="1.5"
                                                    stroke-linecap="round"
                                                />
                                                <path
                                                    id="Vector_2"
                                                    d="M21.1146 15.1512C21.6285 13.0819 21.6285 10.9182 21.1146 8.84875C20.442 6.13984 18.294 4.04534 15.5694 3.44162L15.1156 3.34105C13.0634 2.88632 10.9366 2.88632 8.88443 3.34105L8.43056 3.44162C5.70602 4.04534 3.55805 6.13983 2.88539 8.84875C2.37154 10.9181 2.37154 13.0819 2.88539 15.1513C3.55805 17.8602 5.70601 19.9547 8.43056 20.5584L8.88443 20.659"
                                                    stroke="#363853"
                                                    strokeWidth="1.5"
                                                    stroke-linecap="round"
                                                />
                                                <path
                                                    id="Vector_3"
                                                    d="M8.15112 10.3501L10.7216 12.1866C11.4864 12.7329 12.5136 12.7329 13.2783 12.1866L15.8489 10.3501"
                                                    stroke="#363853"
                                                    strokeWidth="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </g>
                                        </svg>
                                        <input
                                            type="email"
                                            required
                                            placeholder="Email Address"
                                            value={guestUser.email}
                                            onChange={(e) => {
                                                setGuestUser((pre) => ({
                                                    ...pre, email: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="group-input mt-3">
                                        <PhoneInput
                                            placeholder="Phone Number"
                                            enableSearch={true}
                                            value={`${guestUser.country_code}${guestUser.mobile_number}`}
                                            onChange={(phone, data) => {
                                                setGuestUser((pre) => ({
                                                    ...pre,
                                                    mobile_number: phone.slice(data?.dialCode?.length),
                                                    country_code: data.dialCode,
                                                }));
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>}

                    {/*.............after login..................*/}


                    <div className="inner-gred box_shadow">
                        <p className="m-0 mb-3 t-24-bold">Modify Private Session</p>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>

                                {/*.......profile......*/}
                                <Grid item xs={12}>
                                    <div className="d-flex border-gred">
                                        <div className="inner-gred d-flex w-100 align-items-center">
                                            <div className="p_image_shape me-2 me-sm-3" style={{zIndex: "1"}}>
                                                <img src={userDetails?.details?.profile_picture || userIcon} onError={(e) => (e.target.src = userIcon)} alt="profile"/>
                                            </div>
                                            <div>
                                                <h5 className="mb-2"
                                                    style={{fontWeight: "600"}}>{userDetails?.details?.name}</h5>
                                                <div className="d-flex align-items-center gap-1">
                                                    <img src={loactionIcon} alt=""/>
                                                    <span style={{fontWeight: "600"}}
                                                          className="m-0 font-weight-bold map_des">{userDetails?.details?.address}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>

                                {/*......SELECT-SERVICE......*/}

                                <Grid item xs={12}>
                                    <div className="d-flex border-gred session-select">
                                        <Select
                                            value={details.service}
                                            // defaultValue={providerService()[0]?.label}
                                            placeholder={"select service"}
                                            style={{width: "100%", height: "45px"}}
                                            onChange={(value) => {
                                                handleSelectService()
                                                setDetails((pre) => ({...pre, service: value, participants: 1}))
                                            }}
                                            options={providerService()}
                                            className="serviceSelector"
                                        />
                                    </div>
                                </Grid>

                                {/*    ......details section.........*/}

                                <div className="inner-gred box_shadow">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <div className="border-gred ">
                                                <div className="session-input d-flex">
                                                    <p className="m-0">Participants</p>
                                                    <input type="number" min="1"
                                                           max={getOneService()?.maximum_participants}
                                                           onChange={(e) => {
                                                               if (e.target.value <= getOneService()?.maximum_participants) {
                                                                   setDetails((pre) => ({
                                                                       ...pre, participants: e.target.value
                                                                   }))
                                                               }

                                                           }} value={details.participants} className="showArrow"
                                                           style={{textAlign: "end"}}/>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className="border-gred session-select">
                                                <Select
                                                    defaultValue={details.service_type}
                                                    style={{width: "100%", height: "45px"}}
                                                    onChange={(value) => setDetails((pre) => ({
                                                        ...pre, service_type: value
                                                    }))}
                                                    options={[{
                                                        value: 'In person', label: 'In person'
                                                    }, {value: 'virtual', label: 'virtual'}]}
                                                />
                                            </div>
                                        </Grid>
                                        {details?.service_type == "In person" && <>
                                            <Grid item xs={12} sm={6}>
                                                <div className="border-gred m-0 session-select">
                                                    <Select
                                                        defaultValue={details.location_type}
                                                        style={{width: "100%", height: "45px"}}
                                                        onChange={(value) => setDetails((pre) => ({
                                                            ...pre, location_type: value
                                                        }))}
                                                        options={[{
                                                            value: 'My Place', label: 'My Place'
                                                        }, {value: 'Their Place', label: 'Their Place'}]}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {details.location_type == "My Place" && <div>
                                                    <textarea className="session-address"
                                                              placeholder="Enter your location"
                                                              style={{resize: "none", width: "100%"}}
                                                              value={details.address}
                                                              onClick={() => setIsOpenPlace(true)}
                                                              onChange={(e) => setDetails((pre) => ({
                                                                  ...pre, address: e.target.value
                                                              }))}
                                                              readOnly={true}
                                                    />
                                                </div>}
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{marginTop: '0px !importnt'}}>
                                                {(details.location_type == "My Place" && placeNote) && <p
                                                    style={{
                                                        wordBreak: 'break-word',
                                                        fontSize: '15px',
                                                        fontWeight: '400',
                                                        color: '#656565'
                                                    }}>Note: {placeNote}</p>}
                                            </Grid>
                                        </>}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className="border-gred">
                                    <div className="inner-gred calander-bg" style={{padding: "0"}}>
                                        <div className="calander_contant">
                                            <Calendars
                                                value={mainState.activeDate}
                                                onChange={handleCalendarChange}
                                                calendarClassName="custom-calendar"
                                                calendarTodayClassName="custom-today-day"
                                                calendarSelectedDayClassName="custom-selected-day"
                                                calendarRangeStartClassName={calendarRangeStartClassName}
                                                calendarRangeBetweenClassName={calendarRangeBetweenClassName}
                                                calendarRangeEndClassName={calendarRangeEndClassName}
                                                disabledDays={calendar.bookedDate || disabledDays}
                                                colorPrimary="#ABD5B4"
                                                colorPrimaryLight={colorPrimaryLight}
                                                slideAnimationDuration={slideAnimationDuration}
                                                onDisabledDayError={onDisabledDayError}
                                                minimumDate={utils().getToday()}
                                                maximumDate={maximumDate}
                                                selectorStartingYear={selectorStartingYear}
                                                selectorEndingYear={selectorEndingYear}
                                                locale={locale}
                                                shouldHighlightWeekends={shouldHighlightWeekends}
                                                renderFooter={renderFooter}
                                                customDaysClassName={calendar?.myBookedDate !== null ? [].concat(...calendar?.myBookedDate?.map((e) => (getDates(e?.schedule_start_date, e?.schedule_end_date)))) : customDaysClassName}
                                                mainState={mainState}
                                                setMainState={setMainState}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {(mainState.activeDate && !isSloting.length) ?
                                    <p className="t-red">Not available this day</p> : ""}
                            </Grid>
                        </Grid>

                        {/*...........time slots.............*/}

                        {isSloting.length ? <div className="mt-5">
                            <h3>Select Time Slots </h3>
                            <div className="mt-3">
                                <Grid container spacing={2}>

                                    {isSloting?.map((ele, i) => (<React.Fragment key={i}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <div
                                                className={`inner-gred box_shadow ${selectedSlot == ele?.time && "yellow_border"}`}
                                                style={{cursor: "pointer"}} onClick={() => {
                                                setSelectedSlot(ele?.time)
                                                setSelectedTime(ele?.time)
                                                setSelectedAddress(details?.location_type == "My Place" ? details?.address : (ele?.address || getOneService()?.address));
                                                setSelectedLatlong(details?.location_type == "My Place" ? details?.latLng : (ele?.location || getOneService()?.location))
                                            }}>
                                                <p className="m-0"
                                                   style={{
                                                       fontWeight: "600", fontSize: "15px"
                                                   }}>{ele?.time?.split(" : ")[0]} to {ele?.time?.split(" : ")[1]}</p>
                                                {(ele?.address || getOneService()?.address || details.address) &&
                                                    <div className="d-flex gap-2">
                                                        <img src={loactionIcon} alt="icon"/>
                                                        <span>{details?.location_type == "My Place" ? details?.address : (ele?.address || getOneService()?.address)}</span>
                                                    </div>}
                                            </div>
                                        </Grid>
                                    </React.Fragment>))}
                                </Grid>
                            </div>
                        </div> : null}
                    </div>

                    {/*    ..................Payment Details.................... */}

                    {(selectedTime && isSloting.length) ? <div className="inner-gred mt-4 box_shadow">
                        <h3>Payment Details </h3>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={6} className="mt-3">
                                <div>
                                    <span>Booking Details</span>
                                    <div className="border-gred w-100 mt-2">
                                        <div className="inner-gred w-100">
                                            <div className="d-flex justify-content-between mt-1">
                                                <span className="review-Name">Date</span>
                                                <span
                                                    className="review-Name">{mainState.activeDate && (moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').format('D MMM YYYY') !== "Invalid date" ? moment(`${mainState?.activeDate?.year}-${mainState?.activeDate?.month}-${mainState?.activeDate?.day}`, 'YYYY-MM-DD').format('D MMM YYYY') : `${mainState.activeDate.day}  ${mainState.activeDate.month}  ${mainState.activeDate.year}`)} </span>
                                            </div>
                                            <div className="d-flex justify-content-between mt-1">
                                                <span className="review-Name">Time</span>
                                                <span
                                                    className="review-Name">{selectedTime.split(" : ")[0]} to {selectedTime.split(" : ")[1]}</span>
                                            </div>
                                            <div className="d-flex justify-content-between mt-1">
                                                <span className="review-Name">Type</span>
                                                <span
                                                    className="review-Name">{capital(details.service_type)}</span>
                                            </div>
                                            {details?.location_type.includes("My Place") && <>
                                                <div className="d-flex justify-content-between mt-1">
                                                    <span className="review-Name">Location</span>
                                                    <span className="review-Name">{details.location_type}</span>
                                                </div>
                                                <p className="t-13-500" style={{
                                                    marginLeft: "auto", textAlign: "end"
                                                }}>{details.address}</p>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span>Payment Details </span>
                                    <div className="border-gred w-100 mt-2">
                                        <div className="inner-gred w-100">
                                            <div className="d-flex justify-content-between mt-1">
                                                <span className="review-Name">Participants</span>
                                                <span
                                                    className="review-Name">{modifyBookingInfo.old_participate}</span>
                                            </div>
                                            {/*<div className="d-flex justify-content-between mt-1">*/}
                                            {/*    <span className="review-Name">Amount </span>*/}
                                            {/*    <span*/}
                                            {/*        className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.total_amount || myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount).toFixed(2)}</span>*/}
                                            {/*</div>*/}




                                            {(myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_discount == true && myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.is_admin_discount == false) ?
                                                <div className="d-flex justify-content-between mt-1">
                                                    <div className="d-flex flex-column">
                                                        <span className="review-Name">Amount </span>
                                                        <p className="yelloe-t-bold t-14-500 m-0">You have
                                                            received
                                                            a {myBookingDetails?.bookingDetails?.payment_history_id?.discount_percentage ?? 0}%
                                                            discount for
                                                            using the discount code.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                                    <span
                                                                        style={{
                                                                            color: '#9E9E9E',
                                                                            textDecoration: 'line-through'
                                                                        }}
                                                                        className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.discount_code_info?.amount_without_discount ?? 0).toFixed(2)}</span>
                                                        <span
                                                            className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.total_amount ?? 0).toFixed(2)}</span>
                                                    </div>
                                                </div> :
                                                <div className="d-flex justify-content-between mt-1">
                                                    <span className="review-Name">Amount </span>
                                                    <span
                                                        className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.total_amount).toFixed(2)}</span>
                                                </div>}

                                            {/*{myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.is_refree ?*/}
                                            {/*    <div>*/}
                                            {/*        <div className="d-flex justify-content-between mt-1">*/}
                                            {/*        <span*/}
                                            {/*            className="review-Name">Network fees ( {myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user}% )*/}
                                            {/*        </span>*/}
                                            {/*            <div className="d-flex gap-1">*/}
                                            {/*            <span className="review-Name" style={{*/}
                                            {/*                color: '#9E9E9E',*/}
                                            {/*                textDecoration: 'line-through'*/}
                                            {/*            }}>$ {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_amount}</span>*/}
                                            {/*                <span*/}
                                            {/*                    className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user).toFixed(2)}</span>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <p className="yelloe-t-bold t-14-500 m-0">You have received*/}
                                            {/*            a {myBookingDetails?.bookingDetails?.payment_history_id?.referral_code_info?.display_user_fees_percentage}%*/}
                                            {/*            discount for using the referral code.</p>*/}
                                            {/*    </div> :*/}
                                            {/*    <div className="d-flex justify-content-between mt-1">*/}
                                            {/*    <span*/}
                                            {/*        className="review-Name">Network fees ( {myBookingDetails?.bookingDetails?.payment_history_id?.fees_percentage_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_percentage_from_user}% )</span>*/}
                                            {/*        <span*/}
                                            {/*            className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.fees_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.fees_amount_from_user).toFixed(2)}</span>*/}
                                            {/*    </div>*/}
                                            {/*}*/}

                                            {previousNetworkFees(myBookingDetails)}
                                            <div className="d-flex justify-content-between mt-1">
                                                <span
                                                    className="review-Name">Tax {`( ${myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_percentage || myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_percentage}% )`}</span>
                                                <span
                                                    className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.sales_tax_amount_from_user || myBookingDetails?.bookingDetails?.booking_payment_info?.sales_tax_amount_from_user).toFixed(2)}</span>
                                            </div>
                                            <span
                                                className="review-Name text-dark-gray t-10-500">{loginUserDetails?.is_ambassador ? "( As an ambassador user. you are not liable for any charges. )" : ""}</span>

                                            <hr style={{border: "1px dashed black"}}/>
                                            <div className="d-flex justify-content-between mt-1">
                                                <span className="review-Name">Total Amount</span>
                                                <span
                                                    className="review-Name">$ {Number(myBookingDetails?.bookingDetails?.payment_history_id?.total_paid_amount || myBookingDetails?.bookingDetails?.booking_payment_info?.total_paid_amount).toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>



                            <Grid item xs={12} md={6} className="mt-3">
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={12} className="mt-3">
                                        <div style={{marginTop: '19px'}}>
                                            <div className="border-gred" style={{padding: "2px"}}>
                                                <div className="session-input d-flex"
                                                     style={{padding: '0px 0px 0px 10px', height: '44px'}}>
                                                    <div className="p-1 w-100 d-flex">
                                                        <input type="text"
                                                               disabled={discountDetails?.is_expire == false}
                                                               value={isDiscountCode}
                                                               placeholder="Enter Discount code"
                                                               className="showArrow"
                                                               style={{width: '100%'}}
                                                               onChange={(e) => {
                                                                   setIsDiscountCode(e.target.value)
                                                               }}
                                                        />
                                                    </div>
                                                    {discountDetails?.is_expire == true ? <button
                                                        onClick={() => handleCheckdiscountCode()}
                                                        style={{
                                                            background: "linear-gradient(180deg, #0DBCEC 0%, #FFC056 100%)",
                                                            padding: '8px 26px',
                                                            fontWeight: '700'
                                                        }}>Apply
                                                    </button> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12} className="mt-3">
                                        <span>{modifyBookingInfo?.is_refund ? "Refund Payment Details" : "Modify Payment Details"}</span>
                                        <div className="border-gred w-100 mt-2">
                                            <div className="inner-gred w-100">
                                                <div className="d-flex justify-content-between mt-1">
                                                    <span className="review-Name">Participants</span>
                                                    <span
                                                        className="review-Name">{Math.abs(modifyBookingInfo.new_participate)}</span>
                                                </div>
                                                {/*<div className="d-flex justify-content-between mt-1">*/}
                                                {/*    <span className="review-Name">Amount </span>*/}
                                                {/*    <span*/}
                                                {/*        className="review-Name">$ {(modifyBookingInfo?.new_amount ?? 0).toFixed(2)}</span>*/}
                                                {/*</div>*/}


                                                {(modifyBookingInfo?.is_discount == true && modifyBookingInfo?.is_admin_discount == false) ?
                                                    <div className="d-flex justify-content-between mt-1">
                                                        <div className="d-flex flex-column">
                                                            <span className="review-Name">Amount </span>
                                                            <p className="yelloe-t-bold t-14-500 m-0">You have
                                                                received
                                                                a {discountDetails?.discount_percentage ?? 0}%
                                                                discount for
                                                                using the discount code.</p>
                                                        </div>
                                                        <div className="d-flex gap-2">
                                                                    <span
                                                                        style={{
                                                                            color: '#9E9E9E',
                                                                            textDecoration: 'line-through'
                                                                        }}
                                                                        className="review-Name">$ {Number(modifyBookingInfo?.totalAmount ?? 0).toFixed(2)}</span>
                                                            <span
                                                                className="review-Name">$ {Number(modifyBookingInfo?.new_amount ?? 0).toFixed(2)}</span>
                                                        </div>
                                                    </div> :
                                                    <div className="d-flex justify-content-between mt-1">
                                                        <span className="review-Name">Amount </span>
                                                        <span
                                                            className="review-Name">$ {Number(modifyBookingInfo?.new_amount ?? 0).toFixed(2)}</span>
                                                    </div>}


                                                {/*<div className="d-flex justify-content-between align-items-center mt-1">*/}
                                                {/*    <div>*/}
                                                {/*    <span*/}
                                                {/*        className="review-Name">Network fees {loginUserDetails?.is_ambassador ? "" : `( ${modifyBookingInfo?.fees_percentage_from_user}% )`}</span>*/}
                                                {/*        {refCodeData?.referee?.is_expire ? null :*/}
                                                {/*            <p className="yelloe-t-bold t-14-500 m-0">You have received*/}
                                                {/*                a {refCodeData?.referee?.percentage}%*/}
                                                {/*                discount for using the referral*/}
                                                {/*                code.</p>}*/}
                                                {/*    </div>*/}
                                                {/*    <div className="d-flex gap-2">*/}
                                                {/*        {refCodeData?.referee?.is_expire ? null : <span*/}
                                                {/*            className="review-Name" style={{*/}
                                                {/*            color: '#9E9E9E',*/}
                                                {/*            textDecoration: 'line-through'*/}
                                                {/*        }}>$ {(modifyBookingInfo?.referee_fees_amount_from_user ?? 0).toFixed(2)}</span>}*/}
                                                {/*        <span*/}
                                                {/*            className="review-Name">$ {(modifyBookingInfo?.fees_amount_from_user ?? 0).toFixed(2)}</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                {
                                                    modifyNetworkFees(loginUserDetails, refCodeData, modifyBookingInfo)
                                                }
                                                <div className="d-flex justify-content-between mt-1">
                                                <span
                                                    className="review-Name">Tax {`( ${modifyBookingInfo?.sales_tax_percentage}% )`}</span>
                                                    <span
                                                        className="review-Name">$ {(modifyBookingInfo?.sales_tax_amount_from_user ?? 0).toFixed(2)}</span>
                                                </div>
                                                <span
                                                    className="review-Name text-dark-gray t-10-500">{loginUserDetails?.is_ambassador ? "( As an ambassador user. you are not liable for any charges. )" : ""}</span>

                                                <hr style={{border: "1px dashed black"}}/>
                                                <div className="d-flex justify-content-between mt-1">
                                                    <span className="review-Name">Total Amount</span>
                                                    <span
                                                        className="review-Name">$ {(modifyBookingInfo?.new_total_paid_amount ?? 0).toFixed(2)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </div> : <></>}
                    {!userAuth?.token ? (selectedTime && isSloting.length ?
                        <Box className="d-flex flex-wrap align-items-center mt-3">
                            <button
                                className="btn_White ms-auto"
                                onClick={() => navigate(`/provider_details/${providerId}`)}
                            >
                                Cancel
                            </button>
                            {btnLoader ? (<a
                                href="#"
                                onClick={(e) => e.preventDefault()}
                                className="btn_admina "
                            >
                                {/* save */}
                                <div className="loader"></div>
                            </a>) : (<button
                                className="btn_admina"
                                onClick={() => {
                                    BookSession()
                                }}
                            >
                                Check Out
                            </button>)}
                        </Box> : <></>) : ((selectedTime && isSloting.length) ?
                        <Box className="d-flex flex-wrap align-items-center mt-3">
                            <button
                                className="btn_White ms-auto"
                                onClick={() => navigate(`/provider_details/${providerId}`)}
                            >
                                Cancel
                            </button>
                            {btnLoader ? (<a
                                href="#"
                                onClick={(e) => e.preventDefault()}
                                className="btn_admina"
                            >
                                {/* save */}
                                <div className="loader"></div>
                            </a>) : (<button
                                className="btn_admina"
                                onClick={() => {
                                    handleCheckModifyPayment()
                                }}
                                // style={{opacity: myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount == (getOneService()?.session_price * details?.participants) ? '0.5' : '1'}}
                                // disabled={myBookingDetails?.bookingDetails?.booking_payment_info?.total_amount == (getOneService()?.session_price * details?.participants) ? true : false}
                            >
                                Modify Booking
                            </button>)}
                        </Box> : <></>)}
                </div>
            </div>
        </section>

        <BookingPopup open={isChecking} setOpen={setIsCheching} modify={true}/>
        <WalletCardPopup id={walletId} anchorEl={walletMenu} setwalletMenu={setwalletMenu}
                         handleClose={handleWalletMenuClose} open={Walletopen}
                         handleDeleteCard={handleDeleteCard}/>
        <PlacePopUp open={isOpenPlace} setOpen={setIsOpenPlace} setDetails={setDetails} setPlaceNote={setPlaceNote}
                    placeNote={placeNote}/>
    </Box>)
}

export default ModifySessionBooking;