import React, { useState, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import profileImg from "../../assets/images/layout1.jpg";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import deleteIcon from "../../assets/images/deleteAccIcon.svg";
import userIcon from "../../assets/images/userIconWithBg.png";

const BookingReminder = ({
  data,
  formatBadge,
  getReviewTime,
  seenNotification,
  handleDeleteNotification,
}) => {
  const [navRout, setNavRout] = useState("/");
  const auth_id = JSON.parse(localStorage.getItem("AdAnima_auth"))?._id;
  const navigate = useNavigate();
  const [message, setmessage] = useState("");
  const [eventImage, seteventImage] = useState("");
  const setNavigateRout = () => {
    if (data?.booking_reminder_for == "event") {
        navigate(`/view-events/${data?.is_for_provider ? data?.booking_reminder_event_id?._id : data?.booking_reminder_id?.event_id?._id}/${data?.is_for_provider ? data?.booking_reminder_event_id?.user_id : data?.booking_reminder_id?.provider_id?._id}`);
    
    } else{
      // history
      navigate(`/provider_details/${data?.booking_reminder_id?.provider_id?._id}`)
    } 
  };
  console.log(data, "data")

  const handleNavigate = (event, status) => {
    const clickedElement = event.target;
    if (clickedElement.classList.contains("btnn")) {
      setNavigateRout()
      return;
    } else {
      seenNotification();
        setNavigateRout();
    }
  };

  useEffect(() => {
    let NewMessage = data?.noti_msg;

    if (
      data?.booking_reminder_event_id ||
      data?.booking_reminder_id?.booking_type == "event"
    ) {
      if (data?.is_for_provider) {
        if (data?.is_event_virtual) {
          NewMessage = NewMessage?.replace(
            "event_name",
            data?.booking_reminder_event_id?.event_name
          );

          NewMessage = NewMessage?.replace(
            "event_join_link",
            data?.booking_reminder_event_id?.event_join_link
          );

          seteventImage(
            data?.booking_reminder_event_id?.event_media[0]?.file_name
          );
        } else {
          NewMessage = NewMessage?.replace(
            "event_name",
            data?.booking_reminder_event_id?.event_name
          );
          seteventImage(
            data?.booking_reminder_event_id?.event_media[0]?.file_name
          );
        }
      } else {
        if (data?.is_event_virtual) {
          NewMessage = NewMessage?.replace(
            "event_name",
            data?.booking_reminder_id?.event_id?.event_name
          );

          NewMessage = NewMessage?.replace(
            "event_join_link",
            data?.booking_reminder_id?.event_id?.event_join_link
          );

          seteventImage(
            data?.booking_reminder_id?.event_id?.event_media[0]?.file_name
          );
        } else {
          NewMessage = NewMessage?.replace(
            "event_name",
            data?.booking_reminder_id?.event_id?.event_name
          );

          seteventImage(
            data?.booking_reminder_id?.event_id?.event_media[0]?.file_name
          );
        }
      }
    } else {
      if (data?.is_for_provider) {
        if (data?.is_service_virtual) {
          NewMessage = NewMessage?.replace(
            " name ",
            ` ${data?.booking_reminder_id?.user_id?.name} `
          );

          NewMessage = NewMessage?.replace(
            "service_name",
            data?.booking_reminder_id?.service_id?.service_name
          );

          NewMessage = NewMessage?.replace(
            "link",
            data?.booking_reminder_id?.service_id?.link
          );

          seteventImage(
            data?.booking_reminder_id?.provider_id?.provider_banner_images[0]
              ?.file_name
          );
        } else {
          NewMessage = NewMessage?.replace(
            " name ",
            ` ${data?.booking_reminder_id?.user_id?.name} `
          );

          NewMessage = NewMessage?.replace(
            "service_name",
            data?.booking_reminder_id?.service_id?.service_name
          );
          seteventImage(
            data?.booking_reminder_id?.provider_id?.provider_banner_images[0]
              ?.file_name
          );
        }
      } else {
        if (data?.is_service_virtual) {
          NewMessage = NewMessage?.replace(
            " name ",
            ` ${data?.booking_reminder_id?.provider_id?.name} `
          );

          NewMessage = NewMessage?.replace(
            "service_name",
            data?.booking_reminder_id?.service_id?.service_name
          );

          NewMessage = NewMessage?.replace(
            "link",
            data?.booking_reminder_id?.service_id?.link
          );

          seteventImage(
            data?.booking_reminder_id?.provider_id?.provider_banner_images[0]
              ?.file_name
          );
        } else {
          NewMessage = NewMessage?.replace(
            " name ",
            ` ${data?.booking_reminder_id?.provider_id?.name} `
          );

          NewMessage = NewMessage?.replace(
            "service_name",
            data?.booking_reminder_id?.service_id?.service_name
          );

          seteventImage(
            data?.booking_reminder_id?.provider_id?.provider_banner_images[0]
              ?.file_name
          );
        }
      }
    }

    setmessage(NewMessage);
  }, [data]);

  var urlRegex = /(https?:\/\/[^\s]+)/g;

  var newDes = message?.split(urlRegex);

  return (
    <>
      <div
        style={{ background: data?.is_seen ? "white" : "#E6F7FF" }}
        onClick={(e) => {
          handleNavigate(e);
        }}
      >
        <div className="notification-main-div position-relative d-flex align-items-start justify-content-between p-2">
          <div className="d-flex align-items-center w-100">
            <FiberManualRecordIcon
              style={{
                width: "13px",
                color: data?.is_seen ? "transparent" : "#60CE74",
              }}
            />
            <div
              className="p_image_shape me-2 me-sm-0"
              style={{ transform: "scale(0.6)", marginLeft: "-15px" }}
            >
              <img
                src={eventImage}
                onError={(e) => (e.target.src = userIcon)}
              />
            </div>
            <div className="w-100">
              <p
                className="mb-0 t-13-800 mt-0"
                style={{ wordBreak: "break-word" }}
              >
                {/* {data?.noti_msg
                  .replace("user_name", data?.sender_user?.name)
                  ?.replace("login_", "")
                  .replace("service_name", data?.service_name ?? "")
                  ?.replace("event_name", data?.event_name ?? "")
                  .replace("provider_name", data?.provider_name ?? "")} */}

                {/* {message} */}
                {newDes?.map((des) => {
                  if (des.match(urlRegex)) {
                    return (
                      <a
                        target="_blank"
                        style={{ color: "#129df0" }}
                        href={des}
                      >
                        Link
                      </a>
                    );
                  } else {
                    return des;
                  }
                })}
              </p>
              <div className="d-flex justify-content-between w-100">
                <div>
                  <p className="mb-0 t-12-bold mt-0" style={{ width: "80%" }}>
                    {data?.sender_user?.name}
                  </p>
                  <div className="d-flex align-items-center g-2">
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("MMMM D YYYY")}
                    </p>
                    <FiberManualRecordIcon
                      style={{ transform: "scale(0.4)", color: "#555555" }}
                    />
                    <p className="mb-0 t-11-500 mt-0">
                      {moment(data?.noti_date).format("LT")}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="t-10-bold m-0"
                    style={{
                      color: "#2F8A40",
                      textAlign: "end",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {getReviewTime(data?.createdAt)}
                  </p>
                  <p
                    className="t-11-bold mt-1 d-flex align-items-center justify-content-center m-0"
                    style={{
                      borderRadius: "3px",
                      background: "#00ADFF",
                      padding: "2px 4px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* {formatBadge(
                data?.noti_for,
                data?.booking_type == "service" ? false : true
              )} */}
                   Reminder
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="position-absolute delete_notification"
            style={{ left: "0", top: "0" }}
          >
            <button className="btnn">
              <Tooltip placement="top" title={"Clear"}>
                <IconButton
                  style={{ padding: "3px 5px" }}
                  className="btnn"
                  onClick={() => {
                    handleDeleteNotification();
                  }}
                >
                  {/*<CloseIcon style={{width: '15px'}}/>*/}
                  <img
                    src={deleteIcon}
                    alt=""
                    className="btnn"
                    style={{ width: "24px" }}
                  />
                </IconButton>
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingReminder;
