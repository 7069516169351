import React, {useEffect, useState} from 'react';
import image from "../assets/images/event.png";
import {Modal} from "react-bootstrap";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from "@mui/material";

const PlacePopUp = ({open, setOpen, setDetails, value, setPlaceNote, placeNote, showNote=true}) => {
    const [location, setlocation] = useState(value || "");
    const [center, setCenter] = useState();
    const [firstTime, setfirstTime] = useState(true);
    // useEffect(() => {
    //     setDetails((pre) => ({...pre, latlong: center}))
    // }, [center]);


    return (<>
        <Modal
            centered
            show={open}
            backdrop="static"
            keyboard={false}
            onHide={() => {
                setOpen(false);
            }}
            dialogClassName="delete-modal"
        >
            <div className="Congratulations_msg">
                <div style={{padding: "10px"}}>
                    <div>
                        <h5 className="mt-2">Add Your Location</h5>
                    </div>
                    <div style={{
                        // display: "flex",
                        // flexDirection: "column",
                        // justifyContent: "space-between",
                        // height: "100%",
                        // maxHeight: "255px"
                        overflow: "hidden"
                    }}>
                        <div>
                            <div className="group-input mt-2">
                                <PlacesAutocomplete
                                    value={location}
                                    onChange={(e) => {
                                        setlocation(e);

                                        if (e == "") {
                                            if ("geolocation" in navigator) {
                                                //check geolocation available
                                                //try to get user current location using getCurrentPosition() method
                                                navigator?.geolocation?.getCurrentPosition(function (position) {
                                                    // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
                                                    setCenter({
                                                        lat: position?.coords?.latitude,
                                                        lng: position?.coords?.longitude,
                                                    });
                                                });
                                            } else {
                                                console.log("Browser doesn't support geolocation!");
                                            }
                                        }
                                    }}
                                    onSelect={(address) => {
                                        geocodeByAddress(address)
                                            .then((results) => getLatLng(results[0]))
                                            .then((latLng) => {
                                                setlocation(address);
                                                setCenter({
                                                    lat: latLng.lat, lng: latLng.lng,
                                                });
                                                setfirstTime(false);
                                            })
                                            .catch((error) => console.error("Error", error));
                                    }}
                                >
                                    {({
                                          getInputProps, suggestions, getSuggestionItemProps, loading,
                                      }) => (<>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M20 11.1755C20 15.6907 16.4183 21 12 21C7.58172 21 4 15.6907 4 11.1755C4 6.66029 7.58172 3 12 3C16.4183 3 20 6.66029 20 11.1755Z"
                                                stroke="#363853"
                                                strokeWidth="1.5"
                                            />
                                            <path
                                                d="M9.5 10.5C9.5 9.11929 10.6193 8 12 8C13.3807 8 14.5 9.11929 14.5 10.5C14.5 11.8807 13.3807 13 12 13C10.6193 13 9.5 11.8807 9.5 10.5Z"
                                                stroke="#363853"
                                                strokeWidth="1.5"
                                            />
                                        </svg>
                                        <input
                                            type="text"
                                            required
                                            {...getInputProps({
                                                placeholder: "Search Places ...",
                                                className: "location-search-input",
                                            })}
                                            className="ps-5"
                                            placeholder="Search Place Here..."
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                                // inline style for demonstration purpose
                                                const style = suggestion.active ? {
                                                    backgroundColor: "#fafafa", cursor: "pointer",
                                                } : {
                                                    backgroundColor: "#ffffff", cursor: "pointer",
                                                };
                                                return (<div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className, style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>);
                                            })}
                                        </div>
                                    </>)}
                                </PlacesAutocomplete>
                            </div>
                        </div>

                        {showNote ? <div className="mt-3">
                                 <textarea className="session-address"
                                           placeholder="Enter a note"
                                           style={{resize: "none", width: "100%"}}
                                           onChange={(e) => {
                                               if (e.target.value.length < 231) {
                                                   setPlaceNote(e.target.value)
                                               }
                                           }}
                                           value={placeNote}
                                     // onClick={() => setIsOpenPlace(true)}
                                     // onChange={(e) => setDetails((pre) => ({
                                     //       ...pre, address: e.target.value
                                     //       }))}
                                     //       readOnly={true}
                                 />
                            <p className="t-10-bold text-black text-end">{placeNote.length} / 230</p>
                        </div> : null}

                        <div className="w-100 d-flex mt-3">
                            <div className="col-6">
                                <button type="submit"
                                        style={{lineHeight: '23px'}}
                                        className="btn_admina delete-button"
                                    // style={{lineHeight: "2", backgroundSize: "contain"}}
                                        onClick={() => {
                                            setOpen(false)
                                            setPlaceNote("")
                                        }}>
                                    Cancel
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    type="submit"
                                    style={{lineHeight: '23px'}}
                                    className="btn_admina delete-confirm-button"
                                    onClick={() => {
                                        setOpen(false)
                                        setDetails((pre) => ({
                                            ...pre, address: location || value, latlong: center
                                        }))
                                    }}

                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>)
}

export default PlacePopUp;