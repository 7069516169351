import React, { useEffect, useState } from "react";
import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";
import { toast } from "react-toastify";
import Header from "../Component/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getGuestOtherProvider,
  getOtherProvider,
} from "../action/otherProviderConstant";
import { saveService } from "../action/userServiceConstant";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import MapDiscoveryIcon from "../assets/images/mapDiscovery.svg";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { CustomLeftArrow, CustomRightArrow } from "../Component/CarouselArrows";
import ReviewPopUp from "../Component/ReviewPopUp";
import ServicePopUP from "../Component/ServicePopUP";
import moment from "moment";
import noImg from "../assets/images/noImg.jpg";
import AddEventPopup from "../Component/AddEventPopup";
import userIcon from "../assets/images/userIconWithBg.png";
import { otherProviderConstant } from "../action/constant";
import ImageLayouts from "../Component/ImageLayouts/ImageLayouts";
import UserFoundPopup from "../Component/UserFoundPopup";
import Loader from "../Component/Loader";
import ViewReviewPopup from "../Component/ViewReviewPopup";
import EmptyFile from "../Component/EmptyFile";
import { Empty } from "antd";
import savIcon from "../assets/images/save.svg";
import savedIcon from "../assets/images/saveIcon.svg";
import shareIocn from "../assets/images/shareIcon.svg";
import { IconButton } from "@mui/material";
import LoginPopup from "../Component/LoginPopup";
import GuestHeader from "../Component/GuestHeader";
import EventSharePopup from "../Component/EventSharePopup";
import ImageModle from "../Component/ImageModle";
import logo from "../assets/images/ad-anima_logo.png";
import EventTimePopup from "../Component/EventTimePopup";
import Claim_Account_popup from "../Component/Claim_Account_popup";
import Claim_book_request from "../Component/Claim_book_request";
import axios from "axios";
import {
  viewClaimProfile,
  viewGuestClaimProfile,
} from "../action/claimConstant";
import imgLoader from "../assets/images/imgLoader.gif";
import imgErrorIcon from "../assets/images/alartIcon.jpg";
import verifyImage from "../assets/images/check.svg";
import Chatbot from "../Component/Question/Chatbot";
import SliderPopup from "../Component/SliderPopup";
import { PayBooking } from "../action/paymentConstant";
import { ENVIROMENT } from "../Configration/configration";

const reviewResponsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
};
const secoundResponsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
};

const ProviderDetailsPage = () => {
  const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
  const navigate = useNavigate();
  const [center, setCenter] = useState();
  let { providerId, serviceId, reviewOpen, userId } = useParams();
  const [isPopUp, setPopUp] = useState({
    review: reviewOpen ? true : false,
    service: false,
    event: false,
  });
  const [openReview, setOpenReview] = useState(false);
  const [selectedReview, setSelectedReview] = useState("");
  const [popUpData, setPopUpData] = useState({});
  const [openQuestion, setOpenQuestion] = useState(false);

  const [foundMassage, setFoundMassage] = useState("");
  const [userFound, setUserFound] = useState(false);
  const [isShare, setShare] = useState(false);
  const [sharePath, setSharePath] = useState("");
  const [isClaimPopup, setIsClaimPopup] = useState({
    claimAccount: "",
    requestTobook: "",
  });

  const [userService, setuserService] = useState();
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imageNotFound, setImageNotFound] = useState(false);
  const [openimg, setOpenimg] = React.useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const handleOpenimg = (url) => {
    setImageUrl(url);
    setOpenimg(true);
  };
  const handleCloseimg = () => setOpenimg(false);

  const getservice = async () => {
    const fdata = new FormData();
    fdata.append("user_id", userAuth._id);

    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.get_user_service}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userAuth.token}`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setuserService(data.data);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [loader, setloader] = useState(true);

  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.otherProviderDetails?.data);

  useEffect(() => {
    if (userDetails?.details?.user_type == "provider") {
      setloader(false);
    }

    if (userDetails?.details?.user_type == "user") {
      setloader(false);
    }
  }, [userDetails]);

  useEffect(() => {
    if (!userAuth?.token) {
      dispatch(
        getGuestOtherProvider(providerId, center, (a) => {
          setFoundMassage(a);
        })
      );
    } else if (userAuth?._id) {
      dispatch(
        getOtherProvider(providerId, center, (a) => {
          setFoundMassage(a);
        })
      );
      getservice();
    }

    return () => {
      dispatch({
        type: otherProviderConstant.REMOVE_OTHER_PROVIDER_DETAILS,
        payload: null,
      });
    };
  }, [center]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      //check geolocation available
      //try to get user current location using getCurrentPosition() method
      navigator?.geolocation?.getCurrentPosition(async (position) => {
        // console.log("Found your location \nLat : " + position?.coords?.latitude + " \nLang :" + position?.coords?.longitude);
        await setCenter({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    } else {
      console.log("Browser doesn't support geolocation!");
    }
  }, []);

  useEffect(() => {
    if (!userAuth?.token) {
      dispatch(
        viewGuestClaimProfile({
          id: providerId,
          status: true,
        })
      );
    } else {
      dispatch(
        viewClaimProfile({
          id: providerId,
          status: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (foundMassage == "Couldn't found user") {
      setUserFound(true);
    } else {
      setUserFound(false);
    }
  }, [foundMassage]);

  const getReviewTime = (time) => {
    const getTime = moment(time).local().startOf("seconds").fromNow();

    if (getTime.includes("a few seconds ago")) {
      return "Just Now";
    } else {
      return getTime;
    }
  };

  const openServicePopup = () => {
    setPopUp((pre) => ({ ...pre, service: true }));
  };

  const handleShare = (event) => {
    event.stopPropagation();
    setShare(true);
  };

  // ========== IMAGE AI ============

  const [selectedKeyword, setSelectedKeyword] = useState();
  const [imageResults, setImageResults] = useState([]);

  const fetchImages = async (keyword) => {
    const IMAGEARRAY = [];
    try {
      const response = await axios.get(
        "https://api.unsplash.com/search/photos",
        {
          params: {
            query: keyword,
            per_page: 7, // Number of images to retrieve
            client_id: "b1bj8OUuRmvlsEg3lveSzzZaaWKwJqrRISU_Q9dZe-E", // Replace with your Unsplash API key
          },
        }
      );
      response.data.results?.map((ele, i) => {
        IMAGEARRAY.push({
          file_name: ele?.links?.download,
          _id: i,
        });
      });
      setImageResults(IMAGEARRAY);
    } catch (error) {
      console.error("Error fetching images:", error);
    }

    // const apiKey = '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';
    // const data = {
    //     prompt: 'A description or prompt for the image generation',
    //     // Add other parameters as needed according to OpenAI API documentation
    // };
    //
    //
    //
    // try {
    //     const response = await axios.get('https://api.openai.com/v1/engines/davinci/images', {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${apiKey}`,
    //         },
    //         body: JSON.stringify({ prompt }),
    //     });
    //     response.data.results?.map((ele, i) => {
    //         IMAGEARRAY.push({
    //             file_name: ele?.links?.download,
    //             _id: i
    //         });
    //     })
    //     setImageResults(IMAGEARRAY);
    // } catch (error) {
    //     console.error('Error fetching images:', error);
    // }
  };

  // Handle changes in the selected keyword
  useEffect(() => {
    if (selectedKeyword) {
      fetchImages(selectedKeyword);
    }
  }, [selectedKeyword]);

  useEffect(() => {
    if (userDetails?.details?.provider_services?.length) {
      setSelectedKeyword(
        userDetails?.details?.provider_services[0]?.description_name
      );
    }
  }, [userDetails]);

  let authToken;
  if (ENVIROMENT == "LIVE") {
    authToken =
      "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";
  } else {
    authToken =
      "sk_test_51Ng8S1CutznZ678nVvKamQkm2jhrPyeRgwZGDY2Uz0vlREZstr3OhzY5mRm0gajjLzRlWtnKnAsaOcSfG0jIvnzd009dUu3LNC";
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = {
      payment_intent: urlParams.get("payment_intent"),
      payment_intent_client_secret: urlParams.get(
        "payment_intent_client_secret"
      ),
    };

    const ServiceBookingData = JSON.parse(
      localStorage.getItem("ServiceBookingData")
    );

    if (
      ServiceBookingData &&
      myParam?.payment_intent &&
      myParam?.payment_intent_client_secret
    ) {
      fetch(
        `https://api.stripe.com/v1/payment_intents/${myParam?.payment_intent}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((res) => res.json())
        .then((intentData) => {
          if (ServiceBookingData) {
            let payload = {
              ...ServiceBookingData,
              stripe_charge_id: intentData?.latest_charge,
              stripe_payment_intent_id: intentData?.id,
            };
            dispatch(
              PayBooking(payload, (a) => {
                localStorage.removeItem("ServiceBookingData");
              })
            );
          }
        });
    }
  }, []);
  return (
    <>
      {loader && (
        <>
          <Loader />
        </>
      )}

      <section className="main-page">
        {!userAuth?.token ? <GuestHeader /> : <Header select={"Home"} />}

        <div className="main-inner">
          <div className="container">
            {!userAuth?.token
              ? !userDetails?.details?.is_admin_created
                ? null
                : !userDetails?.details?.is_already_claimed && (
                    <div className="d-flex mb-2">
                      <p
                        className="t-16-bold p-2"
                        style={{ background: "rgba(255, 165, 0, 0.50)" }}
                      >
                        THIS ACCOUNT HAS NOT YET BEEN CLAIMED, THANK YOU FOR
                        YOUR PATIENCE.IF IT IS YOURS CLAIM IT NOW.
                      </p>
                    </div>
                  )
              : !userDetails?.details?.is_admin_created
              ? null
              : !userDetails?.details?.is_already_claimed && (
                  <div className="d-flex mb-2">
                    <p
                      className="t-16-bold p-2"
                      style={{ background: "rgba(255, 165, 0, 0.50)" }}
                    >
                      THIS ACCOUNT HAS NOT YET BEEN CLAIMED, THANK YOU FOR YOUR
                      PATIENCE.
                    </p>
                  </div>
                )}
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-6">
                <div className="col-12 col-lg-12 col-xl-12">
                  <div className="border-gred">
                    <div className="inner-gred d-flex align-items-center">
                      <div
                        className="p_image_shape me-2 me-sm-3"
                        onClick={() =>
                          handleOpenimg(userDetails?.details?.profile_picture)
                        }
                      >
                        {userDetails?.details?.is_verified && (
                          <>
                            <img
                              src={verifyImage}
                              className="blue_check blue-check-event"
                            />
                          </>
                        )}

                        {imageNotFound ? (
                          <img src={imgErrorIcon} />
                        ) : (
                          <img
                            src={
                              imgLoaded
                                ? userDetails?.details?.is_admin_created ||
                                  userDetails?.details?.is_already_claimed
                                  ? userDetails?.details?.profile_picture
                                    ? userDetails?.details?.profile_picture
                                    : logo
                                  : userDetails?.details?.profile_picture
                                : imgLoader
                            }
                            onLoad={() => setImgLoaded(true)}
                            // onError={(e) => {
                            //   e.target.src = noImg;
                            // }}
                            onError={() => setImageNotFound(true)}
                          />
                        )}
                      </div>
                      <div className="w-100">
                        <div className="d-flex justify-content-between">
                          <h4 className="mb-1" style={{ fontSize: "25px" }}>
                            {userDetails?.details?.name}
                          </h4>
                          <div>
                            <IconButton
                              onClick={(e) => {
                                setSharePath("provider_details");
                                handleShare(e);
                              }}
                            >
                              <img
                                src={shareIocn}
                                alt=""
                                style={{ transform: "scale(1.8)" }}
                              />
                            </IconButton>
                            {/*<IconButton*/}
                            {/*    // onClick={() => {*/}
                            {/*    //     if (userDetails?.details?.is_save) {*/}
                            {/*    //         saveProvider(userDetails?.details?._id, false);*/}
                            {/*    //     } else {*/}
                            {/*    //         saveProvider(userDetails?.details?._id, true);*/}
                            {/*    //     }*/}
                            {/*    // }}*/}
                            {/*>*/}
                            {/*    <img src={savIcon} alt=""/>*/}
                            {/*</IconButton>*/}
                          </div>
                        </div>
                        <p className="mb-1" style={{ fontSize: "15px" }}>
                          {userDetails?.details?.is_ambassador && (
                            <img
                              src="/images/ambassador.svg"
                              className="mb-1"
                              style={{ height: "16px", marginLeft: "-5px" }}
                            />
                          )}{" "}
                          Provider
                        </p>
                        <div className="d-block d-sm-flex justify-content-between align-items-center mt-1">
                          <div className="d-flex align-items-end">
                            <div className="me-2 me-sm-2">
                              <img
                                src={MapDiscoveryIcon}
                                style={{ width: "18px" }}
                              />
                            </div>
                            <p
                              className="map_des m-0 p-0"
                              style={{
                                fontSize: "14px",
                                color: "#FFC107",
                              }}
                            >
                              {userDetails?.details?.miles_distance} Mile Away
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <div style={{ color: "#FFC107" }}>
                              {userDetails?.details?.average_rating >= 0.5 ? (
                                <StarIcon />
                              ) : (
                                <StarBorderIcon />
                              )}
                              {userDetails?.details?.average_rating >= 1.5 ? (
                                <StarIcon />
                              ) : (
                                <StarBorderIcon />
                              )}
                              {userDetails?.details?.average_rating >= 2.5 ? (
                                <StarIcon />
                              ) : (
                                <StarBorderIcon />
                              )}
                              {userDetails?.details?.average_rating >= 3.5 ? (
                                <StarIcon />
                              ) : (
                                <StarBorderIcon />
                              )}
                              {userDetails?.details?.average_rating >= 4.5 ? (
                                <StarIcon />
                              ) : (
                                <StarBorderIcon />
                              )}
                            </div>
                            <p className="m-0">{`( ${
                              Math.round(
                                userDetails?.details?.average_rating
                              ) || 0
                            } / 5 )`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12 col-xl-12">
                  <div className="border-gred">
                    <div className="inner-gred">
                      <h4 className="dec_title">About</h4>
                      <p
                        className="about-desc"
                        style={{ wordBreak: "break-word" }}
                      >
                        {userDetails?.details?.provider_introduction?.trim()}
                      </p>
                    </div>
                  </div>
                  {userDetails?.details?.is_admin_created &&
                  !userDetails?.details?.is_already_claimed ? null : (
                    <div className="border-gred">
                      <div className="inner-gred">
                        <h4 className="dec_title d-flex justify-content-between">
                          Reviews{" "}
                          {JSON.parse(localStorage.getItem("AdAnima_auth"))
                            ?._id !== providerId && (
                            <p
                              className="map_des m-0 p-0"
                              style={{
                                fontSize: "14px",
                                color: "#FFC107",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setPopUp((pre) => ({ ...pre, review: true }))
                              }
                            >
                              Add Reviews
                            </p>
                          )}
                        </h4>
                        <div className="w-100 providerDitails">
                          {!userDetails?.retingList?.length && (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={<span>No Reviews Found</span>}
                            />
                          )}
                          {userDetails?.retingList?.length > 0 && (
                            <Carousel
                              responsive={reviewResponsive}
                              // autoPlay={true}
                              // autoPlaySpeed={3000}
                              infinite={true}
                              customLeftArrow={<CustomLeftArrow />}
                              customRightArrow={<CustomRightArrow />}
                              removeArrowOnDeviceType={["tablet", "mobile"]}
                            >
                              {userDetails?.retingList?.map((ele) => (
                                <div className="p-0 px-lg-5">
                                  <div className="d-flex mt-4">
                                    <div className="review_shape me-2 me-sm-3">
                                      <img
                                        src={`${
                                          ele?.user_details?.profile_picture ||
                                          userIcon
                                        }`}
                                        onError={(e) =>
                                          (e.target.src = userIcon)
                                        }
                                      />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center text-gray">
                                        <span className="review-Name">
                                          {ele?.user_details?.name}
                                        </span>
                                        <FiberManualRecordIcon
                                          style={{
                                            transform: "scale(0.4)",
                                            margin: "0",
                                          }}
                                        />
                                        <span className="review-Name text-gray">
                                          {/*{moment(ele?.updatedAt)*/}
                                          {/*    .local()*/}
                                          {/*    .startOf("seconds")*/}
                                          {/*    .fromNow()}*/}
                                          {getReviewTime(ele?.updatedAt)}
                                        </span>
                                      </div>
                                      <div style={{ color: "#FFC107" }}>
                                        {ele?.rating >= 0.5 ? (
                                          <StarIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        ) : (
                                          <StarBorderIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        )}
                                        {ele?.rating >= 1.5 ? (
                                          <StarIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        ) : (
                                          <StarBorderIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        )}
                                        {ele?.rating >= 2.5 ? (
                                          <StarIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        ) : (
                                          <StarBorderIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        )}
                                        {ele?.rating >= 3.5 ? (
                                          <StarIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        ) : (
                                          <StarBorderIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        )}
                                        {ele?.rating >= 4.5 ? (
                                          <StarIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        ) : (
                                          <StarBorderIcon
                                            style={{
                                              transform: "scale(0.8)",
                                              margin: "0",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <p
                                    className="mt-3 selected-item review-text m-0 t-14-bold"
                                    style={{ wordBreak: "break-word" }}
                                  >
                                    {ele?.review}
                                  </p>
                                  {ele?.review?.length > 318 && (
                                    <p
                                      onClick={() => {
                                        setSelectedReview(ele?.review);
                                        setOpenReview(true);
                                      }}
                                      style={{
                                        textAlign: "end",
                                        margin: "0",
                                        color: "#9494c9",
                                        fontSize: "13px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Read More
                                    </p>
                                  )}
                                </div>
                              ))}
                            </Carousel>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                {userDetails?.details?.langauges?.length ? (
                  <div className="col-12 col-lg-12 col-xl-12">
                    <div className="border-gred">
                      <div className="inner-gred d-flex ">
                        <div>
                          <h4 className="mb-1">Languages</h4>
                          <div className="selected_languages">
                            {userDetails?.details?.langauges?.map((data) => {
                              return (
                                <>
                                  <span>{data}</span>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {userDetails?.details?.provider_services?.length ? (
                  <div className="col-12 col-lg-12 col-xl-12">
                    <div className="border-gred">
                      <div className="inner-gred d-flex ">
                        <div>
                          <h4 className="mb-1">Description</h4>
                          <div className="selected_services">
                            {userDetails?.details?.provider_services?.map(
                              (data) => {
                                return (
                                  <>
                                    {data?.description_name == "Other" ? (
                                      <>
                                        {userDetails?.details?.other_desc && (
                                          <span>
                                            {userDetails?.details?.other_desc}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <span>{data?.description_name}</span>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col-12 col-lg-12 col-xl-12 align-self-center text-center">
                  <ImageLayouts
                    userDetails={
                      userDetails?.details?.provider_banner_images?.length > 0
                        ? userDetails?.details?.provider_banner_images
                        : userDetails?.details?.random_images
                    }
                  />
                </div>
              </div>
            </div>

            <div className="">
              {userDetails?.details?.find_user_service_details?.length > 0 && (
                <>
                  <Carousel
                    responsive={secoundResponsive}
                    // autoPlay={true}
                    // autoPlaySpeed={2000}
                    // infinite={true}
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    className={`${
                      userDetails?.details?.find_user_service_details?.length >
                      4
                        ? "justify-content-start"
                        : "justify-content-lg-center"
                    }`}
                  >
                    {userDetails?.details?.find_user_service_details?.map(
                      (data) => {
                        return (
                          <div>
                            <div
                              className="pro_carousel"
                              onClick={() => {
                                openServicePopup();
                                setPopUpData(data);
                              }}
                            >
                              <div className="pro_carousel_content">
                                <span className="pro_carousel_title textElips1">
                                  {data?.service_name}
                                </span>
                                <span className="pro_carousel_price mb-0 mt-1">
                                  $
                                  {Number(data?.session_price ?? 0)?.toFixed(2)}
                                </span>
                                <span className="pro_carousel_time">
                                  {data?.session_minute > 0
                                    ? `${data?.session_hour}:${data?.session_minute}`
                                    : `${data?.session_hour}`}{" "}
                                  Hours
                                </span>
                                <span
                                  className="pro_carousel_desc mt-1 textElips"
                                  style={{
                                    maxHeight: "40px",
                                  }}
                                >
                                  {data?.description}
                                </span>
                                {JSON.parse(
                                  localStorage.getItem("AdAnima_auth")
                                )?._id == providerId && (
                                  <span>
                                    <IconButton
                                      className="mt-2"
                                      onClick={(e) => {
                                        setSharePath("book-session");
                                        handleShare(e);
                                        setPopUpData(data);
                                      }}
                                    >
                                      <img
                                        src={shareIocn}
                                        alt=""
                                        style={{ transform: "scale(1.5)" }}
                                      />
                                    </IconButton>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Carousel>
                </>
              )}
            </div>

            {!userDetails?.details?.is_admin_created ? (
              <div className="d-flex flex-wrap align-items-center justify-content-center">
                <button
                  className="massageBtn position-relative mt-3"
                  style={{
                    maxWidth: "350px",
                    height: "45px",
                  }}
                  onClick={() => navigate(`/upcoming-events/${providerId}`)}
                >
                  Upcoming Event
                  <span
                    className="upComing_count d-flex justify-content-center align-items-center"
                    style={{
                      color: "#FFA500",
                      fontWeight: "600",
                    }}
                  >
                    {userDetails?.total_number_of_data || 0}
                  </span>
                </button>
                {JSON.parse(localStorage.getItem("AdAnima_auth"))?._id !==
                  providerId && (
                  <>
                    {userDetails?.details?.find_user_service_details?.length ? (
                      <button
                        style={{
                          maxWidth: "350px",
                          height: "45px",
                        }}
                        className="massageBtn mt-3"
                        onClick={() => {
                          if (serviceId) {
                            navigate(
                              `/book-session/${providerId}/${serviceId}`
                            );
                          } else {
                            navigate(`/book-session/${providerId}`);
                          }
                        }}
                      >
                        Book Private Session
                      </button>
                    ) : null}
                    <button
                      className="massageBtn mt-3"
                      onClick={() => {
                        if (!userAuth?.token) {
                          navigate("/login");
                        } else {
                          navigate("/comingSoon");
                          // setOpenQuestion(true)
                        }
                      }}
                      style={{
                        maxWidth: "350px",
                        height: "45px",
                      }}
                    >
                      Questions?
                    </button>
                    {userAuth?.user_type !== "user" ? (
                      <button
                        style={{
                          maxWidth: "350px",
                          height: "45px",
                        }}
                        className="massageBtn mt-3"
                        onClick={() => {
                          setPopUp((pre) => ({
                            ...pre,
                            event: true,
                          }));
                          if (!userAuth?.token) {
                            navigate("/login");
                          } else {
                            // navigate('/comingSoon')
                          }
                        }}
                      >
                        Add To My Event
                      </button>
                    ) : null}
                  </>
                )}
              </div>
            ) : (
              !userDetails?.details?.is_already_claimed && (
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  {userAuth?.user_type !== "user" ? (
                    userAuth?.token ? (
                      <button
                        style={{
                          maxWidth: "350px",
                          height: "45px",
                        }}
                        className="massageBtn mt-3"
                        onClick={() => {
                          setPopUp((pre) => ({
                            ...pre,
                            event: true,
                          }));
                          if (!userAuth?.token) {
                            navigate("/login");
                          } else {
                            // navigate('/comingSoon')
                          }
                        }}
                      >
                        Add To My Event
                      </button>
                    ) : (
                      <button
                        style={{
                          maxWidth: "350px",
                          height: "45px",
                        }}
                        className="massageBtn mt-3"
                        onClick={() =>
                          setIsClaimPopup((pre) => ({
                            ...pre,
                            claimAccount: true,
                          }))
                        }
                      >
                        Claim Account
                      </button>
                    )
                  ) : null}
                  <button
                    style={{
                      maxWidth: "350px",
                      height: "45px",
                    }}
                    className="massageBtn mt-3"
                    onClick={() =>
                      setIsClaimPopup((pre) => ({
                        ...pre,
                        requestTobook: true,
                      }))
                    }
                  >
                    Request to Book
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </section>
      {userAuth?.token || userId ? (
        <ReviewPopUp
          open={isPopUp}
          setOpen={setPopUp}
          providerId={providerId}
          center={center}
          userDetails={userDetails}
          userId={userId}
          serviceId={serviceId}
        />
      ) : (
        <LoginPopup open={isPopUp} setOpen={setPopUp} />
      )}
      <ServicePopUP
        open={isPopUp}
        setOpen={setPopUp}
        data={popUpData}
        typeSharing={"booking_type"}
        path={sharePath}
        providerId={providerId}
      />
      {userAuth?.token && (
        <AddEventPopup
          open={isPopUp}
          setOpen={setPopUp}
          userDetails={userDetails}
          isClaim={
            !userDetails?.details?.is_already_claimed &&
            userDetails?.details?.is_admin_created
              ? true
              : false
          }
        />
      )}
      <ViewReviewPopup
        open={openReview}
        setOpen={setOpenReview}
        selectedReview={selectedReview}
        setSelectedReview={setSelectedReview}
        title={"Review"}
      />
      <UserFoundPopup open={userFound} setOpen={setUserFound} />
      <EventSharePopup
        setOpen={setShare}
        open={isShare}
        typeSharing={"service_type"}
        path={sharePath}
        providerId={providerId}
        data={userDetails?.details}
        serviceId={serviceId}
      />
      <ImageModle
        open={openimg}
        handleClose={handleCloseimg}
        imageUrl={imageUrl}
      />
      <Claim_Account_popup
        setOpen={setIsClaimPopup}
        open={isClaimPopup}
        userDetails={userDetails?.details}
      />
      <Claim_book_request
        setOpen={setIsClaimPopup}
        open={isClaimPopup}
        userDetails={userDetails?.details}
        type="provider"
      />
      <Chatbot setOpen={setOpenQuestion} open={openQuestion} />
    </>
  );
};

export default ProviderDetailsPage;
