import { toast } from "react-toastify";
import { APP_VERSION, BASE_URL, URL_PATH } from "../Configration/configration";
import { otherProviderConstant } from "./constant";
import { getEvent } from "./eventConstant";

export const getOtherProvider = (userId, center, setFoundMassage) => {
  return async (dispatch) => {
    let response = {};
    let success = { status: false, message: "" };
    const fdata = new FormData();
    fdata.append("user_id", userId);
    if (center?.lat && center?.lng) {
      fdata.append("lat", center.lat);
      fdata.append("long", center.lng);
    }
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.user_details}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = {
          details: data.data,
          total_number_of_data: data.total_number_of_data,
        };
        success = { status: data?.success, message: data?.message };
      })
      .catch((err) => {
        console.log(err);
      });

    const Providerdata = new FormData();
    Providerdata.append("provider_id", userId);
    if (success?.status) {
      await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.user_rating}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
          }`,
        },
        body: Providerdata,
      })
        .then((response) => response.json())
        .then((data) => {
          response = { ...response, retingList: data.data };
          success = { status: data?.success, message: data?.message };
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (success?.status) {
      dispatch({
        type: otherProviderConstant.GET_OTHER_PROVIDER_DETAILS,
        payload: { data: response, success: success },
      });
    } else {
      if (success?.message == "Couldn't found user") {
        setFoundMassage(success?.message);
      } else {
        toast.error(success?.message);
      }
    }
  };
};

export const getGuestOtherProvider = (userId, center, setFoundMassage) => {
  return async (dispatch) => {
    let response = {};
    let success = { status: false, message: "" };
    const fdata = new FormData();
    fdata.append("user_id", userId);
    if (center?.lat && center?.lng) {
      fdata.append("lat", center.lat);
      fdata.append("long", center.lng);
    }
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_user_details}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = {
          details: data.data,
          total_number_of_data: data.total_number_of_data,
        };
        success = { status: data?.success, message: data?.message };
      })
      .catch((err) => {
        console.log(err);
      });

    const Providerdata = new FormData();
    Providerdata.append("provider_id", userId);
    if (success?.status) {
      await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.guest_user_rating}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
          }`,
        },
        body: Providerdata,
      })
        .then((response) => response.json())
        .then((data) => {
          response = { ...response, retingList: data.data };
          success = { status: data?.success, message: data?.message };
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (success?.status) {
      dispatch({
        type: otherProviderConstant.GET_OTHER_PROVIDER_DETAILS,
        payload: { data: response, success: success },
      });
    } else {
      if (success?.message == "Couldn't found user") {
        setFoundMassage(success?.message);
      } else {
        toast.error(success?.message);
      }
    }
  };
};

export const addRating = (data) => {
  return async (dispatch) => {
    let response;

    const fdata = new FormData();
    if (data?.isEvent) {
      fdata.append("event_id", data?.providerId);
    } else {
      fdata.append("provider_id", data?.providerId);
    }
    fdata.append(
      "user_id",
      data?.userId
        ? data?.userId
        : JSON.parse(localStorage.getItem("AdAnima_auth"))?._id
    );
    fdata.append("rating", data?.rate);
    fdata.append("review", data?.discription);
    await fetch(`${BASE_URL}${APP_VERSION}${URL_PATH.add_rating}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("AdAnima_auth"))?.token
        }`,
      },
      body: fdata,
    })
      .then((response) => response.json())
      .then((data) => {
        response = data;
      })
      .catch((err) => {
        console.log(err);
      });

    if (response?.success) {
      if(data?.isEvent) {
        dispatch(getEvent(data?.providerId, (a) => {}, (a) => {}));
      }else {
        dispatch(getOtherProvider(data?.providerId, data?.center, (a) => {}));
      }
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };
};
