import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Box, Grid } from "@mui/material";
import locationIcon from "../../assets/images/Discovery.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { addMyPageDetails } from "../../action/myPageConstant";
import ProviderCard from "./ProviderCard";
import ParticipantCard from "./ParticipantCard";
import EventRegistrationPopup from "../EventRegistrationPopup";
import {
  cancelRagister,
  deleteEvent,
  getEventById,
  getEventParticipants,
  getEventProviders,
  getMoreEventParticipants,
  getMoreEventProviders,
} from "../../action/myEventConstant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DateFormate,
  LocalFormate,
  timeZoneFormate,
} from "../../Utils/DateFormateUtils";
import moment from "moment/moment";
import { capital } from "../../Utils/capitalUtils";
import CancelEventPopup from "../CancelMyEvent";
import ProviderDetailsScreen from "./ProviderDetailsScreen";
import UserProfile from "./UserProfile";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Loader";
import InstaTempModal from "../InstaTempModal";
// import ProviderDetailsScreen from "./ProviderDetailsScreen";

const EventInfo = () => {
  const [tab, setTab] = useState("Providers");
  const [isRegistration, setIsRegistration] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { myEvent } = useSelector((state) => state);
  const [openUserModal, setOpenUserModal] = useState(false);
  const navigate = useNavigate();
  const [openProviderScreen, setOpenProviderScreen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [openProDetails, setOpenProDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [pageCount, setpageCount] = useState({
    user: 2,
    provider: 2,
  });

  const [instaTempModal, setinstaTempModal] = useState(false);

  const getEventData = () => {
    setLoader(true);
    dispatch(getEventById(id));
  };
  const getEventProviderData = () => {
    const payload = {
      id: id,
      limit: 10,
      page: 1,
    };
    dispatch(getEventProviders(payload));
  };
  const getEventParticipantsData = () => {
    const payload = {
      id: id,
      limit: 10,
      page: 1,
    };
    dispatch(
      getEventParticipants(payload, () => {
        setLoader(false);
      })
    );
  };

  const cancleEvent = () => {
    dispatch(
      deleteEvent(id, () => {
        navigate(`/my_events`);
      })
    );
  };

  const closeRegistration = () => {
    dispatch(cancelRagister(id));
  };

  useEffect(() => {
    if (id) {
      getEventData();
      getEventProviderData();
      getEventParticipantsData();
    }
  }, [id]);

  const fetchmoreProData = () => {
    const payload = {
      id: id,
      limit: 10,
      page: pageCount?.provider,
    };
    dispatch(
      getMoreEventProviders(payload, (a) => {
        // setHasMore(a)
      })
    );
    setpageCount((pre) => ({ ...pre, provider: pre?.provider + 1 }));
  };
  const fetchmoreUserData = () => {
    const payload = {
      id: id,
      limit: 10,
      page: pageCount?.user,
    };
    dispatch(
      getMoreEventParticipants(payload, (a) => {
        // setHasMore(a)
      })
    );
    setpageCount((pre) => ({ ...pre, user: pre?.user + 1 }));
  };

  const scrolldataLegth = () => {
    if (tab == "Providers") {
      return myEvent?.eventProvider?.provider_detail;
    } else if (tab == "Participants") {
      return myEvent?.eventParticipant;
    } else {
      return 0;
    }
  };

  const getMoreData = () => {
    if (tab == "Providers") {
      return fetchmoreProData;
    } else {
      return fetchmoreUserData;
    }
  };

  return (
    <>
      <div>
        <section className="main-page">
          <Header select={"My Events"} />
          <div className="main-inner">
            <div
              className="container position-relative"
              // style={{height: "calc(100vh - 170px)"}}
            >
              <div
                style={{
                  height: "100%",
                  position: "relative",
                }}
              >
                <div className="inner-gred box_shadow">
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                      <p className="t-22-bold mb-2 text-black">
                        {myEvent?.eventData?.event_name}
                      </p>
                      {myEvent?.eventData?.is_virtual_event ? (
                        <p className="t-18-500 m-0">Type: Virtual</p>
                      ) : (
                        <div className="d-flex align-items-center mb-1 gap-1">
                          <img src={locationIcon} alt="" />
                          <p className="t-18-500 m-0">
                            {myEvent?.eventData?.address}
                          </p>
                        </div>
                      )}
                      <div className="d-flex flex-wrap mt-2 align-items-center gap-2">
                        <p className="m-0 text-black t-18-bold">
                          {DateFormate(
                            LocalFormate(
                              myEvent?.eventData?.start_date,
                              myEvent?.eventData?.date_timezone
                            ),
                            LocalFormate(
                              myEvent?.eventData?.end_date,
                              myEvent?.eventData?.date_timezone
                            )
                          )}
                        </p>
                        <FiberManualRecordIcon
                          style={{ transform: "scale(0.5)" }}
                        />
                        <p className="m-0 text-black t-18-bold">
                          {moment(
                            LocalFormate(
                              myEvent?.eventData?.start_time,
                              myEvent?.eventData?.date_timezone
                            )
                          ).format("LT")}{" "}
                          to{" "}
                          {moment(
                            LocalFormate(
                              myEvent?.eventData?.end_time,
                              myEvent?.eventData?.date_timezone
                            )
                          ).format("LT")}{" "}
                          (
                          {timeZoneFormate(
                            myEvent?.eventData?.start_time,
                            myEvent?.eventData?.date_timezone
                          )}
                          )
                        </p>
                        <p className="m-0 text-black t-18-bold ms-sm-2">
                          {capital(myEvent?.eventData?.event_type)} Event
                        </p>
                      </div>
                      {myEvent?.eventData?.discount_code_details && (
                        <p className="t-18-500 m-0 mt-2">
                          You are offering a{" "}
                          <span className="t-18-bold" style={{ color: "#000" }}>
                            {
                              myEvent?.eventData?.discount_code_details
                                ?.discount_percentage
                            }
                            %
                          </span>{" "}
                          discount for this event!
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Box className="d-wrap flex-sm-nowrap flex-lg-wrap justify-content-center justify-content-xl-start align-items-center ">
                        <div className="d-flex">
                          <button
                            className="btn_White mini_white_btn"
                            style={{
                              height: "79px",
                              maxWidth: "210px",
                              backgroundSize: "contain",
                            }}
                            onClick={() => navigate(`/edit-events/${id}`)}
                          >
                            Edit Event
                          </button>
                          <button
                            className="btn_White mini_white_btn"
                            style={{
                              height: "79px",
                              maxWidth: "210px",
                              backgroundSize: "contain",
                            }}
                            onClick={() =>
                              navigate(
                                `/edit-events/${id}?is_duplicate=${true}`
                              )
                            }
                          >
                            Duplicate Event
                          </button>
                        </div>
                        <button
                          className="btn_admina"
                          style={{ maxWidth: "410px" }}
                          onClick={() => setIsCancel(true)}
                        >
                          Cancel Event
                        </button>
                      </Box>
                    </Grid>
                  </Grid>

                  {/*    .....................tabs.....................   */}
                </div>
                <div className="eventTab mt-4">
                  <p
                    className={`${
                      tab == "Providers" ? "tab--Active" : ""
                    } tab-button m-0`}
                    onClick={() => setTab("Providers")}
                  >
                    Providers
                  </p>
                  <p
                    className={`${
                      tab == "Participants" ? "tab--Active" : ""
                    } tab-button m-0`}
                    onClick={() => setTab("Participants")}
                  >
                    Participants
                  </p>
                </div>
                <div className="event-flow">
                  <div
                    style={{
                      height: "calc(-431px + 100vh)",
                      overflow: "auto",
                    }}
                    id="scrollableDiv1"
                    className="notificationScroll"
                  >
                    <InfiniteScroll
                      dataLength={scrolldataLegth() ?? 0}
                      next={getMoreData()}
                      hasMore={hasMore}
                      scrollableTarget="scrollableDiv1"
                      className="infinity-list accordion eventinfi"
                      id={"accordionExample"}
                    >
                      <div style={{ height: "100%" }}>
                        {tab == "Providers" ? (
                          myEvent?.eventProvider?.provider_detail?.length ? (
                            <p className="t-14-bold text-black pb-2 pt-3 m-0">
                              {myEvent?.eventProvider?.confirmed_event ?? 0} Out
                              of{" "}
                              {(
                                myEvent?.eventProvider?.provider_detail
                                  ?.length ?? 0
                              )
                                ?.toString()
                                .padStart(2, "0")}{" "}
                              Lightworker is confirmed your event.
                            </p>
                          ) : null
                        ) : myEvent?.eventParticipant?.length ? (
                          <p className="t-14-bold text-black pb-2 pt-3 m-0">
                            {(myEvent?.eventParticipant?.length ?? 0)
                              ?.toString()
                              .padStart(2, "0")}{" "}
                            Participants Joined
                          </p>
                        ) : null}
                        {tab == "Providers" ? (
                          <div style={{ height: "calc(100% - 61px" }}>
                            <Grid container spacing={2}>
                              {myEvent?.eventProvider?.provider_detail
                                ?.length ? (
                                myEvent?.eventProvider?.provider_detail?.map(
                                  (ele) => (
                                    <ProviderCard
                                      data={ele}
                                      event={myEvent?.eventData}
                                      setOpenProviderScreen={
                                        setOpenProviderScreen
                                      }
                                      setOpenProDetails={setOpenProDetails}
                                    />
                                  )
                                )
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    padding: "35px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginTop: "10%",
                                  }}
                                >
                                  <p className="t-30-bold text-gray">
                                    Pending Providers participation
                                  </p>
                                </div>
                              )}
                            </Grid>
                          </div>
                        ) : (
                          <div>
                            <Grid container spacing={2}>
                              {myEvent?.eventParticipant?.length ? (
                                myEvent?.eventParticipant?.map((ele) => (
                                  <ParticipantCard
                                    data={ele}
                                    setOpenUserModal={setOpenUserModal}
                                    setOpenProDetails={setOpenProDetails}
                                  />
                                ))
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    padding: "35px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginTop: "10%",
                                  }}
                                >
                                  {/* {
                                                                        myEvent?.eventData?.need_provider ?
                                                                            <p className="t-30-bold text-gray">
                                                                                The request is pending with the
                                                                                Collaborate
                                                                                Provider
                                                                                participants cannot
                                                                                join the event.
                                                                            </p> :  */}
                                  <p className="t-30-bold text-gray">
                                    No participates.
                                  </p>
                                  {/* } */}
                                </div>
                              )}
                            </Grid>
                          </div>
                        )}
                      </div>
                    </InfiniteScroll>
                  </div>

                  <Box
                    className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center"
                    sx={{ marginTop: "50px" }}
                  >
                    <button
                      className="btn_admina ms-lg-auto mt-3"
                      onClick={() => setinstaTempModal(true)}
                    >
                      Generate Posts
                    </button>
                    {myEvent?.eventData?.is_registration_close ? null : (
                      <button
                        className="btn_admina mt-3"
                        // style={{
                        //     position: 'absolute',
                        //     width: '100%',
                        //     bottom: '0',
                        //     right: '0',
                        // }}
                        onClick={() => setIsRegistration(true)}
                      >
                        Close Registration
                      </button>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <EventRegistrationPopup
        setOpen={setIsRegistration}
        open={isRegistration}
        closeRegistration={closeRegistration}
      />
      <CancelEventPopup
        deleteEvent={cancleEvent}
        open={isCancel}
        setOpen={setIsCancel}
      />
      <ProviderDetailsScreen
        open={openProviderScreen}
        setOpen={setOpenProviderScreen}
        details={openProDetails}
        event={myEvent?.eventData}
      />
      <UserProfile
        open={openUserModal}
        setOpen={setOpenUserModal}
        data={openProDetails}
      />
      <InstaTempModal
        open={instaTempModal}
        setOpen={setinstaTempModal}
        details={myEvent}
        type="Event"
      />
      {loader && <Loader />}
    </>
  );
};

export default EventInfo;
