import React, {useEffect} from 'react';
import {Modal} from "react-bootstrap";
import image from "../assets/images/event.png";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {event, sessionBookingConstant} from "../action/constant";

const BookingPopup = ({open, setOpen, modify = false}) => {
    const {providerId} = useParams();
    const dispatch = useDispatch();
    const userAuth = JSON.parse(localStorage.getItem("AdAnima_auth"));
    const navigate = useNavigate();
    useEffect(() => {
        return () => {
            setOpen(false);
        }
    }, []);

    const handleClineBooking = () => {
        setOpen(false)
        dispatch({
            type: sessionBookingConstant.REMOVE_SESSION_BOOKING,
            payload: null,
        });
        dispatch({
            type: sessionBookingConstant.REMOVE_MODIFY_SESSION_BOOKING,
            payload: null,
        });
        dispatch({
            type: event.REMOVE_CHECK_MODIFY_PAYMENT,
            payload: null,
        });
        dispatch({
            type: event.REMOVE_EVENT,
            payload: null,
        })
        dispatch({
            type: event.REMOVE_MODIFY_EVENT,
            payload: null,
        })
    }
    return (
        <>
            <Modal
                centered
                backdrop="static"
                show={open}
                onHide={() => {
                    setOpen(false);
                }}
                dialogClassName="delete-modal"
            >
                <div className="Congratulations_msg">
                    <div className=" m-0" style={{borderRadius: "10px"}}>
                        <div className="group " style={{borderRadius: "10px"}}>
                            <div className="padding-inner pt-2" style={{borderRadius: "10px"}}>
                                <div className="text-center">
                                    <img
                                        className="img-fluid logo"
                                        alt=""
                                        src={image}
                                    />
                                </div>
                                <h3 className="mb-3 mb-md-3 page-title text-center">
                                    {modify ? 'Modify Successful!' : 'Booking Successful!'}
                                </h3>

                                <p className={!userAuth?.token ? "text-center mb-3 mb-md-5" : "text-center"}
                                   style={{color: "#000", wordBreak: 'break-word'}}>
                                    {modify ? "You have successfully Modify booking" : (!userAuth?.token ? "You have successfully booked this service." : "You have successfully made booking")}
                                </p>

                                {!userAuth?.token &&
                                    <p className="text-center t-15-500 mb-3 mb-md-5" style={{color: "#606060"}}>
                                        Please check your email for confirmation.
                                        You can View and modify your booking by login in
                                    </p>}
                                <div className="w-100 d-flex justify-content-center">
                                    <button className="massageBtn mt-3" onClick={() => {
                                        setOpen(false)
                                        handleClineBooking()
                                        setTimeout(() => {
                                            navigate('/')
                                        }, 500);
                                    }}>
                                        Back To Home
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default BookingPopup;